import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import ItemNameCell from "./ItemNameCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { formatNumber } from "../../../../utils/dataTransformer";
import PreVatAmountCell from "./PreVatAmountCell";
import WithholdingTaxCell from "./WithholdingTaxCell";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { FieldArrayWithId, useWatch } from "react-hook-form";
import { ISalesItemList } from "../../../../types/Sales";
import {
  IDefaultForm,
  IHeaderTable,
  ISelectOption,
} from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { EntityTypeEnum } from "../../../../generated/creatable";
import TocIcon from "@mui/icons-material/Toc";

type FieldArrayType = {
  item_list: ISalesItemList[];
};

type Props = {
  documentType: EntityTypeEnum;
  field: FieldArrayWithId<FieldArrayType, "item_list", "id">;
  index: number;
  control: IDefaultForm["control"];
  getValues: IDefaultForm["getValues"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  headers: IHeaderTable[];
  openDeleteItemConfirmationHandler: (index: number) => void;
  openItemPriceHistoryModalHandler: (
    index: number,
    item_unique_id: string | undefined
  ) => void;
};

const vatOptions: ISelectOption[] = [
  {
    label: "ไม่มี",
    value: "ไม่มี",
  },
  {
    label: "0 %",
    value: "0",
  },
  {
    label: "7 %",
    value: "7",
  },
];

const ItemRow = ({
  documentType,
  field,
  index,
  control,
  disabled,
  errors,
  getValues,
  setValue,
  headers,
  openDeleteItemConfirmationHandler,
  openItemPriceHistoryModalHandler,
}: Props) => {
  const currentPricePerUnit = useWatch({
    control,
    name: `item_list.${index}.price_per_unit` as any,
  });

  return (
    <Draggable
      key={field.id}
      draggableId={"item-" + field.id}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            ...provided.draggableProps.style,
            breakInside: "avoid",
            backgroundColor:
              documentType === "sales_order" &&
              (!currentPricePerUnit || currentPricePerUnit === 0)
                ? "#F2F2F266"
                : "default",
          }}
        >
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[0]?.width || 0 : 0) + 16
            }
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!disabled && <TocIcon fontSize="small" />}
              <Typography fontSize={14}>{index + 1}</Typography>
            </Box>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[1]?.width || 0 : 0) + 16
            }
          >
            <ItemNameCell
              control={control}
              errors={errors}
              field={field}
              index={index}
              disabled={disabled}
              documentType={documentType}
            />
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[2]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Typography fontSize={14}>{field.qty}</Typography>
            ) : (
              <ControlledTextField
                control={control}
                name={`item_list[${index}].qty`}
                type="number"
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[index] &&
                    errors?.item_list[index]?.qty
                )}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e, fieldHook) => {
                  const parseValue = parseInt(e.target.value);
                  fieldHook.onChange(parseValue);
                  const currentUomUniqueId = getValues(
                    `item_list[${index}].uom`
                  );
                  if (currentUomUniqueId) {
                    if (field.uom_group) {
                      if (
                        currentUomUniqueId ===
                        field.uom_group.base_uom?.unique_id
                      ) {
                        setValue(`item_list[${index}].stock_qty`, parseValue);
                      } else {
                        if (
                          field.uom_group.uom_conversion_list &&
                          field.uom_group.uom_conversion_list.length > 0
                        ) {
                          const conversionUom =
                            field.uom_group.uom_conversion_list.find(
                              (conversion) =>
                                conversion.target_uom_unique_id ===
                                currentUomUniqueId
                            );
                          if (conversionUom) {
                            const targetUomQty =
                              conversionUom.base_uom_rate /
                              conversionUom.target_uom_rate;
                            setValue(
                              `item_list[${index}].stock_qty`,
                              parseValue * targetUomQty
                            );
                          }
                        }
                      }
                    }
                  }
                }}
              />
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[3]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Typography fontSize={14}>{field.uom}</Typography>
            ) : (
              <ControlledSelect
                control={control}
                name={`item_list[${index}].uom`}
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[index] &&
                    errors?.item_list[index]?.uom
                )}
                options={
                  field.uom_group
                    ? field.uom_group.convertable_uom_list &&
                      field.uom_group.convertable_uom_list.length > 0
                      ? [
                          {
                            label: field.uom_group?.base_uom?.name || "",
                            value: field.uom_group?.base_uom?.unique_id || "",
                          },
                          ...field.uom_group.convertable_uom_list.map(
                            (uom) => ({
                              label: uom.name,
                              value: uom.unique_id,
                            })
                          ),
                        ]
                      : [
                          {
                            label: field.uom_group?.base_uom?.name || "",
                            value: field.uom_group?.base_uom?.unique_id || "",
                          },
                        ]
                    : []
                }
                onChange={(e: any) => {
                  const currentQty = getValues(`item_list[${index}].qty`);
                  if (field.uom_group) {
                    if (
                      e.target.value === field.uom_group.base_uom?.unique_id
                    ) {
                      setValue(`item_list[${index}].stock_qty`, currentQty);
                    } else {
                      if (
                        field.uom_group.uom_conversion_list &&
                        field.uom_group.uom_conversion_list.length > 0
                      ) {
                        const conversionUom =
                          field.uom_group.uom_conversion_list.find(
                            (conversion) =>
                              conversion.target_uom_unique_id === e.target.value
                          );
                        if (conversionUom) {
                          const targetUomQty =
                            conversionUom.base_uom_rate /
                            conversionUom.target_uom_rate;
                          setValue(
                            `item_list[${index}].stock_qty`,
                            currentQty * targetUomQty
                          );
                        }
                      }
                    }
                  }
                }}
              />
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[4]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Box
                sx={
                  documentType === EntityTypeEnum.Quotation
                    ? {
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }
                    : undefined
                }
              >
                <Typography fontSize={14}>
                  {formatNumber(field.price_per_unit)}
                </Typography>
                {documentType === EntityTypeEnum.Quotation && (
                  <IconButton
                    aria-label="view sales item price history"
                    size="small"
                    sx={{ color: "#2167D3" }}
                    onClick={() =>
                      openItemPriceHistoryModalHandler(
                        index,
                        field.item_unique_id
                      )
                    }
                  >
                    <AccessTimeIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ControlledNumberTextField
                  control={control}
                  name={`item_list[${index}].price_per_unit`}
                  error={Boolean(
                    errors?.item_list &&
                      errors?.item_list[index] &&
                      errors?.item_list[index]?.price_per_unit
                  )}
                />
                {documentType === EntityTypeEnum.Quotation && (
                  <IconButton
                    aria-label="view sales item price history"
                    size="small"
                    sx={{ color: "#2167D3" }}
                    onClick={() =>
                      openItemPriceHistoryModalHandler(
                        index,
                        field.item_unique_id
                      )
                    }
                  >
                    <AccessTimeIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[5]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Typography fontSize={14}>
                {formatNumber(field.discount)}
              </Typography>
            ) : (
              <ControlledNumberTextField
                control={control}
                name={`item_list[${index}].discount`}
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[index] &&
                    errors?.item_list[index]?.discount
                )}
              />
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[6]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Typography fontSize={14}>{field.vat_percentage}</Typography>
            ) : (
              <ControlledSelect
                control={control}
                name={`item_list[${index}].vat_percentage`}
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[index] &&
                    errors?.item_list[index]?.vat_percentage
                )}
                options={vatOptions}
              />
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[7]?.width || 0 : 0) + 16
            }
          >
            <PreVatAmountCell
              control={control}
              index={index}
              field={field}
              disabled={disabled}
              setValue={setValue}
            />
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[8]?.width || 0 : 0) + 16
            }
          >
            <WithholdingTaxCell
              control={control}
              errors={errors}
              index={index}
              documentType={documentType}
              disabled={disabled}
              setValue={setValue}
              field={field}
            />
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[9]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <>
                <Typography fontSize={14}>{field.stock_qty}</Typography>
                <Typography variant="caption">
                  {field.uom_group?.base_uom?.name}
                </Typography>
              </>
            ) : (
              <ControlledTextField
                sx={{ mt: 3 }}
                type="number"
                control={control}
                name={`item_list[${index}].stock_qty`}
                helperText={field.uom_group?.base_uom?.name}
                InputProps={{
                  inputProps: {
                    style: { textAlign: "center" },
                  },
                }}
                disabled={true}
              />
            )}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0 ? headers[10]?.width || 0 : 0) + 16
            }
          >
            {disabled ? (
              <Typography fontSize={14}>{field.remark}</Typography>
            ) : (
              <ControlledTextField
                control={control}
                name={`item_list[${index}].remark`}
                error={Boolean(
                  errors?.item_list &&
                    errors?.item_list[index] &&
                    errors?.item_list[index]?.remark
                )}
              />
            )}
          </TableCell>
          {documentType === "sales_order" && disabled && (
            <>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={
                  (headers && headers.length > 0
                    ? headers[11]?.width || 0
                    : 0) + 16
                }
              >
                <Typography fontSize={14}>
                  {field.qty_manufactured || 0}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  {`ค้างผลิต ${
                    (field.qty_manufactured || 0) >= field.qty
                      ? 0
                      : (field.qty || 0) - (field.qty_manufactured || 0)
                  }`}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={
                  (headers && headers.length > 0
                    ? headers[12]?.width || 0
                    : 0) + 16
                }
              >
                <Typography fontSize={14}>{field.qty_issued || 0}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={
                  (headers && headers.length > 0
                    ? headers[13]?.width || 0
                    : 0) + 16
                }
              >
                <Typography fontSize={14}>{field.qty_to_ship || 0}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={
                  (headers && headers.length > 0
                    ? headers[14]?.width || 0
                    : 0) + 16
                }
              >
                <Typography fontSize={14}>{field.qty_shipped || 0}</Typography>
              </TableCell>
              {/* <TableCell
            align="center"
            sx={{
              px: 1,
              py: 1,
            }}
            width={
              (headers && headers.length > 0
                ? headers[13]?.width || 0
                : 0) + 16
            }
          >
            <Typography fontSize={14}>
              {field.qty_invoiced || 0}
            </Typography>
          </TableCell> */}
              <TableCell
                align="center"
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={
                  (headers && headers.length > 0
                    ? headers[15]?.width || 0
                    : 0) + 16
                }
              >
                <Typography fontSize={13}>{field.qty_returned || 0}</Typography>
              </TableCell>
            </>
          )}
          {!disabled && (
            <TableCell
              align="center"
              sx={{
                px: 1,
                py: 1,
              }}
              width={
                (headers && headers.length > 0 ? headers[11]?.width || 0 : 0) +
                16
              }
            >
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                onClick={() => openDeleteItemConfirmationHandler(index)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )}
    </Draggable>
  );
};

export default ItemRow;
