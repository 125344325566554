import AgGrid from "../../../UI/AgGrid";
import { useEffect, useCallback } from "react";
import { itemColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ColDef,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import {
  InventoryTagsFindByEntityQuery,
  ItemEntityType,
  ItemType,
  useInventoryTagsFindByEntityQuery,
} from "../../../../generated/inventory";
import { ITEMS_SKU_AGGRID } from "../../../../services/AgGrid/InventoryAgGrid";
import { IServerSideDatasource } from "ag-grid-community";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

interface Props {
  gridRef: any;
  isFilter?: string | null;
}

const ItemsTable = ({ gridRef, isFilter }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: tagList } =
    useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
      graphQLClientWithHeaderItem,
      {
        entityName: ItemEntityType.Item,
      }
    );

  const columnDefs: ColDef[] = itemColumnDefs(
    t,
    false,
    false,
    tagList?.itemTagsFindByEntity ?? []
  );

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        name,
        desc,
        type,
        is_active,
        tag_list,
        item_level_1,
        ...otherFilter
      } = filterModel;

      const formatFilter = {
        ...otherFilter,
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        item_sku: (name || desc || type || tag_list || item_level_1) && {
          filterType: "object",
          filter: {
            item: {
              filterType: "object",
              filter: {
                name: name && name,
                desc: desc && desc,
                type: type && { ...type, values: type.values },
                tag_list: tag_list && {
                  filterType: "objectArray",
                  type: "some",
                  filter: {
                    name: {
                      filterType: "set",
                      values: tag_list.values,
                    },
                  },
                },
                item_level_1: item_level_1 && {
                  filterType: "object",
                  filter: {
                    name: item_level_1,
                  },
                },
              },
            },
          },
        },
      };

      try {
        const { itemSkuDetailsFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: itemSkuDetailsFindManyAggrid.data as any[],
          rowCount: itemSkuDetailsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceType = params.api.getFilterInstance("type");
      const instanceStatus = params.api.getFilterInstance("is_active");
      instanceType?.setModel({});
      instanceStatus?.setModel({});
      switch (isFilter) {
        case "normal":
          instanceType?.setModel({
            values: [ItemType.Normal],
          });
          break;
        case "variant":
          instanceType?.setModel({
            values: [ItemType.Variant],
          });
          break;
        case "bundle":
          instanceType?.setModel({ values: [ItemType.Bundle] });
          break;
        case "service":
          instanceType?.setModel({ values: [ItemType.Service] });
          break;
        case "in_active":
          instanceStatus?.setModel({ values: ["0"] });
          break;
        default:
          instanceType?.setModel({});
          instanceStatus?.setModel({ values: ["1"] });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { sku_name } = params.data;
    navigate(
      `${pathname}/${encodeURIComponent(sku_name)}?tab=item&subtab=general`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onGridReady={onGridReady}
      onRowDoubleClicked={rowSelectHandler}
      path={"/inventory/items"}
    />
  );
};

export default ItemsTable;
