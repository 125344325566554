import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import { AdditionalDiscountType } from "../../../generated/purchase";
import { bahttext } from "bahttext";

const CustomizedSummary = styled(Box)({
  display: "grid",
  justifyContent: "flex-end",
  textAlign: "right",
  gridGap: "10px",
  gap: "10px",
  paddingRight: "30px",
  paddingTop: "4px",
  paddingBottom: "4px",
  gridTemplateColumns:
    "minmax(min-content,max-content) minmax(6rem,max-content) 24px",
});

interface Props {
  data: any;
  documentType?: string;
  isPurchase?: boolean;
}

const PDFFooter = ({ data, documentType, isPurchase }: Props) => {
  return (
    <>
      <Grid container sx={{ breakInside: "avoid" }}>
        {documentType !== "delivery_order" && (
          <Grid item xs={12}>
            <CustomizedSummary
              bgcolor={isPurchase ? "#F2EEFC" : "#ECF6FD"}
              borderRadius="4px"
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                มูลค่ารวมก่อนภาษี
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                {formatNumber(data.pre_vat_amount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
          </Grid>
        )}
        <Grid item xs={6}>
          <Box sx={{ display: "block", gap: 0.75, mt: 1.7 }}>
            <Typography sx={{ fontSize: 11, fontWeight: 600 }}>
              หมายเหตุ
            </Typography>
            <Typography sx={{ fontSize: 11, mr: 2, wordBreak: "break-word" }}>
              {data.remark}
            </Typography>
          </Box>
        </Grid>
        {!["purchase_request", "delivery_order"].includes(
          documentType || "-"
        ) && (
          <Grid item xs={6}>
            <Box mt={1}>
              {data.additional_discount > 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    ส่วนลดเพิ่มเติม
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    {formatNumber(data.additional_discount)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    {data.additional_discount_type ===
                    AdditionalDiscountType.Percent
                      ? "%"
                      : "บาท"}
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
              {data.additional_discount !== 0 ? (
                <CustomizedSummary>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    มูลค่าหลังหักส่วนลด
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    {formatNumber(
                      data.pre_vat_amount - data.additional_discount
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      color: isPurchase ? "#4A22AA" : "#2167D3",
                    }}
                  >
                    บาท
                  </Typography>
                </CustomizedSummary>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  ภาษีมูลค่าเพิ่มรวม
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  {formatNumber(data.vat_amount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            </Box>
            <CustomizedSummary
              bgcolor={isPurchase ? "#F2EEFC" : "#ECF6FD"}
              borderRadius="4px"
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                มูลค่ารวมสุทธิ
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                {formatNumber(data.net_amount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
            {data.withholding_tax_amount > 0 && (
              <CustomizedSummary>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  ภาษีหัก ณ ที่จ่าย
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  {formatNumber(data.withholding_tax_amount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    color: isPurchase ? "#4A22AA" : "#2167D3",
                  }}
                >
                  บาท
                </Typography>
              </CustomizedSummary>
            )}
            <CustomizedSummary>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                จำนวนเงินรวมทั้งสิ้น
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                {formatNumber(data.total_amount)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  fontWeight: 600,
                }}
              >
                บาท
              </Typography>
            </CustomizedSummary>
            <Box mr="30px">
              <Typography
                sx={{
                  fontSize: 11,
                  color: isPurchase ? "#4A22AA" : "#2167D3",
                  textAlign: "right",
                }}
              >
                ({bahttext(roundingNumber(data.total_amount))})
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PDFFooter;
