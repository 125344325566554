import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../../UI/AgGrid";
import { useCallback, useEffect, useRef } from "react";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { PURCHASE_REQUESTS_AGGRID } from "../../../../services/AgGrid/PurchaseAgGrid";
import { AgGridReact } from "ag-grid-react";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { IPurchaseRequest } from "../../../../types/Purchase/purchaseRequest";
import { usePurchaseRequestColumnDefs } from "./columnDefs";
import { GraphQLClient } from "graphql-request";
import { useStateContext } from "../../../../contexts/auth-context";

interface Props {
  isFilter?: string | null;
  referenceUniqueId?: string;
}

const PurchaseRequestTable = ({ isFilter, referenceUniqueId }: Props) => {
  const gridRef = useRef<AgGridReact<IPurchaseRequest>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { permissions, authUser },
  } = useStateContext();

  const columnDef: ColDef[] | undefined = usePurchaseRequestColumnDefs(
    t,
    false
  );

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        created_date,
        issue_date,
        due_date,
        related_user_list,
        requestor_contact,
        created_by,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        requestor_contact: requestor_contact && {
          filterType: "json",
          type: requestor_contact.type,
          filter: requestor_contact.filter,
          path: ["name"],
        },
        created_by:
          permissions?.purchase_request.view === "SELF"
            ? {
                filterType: "json",
                type: "equals",
                filter: authUser?.unique_id,
                path: ["user_unique_id"],
              }
            : created_by && {
                filterType: "json",
                type: created_by.type,
                filter: created_by.filter,
                path: ["first_name"],
              },
        related_user_list: related_user_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: related_user_list,
          },
        },
        reference_unique_id_list: referenceUniqueId
          ? {
              filterType: "array",
              type: "hasSome",
              values: [referenceUniqueId],
            }
          : undefined,
      };
      try {
        const { purchaseRequestsFindManyAggrid } =
          await graphQLClientWithHeaderPurchase.request(
            PURCHASE_REQUESTS_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
        params.success({
          rowData: purchaseRequestsFindManyAggrid.data as any[],
          rowCount: purchaseRequestsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_approve":
          instance?.setModel({ values: ["wait_approve"] });
          break;
        case "not_approved":
          instance?.setModel({ values: ["not_approved"] });
          break;
        case "approved":
          instance?.setModel({ values: ["approved"] });
          break;
        case "partially_ordered":
          instance?.setModel({ values: ["partially_ordered"] });
          break;
        case "fully_ordered":
          instance?.setModel({ values: ["fully_ordered"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/purchase/request/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/purchase/request"}
    />
  );
};

export default PurchaseRequestTable;
