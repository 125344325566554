import { useTranslation } from "react-i18next";
import { IBillingAddress, IDefaultForm, IModalUI } from "../../../types/global";
import ModalUI from "../../UI/ModalUI";
import { Box, Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import addressData from "../../../data/address.json";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useAddressOptions } from "../../../hooks/use-address-options";
import { IPurchaseOrder } from "../../../types/Purchase/purchaseOrder";
import { IPurchaseReturn } from "../../../types/Purchase/purchaseReturn";

type BillingAddressProps = IModalUI &
  IDefaultForm & {
    billingAddressSnapshot: IBillingAddress | null;
    setBillingAddressSnapshot: Dispatch<SetStateAction<IBillingAddress | null>>;
  };

const countryOptions = ["ไทย"];
const BillingAddressForm = ({
  control,
  errors,
  open,
  handleClose,
  setValue,
  getValues,
  disabled,
  reset,
  billingAddressSnapshot,
  setBillingAddressSnapshot,
}: BillingAddressProps) => {
  const { t } = useTranslation();
  const {
    subDistrictOptions,
    districtOptions,
    provinceOptions,
    setSearchSubDistrict,
    setSearchDistrict,
    setSearchProvince,
    updateFilter,
  } = useAddressOptions(control);
  const resetSearchOptions = () => {
    setSearchSubDistrict(undefined);
    setSearchDistrict(undefined);
    setSearchProvince(undefined);
  };

  const closeBillingAddressForm = (e: any) => {
    if (reset) {
      reset((prev: IPurchaseOrder | IPurchaseReturn) => ({
        ...prev,
        vendor_contact: {
          ...prev.vendor_contact,
          billing_address: billingAddressSnapshot,
        },
      }));
    }
    if (handleClose) {
      handleClose(e);
    }
    resetSearchOptions();
  };

  const billingSubmitHandler = (e: any) => {
    const currentBillingAddress: IBillingAddress = getValues(
      "vendor_contact.billing_address"
    );
    setBillingAddressSnapshot(currentBillingAddress);
    if (handleClose) {
      handleClose(e);
    }
    resetSearchOptions();
  };

  return (
    <ModalUI
      open={open}
      handleClose={closeBillingAddressForm}
      title={t("address.billing_address")}
      fullWidth
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={closeBillingAddressForm}
            disabled={disabled}
          />
          <CustomizedButton
            title={t("button.save")}
            onClick={(e) => billingSubmitHandler(e)}
            variant="contained"
            size="medium"
            disabled={disabled}
          />
        </Box>
      }
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ControlledTextField
            label={t("address.index")}
            control={control}
            name="vendor_contact.billing_address.address"
            error={Boolean(
              errors.vendor_contact &&
                errors.vendor_contact.billing_address &&
                errors.vendor_contact.billing_address.address
            )}
            helperText={
              errors.vendor_contact &&
              errors.vendor_contact.billing_address &&
              errors.vendor_contact.billing_address.address &&
              errors.vendor_contact.billing_address.address.message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-subdistrict"
            name="vendor_contact.billing_address.sub_district"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={subDistrictOptions}
                label={t("address.sub_district")}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSearchSubDistrict(undefined);
                    updateFilter();
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSearchSubDistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.sub_district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue(
                      "vendor_contact.billing_address.district",
                      filter[0].district
                    );
                    setValue(
                      "vendor_contact.billing_address.province",
                      filter[0].province
                    );
                    setValue(
                      "vendor_contact.billing_address.postal_code",
                      filter[0].postal_code
                    );
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  setValue("vendor_contact.billing_address.country", "ไทย");
                  return field.onChange(option);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-district"
            name="vendor_contact.billing_address.district"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={districtOptions}
                label={t("address.district")}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSearchDistrict(undefined);
                    updateFilter();
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSearchDistrict(option);
                  const filter = addressData.filter((adddress) => {
                    return `${adddress.district}` === `${option}`;
                  });
                  if (filter && filter.length !== 0) {
                    setValue(
                      "vendor_contact.billing_address.province",
                      filter[0].province
                    );
                  } else if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  setValue("vendor_contact.billing_address.country", "ไทย");
                  return field.onChange(option);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-province"
            name="vendor_contact.billing_address.province"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={provinceOptions}
                label={t("address.province")}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    return;
                  } else {
                    setSearchProvince(undefined);
                    updateFilter();
                  }
                }}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  setSearchProvince(option);
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  setValue("vendor_contact.billing_address.country", "ไทย");
                  return field.onChange(option);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            label={t("address.postal_code")}
            control={control}
            name="vendor_contact.billing_address.postal_code"
            error={Boolean(
              errors.vendor_contact &&
                errors.vendor_contact.billing_address &&
                errors.vendor_contact.billing_address.postal_code
            )}
            helperText={
              errors.vendor_contact &&
              errors.vendor_contact.billing_address &&
              errors.vendor_contact.billing_address.postal_code &&
              errors.vendor_contact.billing_address.postal_code.message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="billing-country"
            name="vendor_contact.billing_address.country"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={countryOptions}
                label={t("address.country")}
                onChange={(e, option) => {
                  if (!option) {
                    return field.onChange("");
                  }
                  if (option.value) {
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default BillingAddressForm;
