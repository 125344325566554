import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../types/global";
import { useTranslation } from "react-i18next";

export const usePurchaseOrderCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: `ใบ${t("inventory.goods_receive.index")}`,
        disabled: false,
      },
      {
        value: t("purchase.return.index"),
        disabled: false,
      },
    ],
    [t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "approved":
      case "partially_imported":
      case "fully_imported":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
