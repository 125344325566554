import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  IDeliveryOrder,
  IDeliveryOrderItemList,
} from "../../types/Logistic/deliveryOrder";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";
import { DeliveryOrderQuery } from "../../generated/logistic";
import { GraphQLClient } from "graphql-request";
import { v4 as uuidv4 } from "uuid";

export const deliveryOrderCreatePayloadFormatter = (
  data: IDeliveryOrder,
  status: string
) => {
  const {
    item_list,
    customer_contact,
    tag_list,
    created_date,
    shipping_cost,
    additional_discount,
    ...otherData
  } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(
    ({ uom_group, qty, discount, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      qty: parseInt(qty.toString() || "0"),
      discount: discount || 0,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const { unique_id_name, ...customer } = customer_contact;

  const formatPayload = {
    ...otherData,
    shipping_cost: shipping_cost || 0,
    additional_discount: additional_discount || 0,
    customer_contact: customer,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const deliveryOrderUpdatePayloadFormatter = async (
  data: IDeliveryOrder,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    customer_contact,
    updated_date,
    item_list,
    tag_list,
    created_by,
    created_date,
    shipping_cost,
    additional_discount,
    ...otherData
  } = data;

  const { unique_id_name, ...customer } = customer_contact;

  const formatItemList = item_list.map(
    ({ uom_group, qty, discount, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      discount: typeof discount === "number" ? discount : 0,
      qty: parseInt(qty.toString() || "0"),
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "deliveryOrder",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        attachment_list.push(formatAttachment);
      } else {
        attachment_list.push(file);
      }
    }
  }

  const formatPayload = {
    ...otherData,
    additional_discount:
      typeof additional_discount === "number" ? additional_discount : 0,
    shipping_cost: typeof shipping_cost === "number" ? shipping_cost : 0,
    customer_contact: customer,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    attachment_list,
  };
  return formatPayload;
};

export const deliveryOrderQueryFormatter = async (data: IDeliveryOrder) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");
  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IDeliveryOrderItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
    });
  });

  const formatCustomer = {
    ...data.customer_contact,
    unique_id_name: `${data.customer_contact_unique_id} - ${data.customer_contact.name}`,
  };

  const formatPayload = {
    ...data,
    customer_contact: formatCustomer,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copyDeliveryFormatter = (
  data?: DeliveryOrderQuery["deliveryOrder"]
) => {
  if (data) {
    const {
      id,
      unique_id,
      created_date,
      issue_date,
      created_by,
      external_reference_id,
      attachment_list,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      reference_unique_id_list,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(({ unique_id, ...otherItemList }) => ({
        unique_id: uuidv4(),
        ...otherItemList,
      }));

    return {
      ...otherData,
      item_list: formatItemList,
      attachment_list: [],
    };
  }
};
