import { useCallback, useMemo } from "react";
import { IEvent } from "../../../types/Project";
import { EventSourceInput } from "@fullcalendar/core";

export const useEventObjectFormatter = (allEvents: IEvent[]) => {
  const renderBgColor = useCallback((status: string) => {
    switch (status) {
      case "planning":
        return "#D3D3D3";
      case "appointment":
        return "#FCE69F";
      case "check_in":
        return "#C4DBFD";
      case "finished":
        return "#E5F8EF";
      default:
        return "#D3D3D3";
    }
  }, []);

  const eventObjects: EventSourceInput = useMemo(() => {
    const formattedEvents = allEvents?.map((event: IEvent) => {
      const formattedOption = {
        start:
          event.planning_started_date ??
          event.appointment_started_date ??
          undefined,
        end:
          event.planning_ended_date ??
          event.appointment_ended_date ??
          undefined,
        title: event.name,
        backgroundColor: renderBgColor(
          event.aggrid_status ?? event.main_status
        ),
        display: "block",
        ...event,
      };
      return formattedOption;
    });

    return formattedEvents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents]);

  return {
    eventObjects: eventObjects,
  };
};
