import { Typography, Grid, Box, DialogContentText } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { Fragment, useState } from "react";
import { Controller, UseFormHandleSubmit } from "react-hook-form";
import ModalUI from "../../../UI/ModalUI";

import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";

import { EntityTypeEnum } from "../../../../generated/creatable";

import { IDefaultForm } from "../../../../types/global";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";

import DocDropzoneUI from "../../../UI/DocDropzoneUI";
import ButtonLayout from "../../../UI/ButtonLayout";

type Props = IDefaultForm & {
  handleSubmit: UseFormHandleSubmit<IDeliveryOrder>;
  onSubmit: (data: IDeliveryOrder) => void;
  closeModalHandler: () => void;
  status: string;
};

const DeliveryDetails = ({
  disabled,
  control,
  errors,
  getValues,
  setValue,
  onSubmit,
  status,
  handleSubmit,
  closeModalHandler,
}: Props) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);

  const deliveryStatusOptions = [
    { id: 1, label: t("status.completed"), value: "completed" },
    { id: 2, label: t("status.not_completed"), value: "not_completed" },
  ];

  const deliveryRemarkType = [
    { id: 1, label: "ลายเซ็น", value: "ลายเซ็น" },
    { id: 2, label: "รูปถ่าย", value: "รูปถ่าย" },
  ];

  const mappedStatusToThai = (status: string) => {
    if (status === "wait_deliver") {
      return "รอจัดส่ง";
    } else if (status === "not_completed") {
      return "ไม่สำเร็จ";
    } else if (status === "completed") {
      return "สำเร็จ";
    } else {
      return "";
    }
  };

  const sub_status = getValues("sub_status");

  return (
    <Fragment>
      <Grid container>
        <Grid container sx={{ alignItems: "center", mb: 1 }}>
          <Grid xs={3}>
            <Typography>
              {t("logistic.delivery_order.delivery_status")}
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Controller
              name="sub_status"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  fullWidth
                  error={Boolean(errors?.sub_status)}
                  helperText={errors?.sub_status?.message}
                  options={deliveryStatusOptions}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ alignItems: "center", mb: 1 }}>
          <Grid xs={3}>
            <Typography>
              {t("logistic.delivery_order.recipient_name")}
            </Typography>
          </Grid>
          <Grid xs={9}>
            <Controller
              name="recipient_name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  disabled={disabled}
                  error={Boolean(errors?.recipient_name)}
                  helperText={errors?.recipient_name?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography sx={{ my: 2, fontWeight: "bold" }}>หลักฐานอ้างอิง</Typography>
      <Grid sm={12} md={6} lg={5} my={2}>
        <Grid container sx={{ alignItems: "center", mb: 1 }}>
          <Grid xs={3}>
            <Typography>
              {t("logistic.delivery_order.delivery_reference")}
            </Typography>
          </Grid>
          <Grid xs={9}>
            <ControlledServerSideCreatable
              control={control}
              name="delivery_reference"
              documentType={EntityTypeEnum.DeliveryOrder}
              defaultOptions={deliveryRemarkType}
              error={Boolean(errors && errors?.delivery_reference)}
              helperText={errors?.delivery_reference?.message}
              setValue={setValue}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} sx={{ mt: 3, mb: disabled ? 2 : 0 }}>
            <Controller
              key="delivery_detail"
              name="delivery_detail"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  multiline
                  rows={4}
                  error={Boolean(errors["delivery_detail"])}
                  helperText={errors["delivery_detail"]?.message}
                  {...field}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
        <DocDropzoneUI
          name="attachment_list"
          control={control}
          setValue={setValue}
          disabled={disabled}
        />
      </Grid>
      <ButtonLayout>
        <CustomizedButton
          title={t("button.cancel")}
          variant="outlined"
          onClick={closeModalHandler}
        />
        <CustomizedButton
          title={t("button.save")}
          variant="contained"
          onClick={async () => {
            if (status === "wait_deliver" || status === "not_completed") {
              setConfirmation(true);
            } else {
              handleSubmit(onSubmit);
            }
          }}
        />
      </ButtonLayout>
      <ModalUI
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        title={`ยืนยันการปรับสถานะจัดส่งจาก ${mappedStatusToThai(
          status
        )} เป็น ${mappedStatusToThai(sub_status)}`}
        fullWidth
        maxWidth="xs"
      >
        {sub_status === "completed" && (
          <DialogContentText>
            หากยืนยันแล้วจะไม่สามารถแก้ไขได้
          </DialogContentText>
        )}
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={() => setConfirmation(false)}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </ModalUI>
    </Fragment>
  );
};

export default DeliveryDetails;
