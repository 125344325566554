import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm, ITab } from "../../../types/global";
import { GoodsIssueQuery, WarehousesQuery } from "../../../generated/inventory";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BarcodeTab from "./BarcodeTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsIssueQuery, unknown>>;
  data?: GoodsIssueQuery;
  warehouseData?: WarehousesQuery;
  renderButton: () => JSX.Element | null;
};
const GoodsAdjustmentMobile = ({
  control,
  getValues,
  setValue,
  disabled,
  refetch,
  reset,
  errors,
  data,
  warehouseData,
  renderButton,
}: Props) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs: ITab[] = [
    {
      label: "ข้อมูลนำออก",
      path: `${pathname}`,
    },
    {
      label: "สแกน Barcode",
      path: `${pathname}?tab=barcode`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "barcode":
        setCurrentTab(pathname + "?tab=barcode");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "barcode":
        return (
          <BarcodeTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            renderButton={renderButton}
          />
        );
      default:
        return (
          <DocumentInfoTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            refetch={refetch}
            setValue={setValue}
            data={data}
            reset={reset}
            warehouseData={warehouseData}
          />
        );
    }
  };

  return (
    <form>
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </form>
  );
};

export default GoodsAdjustmentMobile;
