import { Box, Grid } from "@mui/material";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedCheckboxes from "../../../Custom/CustomizedCheckbox";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useState } from "react";
import { IDefaultForm } from "../../../../types/global";

interface Props {
  serialModal: boolean;
  closeSerialModalHandler: () => void;
  addSerialsHandler: () => void;
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  reset: IDefaultForm["reset"];
}

const SerialModal = ({
  serialModal,
  closeSerialModalHandler,
  addSerialsHandler,
  control,
  reset,
  setValue,
  getValues,
}: Props) => {
  const { t } = useTranslation();

  const [lotDate, setLotDate] = useState<boolean>(false);

  const openLotDate = () => {
    setLotDate(true);
  };

  const closeLotDate = () => {
    setLotDate(false);
  };

  const isLot = useWatch({
    control,
    name: "is_lot",
  });

  return (
    <ModalUI
      title="เพิ่ม LOT/SN"
      open={serialModal}
      handleClose={() => {
        if (reset) {
          reset();
        }
        closeSerialModalHandler();
      }}
      maxWidth="sm"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={() => {
              if (reset) {
                reset();
              }
              closeSerialModalHandler();
            }}
          />
          <CustomizedButton
            title={t("button.add")}
            onClick={addSerialsHandler}
            variant="contained"
            size="medium"
          />
        </Box>
      }
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("inventory.goods_receive.serial.serial_quantity")}
            control={control}
            name="serial_quantity"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("inventory.goods_receive.serial.quantity_per_serial")}
            control={control}
            name="quantity_per_serial"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ControlledTextField
            label={t("sentence.remark")}
            control={control}
            name="remark"
          />
        </Grid>
      </Grid>
      <Box my={2}>
        <Controller
          name="is_lot"
          control={control}
          render={({ field }) => (
            <CustomizedCheckboxes
              {...field}
              onChange={(e) => {
                if (e.target.checked) {
                  const date = getValues("posted_date");
                  setValue("lot_date", date);
                } else {
                  setValue("lot_date", null);
                }
                field.onChange(e);
              }}
              label={t("inventory.goods_receive.serial.is_lot")}
            />
          )}
        />
      </Box>
      {isLot && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <ControlledDatePicker
              label="LOT"
              control={control}
              name="lot_date"
              open={lotDate}
              onOpen={openLotDate}
              onClose={closeLotDate}
            />
          </Grid>
        </Grid>
      )}
    </ModalUI>
  );
};

export default SerialModal;
