import { ManufactureDashboard } from "../../generated/manufacture";
import { PurchaseDashboard } from "../../generated/purchase";
import { SalesDashboard } from "../../generated/sales";

export const getTotalValue = (data: any[]) => {
  return data.reduce((acc, curr) => acc + parseFloat(curr.net_amount), 0);
};

export const formatMOChartData = (items: (ManufactureDashboard | null)[]) => {
  // Sort and format chart data
  const chartData = items
    .filter((item) => item !== null)
    .sort((a, b) => b!.total_sale - a!.total_sale)
    .map((item) => {
      return [item!.item_name, item!.total_sale];
    });

  // Find other items index
  const otherItemsIndex = chartData.findIndex((item) => item[0] === "other");

  // Remove other items from the data
  const [otherItems] = chartData.splice(otherItemsIndex, 1);

  // Concat column header and other items to the data
  const chartDataWithColumnName = [
    ["ItemName", "Count"],
    ...chartData,
    otherItems,
  ];

  return chartDataWithColumnName;
};

export const formatSOChartData = (items: SalesDashboard[]) => {
  // Sort and format chart data
  const chartData = items
    .sort((a, b) => b.total_sale - a.total_sale)
    .map((item) => {
      return [item.item_name, item.total_sale];
    });

  // Find other items index
  const otherItemsIndex = chartData.findIndex((item) => item[0] === "other");

  // Remove other items from the data
  const [otherItems] = chartData.splice(otherItemsIndex, 1);

  // Concat column header and other items to the data
  const chartDataWithColumnName = [
    ["ItemName", "Count"],
    ...chartData,
    otherItems,
  ];

  return chartDataWithColumnName;
};

export const formatPOChartData = (items: PurchaseDashboard[]) => {
  // Sort and format chart data
  const chartData = items
    .sort((a, b) => b.total_sale - a.total_sale)
    .map((item) => {
      return [item.item_name, item.total_sale];
    });

  // Find other items index
  const otherItemsIndex = chartData.findIndex((item) => item[0] === "other");

  // Remove other items from the data
  const [otherItems] = chartData.splice(otherItemsIndex, 1);

  // Concat column header and other items to the data
  const chartDataWithColumnName = [
    ["ItemName", "Count"],
    ...chartData,
    otherItems,
  ];

  return chartDataWithColumnName;
};
