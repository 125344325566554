import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFieldArrayReplace, useWatch } from "react-hook-form";
import { useModal } from "../../../../../hooks/use-modal";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckedRoutingModal from "../../../../Table/CheckedRoutingModal";

import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";
import { ICreatedBy, IDefaultForm } from "../../../../../types/global";
import { IBom } from "../../../../../types/Manufacture/bom";
import { IRouting } from "../../../../../types/Manufacture/routing";
import { IManufactureOrder } from "../../../../../types/Manufacture/order";
import { useConfirmation } from "../../../../../hooks/use-confrimation";
import Confirmation from "../../../../UI/Confirmation";
import { addUsersToRelatedEmployee } from "../../../../../utils/Formatter/ManufactureOrder";
// import InventoryService from "../../../../../services/Inventory";

type Props = IDefaultForm & {
  workOrderReplace: UseFieldArrayReplace<IManufactureOrder, "work_order_list">;
};

const RoutingDetail = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  reset,
  workOrderReplace,
}: Props) => {
  const { t } = useTranslation();

  const [routingData, setRoutingData] = useState<IRouting>();
  const [routingIds, setRoutingIds] = useState<string[]>([]);
  const [routingIdsSnapshot, setRoutingIdsSnapshot] = useState<string[]>([]);

  const watchItemId = useWatch({
    control,
    name: "item_unique_id",
  });

  const watchStatus = useWatch({
    control,
    name: "render_status",
  });

  const {
    modal: showRoutingTable,
    openModalHandler: openRoutingTable,
    closeModalHandler: closeRoutingTable,
  } = useModal();

  const watchRoutingId = useWatch({ control, name: "routing_id" });
  const watchRoutingName = useWatch({ control, name: "routing_detail.name" });
  // const watchProductionQty = useWatch({ control, name: "production_qty" });

  useEffect(() => {
    if (!watchStatus && watchRoutingName) {
      setRoutingIds([watchRoutingName]);
      setRoutingIdsSnapshot([watchRoutingName]);
    }
  }, [watchRoutingName, watchStatus]);

  const handleValue = async (data: IRouting) => {
    setValue("routing_id", data.id);
    setValue("routing_detail", data);
    const relatedUsers = getValues(`related_user_list`);
    const createdBy = getValues(`created_by`);

    const { userLists } = data.work_order_list?.reduce(
      (prev: any, curr: any) => {
        if (curr.responsible_user_list && curr.responsible_user_list.length > 0)
          prev.userLists.push(...curr.responsible_user_list);
        return prev;
      },
      {
        userLists: [] as ICreatedBy[],
      }
    );

    addUsersToRelatedEmployee(createdBy, userLists, relatedUsers, setValue);

    workOrderReplace(data?.work_order_list || []);
  };

  const onConfirmChangeBom = async () => {
    if (routingData) {
      const data = routingData;
      handleValue(data);
    }
  };

  const finishSelectHandler = async (data: IRouting) => {
    if (data) {
      if (watchRoutingId) {
        setRoutingData(data);
        openConfirmationHandler();
      } else {
        handleValue(data);
      }
    } else {
      reset &&
        reset((prev: IBom) => ({
          ...prev,
          routing_id: "",
          routing_detail: null,
        }));
    }
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onConfirmChangeBom);

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.routing.index")}
        </Typography>
        {!disabled && watchItemId && (
          <CustomizedTooltip title="เลือกขั้นตอนงาน">
            <IconButton
              onClick={openRoutingTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="routing_detail.name"
            label={t("manufacture.routing.index")}
            control={control}
            error={Boolean(errors?.routing_id)}
            helperText={errors?.routing_id && errors?.routing_id.message}
            disabled
            // sx={{
            //   input: { visibility: disabled ? "hidden" : "visible" },
            // }}
            // InputProps={{
            //   startAdornment: disabled && watchRoutingName && (
            //     <InputAdornment position="start">
            //       <StyledLink onClick={openRoutingForm}>
            //         {watchRoutingName}
            //       </StyledLink>
            //     </InputAdornment>
            //   ),
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       {!disabled && watchRoutingName && (
            //         <CustomizedTooltip title="กดเพื่อดูรายละเอียดสูตรการผลิต">
            //           <IconButton
            //             onClick={openRoutingForm}
            //             sx={{
            //               color: (theme) => theme.palette.grey[500],
            //             }}
            //           >
            //             <LaunchOutlinedIcon />
            //           </IconButton>
            //         </CustomizedTooltip>
            //       )}
            //     </InputAdornment>
            //   ),
            // }}
          />
        </Grid>
        <CheckedRoutingModal
          showSelect={showRoutingTable}
          closeTable={closeRoutingTable}
          finishSelect={finishSelectHandler}
          watchItemId={watchItemId}
          ids={routingIds}
          setIds={setRoutingIds}
          idsSnapshot={routingIdsSnapshot}
          setIdsSnapshot={setRoutingIdsSnapshot}
        />
        <Confirmation
          title="ยืนยันการเปลี่ยนขั้นตอนงาน"
          message="หากเปลี่ยนขั้นตอนงาน รายการงานเดิมจะถูกแทนที่ด้วยรายการใหม่ทั้งหมด"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={submitConfirmationHandler}
        />
      </Grid>
    </Box>
  );
};

export default RoutingDetail;
