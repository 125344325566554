import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const ContactPermission = ({ columnDefs }: Props) => {
  const { t } = useTranslation();
  const gridRef = useRef();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "contact",
        name: t("contact.index"),
        isFeature: true,
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
      },
      {
        functionName: "customer",
        name: t("contact.index") + " (ลูกค้า)",
        edit: "edit",
        delete: "delete",
        feature: "contact",
        isNotViewSelf: true,
      },
      {
        functionName: "vendor",
        name: t("contact.index") + " (ผู้ขาย)",
        edit: "edit",
        delete: "delete",
        feature: "contact",
        isNotViewSelf: true,
      },
      {
        functionName: "contact_report",
        name: t("report"),
        view: "view",
        isNotViewSelf: true,
        feature: "contact",
      },
    ];
    // const filtered = filteredRow(rows, disabled, subtab?.toUpperCase());

    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default ContactPermission;
