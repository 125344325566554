import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const InventoryPermission = ({ columnDefs }: Props) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "inventory",
        name: t("inventory.index"),
        view: "view",
        isFeature: true,
      },
      {
        functionName: "item",
        name: t("inventory.items.index"),
        isNotViewSelf: true,
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
        feature: "inventory",
      },
      {
        functionName: "goods_receive",
        name: t("inventory.goods_receive.index"),
        view: "view",
        create: "create",
        cancel: "cancel",
        feature: "inventory",
      },
      {
        functionName: "goods_return",
        name: t("inventory.goods_return.index"),
        view: "view",
        create: "create",
        feature: "inventory",
      },
      {
        functionName: "goods_issue",
        name: t("inventory.goods_issue.index"),
        view: "view",
        feature: "inventory",
        create: "create",
      },
      {
        functionName: "goods_transfer",
        name: t("inventory.goods_transfer.index"),
        view: "view",
        create: "create",
        feature: "inventory",
      },
      {
        functionName: "goods_adjustment",
        name: t("inventory.goods_adjustment.index"),
        view: "view",
        create: "create",
        feature: "inventory",
      },
      {
        isNotViewSelf: true,
        functionName: "inventory_report",
        name: t("report"),
        view: "view",
        feature: "inventory",
      },
    ];

    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default InventoryPermission;
