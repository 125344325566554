import { Typography, Box, Grid } from "@mui/material";
import { t } from "i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedButton from "../../Custom/CustomizedButton";
import { IComboBoxOption, IDefaultForm } from "../../../types/global";
import EmployeeListForm from "../EmployeeList";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import {
  IFilterBox,
  IProject,
  IStatusOption,
  IProjectWorkflowTemplate,
  IEvent,
} from "../../../types/Project";
import { useProjectStatusOption } from "../../../hooks/Projects/use-project-status-option";
import { useProjectListOption } from "../../../hooks/Projects/use-project-list-option";
import { useProjectTagListOption } from "../../../hooks/Projects/use-project-tag-list-option";
import { useEventStatusFilterOption } from "../../../hooks/Projects/Event/use-event-status-filter-option";
import { useEventListOption } from "../../../hooks/Projects/Event/use-event-list-option";
import { useProjectUniqueIdOption } from "../../../hooks/Projects/use-project-unique-id-option";
import { GraphQLClient } from "graphql-request";
import {
  useProjectTagsQuery,
  ProjectTagsQuery,
} from "../../../generated/project";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

type Props = IDefaultForm & {
  onFilterSubmit: (filter: IFilterBox) => void;
  allStatus?: IProjectWorkflowTemplate[];
  isLoadingAllStatus: boolean;
  allProjects?: IProject[];
  isLoadingAllProjects: boolean;
  onFilterClear: () => void;
  view: string;
  events?: IEvent[];
  isLoadingEvents: boolean;
};

const FilterBox = ({
  control,
  errors,
  setValue,
  getValues,
  handleSubmit,
  onFilterSubmit,
  allStatus,
  isLoadingAllStatus,
  allProjects,
  isLoadingAllProjects,
  onFilterClear,
  view,
  events,
  isLoadingEvents,
}: Props) => {
  const { selectStatusOptions } = useProjectStatusOption(allStatus ?? []);
  const { projectListOptions } = useProjectListOption(allProjects ?? []);
  const { projectUniqueIdOptions } = useProjectUniqueIdOption(
    allProjects ?? []
  );
  const { eventStatusOptions } = useEventStatusFilterOption();
  const { eventListOptions } = useEventListOption(events ?? []);

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { data: projectTags, isLoading: isLoadingProjectTags } =
    useProjectTagsQuery<ProjectTagsQuery>(graphQLClientWithHeaderProject);

  const { projectTagListOptions } = useProjectTagListOption(
    projectTags?.projectTags ?? []
  );

  return (
    <CustomizedBox margin={"0 0 2rem 0 "}>
      <Typography mb={2} fontWeight="bold">
        ตัวกรองแสดงผล
      </Typography>
      <Grid container spacing={2} mb={2} alignItems={"center"}>
        <Grid item>
          <Typography fontWeight="bold">
            {t("project.project.related_user_list")}
          </Typography>
        </Grid>
        <Grid item>
          <EmployeeListForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            disabled={false}
            errors={errors}
            hideCreator
            disabledLockRow
            disabledRemoveCreator
          />
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2}>
            <Controller
              control={control}
              name={"project_name"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  loading={isLoadingAllProjects}
                  label={t("project.project.name")}
                  options={projectListOptions}
                  onChange={(_: any, value: IComboBoxOption) => {
                    if (value) {
                      field.onChange(value.value);

                      if (value.value === "ทั้งหมด") {
                        setValue("project_unique_id", "ทั้งหมด");
                      } else {
                        const projectUniqueId = allProjects?.find(
                          (project) => project.name === value.value
                        )?.unique_id;
                        setValue("project_unique_id", projectUniqueId);
                      }
                    } else {
                      field.onChange(null);
                      setValue("project_unique_id", null);
                    }
                  }}
                />
              )}
            />
          </Grid>
          {view === "calendar" && (
            <Grid item xs={12} sm={12} md={3}>
              <Controller
                control={control}
                name={"event_name"}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    loading={isLoadingEvents}
                    label={t("project.event.event_name")}
                    options={eventListOptions}
                    onChange={(_: any, value: IComboBoxOption) => {
                      if (value) {
                        field.onChange(value.value);
                      } else {
                        field.onChange(null);
                      }
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {view === "calendar" && (
            <Grid item xs={12} sm={12} md={2}>
              <Controller
                control={control}
                name={"status"}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    loading={view === "calendar" ? false : isLoadingAllStatus}
                    label={t("project.project.main_status")}
                    options={
                      view === "calendar"
                        ? eventStatusOptions
                        : selectStatusOptions
                    }
                    onChange={(_: any, value: IStatusOption) => {
                      field.onChange(value ? value.label : "");
                    }}
                    groupBy={(option: IStatusOption) => option.group}
                    error={Boolean(errors.sub_status)}
                    helperText={errors.status?.sub_message}
                    // disabled={disabled}
                  />
                )}
              />
            </Grid>
          )}
          {view === "kanban" && (
            <Grid item xs={12} sm={12} md={2}>
              <Controller
                control={control}
                name={"project_unique_id"}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    loading={isLoadingAllProjects}
                    label={t("project.project.unique_id")}
                    options={projectUniqueIdOptions}
                    onChange={(_: any, value: IComboBoxOption) => {
                      if (value) {
                        field.onChange(value.value);

                        if (value.value === "ทั้งหมด") {
                          setValue("project_name", "ทั้งหมด");
                        } else {
                          const projectName = allProjects?.find(
                            (project) => project.unique_id === value.value
                          )?.name;
                          setValue("project_name", projectName);
                        }
                      } else {
                        field.onChange(null);
                        setValue("project_name", null);
                      }
                    }}
                  />
                )}
              />
            </Grid>
          )}
          {view === "kanban" && (
            <Grid item xs={12} sm={12} md={4}>
              <Controller
                control={control}
                name={"filter_tags"}
                render={({ field }) => {
                  return (
                    <CustomizedComboBox
                      {...field}
                      loading={isLoadingProjectTags}
                      multiple
                      isCheckbox
                      label={"Tags"}
                      options={projectTagListOptions}
                      onChange={(e: any, newValue: any) => {
                        field.onChange(newValue);
                      }}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
            <CustomizedButton
              type="submit"
              title={t("button.filter")}
              variant="contained"
              onClick={handleSubmit && handleSubmit(onFilterSubmit)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} alignSelf="center">
            <CustomizedButton
              title={t("button.reset_filter")}
              variant="outlined"
              onClick={() => onFilterClear()}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </CustomizedBox>
  );
};

export default FilterBox;
