import { useMemo } from "react";
import { useEffect, useState } from "react";
// import { usePermission } from "../use-auth";

export const useModifyOptions = (status?: string | null) => {
  // const { createPermission, editPermission, cancelPermission } =
  //   usePermission();

  const defaultModifyOptions = useMemo(
    () => [
      {
        value: "แก้ไข",
        // disabled: !editPermission,
      },
      {
        value: "คัดลอก",
        // disabled: !createPermission,
      },
      {
        value: "ยกเลิก",
        // disabled: !cancelPermission,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState(defaultModifyOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([
          defaultModifyOptions[2],
          defaultModifyOptions[1],
        ]); //ยกเลิก ,คัดลอก
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultModifyOptions[1]]); //คัดลอก
        break;
      case "finished":
        setSelectedModifyOptions([
          defaultModifyOptions[0],
          defaultModifyOptions[1],
        ]); //แก้ไข , คัดลอก
        break;
      default:
        setSelectedModifyOptions(defaultModifyOptions);
    }
  }, [defaultModifyOptions, status]);

  return selectModifyOptions;
};
