import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Alert,
} from "@mui/material";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSalesOrderOption } from "../../../../hooks/Sales/use-sales-order-option";
import { useSalesOrderCreate } from "../../../../hooks/Sales/use-sales-order-create";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import {
  ICreatedBy,
  IDefaultForm,
  IMenuOption,
} from "../../../../types/global";
import EmployeeListForm from "../../EmployeeList";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { Theme } from "@mui/system";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
} from "@tanstack/react-query";
import {
  DocumentInput,
  Exact,
  ModelType,
  SalesDocumentNextStatusMutation,
  SalesDocumentType,
  SalesOrderQuery,
  SalesOrderUpdateInput,
  SalesOrderUpdateMutation,
  SalesOrderWhereUniqueInput,
  SalesUniqueIdQuery,
  useSalesDocumentCancelMutation,
  useSalesOrderSetTaxInvoiceNumberMutation,
  useSalesUniqueIdQuery,
} from "../../../../generated/sales";
import { useSnackbar } from "notistack";
import {
  copySalesOrderFormatter,
  createDeliveryOrderFromOrder,
  createGoodsIssueFromOrder,
  createManufactureOrderFromOrder,
  createPurchaseRequestFromOrder,
  createSalesReturnFromOrder,
  salesOrderUpdatePayloadFormatter,
} from "../../../../utils/Formatter/SalesOrder";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import { useStateContext } from "../../../../contexts/auth-context";
import SalesEmployeeListForm from "../../EmployeeList/SalesEmployeeList";
import ModalUI from "../../../UI/ModalUI";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { ISalesOrder } from "../../../../types/Sales/salesOrder";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useModal } from "../../../../hooks/use-modal";
import ItemListModal from "../../../Table/ItemListModal";
import { AgGridReact } from "ag-grid-react";
import { ISalesItemList } from "../../../../types/Sales";
import { salesOrderSchema } from "./schema";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

type Props = IDefaultForm & {
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<SalesOrderQuery, unknown>>;
  update: UseMutateFunction<
    SalesOrderUpdateMutation,
    Error,
    Exact<{
      uniqueInput: SalesOrderWhereUniqueInput;
      updateInput: SalesOrderUpdateInput;
    }>,
    unknown
  >;
  updateStatus: UseMutateFunction<
    SalesDocumentNextStatusMutation,
    Error,
    Exact<{
      documentInput: DocumentInput;
    }>,
    unknown
  >;
  taxInvoiceNumber: string | null | undefined;
  setTaxInvoiceNumberLoading: Dispatch<SetStateAction<boolean>>;
};

const taxInvoiceValidation = Yup.object().shape({
  tax_invoice_number: Yup.string()
    .required("กรุณาระบุเลขที่ใบกำกับภาษี")
    .max(15, `กรอกได้ไม่เกิน 15 ตัวอักษร`),
});

const SalesOrderHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  editClickHandler,
  refetch,
  update,
  updateStatus,
  handleSubmit,
  taxInvoiceNumber,
  setTaxInvoiceNumberLoading,
}: Props) => {
  const gridRef = useRef<AgGridReact<ISalesItemList>>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [dueDateIsOpen, setDueDateIsOpen] = useState<boolean>(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState<boolean>(false);

  const {
    control: taxInvoiceControl,
    handleSubmit: taxInvoiceHandleSubmit,
    formState: { errors: taxInvoiceErrors },
  } = useForm({
    defaultValues: {
      tax_invoice_number: "",
    },
    resolver: yupResolver(taxInvoiceValidation),
  });

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { refetch: getUniqueId } = useSalesUniqueIdQuery<SalesUniqueIdQuery>(
    graphQLClientWithHeaderSales,
    {
      modelType: ModelType.SalesOrder,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { mutate: cancel } = useSalesDocumentCancelMutation<Error>(
    graphQLClientWithHeaderSales,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: setTaxInvoiceNumber } =
    useSalesOrderSetTaxInvoiceNumberMutation<Error>(
      graphQLClientWithHeaderSales,
      {
        onMutate: () => {
          setTaxInvoiceNumberLoading(true);
        },
        onSuccess: () => {
          enqueueSnackbar(`บันทึกใบกำกับภาษีสำเร็จ`, {
            variant: "success",
          });
          closeTaxInvoiceNumberModal();
          if (id) {
            refetch();
          }
        },
        onError: (error: any) => {
          const errorMessages = error.response.errors.map(
            (err: any) => err.message
          );
          let snackbarMessage: string;
          if (
            errorMessages.includes(
              "This sales order's tax invoice ID has already been set"
            )
          ) {
            snackbarMessage = "ใบสั่งขายนี้มีใบกำกับภาษีแล้ว";
          } else if (
            errorMessages.includes("This sales order has been cancelled")
          ) {
            snackbarMessage = "ใบสั่งขายนี้ถูกยกเลิกไปแล้ว";
          } else {
            snackbarMessage = "บันทึกใบกำกับภาษีไม่สำเร็จ";
          }
          enqueueSnackbar(snackbarMessage, {
            variant: "error",
          });
        },
        onSettled: () => {
          setTaxInvoiceNumberLoading(false);
        },
      }
    );

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_unique_id_list = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("sales.order.index")}`,
      disabled: !id,
    },
    {
      value: t("global.custom_document_print"),
      disabled: !id,
    },
  ];

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },
    {
      label: t("status.wait_approve"),
      value: "wait_approve",
    },
    {
      label: t("status.approved"),
      value: "approved",
    },
    {
      label: t("status.finished"),
      value: "finished",
    },
  ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(status, watchMainStatus, SalesDocumentType.SalesOrder);

  const selectModifyOptions = useSalesOrderOption(status, taxInvoiceNumber);
  const { selectCreateOptions, disabledCreateOptions } =
    useSalesOrderCreate(status);

  const {
    modal: taxInvoiceNumberModal,
    openModalHandler: openTaxInvoiceNumberModal,
    closeModalHandler: closeTaxInvoiceNumberModal,
  } = useModal();

  const cancelDocumentHandler = () => {
    cancel({
      documentInput: {
        reference_document_type: SalesDocumentType.SalesOrder,
        unique_id: id ? id : "",
      },
    });
  };

  const copyDocumentHandler = () => {
    const salesOrder = getValues();
    navigate("/sales/order/add", {
      state: copySalesOrderFormatter(salesOrder),
    });
  };

  const saveInvoiceHandler = (data: ISalesOrder) => {
    const payload = salesOrderUpdatePayloadFormatter(
      data,
      "finished"
    ) as SalesOrderUpdateInput;
    // setSnackbarMessage("อนุมัติ");
    update({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: payload,
    });
    updateStatus({
      documentInput: {
        reference_document_type: SalesDocumentType.SalesOrder,
        unique_id: data.unique_id,
      },
    });
  };

  const taxInvoiceNumberHandler = (data: { tax_invoice_number: string }) => {
    setTaxInvoiceNumber({
      uniqueInput: {
        unique_id: id,
      },
      taxInvoiceNumber: data.tax_invoice_number,
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    confirmation: saveInvoiceModal,
    openConfirmationHandler: openSaveInvoiceModal,
    closeConfirmationHandler: closeSaveInvoiceModal,
    submitConfirmationHandler: submitSaveInvoiceModal,
  } = useConfirmation(
    handleSubmit ? handleSubmit(saveInvoiceHandler) : undefined
  );

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const selectedItemToMo = () => {
    const salesOrder = getValues();
    const selectedItems = gridRef?.current?.api.getSelectedRows();
    const currentUser: ICreatedBy = {
      user_unique_id: authUser?.unique_id || "",
      email: authUser?.email || "",
      first_name: authUser?.first_name || "",
      last_name: authUser?.last_name,
      img_url: authUser?.img_url,
    };

    localStorage.setItem(
      "mo state",
      JSON.stringify(
        createManufactureOrderFromOrder(
          salesOrder,
          selectedItems?.[0] || salesOrderSchema.item_list?.[0],
          currentUser
        )
      )
    );
    
    window.open("/manufacture/order/add", "_blank"); 
    closeModalHandler();
  };

  return (
    <Fragment>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{t("sales.order.index")}</Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1}>
            <Grid item xs={5.5} sm={5.5} md={5.5} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;

                  if (value.innerText === "พิมพ์ใบสั่งขาย") {
                    navigate(
                      `/sales/order/${encodeURIComponent(id ?? "")}/pdf`
                    );
                  }
                  if (value.innerText === "พิมพ์รูปแบบกำหนดเอง") {
                    navigate(
                      `/sales/order/${encodeURIComponent(id ?? "")}/custom-pdf`
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={5.5} lg={4} xl={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    case "บันทึกใบแจ้งหนี้":
                      openSaveInvoiceModal();
                      break;
                    case "บันทึกใบกำกับภาษี":
                      openTaxInvoiceNumberModal();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={5.5} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  const salesOrder = getValues();
                  const currentUser: ICreatedBy = {
                    user_unique_id: authUser?.unique_id || "",
                    email: authUser?.email || "",
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name,
                    img_url: authUser?.img_url,
                  };
                  switch (value.innerText) {
                    case "ใบขอซื้อ":
                      navigate("/purchase/request/add", {
                        state: createPurchaseRequestFromOrder(salesOrder),
                      });
                      break;
                    case "ใบสั่งผลิต":
                      openModalHandler();

                      break;
                    case "ใบส่งของ":
                      navigate("/logistic/delivery_order/add", {
                        state: createDeliveryOrderFromOrder(salesOrder),
                      });
                      break;
                    case "ใบนำออก":
                      navigate("/inventory/goods_issue/add", {
                        state: createGoodsIssueFromOrder(
                          salesOrder,
                          currentUser
                        ),
                      });
                      break;
                    case "ใบรับคืน":
                      navigate("/sales/return/add", {
                        state: createSalesReturnFromOrder(salesOrder),
                      });
                      break;
                    default:
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">{t("sales.unique_id")}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={Boolean(id)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              {reference_unique_id_list && reference_unique_id_list.length > 0
                ? reference_unique_id_list.map(
                    (reference_unique_id: string) => (
                      <CustomizedChips
                        key={reference_unique_id}
                        onClick={() =>
                          window.open(
                            `/sales/quotation/${encodeURIComponent(
                              reference_unique_id
                            )}`,
                            "_blank"
                          )
                        }
                        value={reference_unique_id}
                        color="primary"
                      />
                    )
                  )
                : "-"}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            {taxInvoiceNumber != null && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <Typography fontWeight="bold">
                    {t("sales.tax_invoice_number")}
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
                  <CustomizedTextField value={taxInvoiceNumber} disabled />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("sales.sales_contact_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <SalesEmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="due_date"
                control={control}
                error={errors.due_date}
                open={dueDateIsOpen}
                onClose={() => setDueDateIsOpen(false)}
                onOpen={() => setDueDateIsOpen(true)}
                label={t("date.due_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                error={errors.delivery_date}
                open={deliveryDateIsOpen}
                onClose={() => setDeliveryDateIsOpen(false)}
                onOpen={() => setDeliveryDateIsOpen(true)}
                label={t("date.delivery_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 0.5 }}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label={t("sales.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
                onChange={(e, field) => {
                  const parseIntValue = parseInt(e.target.value);
                  field.onChange(parseIntValue);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
      <ModalUI
        title="บันทึกใบแจ้งหนี้"
        open={saveInvoiceModal}
        handleClose={closeSaveInvoiceModal}
        maxWidth="xs"
        fullWidth
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closeSaveInvoiceModal}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={submitSaveInvoiceModal}
            />
          </Box>
        }
      >
        <ControlledTextField
          label={t("sales.external_reference_id")}
          control={control}
          name="external_reference_id_confirmation"
        />
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Alert severity="info" style={{ backgroundColor: "#e5f6fd" }}>
            หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
          </Alert>
        </Box>
      </ModalUI>
      <ModalUI
        title="บันทึกใบกำกับภาษี"
        open={taxInvoiceNumberModal}
        handleClose={closeTaxInvoiceNumberModal}
        maxWidth="xs"
        fullWidth
        action={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
            }}
          >
            <CustomizedButton
              title="ยกเลิก"
              variant="outlined"
              onClick={closeTaxInvoiceNumberModal}
            />
            <CustomizedButton
              title="ยืนยัน"
              variant="contained"
              onClick={taxInvoiceHandleSubmit(taxInvoiceNumberHandler)}
            />
          </Box>
        }
      >
        <ControlledTextField
          label={t("sales.tax_invoice_number")}
          control={taxInvoiceControl}
          name="tax_invoice_number"
          error={Boolean(taxInvoiceErrors?.tax_invoice_number)}
          helperText={
            taxInvoiceErrors?.tax_invoice_number &&
            taxInvoiceErrors?.tax_invoice_number.message
          }
          required
        />
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Alert severity="info" style={{ backgroundColor: "#e5f6fd" }}>
            หากบันทึกแล้วจะไม่สามารถเปลี่ยนแปลงได้
          </Alert>
        </Box>
      </ModalUI>
      <ItemListModal
        gridRef={gridRef}
        itemModal={modal}
        closeItemModalHandler={closeModalHandler}
        addItemsHandler={selectedItemToMo}
        itemList={getValues("item_list")}
        documentType="item"
        disabled={disabled}
      />
    </Fragment>
  );
};

export default SalesOrderHeader;
