import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CheckboxModalTable from "./CheckboxModalTable";
import { useTranslation } from "react-i18next";
import { GetRowIdParams } from "ag-grid-community";
import { userColumnDefs } from "./User/columnDefs";
import { IUser, IUserArray, IUserInfo } from "../../types/Auth/user";
import { UsersQuery, useUsersQuery } from "../../generated/company-user";
import {
  UserInformationsQuery,
  useUserInformationsQuery,
} from "../../generated/user-infomation";
import { mergeUsersFormatter } from "../../utils/Formatter/User";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

type CheckedUserModalProps = {
  title?: string;
  showSelectUser: boolean;
  closeUserTable: () => void;
  finishUsersSelect: (data: any) => void;
  userIds: string[];
  setUserIds: Dispatch<SetStateAction<string[]>>;
  userIdsSnapshot: string[];
  setUserIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  lockRows?: (string | undefined)[];
  rowSelection?: "single" | "multiple";
};

const CheckedUserModal = ({
  showSelectUser,
  closeUserTable,
  finishUsersSelect,
  userIds,
  setUserIds,
  userIdsSnapshot,
  setUserIdsSnapshot,
  lockRows,
  rowSelection,
  title,
}: CheckedUserModalProps) => {
  const selectUserGridref = useRef();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<IUserArray>([]);

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { isSuccess: isSuccessUser, data: userData } =
    useUsersQuery<UsersQuery>(graphQLClientWithHeaderCompany);

  const { isSuccess: isSuccessUserInfo, data: userInfoData } =
    useUserInformationsQuery<UserInformationsQuery>(
      graphQLClientWithHeaderSetting
    );

  useEffect(() => {
    if (isSuccessUser && isSuccessUserInfo) {
      const mergeData = mergeUsersFormatter(
        userData.users as IUser[],
        userInfoData.userInformations as IUserInfo[]
      );
      setRowData(mergeData);
    }
  }, [isSuccessUser, isSuccessUserInfo, userData, userInfoData]);

  // const datasource = {
  //   async getRows(params: IServerSideGetRowsParams) {
  //     const { request } = params;
  //     const { startRow, endRow, filterModel, sortModel } = request;
  //     const formatFilter = {
  //       ...filterModel,
  //     };
  //     try {
  //       const { usersFindManyAggrid } = await graphQLClient.request(
  //         USERS_AGGRID,
  //         {
  //           aggridInput: {
  //             startRow,
  //             endRow,
  //             filterModel: formatFilter,
  //             sortModel,
  //           },
  //         }
  //       );
  //       params.success({
  //         rowData: usersFindManyAggrid.data as any[],
  //         rowCount: usersFindManyAggrid.count as number,
  //       });
  //     } catch (err) {
  //       params.fail();
  //     }
  //   },
  // };

  // const onGridReady = (params: GridReadyEvent) => {
  //   params.api.setServerSideDatasource(datasource);
  // };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxModalTable
      modalTitle={title ?? t("sales.employee_list")}
      btnTitle={t("button.add")}
      gridRef={selectUserGridref}
      height={450}
      columnDefs={userColumnDefs(t)}
      rowSelection={rowSelection}
      onFinishEditing={finishUsersSelect}
      modalIsOpen={showSelectUser}
      getRowId={getRowId}
      closeModal={closeUserTable}
      selectedIds={userIds}
      setSelectedIds={setUserIds}
      idsSnapshot={userIdsSnapshot}
      setIdsSnapshot={setUserIdsSnapshot}
      lockRows={lockRows}
      // onGridReady={onGridReady}
      rowData={rowData}
    />
  );
};

export default CheckedUserModal;
