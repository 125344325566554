import * as Yup from "yup";
import { IRole } from "../../../types/Auth/user";
import { defaultPermission } from "../../../utils/userInfo";

export const rbacSchema: IRole = {
  name: "",
  description: "",
  permission_list: defaultPermission,
  user_unique_id_list: [],
  user_list: [],
};

export const validation = Yup.object().shape({
  name: Yup.string().required("กรุณากรอก"),
});
