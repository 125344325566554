import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm, IMenuOption } from "../../../../types/global";
import { useTranslation } from "react-i18next";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import { useEffect, useState } from "react";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { useStateContext } from "../../../../contexts/auth-context";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  GoodsReceiveQuery,
  InventoryDocumentType,
  useInventoryDocumentCancelMutation,
} from "../../../../generated/inventory";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useActiveStep } from "../../../../hooks/use-active-step";
import StepperUI from "../../../UI/StepperUI";

type Props = IDefaultForm & {
  status?: string | null;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsReceiveQuery, unknown>>;
};

const GoodsReturnHeader = ({
  control,
  errors,
  setValue,
  disabled,
  status,
  refetch,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [returnedDateIsOpen, setReturnedDateIsOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const watchCreatedBy = useWatch({
    control,
    name: "created_by",
  });

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },

    {
      label: t("status.finished"),
      value: "finished",
    },
  ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(status, watchMainStatus, InventoryDocumentType.GoodsReturn);

  useEffect(() => {
    if (!id && authUser) {
      setValue("created_by", {
        user_unique_id: authUser?.unique_id,
        email: authUser?.email,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
        img_url: authUser?.img_url,
      });
    }
  }, [authUser, setValue, id]);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { mutate: cancel } = useInventoryDocumentCancelMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const cancelDocumentHandler = () => {
    cancel({
      documentInput: {
        reference_document_type: InventoryDocumentType.GoodsReceive,
        unique_id: id ? id : "",
      },
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const options: IMenuOption[] = [
    {
      value: t("status.cancelled"),
      disabled: false,
    },
  ];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  console.log(openDrawer);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="h5">
            {`${t("button.create")}${t("inventory.goods_return.index")}`}
          </Typography>
          <CustomizedStatus status={status} />
        </Box>

        <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
          <IconButton
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <RestoreOutlinedIcon />
          </IconButton>
        </CustomizedTooltip>
      </Box>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography fontWeight="bold">
              {t("sentence.created_by")}
            </Typography>
            <CustomizedTooltip
              title={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
              enterNextDelay={200}
            >
              {watchCreatedBy?.img_url && watchCreatedBy?.img_url.length > 0 ? (
                <Avatar
                  alt={`avatar`}
                  src={
                    watchCreatedBy?.img_url ? watchCreatedBy?.img_url[0] : ""
                  }
                />
              ) : (
                <CustomizedLetterAvatar
                  name={`${watchCreatedBy?.first_name} ${watchCreatedBy?.last_name}`}
                />
              )}
            </CustomizedTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid
            container
            spacing={2}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="posted_date"
                control={control}
                error={errors.posted_date}
                open={returnedDateIsOpen}
                onClose={() => setReturnedDateIsOpen(false)}
                onOpen={() => setReturnedDateIsOpen(true)}
                label={t("date.returned_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={t("button.option")}
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("status.cancelled")}`:
                      openConfirmationHandler();
                      break;
                    default:
                  }
                }}
                disabled={!id || disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default GoodsReturnHeader;
