import { Box, Typography, CircularProgress, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GeneralForm from "../../../components/Form/Company/GeneralForm";
import { IBreadcrumbsAndMenu, IMenuOption } from "../../../types/global";
import ChannelForm from "../../../components/Form/Company/ChannelForm";
import {
  companyValidation,
  schema,
} from "../../../components/Form/Company/schema";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CompanyAddressForm from "../../../components/Form/Company/CompanyAddressForm";
import { ICompany } from "../../../types/Setting/company";
import {
  CompanyQuery,
  useCompanyQuery,
  useCompanyUpdateMutation,
} from "../../../generated/company-user";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { uploadFileToS3 } from "../../../utils/s3";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

const Company = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.company.index"),
      to: "/setting/company",
    },
  ];

  const tenantId = sessionStorage.getItem("tenant-id");

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICompany>({
    defaultValues: { ...schema },
    resolver: yupResolver(companyValidation),
  });

  // useEffect(() => {
  //   if (!id) {
  //     setDisabled(true);
  //   }
  // }, [id]);

  const options: IMenuOption[] = [
    {
      value: "แก้ไข",
      disabled: false,
    },
  ];

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isLoading, isSuccess } = useCompanyQuery<CompanyQuery>(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: {
        unique_id: tenantId,
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const { company } = data;
      const companyType = company as ICompany;

      reset(companyType);
    }
  }, [data, isSuccess, reset]);

  const { isLoading: isUpdating, mutate: updateCompany } =
    useCompanyUpdateMutation<Error>(graphQLClientWithHeaderCompany, {
      onSuccess: () => {
        enqueueSnackbar("แก้ไขข้อมูลบริษัทสำเร็จ", {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("แก้ไขข้อมูลบริษัทไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const onCompanySubmit = async (data: ICompany) => {
    const {
      img_url,
      expired_date,
      owner_email,
      resubscription_date,
      subscription_level_id,
      unique_id,
      last_active_date,
      ...payload
    } = data;

    let current_img: string[] = [];
    if (data.img_url && data.img_url.length > 0) {
      const filteredFile = data.img_url.filter(
        (img: string | File) => img instanceof File
      );

      if (filteredFile && filteredFile.length > 0) {
        const { Location } = await uploadFileToS3(
          filteredFile[0],
          "company",
          tenantId || ""
        );
        current_img.push(Location);
      } else {
        current_img = data.img_url;
      }
    }
    updateCompany({
      companyUpdateUniqueInput: {
        unique_id: tenantId,
      },
      updateInput: {
        ...payload,
        address_list: payload.address_list.map((address, index) => {
          if (index === 0) return { ...address, is_default: true };
          else return address;
        }),
        img_url: current_img,
      },
    });
    setDisabled(true);
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">{t("setting.company.index")}</Typography>
          <CustomizedMenuOptions
            size="medium"
            label={t("button.option")}
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `แก้ไข`:
                  setDisabled(false);
                  break;
                default:
              }
            }}
            disabled={!disabled}
          />
        </Box>
        <form
          onSubmit={handleSubmit(onCompanySubmit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <GeneralForm
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
          />
          <ChannelForm
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
          />
          <CompanyAddressForm
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
          />
          {!disabled && (
            <BottomNavbar>
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  title={t("button.cancel")}
                  variant="outlined"
                  onClick={cancelEditHandler}
                  disabled={isUpdating}
                />
                <CustomizedButton
                  title={t("button.save")}
                  type="submit"
                  variant="contained"
                  disabled={isUpdating}
                />
              </Stack>
            </BottomNavbar>
          )}
        </form>
      </Box>
    </>
  );
};

export default Company;
