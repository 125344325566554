import { useEffect, useRef, useState } from "react";

const useBarcodeScanner = (
  onBarcodeSubmitHandler: (barcode?: string) => void
) => {
  const scrollRef = useRef<HTMLTableElement>(null);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [scanData, setScanData] = useState<string>("");
  const [scanDataCurr, setScanDataCurr] = useState<string>("");
  const [isInterval, setIsInterval] = useState<boolean>(false);

  useEffect(() => {
    if (isInterval && scanData !== scanDataCurr) {
      setScanDataCurr(scanData);
      onBarcodeSubmitHandler(scanData);
      setTimeout(() => {
        setIsInterval(false);
        setScanDataCurr("");
      }, 2000);
    }
  }, [isInterval, onBarcodeSubmitHandler, scanData, scanDataCurr]);

  useEffect(() => {
    const autoCameraCloseHandler = () => {
      let scrolled = window.scrollY;

      if (scrolled >= 450) {
        setShowCamera(false);
      }
    };

    if (scrollRef && scrollRef.current) {
      window.addEventListener("scroll", autoCameraCloseHandler, false);
      return () => {
        window.removeEventListener("scroll", autoCameraCloseHandler, false);
      };
    }
  }, []);

  return {
    scrollRef,
    showCamera,
    setShowCamera,
    scanData,
    setScanData,
    scanDataCurr,
    setScanDataCurr,
    isInterval,
    setIsInterval,
  };
};

export default useBarcodeScanner;
