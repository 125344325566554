import dayjs from "dayjs";
import {
  InventoryDocumentStatus,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  WarehouseBinLocation,
} from "../../generated/inventory";
import { IUser } from "../../types/Auth/user";
import { ITraceEntry } from "../../types/Inventory";
import { IGoodsTransfer } from "../../types/Inventory/goodsTransfer";
import { v4 as uuidv4 } from "uuid";

export const goodsTransferCreatePayloadFormatter = (data: IGoodsTransfer) => {
  const {
    created_date,
    main_status,
    aggrid_status,
    trace_entry_list,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            source_bin_location,
            destination_bin_location,
            all_bin_locations,
            ...serial
          }) => ({
            ...serial,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            uom_unique_id: uom?.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          all_bin_locations,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push(trace);
    }
  });

  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };
  return payload;
};

const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const goodsTransferQueryFormatter = (
  data: IGoodsTransfer,
  allSkuQtys?: ItemSkuQtysQuery
) => {
  if (allSkuQtys?.itemSkuQtys) {
    const { trace_entry_list, ...otherData } = data;
    const groupedTrace = groupBy(trace_entry_list, "item_unique_id");

    const allTraceEntries: ITraceEntry[] = [];
    for (const entry of Object.entries(groupedTrace)) {
      const key = entry[0] as string;
      const value = entry[1] as ITraceEntry[];

      const foundAllSku = allSkuQtys.itemSkuQtys.filter(
        (sku) => sku?.barcode === value[0].barcode
      );

      const allBinLocation = foundAllSku.map((sku) => ({
        id: sku?.bin_id,
        bin_name: sku?.bin_location?.bin_name,
        stock_qty: sku?.stock_qty,
        warehouse_unique_id:
          sku?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse_unique_id,
      }));

      const filterBinLocations = allBinLocation.filter(
        (bin) =>
          bin.warehouse_unique_id === otherData.source_warehouse_unique_id
      );

      const formatSerial = value.map((serial) => ({
        ...serial,
        posted_date: serial.posted_date ?? undefined,
        scanned_by: serial.scanned_by ?? undefined,
        all_bin_locations: filterBinLocations,
      }));

      const formatTrace: ITraceEntry = {
        item_unique_id: key,
        item_name: value[0].item_name,
        item_img_url: value[0].item_img_url,
        tracability: value[0].tracability,
        type: value[0].type,
        unique_id: value[0].unique_id,
        qty: value.reduce((prev, curr) => prev + curr.qty, 0),
        posted_date:
          value[0].tracability === Tracability.Normal
            ? value[0].posted_date
            : undefined,
        created_date: value[0].created_date,
        uom: value[0].uom,
        source_bin_location:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location
            : undefined,
        source_bin_location_id:
          value[0].tracability === Tracability.Normal
            ? value[0].source_bin_location_id
            : undefined,
        barcode:
          value[0].tracability === Tracability.Normal
            ? value[0].barcode
            : undefined,
        serial_list: value.some(
          (serial) => serial.tracability === Tracability.Normal
        )
          ? undefined
          : formatSerial,
        status:
          value[0].tracability === Tracability.Normal
            ? value[0].status
            : undefined,
        scanned_by:
          value[0].tracability === Tracability.Normal
            ? value[0].scanned_by ?? undefined
            : undefined,
        destination_bin_location:
          value[0].tracability === Tracability.Normal
            ? value[0].destination_bin_location
            : undefined,
        destination_bin_location_id:
          value[0].tracability === Tracability.Normal
            ? value[0].destination_bin_location_id
            : undefined,
        destination_scanned_date:
          value[0].tracability === Tracability.Normal
            ? value[0].destination_scanned_date
            : undefined,
        destination_scanned_by:
          value[0].tracability === Tracability.Normal
            ? value[0].destination_scanned_by ?? undefined
            : undefined,
        all_bin_locations: filterBinLocations,
      };
      allTraceEntries.push(formatTrace);
    }

    return {
      ...otherData,
      trace_entry_list: allTraceEntries,
    };
  }
};

export const goodsTransferTraceEntryFormatter = (
  trace: ITraceEntry,
  binLocations: (WarehouseBinLocation & {
    warehouse_unique_id: string;
    stock_qty?: number;
  })[],
  user: IUser | null
) => {
  const {
    type,
    posted_date,
    created_date,
    scanned_by,
    unique_id,
    status,
    ...otherPayload
  } = trace;
  if (user) {
    const formatTrace: ITraceEntry = {
      ...otherPayload,
      unique_id: uuidv4(),
      type: InventoryDocumentType.GoodsTransfer,
      status: InventoryDocumentStatus.IsActive,
      scanned_by: {
        user_unique_id: user.unique_id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        img_url: user.img_url,
      },
      posted_date: dayjs().toDate(),
      source_bin_location: binLocations[0],
      source_bin_location_id: binLocations[0].id,
      qty: binLocations[0].stock_qty || 0,
      all_bin_locations: binLocations,
    };
    return formatTrace;
  }
  return;
};

export const goodsTransferUpdatePayloadFormatter = (data: IGoodsTransfer) => {
  const {
    created_date,
    unique_id,
    main_status,
    aggrid_status,
    source_warehouse,
    destination_warehouse,
    trace_entry_list,
    created_by,
    ...otherPayload
  } = data;
  let allTraceEntries: ITraceEntry[] = [];

  trace_entry_list.forEach((trace) => {
    if (trace.serial_list) {
      if (trace.serial_list.length > 0) {
        const formatSerialList = trace.serial_list.map(
          ({
            uom,
            created_date,
            source_bin_location,
            destination_bin_location,
            destination_scanned_by,
            destination_scanned_date,
            all_bin_locations,
            ...serial
          }) => ({
            ...serial,
            destination_scanned_by: destination_scanned_by ?? undefined,
            destination_scanned_date: destination_scanned_date ?? undefined,
            qty:
              typeof serial.qty === "string"
                ? parseInt(serial.qty)
                : serial.qty,
            uom_unique_id: uom?.unique_id,
          })
        );
        allTraceEntries.push(...formatSerialList);
      } else {
        const {
          serial_list,
          uom,
          source_bin_location,
          destination_bin_location,
          destination_scanned_by,
          destination_scanned_date,
          created_date,
          all_bin_locations,
          ...otherTrace
        } = trace;
        const formatTrace = {
          ...otherTrace,
          destination_scanned_by: destination_scanned_by ?? undefined,
          destination_scanned_date: destination_scanned_date ?? undefined,
          qty:
            typeof otherTrace.qty === "string"
              ? parseInt(otherTrace.qty)
              : otherTrace.qty,
          uom_unique_id: uom?.unique_id,
        };
        allTraceEntries.push(formatTrace);
      }
    } else {
      allTraceEntries.push(trace);
    }
  });
  const payload = {
    ...otherPayload,
    trace_entry_list: allTraceEntries,
  };
  return payload;
};
