import { Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {
  allowedPermissions: boolean[];
};

const RequirePermission = ({ allowedPermissions }: Props) => {
  const location = useLocation();

  return allowedPermissions.some((permission) => permission) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};
export default RequirePermission;
