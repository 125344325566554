import { Box } from "@mui/system";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const ButtonLayout = ({ children }: Props) => {
  return <Box sx={{ display: "flex", gap: 1 }}>{children}</Box>;
};

export default ButtonLayout;
