import { useCallback, useEffect, useMemo, useState } from "react";
import { IComboBoxOption } from "../../types/global";
import { useTranslation } from "react-i18next";
import { EntityTypeEnum } from "../../generated/creatable";

const quotationStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "wait_accept",
  "accepted",
  "finished",
  "cancelled",
  "expired",
];

const salesOrderStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "finished",
  "cancelled",
  "expired",
];

const salesReturnStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "finished",
  "cancelled",
  "expired",
];

const purchaseOrderStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "partially_imported",
  "fully_imported",
  "expired",
];

const purchaseReturnStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "finished",
  "cancelled",
];

const purchaseRequestStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "partially_ordered",
  "fully_ordered",
  "expired",
];

const purchaseDocumentsStatus = [
  "draft",
  "wait_approve",
  "not_approved",
  "approved",
  "partially_imported",
  "fully_imported",
  "partially_ordered",
  "fully_ordered",
  "expired",
  "cancelled",
];

const DeliveryOrderStatus = [
  "draft",
  "wait_deliver",
  "delivering",
  "completed",
  "not_completed",
  "cancelled",
];

const ManufactureOrderStatus = [
  "draft",
  "pending_manu",
  "manufacturing",
  "finished",
  "cancelled",
];

export const useProjectDocumenStatusOption = (
  tab?: string,
  document?: EntityTypeEnum
) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<IComboBoxOption[]>([]);

  const generateOptions = useCallback(
    (optionValues: string[]) => {
      const allOption = { id: "ALL", label: "ทั้งหมด", value: "ALL" };
      const otherOptions = optionValues.map((value, index) => {
        return { id: index, label: t(`status.${value}`), value: value };
      });
      return [allOption, ...otherOptions];
    },
    [t]
  );

  const quotationStatusOptions = useMemo(
    () => generateOptions(quotationStatus),
    [generateOptions]
  );

  const salesOrderStatusOptions = useMemo(
    () => generateOptions(salesOrderStatus),
    [generateOptions]
  );
  const salesReturnStatusOptions = useMemo(
    () => generateOptions(salesReturnStatus),
    [generateOptions]
  );

  const purchaseRequestStatusOptions = useMemo(
    () => generateOptions(purchaseRequestStatus),
    [generateOptions]
  );

  const purchaseOrderStatusOptions = useMemo(
    () => generateOptions(purchaseOrderStatus),
    [generateOptions]
  );

  const purchaseReturnStatusOptions = useMemo(
    () => generateOptions(purchaseReturnStatus),
    [generateOptions]
  );

  const purchaseDocumentsStatusOptions = useMemo(
    () => generateOptions(purchaseDocumentsStatus),
    [generateOptions]
  );

  const deliveryOrderStatusOptions = useMemo(
    () => generateOptions(DeliveryOrderStatus),
    [generateOptions]
  );

  const deliveryTripStatusOptions = useMemo(
    () => generateOptions(DeliveryOrderStatus),
    [generateOptions]
  );

  const manufactureOrderStatusOptions = useMemo(
    () => generateOptions(ManufactureOrderStatus),
    [generateOptions]
  );

  useEffect(() => {
    switch (tab) {
      case "sales":
        switch (document) {
          case EntityTypeEnum.Quotation:
            return setOptions(quotationStatusOptions);
          case EntityTypeEnum.SalesOrder:
            return setOptions(salesOrderStatusOptions);
          case EntityTypeEnum.SalesReturn:
            return setOptions(salesReturnStatusOptions);
        }
        return setOptions(quotationStatusOptions);
      case "purchase":
        switch (document) {
          case EntityTypeEnum.PurchaseRequest:
            return setOptions(purchaseRequestStatusOptions);
          case EntityTypeEnum.PurchaseOrder:
            return setOptions(purchaseOrderStatusOptions);
          case EntityTypeEnum.PurchaseReturn:
            return setOptions(purchaseReturnStatusOptions);
        }
        return setOptions(purchaseDocumentsStatusOptions);
      case "logistic":
        switch (document) {
          case EntityTypeEnum.DeliveryOrder:
            return setOptions(deliveryOrderStatusOptions);
          case EntityTypeEnum.DeliveryTrip:
            return setOptions(deliveryTripStatusOptions);
        }
        return setOptions(deliveryOrderStatusOptions);
      case "manufacture":
        return setOptions(manufactureOrderStatusOptions);
      default:
        setOptions([]);
        break;
    }
  }, [
    tab,
    document,
    quotationStatusOptions,
    purchaseOrderStatusOptions,
    deliveryOrderStatusOptions,
    manufactureOrderStatusOptions,
    salesOrderStatusOptions,
    salesReturnStatusOptions,
    purchaseRequestStatusOptions,
    purchaseReturnStatusOptions,
    deliveryTripStatusOptions,
    purchaseDocumentsStatusOptions,
  ]);

  return options;
};
