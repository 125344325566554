import { Dispatch, SetStateAction } from "react";
import { useAddressOptions } from "../../../hooks/use-address-options";
import {
  IDefaultForm,
  IDeliveryAddress,
  IModalUI,
} from "../../../types/global";
import { IQuotation } from "../../../types/Sales/quotation";
import { IAddress } from "../../../types/Setting/company";
import ModalUI from "../../UI/ModalUI";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckbox";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import addressData from "../../../data/address.json";
import CustomizedButton from "../../Custom/CustomizedButton";
import { ISalesOrder } from "../../../types/Sales/salesOrder";
import { ISalesReturn } from "../../../types/Sales/salesReturn";

type DeliveryAddressProps = IModalUI &
  IDefaultForm & {
    deliveryAddressSnapshot: IDeliveryAddress | null;
    setDeliveryAddressSnapshot: Dispatch<
      SetStateAction<IDeliveryAddress | null>
    >;
    allAddressList: IAddress[] | null | undefined;
  };

const countryOptions = ["ไทย"];

const DeliveryAddressForm = ({
  control,
  errors,
  open,
  handleClose,
  setValue,
  getValues,
  disabled,
  reset,
  deliveryAddressSnapshot,
  setDeliveryAddressSnapshot,
  allAddressList,
}: DeliveryAddressProps) => {
  const { t } = useTranslation();
  const {
    subDistrictOptions,
    setSubDistrictOptions,
    districtOptions,
    provinceOptions,
    setSearchSubDistrict,
    setSearchDistrict,
    setSearchProvince,
    updateFilter,
    isSameAddress,
  } = useAddressOptions(control);

  const autofillAddress = () => {
    const billing_address = getValues("customer_contact.billing_address");
    const delivery_address = getValues("customer_contact.delivery_address");

    const updatedAddress = {
      is_default: false,
      is_same_as_default_address: true,
      address_type: delivery_address?.address_type,
      address_contact_name: delivery_address?.address_contact_name,
      address_contact_phone: delivery_address?.address_contact_phone,
      address: billing_address?.address,
      sub_district: billing_address?.sub_district,
      district: billing_address?.district,
      province: billing_address?.province,
      postal_code: billing_address?.postal_code,
      country: billing_address?.country,
    };

    if (reset) {
      reset((prev: IQuotation | ISalesOrder | ISalesReturn) => ({
        ...prev,
        customer_contact: {
          ...prev.customer_contact,
          delivery_address: { ...updatedAddress },
        },
      }));
    }
  };

  const resetSearchOptions = () => {
    setSearchSubDistrict(undefined);
    setSearchDistrict(undefined);
    setSearchProvince(undefined);
  };

  const closeDeliveryAddressForm = (e: any) => {
    if (reset) {
      reset((prev: IQuotation | ISalesOrder | ISalesReturn) => ({
        ...prev,
        customer_contact: {
          ...prev.customer_contact,
          delivery_address: deliveryAddressSnapshot,
        },
      }));
    }
    if (handleClose) {
      handleClose(e);
    }
    resetSearchOptions();
  };

  const deliverySubmitHandler = (e: any) => {
    const currentDeliveryAddress: IDeliveryAddress = getValues(
      "customer_contact.delivery_address"
    );

    setDeliveryAddressSnapshot(currentDeliveryAddress);
    if (handleClose) {
      handleClose(e);
    }
    resetSearchOptions();
  };

  const formatAddressList = allAddressList?.map(
    (address) => address.address_type
  );

  const autofillDeliveryAddress = (option: any) => {
    const selectedAddress =
      allAddressList?.filter((address) => address.address_type === option)[0] ||
      null;

    if (selectedAddress) {
      const updatedAddress = {
        address_type: selectedAddress.address_type,
        is_same_as_default_address: false,
        address_contact_name: selectedAddress.address_contact_name || "",
        address_contact_phone: selectedAddress.address_contact_phone || "",
        address: selectedAddress.address || "",
        sub_district: selectedAddress.sub_district || "",
        district: selectedAddress.district || "",
        province: selectedAddress.province || "",
        postal_code: selectedAddress.postal_code || "",
        country: selectedAddress.country || "",
      };
      if (reset) {
        reset((prev: IQuotation | ISalesOrder | ISalesReturn) => ({
          ...prev,
          customer_contact: {
            ...prev.customer_contact,
            delivery_address: { ...updatedAddress },
          },
        }));
      }
    }
  };

  return (
    <ModalUI
      open={open}
      handleClose={closeDeliveryAddressForm}
      title={t("address.delivery_address")}
      fullWidth
      maxWidth="md"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={closeDeliveryAddressForm}
            disabled={disabled}
          />
          <CustomizedButton
            title={t("button.save")}
            onClick={(e) => deliverySubmitHandler(e)}
            variant="contained"
            size="medium"
            disabled={disabled}
          />
        </Box>
      }
    >
      <Grid container spacing={2} mt={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          order={{ xs: 2, md: 1 }}
        >
          <Controller
            key={`address_type`}
            name={`customer_contact.delivery_address.address_type`}
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                options={formatAddressList || []}
                label={t("address.index")}
                onChange={(e, option) => {
                  if (!option) {
                    autofillDeliveryAddress("");
                    return field.onChange("");
                  } else {
                    autofillDeliveryAddress(option);
                  }
                  if (option.value) {
                    autofillDeliveryAddress(option.value);
                    return field.onChange(option.value);
                  }
                  if (option.inputValue) {
                    autofillDeliveryAddress(option.inputValue);
                    return field.onChange(option.inputValue);
                  }
                  return field.onChange(option);
                }}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={10} sm={10} md={4} lg={4} xl={4} order={{ xs: 3 }}>
          <Controller
            key={`same_address`}
            name={`customer_contact.delivery_address.is_same_as_default_address`}
            control={control}
            render={({ field }) => (
              <CustomizedCheckboxes
                {...field}
                label={"เหมือนที่อยู่จดทะเบียน"}
                onChange={(e, selected) => {
                  if (selected) {
                    autofillAddress();
                  }
                  return field.onChange(selected);
                }}
                isDisabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            label={t("address.contact_person_name")}
            control={control}
            name="customer_contact.delivery_address.address_contact_name"
            error={Boolean(
              errors.customer_contact &&
                errors.customer_contact.delivery_address &&
                errors.customer_contact.delivery_address.address_contact_name
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.delivery_address &&
              errors.customer_contact.delivery_address.address_contact_name &&
              errors.customer_contact.delivery_address.address_contact_name
                .message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            label={t("address.phone_number")}
            control={control}
            name="customer_contact.delivery_address.address_contact_phone"
            error={Boolean(
              errors.customer_contact &&
                errors.customer_contact.delivery_address &&
                errors.customer_contact.delivery_address.address_contact_phone
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.delivery_address &&
              errors.customer_contact.delivery_address.address_contact_phone &&
              errors.customer_contact.delivery_address.address_contact_phone
                .message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={t("address.index")}
            control={control}
            name="customer_contact.delivery_address.address"
            error={Boolean(
              errors.customer_contact &&
                errors.customer_contact.delivery_address &&
                errors.customer_contact.delivery_address.address
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.delivery_address &&
              errors.customer_contact.delivery_address.address &&
              errors.customer_contact.delivery_address.address.message
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-subdistrict"
            name={`customer_contact.delivery_address.sub_district`}
            control={control}
            render={({ field }) => {
              if (isSameAddress) {
                return (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(
                      errors.customer_contact &&
                        errors.customer_contact.delivery_address &&
                        errors.customer_contact.delivery_address.sub_district
                    )}
                    helperText={
                      errors.customer_contact &&
                      errors.customer_contact.delivery_address &&
                      errors.customer_contact.delivery_address.sub_district
                    }
                    label={t("address.sub_district")}
                    disabled={true}
                  />
                );
              }
              return (
                <CustomizedComboBox
                  {...field}
                  options={subDistrictOptions}
                  label={t("address.sub_district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchSubDistrict(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      return field.onChange("");
                    }
                    setSearchSubDistrict(option);
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.sub_district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      let fieldsValue = {
                        ...getValues("customer_contact.delivery_address"),
                        district: filter[0].district,
                        province: filter[0].province,
                        postal_code: filter[0].postal_code,
                        country: "ไทย",
                      };
                      setValue(
                        `customer_contact.delivery_address`,
                        fieldsValue
                      );
                    } else if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }}
                  disabled={disabled || isSameAddress}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key="delivery-district"
            name={`customer_contact.delivery_address.district`}
            control={control}
            render={({ field }) => {
              if (isSameAddress) {
                return (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(
                      errors.customer_contact &&
                        errors.customer_contact.delivery_address &&
                        errors.customer_contact.delivery_address.district
                    )}
                    helperText={
                      errors.customer_contact &&
                      errors.customer_contact.delivery_address &&
                      errors.customer_contact.delivery_address.district
                    }
                    label={t("address.district")}
                    disabled={true}
                  />
                );
              }
              return (
                <CustomizedComboBox
                  {...field}
                  options={districtOptions}
                  label={t("address.district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchDistrict(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      return field.onChange("");
                    }
                    setSearchDistrict(option);
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      const filteredSubdistricts = filter.map(
                        (address) => address.sub_district
                      );
                      setSubDistrictOptions(filteredSubdistricts);
                      let fieldsValue = {
                        ...getValues("customer_contact.delivery_address"),
                        province: filter[0].province,
                        postal_code: filter[0].postal_code,
                        country: "ไทย",
                      };
                      setValue(
                        `customer_contact.delivery_address`,
                        fieldsValue
                      );
                    } else if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }}
                  disabled={disabled || isSameAddress}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key={`delivery-province`}
            name={`customer_contact.delivery_address.province`}
            control={control}
            render={({ field }) => {
              if (isSameAddress) {
                return (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(
                      errors.customer_contact &&
                        errors.customer_contact.delivery_address &&
                        errors.customer_contact.delivery_address.province
                    )}
                    helperText={
                      errors.customer_contact &&
                      errors.customer_contact.delivery_address &&
                      errors.customer_contact.delivery_address.province
                    }
                    label={t("address.province")}
                    disabled={true}
                  />
                );
              }
              return (
                <CustomizedComboBox
                  {...field}
                  options={provinceOptions}
                  label={t("address.province")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchProvince(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      return field.onChange("");
                    }
                    setSearchProvince(option);
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    setValue(
                      `customer_contact.delivery_address.country`,
                      "ไทย"
                    );
                    return field.onChange(option);
                  }}
                  disabled={disabled || isSameAddress}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            label={t("address.postal_code")}
            control={control}
            name="customer_contact.delivery_address.postal_code"
            error={Boolean(
              errors.customer_contact &&
                errors.customer_contact.delivery_address &&
                errors.customer_contact.delivery_address.postal_code
            )}
            helperText={
              errors.customer_contact &&
              errors.customer_contact.delivery_address &&
              errors.customer_contact.delivery_address.postal_code &&
              errors.customer_contact.delivery_address.postal_code.message
            }
            disabled={disabled || isSameAddress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            key={`delivery-country`}
            name={`customer_contact.delivery_address.country`}
            control={control}
            render={({ field }) => {
              if (isSameAddress) {
                return (
                  <CustomizedTextField
                    {...field}
                    error={Boolean(
                      errors.customer_contact &&
                        errors.customer_contact.delivery_address &&
                        errors.customer_contact.delivery_address.country
                    )}
                    helperText={
                      errors.customer_contact &&
                      errors.customer_contact.delivery_address &&
                      errors.customer_contact.delivery_address.country
                    }
                    label={t("address.country")}
                    disabled={true}
                  />
                );
              }
              return (
                <CustomizedComboBox
                  {...field}
                  options={countryOptions}
                  label={t("address.country")}
                  onChange={(e, option) => {
                    if (!option) {
                      return field.onChange("");
                    }
                    if (option.value) {
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }}
                  disabled={disabled || isSameAddress}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default DeliveryAddressForm;
