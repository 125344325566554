import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu } from "../../../types/global";
import BomTable from "../../../components/Table/Manufacture/Bom";

const Bom = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.bom.index"),
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.bom.index")}</Typography>
        <Box>
          <CustomizedButton
            sx={{ mr: 2 }}
            title={`สร้าง${t("manufacture.bom.index")}`}
            variant="contained"
            onClick={() => navigate(`/manufacture/bom/add`)}
          />
          {/* <CustomizedButton
            title={`นำเข้า${t("manufacture.bom.index")}`}
            variant="contained"
            onClick={() => navigate(`/manufacture/bom/importer`)}
          /> */}
        </Box>
      </HeaderLayout>
      <BomTable />
    </Fragment>
  );
};

export default Bom;
