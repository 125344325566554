import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const Logistic = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.delivery_order.index"),
      to: "/logistic/delivery_order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    // {
    //   name: t("logistic.order.index"),
    //   to: "/logistic/order",
    // },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Logistic;
