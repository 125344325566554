import { useTranslation } from "react-i18next";
import ModalUI from "../../UI/ModalUI";
import { useForm } from "react-hook-form";
import { IAttribute } from "../../../types/Inventory/item";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import {
  AttributesQuery,
  useAttributeCreateMutation,
  useAttributeUpdateMutation,
  useAttributeQuery,
  AttributeQuery,
  AttributeType,
} from "../../../generated/inventory";
import { useEffect } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import ControlledSelect from "../../Controller/ControlledSelect";
import { ISelectOption } from "../../../types/global";
import { attributeSchema, attributeValidation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

interface Props {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AttributesQuery, unknown>>;
}

const dataType: ISelectOption[] = [
  {
    label: "ตัวเลข",
    value: AttributeType.Number,
  },
  {
    label: "ตัวอักษร",
    value: AttributeType.Text,
  },
];

const AttributeModal = ({
  open,
  closeModalHandler,
  currentId,
  refetch,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IAttribute>({
    defaultValues: attributeSchema,
    resolver: yupResolver(attributeValidation),
  });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isSuccess } = useAttributeQuery<AttributeQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        id: currentId,
      },
    },
    {
      enabled: Boolean(currentId),
    }
  );

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  const { mutate: createAttribute } = useAttributeCreateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: () => {
        enqueueSnackbar(`เพิ่ม${t("setting.inventory.attribute")}สำเร็จ`, {
          variant: "success",
        });
        refetch();
        onClose();
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(
          errorMessage || `เพิ่ม${t("setting.inventory.attribute")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const { mutate: updateAttribute } = useAttributeUpdateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: ({ attributeUpdate }) => {
        if (attributeUpdate) {
          enqueueSnackbar(`แก้ไข${t("setting.inventory.attribute")}สำเร็จ`, {
            variant: "success",
          });
          const formatAttribute = {
            id: attributeUpdate.id,
            name: attributeUpdate.name,
            acronym: attributeUpdate.acronym,
            description: attributeUpdate.description || "",
            data_type: attributeUpdate.data_type,
          };
          reset(formatAttribute);
          refetch();
          onClose();
        }
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(
          errorMessage || `แก้ไข${t("setting.inventory.attribute")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const formatAttribute = {
        id: data?.attribute?.id,
        name: data?.attribute?.name,
        acronym: data?.attribute?.acronym,
        description: data?.attribute?.description || "",
        data_type: data?.attribute?.data_type,
      };
      reset(formatAttribute);
    }
  }, [
    data?.attribute?.acronym,
    data?.attribute?.data_type,
    data?.attribute?.description,
    data?.attribute?.id,
    data?.attribute?.name,
    isSuccess,
    reset,
  ]);

  const addAttributeHandler = (data: IAttribute) => {
    createAttribute({
      createInput: {
        ...data,
        acronym: data.acronym ?? "text",
        name: data.name!,
      },
    });
  };

  const updateAttributeHandler = (data: IAttribute) => {
    const { id, ...payload } = data;
    updateAttribute({
      uniqueInput: {
        id: id,
      },
      updateInput: payload,
    });
  };

  return (
    <ModalUI
      title={
        (currentId ? t("sentence.edit") : t("button.add")) +
        t("setting.inventory.attribute")
      }
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(updateAttributeHandler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(addAttributeHandler)}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                label={t("setting.inventory.attribute")}
                control={control}
                name="name"
                error={Boolean(errors?.name)}
                helperText={errors?.name && errors.name.message}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                label="ตัวย่อ"
                control={control}
                name="acronym"
                error={Boolean(errors?.acronym)}
                helperText={errors?.acronym && errors.acronym.message}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledSelect
                label="ชนิดของข้อมูล"
                options={dataType}
                control={control}
                name="data_type"
                error={Boolean(errors?.data_type)}
                helperText={errors?.data_type && errors.data_type.message}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="คำอธิบาย"
            control={control}
            name="description"
            error={Boolean(errors?.description)}
            helperText={errors?.description && errors.description.message}
            minRows={3}
            multiline
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default AttributeModal;
