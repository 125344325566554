import { IUser, IUserInfo } from "../../types/Auth/user";
import { mergeUserPermissions } from "./Role";

export const mergeUsersFormatter = (
  users: IUser[],
  usersInfo: IUserInfo[]
): IUser[] => {
  const newUser: IUser[] = [];
  usersInfo.forEach(({ user_unique_id, id, ...otherData }) => {
    const findUser = users?.find((user) => user?.unique_id === user_unique_id);
    if (findUser) return newUser.push({ ...findUser, ...otherData });
  });
  return newUser;
};

export const mergeUserFormatter = (user: IUser, userInfo: IUserInfo): IUser => {
  if (user && userInfo) {
    const { user_unique_id, id, ...otherData } = userInfo;
    const permission_list = mergeUserPermissions(userInfo.role_list);
    return { ...user, permission_list: permission_list, ...otherData };
  } else return user;
};
