import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { IDefaultForm } from "../../../../types/global";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
};

const Sales = ({
  control,
  errors,
  disabled,
  isInventoryPage,
}: ExtendedProps) => {
  const { t } = useTranslation();

  const salesVatTypeOption = [
    { id: 0, value: "NO_VAT", label: "ไม่มี" },
    { id: 1, value: "VAT_0", label: "0 %" },
    { id: 2, value: "VAT_7", label: "7 %" },
  ];

  return (
    <Box sx={{ overflow: "visible" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"sale_price"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-sales-price-text-field"
                type="number"
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                label={t("inventory.items.sale_price")}
                error={Boolean(errors.sale_price)}
                helperText={errors.sale_price?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"special_price"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                testId="item-sales-max-discount-text-field"
                type="number"
                onChange={(e) => field.onChange(parseFloat(e.target.value))}
                label={t("inventory.items.special_price")}
                error={Boolean(errors.special_price)}
                helperText={errors.special_price?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"sale_vat_type"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                testId="item-sales-vat-text-field"
                label={t("inventory.items.sale_vat_Type")}
                options={salesVatTypeOption}
                onChange={(_, newValue) => {
                  field.onChange(newValue ? newValue.label : "");
                }}
                error={Boolean(errors.sale_vat_type)}
                helperText={errors.sale_vat_type?.message}
                disabled={!isInventoryPage || disabled}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* <Typography fontWeight="bold" my={2} ml={1}>
        {t("inventory.items.accounting")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"sales_account"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label={t("inventory.items.salesAccount")}
                options={salesVatTypeOption}
                disabled={true}
                error={Boolean(errors.sales_account)}
                helperText={errors.sales_account?.message}
              />
            )}
          />
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default Sales;
