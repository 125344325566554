import { useState, useEffect, Fragment, ReactNode } from "react";
import { TextField, IconButton, SxProps, TextFieldProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
// import GlobalService from "../../services/Global";
import { IDefaultForm, IOptionsCreatable } from "../../types/global";
import {
  EntityTypeEnum,
  useCreatableCreateMutation,
  useCreatablesQuery,
  CreatablesQuery,
  useCreatableDeleteMutation,
} from "../../generated/creatable";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

interface Props {
  control: IDefaultForm["control"];
  sx?: SxProps;
  name: string;
  dataName?: string;
  setValue: IDefaultForm["setValue"];
  error?: TextFieldProps["error"];
  defaultOptions: IOptionsCreatable[];
  onInputChange?: AutocompleteProps<any, any, any, any, any>["onInputChange"];
  onKeyDown?: TextFieldProps["onKeyDown"];
  onChange?: any;
  title?: string;
  helperText?: TextFieldProps["helperText"];
  placeholder?: TextFieldProps["placeholder"];
  disabled?: boolean;
  required?: boolean;
  documentType: EntityTypeEnum;
  endAdornment?: ReactNode;
}

const filter = createFilterOptions();

const ControlledServerSideCreatable = ({
  control,
  sx,
  name,
  dataName,
  setValue,
  error,
  defaultOptions,
  onInputChange,
  onKeyDown,
  onChange,
  helperText,
  title,
  placeholder,
  disabled,
  required,
  documentType,
  endAdornment,
}: Props) => {
  // const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState<IOptionsCreatable[]>([]);
  const [open, setOpen] = useState(false);

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isSuccess } = useCreatablesQuery<CreatablesQuery>(
    graphQLClientWithHeaderSetting,
    {
      filterInput: {
        usage_field_name: dataName || name,
        usage_field_type: documentType,
      },
    }
  );

  const { mutate: createOption } = useCreatableCreateMutation<Error>(
    graphQLClientWithHeaderSetting
  );

  const { mutate: deleteOption } = useCreatableDeleteMutation<Error>(
    graphQLClientWithHeaderSetting
  );

  useEffect(() => {
    if (isSuccess) {
      const getOptions = async () => {
        setOptions(
          data.creatables?.map((option) => {
            return {
              value: option.name,
              label: option.name,
              isDeletable: true,
            };
          })
        );
      };
      getOptions();
    }
  }, [isSuccess, data]);

  const addOptionHandler = async (optionToAdd: any) => {
    createOption({
      createInput: {
        usage_field_type: documentType,
        usage_field_name: dataName || name,
        name: optionToAdd,
      },
    });
    setValue(name, optionToAdd);
    setOptions((prevOptions: any) => [
      ...prevOptions,
      { value: optionToAdd, label: optionToAdd, isDeletable: true },
    ]);
  };

  const deleteOptionHandler = async (
    optionToDelete: IOptionsCreatable["value"]
  ) => {
    deleteOption({
      uniqueInput: {
        name_usage_field_type_usage_field_name: {
          usage_field_type: documentType,
          usage_field_name: dataName || name,
          name: optionToDelete,
        },
      },
    });
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== optionToDelete)
    );
    setValue(name, "");
  };

  const combinedOptions = [...defaultOptions, ...options];

  return (
    <>
      <Controller
        key={`${name}-creatable`}
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            fullWidth
            {...field}
            onChange={
              onChange
                ? (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      addOptionHandler(option.inputValue);
                    }
                    onChange(e, option, field);
                  }
                : (e, option) => {
                    if (option?.label?.includes("เพิ่ม ")) {
                      addOptionHandler(option.inputValue);
                    }
                    if (!option) {
                      return field.onChange("");
                    }
                    if (option.value) {
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      return field.onChange(option.inputValue);
                    }
                    return field.onChange(option);
                  }
            }
            disabled={disabled}
            onInputChange={onInputChange}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `เพิ่ม "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            disableClearable={endAdornment ? true : false}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            options={combinedOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.value || option.type;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  value={option.value ?? option}
                  key={option.label ?? option}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {option.label ?? option}
                  {!option?.label?.includes("เพิ่ม ") && option.isDeletable && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        deleteOptionHandler(option.value ?? option);
                        e.stopPropagation();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </li>
              );
            }}
            sx={sx}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                size="small"
                name={name}
                error={error}
                onKeyDown={onKeyDown}
                helperText={helperText}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: endAdornment ?? (
                    <Fragment>{params.InputProps.endAdornment}</Fragment>
                  ),
                }}
              />
            )}
            readOnly={disabled}
          />
        )}
      />
    </>
  );
};

export default ControlledServerSideCreatable;
