import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import StockByLotReportTable from "../../../components/Table/Inventory/Reports/StockByLotReportTable";
import { useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { GENERATE_REPORT } from "../../../services/AgGrid/InventoryReportAgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const StockByLotReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.itemStockByLot.index"),
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const onBtnExport = async () => {
    try {
      setIsLoading(true);
      const filterModel = gridRef?.current?.api.getFilterModel();
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { item_status } = filterModel as any;

      const formatFilter = {
        ...filterModel,
        item_status: item_status && {
          ...item_status,
          values: item_status.values.map((v: string) => parseInt(v)),
        },
      };

      // const { GenerateReportNoQueue } = await graphQLClient.request(
      //   GET_EXPORTED_REPORT,
      //   {
      //     reportType: "stock_by_lot",
      //     aggridInput: {
      //       startRow: 0,
      //       endRow: Math.pow(10, 5),
      //       filterModel: formatFilter,
      //       sortModel,
      //     },
      //   }
      // );
      // window.open(GenerateReportNoQueue, "_blank");
      // enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
      //   variant: "success",
      // });

      await graphQLClient.request(GENERATE_REPORT, {
        priority: 1,
        reportType: "stock_by_lot",
        params: {
          aggridInput: {
            startRow: 0,
            endRow: Math.pow(10, 5),
            filterModel: formatFilter,
            sortModel,
          },
        },
      });

      enqueueSnackbar("คำขอสำเร็จ ระบบกำลังดำเนินการ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
      // enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
      //   variant: "error",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("inventory.itemStockByLot.index")}
        </Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading}
        />
      </HeaderLayout>
      <StockByLotReportTable gridRef={gridRef} />
    </>
  );
};

export default StockByLotReport;
