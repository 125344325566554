export const customHeaderColRender = (
  engName: string,
  thaiName: string,
  required?: boolean
) => {
  return `<div class="ag-cell-label-container" role="presentation">
        <span
          ref="eMenu"
          class="ag-header-icon ag-header-cell-menu-button"
          aria-hidden="true"
        ></span>
        <div ref="eLabel" class="ag-header-cell-label center" role="presentation">
          ${
            engName
              ? `
              <div class="double-header center">
                <div class="ag-header-cell-text center" role="columnheader">
                  ${thaiName}
                </div>
                <div class="ag-header-cell-text center" role="columnheader">
                  ${engName}
                  <div class="color-red">${required ? "*" : ""}</div>
                </div>
              </div>
            `
              : `
              <div class="center">
                <div class="ag-header-cell-text center" role="columnheader">
                  ${thaiName}
                </div>
              </div>
            `
          }
        </div>
      </div>`;
};
