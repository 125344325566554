import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@mui/material";
import { Controller, FieldArrayWithId, useWatch } from "react-hook-form";

import { IDefaultForm } from "../../../../../types/global";

import ControlledTextField from "../../../../Controller/ControlledTextField";
import { calColorCount } from "../../../../../utils/Formatter/Item";
import { IItem } from "../../../../../types/Inventory/item";
import { parseFloatFunction } from "../../../../../utils/dataTransformer";

type ExtendedProps = IDefaultForm & {
  fields: FieldArrayWithId<IItem, "variation_list", "id">[];
  watchItemIsActive: boolean;
  openDisabledConfirmation: any;
  setSelectedSku: any;
  option_1: any;
  option_2: any;
};

const TwoVariantTable = ({
  control,
  setValue,
  getValues,
  errors,
  option_1,
  option_2,
  disabled,
  watchItemIsActive,
  openDisabledConfirmation,
  setSelectedSku,
  fields,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const watchTracability = useWatch({ control, name: "tracability" });

  const priceSX = {
    textAlign: "center",
    maxWidth: 120,
    minWidth: 70,
  };

  const tableHeader =
    watchTracability !== "normal"
      ? [
          option_1.key,
          option_2.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "Seller SKU",
          t("inventory.active"),
        ]
      : [
          option_1.key,
          option_2.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "Seller SKU",
          "Barcode",
          t("inventory.active"),
        ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableHeader.map((header) => (
            <TableCell sx={{ textAlign: "center" }} key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {fields &&
          fields.map((firstOption, firstInd) => (
            <Fragment key={firstOption.id}>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell
                  rowSpan={calColorCount(firstOption)}
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    // paddingBottom: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  {firstOption.name}
                </TableCell>
              </TableRow>
              {firstOption.value?.map((secondOption: any, secondInd) => (
                <Fragment key={secondOption.name || "" + secondInd}>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {secondOption.name}
                    </TableCell>
                    <TableCell sx={priceSX}>
                      <ControlledTextField
                        control={control}
                        name={`variation_list[${firstInd}].value[${secondInd}].sale_price`}
                        type="number"
                        onChange={parseFloatFunction}
                        disabled={!secondOption.is_active || disabled}
                      />
                    </TableCell>
                    <TableCell sx={priceSX}>
                      <ControlledTextField
                        control={control}
                        name={`variation_list[${firstInd}].value[${secondInd}].special_price`}
                        type="number"
                        onChange={parseFloatFunction}
                        disabled={!secondOption.is_active || disabled}
                      />
                    </TableCell>
                    <TableCell sx={priceSX}>
                      <ControlledTextField
                        control={control}
                        name={`variation_list[${firstInd}].value[${secondInd}].cost_price`}
                        type="number"
                        onChange={parseFloatFunction}
                        disabled={!secondOption.is_active || disabled}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        minWidth: 250,
                      }}
                    >
                      <ControlledTextField
                        control={control}
                        name={`variation_list[${firstInd}].value[${secondInd}].seller_sku_name`}
                        disabled={!secondOption.is_active || disabled}
                      />
                    </TableCell>
                    {watchTracability === "normal" && (
                      <TableCell
                        sx={{
                          textAlign: "center",
                          minWidth: 250,
                        }}
                      >
                        <ControlledTextField
                          control={control}
                          name={`variation_list[${firstInd}].value[${secondInd}].barcode`}
                          disabled={!secondOption.is_active || disabled}
                          error={
                            errors?.variation_list?.[firstInd]?.value?.[
                              secondInd
                            ]?.barcode
                          }
                          helperText={
                            errors?.variation_list?.[firstInd]?.value?.[
                              secondInd
                            ]?.barcode?.message
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`variation_list[${firstInd}].value[${secondInd}].is_active`}
                        render={({ field }) => (
                          <Box
                            sx={{
                              cursor:
                                !disabled && Boolean(!watchItemIsActive)
                                  ? "pointer"
                                  : "default",
                            }}
                            onClick={
                              !disabled && Boolean(!watchItemIsActive)
                                ? () => {
                                    enqueueSnackbar(
                                      "หากต้องการเปิดการใช้งานของตัวเลือก\nกรุณาเปิดการใช้งานสถานะในหน้าทั่วไปของสินค้าก่อน",
                                      {
                                        variant: "error",
                                        style: {
                                          whiteSpace: "pre-line",
                                        },
                                      }
                                    );
                                  }
                                : () => {}
                            }
                          >
                            <Switch
                              checked={field.value}
                              onChange={() => {
                                if (!field.value)
                                  return field.onChange(!field.value);
                                openDisabledConfirmation();
                                setSelectedSku({
                                  name: getValues(
                                    `variation_list[${firstInd}].value[${secondInd}].sku_name`
                                  ),
                                  position: `variation_list[${firstInd}].value[${secondInd}].is_active`,
                                  current_value: field.value,
                                });
                              }}
                              disabled={disabled || !watchItemIsActive}
                            />
                          </Box>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </Fragment>
          ))}
      </TableBody>
    </Table>
  );
};

export default TwoVariantTable;
