import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ActivityType,
  ContactActivityLogDocumentType,
  ValidateContactImportQuery,
  useImportContactsMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import { formatString, formatNumber, formatFloat, formatInt } from "../../../utils/dataTransformer";
import { IAddress, ICredit, IFinance } from "../../../types/Setting/company";
import { addImporterErrorHandler, findNoneBoolean, findNoneContactType, findNoneContactTypeDetail, findNoneContactTypeDetailForIndividual, findNoneContactTypeDetailForJuristicPerson, findNoneStatus, mapNestedData } from "../../../utils/Importer";
import { useActivityLog } from "../../use-activity-log";
import { IImporterError } from "../../../types/global";
import { useTranslation } from "react-i18next";

const columns = [
  "unique_id",
  "is_customer",
  "is_vendor",
  "contact_type",
  "contact_type_detail",
  "title_name",
  "main_contact_name",
  "secondary_contact_name",
  "identity_no",
  "registered_capital",
  "contact_source",
  "branch_type",
  "branch_id",
  "branch_name",
  "is_active",
  "status_remark",
  "billing_address",
  "billing_address_sub_district",
  "billing_address_district",
  "billing_address_province",
  "billing_address_postal_code",
  "billing_address_country",
  "is_cheque",
  "is_transfer",
  "is_cash",
  "payment_day",
  "payment_contact_name",
  "payment_contact_phone",
  "credit_count",
  "billing_day",
  "billing_contact_name",
  "billing_contact_phone",
  "credit_limit_value",
  "credit_limit_day",
  "account_payable_id",
  "account_receivable_id",
  "sale_unique_id",
  "remark"
];

const formatBoolean = (value: string) => {
  const trmmedValue = formatString(value);
  switch (trmmedValue) {
    case "ใช่":
      return true;
    case "ไม่ใช่":
      return false;
    default:
      return true;
  }
};

const findDuplicatesWithList = (array: any[]) => {
  const countMap = new Map();
  const duplicates = [];

  for (const item of array) {
    const count = (countMap.get(item) || 0) + 1;
    countMap.set(item, count);
  }

  for (const item of array) {
    const count = countMap.get(item);
    if (count && count >= 2) {
      duplicates.push(item);
    }
  }

  return duplicates;
};

const count_limit = 5000;

const useNormalContactImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  setErrorData: Dispatch<SetStateAction<any[]>>,
  openModalHandler: () => void
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();
  const { createActivityLog } = useActivityLog();

  const requiredKeys = [
    {
      label: "รหัสผู้ติดต่อ",
      value: "unique_id",
    },
    {
      label: "ลูกค้า",
      value: "is_customer",
    },
    {
      label: "ผู้ขาย",
      value: "is_vendor",
    },
    {
      label: "ประเภทกิจการ",
      value: "contact_type",
    },
    {
      label: "ชื่อประเภทกิจการ",
      value: "contact_type_detail",
    },
    {
      label: "ชื่อกิจการ/ชื่อจริง",
      value: "main_contact_name",
    },
    {
      label: "ประเภทสำนักงาน",
      value: "branch_type",
    },
    // {
    //   label: "รหัสสาขา",
    //   value: "branch_id",
    // },
    {
      label: "สถานะ",
      value: "is_active",
    }
  ]
  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);
  const [userSaleUniqueIdList, setuserSaleUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("contact");

  const { refetch: validateCustomer, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_mode: getValues("type"),
          contact_unique_id_list: contactUniqueIdList,
          tag_list: [],
          user_sale_unique_id_list: userSaleUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdate, isLoading: isCreatingOrUpdating } =
    useImportContactsMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      console.log("dataCols", dataCols)
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const errorData: IImporterError[] = [];
      const requiredFieldErrors: any[] = [];
      const invalidTypeErrors: any[] = [];
      const formattedDataSnapshot = [...data];
      const groupDataErrors: any[] = [];

      for (let i = 0; i < formattedDataSnapshot.length; i++) {
        for (let j = 0; j < requiredKeys.length; j++) {
          if (!formattedDataSnapshot[i][requiredKeys[j].value]) {
            requiredFieldErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "required",
              field: requiredKeys[j].label,
            });
          }
        }
        const isCustomer = formattedDataSnapshot[i].is_customer;
        const isVendor = formattedDataSnapshot[i].is_vendor;
        if (!formatBoolean(isCustomer) && !formatBoolean(isVendor)) {
          invalidTypeErrors.push({
            unique_id: formattedDataSnapshot[i].unique_id,
            type: "invalid",
            field: "ลูกค้า/ผู้ขาย",
            detail: `${isCustomer ? "ใช่" : "ไม่ใช่"} ${isVendor ? "ใช่" : "ไม่ใช่"}`
          });
        }

        const contactType = formattedDataSnapshot[i].contact_type;
        const contactTypeDetail = formattedDataSnapshot[i].contact_type_detail;
        const status = formattedDataSnapshot[i].is_active;

        if (findNoneContactType([contactType]).length === 0) {
          if (contactType === "นิติบุคคล") {
            const noneContactTypeDetail = findNoneContactTypeDetailForJuristicPerson([contactTypeDetail]);
            if (findNoneContactTypeDetail([contactTypeDetail]).length === 0 && noneContactTypeDetail.length > 0) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: contactTypeDetail,
              });
            }
          } else if (contactType === 'บุคคลธรรมดา') {
            const noneContactTypeDetail = findNoneContactTypeDetailForIndividual([contactTypeDetail]);
            if (findNoneContactTypeDetail([contactTypeDetail]).length === 0 && noneContactTypeDetail.length > 0) {
              groupDataErrors.push({
                unique_id: formattedDataSnapshot[i].unique_id,
                type: "invalid",
                field: "ชื่อประเภทกิจการ",
                detail: contactTypeDetail,
              });
            }
          }

          if (findNoneStatus([status]).length === 0 && status !== "ใช้งาน" && !formattedDataSnapshot[i].remark_status) {
            groupDataErrors.push({
              unique_id: formattedDataSnapshot[i].unique_id,
              type: "invalid",
              field: "หมายเหตุสถานะ",
            })
          }
        };
      }
      if (invalidTypeErrors.length > 0) {
        invalidTypeErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ประเภทผู้ติดต่อ ต้องระบุอย่างน้อย 1 ประเภท",
            error.field,
            [error.detail]
          );
        });
      }

      if (requiredFieldErrors.length > 0) {
        requiredFieldErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "กรุณาระบุข้อมูลที่จำเป็น",
            error.field,
            [""]
          );
        });
      }

      if (groupDataErrors.length > 0) {
        groupDataErrors.forEach((error) => {
          addImporterErrorHandler(
            errorData,
            "ข้อมูลชุดไม่ถูกต้อง",
            error.field,
            [error.detail]
          );
        });
      }

      let formattedData: any[] = [];

      const uniqueIdList: string[] = [];
      const userSaleUniqueIdList: string[] = [];
      const customerList: string[] = [];
      const vendorList: string[] = [];
      const contactTypeList: string[] = [];
      const contactTypeDetailList: string[] = [];
      const titleNameList: string[] = [];
      const contactSourceList: string[] = [];
      const branchTypeList: string[] = [];
      const statusList: string[] = [];
      const chequeList: string[] = [];
      const cashList: string[] = [];
      const transferList: string[] = [];
      const countryList: string[] = [];

      data.forEach((contact: any) => {
        const {
          unique_id,
          sale_unique_id,
          is_customer,
          is_vendor,
          contact_type,
          contact_type_detail,
          title_name,
          contact_source,
          branch_type,
          is_active,
          is_cheque,
          is_cash,
          is_transfer,
          billing_address_country
        } = contact

        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
        if (sale_unique_id) {
          userSaleUniqueIdList.push(sale_unique_id)
        }
        if (is_customer) {
          customerList.push(is_customer);
        }
        if (is_vendor) {
          vendorList.push(is_vendor);
        }
        if (contact_type) {
          contactTypeList.push(contact_type);
        }
        if (contact_type_detail) {
          contactTypeDetailList.push(contact_type_detail);
        }
        if (title_name) {
          titleNameList.push(title_name);
        }
        if (contact_source) {
          contactSourceList.push(contact_source);
        }
        if (branch_type) {
          branchTypeList.push(branch_type);
        }
        if (is_active) {
          statusList.push(is_active);
        }
        if (is_cheque) {
          chequeList.push(is_cheque);
        }
        if (is_cash) {
          cashList.push(is_cash);
        }
        if (is_transfer) {
          transferList.push(is_transfer);
        }
        if (billing_address_country) {
          countryList.push(billing_address_country);
        }

        const address_list: IAddress[] = [
          {
            address_type: "ที่อยู่จดทะเบียน",
            is_default: true,
            address_contact_name: "",
            address_contact_phone: "",
            address: formatString(contact.billing_address),
            sub_district: formatString(contact.billing_address_sub_district),
            district: formatString(contact.billing_address_district),
            province: formatString(contact.billing_address_province),
            postal_code: formatString(contact.billing_address_postal_code),
            country: formatString(contact.billing_address_country),
          },
          // {
          //   address_type: "ที่อยู่จัดส่ง",
          //   is_default: false,
          //   is_same_as_default_address: false,
          //   address_contact_name: formatString(
          //     contact.delivery_address_contact_name
          //   ),
          //   address_contact_phone: formatString(
          //     contact.delivery_address_contact_phone
          //   ),
          //   address: formatString(contact.delivery_address),
          //   sub_district: formatString(contact.delivery_address_sub_district),
          //   district: formatString(contact.delivery_address_district),
          //   province: formatString(contact.delivery_address_province),
          //   postal_code: formatString(contact.delivery_address_postal_code),
          //   country: formatString(contact.delivery_address_country),
          // },
        ];

        const request_credit: ICredit = {
          credit_limit_day: formatFloat(contact.credit_limit_day),
          credit_limit_value: formatFloat(contact.credit_limit_value)
        }

        const finance: IFinance =
        {
          account_payable_id: formatString(contact.account_payable_id),
          account_receivable_id: formatString(contact.account_receivable_id),
          bank_list: [],
          billing_contact_name: formatString(contact.billing_contact_name),
          billing_contact_phone: formatString(contact.billing_contact_phone),
          billing_day: formatFloat(contact.billing_day),
          credit_count: formatString(contact.credit_count),
          is_cash: contact.is_cash === "ใช่" ? true : false,
          is_cheque: contact.is_cheque === "ใช่" ? true : false,
          is_transfer: contact.is_transfer === "ใช่" ? true : false,
          payment_contact_name: formatString(contact.payment_contact_name),
          payment_contact_phone: formatString(contact.payment_contact_phone),
          payment_day: formatString(contact.payment_day),
          request_credit
        }


        const mappedData = {
          unique_id: formatString(contact.unique_id),
          is_customer: formatBoolean(contact.is_customer),
          is_vendor: formatBoolean(contact.is_vendor),
          contact_type: formatString(contact.contact_type),
          contact_type_detail: formatString(contact.contact_type_detail),
          title_name: formatString(contact.title_name),
          main_contact_name: formatString(contact.main_contact_name),
          secondary_contact_name: formatString(contact.secondary_contact_name),
          identity_no: formatString(contact.identity_no),
          registered_capital: formatInt(contact.registered_capital),
          contact_source: formatString(contact.contact_source),
          branch_type: formatString(contact.branch_type),
          branch_id: formatString(contact.branch_id),
          branch_name: formatString(contact.branch_name),
          is_active: contact.is_active === "ใช้งาน" ? 1 : 0,
          status_remark: formatString(contact.status_remark),
          address_list,
          finance,
          sales_list: formatString(contact.sale_unique_id),
          remark: formatString(contact.remark),


          // unique_id: formatString(contact.unique_id),
          // is_vendor: formatBooleanString(contact.is_vendor),
          // is_customer: formatBooleanString(contact.is_customer),
          // contact_type: formatString(contact.contact_type),
          // contact_type_detail: formatString(contact.contact_type_detail) || "",
          // title_name: formatString(contact.title_name) || "",
          // main_contact_name: formatString(contact.main_contact_name),
          // secondary_contact_name:
          //   formatString(contact.secondary_contact_name) || "",
          // is_active: contact.is_active === "ใช้งาน" ? 1 : 0,
          // registered_capital: 0,
          // identity_no: formatString(contact.identity_no),
          // address_list,
          // finance:
          //   contact.payment_day || contact.billing_day
          //     ? {
          //       payment_day: formatNumber(contact.payment_day, 0),
          //       billing_day: formatNumber(contact.billing_day, 0),
          //     }
          //     : undefined,
        };

        formattedData.push(mappedData);
        console.log("Data", formattedData)
      });

      const duplicateUniqueId = findDuplicatesWithList(uniqueIdList);
      const noneCustomer = findNoneBoolean(customerList)
      const noneVendor = findNoneBoolean(vendorList)
      const noneCheque = findNoneBoolean(chequeList);
      const noneTransfer = findNoneBoolean(transferList);
      const noneCash = findNoneBoolean(cashList);
      const noneContactType = findNoneContactType(contactTypeList);
      const noneContactTypeDetail = findNoneContactTypeDetail(contactTypeDetailList);
      const noneStatus = findNoneStatus(statusList)

      const allError = [
        duplicateUniqueId,
        requiredFieldErrors,
        invalidTypeErrors,
        noneCustomer,
        noneVendor,
        noneCheque,
        noneTransfer,
        noneCash,
        noneContactType,
        noneContactTypeDetail,
        noneStatus
      ];
      addImporterErrorHandler(
        errorData,
        `ข้อมูลในไฟล์ซ้ำกัน`,
        `${t(`contact.document_id`)}`,
        duplicateUniqueId
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ลูกค้า",
        noneCustomer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ผู้ขาย",
        noneVendor
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เช็ค (การชำระเงิน)",
        noneCheque
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "โอนเงิน (การชำระเงิน)",
        noneTransfer
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "เงินสด (การชำระเงิน)",
        noneCash
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ประเภทกิจการ",
        noneContactType
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "ชื่อประเภทกิจการ",
        noneContactTypeDetail
      );
      addImporterErrorHandler(
        errorData,
        "รูปแบบข้อมูลไม่ถูกต้อง",
        "สถานะ",
        noneStatus
      );
      setErrorData(errorData);
      const sumErrorLength = allError.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        openModalHandler();
        return [];
      }

      setContactUniqueIdList(Array.from(new Set(uniqueIdList)));
      setuserSaleUniqueIdList(Array.from(new Set(userSaleUniqueIdList)))

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (data.length === 0) {
        message = "กรุณาระบุข้อมูล";
      }
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      if (!tempDoc || tempDoc.length === 0) {
        return;
      }
      const formattedData = await formatItemData(tempDoc);
      if (formattedData && formattedData.length > 0) {
        if (formattedData.length > count_limit) {
          enqueueSnackbar(
            `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
            {
              variant: "error",
            }
          );
        } else {
          setRowData(formattedData);
          enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
            variant: "success",
          });
          setValue("step", 1);
        }
      } else if (tempDoc.length > 0){
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      //TODO: Validate
      const { data } = await validateCustomer();
      const existingUniqueId =
        data?.ValidateContactImport.existing_contact_unique_id_list || [];
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];
      const missingUserSaleUniqueId =
        data?.ValidateContactImport.missing_user_sale_unique_id_list || [];
      const arrays = [existingUniqueId, missingUniqueId, missingUserSaleUniqueId];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      const errorData: IImporterError[] = [];

      addImporterErrorHandler(
        errorData,
        `ข้อมูลซ้ำกับในระบบ`,
        `${t(`contact.document_id`)}`,
        existingUniqueId
      );

      addImporterErrorHandler(
        errorData,
        `ไม่พบข้อมูลในระบบ`,
        `${t(`contact.document_id`)}`,
        missingUniqueId
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await createOrUpdate({
            importInput: {
              import_mode: getValues("type"),
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          for (const contact of rowData) {
            const response = await graphqlClient.request(
              `query GetContactId($unique_id: String!) {
                    contact(uniqueInput: { unique_id: $unique_id }) {
                        id
                    }
                }`,
              { unique_id: contact.unique_id }
            );
            const contactId = response?.contact?.id;
            if (contactId) {
              await createActivityLog({
                activity_type: ActivityType.CreateImporter,
                document_type: ContactActivityLogDocumentType.Contact,
                reference_id: contactId,
                activity_detail: {},
              });
            }
          }
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isCreatingOrUpdating,
    validateHandler,
    importHandler,
  };
};

export default useNormalContactImporter;
