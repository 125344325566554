import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import { IDefaultForm } from "../../../../types/global";
import { EntityTypeEnum } from "../../../../generated/creatable";

const ReasonForm = ({ control, errors, disabled, setValue }: IDefaultForm) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Typography ml={1} fontWeight="bold">
        {t("purchase.return.reason_to_return")}
      </Typography>
      <Box width={300} my={2}>
        <ControlledServerSideCreatable
          control={control}
          error={Boolean(errors && errors.reason_to_return)}
          defaultOptions={[]}
          documentType={EntityTypeEnum.PurchaseReturn}
          name="reason_to_return"
          setValue={setValue}
          disabled={disabled}
          placeholder={t("purchase.return.reason_to_return")}
        />
      </Box>
    </CustomizedBox>
  );
};

export default ReasonForm;
