import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { NavbarContextProvider } from "./contexts/navbar-context";
import CustomizedSnackbar from "./components/Custom/CustomizedSnackbar";
import { StateContextProvider } from "./contexts/auth-context";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { ActivityLogContextProvider } from "./contexts/activity-log-context";
import { DisableContextProvider } from "./contexts/disable-context";
// import * as Sentry from "@sentry/react";
// import { CURRENT_VERSION } from "./config/variables";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

// Sentry.init({
//   dsn: "https://56db705a5f5c4d238f9d7a10c9f5b99f@o4505345515913216.ingest.sentry.io/4505408012877824",
//   release: "npr-erp-production@" + CURRENT_VERSION,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/erp-api\.npr\.digital\/graphql/,
//       ],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       maskAllInputs: false,
//       blockAllMedia: false,
//       networkDetailAllowUrls: ["https://erp-api.npr.digital/graphql"],
//       networkRequestHeaders: ["X-Custom-Header"],
//       networkResponseHeaders: ["X-Custom-Header"],
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: process.env.REACT_APP_ENVIRONMENT,
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CustomizedSnackbar>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StateContextProvider>
            <AuthMiddleware>
              <NavbarContextProvider>
                <ActivityLogContextProvider>
                  <DisableContextProvider>
                    <App />
                  </DisableContextProvider>
                </ActivityLogContextProvider>
              </NavbarContextProvider>
            </AuthMiddleware>
          </StateContextProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CustomizedSnackbar>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
