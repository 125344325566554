import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useDeliveryError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const { unique_id, customer_contact, item_list } = errors;
      if (unique_id) {
        enqueueSnackbar(unique_id.message, {
          variant: "error",
        });
      }
      if (customer_contact) {
        enqueueSnackbar(customer_contact.name.message, {
          variant: "error",
        });
      }
      if (item_list) {
        if (item_list.message) {
          enqueueSnackbar(errors?.item_list?.message, {
            variant: "error",
          });
        }
      }
    }
  }, [enqueueSnackbar, errors]);
  return;
};
