import * as Yup from "yup";
import dayjs from "dayjs";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/sales";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";

export const deliveryOrderSchema: IDeliveryOrder = {
  unique_id: "",
  created_by: null,
  created_date: dayjs().toDate(),
  credit_day: 0,
  issue_date: dayjs(),
  delivery_date: dayjs(),
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  shipping_cost: 0,
  price_vat_type: PriceVatType.ExcludedVat,
  customer_contact: {
    unique_id_name: "",
    name: "",
    phone: "",
    branch: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      address_type: "",
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  sub_total: 0,
  total_amount: 0,
  vat_0_amount: 0,
  vat_7_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  customer_contact_unique_id: null,
  withholding_tax_amount: 0,
  external_reference_id: "",
  tag_list: [],
};

const deliveryOrderItemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณากรอกชื่อสินค้า"),
  qty: Yup.string()
    .required("กรุณากรอกจำนวนสินค้า")
    .test(
      "not-NaN",
      "กรุณากรอกจำนวนสินค้า",
      (value) => value !== "NaN" && !isNaN(parseFloat(value))
    ),
  uom: Yup.string().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    // .typeError("กรุณากรอกจำนวนเงินที่มากกว่า 0")
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    // .min(0, "ราคาต่อหน่วยไม่ต่ำกว่า 0")
    .required("กรุณากรอกราคาต่อหน่วย"),
});

export const deliveryOrderItemListValidation = Yup.object().shape({
  item_list: Yup.array()
    .of(deliveryOrderItemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
});

export const deliveryOrderValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร").nullable(),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
  customer_contact: Yup.object().shape({
    name: Yup.string().required("กรุณาระบุลูกค้า"),
  }),
  item_list: Yup.array()
    .of(deliveryOrderItemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
});
