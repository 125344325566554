import dayjs from "dayjs";
import * as Yup from "yup";

export const projectSchema = {
  id: "",
  unique_id: "",
  name: "",
  description: "",
  img_url: [],
  related_user_list: [],
  responsible_user_list: [],
  customer_contact_unique_id: "",
  tag_list: [],
  project_value: "",
  opportunity_close_sale: 0,
  expected_start_date: dayjs(),
  expected_end_date: dayjs(),
  start_date: dayjs(),
  end_date: dayjs(),
  remark: "",
  created_date: dayjs().toDate(),
  created_by: null,
  attachment_list: [],
  main_status: "",
  sub_status: "",
  aggrid_status: "",
  flag_status: [],
  cancel_remark: "",
  tasks_id_list: [],
  customer_contact: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    billing_address: {
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      address_type: "",
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
};

export const ProjectValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุรหัสโครงการ"),
  name: Yup.string().required("กรุณาระบุชื่อโครงการ"),
  // customer_contact_unique_id: Yup.string().required("กรุณาระบุรหัสลูกค้า"),
  sub_status: Yup.string().required("กรุณาระบุสถานะ"),
  // project_value: Yup.number().typeError(
  //   "กรุณาระบุมูลค่าโครงการเป็นตัวเลขเท่านั้น"
  // ),
  opportunity_close_sale: Yup.number().typeError(
    "กรุณาระบุโอกาสปิดการขายเป็นตัวเลขเท่านั้น"
  ),
});
