import { useState, useEffect, forwardRef, Fragment, useMemo } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

// import ControlledDatePicker from "../Custom/ControlledDatePicker";
// import CustomizedMenuOption from "../Custom/CustomizedMenuOption";
// import ModalUI from "../../UI/ModalUI";
// import RightDrawer from "../../UI/RightDrawer";
// import ContactService from "../../../services/Contact";
// import ActivityLogsService from "../../../services/ActivityLogs";
// import { createActivityLogDeletePayload } from "../../../utils/activityLogsPayloadFormatter";
// import { useAuth } from "../../../hooks/use-auth";
// import { userHavePermission } from "../../../utils/userInfo";

import DropzoneUI from "../../UI/DropzoneUI";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckbox";
import CustomizedRadioGroup from "../../Custom/CustomizedRadioGroup";

import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { useParams } from "react-router-dom";
import { ContactEntityType } from "../../../generated/contact";
import ContactTagList from "../../UI/TagList/ContactTagList";

const defaultContactChannelValues = {
  contact_channel_type: "เบอร์โทรศัพท์",
  contact_channel_name: "",
};

const ContactInformationForm = forwardRef<HTMLInputElement, any>(
  (
    {
      control,
      onCancel,
      setValue,
      getValues,
      watch,
      errors,
      isContactPage,
      disabled,
      generateUniqueId,
    },
    ref
  ) => {
    // const { user } = useAuth();
    const { id } = useParams();
    const { t } = useTranslation();

    const watchContactType = watch("contact_type");
    const contactTypeIsSelected = Boolean(watch("contact_type"));
    const watchBranchType = watch("branch_type");

    const [identityNoError, setIdentityNoError] = useState<boolean>(false);

    const {
      fields: contactFields,
      append: appendContact,
      remove: removeContact,
    } = useFieldArray({
      control,
      name: "contact_channel_list",
    });

    const watchContactFieldArray = watch("contact_channel_list");
    const controlledContactFields = contactFields.map((field, index) => {
      return { ...field, ...watchContactFieldArray[index] };
    });

    const hasContactError =
      Object.keys(errors)?.filter((errorType) =>
        ["is_customer", "is_vendor"].includes(errorType)
      )?.length > 1;

    const coperateTypes = useMemo(
      () => [
        {
          id: 1,
          label: t("contact.info.coperate_type.company"),
          value: "บริษัท",
        },
        {
          id: 2,
          label: t("contact.info.coperate_type.public_limited"),
          value: "บริษัทมหาชนจำกัด",
        },
        {
          id: 3,
          label: t("contact.info.coperate_type.limited_partnership"),
          value: "ห้างหุ้นส่วนจำกัด",
        },
        {
          id: 4,
          label: t("contact.info.coperate_type.juristic_partnership"),
          value: "ห้างหุ้นส่วนสามัญนิติบุคคล",
        },
        {
          id: 5,
          label: t("contact.info.coperate_type.association"),
          value: "สมาคม",
        },
        { id: 6, label: t("contact.info.coperate_type.other"), value: "อื่นๆ" },
      ],
      [t]
    );

    const naturalPersonTypes = useMemo(
      () => [
        {
          id: 1,
          label: t("contact.info.natural_person_type.index"),
          value: "บุคคลธรรมดา",
        },
        {
          id: 2,
          label: t("contact.info.natural_person_type.ordinary_partnership"),
          value: "ห้างหุ้นส่วนสามัญ",
        },
        {
          id: 3,
          label: t("contact.info.natural_person_type.store"),
          value: "ร้านค้า",
        },
        {
          id: 4,
          label: t("contact.info.natural_person_type.entities"),
          value: "คณะบุคคล",
        },
      ],
      [t]
    );

    const radioLists = [
      { value: 1, label: "ใช้งาน" },
      { value: 0, label: "หยุดใช้งาน" },
    ];

    const businessTypes = [
      {
        id: 1,
        label: t("contact.info.coperate_type.index"),
        value: "นิติบุคคล",
      },
      {
        id: 2,
        label: t("contact.info.natural_person_type.index"),
        value: "บุคคลธรรมดา",
      },
    ];

    const prefixes = [
      {
        id: 1,
        label: t("contact.contact_person.prefix.mr"),
        value: t("contact.contact_person.prefix.mr"),
      },
      {
        id: 2,
        label: t("contact.contact_person.prefix.mrs"),
        value: t("contact.contact_person.prefix.mrs"),
      },
      {
        id: 3,
        label: t("contact.contact_person.prefix.ms"),
        value: t("contact.contact_person.prefix.ms"),
      },

      {
        id: 4,
        label: t("contact.contact_person.prefix.sir"),
        value: t("contact.contact_person.prefix.sir"),
      },
      {
        id: 5,
        label: t("contact.contact_person.prefix.none"),
        value: t("contact.contact_person.prefix.none"),
      },
    ];

    const sources = [
      { id: 1, label: "Sales", value: "sales" },
      {
        id: 2,
        label: "Facebook",
        value: "facebook",
      },
      {
        id: 3,
        label: "Instagram",
        value: "instagram",
      },
      {
        id: 4,
        label: "Website",
        value: "website",
      },
      {
        id: 5,
        label: "Email",
        value: "email",
      },
      {
        id: 6,
        label: "Line@",
        value: "line@",
      },
      {
        id: 7,
        label: "โทรศัพท์ call in",
        value: "โทรศัพท์ call in",
      },
      {
        id: 8,
        label: "Marketplace",
        value: "marketplace",
      },
      {
        id: 9,
        label: "อื่นๆ",
        value: "อื่นๆ",
      },
    ];

    const contactChannels = [
      "เบอร์โทรศัพท์",
      "Email",
      "แฟกซ์",
      "Line",
      "Facebook",
      "Website",
      "Instagram",
      "อื่นๆ",
    ];

    const checkBoxField = [
      {
        name: "is_customer",
        label: t("contact.contact_type.customer"),
        xs: 12,
      },
      {
        name: "is_vendor",
        label: t("contact.contact_type.vendor"),
        xs: 12,
      },
    ];

    const branchTypes = [
      {
        id: 1,
        label: "สำนักงานใหญ่",
        value: "hq",
      },
      {
        id: 2,
        label: "สาขา",
        value: "branch",
      },
      {
        id: 3,
        label: "ไม่ระบุ",
        value: "none",
      },
    ];

    const renderCheckBox = (name: string, label: string) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CustomizedCheckboxes
            label={label}
            {...field}
            isDisabled={disabled}
            error={hasContactError}
          />
        )}
      />
    );

    const renderCoperateType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-type"
              name="contact_type_detail"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  fullWidth
                  error={Boolean(errors.contact_type_detail)}
                  helperText={errors.contact_type_detail?.message}
                  label={t("contact.info.coperate_type.index")}
                  options={coperateTypes}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-brand"
              name="main_contact_name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.main_contact_name)}
                  helperText={errors.main_contact_name?.message}
                  label={t("contact.info.brand_name")}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-taxpayer_id"
              name="identity_no"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.identity_no) || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  label={t("contact.info.taxpayer_id")}
                  {...field}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-authorized_capital"
              name="registered_capital"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  type="number"
                  error={Boolean(errors.registered_capital)}
                  helperText={errors.registered_capital?.message}
                  label={t("contact.info.authorized_capital")}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="coperate-source"
              name="contact_source"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_source)}
                  helperText={errors.contact_source?.message}
                  label={t("contact.info.source.index")}
                  options={sources}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="branch-type"
              name="branch_type"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.branch_type)}
                  helperText={errors.branch_type?.message}
                  label={t("contact.info.branch_type")}
                  options={branchTypes}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                  required
                />
              )}
            />
          </Grid>
          {watchBranchType === "branch" && (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  key="branch-id"
                  name="branch_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.branch_id)}
                      helperText={errors.branch_id?.message}
                      label={t("contact.info.branch_id")}
                      {...field}
                      disabled={disabled || !contactTypeIsSelected}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  key="branch-name"
                  name="branch_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.branch_name)}
                      helperText={errors.branch_name?.message}
                      label={t("contact.info.branch_name")}
                      {...field}
                      disabled={disabled || !contactTypeIsSelected}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </>
      );
    };

    const renderContactChannelAction = (type: string, info: string) => {
      if (!info) {
        return null;
      }
      let icon;
      let action;
      switch (type) {
        case "เบอร์โทรศัพท์":
        case "แฟกซ์":
          icon = <PhoneIcon />;
          action = () => {
            window.open(`tel:${info}`, "_self");
          };
          break;
        case "Email":
          icon = <EmailIcon />;
          action = () => {
            window.open("mailto:" + info, "_self");
          };
          break;
        case "Line":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://line.me/R/ti/p/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Facebook":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://facebook.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        case "Website":
          icon = <PublicIcon />;
          action = () => {
            window.open(`https://${info}`, "_blank", "noopener,noreferrer");
          };
          break;
        case "Instagram":
          icon = <LaunchOutlinedIcon />;
          action = () => {
            window.open(
              "https://instagram.com/" + info,
              "_blank",
              "noopener,noreferrer"
            );
          };
          break;
        default:
          icon = null;
          action = () => {};
          break;
      }
      if (!icon) {
        return null;
      }
      return (
        <IconButton
          onClick={action}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {icon}
        </IconButton>
      );
    };

    const renderNaturalPersonType = () => {
      return (
        <>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-type"
              name="contact_type_detail"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_type_detail)}
                  helperText={errors.contact_type_detail?.message}
                  label={t("contact.info.natural_person_type.index")}
                  options={naturalPersonTypes}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-taxpayer_id"
              name="identity_no"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.identity_no) || identityNoError}
                  helperText={
                    identityNoError
                      ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                      : errors.identity_no?.message
                  }
                  label={t("contact.info.taxpayer_id")}
                  {...field}
                  onChange={(e) => {
                    if (e.target.value.length > 13) {
                      setIdentityNoError(true);
                      return getValues("identity_no");
                    }
                    setIdentityNoError(false);
                    return field.onChange(e.target.value);
                  }}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-initial"
              name="title_name"
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.title_name)}
                  helperText={errors.title_name?.message}
                  label={t("contact.contact_person.prefix.index")}
                  options={prefixes}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-firstname"
              name="main_contact_name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.main_contact_name)}
                  helperText={errors.main_contact_name?.message}
                  label={t("contact.contact_person.name.first_name")}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-lastname"
              name="secondary_contact_name"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.secondary_contact_name)}
                  helperText={errors.secondary_contact_name?.message}
                  label={t("contact.contact_person.name.last_name")}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="natural_person-source"
              name="contact_source"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.contact_source)}
                  helperText={errors.contact_source?.message}
                  label={t("contact.info.source.index")}
                  options={sources}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                />
              )}
            />
          </Grid>
          <Box width={"100%"} />
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key="branch-type"
              name="branch_type"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <CustomizedSelect
                  fullWidth
                  error={Boolean(errors.branch_type)}
                  helperText={errors.branch_type?.message}
                  label={t("contact.info.branch_type")}
                  options={branchTypes}
                  {...field}
                  disabled={disabled || !contactTypeIsSelected}
                  required
                />
              )}
            />
          </Grid>
          {watchBranchType === "branch" && (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  key="branch-id"
                  name="branch_id"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.branch_id)}
                      helperText={errors.branch_id?.message}
                      label={t("contact.info.branch_id")}
                      {...field}
                      disabled={disabled || !contactTypeIsSelected}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  key="branch-name"
                  name="branch_name"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.branch_name)}
                      helperText={errors.branch_name?.message}
                      label={t("contact.info.branch_name")}
                      {...field}
                      disabled={disabled || !contactTypeIsSelected}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </>
      );
    };

    const renderContactChannels = () => {
      return controlledContactFields.map((item, index) => {
        return (
          <Grid item xs={12} key={item.id}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                order={{ xs: 2, md: 1 }}
              >
                <Controller
                  control={control}
                  name={`contact_channel_list.${index}.contact_channel_type`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("contact.contact_channel.type")}
                      isOptionEqualToValue={(option: any, value: string) => {
                        if (value === "") {
                          return true;
                        }
                        return option === value;
                      }}
                      onChange={(e: any, value: string) =>
                        field.onChange(value)
                      }
                      options={contactChannels}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
                order={{ xs: 2, md: 3 }}
              >
                <Controller
                  name={`contact_channel_list.${index}.contact_channel_name`}
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_name
                      )}
                      helperText={
                        errors.contact_channel_list?.[index]
                          ?.contact_channel_name?.message
                      }
                      label={
                        item.contact_channel_type ||
                        t("contact.contact_channel.info")
                      }
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              {!disabled && (
                <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeContact(index)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
              {disabled && (
                <Grid item xs={1} order={{ xs: 4 }}>
                  {renderContactChannelAction(
                    item.contact_channel_type,
                    item.contact_channel_name
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      });
    };

    useEffect(() => {
      if (getValues("created_date") === "") {
        setValue("created_date", new Date());
      }
    });

    const image = getValues("img_url");

    return (
      <Fragment>
        <CustomizedBox
          boxShadow={!isContactPage ? "none" : undefined}
          margin={!isContactPage ? 0 : "2rem 0 2rem 0"}
          ref={ref}
        >
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
                  ประเภทผู้ติดต่อ
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ ml: 1 }}>
                      {checkBoxField.map((field) => (
                        <Fragment key={field.label}>
                          {renderCheckBox(field.name, field.label)}
                        </Fragment>
                      ))}
                    </Box>
                    {hasContactError && (
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          sx={{ color: "error.main" }}
                          variant="caption"
                        >
                          กรุณาเลือกประเภทผู้ติดต่อ
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={2}>
                  <Grid
                    item
                    xs={disabled ? 12 : 10}
                    sm={disabled ? 12 : 10}
                    md={6}
                    lg={6}
                    xl={6}
                  >
                    <Controller
                      name="unique_id"
                      control={control}
                      render={({ field }) => (
                        <CustomizedTextField
                          {...field}
                          fullWidth
                          error={Boolean(errors.unique_id)}
                          helperText={errors.unique_id?.message}
                          label={t("contact.document_id")}
                          disabled={disabled || id}
                          onChange={(e) => {
                            const trimmedValue = e.target.value
                              .replaceAll(" ", "")
                              .replaceAll(/\u00a0/g, "");
                            field.onChange(trimmedValue);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {!id && (
                    <Grid item xs={2}>
                      <Box sx={{ ml: -1 }}>
                        <IconButton
                          onClick={() => generateUniqueId(true)}
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                          }}
                        >
                          <CustomizedTooltip
                            title={"เรียกเลขที่ผู้ติดต่อใหม่"}
                            enterNextDelay={200}
                          >
                            <RestartAltOutlinedIcon />
                          </CustomizedTooltip>
                        </IconButton>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {((image && image.length > 0) || !disabled) && (
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <DropzoneUI
                    control={control}
                    setValue={setValue}
                    name="img_url"
                    acceptedFileType="image"
                    maxSize={1}
                    disabled={disabled}
                    // disabled={!isInventoryPage || disabled}
                  />
                </Grid>
              )}
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              ข้อมูลกิจการ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  name="contact_type"
                  control={control}
                  render={({ field }) => (
                    <CustomizedSelect
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        const value = e.target.value;
                        //นิติบุคคล, บุคคลธรรมดา
                        if (value === "นิติบุคคล") {
                          setValue("title_name", "");
                          setValue("secondary_contact_name", "");
                        } else {
                          setValue("registered_capital", "");
                        }
                        setValue("main_contact_name", "");
                        setValue("contact_type_detail", "");
                        setValue("identity_no", "");
                        setValue("contact_source", "");
                        setValue("branch_type", "");
                      }}
                      fullWidth
                      error={Boolean(errors.contact_type)}
                      helperText={errors.contact_type?.message}
                      label={t("contact.info.business_type")}
                      renderValue={(selected: any) => {
                        if (selected.length === 0) {
                          return "เลือกประเภทกิจการ";
                        }
                        return selected;
                      }}
                      options={businessTypes}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              {watchContactType === "บุคคลธรรมดา"
                ? renderNaturalPersonType()
                : renderCoperateType()}
            </Grid>

            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_channel.index")}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomizedButton
                  title={"เพิ่มช่องทางติดต่อ"}
                  variant="outlined"
                  size="medium"
                  onClick={() =>
                    appendContact({ ...defaultContactChannelValues })
                  }
                  disabled={disabled}
                />
              </Grid>
              {renderContactChannels()}
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("contact.contact_status")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <CustomizedRadioGroup
                        radioList={radioLists}
                        row
                        {...field}
                        disabled={disabled}
                      />
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Controller
                  name="status_remark"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.status_remark)}
                      helperText={errors.status_remark?.message}
                      label={t("contact.status_remark")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography fontWeight="bold" my={2} ml={1}>
                  {t("inventory.items.group_tag_list")}
                </Typography>
              </Grid>
              {/* <TagList
                control={control}
                name="tag_list"
                entity={"contact"}
                disabled={!isContactPage || disabled}
                title=""
              /> */}
              <ContactTagList
                control={control}
                disabled={disabled}
                setValue={setValue}
                getValues={getValues}
                name="tag_list"
                entity={ContactEntityType.Contact}
              />
            </Grid>
          </Grid>
        </CustomizedBox>
      </Fragment>
    );
  }
);

export default ContactInformationForm;
