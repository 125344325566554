import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

const Project = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("project.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("project.index"),
      to: "projects",
      icon: <CorporateFareOutlinedIcon color="primary" />,
    },
    {
      name: t("project.event.index"),
      to: "events",
      icon: <EventOutlinedIcon color="primary" />,
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Project;
