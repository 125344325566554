import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CurrentStock from "../../../components/Table/Inventory/Item/CurrentStock";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { IDefaultForm } from "../../../types/global";
import {
  Item,
  ItemSkuDetail,
  ItemSkuQtysQuery,
  ItemType,
  Tracability,
  WarehousesQuery,
  useWarehousesQuery,
} from "../../../generated/inventory";
import { IWarehouse } from "../../../types/Setting/inventory";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { formatDate } from "../../../utils/Formatter/Date";

type ExtentedProps = {
  getValues: IDefaultForm["getValues"];
  itemType: ItemType;
  tableType: string;
  filterType: string;
  skuQtysData?: ItemSkuDetail["item_sku_qty"];
};

const ItemCurrentStockTab = ({
  getValues,
  itemType,
  tableType,
  skuQtysData,
  filterType,
}: ExtentedProps) => {
  const { t } = useTranslation();
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  // const [skuData, setSkuData] = useState<ItemSkuQtysQuery["itemSkuQtys"][]>([]);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: warehouseData, isSuccess: warehouseIsSuccess } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  // useEffect(() => {
  //   if (skuQtysData?.itemSkuQtys) {
  //     const skuType =
  //       skuQtysData.itemSkuQtys as ItemSkuQtysQuery["itemSkuQtys"][];
  //     setSkuData(skuType);
  //   }
  // }, [skuQtysData?.itemSkuQtys]);

  useEffect(() => {
    if (warehouseIsSuccess) {
      const warehouseType =
        warehouseData?.warehouses as unknown as IWarehouse[];
      setWarehouses(warehouseType);
    }
  }, [warehouseData?.warehouses, warehouseIsSuccess]);

  const TemplateBox = ({
    type,
    label,
    data,
  }: {
    type: string;
    label: string;
    data: any;
  }) => (
    <CustomizedBox margin="1rem 0 4rem 0">
      <Typography fontWeight={"bold"} mb={3}>
        {label}
      </Typography>
      <CurrentStock type={type} data={data} />
    </CustomizedBox>
  );

  const mapQtyAndStockQty = (
    itemSku: ItemSkuDetail["item_sku_qty"],
    bundleList: Item["bundle_item_detail_list"]
  ) => {
    const result: {
      warehouse_unique_id: string;
      name: string;
      stock_qty: number;
    }[] = [];

    if (filterType !== "seperate") {
      bundleList?.forEach((item) => {
        const matchingItems = itemSku?.filter(
          (list) => list?.item_unique_id === item?.reference_item_unique_id
        );
        if (matchingItems) {
          const itemQtys = matchingItems.map((item) => item?.stock_qty ?? 0);
          if (itemQtys.length > 0) {
            const minItemQty = Math.min(...itemQtys);
            const bundleQty = item?.qty ?? 0;
            const bundleItemQty = minItemQty / bundleQty;

            const warehouseIds = Array.from(
              new Set(
                matchingItems.map(
                  (item) =>
                    item?.warehouse_level_3?.sub_level_2?.sub_level_1
                      ?.warehouse_unique_id
                )
              )
            ).join(",");

            const warehouseNames = Array.from(
              new Set(
                matchingItems.map(
                  (item) =>
                    item?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse
                      ?.name
                )
              )
            ).join(",");

            result.push({
              warehouse_unique_id: warehouseIds,
              name: warehouseNames,
              stock_qty: Math.floor(bundleItemQty),
            });
          }
        }
      });
      return result;
    } else {
      const newItems: any[] = [];
      bundleList?.forEach((item) => {
        const matchingItems = itemSku?.filter(
          (list) => list?.item_unique_id === item?.reference_item_unique_id
        );
        return matchingItems?.forEach((item) => newItems.push(item));
      });
      const stockQtySum: { [key: string]: number } = {};
      // Iterate through the data and calculate the sum for each warehouse_unique_id and item_unique_id
      newItems?.forEach((item) => {
        const key = `${
          item?.warehouse_level_3?.sub_level_2?.sub_level_1
            ?.warehouse_unique_id || ""
        }_${item?.item_unique_id}`;
        if (stockQtySum[key]) {
          stockQtySum[key] += item?.stock_qty ?? 0;
        } else {
          stockQtySum[key] = item?.stock_qty ?? 0;
        }
      });

      // Create a new array with the aggregated stock_qty for each warehouse_unique_id and item_unique_id
      const newArray: {
        warehouse_unique_id: string;
        item_unique_id: string;
        stock_qty: number;
      }[] = Object.entries(stockQtySum).map(([key, stock_qty]) => {
        const [warehouse_unique_id, item_unique_id] = key.split("_");
        return {
          warehouse_unique_id,
          item_unique_id,
          stock_qty: Math.floor(stock_qty),
        };
      });

      warehouses.forEach((warehouse) => {
        const filterItems = newArray.filter(
          (item) => item.warehouse_unique_id === warehouse.unique_id
        );
        if (filterItems.length !== bundleList?.length) {
          result.push({
            warehouse_unique_id: warehouse.unique_id,
            name: warehouse.name,
            stock_qty: 0,
          });
        } else {
          bundleList?.forEach((item) => {
            const matchingItems = itemSku?.filter(
              (list) => list?.item_unique_id === item?.reference_item_unique_id
            );
            if (matchingItems) {
              const itemQtys = matchingItems.map(
                (item) => item?.stock_qty ?? 0
              );
              if (itemQtys.length > 0) {
                const minItemQty = Math.min(...itemQtys);
                const bundleQty = item?.qty ?? 0;
                const bundleItemQty = minItemQty / bundleQty;

                const warehouseIds =
                  matchingItems[0]?.warehouse_level_3?.sub_level_2?.sub_level_1
                    ?.warehouse_unique_id ?? "";

                const warehouseNames =
                  matchingItems[0]?.warehouse_level_3?.sub_level_2?.sub_level_1
                    ?.warehouse?.name ?? "";

                result.push({
                  warehouse_unique_id: warehouseIds,
                  name: warehouseNames,
                  stock_qty: Math.floor(bundleItemQty),
                });
              }
            }
          });
        }
      });
      return result;

      //<============= not work ==============>
      // const newJsonArr: {
      //   [key: string]: {
      //     item_unique_id: string;
      //     stock_qty: number;
      //   }[];
      // } = {};
      // Object.entries(stockQtySum).forEach(([key, stock_qty]) => {
      //   const [warehouse_unique_id, item_unique_id] = key.split("_");
      //   for (let key2 of Object.keys(newJsonArr)) {
      //     if (key2 === warehouse_unique_id) {
      //       newJsonArr[warehouse_unique_id].push({
      //         item_unique_id,
      //         stock_qty,
      //       });
      //     } else {
      //       newJsonArr[warehouse_unique_id] = [
      //         {
      //           item_unique_id,
      //           stock_qty,
      //         },
      //       ];
      //     }
      //   }
      // });

      // const itemQtys = matchingItems.map((item) => item?.stock_qty ?? 0);
      // if (itemQtys.length > 0) {
      //   const minItemQty = Math.min(...itemQtys);
      //   const bundleQty = item?.qty ?? 0;
      //   const bundleItemQty = minItemQty / bundleQty;

      //   result.push({
      //     warehouse_unique_id: warehouseIds,
      //     name: warehouseNames,
      //     stock_qty: bundleItemQty,
      //   });
      // }
    }
  };

  const sumStockQtyByWarehouse = (
    data: ItemSkuDetail["item_sku_qty"]
  ): {
    warehouse_unique_id: string;
    name: string;
    stock_qty: number;
  }[] => {
    const sumByWarehouse: {
      [key: string]: {
        warehouse_unique_id: string;
        name: string;
        stock_qty: number;
      };
    } = {};

    const bundleList = getValues("bundle_item_detail_list");

    if (itemType !== ItemType.Bundle) {
      data?.forEach((item) => {
        const warehouseId =
          item?.warehouse_level_3?.sub_level_2?.sub_level_1
            ?.warehouse_unique_id || "";
        const stockQty = item?.stock_qty ?? 0;
        const warehouseName =
          item?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse?.name ??
          "";

        if (sumByWarehouse[warehouseId]) {
          sumByWarehouse[warehouseId].stock_qty += stockQty;
        } else {
          sumByWarehouse[warehouseId] = {
            warehouse_unique_id: warehouseId,
            name: warehouseName,
            stock_qty: stockQty,
          };
        }
      });
      const result = Object.values(sumByWarehouse);

      return result;
    } else {
      const res = mapQtyAndStockQty(data, bundleList);
      const sortMinimum = res?.sort((a, b) => a.stock_qty - b.stock_qty);
      if (filterType !== "seperate")
        if (sortMinimum && sortMinimum.length > 0) return [sortMinimum[0]];
        else return res;
      else return res;
    }
  };

  const sumStockQtyByBinLocation = (
    data: ItemSkuDetail["item_sku_qty"]
  ): {
    warehouse_unique_id: string;
    name: string;
    bin_name: string;
    stock_qty: number;
  }[] => {
    const sumByWarehouse: {
      [key: string]: {
        warehouse_unique_id: string;
        name: string;
        bin_name: string;
        stock_qty: number;
      };
    } = {};

    data?.forEach((item) => {
      const warehouseId =
        item?.warehouse_level_3?.sub_level_2?.sub_level_1
          ?.warehouse_unique_id || "";
      const stockQty = item?.stock_qty ?? 0;
      const warehouseName =
        item?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse?.name ??
        "";
      const binName = item?.bin_name ?? "";

      if (sumByWarehouse[binName]) {
        sumByWarehouse[binName].stock_qty += stockQty;
      } else {
        sumByWarehouse[binName] = {
          warehouse_unique_id: warehouseId,
          name: warehouseName,
          bin_name: binName,
          stock_qty: stockQty,
        };
      }
    });

    const result = Object.values(sumByWarehouse);

    return result;
  };

  const sumStockQtyBySerialNumber = (
    data: ItemSkuQtysQuery["itemSkuQtys"]
  ): {
    warehouse_unique_id: string;
    name: string;
    serial_number: string;
    bin_name: string;
    stock_qty: number;
    lot_date: string;
    barcode: string;
  }[] => {
    const sumByWarehouse: {
      [key: string]: {
        warehouse_unique_id: string;
        serial_number: string;
        name: string;
        bin_name: string;
        stock_qty: number;
        lot_date: string;
        barcode: string;
      };
    } = {};

    data?.forEach((item) => {
      const warehouseId =
        item?.warehouse_level_3?.sub_level_2?.sub_level_1
          ?.warehouse_unique_id || "";
      const stockQty = item?.stock_qty ?? 0;
      const warehouseName =
        item?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse?.name ??
        "";
      const binName = item?.bin_name ?? "";
      const sn = item?.serial_no ?? "";
      // const serial_number = item?.barcode ?? "";
      const barcode = item?.barcode ?? "";
      const lot_date = formatDate(item?.lot_date) ?? "";

      if (sumByWarehouse[barcode]) {
        sumByWarehouse[barcode].stock_qty += stockQty;
      } else {
        sumByWarehouse[barcode] = {
          warehouse_unique_id: warehouseId,
          name: warehouseName,
          serial_number: sn,
          bin_name: binName,
          stock_qty: stockQty,
          lot_date: lot_date,
          barcode: barcode,
        };
      }
    });

    const result = Object.values(sumByWarehouse);

    return result;
  };

  const formatData = (type: string) => {
    if (type === "default") {
      const result = sumStockQtyByWarehouse(skuQtysData ?? []);
      return result;
    } else if (type === "bin_location") {
      const result = sumStockQtyByBinLocation(skuQtysData ?? []);
      return result;
    } else if (type === "serial_number") {
      const result = sumStockQtyBySerialNumber(skuQtysData ?? []);
      return result;
    }
  };

  const DefaultTamplate = () => (
    <Fragment>
      <TemplateBox
        type="default"
        data={formatData("default")}
        label={t("inventory.items.onHandItemWarehouseTab")}
      />
      {itemType !== ItemType.Bundle && (
        <Fragment>
          <TemplateBox
            type="bin_location"
            data={formatData("bin_location")}
            label={t("inventory.items.onHandItemWarehouse")}
          />
          {getValues("tracability") !== Tracability.Normal && (
            <TemplateBox
              type="serial_number"
              data={formatData("serial_number")}
              label={t("inventory.items.onHandItemWarehouseSN")}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );

  const QuantityTamplate = () => (
    <Fragment>
      <TemplateBox
        type={tableType}
        data={[]}
        label={t(`inventory.quantities.${tableType}`)}
      />
    </Fragment>
  );

  const TemplateComponent = () => {
    switch (tableType) {
      case "available_qty":
      case "stock_qty":
        return <DefaultTamplate />;
      case "purchase_ordered_qty":
      case "manufacture_ordered_qty":
      case "sale_committed_qty":
      case "manufacture_committed_qty":
        return <QuantityTamplate />;
      default:
        return <></>;
    }
  };

  return (
    <Fragment>
      <TemplateComponent />
    </Fragment>
  );
};

export default ItemCurrentStockTab;
