import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../types/global";

export const usePurchaseOrderOption = (status?: string | null) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    switch (status) {
      case "draft":
        setSelectedModifyOptions([defaultOptions[1], defaultOptions[2]]);
        break;
      case "cancelled":
        setSelectedModifyOptions([defaultOptions[1]]);
        break;
      case "approved":
      case "partially_imported":
      case "fully_imported":
        setSelectedModifyOptions([
          {
            value: "บันทึกใบบันทึกซื้อ",
            disabled: false,
          },
          ...defaultOptions,
        ]);
        break;
      default:
        setSelectedModifyOptions(defaultOptions);
        break;
    }
  }, [defaultOptions, status]);

  return selectModifyOptions;
};
