import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import * as XLSX from "xlsx";
import { useFormContext, useWatch } from "react-hook-form";
import AttachmentCard from "./UI/AttachmentCard";
import dayjs from "dayjs";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

type ImporterDropzoneUIProps = {
  // keys: string[];
  // setImportedDoc: React.Dispatch<React.SetStateAction<any>>;
  // dataFormatter: (data: any) => {};
  // setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  // setErrors: React.Dispatch<React.SetStateAction<any>>;
  // setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  // uniqueIdErrorMesage?: string;
  hasLabelHeader: boolean;
};

const deepen = (obj: any) => {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split(".");

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift() as string;
      target = (target as any)[part] = (target as any)[part] || {};
    }

    // Set value at end of path
    (target as any)[parts[0]] = obj[objectPath];
  }

  Object.keys(result).forEach((key) => {
    if (key.includes("__EMPTY")) {
      delete (result as any)[key];
    }
  });
  return result;
};

const NewImporterDropzoneUI = ({
  // keys,
  // setImportedDoc,
  // dataFormatter,
  // setLoading,
  disabled,
  // setErrors,
  // setHasError,
  hasLabelHeader,
}: // uniqueIdErrorMesage,
ImporterDropzoneUIProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { control, setValue } = useFormContext<any>();

  const filePreview = useWatch({
    control,
    name: "filePreview",
  });

  const removeFile = () => {
    setValue("filePreview", null);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      // setImportedDoc([]);
      // setLoading(true);
      // setErrors([]);
      // setHasError(false);
      const formatFile = Object.assign(acceptedFiles[0], {
        attachment_name: acceptedFiles[0].name,
        uploaded_date: dayjs(),
      });
      setValue("filePreview", formatFile);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        const wb = XLSX.read(binaryStr, { type: "binary", cellDates: true });
        console.log("sheetNames: ", wb.SheetNames)
        // wb.SheetNames.forEach(function (sheetName) {
          const XL_row_object = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            defval: null,
            range: hasLabelHeader ? 1 : undefined,
          });
          const deepened = XL_row_object.map((record) => {
            return deepen(record);
          });
          setValue("file", deepened);
          // const converted = mapNestedData(deepened, uniqueIdErrorMesage);
          // setTempDoc(converted as any);
        // });
      };
      try {
        reader.readAsArrayBuffer(acceptedFiles[0]);
      } catch (err) {
        enqueueSnackbar("ประเภทไฟล์ไม่รองรับ", {
          variant: "error",
        });
        // setLoading(false);
      }
    },
    disabled: disabled,
  });

  // useEffect(() => {
  //   if (!isInit && tempDoc && tempDoc.length > 0) {
  //     const getFormattedData = async () => {
  //       const formattedData = await dataFormatter(tempDoc);
  //       setImportedDoc(formattedData);
  //       console.log(formattedData);
  //     };
  //     getFormattedData();
  //     setIsInit(true);
  //     setLoading(false);
  //   }
  // }, [tempDoc, dataFormatter, setImportedDoc, isInit, setLoading]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  ) as any;

  return filePreview ? (
    <AttachmentCard
      control={control}
      disabled={disabled}
      file={filePreview}
      removeFile={removeFile}
      preventNameChange
    />
  ) : (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>ลากมาวางเพื่ออัปโหลด</p>
      </div>
    </section>
  );
};

export default NewImporterDropzoneUI;
