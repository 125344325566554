import { ColDef, RowDoubleClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useBomColumnDefs } from "../Bom/columnDefs";
import { useTranslation } from "react-i18next";
import { IBom } from "../../../../types/Manufacture/bom";

interface Props {
  gridRef: RefObject<AgGridReact<IBom>>;
  rowData: any[];
}

const ManufactureBomReportTable = ({ gridRef, rowData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useBomColumnDefs(t, [], true);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/order/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default ManufactureBomReportTable;
