import { Box, Typography, Button, Grid, Alert } from "@mui/material";
import { Fragment, ReactInstance, ReactNode, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
// import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import { t } from "i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../ModalUI";
import { Control } from "react-hook-form";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckbox";
interface Props {
  children?: ReactNode;
  documentName: string;
  control: Control<any>;
  isError?: boolean;
  handleSaveToLocalStorage: () => void;
  modal: boolean;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  tempShowOnlyPricedItems: boolean;
  setTempShowOnlyPricedItems: (value: boolean) => void;
}

const CustomPDFLayout = ({
  documentName,
  children,
  control,
  isError,
  handleSaveToLocalStorage,
  modal,
  handleCloseModal,
  handleOpenModal,
  tempShowOnlyPricedItems,
  setTempShowOnlyPricedItems,
}: Props) => {
  const { id } = useParams();
  const componentRef = useRef<ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onCheckboxChange = () => {
    setTempShowOnlyPricedItems(!tempShowOnlyPricedItems);
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5">{documentName}</Typography>
          <Typography>เลขที่เอกสาร {id ? id : "-"}</Typography>
        </Box>
        <Box>
          <Button
            size="small"
            variant="outlined"
            onClick={handleOpenModal}
            sx={{ mr: 2 }}
          >
            ตั้งค่ารูปแบบ
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={handlePrint}
            disabled={isError}
          >
            พิมพ์เอกสาร
          </Button>
        </Box>
      </Box>

      {/* <Box
        sx={{
          width: "90%",
          minWidth: "210mm",
          height: "297mm",
          border: "1px solid #eee",
          borderRadius: "5px",
          overflowY: "auto",
          position: "relative",
          m: "10px auto",
        }}
      >
        <CustomizedScrollbar
          sx={{
            height: "100%",
          }}
        > */}
      <Box
        className={"print-container"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: { xs: undefined, md: "center" },
        }}
        ref={componentRef}
      >
        {children}
      </Box>
      {/* </CustomizedScrollbar>
      </Box> */}
      <ModalUI
        open={modal}
        handleClose={handleCloseModal}
        title={"ตั้งค่ารูปแบบกำหนดเอง"}
      >
        <Fragment>
          <Grid container spacing={2}>
            <Grid item>
              <Alert
                severity="info"
                sx={{
                  color: "#333333",
                  backgroundColor: "#ECF6FD",
                  padding: "1px 16px",
                }}
              >
                ระบบจะจำค่าเฉพาะเครื่องนี่เท่านั้น จะไม่มีผลกับเครื่องอื่นๆ
              </Alert>
              {/* <Typography>
                *ระบบจะจำค่าเฉพาะเครื่องนี่เท่านั้น จะไม่มีผลกับเครื่องอื่นๆ
              </Typography> */}
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name={"default_setting"}
                control={control}
                multiline
                maxRows={10}
                minRows={10}
                label={"Paste JSON configuration here..."}
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={12}> */}
          <Box display="flex" justifyContent="flex-start" marginTop={1}>
            <CustomizedCheckboxes
              label="แสดงเฉพาะรายการมูลค่ามากกว่า 0"
              value={tempShowOnlyPricedItems}
              onChange={onCheckboxChange}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              onClick={handleCloseModal}
              sx={{ mr: 2 }}
            />
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={() => {
                handleSaveToLocalStorage();
              }}
            />
          </Box>
          {/* </Grid> */}
          {/* <Box display="flex" justifyContent="flex-end">
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                handleSaveToLocalStorage();
              }}
            />
          </Box> */}
        </Fragment>
      </ModalUI>
    </>
  );
};

export default CustomPDFLayout;
