import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../../types/global";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedButton from "../../Custom/CustomizedButton";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import RadioBox from "../../UI/RadioBox";
import NewImporterDropzoneUI from "../../NewImporterDropzoneUI";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ContactImportUploader = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const itemTypeOptions: ISelectOption[] = [
    { label: t("contact.index"), value: "contact" },
    { label: t("address.index"), value: "contact_address" },
  ];

  const importType = watch("import_type");
  const type = watch("type");

  const downloadTemplateHandler = () => {
      let fileName = `${importType}_importer_template.xlsx`;
      if (importType === "contact") {
        fileName = "template_ผู้ติดต่อ.xlsx";
      }
      const filePath = `/static/Importer/contact/${fileName}`;
      window.open(filePath, "_blank");    
    // const filePath = `/static/importer/contact/${importType}_importer_template.xlsx`;
    // window.open(filePath, "_blank");
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="import_type"
            options={itemTypeOptions}
            onChange={(e: any) => {
              const value = e.target.value;
              if (value === "contact_address") {
                setValue("type", "create");
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={downloadTemplateHandler}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("sentence.edit")}
            checked={type === "update"}
            disabled={importType === "contact_address"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12}>
          <NewImporterDropzoneUI hasLabelHeader />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ContactImportUploader;
