import { IContact } from "../../types/Contact";
import { IAttachment, ISelectOption } from "../../types/global";
import { uploadFileToS3 } from "../s3";

export const formatContactPayload = async (data: IContact) => {
  // const unixCreatedDate = moment(data.created_date).unix();
  let current_img: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "contact",
        data?.unique_id || ""
      );
      current_img.push(Location);
    } else {
      current_img = data.img_url;
    }
  }

  const transformedContactPersons = await Promise.all(
    data?.contact_person_list?.map(async (person: any, index: number) => {
      let updatedImgUrl: string[] = [];
      if (person.img_url && person.img_url.length > 0) {
        const filteredFile = person.img_url.filter(
          (img: string | File) => img instanceof File
        );

        if (filteredFile && filteredFile.length > 0) {
          const { Location } = await uploadFileToS3(
            filteredFile[0],
            "contact",
            data?.unique_id + person.unique_id + index || ""
          );
          updatedImgUrl.push(Location);
        } else {
          updatedImgUrl = data.img_url;
        }
        return { ...person, img_url: updatedImgUrl || [] };
      }
      return [];
    }) || []
  );

  let attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "contact",
          data?.unique_id || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        attachment_list.push(formatAttachment);
      } else {
        attachment_list.push(file);
      }
    }
  }

  const transformedSalesList = data?.sales_list?.map(
    ({
      id,
      unique_id,
      first_name,
      last_name,
      img_url,
      position,
      email,
    }: any) => ({
      id,
      unique_id,
      first_name,
      last_name,
      position,
      img_url,
      email,
    })
  );
  delete data.id;
  delete data.created_date;
  // check if numeric data is empty string or not, if so, convert to null before submitting
  const updatedContactData = {
    ...data,
    unique_id: data.unique_id,
    // created_date: unixCreatedDate,
    // ? user.document_id
    // : null,
    img_url: current_img,
    contact_person_list: transformedContactPersons,
    attachment_list: attachment_list,
    sales_list: transformedSalesList,
    tag_list: data.tag_list?.map((tag: any) => {
      return tag.name;
    }),
    registered_capital: data.registered_capital ? data.registered_capital : 0,
    estimate_sales_volume: data.estimate_sales_volume
      ? data.estimate_sales_volume
      : null,
    finance: {
      ...data.finance,
      payment_day: data?.finance?.payment_day ? data.finance.payment_day : null,
      billing_day: data?.finance?.billing_day ? data.finance.billing_day : null,
      request_credit: {
        credit_limit_value: data?.finance?.request_credit?.credit_limit_value
          ? data.finance.request_credit.credit_limit_value
          : undefined,
        credit_limit_day: data?.finance?.request_credit?.credit_limit_day
          ? data.finance.request_credit.credit_limit_day
          : undefined,
      },
    },
    is_active: formatBoolean(data.is_active),
  };
  return updatedContactData;
};

const formatBoolean = (value: any) => {
  if ([1, true, "true", "1"].includes(value)) return 1;
  return 0;
};

export const contactTypeOptions: ISelectOption[] = [
  {
    label: "ผู้ติดต่อ",
    value: "all",
  },
  {
    label: "ลูกค้า",
    value: "customer",
  },
  {
    label: "ผู้ขาย",
    value: "vendor",
  },
];
