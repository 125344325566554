import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FieldErrors, FormProvider, useForm, useWatch } from "react-hook-form";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import {
  userSchema,
  validation,
  validationEdit,
} from "../../../components/Form/UserAccount/schema";
import { yupResolver } from "@hookform/resolvers/yup";

import Account from "./Account";
import Permission from "./Permission";
import { IRole, IUser, IUserForm, IUserInfo } from "../../../types/Auth/user";
import {
  UserCreateMutation,
  UserUpdateMutation,
  useUserCreateMutation,
  useUserQuery,
  useUserUpdateMutation,
} from "../../../generated/company-user";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { uploadFileToS3 } from "../../../utils/s3";
import {
  UserInformationCreateMutation,
  useUserInformationCreateMutation,
  useUserInformationUpdateMutation,
  useUserInformationQuery,
  UserInformationUpdateMutation,
} from "../../../generated/user-infomation";
import { mergeUserFormatter } from "../../../utils/Formatter/User";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import LoadingUI from "../../../components/UI/LoadingUI";
import { useStateContext } from "../../../contexts/auth-context";
import { mergeUserPermissions } from "../../../utils/Formatter/Role";
import { Stack } from "@mui/material";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

interface Props {
  isCreate?: boolean;
}

const AccountContainer = ({ isCreate }: Props) => {
  // const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);

  const setting = pathname.split("/")[2] === "setting";

  const {
    dispatch,
    state: { authUser, permissions },
  } = useStateContext();

  const methods = useForm<IUserForm>({
    defaultValues: { ...userSchema },
    resolver: yupResolver(id ? validationEdit : validation, {
      abortEarly: false,
    }),
    criteriaMode: "all",
  });

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const watchEmail = useWatch({ control, name: "email" });
  const watchStatus = useWatch({ control, name: "is_active" });

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isSuccess, isLoading } = useUserQuery(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: { unique_id: id },
    },
    { enabled: !!id }
  );

  const { refetch } = useUserQuery(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: { email: watchEmail.toLowerCase() },
    },
    { enabled: false }
  );

  const { data: userInfoData, isSuccess: isSuccessUserInfo } =
    useUserInformationQuery(
      graphQLClientWithHeaderSetting,
      {
        uniqueInput: { user_unique_id: id },
      },
      { enabled: !!id }
    );

  useEffect(() => {
    if (isSuccess && isSuccessUserInfo) {
      const mergeData = mergeUserFormatter(
        data.user as IUser,
        userInfoData.userinformation as IUserInfo
      );
      reset(mergeData);
      setDisabled(setting ? false : true);
    }
  }, [
    data?.user,
    isSuccess,
    isSuccessUserInfo,
    reset,
    setting,
    userInfoData?.userinformation,
  ]);

  const { mutate: createUser } = useUserCreateMutation<Error>(
    graphQLClientWithHeaderCompany,
    {
      onMutate: () => {
        setDisabled(true);
      },
      onSuccess: ({ userCreate }: UserCreateMutation) => {
        const data = getValues();

        const userInfoPayload = {
          user_unique_id: userCreate?.unique_id ?? "",
          position: data.position ?? "",
          department: data.department ?? "",
          img_url: userCreate?.img_url ?? [],
          status: 1,
          inactive_remarks: "",
          role_id_list: data.role_list?.map((role) => role.id || 0),
        };

        createUserInfo({
          createInput: userInfoPayload,
        });
        // enqueueSnackbar("เพิ่มผู้ใช้งานสำเร็จ", {
        //   variant: "success",
        // });
        // navigate("/user/account/" + userCreate?.unique_id);
      },
      onError: async (error, { createInput }) => {
        const formatError = JSON.stringify(error);
        if (
          formatError.includes(
            "Unique constraint failed on the fields: (`email`)"
          )
        ) {
          const data = getValues();

          const { data: watchData } = await refetch();

          const uniqueId = watchData?.user?.unique_id;

          const userInfoPayload = {
            user_unique_id: uniqueId ?? "",
            position: data.position ?? "",
            department: data.department ?? "",
            img_url: createInput.img_url ?? [],
            status: 1,
            inactive_remarks: "",
            role_id_list: data.role_list?.map((role) => role.id || 0),
          };

          createUserInfo({
            createInput: userInfoPayload,
          });
        } else {
          enqueueSnackbar("เพิ่มผู้ใช้งานไม่สำเร็จ", {
            variant: "error",
          });
          setDisabled(false);
        }
      },
    }
  );

  const { mutate: updateUser } = useUserUpdateMutation<Error>(
    graphQLClientWithHeaderCompany,
    {
      onMutate: () => {
        setDisabled(setting ? false : true);
      },
      onSuccess: ({ userUpdate }: UserUpdateMutation) => {
        if (authUser?.unique_id === userUpdate?.unique_id) {
          dispatch({
            type: "SET_USER",
            payload: userUpdate as IUser,
          });
        }

        const data = getValues();

        const userInfoPayload = {
          position: data.position ?? "",
          department: data.department ?? "",
          img_url: userUpdate?.img_url ?? [],
          status: watchStatus,
          inactive_remarks: "",
          role_id_list: data.role_list?.map((role) => role.id || 0),
        };

        updateUserInfo({
          uniqueInput: { user_unique_id: userUpdate?.unique_id ?? "" },
          updateInput: userInfoPayload,
        });
        // enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
        //   variant: "success",
        // });
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("แก้ไขผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
        setDisabled(false);
      },
    }
  );

  // const { mutate: deleteUser } = useUserDeleteMutation<Error>(graphQLClient);

  const { mutate: createUserInfo, isLoading: creatingUser } =
    useUserInformationCreateMutation<Error>(graphQLClientWithHeaderSetting, {
      onMutate: () => {
        setDisabled(true);
      },
      onSuccess: ({ userInformationCreate }: UserInformationCreateMutation) => {
        enqueueSnackbar("เพิ่มผู้ใช้งานสำเร็จ", {
          variant: "success",
        });
        navigate(
          "/user/account/" +
            userInformationCreate?.user_unique_id +
            "?subtab=inventory"
        );
      },
      onError: (error, variables) => {
        // deleteUser({
        //   uniqueInput: { unique_id: variables.createInput.user_unique_id },
        // });
        enqueueSnackbar("เพิ่มผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
        setDisabled(false);
      },
    });

  const { mutate: updateUserInfo, isLoading: updatingUser } =
    useUserInformationUpdateMutation<Error>(graphQLClientWithHeaderSetting, {
      onMutate: () => {
        setDisabled(setting ? false : true);
      },
      onSuccess: ({ userInformationUpdate }: UserInformationUpdateMutation) => {
        if (authUser?.unique_id === userInformationUpdate?.user_unique_id) {
          const permission = mergeUserPermissions(
            (userInformationUpdate?.role_list as IRole[]) || []
          );

          dispatch({
            type: "SET_PERMISSION",
            payload: permission,
          });
        }

        enqueueSnackbar("แก้ไขผู้ใช้งานสำเร็จ", {
          variant: "success",
        });
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("แก้ไขผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
        setDisabled(false);
      },
    });

  const onSubmit = async (data: IUserForm) => {
    //   //wait to be fixed
    let img_url: string[] = [];
    if (data.img_url && data.img_url.length > 0) {
      const filteredFile = data.img_url.filter(
        (img: string | File) => img instanceof File
      );

      if (filteredFile && filteredFile.length > 0) {
        const { Location } = await uploadFileToS3(
          filteredFile[0],
          "user",
          id ?? ""
        );
        img_url.push(Location);
      } else {
        img_url = data.img_url;
      }
    }
    // const role_list = data.group_role_list?.map(
    //   (role: IRole) => role.document_id
    // );
    const unique_id = uuidv4();
    const newUserPayload = {
      unique_id: id ?? unique_id,
      title_name: data.title_name,
      first_name: data.first_name,
      last_name: data.last_name,
      nick_name: data.nick_name,
      email: data.email.toLowerCase(),
      img_url,
      phone: data.phone?.toString(),
      is_active: data.is_active,
      // role_list: role_list,
      password: "",
    };

    if (data?.new_password === data?.confirm_new_password) {
      newUserPayload.password = data.new_password;
    }
    if (id) {
      //case : update existing user
      const { unique_id, password, is_active, ...otherData } = newUserPayload;
      const otherPayload = {
        ...otherData,
        user_credential: password ? { password } : undefined,
      };
      updateUser({
        uniqueInput: { unique_id },
        updateInput: password === "" ? otherData : otherPayload,
      });
    } else {
      //case : create new user
      const { password, ...otherData } = newUserPayload;
      createUser({
        createInput: { ...otherData, user_credential: { password } },
      });
    }
  };

  const onError = async (errors: FieldErrors<IUserForm>) => {
    if (errors?.new_password?.message) {
      enqueueSnackbar(errors?.new_password?.message, {
        variant: "error",
      });
    }
  };

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
      to: "/user/account",
    },
    {
      name: id
        ? `${data?.user?.first_name ?? ""} ${data?.user?.last_name ?? ""}`
        : t("user.account.add_new_account"),
    },
  ];

  if (id && (isLoading || creatingUser || updatingUser)) {
    return <LoadingUI />;
  }

  return (
    <FormProvider {...methods}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Account
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          disabled={disabled}
          setDisabled={setDisabled}
        />
        {permissions?.role.view === "ALL" && <Permission disabled={disabled} />}
        {!disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                type="submit"
                // onClick={() => {
                //   const data = getValues();
                //   onSubmit(data);
                // }}
              />
            </Stack>
          </BottomNavbar>
        )}
      </form>
    </FormProvider>
  );
};

export default AccountContainer;
