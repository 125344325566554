import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useEventStatusFilterOption = () => {
  const { t } = useTranslation();
  const mapEventStatustoThai = useCallback(
    (status: string) => {
      switch (status) {
        case "planning":
          return t("project.event.status.planning");
        case "appointment":
          return t("project.event.status.appointment");
        case "check_in":
          return t("project.event.status.check_in");
        case "finished":
          return t("project.event.status.finished");
        case "cancelled":
          return t("project.event.status.cancelled");
        default:
          return;
      }
    },
    [t]
  );

  const defaultOptions = useMemo(() => {
    return [
      { id: "ALL", label: "ทั้งหมด", value: "ทั้งหมด" },
      { id: "0", label: t("project.event.status.planning"), value: "planning" },
      {
        id: "1",
        label: t("project.event.status.appointment"),
        value: "appointment",
      },
      { id: "2", label: t("project.event.status.check_in"), value: "check_in" },
      { id: "3", label: t("project.event.status.finished"), value: "finished" },
      {
        id: "4",
        label: t("project.event.status.cancelled"),
        value: "cancelled",
      },
    ];
  }, [t]);

  return {
    eventStatusOptions: defaultOptions,
    mapEventStatustoThai: mapEventStatustoThai,
  };
};
