import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const PurchasePermission = ({ columnDefs }: Props) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "purchase",
        name: t("purchase.index"),
        isFeature: true,
        view: "view",
      },
      {
        functionName: "purchase_request",
        name: t("purchase.request.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
        feature: "purchase",
      },
      {
        functionName: "purchase_order",
        name: t("purchase.order.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
        feature: "purchase",
      },
      {
        functionName: "purchase_return",
        name: t("purchase.return.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
        feature: "purchase",
      },
      {
        functionName: "purchase_report",
        name: t("report"),
        view: "view",
        isNotViewSelf: true,
        feature: "purchase",
      },
    ];
    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default PurchasePermission;
