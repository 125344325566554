import { Grid } from "@mui/material";
import { ChangeEventHandler, FC, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { FileRejection } from "react-dropzone";
import { useSnackbar } from "notistack";
import { FileError } from "react-dropzone";
import { IAttachment, IMenuOption } from "../../../types/global";
import AttachmentCard from "../../UI/AttachmentCard";
import dayjs from "dayjs";
import { useStateContext } from "../../../contexts/auth-context";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
} as const;

const activeStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

const DocDropzoneUI: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  options?: IMenuOption[];
  acceptedFileType?: string;
  maxSize?: number;
  maxFileSize?: number;
  disabled?: boolean;
  isModal?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  disabled,
  isModal,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Dropzone
          control={control}
          name={name}
          setValue={setValue}
          multiple={multiple}
          onChange={(e) =>
            onChange(multiple ? e.target.files : e.target.files?.[0] ?? null)
          }
          options={options}
          acceptedFileType={acceptedFileType}
          maxSize={maxSize}
          disabled={disabled}
          maxFileSize={maxFileSize}
          isModal={isModal}
        />
      )}
    />
  );
};

const Dropzone: FC<{
  control: Control;
  name: string;
  setValue: UseFormSetValue<any>;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  options?: IMenuOption[];
  maxSize?: number;
  maxFileSize?: number;
  acceptedFileType?: string;
  isModal?: boolean;
}> = ({
  control,
  name,
  setValue,
  multiple,
  onChange,
  disabled,
  options,
  acceptedFileType,
  maxSize,
  maxFileSize,
  isModal,
}) => {
  const getAcceptFileType = (
    fileType: string | undefined
  ): {
    "image/*"?: any[];
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"?: any[];
    "application/pdf"?: any[];
  } => {
    switch (fileType) {
      case "image":
        return {
          "image/*": [],
        };
      case "xlsx":
        return {
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
      default:
        return {
          "image/*": [],
          "application/pdf": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { authUser },
  } = useStateContext();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: getAcceptFileType(acceptedFileType),
    maxSize: maxFileSize ? maxFileSize : 1048576,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === "file-too-large") {
            enqueueSnackbar(
              "ไม่สามารถอัปโหลดได้\nเนื่องจากไฟล์มีขนาดใหญ่เกิน 10MB",
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
              }
            );
          }
        });
      });

      const newFiles: (File & IAttachment)[] =
        (!!files?.length && [...files].concat(acceptedFiles)) || acceptedFiles;

      const formatFiles: IAttachment[] = newFiles.map(
        (file: File & IAttachment) => {
          if (file instanceof File)
            return Object.assign(file, {
              attachment_name: file.attachment_name || file.name,
              uploaded_by: {
                user_unique_id: authUser?.unique_id || "",
                first_name: authUser?.first_name || "",
                last_name: authUser?.last_name || "",
              },
              uploaded_date: dayjs(),
              url: URL.createObjectURL(file),
            });
          else return file;
        }
      );

      setValue(name, formatFiles);
    },
    disabled,
    multiple,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = useWatch({
    control,
    name,
  });

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);

    setValue(name, newFiles);
  };

  const renderDocCards = () => {
    if (!Array.isArray(files)) {
      return <></>;
    }
    return files.map((file: any, index: number) => (
      <Grid key={index} item md={3}>
        <AttachmentCard
          control={control}
          disabled={disabled}
          file={file}
          index={index}
          removeFile={removeFile}
          name={name}
        />
      </Grid>
    ));
  };

  return (
    <>
      {!disabled && (
        <Grid item xs={12} sm={12} md={8} lg={8} xl={6} mb={2}>
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>ลากมาวางเพื่ออัปโหลด</p>
            </div>
          </section>
        </Grid>
      )}
      <Grid container spacing={2}>
        {files.length > 0 && renderDocCards()}
      </Grid>
    </>
  );
};

export default DocDropzoneUI;

// const renderDocCards = () => {
//   return controlledFields.map((file, index) => (
//     <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={2}>
//       <AttachmentCard
//         control={control}
//         disabled={disabled}
//         file={file}
//         index={index}
//         removeFile={removeFile}
//         name={name}
//       />
//     </Grid>
//   ));
// };

// return (
//   <>
//     {!disabled && (
//       <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
//         <section className="container">
//           <div {...getRootProps({ style })}>
//             <input {...getInputProps()} />
//             <p>ลากมาวางเพื่ออัปโหลด</p>
//           </div>
//         </section>
//       </Grid>
//     )}
//     <Grid container spacing={2}>
//       {files.length > 0 && renderDocCards()}
//     </Grid>
//   </>
// );
