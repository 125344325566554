import { useMemo } from "react";

interface IProjectTag {
  id: string;
  name: string;
}

export const useProjectTagListOption = (allTags: IProjectTag[]) => {
  const defaultOptions = useMemo(
    () =>
      allTags.map((tag) => {
        return tag.name;
      }),
    [allTags]
  );
  return {
    projectTagListOptions: defaultOptions,
  };
};
