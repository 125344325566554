import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import InformationPackage from "../../../components/Form/Package/Information";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu } from "../../../types/global";

const Package = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.package.sentence.you_package"),
      to: "/setting/package",
    },
  ];

  const companyInfo = sessionStorage.getItem("company-info")
    ? JSON.parse(sessionStorage.getItem("company-info") ?? "")
    : null;

  const expired = companyInfo?.is_expired ?? false;

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={2}
        alignItems="center"
      >
        <Typography variant="h5">
          {t("setting.package.sentence.you_package")}
        </Typography>
      </Box>
      <Typography mt={2} ml={1}>
        {t("setting.package.sentence.support_info")}
      </Typography>
      <InformationPackage
        expired={expired}
        expired_date={companyInfo?.expired_date}
      />
      {/* <ModalUI
        open={expired}
        handleClose={undefined}
        title={t("setting.package.sentence.expired_title")}
      >
        {t("setting.package.sentence.expired_info")}
      </ModalUI> */}
    </Fragment>
  );
};

export default Package;
