import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MultipleStopOutlinedIcon from "@mui/icons-material/MultipleStopOutlined";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";

const Inventory = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.items.index"),
      to: "items",
      icon: <LocalMallOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.goods_receive.index"),
      to: "goods_receive",
      icon: <FileDownloadOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.goods_return.index"),
      to: "goods_return",
      icon: <SettingsBackupRestoreOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.goods_issue.index"),
      to: "goods_issue",
      icon: <FileUploadOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.goods_transfer.index"),
      to: "goods_transfer",
      icon: <MultipleStopOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.goods_adjustment.index"),
      to: "goods_adjustment",
      icon: <BuildCircleOutlinedIcon color="primary" />,
    },
    {
      name: t("report"),
      to: "report",
      icon: <ContentPasteOutlinedIcon color="primary" />,
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default Inventory;
