import { Box, Button, Typography, useMediaQuery, Theme } from "@mui/material";
import { ReactNode } from "react";
import CustomizedButton from "../../Custom/CustomizedButton";
import { useNavbar } from "../../../hooks/use-navbar";

interface Props {
  documentName: string;
  documentId?: string;
  children?: ReactNode;
  handlePrint: () => void;
}

const PDFWrapper = ({
  documentName,
  documentId,
  children,
  handlePrint,
}: Props) => {
  const navbar = useNavbar();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return (
    <>
      <Box
        sx={(theme) => ({
          position: isMobile ? "block" : "fixed",
          top: "65px",
          width: "100%",
          zIndex: 1100,
          marginLeft: "-1.5rem",
          padding: "1.5rem 1.5rem 1rem 1.5rem",
          backgroundColor: "white",
          ...(navbar?.sidebar.isSidebarOpen && {
            width: `calc(100% - 259px)`,
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        })}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography variant="h5" component="span">
              {documentName}
            </Typography>
            <Typography ml={1} component="span">
              {documentId ?? ""}
            </Typography>
          </Box>
          {!isMobile && (
            <Box>
              <Button size="small" variant="outlined" onClick={handlePrint}>
                พิมพ์เอกสาร
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "5rem" }} />
      {children}
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            bottom: "0",
            width: "100%",
            zIndex: 1100,
            backgroundColor: "white",
            padding: "0.75rem 0",
            margin: "0 -1.5rem",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ width: "150px" }}>
            <CustomizedButton
              title="พิมพ์เอกสาร"
              fullWidth
              variant="outlined"
              onClick={handlePrint}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PDFWrapper;
