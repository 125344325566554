import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import SalesReturnTable from "../../../components/Table/Sales/Return";

const SalesReturnTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" mb={3}>
        {t("sales.return.index")}
      </Typography>
      <SalesReturnTable referenceUniqueId={id} />
    </>
  );
};

export default SalesReturnTab;
