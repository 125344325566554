import * as Yup from "yup";
import { characterRegExp } from "../../../utils/Formatter/Global";

export const uomSchema = {
  unique_id: "",
  name: "",
};

export const uomValidation = Yup.object().shape({
  name: Yup.string()
    .required("กรุณาระบุชื่อหน่วย")
    .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
});
