import { useCallback, useEffect, useState, Fragment } from "react";
import { IDefaultForm } from "../../../types/global";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import addressData from "../../../data/address.json";
import { IAddress } from "../../../types/Setting/company";
import { Box, Grid, Typography } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { useTranslation } from "react-i18next";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import NewAddress from "./NewAddress";

const defaultNewAddressValues: IAddress = {
  address_type: "",
  is_default: false,
  is_same_as_default_address: false,
  address_contact_name: "",
  address_contact_phone: "",
  address: "",
  sub_district: "",
  district: "",
  province: "",
  postal_code: "",
  country: "",
};

const countryOptions = ["ไทย"];

const AddressForm = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
}: IDefaultForm) => {
  const { t } = useTranslation();
  const [subDistrictOptions, setSubDistrictOptions] = useState<string[]>([]);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<string[]>([]);
  const [searchSubDistrict, setSearchSubDistrict] = useState<
    string | null | undefined
  >("");
  const [searchDistrict, setSearchDistrict] = useState<
    string | null | undefined
  >("");
  const [searchProvince, setSearchProvince] = useState<
    string | null | undefined
  >("");

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "address_list",
  });

  const watchFieldArray = useWatch({ control, name: "address_list" });
  const isSameAddress = watchFieldArray?.map((field: IAddress) => {
    return field.is_same_as_default_address;
  });

  const addNewAddressHandler = () => {
    setSearchSubDistrict(undefined);
    setSearchDistrict(undefined);
    setSearchProvince(undefined);
    append({ ...defaultNewAddressValues });
  };

  const updateAdditionalAddressHandler = (
    name: string,
    newValue: string | null
  ) => {
    let additionalAdresses = getValues("address_list").slice(1);
    additionalAdresses.forEach((address: IAddress, index: number) => {
      if (
        address.is_same_as_default_address &&
        address[name as keyof IAddress] !== newValue
      ) {
        const updatedAddress = { ...address, [name]: newValue };
        update(index + 1, updatedAddress);
      }
    });
  };

  const updateFilter = useCallback(() => {
    let filteredAddressData = addressData;
    if (searchSubDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.sub_district.includes(searchSubDistrict)
      );
    }
    if (searchDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.district.includes(searchDistrict)
      );
    }
    if (searchProvince) {
      filteredAddressData = addressData.filter((item) =>
        item.province.includes(searchProvince)
      );
    }
    let filteredProvinces: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredProvinces.includes(item.province)) {
        filteredProvinces = [...filteredProvinces, item.province];
      }
    });
    setProvinceOptions(filteredProvinces);

    let filteredDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredDistricts.includes(item.district)) {
        filteredDistricts = [...filteredDistricts, item.district];
      }
    });
    setDistrictOptions(filteredDistricts);

    let filteredSubDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredSubDistricts.includes(item.sub_district)) {
        filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
      }
    });
    setSubDistrictOptions(filteredSubDistricts);
  }, [searchDistrict, searchProvince, searchSubDistrict]);

  useEffect(() => {
    updateFilter();
  }, [updateFilter]);

  const renderNewAddress = fields
    .slice(1)
    .map((item, index) => (
      <NewAddress
        key={item.id}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        update={update}
        remove={remove}
        disabled={disabled}
        itemId={item.id}
        index={index + 1}
        isSameAddress={isSameAddress}
      />
    ));

  return (
    <Fragment>
      {!disabled && (
        <Box sx={{ mb: 2 }}>
          <CustomizedButton
            title={"เพิ่มที่อยู่"}
            variant="outlined"
            size="medium"
            onClick={addNewAddressHandler}
            disabled={disabled}
          />
        </Box>
      )}
      <Typography fontWeight="bold" mb={2} ml={1}>
        ที่อยู่จดทะเบียน
      </Typography>
      {fields.slice(0, 1).map((item) => (
        <Grid container spacing={2} key={item.id}>
          <Grid item xs={12}>
            <Controller
              key={`${item.id}-address_main`}
              name={"address_list.0.address"}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  error={Boolean(errors.address_list?.["0"]?.address)}
                  helperText={errors.address_list?.["0"]?.address?.message}
                  label={t("address.index")}
                  {...field}
                  onBlur={(e) => {
                    updateAdditionalAddressHandler("address", e.target.value);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-subdistrict`}
              name={"address_list.0.sub_district"}
              control={control}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={subDistrictOptions}
                  label={t("address.sub_district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchSubDistrict(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("sub_district", "");
                      return field.onChange("");
                    }
                    setSearchSubDistrict(option);
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.sub_district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      const fieldsValue = {
                        ...getValues("address_list")[0],
                        district: filter[0].district,
                        province: filter[0].province,
                        postal_code: filter[0].postal_code,
                        country: "ไทย",
                      };
                      setValue("address_list.0", fieldsValue);
                      updateAdditionalAddressHandler(
                        "district",
                        filter[0].district
                      );
                      updateAdditionalAddressHandler(
                        "province",
                        filter[0].province
                      );
                      updateAdditionalAddressHandler(
                        "postal_code",
                        filter[0].postal_code
                      );
                      updateAdditionalAddressHandler("country", "ไทย");
                    } else if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "sub_district",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("sub_district", option);
                    return field.onChange(option);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-district`}
              name={"address_list.0.district"}
              control={control}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={districtOptions}
                  label={t("address.district")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchDistrict(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("district", "");
                      return field.onChange("");
                    }
                    setSearchDistrict(option);
                    const filter = addressData.filter((adddress) => {
                      return `${adddress.district}` === `${option}`;
                    });
                    if (filter && filter.length !== 0) {
                      const filteredSubdistricts = filter.map(
                        (address) => address.sub_district
                      );
                      setSubDistrictOptions(filteredSubdistricts);
                      setValue("address_list.0.province", filter[0].province);
                      setValue(
                        "address_list.0.postal_code",
                        filter[0].postal_code
                      );
                      setValue("address_list.0.country", "ไทย");
                      updateAdditionalAddressHandler(
                        "province",
                        filter[0].province
                      );
                      updateAdditionalAddressHandler("country", "ไทย");
                    } else if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "district",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("district", option);
                    return field.onChange(option);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-province`}
              name={"address_list.0.province"}
              control={control}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={provinceOptions}
                  label={t("address.province")}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "reset") {
                      return;
                    } else {
                      setSearchProvince(undefined);
                      updateFilter();
                    }
                  }}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("province", "");
                      return field.onChange("");
                    }
                    setSearchProvince(option);
                    if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "province",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("province", option);
                    setValue("address_list.0.country", "ไทย");
                    updateAdditionalAddressHandler("country", "ไทย");
                    return field.onChange(option);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-postal_code`}
              name={"address_list.0.postal_code"}
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  error={Boolean(errors.address?.postal_code)}
                  helperText={errors.address?.postal_code?.message}
                  label={t("address.postal_code")}
                  onBlur={(e) => {
                    updateAdditionalAddressHandler(
                      "postal_code",
                      e.target.value
                    );
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Controller
              key={`${item.id}-address_main-country`}
              name="address_list.0.country"
              control={control}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  options={countryOptions}
                  label={t("address.country")}
                  onChange={(e, option) => {
                    if (!option) {
                      updateAdditionalAddressHandler("country", "");
                      return field.onChange("");
                    }
                    if (option.value) {
                      updateAdditionalAddressHandler("country", option.value);
                      return field.onChange(option.value);
                    }
                    if (option.inputValue) {
                      updateAdditionalAddressHandler(
                        "country",
                        option.inputValue
                      );
                      return field.onChange(option.inputValue);
                    }
                    updateAdditionalAddressHandler("country", option);
                    return field.onChange(option);
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
      {renderNewAddress}
    </Fragment>
  );
};

export default AddressForm;
