import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  styled,
  StepConnector,
  stepConnectorClasses,
  Theme,
  useMediaQuery,
  MobileStepper,
  Typography,
  stepLabelClasses,
  StepIcon,
} from "@mui/material";
import { ReactNode } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface Props {
  steps: { label: string; value: string }[];
  activeStep: number;
  error?: string;
  percentageProgress?: number;
}

const StepperUI = ({ steps, activeStep, error, percentageProgress }: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const RenderCustomizedStepIcon = (props: StepIconProps) => {
    const { completed, error } = props;
    if (completed) return <CheckIcon color="success" />;
    if (error) return <CloseIcon color="error" />;

    return <StepIcon {...props} />;
  };

  const CustomizedStepLabel = styled(StepLabel)(({ theme }) => ({
    [`& .${stepLabelClasses.label}`]: {
      [`&.${stepLabelClasses.active}`]: {
        color: theme.palette.primary.main,
      },
      [`&.${stepLabelClasses.completed}`]: {
        color: "#737373",
      },
      [`&.${stepLabelClasses.error}`]: {
        color: "#737373",
      },
      color: "#737373",
    },
  }));

  const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: 2,
      borderRadius: 1,
    },
  }));

  const CustomizedMobileStepper = styled(MobileStepper)<{ error?: string }>(
    ({ theme, error }) => ({
      "& .MuiLinearProgress-bar": {
        borderRadius: "4px",
        // height: "4px",
        // top: "2px",
        background: error
          ? "#E2E2E2"
          : "linear-gradient(90deg, #2786ED 0%, #226EE1 19%, #226EE1 53%, #43A7EF 97%)",
      },
    })
  );

  return !isMobile ? (
    <Box sx={{ width: "100%" }} my={4}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomizedConnector />}
        sx={{
          ".MuiSvgIcon-root.Mui-active": {
            color: (theme) => theme.palette.primary.main,
            borderRadius: "50%",
            border: "2px solid #BFC5E9",
          },
        }}
      >
        {steps.map((step, index) => {
          const labelProps: {
            error?: boolean;
            icon?: ReactNode;
          } = {};
          const stepProps: { completed?: boolean } = {};

          if (error && activeStep === index) {
            labelProps.error = true;
          }

          if (activeStep === steps.length - 1) {
            stepProps.completed = true;
          }

          return (
            <Step key={step.value} {...stepProps}>
              <CustomizedStepLabel
                {...labelProps}
                StepIconComponent={RenderCustomizedStepIcon}
              >
                {step.label}
              </CustomizedStepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  ) : (
    <Box my={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Typography color="#737373">
          {activeStep > -1 && steps[activeStep].label}
        </Typography>
        {activeStep !== steps.length - 1 && (
          <ArrowForwardIcon sx={{ color: "#737373" }} />
        )}
        <Typography color="#737373">
          {activeStep > -1 &&
            activeStep !== steps.length - 1 &&
            steps[activeStep + 1].label}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <CustomizedMobileStepper
          variant="progress"
          steps={101}
          position="static"
          activeStep={percentageProgress}
          LinearProgressProps={{
            sx: {
              width: "100%",
              borderRadius: "4px",
              height: "8px",
              backgroundColor: "#F2F2F2",
            },
          }}
          sx={{ width: "100%" }}
          backButton={null}
          nextButton={null}
          error={error}
        />
        <Typography sx={{ color: "#737373" }}>
          {activeStep + 1}/{steps.length}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepperUI;
