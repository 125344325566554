import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Typography } from "@mui/material";
import { IGoodsTransfer } from "../../../../types/Inventory/goodsTransfer";

interface Props {
  control: IDefaultForm<IGoodsTransfer>["control"];
  nestedIndex: number;
}

const TotalPostedQuantityCell = ({ control, nestedIndex }: Props) => {
  const watchSerialList =
    useWatch({
      control,
      name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
    }) || [];

  const totalPostedQuantity = watchSerialList.reduce((prev, curr) => {
    if (curr.qty) {
      if (typeof curr.qty === "string") {
        return prev + parseInt(curr.qty);
      } else {
        return prev + curr.qty;
      }
    } else {
      return prev + 0;
    }
  }, 0);

  return <Typography fontSize={14}>{totalPostedQuantity}</Typography>;
};

export default TotalPostedQuantityCell;
