import { useState } from "react";

export const useModal = () => {
  const [modal, setModal] = useState<boolean>(false);

  const openModalHandler = () => {
    setModal(true);
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  return {
    modal,
    openModalHandler,
    closeModalHandler,
  };
};
