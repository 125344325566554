import { useRef, useState } from "react";
import { IItemGroup } from "../../../../types/Inventory/item";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import AgGrid from "../../../UI/AgGrid";
import {
  ItemGroupLevel3sQuery,
  useItemGroupLevel3DeleteMutation,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { Box, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useModal } from "../../../../hooks/use-modal";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import { useSnackbar } from "notistack";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import LevelThreeModal from "../../../Form/Category/LevelThreeModal";
import { errorMessageFormatter } from "../../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";

interface Props {
  data?: ItemGroupLevel3sQuery;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ItemGroupLevel3sQuery, unknown>>;
}

const LevelThreeTable = ({ data, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef<IItemGroup>(null);
  const [currentId, setCurrentId] = useState<number>();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { mutate } = useItemGroupLevel3DeleteMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess() {
        enqueueSnackbar(
          `ลบ${t("setting.inventory.category.level_three")}สำเร็จ`,
          {
            variant: "success",
          }
        );
        refetch();
      },
      onError(error) {
        const errorMessage = errorMessageFormatter(error);
        enqueueSnackbar(
          errorMessage ||
            `ลบ${t("setting.inventory.category.level_three")}ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  const { modal, openModalHandler, closeModalHandler } = useModal();
  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(() =>
    mutate({
      uniqueInput: {
        id: currentId,
      },
    })
  );

  const onEditHandler = (id?: number) => {
    setCurrentId(id);
    openModalHandler();
  };

  const onDeleteHandler = (id?: number) => {
    setCurrentId(id);
    openConfirmationHandler();
  };

  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "ชื่อ",
      filter: "agTextColumnFilter",
    },
    {
      field: "",
      filter: false,
      sortable: false,
      width: 100,
      cellStyle: {
        display: "flex",
        justifyContent: "center",
      },
      cellRenderer: (params: ICellRendererParams<IItemGroup>) => {
        if (params.data) {
          return (
            <Box>
              <IconButton onClick={() => onEditHandler(params.data?.id)}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  return (
    <>
      <AgGrid
        columnDefs={columnDefs}
        ref={gridRef}
        rowData={data?.itemGroupLevel3s || []}
        height={650}
        disabledSidebar
      />
      <LevelThreeModal
        open={modal}
        closeModalHandler={closeModalHandler}
        currentId={currentId}
        refetch={refetch}
      />
      <Confirmation
        title={`ยันยืนการลบ${t("setting.inventory.category.level_three")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default LevelThreeTable;
