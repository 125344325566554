import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import RbacTable from "../../../components/Table/Rbac";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

const Rbac = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const breadcrumbs = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.rbac.index"),
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("user.rbac.index")}</Typography>
        <CustomizedButton
          title={t("user.rbac.add_new_role")}
          variant="contained"
          onClick={() => navigate(`${pathname}/add?subtab=inventory`)}
        />
      </HeaderLayout>
      <RbacTable />
    </>
  );
};

export default Rbac;
