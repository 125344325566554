import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import QuotationReportTable from "../../../components/Table/Sales/Quotation/Report";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { exportCSVParams } from "../../../utils/Formatter/AgGridFilter";
import { AgGridReact } from "ag-grid-react";
import { IQuotation } from "../../../types/Sales/quotation";
import { useCallback, useRef } from "react";
import { useSnackbar } from "notistack";
import {
  QuotationFindManyAggridQuery,
  SortOrder,
  useQuotationFindManyAggridQuery,
} from "../../../generated/sales";
import { salesExportFormatter } from "../../../utils/Formatter/Report";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

const QuotationReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IQuotation>>(null);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("report"),
      to: "/sales/report",
    },
    {
      name: t("sales.quotation.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });

  const onBtnExport = useCallback(() => {
    let startDate = getValues("dateFrom");
    let endDate = getValues("dateTo");
    const params = exportCSVParams(
      t("sales.quotation.index"),
      startDate,
      endDate
    );
    if (gridRef && gridRef.current) {
      gridRef.current.api.exportDataAsCsv(params);
      enqueueSnackbar("นำออกใบเสนอราคาสำเร็จ", {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, getValues, t]);

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { data, refetch } =
    useQuotationFindManyAggridQuery<QuotationFindManyAggridQuery>(
      graphQLClientWithHeaderSales,
      {
        aggridInput: {
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: {
            issue_date: {
              filterType: "date",
              type: "inRange",
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "created_date",
            },
          ],
        },
      },
      {
        cacheTime: 0,
        enabled: false,
      }
    );

  const getFilteredData = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const onFilterReset = async () => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setFilterModel({});
    }
    await reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    await refetch();
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("sales.quotation.index")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
        future
      />
      <QuotationReportTable
        gridRef={gridRef}
        rowData={
          salesExportFormatter(
            data?.quotationFindManyAggrid.data,
            "quotation"
          ) || []
        }
      />
    </>
  );
};

export default QuotationReport;
