import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";
import { Theme, useMediaQuery } from "@mui/material";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
  },
  {
    thaiLabel: "จำนวนนำเข้าแล้ว",
    width: 100,
  },
  {
    thaiLabel: "จำนวนรอนำเข้า",
    width: 100,
  },

  {
    thaiLabel: "จำนวนที่จะนำเข้า",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    label: "",
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 150,
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

const defaultScanHeader: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่",
    width: 200,
  },
  {
    thaiLabel: "",
    width: 75,
  },
  {
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
  {
    thaiLabel: "พิมพ์",
    width: 60,
  },
];

export const useGoodsReceiveItemList = (step: number, disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (!disabled) {
      if (step === 1) {
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        if (isMobile) {
          const newHeader = defaultScanHeader.filter(
            (_, index) => index !== 12
          );
          setHeaders(newHeader);
        } else {
          setHeaders([...defaultScanHeader]);
        }
      }
    } else {
      if (step === 1) {
        setHeaders(defaultHeaders);
      } else {
        const newHeader = defaultScanHeader.filter((_, index) => index !== 9);
        if (isMobile) {
          const newMobileHeader = newHeader.filter((_, index) => index !== 11);
          setHeaders(newMobileHeader);
        } else {
          setHeaders(newHeader);
        }
      }
    }
  }, [disabled, isMobile, step]);

  return headers;
};
