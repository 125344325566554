import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DeliveryOrderTable from "../../../components/Table/Logistic/DeliveryOrder";
// import SalesOrderTable from "../../../components/Table/Sales/Order";

const DeliveryOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("logistic.index"),
      to: "/sales",
    },
    {
      name: t("logistic.delivery_order.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_deliver"),
      path: `${pathname}?filter=wait_deliver`,
    },
    {
      label: t("status.completed"),
      path: `${pathname}?filter=completed`,
    },
    {
      label: t("status.not_completed"),
      path: `${pathname}?filter=not_completed`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {t("logistic.delivery_order.index")}
        </Typography>
        <CustomizedButton
          title={`${t("button.create")}${t("logistic.delivery_order.index")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <DeliveryOrderTable isFilter={isFilter} />
    </>
  );
};

export default DeliveryOrder;
