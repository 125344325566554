import { Box, Typography, Link, TablePagination } from "@mui/material";
import { SlideInPanel, SlideInPanelProps } from "./SlideInPanel";
import {
  ContactActivityLog,
  ActivityType,
  ContactActivityLogDocumentType,
  ContactActivityLogFindManyByReferenceIdQuery,
  useContactActivityLogFindManyByReferenceIdQuery,
} from "../../../generated/contact";

import {
  InventoryActivityLogDocumentType,
  InventoryActivityLogFindManyByReferenceIdQuery,
  useInventoryActivityLogFindManyByReferenceIdQuery,
} from "../../../generated/inventory";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Fragment, useState, useEffect, useMemo } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
// import ActivityLogDetail from "../../Form/ActivityLog/Detail";
import { Link as RouterLink } from "react-router-dom";
import { formatDateTime } from "../../../utils/Formatter/Date";
// import { useDisable } from "../../../hooks/use-disable";
import { useActivityLog } from "../../../hooks/use-activity-log";

const ICON_MAP: Record<ActivityType, typeof DeleteOutlineIcon> = {
  [ActivityType.AddRelatedEmployee]: PersonAddAltOutlinedIcon,
  [ActivityType.Approve]: DoneOutlinedIcon,
  [ActivityType.Cancel]: ClearOutlinedIcon,
  [ActivityType.Comment]: CommentOutlinedIcon,
  [ActivityType.Copy]: ContentCopyOutlinedIcon,
  [ActivityType.Create]: AddOutlinedIcon,
  [ActivityType.Delete]: DeleteOutlineIcon,
  [ActivityType.DeleteRelatedEmployee]: PersonRemoveOutlinedIcon,
  [ActivityType.Document]: DescriptionOutlinedIcon,
  [ActivityType.Edit]: EditOutlinedIcon,
  [ActivityType.Image]: ImageOutlinedIcon,
  [ActivityType.StatusChange]: ArrowForwardOutlinedIcon,
  [ActivityType.Active]: CheckOutlinedIcon,
  [ActivityType.InActive]: CloseOutlinedIcon,
  [ActivityType.ChangePassword]: EditIcon,
  [ActivityType.EditPermission]: EditIcon,
  [ActivityType.CreateImporter]: AddOutlinedIcon,
  [ActivityType.EditImporter]: EditIcon,
  [ActivityType.FactChange]: EditIcon,
  [ActivityType.MainBom]: EditIcon,
  [ActivityType.MainRouting]: EditIcon,
};

const queryObjectKey = (documentType: string) => {
  switch (documentType) {
    case "contact":
      return "ContactActivityLogFindManyByReferenceId";
    case "goods_receive":
      return "InventoryActivityLogFindManyByReferenceId";
    default:
      return "";
  }
};

const formatActivityType = (activityType: ActivityType | undefined) => {
  switch (activityType) {
    case ActivityType.AddRelatedEmployee:
      return "เพิ่มพนักงานที่เกี่ยวข้อง";
    case ActivityType.Approve:
      return "อนุมัติ";
    case ActivityType.Cancel:
      return "ยกเลิก";
    // case ActivityType.CancelPendingScan:
    //   return "ยกเลิกจำนวนที่ค้าง";
    case ActivityType.Comment:
      return "แสดงความคิดเห็น";
    case ActivityType.Copy:
      return "คัดลอก";
    case ActivityType.Create:
      return "สร้าง";
    case ActivityType.Delete:
      return "ลบ";
    case ActivityType.DeleteRelatedEmployee:
      return "ลบพนักงานที่เกี่ยวข้อง";
    case ActivityType.Document:
      return "เอกสาร";
    case ActivityType.Edit:
      return "แก้ไข";
    case ActivityType.Image:
      return "รูปภาพ";
    case ActivityType.StatusChange:
      return "เปลี่ยนสถานะ";
    case ActivityType.CreateImporter:
      return "สร้างโดยการนำเข้าไฟล์"
    default:
      return "";
  }
};

type Props = Pick<SlideInPanelProps, "open" | "handleClose"> & {
  documentId: string | number;
  documentType: string;
  handleClose: () => void;
};

export const ActivityLogSlideInPanel = ({
  documentType,
  documentId,
  handleClose,
  ...props
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState("desc");
  // const [disabled] = useDisable();
  const sortOptions = [
    { id: 1, value: "desc", label: "ใหม่ไปเก่า" },
    { id: 2, value: "asc", label: "เก่าไปใหม่" },
  ];

  const { activityLogIsInit, setActivityLogIsInit } = useActivityLog();

  const graphQLClientContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const graphqlClientInventory: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: contactData, refetch: refetchContactData } =
    useContactActivityLogFindManyByReferenceIdQuery<
      Array<ContactActivityLogFindManyByReferenceIdQuery>
    >(
      graphQLClientContact,
      {
        findManyInput: {
          reference_id:
            typeof documentId === "string" ? parseInt(documentId) : documentId,
          document_type: documentType as ContactActivityLogDocumentType,
        },
      },
      {
        enabled: !activityLogIsInit && documentType === "contact",
      }
    );

  const { data: inventoryData, refetch: refetchInventoryData } =
    useInventoryActivityLogFindManyByReferenceIdQuery<
      Array<InventoryActivityLogFindManyByReferenceIdQuery>
    >(
      graphqlClientInventory,
      {
        findManyInput: {
          reference_id:
            typeof documentId === "string" ? parseInt(documentId) : documentId,
          document_type: documentType as InventoryActivityLogDocumentType,
        },
      },
      {
        enabled: !activityLogIsInit && documentType === "goods_receive",
      }
    );

  useEffect(() => {
    if (!activityLogIsInit) {
      switch (documentType) {
        case "contact":
          refetchContactData();
          break;
        case "goods_receive":
          refetchInventoryData();
          break;
        default:
          break;
      }
    }
  }, [
    activityLogIsInit,
    refetchContactData,
    refetchInventoryData,
    documentType,
  ]);

  useEffect(() => {
    if (!activityLogIsInit && documentId) {
      setActivityLogIsInit(true);
    }
    return () => {
      if (activityLogIsInit) {
        setActivityLogIsInit(false);
      }
    };
  }, [
    activityLogIsInit,
    documentId,
    setActivityLogIsInit,
    contactData,
    inventoryData,
    refetchContactData,
    refetchInventoryData,
  ]);

  const sortedData = useMemo(() => {
    const data = documentType === "contact" ? contactData : inventoryData;
    const key = queryObjectKey(documentType);
    if ((data as any)?.[key]?.length > 0) {
      return [...(data as any)?.[key]].sort((a: any, b: any) => {
        const dateComparison =
          new Date(a.created_date).getTime() -
          new Date(b.created_date).getTime();

        // in case of same date, sort by id (happens through status change)
        if (dateComparison === 0) {
          return sortBy === "asc" ? a.id - b.id : b.id - a.id;
        } else {
          return sortBy === "asc" ? dateComparison : -dateComparison;
        }
      });
    } else {
      return [];
    }
  }, [contactData, inventoryData, documentType, sortBy]);

  const paginatedData = useMemo(() => {
    return sortedData.slice(page * 5, page * 5 + 5);
  }, [sortedData, page]);

  const handleSelectSortOption = (value: string) => {
    setSortBy(value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Fragment>
      <SlideInPanel
        title="การเคลื่อนไหว"
        handleClose={handleClose}
        {...props}
        width="560px"
      >
        {/* {!disabled && <ActivityLogDetail />} */}
        <CustomizedScrollbar
        // sx={{
        //   height: "calc(100vh - 9rem)",
        // }}
        >
          <CustomizedBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minHeight: "400px",
              height: "calc(100vh - 9rem)",
              fontSize: "0.875rem",
            }}
          >
            <CustomizedSelect
              options={sortOptions}
              label="เรียงลำดับ"
              onChange={(e: any) => handleSelectSortOption(e.target.value)}
              value={sortBy}
              sx={{
                mb: 0.5,
                width: "115px",
              }}
            />
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                minHeight: "100px",
                position: "relative",
              }}
            >
              {paginatedData?.map((row: ContactActivityLog, index: number) => {
                const Icon =
                  ICON_MAP[row.activity_type || ActivityType.Approve];
                return (
                  <Fragment key={row.id}>
                    {index > 0 && (
                      <Box
                        sx={{
                          borderRight: "1px solid #c8c8c8",
                          height: "40px",
                          width: "calc(50% - 0.5px)",
                        }}
                      ></Box>
                    )}
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 24px 1fr",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Box>วันที่ {formatDateTime(row?.created_date)}</Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          bgcolor: "#F5F5F5",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography fontWeight={600} fontSize={"0.875rem"}>
                            {formatActivityType(
                              row.activity_type as ActivityType
                            )}
                            {row.activity_type === ActivityType.Approve &&
                              ` ${row.activity_detail?.message?.[0]}`}
                          </Typography>
                          {row.activity_type === ActivityType.StatusChange && (
                            <CustomizedStatus
                              status={row.activity_detail?.curr_status}
                              fontSize="0.75rem"
                            />
                          )}
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              display: "inline-block",
                              fontSize: "0.875rem",
                            }}
                          >
                            โดย
                          </Typography>
                          <Link
                            component={RouterLink}
                            key={index}
                            style={{ marginLeft: "0.25rem" }}
                            underline="none"
                            onClick={() => {
                              handleClose();
                            }}
                            to={`/user/account/${row.created_by?.user_unique_id}?subtab=inventory`}
                          >
                            {`${row.created_by?.first_name} ${row.created_by?.last_name}`}
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Fragment>
                );
              })}
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={sortedData.length}
                rowsPerPage={5}
                page={page}
                onPageChange={handleChangePage}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              />
            </Box>
          </CustomizedBox>
        </CustomizedScrollbar>
      </SlideInPanel>
    </Fragment>
  );
};
