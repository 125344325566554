import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const ManufacturePermission = ({ columnDefs }: Props) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "manufacture",
        name: t("manufacture.index"),
        view: "view",
        isFeature: true,
      },
      {
        functionName: "bom",
        name: t("manufacture.bom.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
        feature: "manufacture",
        isNotViewSelf: true,
      },
      {
        functionName: "manufacture_order",
        name: t("manufacture.order.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
        feature: "manufacture",
      },
      {
        functionName: "routing",
        name: t("manufacture.routing.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
        feature: "manufacture",
        isNotViewSelf: true,
      },
      {
        functionName: "manufacture_report",
        name: t("report"),
        view: "view",
        isNotViewSelf: true,
        feature: "manufacture",
      },
    ];
    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default ManufacturePermission;
