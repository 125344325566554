import DashboardCard from "../DashboardCard";
import DashboardSection from "../DashboardSection";
import { useForm, useWatch } from "react-hook-form";
import { IPeriodFilter } from "../../../../../types/Dashboard";
import { periodFilterSchema } from "../../../../Form/Dashboard/schema";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DashboardChartCard from "../DashboardChartCard";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  QUOTATIONS_AGGRID,
  SALES_ORDER_AGGRID,
  SALES_RETURN_AGGRID,
} from "../../../../../services/AgGrid/SalesAgGrid";
import {
  formatSOChartData,
  getTotalValue,
} from "../../../../../utils/Formatter/dashboard";
import {
  MostSellItemByStatusQuery,
  SalesDashboard,
  useMostSellItemByStatusQuery,
} from "../../../../../generated/sales";

dayjs.extend(relativeTime);
dayjs.locale(th);

const SalesSection = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useForm<IPeriodFilter>({
    defaultValues: periodFilterSchema,
  });
  // const [latestFetchedTime, setLatestFetchedTime] = useState<Dayjs>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [qaWaitApprove, setQaWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [qaWaitAccept, setQaWaitAccept] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [srWaitApprove, setSrWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [soWaitApprove, setSoWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });
  const [salesOrderChartData, setSalesOrderChartData] = useState<
    (string | number)[][]
  >([]);

  const cardList = useMemo(
    () => [
      {
        title: t("sales.quotation.index"),
        status: t("status.wait_approve"),
        quantity: qaWaitApprove.quantity,
        totalValue: qaWaitApprove.totalValue,
        linkTo: "/sales/quotation?filter=wait_approve",
      },
      {
        title: t("sales.order.index"),
        status: t("status.wait_approve"),
        quantity: soWaitApprove.quantity,
        totalValue: soWaitApprove.totalValue,
        linkTo: "/sales/order?filter=wait_approve",
      },
      {
        title: t("sales.quotation.index"),
        status: t("status.wait_accept"),
        quantity: qaWaitAccept.quantity,
        totalValue: qaWaitAccept.totalValue,
        linkTo: "/sales/quotation?filter=wait_accept",
      },
      {
        title: t("sales.return.index"),
        status: t("status.wait_approve"),
        quantity: srWaitApprove.quantity,
        totalValue: srWaitApprove.totalValue,
        linkTo: "/sales/return?filter=wait_approve",
      },
    ],
    [
      qaWaitAccept.quantity,
      qaWaitAccept.totalValue,
      qaWaitApprove.quantity,
      qaWaitApprove.totalValue,
      soWaitApprove.quantity,
      soWaitApprove.totalValue,
      srWaitApprove.quantity,
      srWaitApprove.totalValue,
      t,
    ]
  );

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const { isLoading: isLoadingMostSalesItems, refetch: refetchMostSellItems } =
    useMostSellItemByStatusQuery<MostSellItemByStatusQuery>(
      graphQLClientWithHeaderSales,
      {
        salesDashboardInput: {
          started_date: dateFrom,
          ended_date: dateTo,
          status: "finished",
          number_most_sell_item: 5,
        },
      },
      {
        enabled: false,
        onSuccess: ({ MostSellItemByStatus }) => {
          formatChartData(MostSellItemByStatus);
        },
      }
    );

  const getQuotationData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { quotationFindManyAggrid } =
          await graphQLClientWithHeaderSales.request(QUOTATIONS_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });

        const count = quotationFindManyAggrid.count as number;
        const data = quotationFindManyAggrid.data as any[];

        const totalValue = getTotalValue(data);

        if (status === "wait_approve") {
          setQaWaitApprove(() => {
            return { totalValue: totalValue, quantity: count };
          });
        } else if (status === "wait_accept") {
          setQaWaitAccept(() => {
            return { totalValue: totalValue, quantity: count };
          });
        }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSalesOrderData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { salesOrdersFindManyAggrid } =
          await graphQLClientWithHeaderSales.request(SALES_ORDER_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });

        const count = salesOrdersFindManyAggrid.count as number;
        const data = salesOrdersFindManyAggrid.data as any[];

        if (status === "wait_approve") {
          const totalValue = getTotalValue(data);
          setSoWaitApprove(() => {
            return { totalValue: totalValue, quantity: count };
          });
        }
        // if (status === "finished") {
        //   storeChartData(data);
        // }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSalesReturnData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { salesReturnFindManyAggrids } =
          await graphQLClientWithHeaderSales.request(SALES_RETURN_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });

        const count = salesReturnFindManyAggrids.count as number;
        const data = salesReturnFindManyAggrids.data as any[];

        const totalValue = getTotalValue(data);

        if (status === "wait_approve") {
          setSrWaitApprove(() => {
            return { totalValue: totalValue, quantity: count };
          });
        }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getAllData = useCallback(
    async (dateFrom?: Date | null, dateTo?: Date | null) => {
      setIsLoading(true);
      refetchMostSellItems();
      await getQuotationData("wait_accept", dateFrom, dateTo);
      await getQuotationData("wait_approve", dateFrom, dateTo);
      await getSalesOrderData("wait_approve", dateFrom, dateTo);
      await getSalesReturnData("wait_approve", dateFrom, dateTo);

      setIsLoading(false);

      // setLatestFetchedTime(dayjs());
    },
    [
      getQuotationData,
      getSalesOrderData,
      getSalesReturnData,
      refetchMostSellItems,
    ]
  );

  const formatChartData = (data?: SalesDashboard[] | null) => {
    const formatData = formatSOChartData(data ?? []);

    setSalesOrderChartData(formatData);
  };

  // const onFilterReset = async () => {
  //   await reset(periodFilterSchema);
  //   await getAllData(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
  // };

  const onFilterChanged = () => {
    getAllData(dateFrom, dateTo);
  };

  // const onRefresh = () => {
  //   getAllData(dateFrom, dateTo);
  // };

  useEffect(() => {
    getAllData(dateFrom, dateTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardSection
      title={t("sales.index")}
      control={control}
      // onRefresh={onRefresh}
      // onFilterReset={onFilterReset}
      getFilteredData={onFilterChanged}
      getValues={getValues}
      setValue={setValue}
      // latestFetchTime={latestFetchedTime}
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <DashboardChartCard
            data={salesOrderChartData}
            height="100%"
            isLoading={isLoading && isLoadingMostSalesItems}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} md={8}>
          {cardList.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={6}>
              <DashboardCard
                title={card.title}
                documentCount={card.quantity}
                status={card.status}
                totalValue={card.totalValue}
                linkTo={card.linkTo}
                isLoading={isLoading}
                expandHeight
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DashboardSection>
  );
};

export default SalesSection;
