import { AttributeType } from "../../../generated/inventory";
import * as Yup from "yup";
import { characterRegExp } from "../../../utils/Formatter/Global";

export const attributeSchema = {
  acronym: "",
  name: "",
  description: "",
  data_type: AttributeType.Number,
};

export const attributeValidation = Yup.object().shape({
  name: Yup.string()
    .required("กรุณาระบุคุณสมบัติ")
    .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
  acronym: Yup.string()
    .required("กรุณาระบุตัวย่อ")
    .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
});
