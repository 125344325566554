import { TFunction } from "i18next";

import { ColDef, ValueFormatterParams } from "ag-grid-community";

import { formatDate } from "../../../../utils/Formatter/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";

import CustomizedStatus from "../../../Custom/CustomizedStatus";

import { CreatablesQuery } from "../../../../generated/creatable";
import { useEffect, useState } from "react";

export const useBomColumnDefs = (
  t: TFunction,
  type: CreatablesQuery["creatables"],
  isReport: boolean
) => {
  const [columnDef, setColumnDef] = useState<ColDef[]>([]);

  useEffect(() => {
    if (!isReport) {
      setColumnDef([
        {
          field: "name",
          headerName: t("manufacture.bom.bom_name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "item_unique_id",
          headerName: t("manufacture.bom.item_unique_id"),
          filter: "agTextColumnFilter",
        },

        {
          field: "item_name",
          headerName: t("manufacture.bom.item_name"),
          filter: "agTextColumnFilter",
        },
        {
          field: "mfg_type",
          headerName: t("manufacture.bom.type"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: async (params: any) => {
              // fetch values from server
              const values = type;
              const formatValues = values.map((tag) => tag.name);
              params.success(formatValues);
            },
          },
        },
        {
          field: "created_date",
          headerName: t("sales.createdDate"),
          sort: "desc",
          hide: true,
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
          width: 200,
          suppressColumnsToolPanel: true,
        },
        {
          field: "start_date",
          headerName: t("manufacture.bom.start_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "end_date",
          headerName: t("manufacture.bom.end_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueFormatter: (params: ValueFormatterParams) =>
            formatDate(params.value),
        },
        {
          field: "internal_remark",
          headerName: t("manufacture.bom.internal_remark"),
          filter: "agTextColumnFilter",
        },
        {
          field: "is_active",
          headerName: t("manufacture.bom.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: ({ value }: { value: number }) => {
              if (value) return "ใช้งาน";
              return "หยุดใช้งาน";
            },
            values: [1, 0],
          },
          cellRenderer: ({ value }: { value: boolean }) => {
            if (value) return <CustomizedStatus status="active" />;
            return <CustomizedStatus status="inactive" />;
          },
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "is_main_bom",
          headerName: t("manufacture.bom.is_main_bom"),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams) =>
              statusValueFormatter(params.value),
            values: [1, 0],
          },
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value) {
              return "ใช่";
            } else {
              return "ไม่";
            }
          },
        },
      ]);
    } else {
      setColumnDef([
        {
          field: "name",
          headerName: t("manufacture.bom.bom_name"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 250,
        },
        {
          field: "mfg_type",
          headerName: t("manufacture.bom.type"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },
        {
          field: "is_active",
          headerName: t("manufacture.bom.status"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) => {
              if (value) return "ใช้งาน";
              return "หยุดใช้งาน";
            },
            keyCreator: (params: any) => {
              return params.value === 1 ? 1 : 0;
            },
          },
          cellRenderer: ({ value }: { value: boolean }) => {
            if (value) return <CustomizedStatus status="active" />;
            return <CustomizedStatus status="inactive" />;
          },
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
          minWidth: 150,
        },
        {
          field: "created_date",
          headerName: t("manufacture.bom.created_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => formatDate(params.data.created_date),
          width: 200,
        },
        {
          field: "start_date",
          headerName: t("manufacture.bom.start_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => formatDate(params.data.start_date),
          minWidth: 150,
        },
        {
          field: "end_date",
          headerName: t("manufacture.bom.end_date"),
          filter: "agDateColumnFilter",
          filterParams: dateFilterParams,
          valueGetter: (params) => formatDate(params.data.end_date),
          minWidth: 150,
        },
        {
          field: "product_id",
          headerName: t("manufacture.bom.item_unique_id"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },

        {
          field: "product_name",
          headerName: t("manufacture.bom.item_name"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 250,
        },
        {
          field: "rank",
          headerName: t("manufacture.bom.rank"),
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          suppressMenu: true,
          floatingFilter: false,
          sortable: false,
          width: 100,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          headerClass: "ag-end-header",
        },
        {
          field: "item_id",
          headerName: t("manufacture.order.ingredient_unique_id"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },

        {
          field: "item_name",
          headerName: t("manufacture.order.ingredient_name"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 250,
        },

        {
          field: "qty",
          headerName: t("manufacture.bom.ingredient_qty"),
          filter: "agNumberColumnFilter",
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 150,
          floatingFilter: false,
          sortable: false,
          headerClass: "ag-end-header",
        },
        {
          field: "uom_item",
          headerName: t("manufacture.bom.ingredient_uom"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
          floatingFilter: false,
          sortable: false,
        },
        {
          field: "cost_per_unit",
          headerName: t("manufacture.ingredient.cost_per_unit"),
          filter: "agNumberColumnFilter",
          valueFormatter: (params) =>
            params.value ? params.value.toLocaleString() : 0,
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          minWidth: 150,
          floatingFilter: false,
          sortable: false,
          headerClass: "ag-end-header",
        },
        {
          field: "mfg_qty",
          headerName: t("manufacture.bom.mfg_qty_report"),
          filter: "agNumberColumnFilter",
          cellStyle: { display: "flex", justifyContent: "flex-end" },
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
          headerClass: "ag-end-header",
        },
        {
          field: "uom_product",
          headerName: t("manufacture.bom.stock_uom_report"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },
        {
          field: "internal_remark",
          headerName: t("manufacture.bom.internal_remark"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },
        {
          field: "description",
          headerName: t("manufacture.bom.bom_description"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          minWidth: 150,
        },
        {
          field: "is_main",
          headerName: t("manufacture.bom.is_main_bom"),
          filter: "agSetColumnFilter",
          filterParams: {
            values: [1, 0],
            valueFormatter: ({ value }: { value: number }) => {
              if (value) return "ใช่";
              return "ไม่ใช่";
            },
            keyCreator: (params: any) => {
              return params.value === 1 ? 1 : 0;
            },
          },
          valueFormatter: ({ value }: { value: number }) => {
            if (value) return "ใช่";
            return "ไม่ใช่";
          },
          minWidth: 150,
        },
        {
          field: "created_by",
          headerName: t("sentence.created_by"),
          filter: "agTextColumnFilter",
          valueFormatter: (params) => {
            return params.value ? params.value : "-";
          },
          width: 200,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return columnDef;
};
