import { useTranslation } from "react-i18next";
import {
  IBreadcrumbsAndMenu,
  ITab,
  IImporterError,
} from "../../../../types/global";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import ItemImportUploader from "../../../../components/Form/Inventory/Item/ImportUploader";
import CustomizedTab from "../../../../components/Custom/CustomizedTab";
import ItemImporterTable from "../../../../components/Table/Inventory/Item/Importer";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { CustomizedBox } from "../../../../components/Custom/CustomizedBox";
import {
  ItemType,
  ValidateItemImportNewQuery,
  useImportNormalItemsMutation,
  useValidateItemImportNewQuery,
} from "../../../../generated/inventory";
import { useState } from "react";
import {
  formatFloat,
  formatInt,
  formatString,
  formatStringToArray,
} from "../../../../utils/dataTransformer";
import { useSnackbar } from "notistack";
import {
  mapNestedData,
  addImporterErrorHandler,
} from "../../../../utils/Importer";
import CustomizedSteppers from "../../../../components/Custom/CustomizedStepper";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";
import ImporterErrorModal from "../../../../components/UI/ImporterErrorModal";
import { useModal } from "../../../../hooks/use-modal";
import { validateItemFieldOptions } from "../../../../utils/validator/item-import";

const keys: string[] = ["uom_group.uom_conversion_list", "attribute_list"];

// const requiredKeys = [
//   "unique_id",
//   "name",
//   "is_active",
//   "item_level_1_unique_id",
//   "tracability",
//   "stock_uom_unique_id",
// ];

const requiredKeys = [
  { label: "ชื่อสินค้า", value: "name" },
  { label: "หมวดหมู่สินค้า 1", value: "item_level_1_unique_id" },
  { label: "หน่วยมาตรฐาน", value: "stock_uom_unique_id" },
];

const columns = [
  "unique_id",
  "name",
  "seller_sku",
  "description",
  "is_purchasable",
  "is_saleable",
  "is_manufacturable",
  "is_stockable",
  "is_active",
  "remark_status",
  "image_list",
  "item_group_sub_level_1_unique_id",
  "item_group_sub_level_2_unique_id",
  "item_group_sub_level_3_unique_id",
  "tag_list",
  "width",
  "width_uom_unique_id",
  "length",
  "length_uom_unique_id",
  "height",
  "height_uom_unique_id",
  "thickness",
  "thickness_uom_unique_id",
  "package_width",
  "package_length",
  "package_height",
  "package_weight",
  "purchase_standard_price",
  "purchase_minimum_qty",
  "purchase_vat_type",
  "sales_standard_price",
  "sales_special_price",
  "sales_vat_type",
  "tracibility_mode",
  "barcode",
  "stock_uom_unique_id",
  "sales_uom_unique_id",
  "purchase_uom_unique_id",
  "deliver_uom_unique_id",
  "uom_group",
  "minimum_stock_qty",
  "maximum_stock_qty",
  "reorder_point_qty",
  "manufacture_type",
  "manufacture_minimum_qty",
  "mrp_default_method",
  "attribute_list",
];

const numeric_columns = [
  {
    key: "width",
    name: "กว้าง (สินค้า)",
  },
  {
    key: "length",
    name: "ยาว (สินค้า)",
  },
  {
    key: "height",
    name: "สูง (สินค้า)",
  },
  {
    key: "thickness",
    name: "หนา (สินค้า)",
  },
  {
    key: "package_width",
    name: "กว้าง (ขนส่ง)",
  },
  {
    key: "package_length",
    name: "ยาว (ขนส่ง)",
  },
  {
    key: "package_height",
    name: "สูง (ขนส่ง)",
  },
  {
    key: "package_weight",
    name: "น้ำหนัก (ขนส่ง)",
  },
  {
    key: "purchase_standard_price",
    name: "ราคาซื้อต่อหน่วย",
  },
  {
    key: "purchase_minimum_qty",
    name: "จำนวนสั่งซื้อขั้นต่ำ",
  },
  {
    key: "sales_standard_price",
    name: "ราคาขายต่อหน่วย",
  },
  {
    key: "sales_special_price",
    name: "ราคาขายพิเศษต่อหน่วย",
  },
  {
    key: "minimum_stock_qty",
    name: "Minimum Stock",
  },
  {
    key: "maximum_stock_qty",
    name: "Maximum Stock",
  },
  {
    key: "reorder_point_qty",
    name: "Reorder Point",
  },
  {
    key: "manufacture_minimum_qty",
    name: "จำนวนสั่งผลิตขั้นต่ำ",
  },
];

const count_limit = 5000;

const ItemImporter = () => {
  const {
    state: { authUser },
  } = useStateContext();
  const [rowData, setRowData] = useState<any[]>([]);
  const [errorData, setErrorData] = useState<any[]>([]);
  const [itemUniqueIds, setItemUniqueIds] = useState<string[]>([]);
  const [barcodes, setBarcodes] = useState<string[]>([]);
  const [itemNames, setItemNames] = useState<string[]>([]);
  const [skuNames] = useState<string[]>([]);
  const [widthUomUniqueIds, setWidthUomUniqueIds] = useState<string[]>([]);
  const [lengthUomUniqueIds, setLengthUomUniqueIds] = useState<string[]>([]);
  const [heightUomUniqueIds, setHeightUomUniqueIds] = useState<string[]>([]);
  const [thicknessUomUniqueIds, setThicknessUomUniqueIds] = useState<string[]>(
    []
  );
  const [stockUomUniqueIds, setStockUomUniqueIds] = useState<string[]>([]);
  const [salesUomUniqueIds, setSalesUomUniqueIds] = useState<string[]>([]);
  const [purchaseUomUniqueIds, setPurchaseUomUniqueIds] = useState<string[]>(
    []
  );
  const [deliverUomUniqueIds, setDeliverUomUniqueIds] = useState<string[]>([]);
  const [conversionUomUniqueIds, setConversionUomUniqueIds] = useState<
    string[]
  >([]);
  const [attributeNames, setAttributeNames] = useState<string[]>([]);

  const [itemGroupsLevel1UniqueIds, setItemGroupsLevel1UniqueIds] = useState<
    string[]
  >([]);
  const [itemGroupsLevel2UniqueIds, setItemGroupsLevel2UniqueIds] = useState<
    string[]
  >([]);
  const [itemGroupsLevel3UniqueIds, setItemGroupsLevel3UniqueIds] = useState<
    string[]
  >([]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { modal, openModalHandler, closeModalHandler } = useModal();

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
      to: "/inventory/items",
    },
    {
      name: `นำเข้า${t("inventory.items.index")}`,
    },
  ];
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();

  const methods = useForm<any>({
    defaultValues: {
      import_type: ItemType.Normal,
      type: "create",
      filePreview: null,
      file: null,
      step: 0,
      errors: [],
    },
  });

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const graphqlClient = createGraphQLClientWithMiddleware("item");

  const { refetch: validateItem, isFetching: isValidating } =
    useValidateItemImportNewQuery<ValidateItemImportNewQuery>(
      graphqlClient,
      {
        validateInput: {
          import_mode: getValues("type"),
          barcode_list: barcodes,
          item_level_1_unique_id_list: itemGroupsLevel1UniqueIds,
          item_level_2_unique_id_list: itemGroupsLevel2UniqueIds,
          item_level_3_unique_id_list: itemGroupsLevel3UniqueIds,
          item_name_list: itemNames,
          item_unique_id_list: itemUniqueIds,
          sku_name_list: skuNames,
          width_uom_unique_id_list: widthUomUniqueIds,
          length_uom_unique_id_list: lengthUomUniqueIds,
          height_uom_unique_id_list: heightUomUniqueIds,
          thickness_uom_unique_id_list: thicknessUomUniqueIds,
          stock_uom_unique_id_list: stockUomUniqueIds,
          sales_uom_unique_id_list: salesUomUniqueIds,
          purchase_uom_unique_id_list: purchaseUomUniqueIds,
          deliver_uom_unique_id_list: deliverUomUniqueIds,
          conversion_uom_unique_id_list: conversionUomUniqueIds,
          attribute_name_list: attributeNames,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: createOrUpdateItems, isLoading: isCreatingOrUpdating } =
    useImportNormalItemsMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      // const inputAttributes = data
      //   .map((item: any) =>
      //     item.attribute_list.map((attribute: any) =>
      //       attribute.attribute_name?.toString().trim()
      //     )
      //   )
      //   .flat();

      // const uniqueFoundAttributes = [
      //   ...Array.from(new Set(inputAttributes)),
      // ].filter((attr) => attr) as string[];

      // setAttributeNames(uniqueFoundAttributes);

      const allItemUniqueIds: string[] = [];
      const allItemNames: string[] = [];
      const allItemSkuNames: string[] = [];
      const allItemBarcodes: string[] = [];
      const allItemWidthUoms: string[] = [];
      const allItemLengthUoms: string[] = [];
      const allItemHeightUoms: string[] = [];
      const allItemThicknessUoms: string[] = [];
      const allItemStockUoms: string[] = [];
      const allItemSalesUoms: string[] = [];
      const allItemPurchaseUoms: string[] = [];
      const allItemDeliverUoms: string[] = [];
      const allItemAttributeNames: string[] = [];

      const itemGroupsLevel1: string[] = [];
      const itemGroupsLevel2: string[] = [];
      const itemGroupsLevel3: string[] = [];

      data.forEach((item: any) => {
        const level_1 = item.item_group_sub_level_1_unique_id
          ?.toString()
          .trim();
        const level_2 = item.item_group_sub_level_2_unique_id
          ?.toString()
          .trim();
        const level_3 = item.item_group_sub_level_3_unique_id
          ?.toString()
          .trim();

        if (level_1) {
          itemGroupsLevel1.push(level_1);
        }
        if (level_2) {
          itemGroupsLevel2.push(level_2);
        }
        if (level_3) {
          itemGroupsLevel3.push(level_3);
        }
        if (item.unique_id) {
          allItemUniqueIds.push(item.unique_id?.toString().trim());
        }
        if (item.name) {
          allItemNames.push(item.name?.toString().trim());
        }
        if (item.seller_sku) {
          allItemSkuNames.push(item.seller_sku?.toString().trim());
        }
        if (item.barcode) {
          allItemBarcodes.push(item.barcode?.toString().trim());
        }
        if (item.width_uom_unique_id) {
          allItemWidthUoms.push(item.width_uom_unique_id?.toString().trim());
        }
        if (item.length_uom_unique_id) {
          allItemLengthUoms.push(item.length_uom_unique_id?.toString().trim());
        }
        if (item.height_uom_unique_id) {
          allItemHeightUoms.push(item.height_uom_unique_id?.toString().trim());
        }
        if (item.thickness_uom_unique_id) {
          allItemThicknessUoms.push(
            item.thickness_uom_unique_id?.toString().trim()
          );
        }
        if (item.stock_uom_unique_id) {
          allItemStockUoms.push(item.stock_uom_unique_id?.toString().trim());
        }
        if (item.sales_uom_unique_id) {
          allItemSalesUoms.push(item.sales_uom_unique_id?.toString().trim());
        }
        if (item.purchase_uom_unique_id) {
          allItemPurchaseUoms.push(
            item.purchase_uom_unique_id?.toString().trim()
          );
        }
        if (item.deliver_uom_unique_id) {
          allItemDeliverUoms.push(
            item.deliver_uom_unique_id?.toString().trim()
          );
        }
        if (item.attribute_list?.length > 0) {
          item.attribute_list.forEach((attribute: any) => {
            if (attribute.attribute_name) {
              allItemAttributeNames.push(
                attribute.attribute_name?.toString().trim()
              );
            }
          });
        }
      });

      const uniqueItemGroupsLevel1 = [...Array.from(new Set(itemGroupsLevel1))];
      const uniqueItemGroupsLevel2 = [...Array.from(new Set(itemGroupsLevel2))];
      const uniqueItemGroupsLevel3 = [...Array.from(new Set(itemGroupsLevel3))];
      const uniqueWidthUoms = [...Array.from(new Set(allItemWidthUoms))];
      const uniqueLengthUoms = [...Array.from(new Set(allItemLengthUoms))];
      const uniqueHeightUoms = [...Array.from(new Set(allItemHeightUoms))];
      const uniqueThicknessUoms = [
        ...Array.from(new Set(allItemThicknessUoms)),
      ];
      const uniqueStockUoms = [
        ...Array.from(new Set(allItemStockUoms)),
        "เซนติเมตร",
        "กิโลกรัม",
      ];
      const uniqueSalesUoms = [...Array.from(new Set(allItemSalesUoms))];
      const uniquePurchaseUoms = [...Array.from(new Set(allItemPurchaseUoms))];
      const uniqueDeliverUoms = [...Array.from(new Set(allItemDeliverUoms))];
      const uniqueAttributeNames = [
        ...Array.from(new Set(allItemAttributeNames)),
      ];

      setItemGroupsLevel1UniqueIds(uniqueItemGroupsLevel1);
      setItemGroupsLevel2UniqueIds(uniqueItemGroupsLevel2);
      setItemGroupsLevel3UniqueIds(uniqueItemGroupsLevel3);
      setItemUniqueIds(allItemUniqueIds);
      setItemNames(allItemNames);
      // setSkuNames(allItemSkuNames);
      setBarcodes(allItemBarcodes);
      setWidthUomUniqueIds(uniqueWidthUoms);
      setLengthUomUniqueIds(uniqueLengthUoms);
      setHeightUomUniqueIds(uniqueHeightUoms);
      setThicknessUomUniqueIds(uniqueThicknessUoms);
      setStockUomUniqueIds(uniqueStockUoms);
      setSalesUomUniqueIds(uniqueSalesUoms);
      setPurchaseUomUniqueIds(uniquePurchaseUoms);
      setDeliverUomUniqueIds(uniqueDeliverUoms);
      setAttributeNames(uniqueAttributeNames);

      const uniqueInputConversionUoms = data
        .map((item: any) =>
          item.uom_group?.uom_conversion_list?.map((uomConversion: any) =>
            formatString(uomConversion.target_uom_unique_id)
          )
        )
        .flat()
        .filter((uom: any) => uom) as string[];

      setConversionUomUniqueIds(uniqueInputConversionUoms);

      // const inputUoms = data
      //   .map((item: any) => [
      //     formatString(item.width_uom_unique_id),
      //     formatString(item.length_uom_unique_id),
      //     formatString(item.height_uom_unique_id),
      //     formatString(item.thickness_uom_unique_id),
      //     formatString(item.stock_uom_unique_id),
      //     formatString(item.sales_uom_unique_id),
      //     formatString(item.purchase_uom_unique_id),
      //     formatString(item.deliver_uom_unique_id),
      //     item.uom_group.uom_conversion_list?.map((uomConversion: any) =>
      //       formatString(uomConversion.target_uom_unique_id)
      //     ),
      //     "เซนติเมตร",
      //     "กิโลกรัม",
      //   ])
      //   .flat()
      //   .flat();

      // const uniqueInputUoms = [...Array.from(new Set(inputUoms))].filter(
      //   (uom) => uom
      // ) as string[];
      // setUomUniqueIds(uniqueInputUoms);

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const formatVatType = (type: any) => {
        switch (type) {
          case 7:
          case 0.07:
          case "7":
          case "7%":
          case "7 %":
            return "7";
          case 0:
          case "0":
          case "0%":
          case "0 %":
            return "0";
          default:
            return "ไม่มี";
        }
      };

      const formatMrpDefaultMethod = (type: string) => {
        const trimmedType = formatString(type);
        switch (trimmedType) {
          case "ผลิต":
            return "produce";
          case "สั่งซื้อ":
            return "buy";
          case "ไม่ได้ตั้งค่า":
            return "no_setting";
          default:
            return undefined;
        }
      };

      const formatActiveStatus = (status: string) => {
        const trimmedStatus = formatString(status);
        switch (trimmedStatus) {
          case "ใช้งาน":
            return 1;
          case "หยุดใช้งาน":
            return 0;
          default:
            return 1;
        }
      };

      const formatTracability = (type: string) => {
        const trimmedTrace = formatString(type)?.toUpperCase();
        switch (trimmedTrace) {
          case "BATCH/SERIAL NUMBER":
          case "BATCH/SERAIL NUMBER":
          case "LOT":
          case "SN":
          case "LOT/SN":
            return "serial";
          default:
            return "normal";
        }
      };

      const formatBooleanString = (value: string) => {
        const trmmedValue = formatString(value);
        switch (trmmedValue) {
          case "ได้":
            return true;
          case "ไม่ได้":
            return false;
          default:
            return true;
        }
      };

      const formattedData = data.map((item: any) => {
        const physical_attribute = {
          width: formatFloat(item.width) || 0,
          width_uom_unique_id: formatString(item.width_uom_unique_id),
          length: formatFloat(item.length) || 0,
          length_uom_unique_id: formatString(item.length_uom_unique_id),
          height: formatFloat(item.height) || 0,
          height_uom_unique_id: formatString(item.height_uom_unique_id),
          thick: formatFloat(item.thickness) || 0,
          thick_uom_unique_id: formatString(item.thickness_uom_unique_id),
        };

        const package_attribute = {
          width: formatFloat(item.package_width) || 0,
          width_uom_unique_id: "เซนติเมตร",
          length: formatFloat(item.package_length) || 0,
          length_uom_unique_id: "เซนติเมตร",
          height: formatFloat(item.package_height) || 0,
          height_uom_unique_id: "เซนติเมตร",
          weight: formatFloat(item.package_weight) || 0,
          weight_uom_unique_id: "กิโลกรัม",
        };

        const uom_group = {
          name: `${formatString(item.unique_id)}_uom_group`,
          unique_id: formatString(item.unique_id),
          uom_conversion_list: item.uom_group?.uom_conversion_list
            ?.map((uomConversion: any) => {
              return {
                base_uom_rate: formatFloat(uomConversion.base_uom_rate),
                target_uom_rate: formatFloat(uomConversion.target_uom_rate),
                target_uom_unique_id:
                  formatString(uomConversion.target_uom_unique_id) || undefined,
              };
            })
            .filter(
              (uomConversion: any) =>
                !Object.values(uomConversion).every(
                  (value) => typeof value === "undefined"
                )
            ),
        };

        const attribute_list = item.attribute_list
          ?.map((attribute: any) => {
            return {
              attribute_name:
                formatString(attribute.attribute_name) || undefined,
              attribute_value:
                formatString(attribute.attribute_value) || undefined,
            };
          })
          .filter(
            (attribute: any) =>
              !Object.values(attribute).every(
                (value) => typeof value === "undefined"
              )
          );

        return {
          type: getValues("import_type"),
          unique_id: formatString(item.unique_id),
          name: formatString(item.name),
          seller_sku_name: formatString(item.seller_sku),
          desc: formatString(item.description),
          is_purchasable: formatBooleanString(item.is_purchasable),
          is_saleable: formatBooleanString(item.is_saleable),
          is_manufactured: formatBooleanString(item.is_manufacturable),
          is_stockable: formatBooleanString(item.is_stockable),
          is_active: formatActiveStatus(item.is_active),
          remark_status: formatString(item.remark_status),
          // img_url: formatStringToArray(item.image_list),
          item_level_1_unique_id: formatString(
            item.item_group_sub_level_1_unique_id
          ),
          item_level_2_unique_id: formatString(
            item.item_group_sub_level_2_unique_id
          ),
          item_level_3_unique_id: formatString(
            item.item_group_sub_level_3_unique_id
          ),
          tag_list: formatStringToArray(item.tag_list)?.filter((tag) => tag),
          physical_attribute,
          package_attribute,
          purchase_standard_price: formatFloat(item.purchase_standard_price),
          purchase_minimum_qty: formatInt(item.purchase_minimum_qty),
          purchase_vat_type: formatVatType(item.purchase_vat_type),
          sale_price: formatFloat(item.sales_standard_price),
          special_price: formatFloat(item.sales_special_price),
          sale_vat_type: formatVatType(item.sales_vat_type),
          tracability: formatTracability(item.tracibility_mode),
          barcode: item.barcode
            ? formatString(item.barcode)
            : formatString(item.unique_id),
          stock_uom_unique_id: formatString(item.stock_uom_unique_id),
          sales_uom_unique_id: formatString(item.sales_uom_unique_id),
          purchase_uom_unique_id: formatString(item.purchase_uom_unique_id),
          deliver_uom_unique_id: formatString(item.deliver_uom_unique_id),
          uom_group,
          minimum_stock: formatInt(item.minimum_stock_qty),
          maximum_stock: formatInt(item.maximum_stock_qty),
          reorder_point: formatInt(item.reorder_point_qty),
          manufacturing_type: formatString(item.manufacture_type),
          manufacture_minimum_qty: formatInt(item.manufacture_minimum_qty),
          mrp_default_method: formatMrpDefaultMethod(item.mrp_default_method),
          attribute_list,
        };
      });
      return formattedData;
    } catch (e) {
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    const errorData: IImporterError[] = [];
    const conversionUomErrors = [];
    const requiredFieldErrors = [];
    const numericTypeErrors: any[] = [];
    const duplicatedUniqueIds = [];
    let invalidFieldOptions = [];
    try {
      const tempDoc = mapNestedData(keys, getValues, enqueueSnackbar);

      invalidFieldOptions = validateItemFieldOptions(tempDoc);

      if (tempDoc && tempDoc.length > 0) {
        tempDoc.forEach((item: any) => {
          numeric_columns.forEach((col) => {
            if (
              item[col.key] &&
              isNaN(item[col.key]) &&
              item[col.key]?.length > 0
            ) {
              numericTypeErrors.push({
                unique_id: item.unique_id,
                type: "numeric",
                field: col.name,
              });
            }
          });

          item.uom_group?.uom_conversion_list?.forEach((uomConversion: any) => {
            if (
              uomConversion.base_uom_rate &&
              isNaN(uomConversion.base_uom_rate)
            ) {
              numericTypeErrors.push({
                unique_id: item.unique_id,
                type: "numeric",
                field: "จำนวนหน่วยหลัก",
              });
            }
            if (
              uomConversion.target_uom_rate &&
              isNaN(uomConversion.target_uom_rate)
            ) {
              numericTypeErrors.push({
                unique_id: item.unique_id,
                type: "numeric",
                field: "จำนวนหน่วยแปลง",
              });
            }
          });
        });
      }

      const formattedData = await formatItemData(tempDoc);

      if (formattedData.length > count_limit) {
        enqueueSnackbar(
          `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
          {
            variant: "error",
          }
        );
      } else {
        for (let i = 0; i < formattedData.length; i++) {
          const unique_id = formattedData[i].unique_id;

          for (let j = 0; j < formattedData.length; j++) {
            if (i === j) continue;
            if (unique_id === formattedData[j].unique_id) {
              duplicatedUniqueIds.push(unique_id);
              break;
            }
          }

          for (const uomConversion of formattedData[i].uom_group
            ?.uom_conversion_list || []) {
            if (
              !uomConversion.base_uom_rate ||
              !uomConversion.target_uom_rate ||
              !uomConversion.target_uom_unique_id
            ) {
              conversionUomErrors.push({
                unique_id: unique_id,
                type: "required",
                field: "การแปลงหน่วย",
              });
              break;
            }
          }
          for (let j = 0; j < requiredKeys.length; j++) {
            if (!formattedData[i][requiredKeys[j].value]) {
              requiredFieldErrors.push({
                unique_id: unique_id,
                type: "required",
                field: requiredKeys[j].label,
              });
            }
          }
        }

        const uniqueDuplicatedIds = [...new Set(duplicatedUniqueIds)];

        if (uniqueDuplicatedIds.length > 0) {
          uniqueDuplicatedIds.forEach((unique_id) => {
            addImporterErrorHandler(
              errorData,
              "รหัสสินค้าซ้ำในไฟล์",
              "รหัสสินค้า",
              [unique_id]
            );
          });
        }

        if (conversionUomErrors?.length > 0) {
          conversionUomErrors.forEach((error) => {
            addImporterErrorHandler(
              errorData,
              "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
              error.field,
              ["กรุณากรอก จำนวนหน่วยหลัก, จำนวนหน่วยแปลง, หน่วยแปลง"]
            );
          });
        }

        if (requiredFieldErrors.length > 0) {
          requiredFieldErrors.forEach((error) => {
            addImporterErrorHandler(
              errorData,
              "กรุณาระบุข้อมูลที่จำเป็นต้องใส่",
              error.field,
              [``]
            );
          });
        }

        if (numericTypeErrors.length > 0) {
          numericTypeErrors.forEach((error) => {
            addImporterErrorHandler(
              errorData,
              "กรุณาระบุข้อมูลที่เป็นตัวเลขให้ถูกต้อง",
              error.field,
              error.unique_id ? [`รหัสสินค้า: ${error.unique_id}`] : [""]
            );
          });
        }

        if (invalidFieldOptions.length > 0) {
          invalidFieldOptions.forEach((error) => {
            addImporterErrorHandler(
              errorData,
              "ข้อมูลผิดรูปแบบ",
              error.field,
              error.value ? [error.value] : [""]
            );
          });
        }

        if (
          conversionUomErrors?.length > 0 ||
          requiredFieldErrors.length > 0 ||
          uniqueDuplicatedIds.length > 0 ||
          numericTypeErrors.length > 0 ||
          invalidFieldOptions.length > 0
        ) {
          setErrorData(errorData);

          throw new Error("required");
        }

        setRowData(formattedData);
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
        setValue("step", 1);
      }
    } catch (err) {
      console.error(err);
      if (errorData.length > 0) {
        openModalHandler();
      }
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const importHandler = async () => {
    try {
      const { data } = await validateItem();
      let existingBarcodes =
        data?.ValidateItemImportNew.existing_barcode_list || [];
      let existingItemNameObjects =
        data?.ValidateItemImportNew.existing_item_name_list || [];
      const existingItemUniqueIds =
        data?.ValidateItemImportNew.existing_item_unique_id_list || [];
      const existingSkuNames =
        data?.ValidateItemImportNew.existing_sku_name_list || [];
      const missingItemGroupsLevel1 =
        data?.ValidateItemImportNew.missing_item_level_1_unique_id_list || [];
      const missingItemGroupsLevel2 =
        data?.ValidateItemImportNew.missing_item_level_2_unique_id_list || [];
      const missingItemGroupsLevel3 =
        data?.ValidateItemImportNew.missing_item_level_3_unique_id_list || [];
      const missingItemUniqueIds =
        data?.ValidateItemImportNew.missing_item_unique_id_list || [];
      const missingWidthUomUniqueIds =
        data?.ValidateItemImportNew.missing_width_uom_unique_id_list || [];
      const missingLengthUomUniqueIds =
        data?.ValidateItemImportNew.missing_length_uom_unique_id_list || [];
      const missingHeightUomUniqueIds =
        data?.ValidateItemImportNew.missing_height_uom_unique_id_list || [];
      const missingThicknessUomUniqueIds =
        data?.ValidateItemImportNew.missing_thickness_uom_unique_id_list || [];
      const missingStockUomUniqueIds =
        data?.ValidateItemImportNew.missing_stock_uom_unique_id_list || [];
      const missingSalesUomUniqueIds =
        data?.ValidateItemImportNew.missing_sales_uom_unique_id_list || [];
      const missingPurchaseUomUniqueIds =
        data?.ValidateItemImportNew.missing_purchase_uom_unique_id_list || [];
      const missingDeliverUomUniqueIds =
        data?.ValidateItemImportNew.missing_deliver_uom_unique_id_list || [];
      const missingConversionUomUniqueIds =
        data?.ValidateItemImportNew.missing_conversion_uom_unique_id_list || [];
      const missingAttributeNames =
        data?.ValidateItemImportNew.missing_attribute_name_list || [];

      const importType = getValues("type");

      if (importType === "update") {
        existingItemNameObjects = existingItemNameObjects?.filter(
          (validateItem) => {
            return !rowData.find(
              (importData) =>
                importData.name === validateItem.item_name &&
                importData.unique_id === validateItem.item_unique_id
            );
          }
        );

        const itemUniqueIdList = rowData.map((item) => item.unique_id);

        existingBarcodes = existingBarcodes?.filter(
          (barcode) => !itemUniqueIdList.includes(barcode.item_unique_id)
        );
      }

      let arrays = [
        existingBarcodes,
        missingItemGroupsLevel1,
        missingItemGroupsLevel2,
        missingItemGroupsLevel3,
        missingItemUniqueIds,
        missingWidthUomUniqueIds,
        missingLengthUomUniqueIds,
        missingHeightUomUniqueIds,
        missingThicknessUomUniqueIds,
        missingStockUomUniqueIds,
        missingSalesUomUniqueIds,
        missingPurchaseUomUniqueIds,
        missingDeliverUomUniqueIds,
        missingConversionUomUniqueIds,
        existingItemNameObjects,
        missingAttributeNames,
      ];

      let errorData: IImporterError[] = [];
      if (importType === "create") {
        arrays = [...arrays, existingItemUniqueIds, existingSkuNames];
        addImporterErrorHandler(
          errorData,
          "รหัสสินค้าซ้ำกับในระบบ",
          "รหัสสินค้า",
          existingItemUniqueIds
        );
        addImporterErrorHandler(
          errorData,
          "รหัสสินค้าซ้ำกับในระบบ",
          "SKU",
          existingSkuNames
        );
      } else {
        arrays = [...arrays, existingItemUniqueIds];
        addImporterErrorHandler(
          errorData,
          "รหัสสินค้าไม่มีในระบบ",
          "รหัสสินค้า",
          missingItemUniqueIds
        );
      }

      addImporterErrorHandler(
        errorData,
        "ชื่อสินค้าซ้ำกับในระบบ",
        "ชื่อสินค้า",
        existingItemNameObjects
          ?.map((item: any) => item.item_name)
          .filter((name) => name)
      );
      addImporterErrorHandler(errorData, "รหัสสินค้าซ้ำกับในระบบ", "บาร์โค้ด", [
        ...Array.from(
          new Set(existingBarcodes.map((item: any) => item.barcode_list).flat())
        ),
      ]);
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยกว้าง",
        missingWidthUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยยาว",
        missingLengthUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยสูง",
        missingHeightUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยหนา",
        missingThicknessUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยมาตรฐาน",
        missingStockUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยขาย",
        missingSalesUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยซื้อ",
        missingPurchaseUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยส่ง",
        missingDeliverUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หน่วยแปลง",
        missingConversionUomUniqueIds
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หมวดหมู่สินค้า 1",
        missingItemGroupsLevel1
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หมวดหมู่สินค้า 2",
        missingItemGroupsLevel2
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "หมวดหมู่สินค้า 3",
        missingItemGroupsLevel3
      );
      addImporterErrorHandler(
        errorData,
        "ไม่มีการตั้งค่าในระบบ",
        "คุณสมบัติ",
        missingAttributeNames
      );

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      setErrorData(errorData);

      if (sumErrorLength) {
        openModalHandler();
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await createOrUpdateItems({
            importInput: {
              data: rowData,
              import_mode: getValues("type"),
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const backToAllItemHandler = () => {
    navigate("/inventory/items");
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <ItemImportUploader />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          <ItemImporterTable data={rowData} />
        </>
      );
    }
  };

  const renderButton = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importHandler}
              disabled={!rowData || rowData.length === 0}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูสินค้าทั้งหมด"
            variant="contained"
            onClick={backToAllItemHandler}
          />
        );
      default:
        return null;
    }
  };

  if (isValidating || isCreatingOrUpdating) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {`นำเข้า${t("inventory.items.index")}`}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep)}
        </CustomizedBox>
      </FormProvider>
      <Stack direction={"row"} gap={1}>
        {renderButton(activeStep)}
      </Stack>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default ItemImporter;
