import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { IBreadcrumbsAndMenu, ICardList, ITab } from "../../../types/global";

import TotalBox from "../../../components/UI/TotalBox";
import ScanNavigate from "../../../components/UI/ScanNavigate";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import ItemsTable from "../../../components/Table/Inventory/Item";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { itemSchema } from "../../../components/Form/Inventory/Item/schema";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";

import { ITEMS_COUNT_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";
import { ITEMS_EXPORT_FOR_IMPORT } from "../../../services/Inventory/Item/ItemsExportForImport";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ItemsQuery } from "../../../generated/inventory";

// import AgGrid from "../../../components/UI/AgGrid";
// import { ColDef } from "ag-grid-community";

const Items = () => {
  const gridRef = useRef<AgGridReact<ItemsQuery["items"]>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const { enqueueSnackbar } = useSnackbar();
  const [itemTypeCount, setItemTypeCount] = useState<
    { type: string; count: string }[]
  >([]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.items.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("inventory.type.normal"),
      path: `${pathname}?filter=normal`,
    },
    // {
    //   label: t("inventory.type.variant"),
    //   path: `${pathname}?filter=variant`,
    // },
    // {
    //   label: t("inventory.type.bundle"),
    //   path: `${pathname}?filter=bundle`,
    // },
    {
      label: t("inventory.type.service"),
      path: `${pathname}?filter=service`,
    },
    {
      label: t("status.in_active"),
      path: `${pathname}?filter=in_active`,
    },
  ];

  const createOptions = [
    { value: t("inventory.type.normal") },
    // { value: t("inventory.type.variant") },
    // { value: t("inventory.type.bundle") },
    { value: t("inventory.type.service") },
  ];

  const options = [
    {
      value: t("inventory.import_items"),
    },
    {
      value: t("inventory.export_items"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const sumValues = useCallback(
    async (type: string): Promise<string> => {
      let count = 0;
      const filterType =
        type === "all"
          ? {}
          : {
              type: {
                filterType: "set",
                values: [type],
              },
            };
      try {
        const { itemsFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(ITEMS_COUNT_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filterType,
              sortModel: [],
            },
          });
        count = itemsFindManyAggrid.count as number;
      } catch (err) {
        // Handle the error if needed
      }
      return count.toLocaleString() || "-";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const CardList: ICardList[] = useMemo(
    () => [
      {
        title: t("inventory.all"),
        field: "all",
      },
      {
        title: t("inventory.type.normal"),
        field: "normal",
        borderColor: "#BF8CDE",
        // value: item.stock_qty?.toLocaleString(),
      },
      {
        title: t("inventory.type.variant"),
        field: "variant",
        // value: item.purchase_ordered_qty?.toLocaleString(),
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.type.bundle"),
        field: "bundle",
        // value: item.manufacture_ordered_qty?.toLocaleString(),
        borderColor: "#BF8CDE",
      },
      {
        title: t("inventory.type.service"),
        field: "service",
        // value: item.sale_committed_qty?.toLocaleString(),
        borderColor: "#BF8CDE",
      },
    ],
    [t]
  );

  useEffect(() => {
    const fetchData = async () => {
      for (const list of CardList) {
        const result = await sumValues(list.field);
        setItemTypeCount((prev) => [
          ...prev,
          { type: list.field, count: result },
        ]);
      }
    };

    fetchData();
  }, [CardList, sumValues]);

  const renderCard = (list: ICardList[]) =>
    list.map((menu: ICardList) => (
      <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4} key={menu.title}>
        <TotalBox
          title={menu.title}
          value={
            itemTypeCount.find((item) => item.type === menu.field)?.count || "0"
          }
          borderColor={menu.borderColor}
        />
      </Grid>
    ));

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current?.api.setFilterModel({});
    }
  };

  const exportItems = async () => {
    try {
      const { ItemsExportForImport } =
        await graphQLClientWithHeaderItem.request(ITEMS_EXPORT_FOR_IMPORT, {
          exportInput: {
            type: "item_normal",
          },
        });
      window.open(ItemsExportForImport, "_blank", "noopener,noreferrer");
      enqueueSnackbar("นำออกสินค้าสำเร็จ", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("นำออกสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={1}>
        {renderCard(CardList)}
      </Grid>
      <HeaderLayout marginBottom={0}>
        <Typography variant="h5">{t("inventory.items.index")}</Typography>
        <Box>
          {/* <CustomizedMenuOptions
            sx={{ mr: 2 }}
            label={t("inventory.items.import")}
            variant="outlined"
            options={createOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerHTML) {
                case t("inventory.type.normal"):
                  navigate(`/inventory/items/import/normal`);
                  break;
                case t("inventory.type.variant"):
                  navigate(`/inventory/items/import/variant`);
                  break;
                case t("inventory.type.bundle"):
                  navigate(`/inventory/items/import/bundle`);
                  break;
                case t("inventory.type.service"):
                  navigate(`/inventory/items/import/service`);
                  break;
                default:
                  break;
              }
            }}
            // onClick={() => navigate(`${pathname}/add?tab=item&subtab=general`)}
          /> */}
          <CustomizedMenuOptions
            label={t("inventory.items.add")}
            variant="contained"
            options={createOptions}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerHTML) {
                case t("inventory.type.normal"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "normal" },
                  });
                  break;
                case t("inventory.type.variant"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "variant" },
                  });
                  break;
                case t("inventory.type.bundle"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "bundle" },
                  });
                  break;
                case t("inventory.type.service"):
                  navigate(`/inventory/items/add?tab=item&subtab=general`, {
                    state: { ...itemSchema, type: "service" },
                  });
                  break;
                default:
                  break;
              }
            }}
            // onClick={() => navigate(`${pathname}/add?tab=item&subtab=general`)}
          />
          <CustomizedMenuOptions
            isIcon
            label="item-options"
            options={options}
            onSelect={(e) => {
              const value = e.target as HTMLElement;
              switch (value.innerText) {
                case `${t("inventory.import_items")}`:
                  navigate(`${pathname}/import`);
                  break;
                case `${t("inventory.export_items")}`:
                  exportItems();
                  break;
                default:
              }
            }}
          />
        </Box>
      </HeaderLayout>
      <ScanNavigate pathname="inventory/items" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={t("button.reset_filter")}
          variant="outlined"
          onClick={onFilterReset}
          sx={{ height: "33px" }}
        />
      </Box>
      <ItemsTable gridRef={gridRef} isFilter={isFilter} />
    </>
  );
};

export default Items;
