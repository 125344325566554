import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../../types/global";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { useModal } from "../../../../hooks/use-modal";
import LevelThreeModal from "../../../../components/Form/Category/LevelThreeModal";
import {
  ItemGroupLevel3sQuery,
  useItemGroupLevel3sQuery,
} from "../../../../generated/inventory";
import LevelThreeTable from "../../../../components/Table/Setting/Inventory/LevelThreeTable";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

const LevelThree = () => {
  const { t } = useTranslation();
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.category.level_three"),
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, refetch } = useItemGroupLevel3sQuery<ItemGroupLevel3sQuery>(
    graphQLClientWithHeaderItem
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent="space-between"
        my={2}
        alignItems="center"
      >
        <Typography variant="h5">
          {t("setting.inventory.category.level_three")}
        </Typography>
        <CustomizedButton
          variant="contained"
          title={t("button.add") + t("setting.inventory.category.level_three")}
          onClick={openModalHandler}
        />
      </Box>
      <LevelThreeTable data={data} refetch={refetch} />
      <LevelThreeModal
        open={modal}
        closeModalHandler={closeModalHandler}
        refetch={refetch}
      />
    </>
  );
};

export default LevelThree;
