import { useState } from "react";
import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../types/global";

import { BomQuery, useBomQuery } from "../generated/manufacture";

import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

import { IBom } from "../types/Manufacture/bom";
import { GraphQLClient } from "graphql-request";

export const useBomDescription = (
  control: IDefaultForm["control"],
  reset: IDefaultForm["reset"]
) => {
  const bomId = useWatch({
    control,
    name: "bom_id",
  });

  const [openBomDetail, setOpenBomDetail] = useState<boolean>(false);
  const [openBomTable, setOpenBomTable] = useState<boolean>(false);

  const [bomIds, setBomIds] = useState<string[]>([]);
  const [bomIdsSnapshot, setBomIdsSnapshot] = useState<string[]>([]);

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { refetch: bomRefetch } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: {
        id: bomId,
      },
    },
    {
      enabled: false,
    }
  );

  const openBomDetailHandler = async () => {
    if (reset) {
      const { data } = await bomRefetch();
      const bomType = data?.bom as IBom;
      reset(bomType);
      setOpenBomDetail(true);
    }
  };

  const closeBomDetailHandler = () => {
    setOpenBomDetail(false);
  };

  return {
    openBomDetail,
    openBomDetailHandler,
    closeBomDetailHandler,
    openBomTable,
    setOpenBomTable,
    bomIds,
    setBomIds,
    bomIdsSnapshot,
    setBomIdsSnapshot,
  };
};
