import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { IDefaultForm } from "../../../types/global";
import CustomizedChips from "../../Custom/CustomizedChips";
import SalesTagList from "../../UI/TagList/SalesTagList";
import { SalesEntityType } from "../../../generated/sales";
import ControlledServerSideCreatable from "../../Controller/ControlledSSCreatable";
import { EntityTypeEnum } from "../../../generated/creatable";

interface TagList {
  id: number;
  name: string;
  entity: any;
}

type Props = IDefaultForm & {
  documentType: EntityTypeEnum;
};

const SalesInfo = ({
  control,
  errors,
  disabled,
  getValues,
  setValue,
  documentType,
}: Props) => {
  const { t } = useTranslation();

  const tagList: TagList[] = getValues("tag_list");

  return (
    <>
      {!Boolean(documentType === EntityTypeEnum.DeliveryOrder) && (
        <>
          <Typography fontWeight="bold" mt={2}>
            {t("sales.sales_description")}
          </Typography>
          <Grid container spacing={2} my={1}>
            {documentType === EntityTypeEnum.SalesOrder && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <ControlledServerSideCreatable
                  title={t("sales.payment_type")}
                  control={control}
                  error={Boolean(errors && errors.payment_type)}
                  defaultOptions={[]}
                  documentType={documentType}
                  name="payment_type"
                  setValue={setValue}
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <ControlledServerSideCreatable
                title={t("sales.sales_channel")}
                control={control}
                error={Boolean(errors && errors.sales_channel)}
                defaultOptions={[]}
                documentType={documentType}
                name="sales_channel"
                setValue={setValue}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <ControlledTextField
                label={t("sales.account_id")}
                control={control}
                name="account_id"
                error={Boolean(errors.account_id)}
                helperText={errors.account_id && errors.account_id.message}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Typography my={2} fontWeight="bold">
        {t("sales.tag_list")}
      </Typography>
      {disabled ? (
        <>
          {tagList && tagList.length > 0
            ? tagList.map((tag) => (
                <CustomizedChips
                  key={tag.name}
                  value={tag.name}
                  color="primary"
                />
              ))
            : "-"}
        </>
      ) : (
        <Grid container spacing={2} alignItems="center">
          <SalesTagList
            control={control}
            disabled={disabled}
            entity={SalesEntityType.Quotation}
            setValue={setValue}
            getValues={getValues}
            name="tag_list"
          />
        </Grid>
      )}
    </>
  );
};

export default SalesInfo;
