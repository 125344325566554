import { Box, List, ListItem, Typography } from "@mui/material";
import CustomizedButton from "../Custom/CustomizedButton";
import ModalUI from "../UI/ModalUI";
import { ItemEntityType } from "../../generated/inventory";
import { ContactEntityType } from "../../generated/contact";

interface ModalPayload {
  value: boolean;
  document_id_list?: string[];
  optionToDelete: string;
  uniqueInput: { id?: number };
}

type ExtenedProps = {
  payload: ModalPayload;
  openDeleteConfirmation: boolean;
  comfirmationDeleteAction: () => void;
  closeModalHandler: () => void;
  entityType: ItemEntityType | ContactEntityType | string;
};

const DeleteTagListConfirmation = ({
  payload,
  openDeleteConfirmation,
  comfirmationDeleteAction,
  closeModalHandler,
  entityType,
}: ExtenedProps) => {
  const mapEntity = () => {
    switch (entityType) {
      case "item":
        return "สินค้า";
      case "contact":
        return "ผู้ติดต่อ";
      case "project":
        return "โครงการ";
      // case "manufacture_order":
      //   return "ใบสั่งผลิต";
      default:
        break;
    }
  };

  return (
    <ModalUI
      title={`การจัดกลุ่ม ${payload.optionToDelete} ถูกใช้อยู่ใน${mapEntity()}`}
      open={openDeleteConfirmation}
      handleClose={closeModalHandler}
      maxWidth="xs"
      fullWidth
    >
      {payload.document_id_list && payload.document_id_list.length > 0 && (
        <>
          <Typography>{mapEntity()}ที่ใช้งานการจัดกลุ่มนี้ คือ</Typography>
          <List sx={{ listStyleType: "disc", ml: 3 }}>
            {payload.document_id_list?.map((documentId, index) => {
              if (index > 5) return null;
              else
                return (
                  <ListItem sx={{ display: "list-item" }}>
                    {index > 4 ? "..." : documentId}
                  </ListItem>
                );
            })}
          </List>
        </>
      )}
      <Typography
        mt={2}
        sx={{
          fontWeight: 600,
          fontSize: "1rem",
          lineHeight: 1.375,
          color: "#333333",
        }}
      >
        หากลบการจัดกลุ่ม {payload.optionToDelete} การจัดกลุ่มที่อยู่ในแต่ละ
        {mapEntity()}
        {payload.document_id_list && payload.document_id_list?.length > 0
          ? "ตามที่แสดงด้านบน"
          : ""}
        จะถูกลบด้วย
      </Typography>
      <Typography>กดยืนยันหากต้องการลบการจัดกลุ่ม</Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={closeModalHandler}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={comfirmationDeleteAction}
        />
      </Box>
    </ModalUI>
  );
};

export default DeleteTagListConfirmation;
