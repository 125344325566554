import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { REFRESH_ACCESS_TOKEN } from "../middleware/AuthMiddleware";
import { useStateContext } from "../contexts/auth-context";
import { IPermission, IUser } from "../types/Auth/user";
import {
  ValidateAccesssQuery,
  useValidateAccesssQuery,
} from "../generated/company-user";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { Box, CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { defaultPermission } from "../utils/userInfo";

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);
  const navigate = useNavigate();

  // const {
  //   state: { permissions },
  // } = useStateContext();

  const location = useLocation();
  const stateContext = useStateContext();

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "company-user",
    false
  );

  const { data, refetch, isFetching, isLoading, isError } =
    useValidateAccesssQuery<ValidateAccesssQuery, Error>(
      graphQLClientWithHeaderCompany,
      {},
      {
        retry: 1,
        // cacheTime: 0,
        // enabled: cookies.logged_in === "true",
        staleTime: 3600 * 1000,
        onSuccess: (data) => {
          stateContext.dispatch({
            type: "SET_USER",
            payload: data.validateAccesssTokenWithHeader as IUser,
          });
          stateContext.dispatch({
            type: "SET_PERMISSION",
            payload: defaultPermission as IPermission,
          });
          Sentry.setUser({
            id: data.validateAccesssTokenWithHeader.unique_id,
            email: data.validateAccesssTokenWithHeader.email,
          });
        },
        onError(error: any) {
          if (cookies.refresh_token) {
            error.response.errors.forEach(async (err: any) => {
              if (err.message.includes("Unauthorized")) {
                try {
                  const { refreshToken } = await graphQLClient.request(
                    REFRESH_ACCESS_TOKEN
                  );
                  const { access_token } = refreshToken;
                  const { exp: accessMaxAge } = jwtDecode<IToken>(access_token);
                  setCookie("access_token", access_token, {
                    path: "/",
                    maxAge: accessMaxAge,
                  });
                  refetch();
                } catch (error) {
                  removeCookie("access_token");
                  removeCookie("refresh_token");
                  setCookie("logged_in", "false", { path: "/" });
                  sessionStorage.removeItem("tenant-id");
                  sessionStorage.removeItem("company-info");
                  navigate("/login");
                }
              }
            });
          } else {
            removeCookie("access_token");
            removeCookie("refresh_token");
            setCookie("logged_in", "false", { path: "/" });
            sessionStorage.removeItem("tenant-id");
            sessionStorage.removeItem("company-info");
            navigate("/login");
          }
        },
      }
    );

  const user = data?.validateAccesssTokenWithHeader;

  const loading = isFetching || isLoading || isError;

  if (loading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (cookies.logged_in === "true" || user) &&
    allowedRoles.includes("ADMIN") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireUser;
