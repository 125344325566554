import { useState, Fragment, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  contactSchema,
  validation,
} from "../../components/Form/Contact/schema";
import {
  ContactCreateMutation,
  ContactType,
  ContactUniqueIdQuery,
  ContactUpdateMutation,
  useContactCreateMutation,
  useContactQuery,
  useContactUniqueIdQuery,
  useContactUpdateMutation,
} from "../../generated/contact";

import CustomizedButton from "../../components/Custom/CustomizedButton";
import BottomNavbar from "../../components/UI/Navbar/BottomNavbar";
import ContactHeader from "../../components/Form/Contact/Header";
import ContactGeneralForm from "../../components/Form/Contact/General";
import ContactRemarkForm from "../../components/Form/Contact/Remark";
import ContactInformationForm from "../../components/Form/Contact/Information";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../types/global";
import {
  ContactQuery,
  ActivityType,
  ContactActivityLogDocumentType,
} from "../../generated/contact";
import { IContact } from "../../types/Contact";
import { formatContactPayload } from "../../utils/Formatter/Contact";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { useActivityLog } from "../../hooks/use-activity-log";
import { ActivityLogSlideInPanel } from "../../components/UI/SlideInPanel/ActivityLogSlideInPanel";
import { CustomizedTooltip } from "../../components/Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

interface Props {
  isContactPage: boolean;
  contactUniqueId?: string;
}

const ContactContainer = ({ isContactPage, contactUniqueId }: Props) => {
  // const { user } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [contactType, setContactType] = useState<ContactType>(
    ContactType.Customer
  );
  const [showActivityLog, setShowActivityLog] = useState(false);

  const { createActivityLog } = useActivityLog();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact/contacts",
    },
    {
      name: contactUniqueId ? contactUniqueId : id ? id : "เพิ่มผู้ติดต่อ",
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<IContact>({
    defaultValues: { ...contactSchema },
    resolver: yupResolver(validation),
  });

  const isVendor = useWatch({ control, name: "is_vendor" });
  const isCustomer = useWatch({ control, name: "is_customer" });

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const { data, isSuccess } = useContactQuery<ContactQuery>(
    graphQLClientWithHeaderContact,
    {
      uniqueInput: {
        unique_id: contactUniqueId || id,
      },
    },
    { enabled: !!id || !!contactUniqueId }
  );

  const contactId = data?.contact?.id;

  const getContactType = useCallback(async () => {
    if (isVendor && !isCustomer) {
      setContactType(ContactType.Vendor);
      return ContactType.Vendor;
    } else {
      setContactType(ContactType.Customer);
    }
  }, [isCustomer, isVendor]);

  const { refetch: refetchUniqueId } =
    useContactUniqueIdQuery<ContactUniqueIdQuery>(
      graphQLClientWithHeaderContact,
      {
        contactType: contactType,
      },
      {
        enabled: false,
      }
    );

  const callUniqueIdFromGenerate = useCallback(
    async (button: boolean) => {
      const { data } = await refetchUniqueId();
      if (!id || button)
        setValue("unique_id", data?.utilGetContactUniqueId ?? "");
    },
    [refetchUniqueId, id, setValue]
  );

  const generateUniqueId = useCallback(
    async (button: boolean) => {
      await getContactType();
      callUniqueIdFromGenerate(button);
    },
    [getContactType, callUniqueIdFromGenerate]
  );

  const onSubmit = (data: IContact) => {
    if (id) submitEditContactHandler(data);
    else submitCreateContactHandler(data);
  };

  useEffect(() => {
    generateUniqueId(false);
  }, [isVendor, isCustomer, generateUniqueId]);

  const getContact = useCallback(() => {
    if (isSuccess) {
      const { contact } = data;
      const contactType = contact as IContact;
      const formattedContact = {
        ...contactType,
        title_name: contactType.title_name || "",
        secondary_contact_name: contactType.secondary_contact_name || "",
        registered_capital: contactType.registered_capital || 0,
      };
      reset(formattedContact);
      setDisabled(isSuccess);
    }
  }, [data, isSuccess, reset]);

  useEffect(() => {
    getContact();
  }, [getContact]);

  const { mutate: createContact } = useContactCreateMutation<Error>(
    graphQLClientWithHeaderContact,
    {
      onSuccess: async (data: ContactCreateMutation) => {
        const { contactCreate } = data;
        try {
          await createActivityLog({
            activity_type: ActivityType.Create,
            document_type: ContactActivityLogDocumentType.Contact,
            reference_id: contactCreate.id!,
            activity_detail: {},
          });
        } catch (e) {
          console.log("Cannot create activity log");
        }
        enqueueSnackbar("เพิ่มผู้ติดต่อสำเร็จ", {
          variant: "success",
        });
        navigate("/contact/contacts");
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("เพิ่มผู้ติดต่อไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: updateContact } = useContactUpdateMutation<Error>(
    graphQLClientWithHeaderContact,
    {
      onSuccess: async (data: ContactUpdateMutation) => {
        const { contactUpdate } = data;
        try {
          await createActivityLog({
            activity_type: ActivityType.Edit,
            document_type: ContactActivityLogDocumentType.Contact,
            reference_id: contactUpdate.id!,
            activity_detail: {},
          });
        } catch (e) {
          console.log("Cannot create activity log");
        }
        enqueueSnackbar("แก้ไขผู้ติดต่อสำเร็จ", {
          variant: "success",
        });
        setDisabled(true);
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("แก้ไขผู้ติดต่อไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const submitCreateContactHandler = async (submitData: IContact) => {
    const formattedFormData = await formatContactPayload(submitData);
    createContact({
      createInput: formattedFormData,
    });
  };

  const submitEditContactHandler = async (data: IContact) => {
    const formattedFormData = await formatContactPayload(data);
    const { unique_id, ...otherData } = formattedFormData;
    updateContact({
      uniqueInput: { unique_id },
      updateInput: otherData,
    });
  };

  return (
    <Fragment>
      {id && (
        <ActivityLogSlideInPanel
          open={showActivityLog}
          handleClose={() => setShowActivityLog(false)}
          documentId={contactId!}
          documentType={ContactActivityLogDocumentType.Contact}
        />
      )}
      {isContactPage ? (
        <Box display="flex">
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />{" "}
          <Box marginLeft="auto">
            <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
              <IconButton
                onClick={
                  contactId
                    ? () => {
                        setShowActivityLog(true);
                      }
                    : () => {}
                }
                sx={{
                  color: (theme) => theme.palette.grey[500],
                  padding: 0,
                  marginLeft: "4px",
                }}
              >
                <RestoreOutlinedIcon />
              </IconButton>
            </CustomizedTooltip>
          </Box>
        </Box>
      ) : (
        <Box mb={1}></Box>
      )}
      <form>
        <ContactHeader
          control={control}
          disabled={disabled}
          setDisabled={setDisabled}
          isContactPage={isContactPage}
        />
        <ContactInformationForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          reset={reset}
          watch={watch}
          errors={errors}
          disabled={disabled}
          setDisabled={setDisabled}
          generateUniqueId={generateUniqueId}
          isContactPage={isContactPage}
        />
        <ContactGeneralForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          reset={reset}
          errors={errors}
          disabled={disabled}
          isContactPage={isContactPage}
        />
        <ContactRemarkForm
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          disabled={disabled}
          isContactPage={isContactPage}
        />

        {!disabled && (
          <BottomNavbar>
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={"ยกเลิก"}
                variant="outlined"
                type="reset"
                onClick={getContact}
              />
              <CustomizedButton
                title={"บันทึก"}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              />
            </Stack>
          </BottomNavbar>
        )}
      </form>
    </Fragment>
  );
};

export default ContactContainer;
