import { Dispatch, SetStateAction, useCallback, useRef } from "react";
// import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckboxModalTable from "./CheckboxModalTable";
import { usePurchaseRequestColumnDefs } from "./Purchase/Request/columnDefs";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { PURCHASE_REQUESTS_AGGRID } from "../../services/AgGrid/PurchaseAgGrid";
import { dateFilterModel } from "../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";

type CheckedPurchaseRequestModalProps = {
  showSelectPurchaseRequest: boolean;
  closePurchaseRequestTable: () => void;
  finishPurchaseRequestsSelect: (data: any) => void;
  purchaseRequestIds: string[];
  setPurchaseRequestIds: Dispatch<SetStateAction<string[]>>;
  purchaseRequestIdsSnapshot: string[];
  setPurchaseRequestIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  isLoading?: boolean;
  rowSelection?: "single" | "multiple";
};

const CheckedPurchaseRequestModal = ({
  showSelectPurchaseRequest,
  closePurchaseRequestTable,
  finishPurchaseRequestsSelect,
  purchaseRequestIds,
  setPurchaseRequestIds,
  purchaseRequestIdsSnapshot,
  setPurchaseRequestIdsSnapshot,
}: CheckedPurchaseRequestModalProps) => {
  const selectPurchaseRequestGridRef = useRef();
  const { t } = useTranslation();

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        created_date,
        issue_date,
        due_date,
        related_user_list,
        requestor_contact,
        created_by,
        ...otherFilterModel
      } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        issue_date: dateFilterModel(issue_date),
        due_date: dateFilterModel(due_date),
        requestor_contact: requestor_contact && {
          filterType: "json",
          type: requestor_contact.type,
          filter: requestor_contact.filter,
          path: ["name"],
        },
        created_by: created_by && {
          filterType: "json",
          type: created_by.type,
          filter: created_by.filter,
          path: ["first_name"],
        },
        related_user_list: related_user_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: related_user_list,
          },
        },
      };
      try {
        const { purchaseRequestsFindManyAggrid } =
          await graphQLClientWithHeaderPurchase.request(
            PURCHASE_REQUESTS_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
        params.success({
          rowData: purchaseRequestsFindManyAggrid.data as any[],
          rowCount: purchaseRequestsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instance = params.api.getFilterInstance("aggrid_status");
    instance?.setModel({
      filterType: "set",
      values: ["approved", "partially_ordered", "fully_ordered"],
    });
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxModalTable
      modalTitle="นำเข้าใบขอซื้อ"
      btnTitle={t("button.add")}
      gridRef={selectPurchaseRequestGridRef}
      height={665}
      columnDefs={usePurchaseRequestColumnDefs(t, false)}
      rowSelection="multiple"
      onFinishEditing={finishPurchaseRequestsSelect}
      modalIsOpen={showSelectPurchaseRequest}
      closeModal={closePurchaseRequestTable}
      onGridReady={onGridReady}
      selectedIds={purchaseRequestIds}
      setSelectedIds={setPurchaseRequestIds}
      idsSnapshot={purchaseRequestIdsSnapshot}
      setIdsSnapshot={setPurchaseRequestIdsSnapshot}
      getRowId={getRowId}
      disabledSidebar
    />
  );
};

export default CheckedPurchaseRequestModal;
