import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";

import AgGrid from "../../../UI/AgGrid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useBomColumnDefs } from "./columnDefs";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";

import { BOMS_AGGRID } from "../../../../services/AgGrid/ManufactureAgGrid";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  CreatablesQuery,
  EntityTypeEnum,
  useCreatablesQuery,
} from "../../../../generated/creatable";
import { GraphQLClient } from "graphql-request";

const BomTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact<any>>(null);
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data } = useCreatablesQuery<CreatablesQuery>(
    graphQLClientWithHeaderSetting,
    {
      filterInput: {
        usage_field_name: "mfg_type",
        usage_field_type: EntityTypeEnum.Bom,
      },
    }
  );

  const columnDef: ColDef[] = useBomColumnDefs(
    t,
    data?.creatables ?? [],
    false
  );

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        created_date,
        start_date,
        end_date,
        expect_date,
        created_by,
        is_active,
        ...otherFilterModel
      } = filterModel;

      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        start_date: dateFilterModel(start_date),
        end_date: dateFilterModel(end_date),
        created_by: created_by && {
          filterType: "json",
          type: created_by.type,
          filter: created_by.filter,
          path: ["first_name"],
        },
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
      };
      try {
        const { bomsFindManyAggrid } =
          await graphQLClientWithHeaderManufacture.request(BOMS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: bomsFindManyAggrid.data as any[],
          rowCount: bomsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/bom/${encodeURIComponent(params.data.id)}`);
  };

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceItemUniqueId =
        params.api.getFilterInstance("item_unique_id");

      if (isFilter)
        instanceItemUniqueId?.setModel({
          filterType: "text",
          type: "equels",
          filter: isFilter,
        });
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/manufacture/bom"}
    />
  );
};

export default BomTable;
