import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";
import { EntityTypeEnum } from "../../generated/creatable";

const defaultHeader = [
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 120,
  },
  {
    thaiLabel: "สถานะ",
    width: 120,
  },
  {
    thaiLabel: "มูลค่า",
    width: 120,
  },
  {
    thaiLabel: "วันที่ออกเอกสาร",
    width: 120,
  },
];

const defaultDeliveryHeader = [
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 120,
  },
  {
    thaiLabel: "สถานะ",
    width: 120,
  },

  {
    thaiLabel: "วันที่ออกเอกสาร",
    width: 120,
  },
  {
    thaiLabel: "วันกำหนดส่ง",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const quotationHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "ใช้ได้ถึง",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const salesOrderHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "วันกำหนดส่งของ",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const salesReturnHeader: IHeaderTable[] = [...defaultHeader];

const purchaseRequestHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "ต้องการภายในวันที่",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const purchaseOrderHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "วันประมาณการณ์สินค้าเข้า",
    width: 120,
  },
  {
    thaiLabel: "ต้องการภายในวันที่",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const purchaseReturnHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "วันที่ส่ง",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

const deliveryOrderHeader: IHeaderTable[] = [...defaultDeliveryHeader];

const deliveryTripHeader: IHeaderTable[] = [
  ...defaultDeliveryHeader,
  {
    thaiLabel: "การจัดส่ง",
    width: 120,
  },
  {
    thaiLabel: "ผู้จัดส่ง",
    width: 120,
  },
];

const manufatureOrderHeader: IHeaderTable[] = [
  ...defaultHeader,
  {
    thaiLabel: "วันกำหนดผลิต",
    width: 120,
  },
  {
    thaiLabel: "วันกำหนดผลิตเสร็จ",
    width: 120,
  },
  {
    thaiLabel: "ครบกำหนดอีก",
    width: 120,
  },
];

export const useProjectRelatedDocumentList = (
  documentType: EntityTypeEnum | string,
  tab: string
) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    switch (documentType) {
      case EntityTypeEnum.Quotation:
        setHeaders(quotationHeader);
        break;
      case EntityTypeEnum.SalesOrder:
        setHeaders(salesOrderHeader);
        break;
      case EntityTypeEnum.SalesReturn:
        setHeaders(salesReturnHeader);
        break;
      case EntityTypeEnum.PurchaseRequest:
        setHeaders(purchaseRequestHeader);
        break;
      case EntityTypeEnum.PurchaseOrder:
        setHeaders(purchaseOrderHeader);
        break;
      case EntityTypeEnum.PurchaseReturn:
        setHeaders(purchaseReturnHeader);
        break;
      case EntityTypeEnum.DeliveryOrder:
        setHeaders(deliveryOrderHeader);
        break;
      case EntityTypeEnum.DeliveryTrip:
        setHeaders(deliveryTripHeader);
        break;
      case EntityTypeEnum.ManufactureOrder:
        setHeaders(manufatureOrderHeader);
        break;
      case "ALL":
      default:
        switch (tab) {
          case "sales":
          case "purchase":
            setHeaders(defaultHeader);
            break;
          case "delivery":
            setHeaders(defaultDeliveryHeader);
            break;
          case "manufacture":
            setHeaders(
              defaultHeader.filter((item) => item.thaiLabel !== "มูลค่า")
            );
            break;
          default:
            break;
        }
        break;
    }
  }, [documentType, tab]);

  return headers;
};
