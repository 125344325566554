import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { IGoodsIssue } from "../../../types/Inventory/goodsIssue";
import { Typography } from "@mui/material";
import GoodsIssueTable from "../../../components/Table/Inventory/GoodsIssue";
import { useParams } from "react-router-dom";

const GoodsIssueTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IGoodsIssue>>(null);
  return (
    <>
      <Typography variant="h5" mb={3}>
        ใบ{t("inventory.goods_issue.index")}
      </Typography>
      <GoodsIssueTable gridRef={gridRef} referenceUniqueId={id} />
    </>
  );
};

export default GoodsIssueTab;
