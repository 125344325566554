import {
  Drawer,
  Box,
  Typography,
  IconButton,
  DrawerProps,
  Grid,
  CircularProgress,
  Divider,
} from "@mui/material";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ControlPointOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import TaskModal from "./TaskModal";
import { useForm } from "react-hook-form";
import { IProject, ITask } from "../../../types/Project";
import { taskSchema, taskValidation } from "../../Form/Project/Task/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TasksQuery,
  useTaskCreateMutation,
  useTaskDeleteMutation,
  useTaskUpdateMutation,
  useTasksQuery,
} from "../../../generated/project";
import { enqueueSnackbar } from "notistack";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  taskCreatePayloadFormatter,
  taskUpdatePayloadFormatter,
} from "../../../utils/Formatter/Project";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import TaskItem from "./TaskItem";
import { useParams } from "react-router-dom";
import { useTaskDnD } from "../../../hooks/Projects/Task/use-task-dnd";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

type Props = {
  open: DrawerProps["open"];
  onClose: () => void;
  project?: IProject;
  refetchProject: any;
};

const TaskDrawer = ({ open, onClose, project, refetchProject }: Props) => {
  const { t } = useTranslation();
  const { id: projectId } = useParams();
  const [taskModal, setTaskModal] = useState(false);
  const [expanded, setExpanded] = useState<string[]>(["1"]);

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<ITask>({
    defaultValues: taskSchema,
    resolver: yupResolver(taskValidation),
  });

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const {
    data: tasksQuery,
    isLoading: isLoadingTasks,
    refetch: refetchTasks,
  } = useTasksQuery<TasksQuery>(
    graphQLClientWithHeaderProject,
    {},
    { enabled: open }
  );

  const { doneTasks, notDoneTasks, onDragEnd, updateProjectHandler } =
    useTaskDnD(tasksQuery?.tasks as ITask[], project, refetchProject);

  const { isLoading: isCreating, mutate: createTask } =
    useTaskCreateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: async ({ createTask }) => {
        if (createTask) {
          handleCloseTaskModal();
          enqueueSnackbar("สร้างงานสำเร็จ", {
            variant: "success",
          });
          console.log(createTask);

          // After creating new task, add to tasks_id_list in the project
          if (project) {
            if (project?.tasks_id_list) {
              await updateProjectHandler(project, [
                parseInt(createTask.id),
                ...project?.tasks_id_list,
              ]);
            } else {
              await updateProjectHandler(project, [parseInt(createTask.id)]);
            }
          }

          refetchTasks();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "task");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างงานไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: updateTask } =
    useTaskUpdateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: ({ updateTask }) => {
        enqueueSnackbar("แก้ไขงานสำเร็จ", {
          variant: "success",
        });
        handleCloseTaskModal();
        refetchTasks();

        if (updateTask) {
          if (updateTask.status === 1) {
            handleExpandDoneTask();
          }
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "task");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขงานไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { mutate: deleteTask } = useTaskDeleteMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: async ({ removeTask }) => {
        enqueueSnackbar("ลบงานสำเร็จ", {
          variant: "success",
        });
        handleCloseTaskModal();

        // After removing a task, update to tasks_id_list
        if (project && project.tasks_id_list) {
          const removedTaskId = parseInt(removeTask.id);
          const newTaskIdList = project.tasks_id_list.filter(
            (taskId) => taskId !== removedTaskId
          );
          await updateProjectHandler(project, newTaskIdList);
        }

        refetchTasks();
      },
      onError: (err) => {
        console.log(err);
        enqueueSnackbar("ลบงานไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const handleOpenTaskModal = (taskId?: string) => {
    if (taskId) {
      const focusedTask = tasksQuery?.tasks.find(
        (task) => task.id === taskId
      ) as ITask;
      reset(focusedTask);
    } else {
      reset(taskSchema);
    }
    setTaskModal(true);
  };

  const handleCloseTaskModal = () => {
    reset(taskSchema);
    setTaskModal(false);
  };

  const handleCreateTask = async (data: ITask) => {
    if (projectId) {
      const formattedTaskPayload = await taskCreatePayloadFormatter(
        data,
        projectId
      );
      createTask({ data: formattedTaskPayload });
    } else {
      console.log("no project id found ");
    }
  };

  const handleEditTask = async (data: ITask) => {
    const formattedTaskPayload = await taskUpdatePayloadFormatter(data);
    console.log(formattedTaskPayload);

    updateTask({
      where: { id: parseInt(data.id) },
      data: formattedTaskPayload,
    });
  };

  const handleDeleteTask = (id: string) => {
    deleteTask({ where: { id: parseInt(id) } });
  };

  const handleChangeTaskStatus = async (task: ITask) => {
    const newStatus = task.status === 0 ? 1 : 0;
    const taskUpdatedStatus = { ...task, status: newStatus };
    const formattedTaskPayload = await taskUpdatePayloadFormatter(
      taskUpdatedStatus
    );
    console.log(formattedTaskPayload);
    updateTask({
      where: { id: parseInt(task.id) },
      data: formattedTaskPayload,
    });
  };

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleExpandDoneTask = () => {
    setExpanded((prev) => [...prev, "2"]);
  };

  const renderTaskDrawerHeader = () => (
    <>
      <Grid container justifyContent={"space-between"} mb={2}>
        <Grid item>
          <Typography fontWeight={"bold"}>{t("project.task.index")}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose} size="small">
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} mb={2}>
        <Grid item mr={2}>
          <Typography
            fontWeight={"bold"}
            color={(theme) => theme.palette.primary.main}
          >
            {t("project.task.add")}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => handleOpenTaskModal()}
            size="small"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <ControlPointOutlined fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );

  useEffect(() => {
    if (open) setExpanded(["1"]);
  }, [open]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 450,
          borderRadius: "10px 0px 0px 10px",
          padding: "16px",
        },
      }}
      ModalProps={{ sx: { zIndex: "rightDrawer" } }}
    >
      <CustomizedScrollbar>
        {renderTaskDrawerHeader()}
        {isLoadingTasks ? (
          <Box
            sx={{
              height: "calc(100vh - 300px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={["1"]}
            sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
            expanded={expanded}
            onNodeToggle={handleToggle}
          >
            <TreeItem
              nodeId="1"
              label={`ยังไม่สำเร็จ : ${notDoneTasks.length}`}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"not_done_task"}>
                  {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                  ) => (
                    <Box
                      sx={{
                        margin: "15px",
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {notDoneTasks.map((task, index) => (
                        <TaskItem
                          task={task}
                          index={index}
                          key={task.id}
                          onClick={() => handleOpenTaskModal(task.id)}
                          statusChangeHandler={handleChangeTaskStatus}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </TreeItem>
            <Divider sx={{ my: 1 }} />
            <TreeItem nodeId="2" label={`สำเร็จแล้ว : ${doneTasks.length}`}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"done_task"}>
                  {(
                    provided: DroppableProvided,
                    snapshot: DroppableStateSnapshot
                  ) => (
                    <Box
                      sx={{
                        margin: "15px",
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {doneTasks.map((task, index) => (
                        <TaskItem
                          task={task}
                          index={index}
                          key={task.id}
                          onClick={() => handleOpenTaskModal(task.id)}
                          statusChangeHandler={handleChangeTaskStatus}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </TreeItem>
          </TreeView>
        )}
      </CustomizedScrollbar>
      <TaskModal
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        taskModal={taskModal}
        closeTaskModalHandler={handleCloseTaskModal}
        createTaskHandler={handleSubmit(handleCreateTask)}
        editTaskHandler={handleSubmit(handleEditTask)}
        deleteTaskHandler={handleDeleteTask}
        disabled={isCreating || isUpdating}
      />
    </Drawer>
  );
};

export default TaskDrawer;
