import React, { useState, forwardRef, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// import { useSelector } from "react-redux";
import { Controller, useFieldArray } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { validationConversion } from "./validation";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { IDefaultForm, IHeaderTable } from "../../../../types/global";
import { IUom, IUomConversion } from "../../../../types/Inventory/item";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type ExtendedProps = IDefaultForm & {
  uomData: IUom[] | [];
  watchBaseUom: string;
  watchConversion: IUomConversion[];
};

const Conversion = forwardRef<HTMLInputElement, ExtendedProps>(
  (
    {
      control,
      errors,
      setValue,
      getValues,
      uomData,
      watchBaseUom,
      watchConversion,
      disabled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [headers, setHeaders] = useState<IHeaderTable[]>([]);

    const { fields, replace, append } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "uom_conversion_list", // unique name for your Field Array
    });

    const allUomConversion =
      watchConversion?.map((uom) => uom.target_uom_unique_id) ?? [];

    useEffect(() => {
      const newValue = getValues("uom_conversion_list");
      replace(newValue);
    }, [getValues, replace]);

    useEffect(() => {
      if (disabled)
        setHeaders([
          { label: t("inventory.items.conversion.quantity"), width: 230 },
          { label: t("inventory.items.conversion.baseUom"), width: 230 },
          {
            label: t("inventory.items.conversion.quantityConversion"),
            width: 230,
          },
          {
            label: t("inventory.items.conversion.conversionUom"),
            width: 230,
          },
        ]);
      else
        setHeaders([
          { label: t("inventory.items.conversion.quantity"), width: 210 },
          { label: t("inventory.items.conversion.baseUom"), width: 210 },
          {
            label: t("inventory.items.conversion.quantityConversion"),
            width: 210,
          },
          {
            label: t("inventory.items.conversion.conversionUom"),
            width: 210,
          },
          { label: "", width: 90 },
        ]);
    }, [disabled, t]);

    const handleAppendData = () => {
      const filterUom = uomData
        .filter((uom) => uom.unique_id !== watchBaseUom)
        .filter((uom) => !allUomConversion.includes(uom.unique_id));

      append({
        base_uom_rate: 1,
        target_uom_rate: 1,
        base_uom_unique_id: watchBaseUom,
        target_uom_unique_id: (filterUom && filterUom[0].unique_id) || "01",
      });
    };

    const handleRemoveAttribute = (index: number) => {
      const uomValues = getValues("uom_conversion_list");
      const newValues = uomValues.filter(
        (uom: any, uomInd: number) => index !== uomInd
      );
      replace(newValues);
    };
    const renderFieldValue = (value: string) =>
      uomData?.find((uom) => uom?.unique_id === value)?.name;

    return (
      <>
        <Typography fontWeight="bold">
          {t("inventory.items.conversion.index")}
        </Typography>
        {!disabled && (
          <CustomizedButton
            testId="item-add-conversion-btn"
            color="secondary"
            onClick={handleAppendData}
            title={t("button.add")}
            variant="outlined"
            sx={{ mt: 1, mr: 2 }}
            disabled={!watchBaseUom}
          />
        )}
        <Box sx={{ my: disabled ? 0 : 1 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 100, overflow: "scroll" }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  bgcolor: "primary.light",
                }}
              >
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell align="center" key={index} width={header.width}>
                      <Box minWidth={header.width}>
                        <Typography
                          textAlign="center"
                          fontSize={14}
                          fontWeight={700}
                        >
                          {header.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((row, index: number) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <Box sx={{ mt: "2px" }}>
                        <Controller
                          name={`uom_conversion_list[${index}].base_uom_rate`}
                          control={control}
                          render={({ field }) => (
                            <CustomizedTextField
                              testId={`item-conversion-base-rate-text-field-${index}`}
                              type="number"
                              // error={Boolean(errors[index]?.base_uom_rate)}
                              // helperText={
                              //   errors[index]?.base_uom_rate?.message
                              // }
                              disabled={disabled}
                              defaultValue=""
                              {...field}
                              onChange={(e) => {
                                if (e.target.value !== null)
                                  setValue(
                                    `uom_conversion_list[${index}].base_uom_rate`,
                                    parseFloat(e.target.value)
                                  );
                                else
                                  setValue(
                                    `uom_conversion_list[${index}].base_uom_rate`,
                                    e.target.value
                                  );
                              }}
                            />
                          )}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ mt: "2px" }} alignItems={"center"}>
                        <CustomizedTextField
                          disabled
                          value={renderFieldValue(watchBaseUom)}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ mt: "2px" }}>
                        <Controller
                          control={control}
                          name={`uom_conversion_list[${index}].target_uom_rate`}
                          render={({ field }) => (
                            <CustomizedTextField
                              testId={`item-conversion-target-rate-text-field-${index}`}
                              type={"number"}
                              error={Boolean(
                                errors.uom_conversion_list?.[index]
                                  ?.target_uom_rate
                              )}
                              helperText={
                                errors.uom_conversion_list?.[index]
                                  ?.target_uom_rate?.message
                              }
                              defaultValue=""
                              disabled={disabled}
                              {...field}
                              onChange={(e) => {
                                if (e.target.value !== null)
                                  setValue(
                                    `uom_conversion_list[${index}].target_uom_rate`,
                                    parseFloat(e.target.value)
                                  );
                                else
                                  setValue(
                                    `uom_conversion_list[${index}].target_uom_rate`,
                                    e.target.value
                                  );
                              }}
                            />
                          )}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ mt: "2px" }}>
                        <Controller
                          name={`uom_conversion_list[${index}].target_uom_unique_id`}
                          control={control}
                          render={({ field }) => (
                            <CustomizedComboBox
                              testId={`item-conversion-target-uom-field-${index}`}
                              options={uomData
                                ?.map((uom) => {
                                  return {
                                    id: uom.unique_id,
                                    value: uom.unique_id,
                                    label: uom.name,
                                    name: uom.name,
                                  };
                                })
                                .filter((uom) => uom.id !== watchBaseUom)
                                .filter(
                                  (uom) => !allUomConversion.includes(uom.name)
                                )}
                              {...field}
                              disabled={disabled}
                              value={renderFieldValue(field.value)}
                              onChange={(_, newValue) => {
                                field.onChange(newValue ? newValue.id : "");
                              }}
                            />
                          )}
                        />
                      </Box>
                    </TableCell>
                    {!disabled && (
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                          onClick={() => {
                            handleRemoveAttribute(index);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }
);

export default Conversion;
