import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
// import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { Controller } from "react-hook-form";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import { IDefaultForm } from "../../../../types/global";

type ExtendedProps = IDefaultForm & {
  isInventoryPage?: boolean;
};

const MRP = ({ control, errors, disabled, isInventoryPage }: ExtendedProps) => {
  const { t } = useTranslation();

  const option = [
    { label: "ไม่ได้ตั้งค่า", value: "no_setting", id: "ไม่ได้ตั้งค่า" },
    { label: "ผลิต", value: "produce", id: "ผลิต" },
    { label: "สั่งซื้อ", value: "buy", id: "สั่งซื้อ" },
  ];

  return (
    <Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Controller
            control={control}
            name={"mrp_default_method"}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={option}
                label={t("inventory.items.mrp")}
                disabled={!isInventoryPage || disabled}
                error={Boolean(errors.mrp_default_method)}
                helperText={errors.mrp_default_method?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MRP;
