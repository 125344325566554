import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useLocation, useParams } from "react-router";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsReturnDetail from "../../../components/Form/Inventory/GoodsReturn/Detail";
import GoodsReturnHeader from "../../../components/Form/Inventory/GoodsReturn/Header";
import GoodsReturnItemList from "../../../components/Table/Inventory/GoodsReturn/GoodsReturnItemList";
import {
  goodsReturnSchema,
  goodsReturnStepTwoValidation,
  goodsReturnStepOneValidation,
  // goodsReturnValidation,
} from "../../../components/Form/Inventory/GoodsReturn/schema";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  GoodsReceiveCreateInput,
  GoodsReceiveQuery,
  GoodsReceiveUpdateInput,
  InventoryDocumentStatus,
  InventoryDocumentType,
  WarehousesQuery,
  useGoodsReceiveCreateMutation,
  useGoodsReceiveQuery,
  useGoodsReceiveUpdateMutation,
  useInventoryDocumentNextStatusMutation,
  useWarehousesQuery,
  useItemSkuDetailsQuery,
  ItemSkuDetailsQuery,
} from "../../../generated/inventory";
import { IGoodsReturn } from "../../../types/Inventory/goodsReturn";
import { useSnackbar } from "notistack";
import {
  goodsReturnCreatePayloadFormatter,
  goodsReturnQueryFormatter,
  goodsReturnUpdatePayloadFormatter,
} from "../../../utils/Formatter/GoodsReturn";
import { useNavigate } from "react-router-dom";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
  Stack,
} from "@mui/material";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useInventoryError } from "../../../hooks/Inventory/use-inventory-error";
import GoodsReturnScanItemList from "../../../components/Table/Inventory/GoodsReturn/GoodsReturnScanItemList";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import GoodsReturnMobile from "./Mobile";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";

const GoodsReturnContainer = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [step, setStep] = useState<number>(1);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialTraceItems, setInitialTraceItems] = useState<string[]>([]);
  const [existingInitialTraceItems, setExistingInitialTraceItems] = useState<
    string[]
  >([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_return.index"),
      to: "/inventory/goods_return",
    },
    {
      name: Boolean(id)
        ? `${id}`
        : `${t("button.create")}${t("inventory.goods_return.index")}`,
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isLoading, refetch } = useGoodsReceiveQuery<GoodsReceiveQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { refetch: fetchItemSkuDetails } =
    useItemSkuDetailsQuery<ItemSkuDetailsQuery>(
      graphQLClientWithHeaderItem,
      {
        findManyInput: {
          sku_name: {
            in: data?.goodsReceive?.trace_entry_list?.map(
              (trace: any) => trace?.item_unique_id
            ),
          },
        },
      },
      {
        enabled: false,
      }
    );

  useEffect(() => {
    if (data) {
      const traceItems =
        data?.goodsReceive?.trace_entry_list?.map(
          (trace: any) => trace.item_unique_id
        ) ?? [];
      setInitialTraceItems(traceItems);
      const getItemSkuDetails = async () => {
        const { data: result } = await fetchItemSkuDetails();
        const existingSkuNames =
          result?.itemSkuDetails?.map((sku: any) => sku.sku_name) ?? [];
        setExistingInitialTraceItems(existingSkuNames);
      };
      getItemSkuDetails();
    }
  }, [data, fetchItemSkuDetails]);

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IGoodsReturn>({
    defaultValues: goodsReturnSchema,
    resolver:
      step === 2
        ? yupResolver(goodsReturnStepTwoValidation)
        : yupResolver(goodsReturnStepOneValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [reset, state]);

  useEffect(() => {
    const getGoodsReturn = async () => {
      const { data, isSuccess } = await refetch();
      if (isSuccess) {
        const { goodsReceive } = data;
        const goodsReceiveType = goodsReceive as IGoodsReturn;
        const formattedGoodsReceive =
          goodsReturnQueryFormatter(goodsReceiveType);
        const currentStep = formattedGoodsReceive.draft_step_no;
        setStep(currentStep);
        reset(formattedGoodsReceive);
      }
    };
    getGoodsReturn();
  }, [refetch, reset, step]);

  const { isLoading: isCreating, mutateAsync: createGoodsReceive } =
    useGoodsReceiveCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: ({ goodsReceiveCreate }) => {
        if (goodsReceiveCreate) {
          enqueueSnackbar("สร้างใบรับคืนสำเร็จ", {
            variant: "success",
          });
          navigate(`/inventory/goods_return/${goodsReceiveCreate?.unique_id}`);
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบรับคืนไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: updateGoodsReceive } =
    useGoodsReceiveUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar(`${snackbarMessage}ไม่สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        refetch();
      },
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.goodsReceive?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.goodsReceive?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onGoodsReturnCreate = async (
    data: IGoodsReturn,
    status: string,
    step: number
  ) => {
    const payload = goodsReturnCreatePayloadFormatter(
      data
    ) as GoodsReceiveCreateInput;
    await createGoodsReceive({
      createInput: { ...payload, sub_status: status, draft_step_no: step },
    });
  };

  const onGoodsReturnUpdate = async (data: IGoodsReturn) => {
    //check whether trace still have is_scan false or not
    if (data) {
      if (data.main_status === "draft" && step === 1) {
        const traces = data.trace_entry_list.map(
          (trace) => trace.item_unique_id
        );
        const matchingIntialTraces = traces.filter((trace) =>
          initialTraceItems.includes(trace)
        );
        const tracesToBeDeleted = matchingIntialTraces.filter(
          (trace) => !existingInitialTraceItems.includes(trace)
        );
        if (tracesToBeDeleted.length > 0) {
          enqueueSnackbar(
            "เอกสารมีสินค้าที่ถูกลบออกจากระบบแล้ว\nกรุณาลบสินค้า",
            {
              variant: "error",
              style: { whiteSpace: "pre-line" },
            }
          );
          return;
        }
      }
      const payload = goodsReturnUpdatePayloadFormatter(
        data
      ) as GoodsReceiveUpdateInput;
      setSnackbarMessage("บันทึกร่าง");
      await updateGoodsReceive({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onGoodsReturnImport = async (data: IGoodsReturn) => {
    if (data) {
      const payload = goodsReturnUpdatePayloadFormatter(
        data
      ) as GoodsReceiveUpdateInput;
      setSnackbarMessage("รับคืน");
      await updateGoodsReceive({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: { ...payload, sub_status: "finished" },
      });
      await updateStatus({
        documentInput: {
          reference_document_type: InventoryDocumentType.GoodsReturn,
          unique_id: data.unique_id,
        },
      });
    }
  };

  const onGoodsReturnNextStep = async (data: IGoodsReturn) => {
    if (data) {
      if (!id) {
        await onGoodsReturnCreate(data, "draft", 2);
      } else {
        const payload = goodsReturnUpdatePayloadFormatter(
          data
        ) as GoodsReceiveUpdateInput;
        setSnackbarMessage("ดำเนินต่อ");
        await updateGoodsReceive({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, draft_step_no: 2 },
        });
      }
      setStep(2);
    }
  };

  const onGoodsReturnPreviousStep = async (data: IGoodsReturn) => {
    if (data) {
      const payload = goodsReturnUpdatePayloadFormatter(data);
      setSnackbarMessage("ย้อนกลับ");
      const { trace_entry_list, ...otherPayload } = payload;
      const formatTrace = trace_entry_list.map(
        ({
          scanned_by,
          posted_date,
          status,
          source_bin_location,
          source_bin_location_id,
          ...otherTrace
        }) => ({
          ...otherTrace,
          status: InventoryDocumentStatus.IsActive,
        })
      );
      const formatPayload = {
        ...otherPayload,
        trace_entry_list: formatTrace,
        draft_step_no: 1,
      } as GoodsReceiveUpdateInput;
      await updateGoodsReceive({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: formatPayload,
      });
      setStep(1);
    }
  };

  const renderButton = () => {
    switch (data?.goodsReceive?.aggrid_status) {
      case "draft":
        if (step === 1) {
          if (isMobile) {
            return (
              <Grid container spacing={1} mt={3}>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    variant="outlined"
                    title={t("button.save_draft")}
                    disabled={isUpdating}
                    onClick={handleSubmit(onGoodsReturnUpdate)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomizedButton
                    fullWidth
                    size="large"
                    title={t("button.next")}
                    variant="contained"
                    onClick={handleSubmit(onGoodsReturnNextStep)}
                    disabled={isUpdating}
                  />
                </Grid>
              </Grid>
            );
          } else {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <CustomizedButton
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onGoodsReturnUpdate)}
                />
                <CustomizedButton
                  title={t("button.next")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsReturnNextStep)}
                  disabled={isUpdating}
                />
              </Stack>
            );
          }
        }
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.back")}
                  disabled={isUpdating}
                  onClick={handleSubmit(onGoodsReturnPreviousStep)}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.save_draft")}
                  variant="outlined"
                  onClick={handleSubmit(onGoodsReturnUpdate)}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("inventory.goods_receive.index")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsReturnImport)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        }
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomizedButton
              variant="outlined"
              title={t("button.back")}
              disabled={isUpdating}
              onClick={handleSubmit(onGoodsReturnPreviousStep)}
            />
            <CustomizedButton
              title={t("button.save_draft")}
              variant="outlined"
              onClick={handleSubmit(onGoodsReturnUpdate)}
              disabled={isUpdating}
            />
            <CustomizedButton
              title={t("inventory.goods_return.index")}
              variant="contained"
              onClick={handleSubmit(onGoodsReturnImport)}
              disabled={isUpdating || isChanging}
            />
          </Stack>
        );
      case "finished":
      case "cancelled":
        return null;
      default:
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isCreating}
                  onClick={handleSubmit((data) =>
                    onGoodsReturnCreate(data, "draft", 1)
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.next")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsReturnNextStep)}
                  disabled={isUpdating}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating}
                onClick={handleSubmit((data) =>
                  onGoodsReturnCreate(data, "draft", 1)
                )}
              />
              <CustomizedButton
                title={t("button.next")}
                variant="contained"
                onClick={handleSubmit(onGoodsReturnNextStep)}
                disabled={isUpdating}
              />
            </Stack>
          );
        }
    }
  };

  useInventoryError(errors);

  if (id && (isWarehouseLoading || isLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isMobile ? (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsReturnMobile
        control={control}
        disabled={disabled}
        errors={errors}
        getValues={getValues}
        refetch={refetch}
        setValue={setValue}
        data={data}
        reset={reset}
        warehouseData={warehouseData}
        renderButton={renderButton}
        append={append}
        fields={fields}
        remove={remove}
        update={update}
        step={step}
      />
    </form>
  ) : (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsReturnHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        refetch={refetch}
      />
      <GoodsReturnDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsReceive?.aggrid_status}
        allWarehouses={warehouseData?.warehouses || []}
        append={append}
        reset={reset}
        step={step}
      />
      <CustomizedBox margin="2rem 0 4rem 0">
        {step === 1 ? (
          <GoodsReturnItemList
            control={control}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
            errors={errors}
            fields={fields}
            step={step}
            initialTraceItems={initialTraceItems}
            existingInitialTraceItems={existingInitialTraceItems}
          />
        ) : (
          <GoodsReturnScanItemList
            control={control}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
            errors={errors}
            fields={fields}
            step={step}
            append={append}
            remove={remove}
            update={update}
            allWarehouses={warehouseData?.warehouses || []}
            initialTraceItems={initialTraceItems}
            existingInitialTraceItems={existingInitialTraceItems}
          />
        )}
      </CustomizedBox>
      {!["finished", "cancelled"].includes(
        data?.goodsReceive?.aggrid_status || ""
      ) && <BottomNavbar>{renderButton()}</BottomNavbar>}
    </form>
  );
};

export default GoodsReturnContainer;
