import { ColDef, ICellRendererParams } from "ag-grid-community";
import { TFunction } from "i18next";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { IUser } from "../../../types/Auth/user";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  t: TFunction;
  getAllTag?: () => void;
  isReport?: boolean;
  handleDelete?: (index: number) => void;
}

export const userColumnDefs = (
  t: Props["t"],
  handleDelete?: Props["handleDelete"]
): ColDef[] => {
  return [
    {
      field: "unique_id",
      headerName: t("user.account.id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("user.account.img_url"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams<IUser, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              avatars={params.value.map((v) => ({
                img_url: v,
                unique_id: params?.data?.unique_id ?? "",
                first_name: params?.data?.first_name ?? "",
                last_name: params?.data?.last_name ?? "",
              }))}
            />
          );
        } else
          return (
            <CustomizedAvatar
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.first_name ?? "",
                  last_name: params?.data?.last_name ?? "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: t("user.account.first_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.last_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "บริหาร",
          "คลังสินค้า",
          "รายรับ",
          "จัดซื้อ",
          "บัญชี",
          "ขนส่ง",
          "ผลิต",
          "ดูแลระบบ",
        ],
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "is_active",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }: { value: number }) => {
        return <CustomizedStatus status={value ? "active" : "inactive"} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      hide: handleDelete ? false : true,
      field: "",
      filter: false,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data.document_id === "EP-ADMIN") {
          return;
        }
        return (
          <IconButton
            aria-label="delete"
            size="small"
            sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() =>
              handleDelete && handleDelete(params.node.rowIndex || 0)
            }
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        );
      },
      width: 80,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
  ];
};
