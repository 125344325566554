import React, { useMemo } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Box, Grid } from "@mui/material";
import DroppableContainer from "./DroppableContainer";
import { IProject, IProjectWorkflowTemplate } from "../../../types/Project";
import Confirmation from "../Confirmation";

interface Props {
  projectsGroupedByStatus: Record<string, IProject[]>;
  onDragEnd: (result: DropResult) => void;
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  changeStatusHandler: (dropReslt: DropResult | undefined) => void;
  dropResult: DropResult | undefined;
  allStatus?: IProjectWorkflowTemplate[];
  toggleSorting: (droppableId: string) => void;
  sorting: Record<string, boolean>;
}

const KanbanBoard: React.FC<Props> = ({
  projectsGroupedByStatus,
  onDragEnd,
  confirmation,
  closeConfirmationHandler,
  changeStatusHandler,
  dropResult,
  allStatus,
  toggleSorting,
  sorting,
}: Props) => {
  // Define the sorting order of kanbanboard
  const customStatusOrder: { [status: string]: number } = useMemo(
    () => ({
      pre_sales: 1,
      post_sales: 2,
      support: 3,
      finished: 4,
    }),
    []
  );

  const sortedStatus = useMemo(
    () =>
      allStatus?.sort(
        (a: IProjectWorkflowTemplate, b: IProjectWorkflowTemplate) => {
          const statusA = customStatusOrder[a.status_name || ""] || 0; // Default to 0 if status not found
          const statusB = customStatusOrder[b.status_name || ""] || 0;

          return statusA - statusB;
        }
      ) ?? [],
    [allStatus, customStatusOrder]
  );

  const sortedStatusWithCancelled = useMemo(
    () => [
      ...sortedStatus,
      {
        id: "cancelled",
        unique_id: "cancelled",
        status_name: "cancelled",
        sub_status: "cancelled",
      },
    ],
    [sortedStatus]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box
        sx={{
          overflow: "scroll",
          display: "flex",
          flexDirection: "row",
          gap: 2,
          minHeight: "120vh",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {sortedStatusWithCancelled?.map((status) => (
          <Grid item key={status.unique_id}>
            <DroppableContainer
              id={status.sub_status ?? ""}
              title={status.sub_status ?? ""}
              items={
                status.sub_status
                  ? projectsGroupedByStatus[status.sub_status]
                  : []
              }
              key={status.unique_id}
              mainStatus={status.status_name ?? ""}
              toggleSorting={toggleSorting}
              sorting={sorting}
            />
          </Grid>
        ))}

        <Confirmation
          title="คุณต้องการเปลี่ยนสถานะใช่หรือไม่"
          open={confirmation}
          handleClose={closeConfirmationHandler}
          action={() => changeStatusHandler(dropResult)}
        />
      </Box>
    </DragDropContext>
  );
};

export default KanbanBoard;
