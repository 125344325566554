import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GET_ALL_WAREHOUSES } from "../../../../services/AgGrid/InventoryAgGrid";
import { Warehouse } from "../../../../generated/inventory";
import { formatDate } from "../../../../utils/Formatter/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import { IAvatar } from "../../../../types/global";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { GraphQLClient } from "graphql-request";

export const goodsAdjustmentColumnDefs = (t: TFunction) => {
  const columnDef: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.issue_date"),
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("date.adjusted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "unique_id",
      headerName: t("sales.unique_id"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "source_warehouse",
      headerName: t("inventory.warehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");

          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value.name;
        } else {
          return "";
        }
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("sales.created_by"),
      filter: "agTextColumnFilter",
      cellRenderer: (
        params: ICellRendererParams<IGoodsAdjustment, IAvatar>
      ) => {
        if (params.value) {
          if (params.value.img_url && params.value.img_url.length > 0) {
            return <CustomizedAvatar avatars={[params.value]} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.value.first_name + " " + params.value.last_name}
              />
            );
          }
        }
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "remark",
      headerName: t("sentence.remark"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "aggrid_status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams<IGoodsAdjustment, string>) => (
        <CustomizedStatus status={params.value} />
      ),
      filterParams: {
        values: [
          "draft",
          "wait_approve",
          "not_approved",
          "finished",
          "cancelled",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  return columnDef;
};
