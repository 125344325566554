import { ColDef, RowDoubleClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { IQuotation } from "../../../../types/Sales/quotation";
import { useQuotationColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";

interface Props {
  gridRef: RefObject<AgGridReact<IQuotation>>;
  rowData: any[];
}

const QuotationReportTable = ({ gridRef, rowData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useQuotationColumnDefs(t, true);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/sales/quotation/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default QuotationReportTable;
