import { gql } from "graphql-request";

export const ITEM_SKU_DETAIL_CAN_DISABLE = gql`
  query ItemSkuCanDisable($uniqueInput: ItemSkuValueUniqueInput) {
    itemSkuCanDisable(uniqueInput: $uniqueInput) {
      active_document_list
      can_disable
    }
  }
`;
