import { Box, Divider, Grid, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

interface Props {
  documentType?: string;
}

const OldPDFSigner = ({ documentType }: Props) => {
  return (
    <>
      {documentType === "delivery_trip" ? (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        ></Box>
      ) : documentType === "bom" ? (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              ผู้ออกเอกสาร
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
        </Box>
      ) : documentType === "manufacture_order" ? (
        <>
          <Grid container my={2}>
            <Grid item xs={5.75}>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "10px",
                  letterSpacing: "0.15px",
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                  mb: 2,
                }}
              >
                เบิกสินค้า/วัตถุดิบ
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้เบิก
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _________________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    _____/_____/_____
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้ตรวจ/ฝ่ายบัญชี
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _________________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    _____/_____/_____
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้อนุมัติ
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _________________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    _____/_____/_____
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={0.5} padding="0 15px 0 15px">
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={5.75}>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "10px",
                  letterSpacing: "0.15px",
                  fontWeight: 600,
                  color: "#333333",
                  flex: 1,
                  mb: 2,
                }}
              >
                ผลิตสินค้า/วัตถุดิบ
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้จ่ายงาน
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    ____________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    ___/___/___
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้ผลิต
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _____________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    ___/___/___
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้ตรวจสอบ
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _____________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    ___/___/___
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                    ผู้อนุมัติ
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                    _____________
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                    ___/___/___
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "2rem",
            flexWrap: "nowrap",
            width: "197.3mm",
            justifyContent: "center",
          }}
        >
          {documentType === "delivery_order" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: ".25rem",
                marginTop: "0.5rem",
              }}
            >
              <QRCodeSVG value={window.location.href} size={72} />
              <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสาร</Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "GT"
                ? "ผู้โอน"
                : documentType === "delivery_order"
                ? "ผู้รับสินค้า / Received by"
                : "ผู้ออกเอกสาร"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "delivery_order"
                ? "ผู้ส่งสินค้า / Sent by"
                : documentType === "GT"
                ? "ผู้จัดส่ง"
                : "ผู้ตรวจสอบ"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
              {documentType === "delivery_order"
                ? "ผู้อนุมัติ / Approved by"
                : documentType === "GT"
                ? "ผู้รับ"
                : "ผู้อนุมัติ"}
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
              _________________
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
              _____/_____/_____
            </Typography>
          </Box>
          {documentType === "GT" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 6 }}>
                ผู้ตรวจสอบ
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 600, mb: 0.5 }}>
                _________________
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                _____/_____/_____
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OldPDFSigner;
