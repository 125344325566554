import {
  Badge,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { Theme } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IDefaultForm } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedMenuOptions from "../../Custom/CustomizedMenuOptions";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import EmployeeListForm from "../EmployeeList";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ResposibleEmployeeListForm from "../EmployeeList/ResponsibleEmployeeList";
import { useProjectOption } from "../../../hooks/Projects/use-project-option";
import { useProjectCreate } from "../../../hooks/Projects/use-project-create";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { useProjectStatusOption } from "../../../hooks/Projects/use-project-status-option";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "../../UI/ModalUI";
import TaskDrawer from "../../UI/Task/TaskDrawer";
import {
  TasksQuery,
  WorkflowTemplatesQuery,
  useTasksQuery,
  useWorkflowTemplatesQuery,
} from "../../../generated/project";
import { IEvent, IProject, IStatusOption } from "../../../types/Project";
import EventModal from "../../UI/Event/EventModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { eventSchema, eventValidation } from "./Event/schema";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import Confirmation from "../../UI/Confirmation";

type Props = IDefaultForm & {
  status?: string | null;
  editClickHandler: () => void;
  projectCancelHandler: () => void;
  projectDeletehandler: () => void;
  project?: IProject;
  refetchProject: any;
};

const ProjectHeader = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  editClickHandler,
  projectCancelHandler,
  projectDeletehandler,
  project,
  refetchProject,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { t } = useTranslation();
  const { id } = useParams();

  const [openTask, setOpenTask] = useState<boolean>(false);
  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [cancelRemarkModal, setCancelRemarkModal] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  const {
    control: eventControl,
    setValue: SetEventValue,
    formState: { errors: eventErrors },
    getValues: getEventValues,
    handleSubmit: handleSubmitEvent,
    reset: resetEvent,
  } = useForm<IEvent>({
    defaultValues: eventSchema,
    resolver: yupResolver(eventValidation),
  });

  const mainStatus = project?.main_status;

  const aggridStatus = useWatch({
    control,
    name: "aggrid_status",
  });

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { data, isLoading: statusLoading } =
    useWorkflowTemplatesQuery<WorkflowTemplatesQuery>(
      graphQLClientWithHeaderProject
    );

  const { data: tasksQuery } = useTasksQuery<TasksQuery>(
    graphQLClientWithHeaderProject,
    {},
    { enabled: !!id }
  );

  const selectModifyOptions = useProjectOption(aggridStatus);
  const { selectCreateOptions } = useProjectCreate(aggridStatus);
  const { selectStatusOptions } = useProjectStatusOption(
    data?.workflowTemplates ?? []
  );

  const handleClose = () => {
    setCancelRemarkModal(false);
    setValue("cancel_remark", "");
  };

  const onSubmitCancelProject = () => {
    setCancelRemarkModal(false);
    projectCancelHandler();
  };

  const countNotDoneTasks = tasksQuery?.tasks
    ? tasksQuery?.tasks.filter(
        (task) =>
          task.status === 0 && task.reference_unique_id === project?.unique_id
      ).length
    : 0;

  useEffect(() => {
    if (!id && selectStatusOptions.length > 0) {
      setValue("sub_status", selectStatusOptions[0].value);
      setValue("main_status", selectStatusOptions[0].value);
    }
  }, [id, selectStatusOptions, setValue]);

  return (
    <>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{id ?? t("project.add")}</Typography>
            {id && aggridStatus && (
              <CustomizedStatus
                status={
                  aggridStatus === "cancelled" ? aggridStatus : mainStatus
                }
                text={aggridStatus}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={5.5} sm={5.5} md={4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "ยกเลิก":
                      return setCancelRemarkModal(true);
                    case "ลบ":
                      return setDeleteConfirmation(true);
                    default:
                      break;
                  }
                }}
                disabled={!id}
                sx={{ height: "40px" }}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={id ? 3 : 4}>
              <Controller
                control={control}
                name={"sub_status"}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    loading={statusLoading}
                    label={t("project.project.main_status")}
                    options={selectStatusOptions}
                    onChange={(_, value: IStatusOption) => {
                      if (value) {
                        field.onChange(value.label);
                        setValue("main_status", value.main_status);
                      } else {
                        field.onChange("");
                      }
                    }}
                    groupBy={(option) => option.group}
                    error={Boolean(errors.sub_status)}
                    helperText={errors.status?.sub_message}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={id ? 3 : 4}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                variant="contained"
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "เหตุการณ์":
                      id && setOpenEventModal(true);
                      break;
                    default:
                  }
                }}
                disabled={!id || aggridStatus === "cancelled"}
                sx={{ height: "40px" }}
              />
            </Grid>
            {id && (
              <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                <CustomizedTooltip title="งาน" enterNextDelay={200}>
                  <IconButton
                    onClick={() => {
                      id && setOpenTask(true);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Badge badgeContent={countNotDoneTasks} color="secondary">
                      <ChecklistOutlinedIcon />
                    </Badge>
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            )}
            {id && (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <CustomizedTooltip
                  title="ดูความเคลื่อนไหว"
                  enterNextDelay={200}
                >
                  <IconButton
                    // onClick={() => {
                    //   setOpenDrawer(true);
                    // }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestoreOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center" mb={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("project.project.related_user_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                // hideCreator
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("project.project.responsible_user_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={9} lg={9} xl={9}>
              <ResposibleEmployeeListForm
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          container
          spacing={1}
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          mb={3}
        >
          <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
            <ControlledDatePicker
              name="created_date"
              control={control}
              error={errors.created_date}
              open={createdDateIsOpen}
              onClose={() => setCreatedDateIsOpen(false)}
              onOpen={() => setCreatedDateIsOpen(true)}
              label={t("date.created_date")}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalUI
        open={cancelRemarkModal}
        handleClose={handleClose}
        title={t("project.project.cancel_remark_title")}
      >
        <Fragment>
          <Grid item xs={6}>
            <ControlledTextField
              multiline
              minRows={4}
              maxRows={4}
              name={"cancel_remark"}
              control={control}
              onChange={(e, field) => {
                field.onChange(e);
              }}
            />
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <CustomizedButton
              onClick={handleClose}
              title={t("button.cancel")}
              variant="outlined"
              sx={{ mt: 2, mr: 2 }}
            />
            <CustomizedButton
              type="submit"
              title={t("button.save")}
              variant="contained"
              sx={{ mt: 2 }}
              onClick={onSubmitCancelProject}
            />
          </Box>
        </Fragment>
      </ModalUI>
      <TaskDrawer
        open={openTask}
        onClose={() => setOpenTask(false)}
        project={project}
        refetchProject={refetchProject}
      />
      <EventModal
        eventModal={openEventModal}
        closeEventModalHandler={() => setOpenEventModal(false)}
        project={project}
        control={eventControl}
        setValue={SetEventValue}
        getValues={getEventValues}
        handleSubmit={handleSubmitEvent}
        reset={resetEvent}
        errors={eventErrors}
        disabled={false}
      />
      <Confirmation
        title="ยืนยันการลบโครงการ"
        open={deleteConfirmation}
        handleClose={() => setDeleteConfirmation(false)}
        action={() => projectDeletehandler()}
      />
    </>
  );
};

export default ProjectHeader;
