import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { useParams } from "react-router";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import GoodsTransferDetail from "../../../components/Form/Inventory/GoodsTransfer/Detail";
import GoodsTransferHeader from "../../../components/Form/Inventory/GoodsTransfer/Header";
import GoodsTransferItemList from "../../../components/Table/Inventory/GoodsTransfer/GoodsTransferItemList";
import {
  goodsTransferSchema,
  goodsTransferValidation,
} from "../../../components/Form/Inventory/GoodsTransfer/schema";
import {
  GoodsTransferCreateInput,
  GoodsTransferUpdateInput,
  InventoryDocumentType,
  ItemSkuQtysQuery,
  WarehousesQuery,
  useGoodsTransferCreateMutation,
  useGoodsTransferQuery,
  useGoodsTransferUpdateMutation,
  useInventoryDocumentNextStatusMutation,
  useItemSkuQtysQuery,
  useWarehousesQuery,
  useItemSkuDetailsQuery,
  ItemSkuDetailsQuery,
} from "../../../generated/inventory";
import { GoodsTransferQuery } from "../../../generated/inventory";
import { IGoodsTransfer } from "../../../types/Inventory/goodsTransfer";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  goodsTransferCreatePayloadFormatter,
  goodsTransferQueryFormatter,
  goodsTransferUpdatePayloadFormatter,
} from "../../../utils/Formatter/GoodsTransfer";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import GoodsTransferScanItemList from "../../../components/Table/Inventory/GoodsTransfer/GoodsTransferScanItemList";
import { useInventoryError } from "../../../hooks/Inventory/use-inventory-error";
import { yupResolver } from "@hookform/resolvers/yup";
import Confirmation from "../../../components/UI/Confirmation";
import { useConfirmation } from "../../../hooks/use-confrimation";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import GoodsTransferMobile from "./Mobile";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

const GoodsTransferContainer = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { t } = useTranslation();

  const [barcodeList, setBarcodeList] = useState<any[]>([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [createWithWaitTransfer, setCreateWithWaitTransfer] =
    useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [initialTraceItems, setInitialTraceItems] = useState<string[]>([]);
  const [existingInitialTraceItems, setExistingInitialTraceItems] = useState<
    string[]
  >([]);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_transfer.index"),
      to: "/inventory/goods_transfer",
    },
    {
      name: Boolean(id)
        ? `${id}`
        : `${t("button.create")}${t("inventory.goods_transfer.index")}`,
    },
  ];

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<IGoodsTransfer>({
    defaultValues: goodsTransferSchema,
    resolver: yupResolver(goodsTransferValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isLoading, refetch } =
    useGoodsTransferQuery<GoodsTransferQuery>(
      graphQLClientWithHeaderItem,
      {
        uniqueInput: {
          unique_id: id,
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: fetchItemSkuDetails } =
    useItemSkuDetailsQuery<ItemSkuDetailsQuery>(
      graphQLClientWithHeaderItem,
      {
        findManyInput: {
          sku_name: {
            in: data?.goodsTransfer?.trace_entry_list?.map(
              (trace: any) => trace?.item_unique_id
            ),
          },
        },
      },
      {
        enabled: false,
      }
    );

  useEffect(() => {
    if (data) {
      const traceItems =
        data?.goodsTransfer?.trace_entry_list?.map(
          (trace: any) => trace.item_unique_id
        ) ?? [];
      setInitialTraceItems(traceItems);
      const getItemSkuDetails = async () => {
        const { data: result } = await fetchItemSkuDetails();
        const existingSkuNames =
          result?.itemSkuDetails?.map((sku: any) => sku.sku_name) ?? [];
        setExistingInitialTraceItems(existingSkuNames);
      };
      getItemSkuDetails();
    }
  }, [data, fetchItemSkuDetails]);

  const { data: warehouseData, isLoading: isWarehouseLoading } =
    useWarehousesQuery<WarehousesQuery>(graphQLClientWithHeaderItem);

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            in: barcodeList,
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (id) {
      const allSkuQty = async () => {
        const { data } = await refetch();
        if (data) {
          const { goodsTransfer } = data;
          const traceEntryList = goodsTransfer?.trace_entry_list;
          const allBarcode =
            traceEntryList?.map((trace) => trace?.barcode) || [];
          setBarcodeList(allBarcode);

          const { data: allSkuQtys } = await refetchSkuQty();

          const goodsTransferType = goodsTransfer as IGoodsTransfer;
          const formatGoodsIssue = goodsTransferQueryFormatter(
            goodsTransferType,
            allSkuQtys
          );
          reset(formatGoodsIssue);
        }
      };
      allSkuQty();
    }
  }, [data, id, refetch, refetchSkuQty, reset]);

  const { isLoading: isCreating, mutateAsync: createGoodsTransfer } =
    useGoodsTransferCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: ({ goodsTransferCreate }) => {
        if (goodsTransferCreate) {
          if (createWithWaitTransfer) {
            updateStatus({
              documentInput: {
                reference_document_type: InventoryDocumentType.GoodsTransfer,
                unique_id: goodsTransferCreate.unique_id,
              },
            });
            enqueueSnackbar("รอโอนย้ายสำเร็จ", {
              variant: "success",
            });
          }
        } else {
          enqueueSnackbar("สร้างใบโอนย้ายสำเร็จ", {
            variant: "success",
          });
        }

        navigate(`/inventory/goods_transfer/${goodsTransferCreate?.unique_id}`);
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err);
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบโอนย้ายไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutateAsync: updateGoodsTransfer } =
    useGoodsTransferUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar(`${snackbarMessage}สำเร็จ`, {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar(`${snackbarMessage}ไม่สำเร็จ`, {
          variant: "error",
        });
      },
    });

  const { isLoading: isChanging, mutateAsync: updateStatus } =
    useInventoryDocumentNextStatusMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        refetch();
      },
    });

  useEffect(() => {
    if (
      !isLoading &&
      !isWarehouseLoading &&
      id &&
      data?.goodsTransfer?.aggrid_status !== "draft"
    ) {
      setDisabled(true);
    }
  }, [data?.goodsTransfer?.aggrid_status, id, isLoading, isWarehouseLoading]);

  const onGoodsTransferCreate = async (
    data: IGoodsTransfer,
    status: string
  ) => {
    if (data.main_status === "draft") {
      const traces = data.trace_entry_list.map((trace) => trace.item_unique_id);
      const matchingIntialTraces = traces.filter((trace) =>
        initialTraceItems.includes(trace)
      );
      const tracesToBeDeleted = matchingIntialTraces.filter(
        (trace) => !existingInitialTraceItems.includes(trace)
      );
      if (tracesToBeDeleted.length > 0) {
        enqueueSnackbar("เอกสารมีสินค้าที่ถูกลบออกจากระบบแล้ว\nกรุณาลบสินค้า", {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
        return;
      }
    }
    const payload = goodsTransferCreatePayloadFormatter(
      data
    ) as GoodsTransferCreateInput;
    await createGoodsTransfer({
      createInput: { ...payload, sub_status: status },
    });
  };

  const onGoodsTransferUpdate = async (data: IGoodsTransfer) => {
    if (data) {
      const payload = goodsTransferUpdatePayloadFormatter(
        data
      ) as GoodsTransferUpdateInput;
      setSnackbarMessage("บันทึกร่าง");
      await updateGoodsTransfer({
        uniqueInput: {
          unique_id: id,
        },
        updateInput: payload,
      });
    }
  };

  const onWaitGoodsTransfer = async (data: IGoodsTransfer) => {
    if (data) {
      if (!id) {
        setCreateWithWaitTransfer(true);
        await onGoodsTransferCreate(data, "wait_transfer");
      } else {
        setCreateWithWaitTransfer(false);
        const payload = goodsTransferUpdatePayloadFormatter(
          data
        ) as GoodsTransferUpdateInput;
        setSnackbarMessage("รอโอนย้าย");
        await updateGoodsTransfer({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, sub_status: "wait_transfer" },
        });
        await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsTransfer,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onGoodsTransfer = async (data: IGoodsTransfer) => {
    if (data) {
      const { trace_entry_list } = data;
      if (
        trace_entry_list.length > 0 &&
        trace_entry_list.filter(
          (trace) =>
            trace.source_bin_location_id === trace.destination_bin_location_id
        ).length > 0
      ) {
        enqueueSnackbar("กรุณาเลือกสถานที่ปลายทางให้แตกต่างจากสถานที่ต้นทาง", {
          variant: "error",
        });
        return;
      }
      if (
        trace_entry_list.length > 0 &&
        trace_entry_list.some((trace) => trace.status === "is_active")
      ) {
        openConfirmationHandler();
      } else if (
        trace_entry_list.some((trace) =>
          trace.serial_list?.some((serial) => serial.status === "is_active")
        )
      ) {
        openConfirmationHandler();
      } else {
        const payload = goodsTransferUpdatePayloadFormatter(
          data
        ) as GoodsTransferUpdateInput;
        setSnackbarMessage("โอนย้าย");
        await updateGoodsTransfer({
          uniqueInput: {
            unique_id: id,
          },
          updateInput: { ...payload, sub_status: "finished" },
        });
        await updateStatus({
          documentInput: {
            reference_document_type: InventoryDocumentType.GoodsTransfer,
            unique_id: data.unique_id,
          },
        });
      }
    }
  };

  const onSubmitConfirmation = async () => {
    const data: IGoodsTransfer = getValues();
    const payload = goodsTransferUpdatePayloadFormatter(
      data
    ) as GoodsTransferUpdateInput;
    setSnackbarMessage("โอนย้าย");
    await updateGoodsTransfer({
      uniqueInput: {
        unique_id: id,
      },
      updateInput: { ...payload, sub_status: "finished" },
    });
    await updateStatus({
      documentInput: {
        reference_document_type: InventoryDocumentType.GoodsTransfer,
        unique_id: data.unique_id,
      },
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onSubmitConfirmation);

  const renderButton = () => {
    switch (data?.goodsTransfer?.aggrid_status) {
      case "draft":
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("button.save_draft")}
                  variant="outlined"
                  onClick={handleSubmit(onGoodsTransferUpdate)}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={`รอ${t("inventory.goods_transfer.index")}`}
                  variant="contained"
                  onClick={handleSubmit(onWaitGoodsTransfer)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.save_draft")}
                variant="outlined"
                onClick={handleSubmit(onGoodsTransferUpdate)}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={`รอ${t("inventory.goods_transfer.index")}`}
                variant="contained"
                onClick={handleSubmit(onWaitGoodsTransfer)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }

      case "wait_transfer":
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  onClick={handleSubmit(onGoodsTransferUpdate)}
                  disabled={isUpdating}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={t("inventory.goods_transfer.index")}
                  variant="contained"
                  onClick={handleSubmit(onGoodsTransfer)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                title={t("button.save_draft")}
                variant="outlined"
                onClick={handleSubmit(onGoodsTransferUpdate)}
                disabled={isUpdating}
              />
              <CustomizedButton
                title={t("inventory.goods_transfer.index")}
                variant="contained"
                onClick={handleSubmit(onGoodsTransfer)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }

      case "finished":
      case "cancelled":
        return null;
      default:
        if (isMobile) {
          return (
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  title={t("button.save_draft")}
                  disabled={isCreating}
                  onClick={handleSubmit((data) =>
                    onGoodsTransferCreate(data, "draft")
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomizedButton
                  fullWidth
                  size="large"
                  title={`รอ${t("inventory.goods_transfer.index")}`}
                  variant="contained"
                  onClick={handleSubmit(onWaitGoodsTransfer)}
                  disabled={isUpdating || isChanging}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                disabled={isCreating}
                onClick={handleSubmit((data) =>
                  onGoodsTransferCreate(data, "draft")
                )}
              />
              <CustomizedButton
                title={`รอ${t("inventory.goods_transfer.index")}`}
                variant="contained"
                onClick={handleSubmit(onWaitGoodsTransfer)}
                disabled={isUpdating || isChanging}
              />
            </Stack>
          );
        }
    }
  };

  useInventoryError(errors);

  if (id && (isLoading || isWarehouseLoading)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return isMobile ? (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsTransferMobile
        control={control}
        disabled={disabled}
        errors={errors}
        getValues={getValues}
        refetch={refetch}
        setValue={setValue}
        data={data}
        reset={reset}
        warehouseData={warehouseData}
        renderButton={renderButton}
        append={append}
        fields={fields}
        remove={remove}
        update={update}
      />
    </form>
  ) : (
    <form
      onKeyDown={(e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") e.preventDefault();
      }}
    >
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <GoodsTransferHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsTransfer?.aggrid_status}
        refetch={refetch}
      />
      <GoodsTransferDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        status={data?.goodsTransfer?.aggrid_status}
      />
      <CustomizedBox margin="2rem 0 4rem 0">
        {data?.goodsTransfer?.sub_status === "wait_transfer" ||
        data?.goodsTransfer?.sub_status === "finished" ? (
          <GoodsTransferScanItemList
            control={control}
            errors={errors}
            fields={fields}
            disabled={disabled}
            allWarehouses={warehouseData?.warehouses || []}
            status={data?.goodsTransfer?.aggrid_status}
            update={update}
            getValues={getValues}
            setValue={setValue}
          />
        ) : (
          <GoodsTransferItemList
            control={control}
            getValues={getValues}
            setValue={setValue}
            disabled={disabled}
            errors={errors}
            fields={fields}
            append={append}
            remove={remove}
            update={update}
            allWarehouses={warehouseData?.warehouses || []}
            initialTraceItems={initialTraceItems}
            existingInitialTraceItems={existingInitialTraceItems}
          />
        )}
      </CustomizedBox>
      {!["finished", "cancelled"].includes(
        data?.goodsTransfer?.aggrid_status || ""
      ) && <BottomNavbar>{renderButton()}</BottomNavbar>}
      <Confirmation
        title="ยืนยันหากต้องการนำเข้าสินค้า"
        message="ขณะนี้มีสินค้า “รอสแกน” อยู่หากนำเข้าแล้วจะไม่สามารถแก้ไขเอกสารนี้ได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </form>
  );
};

export default GoodsTransferContainer;
