import * as Yup from "yup";
import { ICompany } from "../../../types/Setting/company";

export const schema: ICompany = {
  unique_id: "",
  name: "",
  type: "บริษัท",
  owner_email: "",
  identity_no: "",
  register_date: null,
  is_registered_vat: false,
  vat_registration_date: null,
  is_active: 1,
  img_url: [],
  contact_channel_list: [],
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      is_default: true,
      is_same_as_default_address: false,
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: null,
      district: null,
      province: null,
      postal_code: "",
      country: null,
    },
  ],
};

const contactChannelListSchema = Yup.object().shape({
  contact_channel_type: Yup.string(),
  contact_channel_name: Yup.string().when("contact_channel_type", {
    is: "Email",
    then: (schema) =>
      schema.email("กรุณากรอกอีเมลให้ถูกต้อง").required("กรุณากรอก"),
  }),
});

export const companyValidation = Yup.object().shape({
  contact_channel_list: Yup.array().of(contactChannelListSchema),
});
