import { Box, IconButton, Typography } from "@mui/material";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import LaunchIcon from "@mui/icons-material/Launch";
import CircleIcon from "@mui/icons-material/Circle";
import { IDefaultForm } from "../../../../types/global";
import ItemQty from "../../../UI/ItemQty";
import { useState } from "react";
import { IDeliveryOrderItemList } from "../../../../types/Logistic/deliveryOrder";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: boolean;
  field: IDeliveryOrderItemList;
  index: number;
  documentType: string;
}

const ItemNameCell = ({
  control,
  field,
  errors,
  index,
  disabled,
  documentType,
}: Props) => {
  const [isSufficient, setIsSufficient] = useState<boolean>(true);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography variant="body2" align="left" mt={1} color="primary">
            {field.item_name}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            control={control}
            error={Boolean(
              errors?.item_list &&
                errors?.item_list[index] &&
                errors?.item_list[index]?.item_name
            )}
            helperText={
              errors?.item_list &&
              errors?.item_list[index] &&
              errors?.item_list[index]?.item_name &&
              errors?.item_list[index]?.item_name?.message
            }
            name={`item_list[${index}].item_name`}
            sx={{ mt: 1 }}
          />
        )}
        <Typography
          variant="overline"
          sx={{
            my: 0,
            ml: !disabled ? 2 : 0,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {field.item_unique_id}
        </Typography>
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {field.item_sku_desc}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            control={control}
            name={`item_list[${index}].item_sku_desc`}
            multiline
            minRows={1}
            maxRows={3}
            sx={{ mb: 1 }}
            error={Boolean(
              errors?.item_list &&
                errors?.item_list[index] &&
                errors?.item_list[index]?.item_sku_desc
            )}
            helperText={
              errors?.item_list &&
              errors?.item_list[index] &&
              errors?.item_list[index]?.item_sku_desc &&
              errors?.item_list[index]?.item_sku_desc?.message
            }
          />
        )}
      </Box>
      {!disabled && (
        <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
          <IconButton
            size="small"
            sx={{ color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() =>
              window.open(
                `/inventory/items/${field.item_unique_id}?tab=item&subtab=general`,
                "_blank"
              )
            }
          >
            <LaunchIcon fontSize="small" />
          </IconButton>
        </CustomizedTooltip>
      )}
      <CustomizedTooltip
        title={
          <ItemQty
            control={control}
            index={index}
            documentType={documentType}
            isSufficient={isSufficient}
            setIsSufficient={setIsSufficient}
          />
        }
      >
        <IconButton
          size="small"
          sx={{ color: isSufficient ? "#8FCBB1" : "#F44336", fontSize: "13px" }}
        >
          <CircleIcon fontSize="inherit" />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

export default ItemNameCell;
