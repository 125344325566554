import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PurchaseOrderTable from "../../../components/Table/Purchase/Order";

const PurchaseOrderTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" mb={3}>
        {t("purchase.order.index")}
      </Typography>
      <PurchaseOrderTable referenceUniqueId={id} />
    </>
  );
};

export default PurchaseOrderTab;
