import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { useRemarkTemplatesQuery } from "../../../../generated/remark";

import { Typography, Grid, Box } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { formatNumber } from "../../../../utils/dataTransformer";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import { useManufactureSummary } from "../../../../hooks/Manufacture/use-summary";

type Props = IDefaultForm;

interface ISummary {
  name?: string;
  label: string;
  amount: number;
  changeValue?: boolean;
}

const ManufactureOrderRemarkForm = ({
  control,
  errors,
  setValue,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data } = useRemarkTemplatesQuery(graphQLClientWithHeaderSetting, {
    whereInput: {
      entity_type: {
        equals: "manufacture_order",
      },
    },
  });

  const options: ISelectOption[] =
    data?.remarkTemplates.map((template) => ({
      id: template.id,
      label: template.name,
      value: template.id,
    })) || [];

  const mapTemplateRemarkIdToRemark = useCallback(
    (id: number | null) => {
      if (id) {
        const findById = data?.remarkTemplates.find(
          (template) => template.id === id
        );
        if (findById) setValue("remark", findById.remark_text);
      } else {
        setValue("remark", "");
      }
    },
    [data?.remarkTemplates, setValue]
  );

  const mapTemplateToValue = useCallback(
    (id: number) => {
      if (id) {
        const findById = data?.remarkTemplates.find(
          (template) => template.id === id
        );
        if (findById) {
          return findById.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    [data?.remarkTemplates]
  );

  const summary = useManufactureSummary(control, setValue);

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "other_manufacture_cost",
        label: `${t("manufacture.order.cost.other_manufacture_cost")}`,
        amount: summary.other_manufacture_cost,
        changeValue: true,
      },
      {
        name: "work_order_cost",
        label: `${t("manufacture.order.cost.work_order_cost")}`,
        amount: summary.work_order_cost,
      },
      {
        name: "bom_cost",
        label: `${t("manufacture.order.cost.bom_cost")}`,
        amount: summary.bom_cost,
      },
      {
        name: "total_cost",
        label: `${t("manufacture.order.cost.total_cost")}`,
        amount: summary.total_cost,
      },
    ],
    [
      summary.bom_cost,
      summary.other_manufacture_cost,
      summary.total_cost,
      summary.work_order_cost,
      t,
    ]
  );

  return (
    <CustomizedBox>
      <Typography fontWeight="bold" mb={2}>
        {t("manufacture.bom.internal_remark")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid item>
            {!disabled && (
              <Controller
                name="template_remark_id"
                control={control}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={t("sentence.remark")}
                    options={options}
                    onChange={(_, value) => {
                      if (value) {
                        mapTemplateRemarkIdToRemark(value.value);
                        field.onChange(parseInt(value.value));
                      } else {
                        mapTemplateRemarkIdToRemark(null);
                        field.onChange(null);
                      }
                    }}
                    value={mapTemplateToValue(field.value)}
                    disabled={disabled}
                    error={Boolean(errors.template_remark_id)}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <ControlledTextField
              name="remark"
              control={control}
              error={Boolean(errors.remark)}
              disabled={disabled}
              sx={{ mt: 1.5 }}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {defaultsSummaryList.map((item, index: number) => (
            <Box key={item.label} sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                  gap: 3,
                }}
              >
                <>
                  <Typography
                    variant="subtitle1"
                    mr={1.5}
                    textAlign={"right"}
                    flex={2}
                  >
                    {item.label}
                  </Typography>
                  {item.changeValue ? (
                    <Box
                      minWidth={150}
                      flex={1}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ControlledNumberTextField
                        control={control}
                        name={item?.name || ""}
                        disabled={disabled}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        minWidth: 150,
                        textAlign: "right",
                        mr: 1.5,
                        flex: 1,
                      }}
                    >
                      {formatNumber(item.amount)}
                    </Typography>
                  )}
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
                    บาท
                  </Typography>
                </>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ManufactureOrderRemarkForm;
