import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import PurchaseReturnTab from "./PurchaseReturnTab";
import GoodsReceiveTab from "../../Purchase/Order/GoodsReceiveTab";

const PurchaseOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/order",
    },
    {
      name: id ?? `${t("button.create")}${t("purchase.order.index")}`,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.goods_receive.index"),
      path: `${pathname}?tab=goods_receive`,
      disabled: !id,
    },
    {
      label: t("purchase.return.index"),
      path: `${pathname}?tab=return`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "goods_receive":
        setCurrentTab(pathname + "?tab=goods_receive");
        break;
      case "return":
        setCurrentTab(pathname + "?tab=return");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "goods_receive":
        return <GoodsReceiveTab />;
      case "return":
        return <PurchaseReturnTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </>
  );
};

export default PurchaseOrderContainer;
