import { Grid } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import Remark from "./Remark";
import { IDefaultForm } from "../../../types/global";
import { UseFormResetField } from "react-hook-form";
import LogisticSummary from "./Summary";

interface Props {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  resetField?: UseFormResetField<any>;
  documentType: string;
}

const LogisticFooter = ({
  control,
  setValue,
  errors,
  disabled,
  documentType,
  resetField,
}: Props) => {
  return (
    <CustomizedBox padding={"2rem 1rem 2rem 2rem"} margin={"2rem 0 4rem 0"}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Remark
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            documentType={documentType}
          />
        </Grid>
        {documentType !== "purchase_request" && (
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <LogisticSummary
              control={control}
              setValue={setValue}
              disabled={disabled}
              documentType={documentType}
              resetField={resetField}
            />
          </Grid>
        )}
      </Grid>
    </CustomizedBox>
  );
};

export default LogisticFooter;
