import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import CustomizedSelect from "../../Custom/CustomizedSelect";
import CustomizedCreatable from "../../Custom/CustomizedCreatable";
// import ModalUI from "../../../UI/ModalUI";
import { IDefaultForm, IOptionsCreatable } from "../../../types/global";
import ModalUI from "../../UI/ModalUI";

type ExtendedProps = IDefaultForm & {
  onOpenModal: any;
  onRemove: any;
  onRestoreSnapshot: any;
  bankOptions: any[];
  index: number;
  title: string;
  accountNum: number;
  accountName: string;
};

const BankAccountCard = ({
  control,
  onOpenModal,
  onRemove,
  index,
  title,
  accountNum,
  accountName,
  errors,
  disabled,
  onRestoreSnapshot,
  bankOptions,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const showActions = Boolean(anchorPos);

  const accountTypes = [
    {
      id: 1,
      label: "บัญชีกระแส",
      value: "บัญชีกระแส",
    },
    { id: 2, label: "บัญชีออมทรัพย์", value: "บัญชีออมทรัพย์" },
  ];

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const startEditingHandler = () => {
    closeActionsHandler();
    setIsEditing(true);
    onOpenModal(index);
  };

  const finishEditingHandler = () => {
    setIsEditing(false);
  };

  const cancelEditHandler = () => {
    onRestoreSnapshot(index);
    setIsEditing(false);
  };

  const deleteCardHandler = () => {
    closeActionsHandler();
    onRemove(index);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        <MenuItem onClick={startEditingHandler}>
          {disabled ? "ดู" : t("contact.utility.edit")}
        </MenuItem>
        {!disabled && (
          <MenuItem onClick={deleteCardHandler}>
            {t("contact.utility.delete")}
          </MenuItem>
        )}
      </Menu>
      <CustomizedBox padding="1.5rem 2rem">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">
              {t("contact.financial.bank_info.bank")}
              {title}
            </Typography>
          </Box>
          <IconButton
            onClick={openActionsHandler}
            disabled={isEditing}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>

        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
            >
              {t("contact.financial.bank_info.account_number")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
            >
              {accountNum || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
            >
              {t("contact.financial.bank_info.account_name")}:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
            >
              {accountName || "-"}
            </Typography>
          </Grid>
        </Grid>
      </CustomizedBox>
      <ModalUI
        open={isEditing}
        handleClose={cancelEditHandler}
        fullWidth
        maxWidth="md"
      >
        <Typography fontWeight={"bold"} sx={{ ml: 1, my: 2 }}>
          {t("contact.financial.bank_info.bank")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.bank_name`}
                control={control}
                render={({ field }) => (
                  <CustomizedSelect
                    fullWidth
                    error={Boolean(
                      errors.finance?.bank_list?.[index]?.bank_name
                    )}
                    helperText={
                      errors.finance?.bank_list?.[index]?.bank_name?.message
                    }
                    label={t("contact.financial.bank_info.bank_name")}
                    options={bankOptions}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.bank_account_name`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.finance?.bank_list?.[index]?.bank_account_name
                    )}
                    helperText={
                      errors.finance?.bank_list?.[index]?.bank_account_name
                        ?.message
                    }
                    label={t("contact.financial.bank_info.account_name")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.bank_account_branch`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.finance?.bank_list?.[index]?.bank_account_branch
                    )}
                    helperText={
                      errors.finance?.bank_list?.[index]?.bank_account_branch
                        ?.message
                    }
                    label={t("contact.financial.bank_info.branch")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.bank_account_type`}
                control={control}
                render={({ field }) => (
                  <CustomizedCreatable
                    {...field}
                    options={accountTypes}
                    title={t("contact.financial.bank_info.type")}
                    onChange={(e: EventListener, option: IOptionsCreatable) => {
                      if (!option) {
                        return field.onChange("");
                      }
                      if (option.value) {
                        return field.onChange(option.value);
                      }
                      if (option.inputValue) {
                        return field.onChange(option.inputValue);
                      }
                      return field.onChange(option);
                    }}
                    readOnly={disabled}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.bank_account_no`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.finance?.bank_list?.[index]?.bank_account_no
                    )}
                    helperText={
                      errors.finance?.bank_list?.[index]?.bank_account_no
                        ?.message
                    }
                    label={t("contact.financial.bank_info.account_number")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Controller
                name={`finance.bank_list.${index}.description`}
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    fullWidth
                    error={Boolean(
                      errors.finance?.bank_list?.[index]?.description
                    )}
                    helperText={
                      errors.finance?.bank_list?.[index]?.description?.message
                    }
                    label={t("contact.financial.bank_info.description")}
                    {...field}
                    disabled={disabled}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        {!disabled && (
          <CustomizedButton
            title={"แก้ไข"}
            variant="contained"
            size="medium"
            onClick={finishEditingHandler}
          />
        )}
      </ModalUI>
    </>
  );
};

export default BankAccountCard;
