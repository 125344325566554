import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
// import CustomizedDashboardNavbar from "../../../components/Custom/CustomizedDashboardNavbar";
import CustomizedButton from "../components/Custom/CustomizedButton";
import CustomizedSelect from "../components/Custom/CustomizedSelect";
import CustomizedCheckboxes from "../components/Custom/CustomizedCheckbox";
import { ISelectOption } from "../types/global";

interface BarcodeItem {
  productBarcode: string;
  productId: string;
  productName: string;
  productDescription: string;
  productSn: string;
  productLot: string;
  productQty: number;
  productUom: string;
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#262B32" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 4,
}));

const typeOptions: ISelectOption[] = [
  {
    label: "A4 แนวตั้ง (5 แถว แถวละ 2 ดวง)",
    value: "template_A4_1",
  },
  {
    label: "Label 10x5ซม. (แนวตั้ง)",
    value: "template_label_10x5_cm_1",
  },
  {
    label: "Label 10x5ซม. (แนวนอน)",
    value: "template_label_10x5_cm_2",
  },
  {
    label: "Label 6x4นิ้ว (แนวตั้ง)",
    value: "template_label_6x4_inch_1",
  },
  {
    label: "Label 6x4นิ้ว (แนวนอน)",
    value: "template_label_6x4_inch_2",
  },
];

const typeMarginOptions: ISelectOption[] = [
  {
    label: "0px",
    value: "0px",
  },
  {
    label: "5px",
    value: "5px",
  },
  {
    label: "10px",
    value: "10px",
  },
  {
    label: "15px",
    value: "15px",
  },
  {
    label: "20px",
    value: "20px",
  },
  {
    label: "25px",
    value: "25px",
  },
  {
    label: "30px",
    value: "30px",
  },
  // {
  //   label: "35px",
  //   value: 35,
  // },
  // {
  //   label: "40px",
  //   value: 40,
  // },
  // {
  //   label: "45px",
  //   value: 45,
  // },
  // {
  //   label: "50px",
  //   value: 50,
  // },
];

const infoStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  color: "black",
  flexDirection: "column",
  overflow: "hidden",
};

const infoItemStyle: React.CSSProperties = {
  marginBottom: "5px",
};

const qrStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginRight: "10px",
  marginLeft: "5px",
};

const pageBreakStyle: React.CSSProperties = {
  pageBreakBefore: "always",
};

interface LabelProps {
  item: BarcodeItem;
}

const Label: React.FC<LabelProps> = React.memo(({ item }) => {
  const qrCodeSize = 90;
  return (
    <div className="print-label-each">
      <div style={qrStyle}>
        <QRCode value={item.productBarcode} size={qrCodeSize} />
      </div>
      <div style={infoStyle}>
        <div style={infoItemStyle}>
          <strong>รหัส: </strong> {item.productId}
        </div>
        <div style={infoItemStyle}>
          <strong>ชื่อ: </strong> {item.productName}
        </div>
        {/* <div style={infoItemStyle}>
          <strong>รายละเอียด:</strong> {item.productDescription}
        </div> */}
        {item.productSn && (
          <div style={infoItemStyle}>
            <strong>SN: </strong> {item.productSn}
          </div>
        )}
        {item.productLot && (
          <div style={infoItemStyle}>
            <strong>Lot: </strong>{" "}
            {new Date(item.productLot).toLocaleDateString("en-GB")}
          </div>
        )}
        <div style={infoItemStyle}>
          <strong>จำนวน: </strong> {item.productQty} {item.productUom}
        </div>
      </div>
    </div>
  );
});

const PrintLabel: React.FC = () => {
  const [barcodeList, setBarcodeList] = useState<BarcodeItem[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<string>("template_A4_1");
  const [selectedTemplateBorder, setSelectedTemplateBorder] =
    useState<boolean>(true);
  const [selectedTemplateBorderRadius, setSelectedTemplateBorderRadius] =
    useState<boolean>(true);
  const [
    selectedTemplateHorizontalCenter,
    setSelectedTemplateHorizontalCenter,
  ] = useState<boolean>(true);
  const [selectedTemplateVerticalMargin, setSelectedTemplateVerticalMargin] =
    useState<string>("0px");

  useEffect(() => {
    try {
      const lsState = localStorage.getItem("barcode-list");
      if (lsState) {
        const parsedList = JSON.parse(lsState) as BarcodeItem[];
        if (Array.isArray(parsedList)) {
          setBarcodeList(parsedList);
        } else {
          console.error("Invalid format in local storage for barcode-list");
          setBarcodeList([]);
        }
      } else {
        setBarcodeList([]);
      }
    } catch (error) {
      console.error("Error reading barcode-list from local storage:", error);
      setBarcodeList([]);
    }
  }, []);

  useEffect(() => {
    try {
      const templateState = localStorage.getItem("barcode-template");
      const templateBorderState = localStorage.getItem(
        "barcode-template-border"
      );
      const templateBorderRadiusState = localStorage.getItem(
        "barcode-template-radius"
      );
      const templateHorizontalCenterState = localStorage.getItem(
        "barcode-template-horizontal-center"
      );
      const templateVerticalMarginState = localStorage.getItem(
        "barcode-template-vertical-margin"
      );

      console.log("templateBorderRadiusState", templateBorderRadiusState);
      setSelectedTemplate(templateState || "template_A4_1");
      setSelectedTemplateBorder(templateBorderState === "true" ? true : true);
      setSelectedTemplateBorderRadius(
        templateBorderRadiusState === "true" ? true : true
      );
      setSelectedTemplateHorizontalCenter(
        templateHorizontalCenterState === "true" ? true : false
      );
      setSelectedTemplateVerticalMargin(
        templateVerticalMarginState ? templateVerticalMarginState : "0px"
      );
    } catch (error) {}
  }, []);

  const getPageRows = (marginTop: string) => {
    let rowsPerPage = 1;
    let labelsPerRow = 1;
    if (selectedTemplate === "template_A4_1") {
      rowsPerPage = 5;
      labelsPerRow = 2;
    }

    const pageRows = [];

    for (let i = 0; i < barcodeList.length; i += labelsPerRow * rowsPerPage) {
      const pageItems = barcodeList.slice(i, i + labelsPerRow * rowsPerPage);

      const rowLabels = pageItems.map((item, index) => (
        <Label key={index} item={item} />
      ));

      pageRows.push(
        <>
          <div
            key={i}
            style={{
              ...pageBreakStyle,
              marginTop: marginTop || "0px",
            }}
            className="print-label-page-break"
          >
            <div className="row">{rowLabels}</div>
          </div>
          {i + 1 < barcodeList.length && (
            <div className="print-label-page-break-render">
              - - - - - - - - - - - - - - - - -
            </div>
          )}
        </>
      );
    }

    return pageRows;
  };

  const handlePrint = () => {
    window.print();
  };

  if (barcodeList.length === 0) {
    return <div>ไม่พบรายการ Label</div>;
  }

  return (
    <Box>
      <div className="print-label-navigator">
        <HideOnScroll>
          <AppBar
            sx={{ backgroundColor: "#fff", borderBottom: "2px solid #32418F" }}
          >
            <Toolbar>
              <Container>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={1}>
                    <Item>
                      <Typography
                        variant="body1"
                        sx={{
                          marginTop: 1.1,
                        }}
                      >
                        แสดง {barcodeList.length} รายการ
                      </Typography>
                    </Item>
                    <Item>
                      <CustomizedSelect
                        label={"รูปแบบ"}
                        defaultValue={selectedTemplate}
                        options={typeOptions}
                        sx={{ width: 260, textAlign: "left" }}
                        onChange={(e: any) => {
                          localStorage.setItem(
                            "barcode-template",
                            e.target.value
                          );
                          setSelectedTemplate(e.target.value);
                        }}
                      />
                    </Item>
                    <Item>
                      <CustomizedSelect
                        label={"ระยะบน"}
                        defaultValue={selectedTemplateVerticalMargin}
                        options={typeMarginOptions}
                        sx={{ width: 90, textAlign: "left" }}
                        onChange={(e: any) => {
                          localStorage.setItem(
                            "barcode-template-vertical-margin",
                            e.target.value
                          );
                          setSelectedTemplateVerticalMargin(e.target.value);
                        }}
                      />
                    </Item>
                    <Item>
                      <CustomizedCheckboxes
                        label="มีขอบ"
                        value={selectedTemplateBorder}
                        onChange={(e: any) => {
                          localStorage.setItem(
                            "barcode-template-border",
                            e.target.checked
                          );
                          setSelectedTemplateBorder(e.target.checked || false);
                        }}
                      />
                      <CustomizedCheckboxes
                        label="ขอบมล"
                        value={selectedTemplateBorderRadius}
                        onChange={(e: any) => {
                          localStorage.setItem(
                            "barcode-template-radius",
                            e.target.checked
                          );
                          setSelectedTemplateBorderRadius(
                            e.target.checked || false
                          );
                        }}
                      />
                      <CustomizedCheckboxes
                        label="กลางกระดาษ"
                        value={selectedTemplateHorizontalCenter}
                        onChange={(e: any) => {
                          localStorage.setItem(
                            "barcode-template-horizontal-center",
                            e.target.checked
                          );
                          setSelectedTemplateHorizontalCenter(
                            e.target.checked || false
                          );
                        }}
                      />
                      <CustomizedButton
                        variant="contained"
                        title="พิมพ์ Label"
                        onClick={handlePrint}
                      />
                    </Item>
                  </Stack>
                </Box>
              </Container>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Toolbar />
      </div>

      <div className="print-label-wrapper">
        <div
          className={`${
            selectedTemplate === "template_A4_1" ? "template_A4_1" : ""
          }${
            selectedTemplate === "template_label_10x5_cm_1"
              ? "template_label_10x5_cm_1"
              : " "
          } ${
            selectedTemplate === "template_label_10x5_cm_2"
              ? "template_label_10x5_cm_2"
              : " "
          } ${
            selectedTemplate === "template_label_6x4_inch_1"
              ? "template_label_6x4_inch_1"
              : " "
          }
        ${
          selectedTemplate === "template_label_6x4_inch_2"
            ? "template_label_6x4_inch_2"
            : " "
        }
        ${selectedTemplateBorder ? "print-label-border" : " "}
        ${selectedTemplateBorderRadius ? "print-label-border-radius" : " "} 
        ${
          selectedTemplateHorizontalCenter
            ? "print-label-horizontal-center"
            : " "
        } 
        print-label-default-margin`}
        >
          {getPageRows(selectedTemplateVerticalMargin)}
        </div>
      </div>
    </Box>
  );
};

export default PrintLabel;
