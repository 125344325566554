import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useTranslation } from "react-i18next";
import ProjectHeader from "../../../components/Form/Project/Header";
import ProjectInfoForm from "../../../components/Form/Project/ProjectInfoForm";
import GeneralInfoForm from "../../../components/Form/Project/GeneralInfoForm";
import ProjectImg from "../../../components/Form/Project/ProjectImg";
import {
  ProjectValidation,
  projectSchema,
} from "../../../components/Form/Project/schema";
import ProjectAttachment from "../../../components/Form/Project/ProjectAttachment";
import { yupResolver } from "@hookform/resolvers/yup";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ProjectQuery,
  useProjectCreateMutation,
  useProjectDeleteMutation,
  useProjectQuery,
  useProjectUpdateMutation,
} from "../../../generated/project";
import { useSnackbar } from "notistack";
import {
  errorMessageFormatter,
  errorMessageOnDeleteFormatter,
} from "../../../utils/Formatter/Global";
import { IProject } from "../../../types/Project";
import {
  projectCreatePayloadFormatter,
  projectUpdatePayloadFormatter,
} from "../../../utils/Formatter/Project";
import CancelRemark from "../../../components/Form/Project/CancelRemark";
import { GraphQLClient } from "graphql-request";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";

const DocumentInfoTab = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<IProject>({
    defaultValues: projectSchema,
    resolver: yupResolver(ProjectValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { data, isLoading, isSuccess, refetch } = useProjectQuery<ProjectQuery>(
    graphQLClientWithHeaderProject,
    {
      where: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const { isLoading: isCreating, mutate: createProject } =
    useProjectCreateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: ({ createProject }) => {
        if (createProject) {
          navigate(`/project/projects/${createProject?.unique_id}`);
          enqueueSnackbar("สร้างโครงการสำเร็จ", {
            variant: "success",
          });
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "project");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างโครงการไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { isLoading: isUpdating, mutate: updateProject } =
    useProjectUpdateMutation<Error>(graphQLClientWithHeaderProject, {
      onSuccess: ({ updateProject }) => {
        enqueueSnackbar("แก้ไขโครงการสำเร็จ", {
          variant: "success",
        });
        console.log(updateProject);
        if (id) {
          refetch();
        }
      },
      onError: (err) => {
        const duplicatedUniqueId = errorMessageFormatter(err, "project");
        if (duplicatedUniqueId) {
          enqueueSnackbar(duplicatedUniqueId, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("แก้ไขโครงการไม่สำเร็จ", {
            variant: "error",
          });
        }
      },
    });

  const { mutate: deleteProject } = useProjectDeleteMutation<Error>(
    graphQLClientWithHeaderProject,
    {
      onSuccess: () => {
        enqueueSnackbar("ลบโครงการสำเร็จ", {
          variant: "success",
        });
        navigate("/project/projects");
      },
      onError: (err) => {
        const errorMessage = errorMessageOnDeleteFormatter(err, "project");
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccess) {
      const { project } = data;
      console.log("query project ", project);

      const getProjectData = async () => {
        const typedProject = project as IProject;

        reset(typedProject);
        setValue("customer_contact.name", project.customer_name ?? "");
        setValue("customer_contact.phone", project.phone ?? "");
      };
      getProjectData();
    }
  }, [data, isSuccess, reset, setValue]);

  const createProjectHandler = async (data: IProject) => {
    console.log(data);
    const projectCreatePayload = await projectCreatePayloadFormatter(data);
    console.log(projectCreatePayload);

    createProject({ data: projectCreatePayload });
  };

  const updateProjectHandler = async (data: IProject) => {
    const projectUpdatePayload = await projectUpdatePayloadFormatter(data);
    setDisabled(true);
    updateProject({
      data: projectUpdatePayload,
      where: { unique_id: data.unique_id },
    });
  };

  const cancelProjectHandler = async (data: IProject) => {
    const projectCancelPayload = await projectUpdatePayloadFormatter(
      data,
      "cancelled"
    );
    setDisabled(true);
    console.log("cancel payload", projectCancelPayload);

    updateProject({
      data: projectCancelPayload,
      where: { unique_id: data.unique_id },
    });
  };

  const deleteProjectHandler = () => {
    const unique_id = getValues("unique_id");
    console.log(unique_id);
    deleteProject({
      where: { unique_id: unique_id },
      //wait for plugging in checking-is-project-in-use apis
      shouldSaleError: false,
      shouldDeliveryError: false,
      shouldManufactureError: false,
      shouldPurchaseError: false,
    });
  };

  const cancelEditHandler = () => {
    setDisabled(true);
    reset();
  };

  const cancelCreateHandler = () => {
    reset(projectSchema);
  };

  if (id && (isLoading || isUpdating)) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <ProjectHeader
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        editClickHandler={() => {
          setDisabled(false);
        }}
        projectCancelHandler={handleSubmit(cancelProjectHandler)}
        projectDeletehandler={deleteProjectHandler}
        project={data?.project as IProject}
        refetchProject={refetch}
      />
      <ProjectInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        reset={reset}
      />
      <GeneralInfoForm
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
      />
      <ProjectImg
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
      />
      <ProjectAttachment
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
      />
      {id && data?.project.aggrid_status === "cancelled" && (
        <CancelRemark control={control} />
      )}
      {!disabled && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={() => {
              id ? cancelEditHandler() : cancelCreateHandler();
            }}
          />
          <CustomizedButton
            title={t("button.save")}
            variant="contained"
            onClick={
              id
                ? handleSubmit(updateProjectHandler)
                : handleSubmit(createProjectHandler)
            }
            disabled={isCreating || isUpdating}
          />
        </Box>
      )}
    </form>
  );
};

export default DocumentInfoTab;
