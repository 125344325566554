import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDefaultForm } from "../../../types/global";
import ControlledTextField from "../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import CheckedContactModal from "../../Table/CheckedContactModal";
import TagList from "../../UI/TagList/ProjectTagList";
import { useContactDescription } from "../../../hooks/use-contact-description";
import ModalUI from "../../UI/ModalUI";
import ContactContainer from "../../../pages/Contact/Container";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  ProjectUniqueIdQuery,
  ReferenceDocumentType,
  useProjectUniqueIdQuery,
} from "../../../generated/project";
import { EntityTypeEnum } from "../../../generated/creatable";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const ProjectInfoForm = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
  reset,
}: IDefaultForm) => {
  const { id } = useParams();

  const { t } = useTranslation();

  const {
    contactUniqueId,
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openContactTableHandler,
    finishContactsSelect,
    openContactDetail,
    openContactDetailHandler,
    closeContactDetailHandler,
  } = useContactDescription(control, reset, "sales", EntityTypeEnum.Quotation);

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { refetch: getUniqueId } =
    useProjectUniqueIdQuery<ProjectUniqueIdQuery>(
      graphQLClientWithHeaderProject,
      {
        documentType: ReferenceDocumentType.Project,
      },
      {
        enabled: false,
        onSuccess: ({ getUniqueId }) => {
          setValue("unique_id", getUniqueId);
        },
      }
    );

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            {t("project.project_detail")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item container xs={12} sm={12} md={6} lg={4} xl={4}>
          <Grid item xs>
            <ControlledTextField
              control={control}
              name="unique_id"
              error={Boolean(errors.unique_id)}
              helperText={errors.unique_id?.message}
              label={t("project.project.unique_id")}
              required
              disabled={!!id}
            />
          </Grid>
          {!id && (
            <Grid item xs={1}>
              <CustomizedTooltip title="เรียกรหัสโครงการใหม่">
                <IconButton
                  id="item-btn-reload-unique-id"
                  data-test-id="item-btn-reload-unique-id"
                  onClick={generateUniqueId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            required
            control={control}
            name="name"
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            label={t("project.project.name")}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("project.project.description")}
            disabled={disabled}
          />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography fontWeight="bold">
              {t("project.customer_detail")}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item xs={2}>
              <Box>
                <CustomizedTooltip title={t("sentence.choose_customer")}>
                  <IconButton
                    onClick={openContactTableHandler}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact_unique_id"
            error={Boolean(errors.customer_contact_unique_id)}
            helperText={errors.customer_contact_unique_id?.message}
            label="รหัสลูกค้า"
            disabled
            InputProps={{
              // startAdornment: disabled && (
              //   <InputAdornment position="start">
              //     <StyledLink onClick={openContactDetailHandler}>
              //       {contact?.name}
              //     </StyledLink>
              //   </InputAdornment>
              // ),
              endAdornment: contactUniqueId && (
                <InputAdornment position="end">
                  <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                    <IconButton
                      onClick={openContactDetailHandler}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <LaunchOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.name"
            label="ชื่อลูกค้า"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="customer_contact.phone"
            label="เบอร์โทรศัพท์"
            disabled={disabled}
          />
        </Grid>
        <Grid container item alignItems="center">
          <Grid item xs={12}>
            <Typography fontWeight="bold" mb={2}>
              {t("inventory.items.group_tag_list")}
            </Typography>
          </Grid>
          <TagList
            control={control}
            disabled={disabled}
            setValue={setValue}
            getValues={getValues}
            name="tag_list"
            entity={"project"}
          />
        </Grid>
      </Grid>

      <CheckedContactModal
        showSelectContact={openContactTable}
        closeContactTable={() => {
          closeContactTableHandler();
          if (contactIds.length === 0) {
            setValue("customer_contact_unique_id", "");
          }
        }}
        contactIds={contactIds}
        setContactIds={setContactIds}
        contactIdsSnapshot={contactIdsSnapshot}
        setContactIdsSnapshot={setContactIdsSnapshot}
        finishContactsSelect={finishContactsSelect}
        type="customer"
      />

      <ModalUI
        open={openContactDetail}
        handleClose={closeContactDetailHandler}
        maxWidth="lg"
      >
        <ContactContainer
          isContactPage={false}
          contactUniqueId={contactUniqueId}
        />
      </ModalUI>
    </CustomizedBox>
  );
};

export default ProjectInfoForm;
