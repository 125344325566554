import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IDefaultForm } from "../../../../types/global";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";

type Props = {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
};

const BomRemarkForm = ({ control, errors, disabled }: Props) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("manufacture.bom.internal_remark")}
      </Typography>
      <ControlledTextField
        name="internal_remark"
        control={control}
        error={Boolean(errors?.internal_remark)}
        helperText={errors?.internal_remark && errors?.internal_remark.message}
        disabled={disabled}
        rows={4}
        multiline
      />
    </CustomizedBox>
  );
};

export default BomRemarkForm;
