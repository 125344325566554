import ItemDetail from "./Item";
import RoutingInformation from "./Information";

import { CustomizedBox } from "../../../../Custom/CustomizedBox";

const RoutingDetail = () => {
  return (
    <CustomizedBox>
      <RoutingInformation />
      <ItemDetail />
    </CustomizedBox>
  );
};

export default RoutingDetail;
