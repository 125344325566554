import { TFunction } from "i18next";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { itemTypeEngToThai } from "../../../../utils/dataTransformer";
import {
  InventoryTagsFindByEntityQuery,
  ItemType,
} from "../../../../generated/inventory";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { Box, Typography } from "@mui/material";
import { ISkuDetail, ISkuQty } from "../../../../types/Inventory/item";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

interface Props {
  t: TFunction;
  isReport?: boolean;
  enableCheckbox?: boolean;
  allTag: InventoryTagsFindByEntityQuery["itemTagsFindByEntity"];
}

const mapQtyEngToThai = (eng: string) => {
  switch (eng) {
    case "stock_qty":
      return "คงคลัง";
    case "available_qty":
      return "คงเหลือสุทธิ";
    case "purchase_ordered_qty":
      return "กำลังจัดซื้อ";
    case "manufacture_ordered_qty":
      return "กำลังผลิต";
    case "sale_committed_qty":
      return "ถูกจอง (ขาย)";
    case "manufacture_committed_qty":
      return "ถูกจอง (ผลิต)";
    default:
      return "คงคลัง";
  }
};

export const itemColumnDefs = (
  t: Props["t"],
  isReport: Props["isReport"],
  enableCheckbox: Props["enableCheckbox"],
  allTag: Props["allTag"]
): ColDef[] => {
  return [
    {
      field: "sku_name",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
      checkboxSelection: enableCheckbox,
    },
    {
      field: "img_url",
      headerName: t("inventory.img_url"),
      filter: false,
      floatingFilter: false,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.img_url;
      },
      cellRenderer: (params: ICellRendererParams<ISkuDetail, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] ?? "",
                  unique_id: params?.data?.item_sku?.item?.unique_id ?? "",
                  first_name: params?.data?.item_sku?.item?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
        } else
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.item_sku?.item?.unique_id ?? "",
                  first_name: params?.data?.item_sku?.item?.name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.name;
      },
    },
    {
      field: "desc",
      headerName: t("inventory.items.desc"),
      filter: "agTextColumnFilter",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.item_sku.item.desc;
      },
    },
    {
      field: "type",
      headerName: t("inventory.items.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        readOnly: enableCheckbox,
        values: [
          ItemType.Normal,
          ItemType.Variant,
          ItemType.Bundle,
          ItemType.Service,
        ],
        valueFormatter: (params: ValueFormatterParams) => {
          switch (params.value) {
            case ItemType.Normal:
              return "สินค้าธรรมดา";
            case ItemType.Variant:
              return "สินค้ามีตัวเลือก";
            case ItemType.Bundle:
              return "สินค้าจัดเซ็ต";
            case ItemType.Service:
              return "บริการ";
            default:
              break;
          }
        },
      },
      valueFormatter: (params: ValueFormatterParams) =>
        itemTypeEngToThai(params.data.item_sku.item.type),
    },
    {
      field: "barcode",
      headerName: t("inventory.barcode"),
      filter: "agTextColumnFilter",
    },
    {
      field: "stock_qty",
      headerName: t("inventory.quantities.stock_qty"),
      // filter: "agNumberColumnFilter",
      filter: false,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => {
        const sumWarehouse: {
          warehouse_name: string;
          warehouse_unique_id: string;
          value: number;
        }[] = [];
        const skuQtyType = params.data.item_sku_qty as ISkuQty[];
        const sumOfStockQty = params.data.item_sku_qty.reduce(
          (prev: number, curr: ISkuDetail) => prev + (curr.stock_qty ?? 0),
          0
        );
        skuQtyType.forEach((skuQty) => {
          const warehouseUniqueId =
            skuQty?.warehouse_level_3?.sub_level_2?.sub_level_1
              ?.warehouse_unique_id ?? "";
          const warehouseName =
            skuQty?.warehouse_level_3?.sub_level_2?.sub_level_1?.warehouse
              ?.name ?? "";
          const findWarehouse = sumWarehouse.findIndex(
            (res) => res.warehouse_unique_id === warehouseUniqueId
          );
          if (findWarehouse !== -1) {
            sumWarehouse[findWarehouse].value += skuQty.stock_qty;
          } else {
            sumWarehouse.push({
              warehouse_name: warehouseName,
              warehouse_unique_id: warehouseUniqueId,
              value: skuQty.stock_qty,
            });
          }
        });
        return sumOfStockQty === 0 ? (
          <Typography color={"#000E58"} fontSize={14}>
            {sumOfStockQty}
          </Typography>
        ) : (
          <CustomizedTooltip
            borderRadius={5}
            // backgroundColor="#FFFFFF"
            placement="right"
            title={
              <Box width={200}>
                {sumWarehouse &&
                  sumWarehouse.map((v, index) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      key={index}
                    >
                      <Typography>{v.warehouse_name}</Typography>
                      <Box display="flex" gap={2}>
                        <Typography>{v.value}</Typography>
                        <Typography>
                          {params.data.item_sku?.item?.stock_uom?.name}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            }
          >
            <Typography color={"#000E58"} fontSize={14}>
              {sumOfStockQty}
            </Typography>
          </CustomizedTooltip>
        );
      },
      cellStyle: {
        display: "flex",
      },
    },
    {
      field: "available_qty",
      headerName: t("inventory.quantities.available_qty"),
      // filter: "agNumberColumnFilter",
      filter: false,
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => {
        let sum: {
          purchase_ordered_qty: number;
          manufacture_ordered_qty: number;
          sale_committed_qty: number;
          manufacture_committed_qty: number;
          stock_qty: number;
          available_qty: number;
        } = {
          purchase_ordered_qty: 0,
          manufacture_ordered_qty: 0,
          sale_committed_qty: 0,
          manufacture_committed_qty: 0,
          stock_qty: 0,
          available_qty: 0,
        };
        const skuQtyType = params.data.item_sku_qty as ISkuQty[];

        skuQtyType.forEach((skuQty) => {
          for (let name of Object.keys(sum)) {
            sum[name as keyof typeof sum] += skuQty[name as keyof typeof sum];
          }
        });
        const sumOfAvailableQty = params.data.item_sku_qty.reduce(
          (prev: number, curr: ISkuDetail) => prev + (curr.available_qty ?? 0),
          0
        );

        return (
          <CustomizedTooltip
            borderRadius={5}
            // backgroundColor="#FFFFFF"
            placement="right"
            title={
              <Box width={200}>
                {Object.entries(sum).map(([key, value], index) => (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    key={index}
                  >
                    <Typography>{mapQtyEngToThai(key)}</Typography>
                    <Box display="flex" gap={1}>
                      <Typography>{value}</Typography>
                      <Typography>
                        {params.data.item_sku?.item?.stock_uom?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            }
          >
            <Typography color={"#000E58"} fontSize={14}>
              {sumOfAvailableQty}
            </Typography>
          </CustomizedTooltip>
        );
      },
      cellStyle: {
        display: "flex",
      },
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params: any) => {
          // fetch values from server
          const values = allTag;
          const formatValues = values
            .filter((tag) => tag.entity === "item")
            .map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data.item_sku?.item)
          return params.data.item_sku?.item?.tag_list?.map(
            (tag: { name: string }, index: number) => (
              <Box key={index} sx={{ display: "inline-block", mr: 1, gap: 1 }}>
                <CustomizedChips key={tag.name} value={`${tag.name}`} />
              </Box>
            )
          );
      },
    },
    {
      field: "item_level_1",
      headerName: t("inventory.items.item_group"),
      filter: "agTextColumnFilter",
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.item_sku.item?.item_level_1?.name || "",
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
        readOnly: enableCheckbox,
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];
};
