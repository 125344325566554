import {
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useStateContext } from "../../contexts/auth-context";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useNavigate } from "react-router-dom";
import { ICompany } from "../../types/Setting/company";
import dayjs from "dayjs";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

type Props = {
  filteredCompanyList: ICompany[];
};

const CompanyList = ({ filteredCompanyList }: Props) => {
  const navigate = useNavigate();
  const {
    state: { authUser },
  } = useStateContext();

  const onCompanySelectHandler = (company: ICompany) => {
    cookies.set("selected_tenant", "true", { path: "/" });
    sessionStorage.setItem("tenant-id", company.unique_id);
    const is_expire = dayjs(company.expired_date).isBefore(dayjs());
    sessionStorage.setItem(
      "company-info",
      JSON.stringify({
        is_expired: is_expire,
        expired_date: company.expired_date,
        subscription_level_id: company.subscription_level_id,
        resubscription_date: company.resubscription_date,
        register_date: company.register_date,
      })
    );
    //need to change register_date to expired_date after fix
    if (is_expire) {
      navigate("/setting/package");
    } else navigate("/landing");
  };

  if (!authUser) {
    return <CircularProgress sx={{ mt: 1, mb: 4 }} />;
  }

  return (
    <>
      <CustomizedScrollbar sx={{ my: 3, height: 375, px: 1.5, width: 324 }}>
        <Stack spacing={2}>
          {filteredCompanyList.map((company) => (
            <Card
              key={company.unique_id}
              variant="outlined"
              sx={{
                borderColor: "#8B94CA",
                width: 300,
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => onCompanySelectHandler(company)}
            >
              <CardContent
                sx={{
                  "&:last-child": {
                    p: 1,
                  },
                  p: 1,
                }}
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Box
                    component="span"
                    sx={{
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    bgcolor={(theme) => theme.palette.primary.light}
                  >
                    <DomainOutlinedIcon color="primary" />
                  </Box>
                  <Box>
                    <Typography fontWeight={600}>{company.name}</Typography>
                    <Typography>รหัส: {company.unique_id}</Typography>
                    <Typography>
                      สถานะ: {company.is_active ? "ใช้งาน" : "หมดอายุการใช้งาน"}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </CustomizedScrollbar>
      <Box mb={2}>
        <Card
          variant="outlined"
          sx={{
            borderColor: "#8B94CA",
            width: 300,
            borderRadius: "10px",
            justifySelf: "center",
          }}
        >
          <CardContent
            sx={{
              "&:last-child": {
                p: 1,
              },
              p: 1,
            }}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <Box
                component="span"
                sx={{
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                bgcolor={(theme) => theme.palette.primary.light}
              >
                <ControlPointOutlinedIcon color="primary" />
              </Box>
              <Box>
                <Typography fontWeight={600}>สร้างกิจการใหม่</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default CompanyList;
