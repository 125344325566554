import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";

import AgGrid from "../../../UI/AgGrid";
import { ROUTING_AGGRID } from "../../../../services/AgGrid/ManufactureAgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useRoutingColumnDef } from "./columnDefs";

interface Props {
  referenceUniqueId?: string;
}

const RoutingTable = ({ referenceUniqueId }: Props) => {
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact<any>>(null);
  const columnDef: ColDef[] = useRoutingColumnDef(false);
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { is_main_routing } = filterModel;

      const formatFilter = {
        ...filterModel,
        is_main_routing:
          is_main_routing &&
          is_main_routing.values.length > 0 &&
          typeof is_main_routing.values[0] !== "undefined"
            ? {
                filterType: "boolean",
                type: "equals",
                filter:
                  is_main_routing.values?.length === 0
                    ? undefined
                    : is_main_routing.values[0] === "true"
                    ? true
                    : false,
              }
            : undefined,
      };

      try {
        const { routingsFindManyAggrid } =
          await graphQLClientWithHeaderManufacture.request(ROUTING_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: routingsFindManyAggrid.data as any[],
          rowCount: routingsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceItemUniqueId =
        params.api.getFilterInstance("item_unique_id");

      if (isFilter)
        instanceItemUniqueId?.setModel({
          filterType: "text",
          type: "equels",
          filter: isFilter,
        });
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/routing/${encodeURIComponent(params.data.id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/manufacture/routing"}
    />
  );
};

export default RoutingTable;
