import { useState } from "react";

export const useSessionStorage = (keyName: string, defaultValue?: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = sessionStorage.getItem(keyName);

      if (value) {
        return value;
      } else {
        sessionStorage.setItem(keyName, defaultValue as string);
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: string) => {
    try {
      sessionStorage.setItem(keyName, defaultValue as string);
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
