import dayjs from "dayjs";
import { IRouting } from "../../../../types/Manufacture/routing";
import * as Yup from "yup";

export const routingSchema: IRouting = {
  id: 0,
  name: "",
  description: "",
  is_active: 0,
  created_date: dayjs(),
  start_date: dayjs(),
  end_date: dayjs(),
  item_unique_id: "",
  item_name: "",
  stock_uom: "",
  mfg_type: "",
  work_order_id_list: [],
  work_order_list: [],
  created_by: {},
  internal_remark: "",
  attachment_list: [],
  is_main_routing: false,
};

export const routingValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุชื่อ"),
});
