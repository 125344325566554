import CustomizedStatus from "../../Custom/CustomizedStatus";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { IProject } from "../../../types/Project";
import { IAvatar } from "../../../types/global";
import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import { formatNumber } from "../../../utils/dataTransformer";
import { formatDate } from "../../../utils/Formatter/Date";

interface Props {
  t: TFunction;
}

export const projectColumnDefs = (t: Props["t"]): ColDef[] => {
  return [
    {
      field: "aggrid_status",
      headerName: t("project.status.index"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams<IProject, string>) => (
        <CustomizedStatus
          status={
            params.value === "cancelled"
              ? params.value
              : params.node.data?.main_status
          }
          text={params.value}
        />
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      field: "name",
      headerName: t("project.project.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "customer_name",
      headerName: t("project.project.customer_name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "related_user_list",
      headerName: t("project.project.related_user_list"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams<any, IAvatar[]>) => (
        <CustomizedAvatar avatars={params.value} />
      ),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "expected_start_date",
      headerName: t("project.project.expected_start_date"),
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "start_date",
      headerName: t("project.project.start_date"),
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "opportunity_close_sale",
      headerName: t("project.project.opportunity_close_sale"),
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => `${params.value} %`,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      field: "project_value",
      headerName: t("project.project.project_value"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams<any, string>) =>
        params.value ? formatNumber(parseFloat(params.value)) : "-",
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];
};
