import dayjs from "dayjs";
import * as Yup from "yup";

export const taskSchema = {
  name: "",
  desc: "",
  related_user_list: [],
  remark: "",
  created_date: dayjs().toDate(),
  updated_date: dayjs().toDate(),
  due_date: dayjs().toDate(),
  created_by: null,
  attachment_list: [],
  status: 0,
  type: "",
};

export const taskValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุชื่องาน"),
});
