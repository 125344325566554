import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { IBreadcrumbsAndMenu } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import RoutingTable from "../../../components/Table/Manufacture/Routing";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import ButtonLayout from "../../../components/UI/ButtonLayout";
import CustomizedButton from "../../../components/Custom/CustomizedButton";

const Routing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.routing.index"),
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.routing.index")}</Typography>
        <ButtonLayout>
          <CustomizedButton
            title={`${t("button.create")}${t("manufacture.routing.index")}`}
            variant="contained"
            onClick={() => navigate(`/manufacture/routing/add`)}
          />
        </ButtonLayout>
      </HeaderLayout>
      <RoutingTable />
    </Fragment>
  );
};

export default Routing;
