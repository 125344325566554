import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { formatDate } from "../../../utils/Formatter/Date";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

type Props = {
  expired: boolean;
  expired_date: Date | null;
};

const InformationPackage = ({ expired, expired_date }: Props) => {
  const { t } = useTranslation();
  const remaining_day = dayjs(expired_date).diff(dayjs(), "day");

  return (
    <CustomizedBox display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifySelf="center" gap={2}>
        <Typography fontWeight="bold">
          {t("setting.package.index")} :
        </Typography>
        <Typography fontWeight="bold">ทดลองใช้งาน</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography fontWeight="bold"> {t("status.index")} :</Typography>
        <CustomizedStatus status={expired ? "expired" : "active"} />
      </Box>
      <Box display="flex" gap={2}>
        <Typography fontWeight="bold">
          {t("setting.package.sentence.expired_date")} :
        </Typography>
        <Typography>
          {formatDate(expired_date)} (
          {t("setting.package.sentence.date_remaining")}{" "}
          {remaining_day > 0 ? remaining_day : "0"} วัน)
        </Typography>
      </Box>
    </CustomizedBox>
  );
};

export default InformationPackage;
