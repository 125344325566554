import { useMemo } from "react";
import { IComboBoxOption } from "../../../types/global";
import { IContact } from "../../../types/Contact";

export const useEventContactListOption = (allContacts: IContact[]) => {
  const defaultOptions: IComboBoxOption[] = useMemo(() => {
    const options = allContacts?.map((contact: IContact) => {
      const formattedOption = {
        id: contact.unique_id,
        label:
          `${contact.main_contact_name} ${contact.secondary_contact_name}`.trim(),
        value:
          `${contact.main_contact_name} ${contact.secondary_contact_name}`.trim(),
      };
      return formattedOption;
    });

    return options;
  }, [allContacts]);

  return {
    contactListOptions: defaultOptions,
  };
};
