import { Box } from "@mui/material";
import InventorySection from "./documents/InventorySection";
import SalesSection from "./documents/SalesSection";
import PurchaseSection from "./documents/PurchaseSection";
import ManufactureSection from "./documents/ManufactureSection";

const DashboardSectionContainer = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={3} mt={2}>
      <InventorySection />
      <SalesSection />
      <PurchaseSection />
      <ManufactureSection />
    </Box>
  );
};

export default DashboardSectionContainer;
