import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
import { useTranslation } from "react-i18next";
import CustomizedRadioGroup from "../../../Custom/CustomizedRadioGroup";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import { IBreadcrumbsAndMenu, IRadio } from "../../../../types/global";
import { yupResolver } from "@hookform/resolvers/yup";
import { vehicleSchema, vehicleValidation } from "./schema";
import { useParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { EntityTypeEnum } from "../../../../generated/creatable";

const radioList: IRadio[] = [
  { value: "active", label: "ใช้งาน" },
  { value: "inactive", label: "หยุดใช้งาน" },
];

const vehicleType = [
  { value: "รถเล็ก", label: "รถเล็ก" },
  { value: "รถใหญ่", label: "รถใหญ่" },
  { value: "ลูกพ่วง", label: "ลูกพ่วง" },
];

const VehicleForm = () => {
  const { id } = useParams();
  const [disabled, setDisabled] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    // reset,
  } = useForm({
    defaultValues: vehicleSchema,
    resolver: yupResolver(vehicleValidation),
  });
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.logistic.index"),
      to: "/setting/logistic",
    },
    {
      name: t("setting.logistic.vehicle"),
      to: "/setting/logistic/vehicle",
    },
    { name: id ?? t("setting.logistic.add_vehicle") },
  ];

  useEffect(() => {
    if (id) {
      setDisabled(true);
    }
  }, [id]);

  const onVehicleSubmit = () => {};

  return (
    <form onSubmit={handleSubmit(onVehicleSubmit)}>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {t("setting.logistic.add_vehicle")}
      </Typography>
      <CustomizedBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Typography fontWeight="bold" marginBottom={2} marginLeft={1}>
              {t("setting.logistic.vehicle_info")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="license_plate"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.license_plate)}
                      helperText={errors.license_plate?.message}
                      label={t("setting.logistic.license_plate")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ControlledServerSideCreatable
                  title={t("setting.logistic.vehicle_type")}
                  control={control}
                  name="vehicle_type"
                  documentType={EntityTypeEnum.Vehicle}
                  defaultOptions={vehicleType}
                  error={Boolean(errors.vehicle_type)}
                  helperText={errors.vehicle_type?.message}
                  setValue={setValue}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("setting.logistic.vehicle_size")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="volume"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      type="number"
                      error={Boolean(errors.volume)}
                      helperText={errors.volume?.message}
                      label={t("setting.logistic.volume")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Controller
                  name="weight"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      type="number"
                      error={Boolean(errors.weight)}
                      helperText={errors.weight?.message}
                      label={t("setting.logistic.weight")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("status.index")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <CustomizedRadioGroup
                      {...field}
                      radioList={radioList}
                      row
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="remark_status"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.remark)}
                      label={t("user.account.remark")}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography fontWeight="bold" sx={{ my: 2, ml: 1 }}>
              {t("sentence.remark")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="remark"
                  control={control}
                  render={({ field }) => (
                    <CustomizedTextField
                      fullWidth
                      error={Boolean(errors.remark)}
                      helperText={errors.remark?.message}
                      multiline
                      minRows={3}
                      {...field}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1.5} xl={1.5} />
        </Grid>
      </CustomizedBox>
      <CustomizedButton
        type="submit"
        title={t("button.save")}
        variant="contained"
      />
    </form>
  );
};

export default VehicleForm;
