import styled from "@emotion/styled";

import { Fragment, useEffect, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";

import ModalUI from "../../../../UI/ModalUI";
import CheckboxModalTable from "../../../../Table/CheckboxModalTable";
import { contactColumnDefs } from "../../../../Table/Contacts/columnDefs";

import { IContact } from "../../../../../types/Contact";
import { IDefaultForm } from "../../../../../types/global";

import {
  ContactEntityType,
  ContactQuery,
  ContactTagsFindByEntityQuery,
  useContactQuery,
  useContactTagsFindByEntityQuery,
} from "../../../../../generated/contact";
import { CONTACTS_AGGRID } from "../../../../../services/AgGrid/ContactAgGrid";
import ContactContainer from "../../../../../pages/Contact/Container";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { formatContactName } from "../../../../../utils/Formatter/Global";

type Props = IDefaultForm;

const CustomerDetail = ({ control, errors, disabled, setValue }: Props) => {
  const StyledLink = styled(Link)<any>(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showContactForm, setShowContactForm] = useState(false);

  const selectCustomerGridRef = useRef<any>();

  const [showSelectCustomer, setShowSelectCustomer] = useState(false);

  const [customerIds, setCustomerIds] = useState<string[]>([]);
  const [customerIdsSnapshot, setCustomerIdsSnapshot] = useState<string[]>([]);

  const watchCustomer = useWatch({ control, name: "customer_name" });
  const watchCustomerId = useWatch({
    control,
    name: "customer_contact_unique_id",
  });

  // const [contactsGroupsList, setContactsGroupsList] = useState<ITag[]>([]);

  const openCustomerTable = () => {
    setShowSelectCustomer(true);
  };

  const closeCustomerTable = () => {
    setShowSelectCustomer(false);
  };

  const openContactForm = async () => {
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
    searchParams.delete("contactTab");
    setSearchParams(searchParams);
  };

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const { data, isSuccess } = useContactQuery<ContactQuery>(
    graphQLClientWithHeaderContact,
    {
      uniqueInput: {
        unique_id: watchCustomerId,
      },
    },
    { enabled: !!watchCustomerId }
  );

  const { data: tagList } =
    useContactTagsFindByEntityQuery<ContactTagsFindByEntityQuery>(
      graphQLClientWithHeaderContact,
      {
        entityName: ContactEntityType.Contact,
      }
    );

  useEffect(() => {
    if (watchCustomerId) {
      setCustomerIds([watchCustomerId]);
      setCustomerIdsSnapshot([watchCustomerId]);
      const getContact = async () => {
        const contactData = data?.contact as IContact;
        const formattedName = formatContactName(
          contactData.contact_type,
          contactData.contact_type_detail,
          contactData.title_name || "",
          contactData.main_contact_name,
          contactData.secondary_contact_name || ""
        );
        setValue(
          "customer_name",
          `${contactData.unique_id} - ${formattedName}`
        );
      };

      if (isSuccess) {
        getContact();
      }
    }
  }, [data?.contact, isSuccess, setValue, watchCustomerId]);

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { tag_list, ...otherFilter } = filterModel;
      const formatFilter = {
        ...otherFilter,
        tag_list: tag_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            name: {
              filterType: "set",
              values: tag_list.values,
            },
          },
        },
      };
      try {
        const { contactsFindManyAggrid } =
          await graphQLClientWithHeaderContact.request(CONTACTS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: contactsFindManyAggrid.data as any[],
          rowCount: contactsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFirstDataRendered = () => {
    const instance =
      selectCustomerGridRef.current.api.getFilterInstance("contact_status");
    instance?.setModel({ filterType: "set", values: ["active"] });
    selectCustomerGridRef.current.api.onFilterChanged();
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const finishCustomerSelectHandler = (data: IContact) => {
    if (data) {
      setValue("customer_contact_unique_id", data.unique_id);
      setValue(
        "customer_name",
        `${data.unique_id} - ${data.main_contact_name}${
          data.secondary_contact_name ? ` ${data.secondary_contact_name}` : ""
        }`
      );
    } else {
      setValue("customer_contact_unique_id", "");
      setValue("customer_name", "");
    }
    setShowSelectCustomer(false);
  };

  return (
    <Fragment>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.customer_detail")}
        </Typography>
        {!disabled && (
          <CustomizedTooltip title="เลือกลูกค้า">
            <IconButton
              onClick={openCustomerTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="customer_name"
            label={t("manufacture.order.customer")}
            control={control}
            error={Boolean(errors?.customer_name)}
            helperText={errors?.customer_name && errors?.customer_name.message}
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={openContactForm}>
                    {watchCustomer || t("manufacture.order.customer")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchCustomerId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดลูกค้า">
                      <IconButton
                        onClick={openContactForm}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <CheckboxModalTable
        modalTitle={t("sales.customer")}
        btnTitle={t("button.add")}
        gridRef={selectCustomerGridRef}
        height={450}
        columnDefs={contactColumnDefs(
          t,
          undefined,
          tagList?.contactTagsFindByEntity ?? []
        )}
        rowSelection="single"
        onFinishEditing={finishCustomerSelectHandler}
        modalIsOpen={showSelectCustomer}
        closeModal={closeCustomerTable}
        onGridReady={onGridReady}
        selectedIds={customerIds}
        setSelectedIds={setCustomerIds}
        idsSnapshot={customerIdsSnapshot}
        setIdsSnapshot={setCustomerIdsSnapshot}
        onFirstDataRendered={onFirstDataRendered}
      />
      <ModalUI
        open={showContactForm}
        handleClose={closeContactForm}
        maxWidth="lg"
      >
        <ContactContainer
          isContactPage={false}
          contactUniqueId={watchCustomerId}
        />
      </ModalUI>
    </Fragment>
  );
};

export default CustomerDetail;
