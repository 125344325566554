import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { Cookies } from "react-cookie";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

const cookies = new Cookies();
const access_token = cookies.get("access_token");
const baseTenantId = sessionStorage.getItem("tenant-id");

const wsLink = () => {
  return new GraphQLWsLink(
    createClient({
      url:
        ENVIRONMENT === "development"
          ? "wss://erp-dev-api.npr.digital/item/graphql"
          : "wss://erp-api.npr.digital/item/graphql",
      connectionParams: {
        Authorization: `Bearer ${access_token}`,
        "tenant-id": baseTenantId,
      },
    })
  );
};

export const apolloClient = new ApolloClient({
  link: wsLink(),
  cache: new InMemoryCache(),
});

export const createApolloClient = (tenant_id?: string | null | undefined) => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url:
        ENVIRONMENT === "development"
          ? "wss://erp-dev-api.npr.digital/item/graphql"
          : "wss://erp-api.npr.digital/item/graphql",
      connectionParams: {
        Authorization: `Bearer ${access_token}`,
        "tenant-id": tenant_id,
      },
    })
  );

  return new ApolloClient({
    link: wsLink,
    cache: new InMemoryCache(),
  });
};
