import { Box, Typography } from "@mui/material";
import React from "react";
import ModalUI from "./ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import { IDefaultForm } from "../../types/global";
import ControlledNumberTextField from "../Controller/ControlledNumberTextField";
import CustomizedStatus from "../Custom/CustomizedStatus";
import ControlledTextField from "../Controller/ControlledTextField";

type Props = {
  title?: string;
  message?: string | null;
  nextStatus: string;
  open: boolean;
  handleClose: () => void;
  confirmationAction: () => void;
  control: IDefaultForm["control"];
  uom: string;
};

const ChangeStatusConfirmation = ({
  title,
  message,
  nextStatus,
  open,
  handleClose,
  confirmationAction,
  control,
  uom,
}: Props) => {
  return (
    <ModalUI
      title={title}
      status={<CustomizedStatus status={nextStatus} />}
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
    >
      {message && <Typography color="red">{message}</Typography>}
      {nextStatus === "finished" && (
        <Box display="flex" flexDirection="column" mt={2}>
          <Box width="14rem">
            <Typography mb={2}>จำนวนที่ผลิตได้</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <ControlledNumberTextField
                control={control}
                name="actual_production_qty"
              />
              <Typography>{uom}</Typography>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography mb={2}>หมายเหตุใบสั่งผลิต</Typography>
            <ControlledTextField
              control={control}
              name="remark"
              multiline
              rows={4}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={handleClose}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={confirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default ChangeStatusConfirmation;
