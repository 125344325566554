import {
  ColDef,
  RowDoubleClickedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useBomColumnDefs } from "../Bom/columnDefs";
import { useTranslation } from "react-i18next";
import { IBom } from "../../../../types/Manufacture/bom";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { formatDate } from "../../../../utils/Formatter/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { IIngredient } from "../../../../types/Manufacture";
import CustomizedChips from "../../../Custom/CustomizedChips";

interface Props {
  gridRef: RefObject<AgGridReact<IIngredient>>;
  rowData: any[];
}

const ManufactureIngredientReportTable = ({ gridRef, rowData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columnDef: ColDef[] = [
    {
      field: "unique_id",
      headerName: t("manufacture.order.unique_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 200,
    },
    {
      field: "issue_date",
      headerName: t("manufacture.order.issue_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => {
        return params.data.issue_date
          ? formatDate(params.data.issue_date)
          : null;
      },
      minWidth: 150,
      sort: "desc",
    },
    {
      field: "ingredient_order_id",
      sort: "asc",
      hide: true,
    },
    {
      field: "aggrid_status",
      headerName: t("status.index"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
        values: [
          "draft",
          "pending_manu",
          "in_progress",
          "finished",
          "cancelled",
        ],
      },
      cellRenderer: (params: ValueFormatterParams) => (
        <CustomizedStatus status={params.value} />
      ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
      minWidth: 150,
    },
    {
      field: "reference_unique_ids",
      headerName: t("manufacture.order.reference_unique_id_list"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "external_unique_id",
      headerName: t("manufacture.order.external_reference_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "production_date",
      headerName: t("manufacture.order.production_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => {
        return params.data.production_date
          ? formatDate(params.data.production_date)
          : null;
      },
      minWidth: 150,
    },
    {
      field: "production_completion_date",
      headerName: t("manufacture.order.production_completion_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => {
        return params.data.production_completion_date
          ? formatDate(params.data.production_completion_date)
          : null;
      },
      minWidth: 150,
    },
    {
      field: "delivery_date",
      headerName: t("manufacture.order.delivery_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => {
        return params.data.delivery_date
          ? formatDate(params.data.delivery_date)
          : null;
      },
      minWidth: 150,
    },
    {
      field: "customer_contact_unique_id",
      headerName: t("manufacture.order.contact_document_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "customer_name",
      headerName: t("manufacture.order.customer_name"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 250,
    },
    {
      field: "mo_item_unique_id",
      headerName: t("manufacture.order.item_unique_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "mo_item_name",
      headerName: t("manufacture.order.item_name"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 250,
    },
    {
      field: "production_qty",
      headerName: t("manufacture.order.production_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "actual_production_qty",
      headerName: t("manufacture.order.actual_production_qty"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "goods_receive_qty",
      headerName: t("manufacture.order.goods_receive_qty_1"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "mo_uom",
      headerName: t("manufacture.order.uom"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "mo_item_description",
      headerName: t("manufacture.order.item_description"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "remark",
      headerName: t("manufacture.order.item_remark"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "bom_name",
      headerName: t("manufacture.order.bom"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 180,
    },
    {
      field: "routing_name",
      headerName: t("manufacture.routing.rounting_name"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },

    {
      field: "mfg_type",
      headerName: t("manufacture.order.type"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "group",
      headerName: t("manufacture.order.group"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ValueFormatterParams) =>
        params.value ? (
          <CustomizedChips key={params.value} value={`${params.value}`} />
        ) : (
          ""
        ),
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
      minWidth: 150,
    },
    {
      field: "other_manufacture_cost",
      headerName: t("manufacture.order.cost.other_manufacture_cost"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "work_order_cost",
      headerName: t("manufacture.order.cost.work_order_cost"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "bom_cost",
      headerName: t("manufacture.order.cost.bom_cost"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "total_cost",
      headerName: t("manufacture.order.cost.total_cost"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "item_remark",
      headerName: t("manufacture.bom.internal_remark"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "created_date",
      headerName: t("date.created_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params) => {
        return params.data.created_date
          ? formatDate(params.data.created_date)
          : null;
      },
    },
    {
      field: "created_by",
      headerName: t("sentence.created_by"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      sortable: false,
      minWidth: 200,
    },
    {
      field: "related_users",
      headerName: t("sales.employee_list"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "attachment_urls",
      headerName: t("manufacture.order.attachment_urls"),
      filter: "agTextColumnFilter",
      suppressMenu: true,
      floatingFilter: false,
      sortable: false,
      hide: true,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 200,
    },
    {
      field: "rank",
      headerName:
        t("manufacture.ingredient.no") +
        ` (${t("manufacture.ingredient.index")})`,
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      suppressMenu: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 200,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "ag-end-header",
    },
    {
      field: "item_unique_id",
      headerName:
        t("manufacture.ingredient.item_unique_id") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 150,
    },
    {
      field: "item_name",
      headerName:
        t("manufacture.ingredient.item_name") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 250,
    },
    {
      field: "item_description",
      headerName:
        t("manufacture.ingredient.item_description") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
      minWidth: 250,
    },
    {
      field: "qty",
      headerName:
        t("manufacture.ingredient.qty") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "adNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "cost_per_unit",
      headerName:
        t("manufacture.ingredient.cost_per_unit") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "adNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "good_issue_qty",
      headerName:
        t("manufacture.ingredient.goods_issue_qty") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "adNumberColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      minWidth: 150,
      headerClass: "ag-end-header",
    },
    {
      field: "moi_uom",
      headerName:
        t("manufacture.ingredient.unit") +
        ` (${t("manufacture.ingredient.index")})`,
      filter: "adTextColumnFilter",
      valueFormatter: (params) =>
        params.value ? params.value.toLocaleString() : 0,
      cellStyle: { display: "flex", justifyContent: "flex-start" },
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      minWidth: 150,
    },
  ];

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/order/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default ManufactureIngredientReportTable;
