import { AgGridReact } from "ag-grid-react";
import { useRef } from "react";
import { ColDef } from "ag-grid-community";
// import CustomizedStatus from "../../Custom/CustomizedStatus";
import AgGrid from "../../UI/AgGrid";
// import { IContact } from "../../../types/Contact";

type Props = {
  data: any[];
};

const ContactImporterTable = ({ data }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs: ColDef[] = [
    {
      field: "unique_id",
      headerName: "รหัสผู้ติดต่อ",
      filter: "agTextColumnFilter",
      flex: 1,
      minWidth: 200,
    },
    // {
    //   field: "main_contact_name",
    //   headerName: "ชื่อผู้ติดต่อ",
    //   filter: "agTextColumnFilter",
    //   flex: 1,
    //   minWidth: 200,
    //   valueFormatter: ({ data }: { data: IContact }) => {
    //     if (data.contact_type === "นิติบุคคล") {
    //       return data.main_contact_name;
    //     }
    //     return `${data.main_contact_name} ${
    //       data?.secondary_contact_name || ""
    //     }`;
    //   },
    // },
    // {
    //   field: "is_active",
    //   headerName: "สถานะ",
    //   filter: "agSetColumnFilter",
    //   filterParams: {
    //     values: [1, 0],
    //     valueFormatter: ({ value }: { value: number }) => {
    //       if (value) return "ใช้งาน";
    //       return "หยุดใช้งาน";
    //     },
    //   },
    //   cellRenderer: ({ value }: { value: boolean }) => {
    //     if (value) return <CustomizedStatus status="active" />;
    //     return <CustomizedStatus status="inactive" />;
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  ];
  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      rowData={data}
      disabledSidebar
    />
  );
};

export default ContactImporterTable;
