import { useMemo } from "react";
import { IProject } from "../../types/Project";
import { IComboBoxOption } from "../../types/global";

export const useProjectListOption = (allProjects: IProject[]) => {
  const defaultOptions: IComboBoxOption[] = useMemo(() => {
    const allOption = { id: "ALL", label: "ทั้งหมด", value: "ทั้งหมด" };
    const otherOptions = allProjects?.map((project: IProject) => {
      const formattedOption = {
        id: project.unique_id,
        label: project.name,
        value: project.name,
      };
      return formattedOption;
    });

    return [allOption, ...otherOptions];
  }, [allProjects]);

  return {
    projectListOptions: defaultOptions,
  };
};
