import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../types/global";
import { useTranslation } from "react-i18next";

export const useDeliveryOrderCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("logistic.delivery_trip.index"),
        disabled: false,
      },
    ],
    [t]
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  useEffect(() => {
    switch (status) {
      case "wait_deliver":
      case "completed":
      case "not_completed":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return {
    selectCreateOptions: defaultOptions,
    disabledCreateOptions: disabled,
  };
};
