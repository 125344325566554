import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";

const Purchase = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.request.index"),
      to: "/purchase/request",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("purchase.return.index"),
      to: "/purchase/return",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("report"),
      to: "/purchase/report",
      icon: <ContentPasteOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Purchase;
