import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IDefaultForm } from "../../../types/global";
import { IGoodsReturn } from "../../../types/Inventory/goodsReturn";
import GoodsReturnItemList from "../../../components/Table/Inventory/GoodsReturn/GoodsReturnItemList";
import GoodsReturnScanItemList from "../../../components/Table/Inventory/GoodsReturn/GoodsReturnScanItemList";
import { WarehousesQuery } from "../../../generated/inventory";

type Props = IDefaultForm & {
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  step: number;
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReturn, "trace_entry_list">;
  renderButton: () => JSX.Element | null;
  warehouseData?: WarehousesQuery;
};

const BarcodeTab = ({
  control,
  getValues,
  setValue,
  step,
  disabled,
  errors,
  fields,
  append,
  remove,
  update,
  renderButton,
  warehouseData,
}: Props) => {
  return (
    <>
      {step === 1 ? (
        <GoodsReturnItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          fields={fields}
          step={step}
        />
      ) : (
        <GoodsReturnScanItemList
          control={control}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          errors={errors}
          fields={fields}
          step={step}
          append={append}
          remove={remove}
          update={update}
          allWarehouses={warehouseData?.warehouses || []}
        />
      )}
      {renderButton()}
    </>
  );
};

export default BarcodeTab;
