import { Controller, Control } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DateTimePicker,
  DesktopDatePickerProps,
} from "@mui/x-date-pickers";
import CustomizedTextField from "../Custom/CustomizedTextField";

type ExtendedDatePickerProps = {
  name: string;
  control: Control<any>;
  error?: any;
  required?: boolean;
  open?: DesktopDatePickerProps<HTMLInputElement, Date>["open"];
  onClose?: DesktopDatePickerProps<HTMLInputElement, Date>["onClose"];
  onOpen?: DesktopDatePickerProps<HTMLInputElement, Date>["onOpen"];
  label?: DesktopDatePickerProps<HTMLInputElement, Date>["label"];
  disabled?: DesktopDatePickerProps<HTMLInputElement, Date>["disabled"];
  disablePast?: DesktopDatePickerProps<HTMLInputElement, Date>["disablePast"];
};

const ControlledDateTimePicker = ({
  name,
  control,
  error,
  open,
  onClose,
  onOpen,
  label,
  required,
  disabled,
  disablePast,
}: ExtendedDatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            inputFormat="dd/MM/yyyy HH:mm"
            label={label}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            onChange={onChange}
            value={value}
            ref={ref}
            renderInput={(params) => (
              <CustomizedTextField
                {...params}
                ref={params.inputRef}
                error={Boolean(error)}
                helperText={error?.message}
                required={required}
              />
            )}
            disablePast={disablePast}
            disabled={disabled}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDateTimePicker;
