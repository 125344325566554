import { useCallback, useState } from "react";
import { useWatch } from "react-hook-form";
import { useMemo } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import ControlledDatePicker from "../Controller/ControlledDatePicker";
import ControlledSelect from "../Controller/ControlledSelect";
import { IDefaultForm } from "../../types/global";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import CustomizedButton from "../Custom/CustomizedButton";

interface Props {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  onFilterReset: () => void;
  getFilteredData: () => void;
  isLoading?: boolean;
  future?: boolean;
}

dayjs.extend(quarterOfYear);

export default function ReportDateFilter({
  control,
  setValue,
  getValues,
  onFilterReset,
  isLoading,
  getFilteredData,
  future,
}: Props) {
  const { t } = useTranslation();
  const watchDateType = useWatch({ control, name: "dateType" });
  const [dateFromOpen, setDateFromOpen] = useState<boolean>(false);
  const [dateToOpen, setDateToOpen] = useState<boolean>(false);

  const filterChangedDate = useCallback(
    (startDate: Date, endDate: Date) => {
      setValue("dateFrom", startDate);
      setValue("dateTo", endDate);
    },
    [setValue]
  );

  const pastOption = useMemo(
    () => [
      {
        label: "กำหนดเอง",
        value: "user_defined",
      },
      {
        label: "วันนี้",
        value: "today",
      },
      { label: "เมื่อวาน", value: "yesterday" },
      { label: "7 วันที่แล้ว", value: "last_7_days" },
      { label: "30 วันที่แล้ว", value: "last_30_days" },
      { label: "สัปดาห์นี้", value: "this_week" },
      { label: "สัปดาห์ที่แล้ว", value: "last_week" },
      { label: "เดือนนี้", value: "this_month" },
      { label: "เดือนที่แล้ว", value: "last_month" },
      { label: "ไตรมาสนี้", value: "this_quarter" },
      { label: "ไตรมาสที่แล้ว", value: "last_quarter" },
      { label: "ปีนี้", value: "this_year" },
      { label: "ปีที่แล้ว", value: "last_year" },
    ],
    []
  );

  const futureOption = useMemo(
    () => [
      {
        label: "พรุ่งนี้",
        value: "tomorrow",
      },
      { label: "7 วันข้างหน้า", value: "next_7_days" },
      { label: "30 วันข้างหน้า", value: "next_30_days" },
      { label: "สัปดาห์หน้า", value: "next_week" },
      { label: "เดือนหน้า", value: "next_month" },
      { label: "ไตรมาสหน้า", value: "next_quarter" },
      { label: "ปีหน้า", value: "next_year" },
    ],
    []
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("dateType");
    if (dateType === "user_defined") return;
    let startDate = dayjs().startOf("day").subtract(1, "day").toDate();
    let endDate = dayjs().endOf("day").toDate();

    if (dateType === "today") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "yesterday") {
      startDate = dayjs().startOf("day").subtract(1, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "last_7_days") {
      startDate = dayjs().startOf("day").subtract(6, "day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "last_30_days") {
      startDate = dayjs().startOf("day").subtract(29, "day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "this_week") {
      startDate = dayjs().startOf("week").toDate();
      endDate = dayjs().endOf("week").toDate();
    } else if (dateType === "last_week") {
      startDate = dayjs().startOf("week").subtract(1, "week").toDate();
      endDate = dayjs().endOf("week").subtract(1, "week").toDate();
    } else if (dateType === "this_month") {
      startDate = dayjs().startOf("month").toDate();
      endDate = dayjs().endOf("month").toDate();
    } else if (dateType === "last_month") {
      startDate = dayjs().startOf("month").subtract(1, "month").toDate();
      endDate = dayjs().endOf("month").subtract(1, "month").toDate();
    } else if (dateType === "this_year") {
      startDate = dayjs().startOf("year").toDate();
      endDate = dayjs().endOf("year").toDate();
    } else if (dateType === "last_year") {
      startDate = dayjs().startOf("year").subtract(1, "year").toDate();
      endDate = dayjs().endOf("year").subtract(1, "year").toDate();
    } else if (dateType === "this_quarter") {
      startDate = dayjs().startOf("quarter").toDate();
      endDate = dayjs().endOf("quarter").toDate();
    } else if (dateType === "last_quarter") {
      startDate = dayjs().startOf("quarter").subtract(1, "quarter").toDate();
      endDate = dayjs().endOf("quarter").subtract(1, "quarter").toDate();
    } else if (dateType === "tomorrow") {
      startDate = dayjs().startOf("day").add(1, "day").toDate();
      endDate = dayjs().endOf("day").add(1, "day").toDate();
    } else if (dateType === "next_7_days") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").add(6, "day").toDate();
    } else if (dateType === "next_30_days") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").add(29, "day").toDate();
    } else if (dateType === "next_week") {
      startDate = dayjs().startOf("week").add(1, "week").toDate();
      endDate = dayjs().endOf("week").add(1, "week").toDate();
    } else if (dateType === "next_month") {
      startDate = dayjs().startOf("month").add(1, "month").toDate();
      endDate = dayjs().endOf("month").add(1, "month").toDate();
    } else if (dateType === "next_quarter") {
      startDate = dayjs().startOf("quarter").add(1, "quarter").toDate();
      endDate = dayjs().endOf("quarter").add(1, "quarter").toDate();
    } else if (dateType === "next_year") {
      startDate = dayjs().startOf("year").add(1, "year").toDate();
      endDate = dayjs().endOf("year").add(1, "year").toDate();
    }

    return filterChangedDate(startDate, endDate);
  }, [filterChangedDate, getValues]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  return (
    <CustomizedBox>
      <Typography fontWeight="bold" mb={2}>
        ตัวกรองแสดงผล
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledSelect
            name="dateType"
            control={control}
            options={future ? [...pastOption, ...futureOption] : pastOption}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledDatePicker
            control={control}
            name="dateFrom"
            label={t("date.from")}
            open={dateFromOpen}
            onClose={() => setDateFromOpen(false)}
            onOpen={() => setDateFromOpen(true)}
            onChange={(e, field) => {
              setValue("dateType", "user_defined");
              if (e) field.onChange(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledDatePicker
            control={control}
            name="dateTo"
            label={t("date.to")}
            open={dateToOpen}
            onClose={() => setDateToOpen(false)}
            onOpen={() => setDateToOpen(true)}
            onChange={(e, field) => {
              setValue("dateType", "user_defined");
              if (e) field.onChange(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <CustomizedButton
            title={t("button.filter")}
            variant="contained"
            onClick={getFilteredData}
            disabled={isLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <CustomizedButton
            title={t("button.reset_filter")}
            variant="outlined"
            onClick={onFilterReset}
            disabled={isLoading}
            fullWidth
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
}
