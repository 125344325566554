import { Dispatch, SetStateAction } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedButton from "../../Custom/CustomizedButton";

import AgGrid from "../../UI/AgGrid";
import CheckedUserModal from "../../Table/CheckedUserModal";
import { userColumnDefs } from "../../Table/User/columnDefs";

import { useModal } from "../../../hooks/use-modal";
import { useDisable } from "../../../hooks/use-disable";
import { IRole, IUser } from "../../../types/Auth/user";

type Props = {
  userIds: string[];
  setUserIds: Dispatch<SetStateAction<string[]>>;
  userIdsSnapshot: string[];
  setUserIdsSnapshot: Dispatch<SetStateAction<string[]>>;
};

const UserListForm = ({
  userIds,
  setUserIds,
  userIdsSnapshot,
  setUserIdsSnapshot,
}: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<IRole>();
  const [disabled] = useDisable();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "user_list",
    keyName: "genId",
  });

  const finishUsersSelect = (data: IUser[]) => {
    // filter out users not selected in current modal session
    let filteredUsers = fields.filter((user: IUser) =>
      userIds.includes(user.unique_id)
    );

    // get current users id to prevent duplication when appending newly selected users
    const filteredUsersId = filteredUsers.map((user: IUser) => user.unique_id);

    data.forEach((user) => {
      if (!filteredUsersId.includes(user.unique_id)) {
        filteredUsers.push(user);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredUsers = filteredUsers.sort((a, b) => {
      return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
    });

    const formatUser: any[] = filteredUsers.map((user) => ({
      id: user.id,
      unique_id: user.unique_id,
      first_name: user.first_name,
      img_url: user.img_url,
      last_name: user.last_name,
      position: user.position,
      department: user.department,
      email: user.email,
      branch_name: user.branch_name,
      is_active: user.is_active,
    }));
    replace(formatUser);
    closeModalHandler();
  };

  const handleDelete = (index: number) => {
    remove(index);
    setUserIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setUserIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const columnDefs = userColumnDefs(t, !disabled ? handleDelete : undefined);

  return (
    <CustomizedBox margin={"2rem 0 4rem 0"}>
      <Typography mb={2} variant="h6">
        {t("user.rbac.permitted_employee")}
      </Typography>
      <AgGrid columnDefs={columnDefs} rowData={fields} height={300} />
      {!disabled && (
        <CustomizedButton
          title="เพิ่มพนักงาน"
          variant="contained"
          onClick={openModalHandler}
        />
      )}
      <CheckedUserModal
        title={t("user.rbac.permitted_employee")}
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="multiple"
      />
    </CustomizedBox>
  );
};

export default UserListForm;
