import {
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import {
  IEvent,
  IFilterBox,
  IProject,
  ProjectView,
} from "../../../types/Project";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ProjectsTable from "../../../components/Table/Projects";
import FilterBox from "../../../components/Form/Project/FilterBox";
import { useForm } from "react-hook-form";
import KanbanBoard from "../../../components/UI/KanbanBoard/KanbanBoard";
import { useKanbanBoard } from "../../../hooks/Projects/use-kanbanboard";
import Calendar from "../../../components/UI/Calendar";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import { AgGridReact } from "ag-grid-react";
import {
  EventsQuery,
  ProjectsQuery,
  WorkflowTemplatesQuery,
  useEventsQuery,
  useProjectsQuery,
  useWorkflowTemplatesQuery,
} from "../../../generated/project";
import { useEventObjectFormatter } from "../../../hooks/Projects/Event/use-event-object-formatter";
import { EventClickArg } from "@fullcalendar/core";
import EventModal from "../../../components/UI/Event/EventModal";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  eventSchema,
  eventValidation,
} from "../../../components/Form/Project/Event/schema";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useEventStatusFilterOption } from "../../../hooks/Projects/Event/use-event-status-filter-option";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";

const Projects = () => {
  const gridRef = useRef<AgGridReact<IProject>>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const [view, setView] = useState<string>(ProjectView.Kanban);
  const [allProjects, setAllProjects] = useState<IProject[]>();
  const [allEvents, setAllEvents] = useState<IEvent[]>();
  const [eventModal, setEventModal] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("project.index"),
      to: "/project",
    },
    {
      name: t("project.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("project.all"),
      path: `${pathname}`,
    },
    {
      label: t("project.status.pre_sales"),
      path: `${pathname}?filter=pre-sales`,
    },
    {
      label: t("project.status.post_sales"),
      path: `${pathname}?filter=post-sales`,
    },
    {
      label: t("project.status.support"),
      path: `${pathname}?filter=support`,
    },
    {
      label: t("project.status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("project.status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const {
    control: filterControl,
    setValue: setFilterValue,
    formState: { errors: filterErrors },
    getValues: getFilterValues,
    handleSubmit: handleFilterSubmit,
    reset: resetFilter,
  } = useForm<IFilterBox>({
    defaultValues: {
      project_name: null,
      project_unique_id: null,
      related_user_list: [],
      status: "",
      filter_tags: [],
      event_name: null,
    },
  });

  const {
    control: eventControl,
    setValue: SetEventValue,
    formState: { errors: eventErrors },
    getValues: getEventValues,
    handleSubmit: handleSubmitEvent,
    reset: resetEvent,
  } = useForm<IEvent>({
    defaultValues: eventSchema,
    resolver: yupResolver(eventValidation),
  });

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { data: allStatusQuery, isLoading: isLoadingAllStatus } =
    useWorkflowTemplatesQuery<WorkflowTemplatesQuery>(
      graphQLClientWithHeaderProject
    );

  const {
    data: projectsQuery,
    isLoading: isLoadingProjects,
    isSuccess: isSuccessProjects,
  } = useProjectsQuery<ProjectsQuery>(
    graphQLClientWithHeaderProject,
    {},
    { enabled: view === ProjectView.Kanban }
  );

  const {
    data: eventsQuery,
    isLoading: isLoadingEvents,
    isSuccess: isSuccessEvents,
    refetch: refetchEvents,
    isRefetching: isRefetchingEvents,
  } = useEventsQuery<EventsQuery>(
    graphQLClientWithHeaderProject,
    {},
    { enabled: view === ProjectView.Calendar }
  );

  const { eventObjects } = useEventObjectFormatter(allEvents ?? []);
  const { mapEventStatustoThai, eventStatusOptions } =
    useEventStatusFilterOption();

  const {
    onDragEnd,
    projectsGroupedByStatus,
    openConfirmStatusChangeModal,
    closeConfirmStatusChangeModalHandler,
    confirmChangeStatusHandler,
    dropResult,
    toggleSorting,
    sortingAscending,
  } = useKanbanBoard(
    allStatusQuery?.workflowTemplates ?? [],
    allProjects ?? []
  );

  const handleView = (_: any, newView: string) => {
    if (newView) {
      if (newView === ProjectView.Calendar) {
        return navigate("/project/events");
      } else {
        if (pathname.includes("events")) {
          setView(newView);
          return navigate("/project/projects");
        } else {
          clearAggridFilter();
          setView(newView);
        }
      }
    }
    onFilterClear();
  };

  const eventClickHandler = (info: EventClickArg) => {
    const eventName = info.event.extendedProps.name;
    const event = eventsQuery?.events.find(
      (event) => event.name === eventName
    ) as IEvent;

    resetEvent(event);
    setEventModal(true);
  };

  const closeEventModalHandler = () => {
    // resetEvent()

    setEventModal(false);
  };

  const clearAggridFilter = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
    if (searchParams.has("filter")) {
      searchParams.delete("filter");
      setSearchParams(searchParams);
    }
  };

  const onFilterSubmit = (data: IFilterBox) => {
    console.log(data);
    if (view === "kanban") {
      const filteredProject = projectsQuery?.projects.filter((project) => {
        let filters = true;
        if (data.project_unique_id) {
          if (data.project_unique_id !== "ทั้งหมด")
            return project.unique_id === data.project_unique_id;
        }

        if (data.project_name) {
          if (data.project_name !== "ทั้งหมด")
            return project.name === data.project_name;
        }

        //check if every filter user is in the related_user_list of a project
        if (
          data.related_user_list &&
          project.related_user_list &&
          data.related_user_list?.length > 0
        ) {
          const relatedUserWithCreator = [
            ...project.related_user_list,
            project.created_by,
          ];

          filters =
            filters &&
            data.related_user_list?.every((filterUser) =>
              relatedUserWithCreator.some(
                (projectUser) =>
                  filterUser.user_unique_id === projectUser.user_unique_id
              )
            );
        }

        //check if every filter tags is in a project
        if (data.filter_tags && data.filter_tags?.length > 0) {
          if (project.tag_list) {
            filters =
              filters &&
              data.filter_tags.every((filterTag) =>
                project.tag_list?.some(
                  (projectTag) => projectTag.name === filterTag
                )
              );
          } else {
            return false;
          }
        }

        return filters;
      });

      setAllProjects(filteredProject as IProject[]);
    } else if (view === "calendar") {
      const filteredEvent = eventsQuery?.events.filter((event) => {
        let filters = true;
        if (data.event_name) {
          if (data.event_name !== "ทั้งหมด")
            return event.name === data.event_name;
        }

        if (data.project_name) {
          if (data.project_name !== "ทั้งหมด")
            filters = filters && event.project_name === data.project_name;
        }

        if (data.status && data.status !== "") {
          if (data.status !== "ทั้งหมด") {
            //need to change to event.aggrid_status
            filters =
              filters &&
              mapEventStatustoThai(event.main_status) === data.status;
          }
        }
        if (
          data.related_user_list &&
          event.related_user_list &&
          data.related_user_list?.length > 0
        ) {
          const relatedUserWithCreator = [
            ...event.related_user_list,
            event.created_by,
          ];

          filters =
            filters &&
            relatedUserWithCreator.some((projectUser) =>
              data.related_user_list?.some(
                (filterUser) =>
                  filterUser.user_unique_id === projectUser.user_unique_id
              )
            );
        }

        return filters;
      });

      setAllEvents(filteredEvent as IEvent[]);
    }
  };

  const onFilterClear = () => {
    resetFilter();
    setAllProjects(projectsQuery?.projects as IProject[]);
    setAllEvents(eventsQuery?.events as IEvent[]);
  };

  useEffect(() => {
    if (isSuccessProjects) {
      setAllProjects(projectsQuery?.projects as IProject[]);
    }
    if (isSuccessEvents) {
      setAllEvents(eventsQuery?.events as IEvent[]);
    }
  }, [eventsQuery?.events, isSuccessEvents, isSuccessProjects, projectsQuery]);

  useEffect(() => {
    if (pathname.includes("events")) {
      setView(ProjectView.Calendar);
    }
    if(pathname.includes("projects")){
      setView(ProjectView.Kanban)
    }
  }, [pathname]);

  const renderButtonGroup = () => (
    <ToggleButtonGroup
      color="primary"
      value={view}
      size="small"
      exclusive
      onChange={handleView}
      aria-label="view"
      sx={{ ml: 1 }}
    >
      <ToggleButton value={ProjectView.Table} aria-label="table view">
        <CustomizedTooltip title={"หน้ารายการ"}>
          <FormatListBulletedIcon />
        </CustomizedTooltip>
      </ToggleButton>
      <ToggleButton value={ProjectView.Kanban} aria-label="kanban board view">
        <CustomizedTooltip title={"หน้า Kanban"}>
          <DashboardOutlinedIcon />
        </CustomizedTooltip>
      </ToggleButton>
      <ToggleButton value={ProjectView.Calendar} aria-label="calendar view">
        <CustomizedTooltip title={"เหตุการณ์"}>
          <CalendarTodayIcon />
        </CustomizedTooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("project.index")}</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          {renderButtonGroup()}
          {!isSmallScreen && (
            <>
              <CustomizedButton
                title={t("project.add")}
                variant="contained"
                onClick={() => navigate(`/project/projects/add`)}
              />
              <CustomizedButton
                title={t("project.event.add")}
                variant="contained"
                onClick={() => {
                  setEventModal(true);
                }}
              />
            </>
          )}
        </Box>
      </HeaderLayout>
      {isSmallScreen && (
        <Grid container mb={1} spacing={1}>
          <Grid item xs={6}>
            <CustomizedButton
              title={t("project.add")}
              variant="contained"
              onClick={() => navigate(`/project/projects/add`)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedButton
              title={t("project.event.add")}
              variant="contained"
              onClick={() => {
                setEventModal(true);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      {view === ProjectView.Table && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          <CustomizedButton
            title={`ล้างตัวกรอง`}
            variant="outlined"
            onClick={clearAggridFilter}
            sx={{ height: "100%" }}
          />
        </Box>
      )}
      {view !== ProjectView.Table && (
        <FilterBox
          view={view}
          control={filterControl}
          errors={filterErrors}
          getValues={getFilterValues}
          setValue={setFilterValue}
          disabled={false}
          handleSubmit={handleFilterSubmit}
          onFilterSubmit={onFilterSubmit}
          onFilterClear={onFilterClear}
          allStatus={allStatusQuery?.workflowTemplates}
          isLoadingAllStatus={isLoadingAllStatus}
          allProjects={projectsQuery?.projects as IProject[]}
          isLoadingAllProjects={isLoadingProjects}
          events={eventsQuery?.events as IEvent[]}
          isLoadingEvents={isLoadingEvents}
        />
      )}
      {view === ProjectView.Table && (
        <ProjectsTable
          gridRef={gridRef}
          isFilter={isFilter}
          allProjects={projectsQuery?.projects as IProject[]}
        />
      )}
      {view === ProjectView.Calendar &&
        (isLoadingEvents ? (
          <Box
            sx={{
              height: "calc(100vh - 300px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={1} mb={3}>
              {eventStatusOptions.map((status, index) => (
                <Grid item key={index + Math.random()}>
                  <CustomizedStatus status={status.value}></CustomizedStatus>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ overflowX: "scroll" }}>
              <Calendar
                events={eventObjects ?? []}
                eventClickHandler={eventClickHandler}
              />
            </Box>
          </>
        ))}
      {view === ProjectView.Kanban &&
        (isLoadingProjects || isLoadingAllStatus || isRefetchingEvents ? (
          <Box
            sx={{
              height: "calc(100vh - 300px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <KanbanBoard
            projectsGroupedByStatus={projectsGroupedByStatus}
            onDragEnd={onDragEnd}
            confirmation={openConfirmStatusChangeModal}
            closeConfirmationHandler={closeConfirmStatusChangeModalHandler}
            changeStatusHandler={confirmChangeStatusHandler}
            dropResult={dropResult}
            allStatus={allStatusQuery?.workflowTemplates ?? []}
            toggleSorting={toggleSorting}
            sorting={sortingAscending}
          />
        ))}
      <EventModal
        eventModal={eventModal}
        closeEventModalHandler={closeEventModalHandler}
        refetchEvents={refetchEvents}
        control={eventControl}
        setValue={SetEventValue}
        getValues={getEventValues}
        handleSubmit={handleSubmitEvent}
        reset={resetEvent}
        errors={eventErrors}
        disabled={false}
      />
    </>
  );
};

export default Projects;
