import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm } from "../../../types/global";
import { GoodsIssueQuery, WarehousesQuery } from "../../../generated/inventory";
import GoodsAdjustmentHeader from "../../../components/Form/Inventory/GoodsAdjustment/Header";
import GoodsAdjustmentDetail from "../../../components/Form/Inventory/GoodsAdjustment/Detail";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsIssueQuery, unknown>>;
  data?: GoodsIssueQuery;
  warehouseData?: WarehousesQuery;
};

const DocumentInfoTab = ({
  control,
  disabled,
  errors,
  getValues,
  refetch,
  setValue,
  data,
  warehouseData,
  reset,
}: Props) => {
  return (
    <>
      <GoodsAdjustmentHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsIssue?.aggrid_status}
        refetch={refetch}
      />
      <GoodsAdjustmentDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        status={data?.goodsIssue?.aggrid_status}
      />
    </>
  );
};

export default DocumentInfoTab;
