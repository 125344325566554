import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IDefaultForm, ISelectOption } from "../../../../types/global";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import {
  InventoryDocumentType,
  InventoryUniqueIdQuery,
  ModelType,
  WarehouseQuery,
  useInventoryUniqueIdQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useCallback, useEffect, useState } from "react";
import { Controller, UseFieldArrayAppend, useWatch } from "react-hook-form";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import { useParams } from "react-router-dom";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { IGoodsIssue } from "../../../../types/Inventory/goodsIssue";
import {
  SalesItemListQuery,
  SalesOrdersUniqueIdQuery,
  SortOrder,
  useSalesItemListQuery,
  useSalesOrdersUniqueIdQuery,
} from "../../../../generated/sales";
import { itemListToTraceEntryListFormatter } from "../../../../utils/Formatter/Global";
import { ISalesItemList } from "../../../../types/Sales";
import { EntityTypeEnum } from "../../../../generated/creatable";
import {
  PurchaseDocumentType,
  PurchaseItemListQuery,
  PurchaseReturnsUniqueIdQuery,
  usePurchaseItemListQuery,
  usePurchaseReturnsUniqueIdQuery,
} from "../../../../generated/purchase";
import { IPurchaseItemList } from "../../../../types/Purchase";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { GraphQLClient } from "graphql-request";
import {
  ManufactureOrderQuery,
  ManufactureOrdersUniqueIdQuery,
  useManufactureOrderQuery,
  useManufactureOrdersUniqueIdQuery,
} from "../../../../generated/manufacture";
import { IManufactureOrder } from "../../../../types/Manufacture/order";
import { manufactureOrderToTraceEntryFormatter } from "../../../../utils/Formatter/ManufactureOrder";

const typeOptions: ISelectOption[] = [
  {
    label: "ขาย",
    value: "sales_order",
  },
  {
    label: "ผลิต",
    value: "manufacture_order",
  },
  {
    label: "ส่งคืน",
    value: "purchase_return",
  },
  {
    label: "อื่นๆ",
    value: "other",
  },
];

const StyledLink = styled(Link)<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: 8,
}));

type Props = IDefaultForm & {
  status?: string | null;
  allWarehouses?: WarehouseQuery["warehouse"][];
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
};

const GoodsIssueDetail = ({
  control,
  errors,
  setValue,
  getValues,
  reset,
  append,
  disabled,
  allWarehouses,
  status,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState<string>("");
  const [currentWarehouse, setCurrentWarehouse] = useState<string>("");
  const [currentReference, setCurrentReference] = useState<string>("");
  const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
    ISelectOption[]
  >([]);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchReferenceUniqueId = useWatch({
    control,
    name: "reference_unique_id",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { refetch } = useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
    graphQLClientWithHeaderItem,
    {
      modelType: ModelType.GoodsIssue,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { data: salesOrdersUniqueId, isFetching: isSalesOrderFetching } =
    useSalesOrdersUniqueIdQuery<SalesOrdersUniqueIdQuery>(
      graphQLClientWithHeaderSales,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            aggrid_status: {
              values: ["approved", "finished"],
              filterType: "set",
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: watchType === "sales_order",
      }
    );

  const { refetch: salesItemListRefetch } =
    useSalesItemListQuery<SalesItemListQuery>(
      graphQLClientWithHeaderSales,
      {
        whereInput: {
          reference_document_type: "sales_order",
          reference_unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const {
    data: purchaseReturnsUniqueId,
    isFetching: isPurchaseReturnFetching,
  } = usePurchaseReturnsUniqueIdQuery<PurchaseReturnsUniqueIdQuery>(
    graphQLClientWithHeaderPurchase,
    {
      aggridInput: {
        startRow: 0,
        endRow: 9999,
        filterModel: {
          aggrid_status: {
            values: ["approved", "finished"],
            filterType: "set",
          },
        },
        sortModel: [
          {
            sort: SortOrder.Desc,
            colId: "created_date",
          },
        ],
      },
    },
    {
      enabled: watchType === "purchase_return",
    }
  );

  const { refetch: purchaseItemListRefetch } =
    usePurchaseItemListQuery<PurchaseItemListQuery>(
      graphQLClientWithHeaderPurchase,
      {
        whereInput: {
          reference_document_type: PurchaseDocumentType.PurchaseReturn,
          reference_unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const { data: manufactureOrdersUniqueId, isFetching: isManufactureFetching } =
    useManufactureOrdersUniqueIdQuery<ManufactureOrdersUniqueIdQuery>(
      graphQLClientWithHeaderManufacture,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            aggrid_status: {
              values: ["pending_manu", "in_progress", "finished"],
              filterType: "set",
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: watchType === "manufacture_order",
      }
    );

  const { refetch: manufactureOrderRefetch } =
    useManufactureOrderQuery<ManufactureOrderQuery>(
      graphQLClientWithHeaderManufacture,
      {
        uniqueInput: {
          unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const resetTraceEntry = () => {
    if (reset) {
      reset((prev: IGoodsIssue) => ({
        ...prev,
        trace_entry_list: [],
      }));
    }
  };

  const onTypeChangeHandler = () => {
    setValue("type", currentType);
    setValue("reference_unique_id", "");
    resetTraceEntry();
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onTypeChangeHandler);

  const onReferenceChangeHandler = async () => {
    resetTraceEntry();
    if (currentReference) {
      await setValue("reference_unique_id", currentReference);

      if (currentType === "sales_order") {
        const { data } = await salesItemListRefetch();
        if (data?.salesItems) {
          const formatSalesOrderItemList = itemListToTraceEntryListFormatter(
            data.salesItems as ISalesItemList[],
            InventoryDocumentType.GoodsIssue,
            EntityTypeEnum.SalesOrder
          );
          append(formatSalesOrderItemList);
        }
      } else if (currentType === "purchase_return") {
        const { data } = await purchaseItemListRefetch();
        if (data?.purchaseItems) {
          const formatPurchaseReturnItemList =
            itemListToTraceEntryListFormatter(
              data.purchaseItems as IPurchaseItemList[],
              InventoryDocumentType.GoodsIssue,
              EntityTypeEnum.PurchaseReturn
            );
          append(formatPurchaseReturnItemList);
        }
      } else if (currentType === "manufacture_order") {
        const { data } = await manufactureOrderRefetch();
        if (data?.manufactureOrder) {
          const formatManufactureOrderItemList =
            manufactureOrderToTraceEntryFormatter(
              data.manufactureOrder as IManufactureOrder,
              undefined,
              InventoryDocumentType.GoodsIssue
            );
          append(formatManufactureOrderItemList);
        }
      }
    }
  };

  const {
    confirmation: referenceConfirmation,
    openConfirmationHandler: openReferenceConfirmationHandler,
    closeConfirmationHandler: closeReferenceConfirmationHandler,
    submitConfirmationHandler: submitReferenceConfirmationHandler,
  } = useConfirmation(onReferenceChangeHandler);

  const onWarehouseChangeHandler = () => {
    if (currentWarehouse) {
      setValue("source_warehouse_unique_id", currentWarehouse);
      resetTraceEntry();
    }
  };

  const {
    confirmation: warehouseConfirmation,
    openConfirmationHandler: openWarehouseConfirmationHandler,
    closeConfirmationHandler: closeWarehouseConfirmationHandler,
    submitConfirmationHandler: submitWarehouseConfirmationHandler,
  } = useConfirmation(onWarehouseChangeHandler);

  const warehouseOptions: ISelectOption[] =
    allWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  useEffect(() => {
    if (watchType === "sales_order") {
      setReferenceUniqueIdOptions(
        salesOrdersUniqueId?.salesOrdersFindManyAggrid.data.map(
          (salesOrder) => ({
            id: salesOrder.unique_id,
            label: salesOrder.unique_id,
            value: salesOrder.unique_id,
          })
        ) || []
      );
    } else if (watchType === "purchase_return") {
      setReferenceUniqueIdOptions(
        purchaseReturnsUniqueId?.purchaseReturnsFindManyAggrid?.data?.map(
          (purchaseReturn) => ({
            id: purchaseReturn?.unique_id || "",
            label: purchaseReturn?.unique_id || "",
            value: purchaseReturn?.unique_id || "",
          })
        ) || []
      );
    } else if (watchType === "manufacture_order") {
      setReferenceUniqueIdOptions(
        manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data?.map(
          (manufactureOrder) => ({
            id: manufactureOrder?.unique_id || "",
            label: manufactureOrder?.unique_id || "",
            value: manufactureOrder?.unique_id || "",
          })
        ) || []
      );
    } else {
      setReferenceUniqueIdOptions([]);
    }
  }, [
    watchType,
    salesOrdersUniqueId?.salesOrdersFindManyAggrid.data,
    purchaseReturnsUniqueId?.purchaseReturnsFindManyAggrid?.data,
    manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data,
  ]);

  const generateUniqueId = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const handleClickOpenRef = () => {
    if (watchType === "manufacture_order")
      return window.open(
        `/manufacture/order/` + watchReferenceUniqueId,
        "_blank"
      );
    else if (watchType === "sales_order")
      return window.open(`/sales/order/` + watchReferenceUniqueId, "_blank");
    else if (watchType === "purchase_return")
      return window.open(
        `/purchase/return/` + watchReferenceUniqueId,
        "_blank"
      );
  };

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Typography fontWeight="bold">{t("sentence.detail")}</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ControlledTextField
              control={control}
              name="unique_id"
              error={Boolean(errors && errors.unique_id)}
              helperText={errors && errors.unique_id?.message}
              label={t("sentence.unique_id")}
              disabled={disabled || status === "draft"}
              required
              onChange={(e, field) => {
                const trimmedValue = e.target.value
                  .replaceAll(" ", "")
                  .replaceAll(/\u00a0/g, "");
                field.onChange(trimmedValue);
              }}
            />
            {!id && (
              <CustomizedTooltip
                title="เรียกเลขที่เอกสารใหม่"
                enterNextDelay={200}
              >
                <IconButton
                  onClick={generateUniqueId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                label={t("sentence.type")}
                error={Boolean(errors && errors.type)}
                helperText={errors && errors.type?.message}
                disabled={disabled}
                options={typeOptions}
                onChange={(e: any) => {
                  setCurrentType(e.target.value);
                  setValue("reference_unique_id", "");
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openConfirmationHandler();
                  } else {
                    field.onChange(e);
                  }
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="reference_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={
                  isPurchaseReturnFetching ||
                  isSalesOrderFetching ||
                  isManufactureFetching
                }
                label={t("sentence.reference_unique_id")}
                error={Boolean(errors && errors.reference_unique_id)}
                helperText={errors && errors.reference_unique_id?.message}
                options={referenceUniqueIdOptions}
                onChange={async (_, newValue) => {
                  setCurrentReference(newValue.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openReferenceConfirmationHandler();
                  } else {
                    await field.onChange(newValue.value);
                    if (currentType === "sales_order") {
                      const { data } = await salesItemListRefetch();
                      if (data?.salesItems) {
                        const formatSalesOrderItemList =
                          itemListToTraceEntryListFormatter(
                            data.salesItems as ISalesItemList[],
                            InventoryDocumentType.GoodsIssue,
                            EntityTypeEnum.SalesOrder
                          );
                        append(formatSalesOrderItemList);
                      }
                    } else if (currentType === "purchase_return") {
                      const { data } = await purchaseItemListRefetch();
                      if (data?.purchaseItems) {
                        const formatPurchaseReturnItemList =
                          itemListToTraceEntryListFormatter(
                            data.purchaseItems as IPurchaseItemList[],
                            InventoryDocumentType.GoodsIssue,
                            EntityTypeEnum.PurchaseReturn
                          );
                        append(formatPurchaseReturnItemList);
                      }
                    } else if (currentType === "manufacture_order") {
                      const { data } = await manufactureOrderRefetch();
                      if (data?.manufactureOrder) {
                        const formatManufactureOrderItemList =
                          manufactureOrderToTraceEntryFormatter(
                            data.manufactureOrder as IManufactureOrder,
                            undefined,
                            InventoryDocumentType.GoodsIssue
                          );
                        append(formatManufactureOrderItemList);
                      }
                    }
                  }
                }}
                disabled={disabled || !watchType || watchType === "other"}
                sx={{
                  input: {
                    visibility:
                      watchType !== "other" && disabled ? "hidden" : "visible",
                  },
                }}
                InputProps={{
                  startAdornment: watchType !== "other" && disabled && (
                    <InputAdornment position="start">
                      <StyledLink onClick={handleClickOpenRef}>
                        {watchReferenceUniqueId}
                      </StyledLink>
                    </InputAdornment>
                  ),
                }}
                required={watchType && watchType !== "other"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="source_warehouse_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                label={t("inventory.warehouse")}
                error={Boolean(errors && errors.source_warehouse_unique_id)}
                helperText={
                  errors && errors.source_warehouse_unique_id?.message
                }
                disabled={disabled}
                options={warehouseOptions}
                onChange={(e: any) => {
                  setCurrentWarehouse(e.target.value);
                  const currentTrace = getValues("trace_entry_list");
                  const currentWarehouse = getValues(
                    "source_warehouse_unique_id"
                  );
                  if (currentWarehouse && currentTrace.length > 0) {
                    openWarehouseConfirmationHandler();
                  } else {
                    field.onChange(e);
                  }
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            error={Boolean(errors && errors.remark)}
            helperText={errors && errors.remark?.message}
            label={t("sentence.remark")}
            disabled={status === "finished" || status === "cancelled"}
          />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนประเภท"
        message="หากเปลี่ยนประเภทแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนเอกสารอ้างอิง"
        message="หากเปลี่ยนเอกสารอ้างอิงแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={referenceConfirmation}
        handleClose={closeReferenceConfirmationHandler}
        action={submitReferenceConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนคลัง"
        message="หากเปลี่ยนคลังแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={warehouseConfirmation}
        handleClose={closeWarehouseConfirmationHandler}
        action={submitWarehouseConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default GoodsIssueDetail;
