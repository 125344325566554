import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@mui/material";
import { useWatch, Controller, FieldArrayWithId } from "react-hook-form";

import { IDefaultForm } from "../../../../../types/global";

import ControlledTextField from "../../../../Controller/ControlledTextField";
import { parseFloatFunction } from "../../../../../utils/dataTransformer";
import { IItem } from "../../../../../types/Inventory/item";
import { calColorCount } from "../../../../../utils/Formatter/Item";

type ExtendedProps = IDefaultForm & {
  fields: FieldArrayWithId<IItem, "variation_list", "id">[];
  watchItemIsActive: boolean;
  openDisabledConfirmation: any;
  setSelectedSku: any;
  option_1: any;
  option_2: any;
  option_3: any;
};

const ThreeVariantTable = ({
  control,
  setValue,
  getValues,
  errors,
  option_1,
  option_2,
  option_3,
  disabled,
  watchItemIsActive,
  openDisabledConfirmation,
  setSelectedSku,
  fields,
}: ExtendedProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const watchTracability = useWatch({ control, name: "tracability" });

  const priceSX = {
    textAlign: "center",
    maxWidth: 120,
    minWidth: 70,
  };

  const tableHeader =
    watchTracability !== "normal"
      ? [
          option_1.key,
          option_2.key,
          option_3.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "Seller SKU",
          t("inventory.active"),
        ]
      : [
          option_1.key,
          option_2.key,
          option_3.key,
          t("inventory.variant.header.sales_price"),
          t("inventory.variant.header.special_price"),
          t("inventory.variant.header.selling_price"),
          "Seller SKU",
          "Barcode",
          t("inventory.active"),
        ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableHeader.map((header) => (
            <TableCell sx={{ textAlign: "center" }} key={header}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {fields &&
          fields.map((firstOption, firstInd) => (
            <Fragment key={firstOption.id}>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell
                  rowSpan={calColorCount(firstOption)}
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    // paddingBottom: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  {firstOption.name}
                </TableCell>
              </TableRow>
              {firstOption.value?.map((secondOption, secondInd) => (
                <Fragment key={secondOption.name || "" + secondInd}>
                  <TableRow>
                    <TableCell
                      rowSpan={
                        secondOption &&
                        secondOption.value &&
                        secondOption.value.length + 1
                      }
                      sx={{
                        textAlign: "center",
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {secondOption.name}
                    </TableCell>
                  </TableRow>
                  {secondOption.value?.map((thirdOption, thirdInd) => {
                    return (
                      <Fragment key={thirdOption.name || "" + thirdInd}>
                        <TableRow>
                          <TableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {thirdOption.name}
                          </TableCell>
                          <TableCell sx={priceSX}>
                            <ControlledTextField
                              control={control}
                              name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].sale_price`}
                              type="number"
                              disabled={!thirdOption.is_active || disabled}
                              onChange={parseFloatFunction}
                            />
                          </TableCell>
                          <TableCell sx={priceSX}>
                            <ControlledTextField
                              control={control}
                              name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].special_price`}
                              type="number"
                              disabled={!thirdOption.is_active || disabled}
                              onChange={parseFloatFunction}
                            />
                          </TableCell>
                          <TableCell sx={priceSX}>
                            <ControlledTextField
                              control={control}
                              name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].cost_price`}
                              type="number"
                              disabled={!thirdOption.is_active || disabled}
                              onChange={parseFloatFunction}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              minWidth: 250,
                            }}
                          >
                            <ControlledTextField
                              control={control}
                              name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].seller_sku_name`}
                              disabled={!thirdOption.is_active || disabled}
                            />
                          </TableCell>
                          {watchTracability === "normal" && (
                            <TableCell
                              sx={{
                                textAlign: "center",
                                minWidth: 250,
                              }}
                            >
                              <ControlledTextField
                                control={control}
                                name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].barcode`}
                                disabled={!thirdOption.is_active || disabled}
                                error={
                                  errors?.variation_list?.[firstInd]?.value?.[
                                    secondInd
                                  ]?.value?.[thirdInd]?.barcode
                                }
                                helperText={
                                  errors?.variation_list?.[firstInd]?.value?.[
                                    secondInd
                                  ]?.value?.[thirdInd]?.barcode?.message
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Controller
                              control={control}
                              name={`variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].is_active`}
                              render={({ field }) => (
                                <Box
                                  sx={{
                                    cursor:
                                      !disabled && Boolean(!watchItemIsActive)
                                        ? "pointer"
                                        : "default",
                                  }}
                                  onClick={
                                    !disabled && Boolean(!watchItemIsActive)
                                      ? () => {
                                          enqueueSnackbar(
                                            "หากต้องการเปิดการใช้งานของตัวเลือก\nกรุณาเปิดการใช้งานสถานะในหน้าทั่วไปของสินค้าก่อน",
                                            {
                                              variant: "error",
                                              style: {
                                                whiteSpace: "pre-line",
                                              },
                                            }
                                          );
                                        }
                                      : () => {}
                                  }
                                >
                                  <Switch
                                    checked={field.value}
                                    onChange={() => {
                                      if (!field.value)
                                        return field.onChange(!field.value);
                                      openDisabledConfirmation();
                                      setSelectedSku({
                                        name: getValues(
                                          `variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].sku_name`
                                        ),
                                        position: `variation_list[${firstInd}].value[${secondInd}].value[${thirdInd}].is_active`,
                                        current_value: field.value,
                                      });
                                    }}
                                    disabled={disabled || !watchItemIsActive}
                                  />
                                </Box>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </Fragment>
          ))}
      </TableBody>
    </Table>
  );
};

export default ThreeVariantTable;
