import { useEffect, useState } from "react";

export const useCreateOptions = (status: string) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    switch (status) {
      case "pending_manu":
      case "in_progress":
      case "finished":
        setDisabled(false);
        break;
      default:
        setDisabled(true);
    }
  }, [status]);
  return disabled;
};
