import React from "react";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../components/Custom/CustomizedButton";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography variant="h5">คุณไม่มีสิทธิ์เข้าถึงข้อมูล</Typography>
      <Typography>กรุณาติดต่อเจ้าหน้าที่เพื่อขอสิทธิ์การเข้าถึง</Typography>
      <CustomizedButton
        variant="outlined"
        title="กลับหน้าหลัก"
        sx={{ mt: 3, mb: 10 }}
        onClick={() => navigate("/landing")}
      />
      <img src="/static/notFoundPic.svg" alt="Not Found"></img>
    </Box>
  );
};

export default Unauthorized;
