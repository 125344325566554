import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";
import CheckboxModalTable from "./CheckboxModalTable";
import {
  GetRowIdParams,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
// import { useStateContext } from "../../contexts/auth-context";
import { contactColumnDefs } from "./Contacts/columnDefs";
import { CONTACTS_AGGRID } from "../../services/AgGrid/ContactAgGrid";
import {
  ContactEntityType,
  ContactTagsFindByEntityQuery,
  useContactTagsFindByEntityQuery,
} from "../../generated/contact";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

type CheckedContactModalProps = {
  showSelectContact: boolean;
  closeContactTable: () => void;
  finishContactsSelect: (data: any) => void;
  contactIds: string[];
  setContactIds: Dispatch<SetStateAction<string[]>>;
  contactIdsSnapshot: string[];
  setContactIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  type: "customer" | "vendor";
};

const CheckedContactModal = ({
  showSelectContact,
  closeContactTable,
  finishContactsSelect,
  contactIds,
  setContactIds,
  contactIdsSnapshot,
  setContactIdsSnapshot,
  type,
}: CheckedContactModalProps) => {
  const { t } = useTranslation();
  const selectContactGridRef = useRef();

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const { data: tagList } =
    useContactTagsFindByEntityQuery<ContactTagsFindByEntityQuery>(
      graphQLClientWithHeaderContact,
      {
        entityName: ContactEntityType.Contact,
      }
    );

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const formatFilter = {
        ...filterModel,
        is_customer:
          type === "customer"
            ? {
                filterType: "boolean",
                type: "equals",
                filter: true,
              }
            : undefined,
        is_vendor:
          type === "vendor"
            ? {
                filterType: "boolean",
                type: "equals",
                filter: true,
              }
            : undefined,
      };
      console.log(formatFilter);
      try {
        const { contactsFindManyAggrid } =
          await graphQLClientWithHeaderContact.request(CONTACTS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: contactsFindManyAggrid.data as any[],
          rowCount: contactsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data.unique_id;
  }, []);

  return (
    <CheckboxModalTable
      modalTitle={
        type === "customer" ? t("sales.customer") : t("purchase.vendor")
      }
      btnTitle={t("button.add")}
      gridRef={selectContactGridRef}
      height={450}
      columnDefs={contactColumnDefs(
        t,
        undefined,
        tagList?.contactTagsFindByEntity ?? []
      )}
      rowSelection="single"
      onFinishEditing={finishContactsSelect}
      modalIsOpen={showSelectContact}
      getRowId={getRowId}
      closeModal={closeContactTable}
      selectedIds={contactIds}
      setSelectedIds={setContactIds}
      idsSnapshot={contactIdsSnapshot}
      setIdsSnapshot={setContactIdsSnapshot}
      onGridReady={onGridReady}
    />
  );
};

export default CheckedContactModal;
