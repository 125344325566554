import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedMenuList from "../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../types/global";
import DashboardSectionContainer from "../components/UI/Dashboard/operationalDashboard/DashboardSectionContainer";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CabinOutlinedIcon from "@mui/icons-material/CabinOutlined";
// import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import { useStateContext } from "../contexts/auth-context";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

const Landing = () => {
  const { t } = useTranslation();
  const {
    state: { permissions },
  } = useStateContext();

  const menuList: IBreadcrumbsAndMenu[] = [
    permissions?.contact.view === "ALLOW" && {
      name: t("contact.index"),
      to: "/contact",
      icon: <PeopleOutlineOutlinedIcon color="primary" />,
    },
    permissions?.inventory.view === "ALLOW" && {
      name: t("inventory.index"),
      to: "/inventory",
      icon: <Inventory2OutlinedIcon color="primary" />,
    },
    // permissions?.project.view === "ALLOW" && {
    //   name: t("project.index"),
    //   to: "/project",
    //   icon: <AddBusinessOutlinedIcon color="primary" />,
    // },
    permissions?.sales.view === "ALLOW" && {
      name: t("sales.index"),
      to: "/sales",
      icon: <ControlPointOutlinedIcon color="primary" />,
    },
    permissions?.purchase.view === "ALLOW" && {
      name: t("purchase.index"),
      to: "/purchase",
      icon: <AddShoppingCartOutlinedIcon color="primary" />,
    },
    permissions?.delivery.view === "ALLOW" && {
      name: t("logistic.index"),
      to: "/logistic",
      icon: <LocalShippingOutlinedIcon color="primary" />,
    },
    permissions?.manufacture.view === "ALLOW" && {
      name: t("manufacture.index"),
      to: "/manufacture",
      icon: <CabinOutlinedIcon color="primary" />,
    },
    {
      name: t("user.index"),
      to: "/user",
      icon: <PeopleOutlineOutlinedIcon color="primary" />,
    },
    permissions?.setting.view === "ALLOW" && {
      name: t("setting.index"),
      to: "/setting",
      icon: <SettingsOutlinedIcon color="primary" />,
    },
  ].filter(Boolean) as IBreadcrumbsAndMenu[];

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <>
      <Typography fontWeight="bold">หน้าแรก</Typography>
      {isMobile && <CustomizedMenuList menuList={menuList} />}
      <DashboardSectionContainer />
    </>
  );
};

export default Landing;
