import * as Yup from "yup";
import { defaultPermission } from "../../../utils/userInfo";

export const userSchema = {
  unique_id: "",
  title_name: "นาย",
  nick_name: "",
  first_name: "",
  last_name: "",
  img_url: [],
  phone: "",
  email: "",
  address: "",
  dob: new Date(),
  naional_id: "",
  department: "",
  position: "",
  organization: "",
  new_password: "",
  confirm_new_password: "",
  old_password: "",
  is_active: 1,
  role_list: [],
  permission_list: defaultPermission,
};

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const validation = Yup.object().shape({
  first_name: Yup.string().required("กรุณากรอก"),
  last_name: Yup.string().required("กรุณากรอก"),
  email: Yup.string().email("กรุณากรอกอีเมลให้ถูกต้อง").required("กรุณากรอก"),
  department: Yup.string().required("กรุณากรอก").nullable(),
  position: Yup.string().required("กรุณากรอก").nullable(),
  phone: Yup.string().matches(phoneRegExp, "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง"),
  // img_url: Yup.string().required("กรุณากรอก"),
  new_password: Yup.string()
    .min(8, "กรุณาระบุมากกว่า 8 ตัว")
    .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
    .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
      `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
    )
    .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว"),
  // is_active: Yup.boolean().required("กรุณากรอก"),
  confirm_new_password: Yup.string().oneOf(
    [Yup.ref("new_password"), undefined],
    "รหัสผ่านไม่ตรงกัน"
  ),
});

export const validationEdit = Yup.object().shape({
  first_name: Yup.string().required("กรุณากรอก"),
  last_name: Yup.string().required("กรุณากรอก"),
  email: Yup.string().email("กรุณากรอกอีเมลให้ถูกต้อง").required("กรุณากรอก"),
  department: Yup.string().required("กรุณากรอก").nullable(),
  position: Yup.string().required("กรุณากรอก").nullable(),
  phone: Yup.string().matches(phoneRegExp, "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง"),
  // img_url: Yup.string().required("กรุณากรอก"),
  old_password: Yup.string(),
  new_password: Yup.string().when("old_password", (old_password) =>
    old_password.some((val) => val !== "")
      ? Yup.string()
          .min(8, "กรุณาระบุมากกว่า 8 ตัว")
          .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
          .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
          .matches(
            // eslint-disable-next-line no-useless-escape
            /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
            `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
          )
          .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว")
      : Yup.string()
  ),
  // new_password: Yup.string()
  //   .min(8, "กรุณาระบุมากกว่า 8 ตัว")
  //   .matches(/[a-z]+/, "ตัวอักษรพิมพ์เล็กอย่างน้อย 1 ตัว")
  //   .matches(/[A-Z]+/, "ตัวอักษรพิมพ์ใหญ่อย่างน้อย 1 ตัว")
  //   .matches(
  //     // eslint-disable-next-line no-useless-escape
  //     /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
  //     `อักขระพิเศษ (!@#$%^&*()_+|~-=\`{}[]:";'<>?,./) อย่างน้อย 1 ตัว`
  //   )
  //   .matches(/\d+/, "ตัวเลขอย่างน้อย 1 ตัว"),
  // is_active: Yup.boolean().required("กรุณากรอก"),
  confirm_new_password: Yup.string().oneOf(
    [Yup.ref("new_password"), undefined],
    "รหัสผ่านไม่ตรงกัน"
  ),
});
