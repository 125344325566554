import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { UseFormHandleSubmit, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm, IMenuOption } from "../../../../types/global";

import { GraphQLClient } from "graphql-request";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import { useDeliveryOrderOption } from "../../../../hooks/Logistic/use-delivery-order-options";
import { useDeliveryOrderCreate } from "../../../../hooks/Logistic/use-delivery-order-create";

import CustomizedChips from "../../../Custom/CustomizedChips";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import EmployeeListForm from "../../EmployeeList";
import Confirmation from "../../../UI/Confirmation";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";

import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  DeliveryDocumentType,
  DeliveryOrderQuery,
  LogisticUniqueIdQuery,
  ModelType,
  useLogisticDocumentCancelMutation,
  useLogisticUniqueIdQuery,
} from "../../../../generated/logistic";
import CustomizedButton from "../../../Custom/CustomizedButton";
import ModalUI from "../../../UI/ModalUI";
import { useModal } from "../../../../hooks/use-modal";
import DeliveryDetails from "./Details";
import { IDeliveryOrder } from "../../../../types/Logistic/deliveryOrder";
import { copyDeliveryFormatter } from "../../../../utils/Formatter/DeliveryOrder";
import StepperUI from "../../../UI/StepperUI";
import { useActiveStep } from "../../../../hooks/use-active-step";

type Props = IDefaultForm & {
  editClickHandler: () => void;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<DeliveryOrderQuery, unknown>>;
  onDeliveryOrderSubmitDetail: (data: IDeliveryOrder) => void;
  handleSubmit: UseFormHandleSubmit<IDeliveryOrder>;
};

const DeliveryOrderHeader = ({
  control,
  errors,
  setValue,
  disabled,
  getValues,
  refetch,
  editClickHandler,
  handleSubmit,
  onDeliveryOrderSubmitDetail,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [createdDateIsOpen, setCreatedDateIsOpen] = useState<boolean>(false);
  const [issueDateIsOpen, setIssueDateIsOpen] = useState<boolean>(false);
  const [deliveryDateIsOpen, setDeliveryDateIsOpen] = useState<boolean>(false);

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const { refetch: getUniqueId } =
    useLogisticUniqueIdQuery<LogisticUniqueIdQuery>(
      graphQLClientWithHeaderLogistic,
      {
        modelType: ModelType.DeliveryOrder,
      },
      {
        enabled: false,
        onSuccess: ({ utilGetUniqueId }) => {
          setValue("unique_id", utilGetUniqueId);
        },
      }
    );

  const { mutate: cancel } = useLogisticDocumentCancelMutation<Error>(
    graphQLClientWithHeaderLogistic,
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("ยกเลิกสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  //   const {
  //     state: { authUser },
  //   } = useStateContext();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const reference_unique_id_list = useWatch({
    control,
    name: "reference_unique_id_list",
  });

  const printOptions: IMenuOption[] = [
    {
      value: `พิมพ์${t("logistic.delivery_order.index")}`,
      disabled: !id,
    },
  ];

  const watchMainStatus = useWatch({ control, name: "main_status" });

  const steps = [
    {
      label: t("status.draft"),
      value: "draft",
    },
    {
      label: t("status.wait_deliver"),
      value: "wait_deliver",
    },
    {
      label: t("status.completed"),
      value: "completed",
    },
  ];

  const {
    activeStep,
    percentage,
    error: stepperError,
  } = useActiveStep(
    status,
    watchMainStatus,
    DeliveryDocumentType.DeliveryOrder
  );

  const selectModifyOptions = useDeliveryOrderOption(status);
  const { selectCreateOptions, disabledCreateOptions } =
    useDeliveryOrderCreate(status);

  const generateUniqueId = useCallback(() => {
    getUniqueId();
  }, [getUniqueId]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const cancelDocumentHandler = () => {
    cancel({
      documentInput: {
        reference_document_type: DeliveryDocumentType.DeliveryOrder,
        unique_id: id ? id : "",
      },
    });
  };

  const copyDocumentHandler = () => {
    const deliveryOrder = getValues();
    navigate("/logistic/delivery_order/add", {
      state: copyDeliveryFormatter(deliveryOrder),
    });
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(cancelDocumentHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyDocumentHandler);

  const {
    modal: modalDeliveryDetails,
    openModalHandler,
    closeModalHandler,
  } = useModal();

  console.log(openDrawer);

  return (
    <Fragment>
      <Grid container mb={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={2}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              {t("logistic.delivery_order.index")}
            </Typography>
            <CustomizedStatus status={status} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={5.5} md={4} lg={2.5}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === "พิมพ์ใบส่งของ") {
                    navigate(
                      `/logistic/delivery_order/${encodeURIComponent(
                        id ?? ""
                      )}/pdf`
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} md={4} lg={2.5}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "แก้ไข":
                      editClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ยกเลิก":
                      openConfirmationHandler();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} md={4} lg={2.5}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={"สร้าง"}
                options={selectCreateOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "รอบจัดส่ง":
                      console.log("สร้างรอบจัดส่ง");
                      break;
                    default:
                      break;
                  }
                }}
                disabled={disabledCreateOptions}
              />
            </Grid>
            {status === "wait_deliver" && (
              <Grid item sm={6} md={4} lg={3}>
                <CustomizedButton
                  fullWidth
                  size="medium"
                  title="บันทึกการจัดส่ง"
                  variant="contained"
                  onClick={openModalHandler}
                  sx={{ padding: "6px 0" }}
                />
              </Grid>
            )}
            <Grid item xs={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StepperUI
        steps={steps}
        activeStep={activeStep}
        error={stepperError}
        percentageProgress={percentage}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mb={3}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("purchase.unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <ControlledTextField
                name="unique_id"
                control={control}
                error={Boolean(errors?.unique_id)}
                helperText={errors?.unique_id && errors?.unique_id.message}
                disabled={!!id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3}>
              {!id && (
                <CustomizedTooltip
                  title="เรียกเลขที่เอกสารใหม่"
                  enterNextDelay={200}
                >
                  <IconButton
                    onClick={generateUniqueId}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <RestartAltOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("purchase.reference_unique_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              {reference_unique_id_list && reference_unique_id_list.length > 0
                ? reference_unique_id_list.map(
                    (reference_unique_id: string) => (
                      <CustomizedChips
                        key={reference_unique_id}
                        onClick={() =>
                          window.open(
                            `/sales/order/${encodeURIComponent(
                              reference_unique_id
                            )}`,
                            "_blank"
                          )
                        }
                        sx={{ mr: 0.5 }}
                        value={reference_unique_id}
                        color="primary"
                      />
                    )
                  )
                : "-"}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("purchase.external_reference_id")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} xl={6}>
              <ControlledTextField
                name="external_reference_id"
                control={control}
                error={Boolean(errors?.external_reference_id)}
                helperText={
                  errors?.external_reference_id &&
                  errors?.external_reference_id.message
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <Typography fontWeight="bold">
                {t("purchase.employee_list")}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={9} xl={9}>
              <EmployeeListForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                disabled={disabled}
                errors={errors}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateIsOpen}
                onClose={() => setCreatedDateIsOpen(false)}
                onOpen={() => setCreatedDateIsOpen(true)}
                label={t("date.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="issue_date"
                control={control}
                error={errors.issue_date}
                open={issueDateIsOpen}
                onClose={() => setIssueDateIsOpen(false)}
                onOpen={() => setIssueDateIsOpen(true)}
                label={t("date.issue_date")}
                disabled={disabled}
                required
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
              <ControlledDatePicker
                name="delivery_date"
                control={control}
                error={errors.delivery_date}
                open={deliveryDateIsOpen}
                onClose={() => setDeliveryDateIsOpen(false)}
                onOpen={() => setDeliveryDateIsOpen(true)}
                label={t("date.delivery_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 0.5 }}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledTextField
                type="number"
                label={t("purchase.credit_day")}
                control={control}
                name="credit_day"
                error={Boolean(errors?.credit_day)}
                helperText={errors?.credit_day && errors?.credit_day?.message}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalUI
        maxWidth="sm"
        title={t("logistic.delivery_order.delivery_details")}
        open={modalDeliveryDetails}
        handleClose={closeModalHandler}
      >
        <DeliveryDetails
          disabled={status === "completed" || status === "not_completed"}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          onSubmit={onDeliveryOrderSubmitDetail}
          closeModalHandler={closeModalHandler}
          status={status}
          handleSubmit={handleSubmit}
        />
      </ModalUI>
      <Confirmation
        title="ยืนยันหากต้องการยกเลิกเอกสาร"
        message="หากยกเลิกแล้ว จะไม่สามารถเปลี่ยนแปลงได้"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการคัดลอกเอกสาร"
        open={copyConfirmation}
        handleClose={closeCopyConfirmation}
        action={submitCopyConfirmation}
      />
    </Fragment>
  );
};

export default DeliveryOrderHeader;
