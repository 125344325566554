import { useEffect, useState } from "react";
import { IComboBoxOption } from "../../types/global";
import { EntityTypeEnum } from "../../generated/creatable";

const salesDocumentOptions = [
  { id: 0, label: "ทั้งหมด", value: "ALL" },
  { id: 1, label: "ใบเสนอราคา", value: EntityTypeEnum.Quotation },
  { id: 2, label: "ใบสั่งขาย", value: EntityTypeEnum.SalesOrder },
  { id: 3, label: "ใบรับคืน", value: EntityTypeEnum.SalesReturn },
];

const purchaseDocumentOptions = [
  { id: 0, label: "ทั้งหมด", value: "ALL" },
  { id: 1, label: "ใบขอซื้อ", value: EntityTypeEnum.PurchaseRequest },
  { id: 2, label: "ใบสั่งซื้อ", value: EntityTypeEnum.PurchaseOrder },
  { id: 3, label: "ใบส่งคืน", value: EntityTypeEnum.PurchaseReturn },
];

const deliveryDocumentOptions = [
  { id: 0, label: "ทั้งหมด", value: "ALL" },
  { id: 1, label: "ใบส่งของ", value: EntityTypeEnum.DeliveryOrder },
  { id: 2, label: "รอบจัดส่ง", value: EntityTypeEnum.DeliveryTrip },
];
const manufactureDocumentOptions = [
  { id: 0, label: "ทั้งหมด", value: "ALL" },
  { id: 1, label: "ใบสั่งผลิต", value: EntityTypeEnum.ManufactureOrder },
];

export const useProjectDocumentOption = (tab?: string) => {
  const [options, setOptions] = useState<IComboBoxOption[]>([]);

  useEffect(() => {
    switch (tab) {
      case "sales":
        return setOptions(salesDocumentOptions);
      case "purchase":
        return setOptions(purchaseDocumentOptions);
      case "logistic":
        return setOptions(deliveryDocumentOptions);
      case "manufacture":
        return setOptions(manufactureDocumentOptions);
      default:
        setOptions([]);
        break;
    }
  }, [tab]);

  return options;
};
