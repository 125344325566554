import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";

import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import GoodsReceiveTab from "./GoodsReceiveTab";
import GoodsIssueTab from "./GoodsIssueTab";

const ManufactureOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("manufacture.order.index"),
      to: "/manufacture/order",
    },
    {
      name: id ?? `สร้าง${t("manufacture.order.index")}`,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: "ใบ" + t("inventory.goods_issue.index"),
      path: `${pathname}?tab=goods-issue`,
      disabled: !id,
    },
    {
      label: "ใบ" + t("inventory.goods_receive.index"),
      path: `${pathname}?tab=goods-receive`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "goods-receive":
        setCurrentTab(pathname + "?tab=goods-receive");
        break;
      case "goods-issue":
        setCurrentTab(pathname + "?tab=goods-issue");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab?: string | null) => {
    switch (tab) {
      case "goods-receive":
        return <GoodsReceiveTab />;
      case "goods-issue":
        return <GoodsIssueTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </Fragment>
  );
};

export default ManufactureOrderContainer;
