import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { IDefaultForm } from "../../../types/global";

import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import BundleTable from "../../../components/Table/Inventory/Item/Bundle";
import { Dispatch, SetStateAction } from "react";

type ExtendedProps = IDefaultForm & {
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
};

const BundleTab = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
}: ExtendedProps) => {
  const { t } = useTranslation();

  return (
    <CustomizedBox>
      <Typography fontWeight="bold">
        {t("inventory.items.item_bundle")}
      </Typography>
      <BundleTable
        control={control}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        disabled={disabled}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
      />
    </CustomizedBox>
  );
};

export default BundleTab;
