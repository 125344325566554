import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";

import { IBreadcrumbsAndMenu } from "../../../types/global";
import { IWarehouse } from "../../../types/Setting/inventory";
import {
  WarehousesQuery,
  useWarehouseBinLocationCreateMutation,
  useWarehouseCreateMutation,
  useWarehouseLevel1CreateMutation,
  useWarehouseLevel2CreateMutation,
  useWarehouseLevel3CreateMutation,
  useWarehouseBinLocationUpdateMutation,
  useWarehouseUpdateMutation,
  useWarehouseLevel1UpdateMutation,
  useWarehouseLevel2UpdateMutation,
  useWarehouseLevel3UpdateMutation,
  useWarehouseBinLocationDeleteMutation,
  useWarehouseDeleteMutation,
  useWarehouseLevel1DeleteMutation,
  useWarehouseLevel2DeleteMutation,
  useWarehouseLevel3DeleteMutation,
  useWarehousesQuery,
  useWarehouseUniqueIdQuery,
} from "../../../generated/inventory";
import { useSnackbar } from "notistack";
import ModalUI from "../../../components/UI/ModalUI";
import ControlledTextField from "../../../components/Controller/ControlledTextField";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { characterRegExp } from "../../../utils/Formatter/Global";

export const errorMessageFormatter = (error: any) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("is in use")) {
    return "ไม่สามารถลบได้เนื่องจากถูกใช้งานอยู่";
  } else if (
    formatError.includes("Foreign key constraint failed on the field")
  ) {
    return "ไม่สามารถลบได้ กรุณาลบสถานที่ย่อยทั้งหมดก่อน";
  } else if (formatError.includes("Unique constraint failed on the fields")) {
    return "ไม่สามารถเพิ่มได้เนื่องจากมีข้อมูลอยู่แล้วในระบบ";
  }
};

const validation = Yup.object().shape({
  value: Yup.string()
    .required("กรุณากรอก")
    .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
  unique_id: Yup.string().required("กรุณากรอก"),
});

interface IDefault {
  value: string;
  unique_id: string;
}

const WarehouseSetting = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [allLocation, setAllLocation] = useState<IWarehouse[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any[]>();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.warehouse.index"),
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isSuccess, refetch } = useWarehousesQuery<WarehousesQuery>(
    graphQLClientWithHeaderItem
  );

  const { mutate: warehouseCreate } = useWarehouseCreateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data) {
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    }
  );

  const { mutate: warehouseLv1Create } =
    useWarehouseLevel1CreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv2Create } =
    useWarehouseLevel2CreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv3Create } =
    useWarehouseLevel3CreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: binlocationCreate } =
    useWarehouseBinLocationCreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("สร้างสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseUpdate } = useWarehouseUpdateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data) {
        enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const { refetch: getUniqueId } = useWarehouseUniqueIdQuery(
    graphQLClientWithHeaderItem
  );

  const { mutate: warehouseLv1Update } =
    useWarehouseLevel1UpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv2Update } =
    useWarehouseLevel2UpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv3Update } =
    useWarehouseLevel3UpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const { mutate: binlocationUpdate } =
    useWarehouseBinLocationUpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("แก้ไขสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar("แก้ไขสถานที่ไม่สำเร็จ", {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseDelete } = useWarehouseDeleteMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data) {
        enqueueSnackbar("ลบสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error, variables, context) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    }
  );

  const { mutate: warehouseLv1Delete } =
    useWarehouseLevel1DeleteMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("ลบสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv2Delete } =
    useWarehouseLevel2DeleteMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("ลบสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: warehouseLv3Delete } =
    useWarehouseLevel3DeleteMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("ลบสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  const { mutate: binlocationDelete } =
    useWarehouseBinLocationDeleteMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess(data) {
        enqueueSnackbar("ลบสถานที่สำเร็จ", {
          variant: "success",
        });
        handleClose();
      },
      onError(error) {
        enqueueSnackbar(errorMessageFormatter(error), {
          variant: "error",
        });
      },
    });

  useEffect(() => {
    if (isSuccess) {
      const warehouses = data.warehouses as IWarehouse[];
      setAllLocation(warehouses);
    }
  }, [data, isSuccess]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IDefault>({
    defaultValues: {
      unique_id: "",
      value: "",
    },
    resolver: yupResolver(validation),
  });

  const getMergePrefixDocumentId = async (
    selectedLocation: any,
    value?: string
  ) => {
    if (
      (selectedLocation && selectedLocation.length === 0) ||
      selectedLocation === undefined
    ) {
      const { data } = await getUniqueId();
      return data?.utilGetWarehouseUniqueId || "";
    } else if (selectedLocation && selectedLocation.length === 1) {
      return selectedLocation[0].unique_id + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 2) {
      return selectedLocation[1].sub_level_1_name + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 3) {
      return selectedLocation[2].sub_level_2_name + "-" + (value ?? "");
    } else if (selectedLocation && selectedLocation.length === 4) {
      return selectedLocation[3].sub_level_3_name + "-" + (value ?? "");
    }
    return "";
  };

  const handleOpen = async ({
    title,
    location,
  }: {
    title: string;
    location?: any;
  }) => {
    if (title === "addLocation") {
      setValue("value", "");
      const new_location = (await getMergePrefixDocumentId(location)) || "";
      setValue("unique_id", new_location);
    } else {
      setValue(
        "unique_id",
        location[location.length - 1].unique_id ??
          location[location.length - 1].sub_level_1_name ??
          location[location.length - 1].sub_level_2_name ??
          location[location.length - 1].sub_level_3_name ??
          location[location.length - 1].bin_name
      );
      setValue(
        "value",
        location[location.length - 1].name ??
          location[location.length - 1].sub_level_1_name ??
          location[location.length - 1].sub_level_2_name ??
          location[location.length - 1].sub_level_3_name ??
          location[location.length - 1].bin_name
      );
    }
    if (location) setSelectedLocation(location);
    setTitle(title);
    setModal(true);
  };

  const handleClose = async () => {
    setModal(false);
    refetch();
    setTimeout(() => {
      setTitle("");
      setSelectedLocation(undefined);
    }, 150);
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  const onSubmit = (data: IDefault) => {
    switch (title) {
      case "addLocation":
        handleSubmitCreate(data);
        break;
      case "editLocation":
        handleSubmitUpdate(data);
        break;
      case "deleteLocation":
        handleSubmitDelete();
        break;
      default:
        break;
    }
  };

  const handleSubmitCreate = async (data: IDefault) => {
    if (selectedLocation?.length === 0 || !selectedLocation) {
      const input = {
        unique_id: data.unique_id,
        name: data.value,
      };
      warehouseCreate({ createInput: input });
    } else if (selectedLocation?.length === 1) {
      const input = {
        sub_level_1_name: data.unique_id,
        warehouse_unique_id: selectedLocation[0].unique_id,
      };
      warehouseLv1Create({ createInput: input });
    } else if (selectedLocation?.length === 2) {
      const input = {
        sub_level_2_name: data.unique_id,
        sub_level_1_id: selectedLocation[1].id ?? 0,
      };
      warehouseLv2Create({ createInput: input });
    } else if (selectedLocation?.length === 3) {
      const input = {
        sub_level_3_name: data.unique_id,
        sub_level_2_id: selectedLocation[2].id ?? 0,
      };
      warehouseLv3Create({ createInput: input });
    } else if (selectedLocation?.length === 4) {
      const input = {
        bin_name: data.unique_id,
        sub_level_3_id: selectedLocation[3].id ?? 0,
      };
      binlocationCreate({ createInput: input });
    }
  };

  const handleSubmitUpdate = async (data: IDefault) => {
    if (selectedLocation?.length === 1) {
      const input = {
        updateInput: {
          name: data.value,
        },
        uniqueInput: {
          unique_id: selectedLocation[0].unique_id,
        },
      };
      warehouseUpdate(input);
    } else if (selectedLocation?.length === 2) {
      const input = {
        updateInput: {
          sub_level_1_name: data.value,
        },
        uniqueInput: {
          id: selectedLocation[1].id,
        },
      };
      warehouseLv1Update(input);
    } else if (selectedLocation?.length === 3) {
      const input = {
        updateInput: {
          sub_level_2_name: data.value,
        },
        uniqueInput: {
          id: selectedLocation[2].id,
        },
      };
      warehouseLv2Update(input);
    } else if (selectedLocation?.length === 4) {
      const input = {
        updateInput: {
          sub_level_3_name: data.value,
        },
        uniqueInput: {
          id: selectedLocation[3].id,
        },
      };
      warehouseLv3Update(input);
    } else if (selectedLocation?.length === 5) {
      const input = {
        updateInput: {
          bin_name: data.value,
        },
        uniqueInput: {
          bin_name: selectedLocation[4].bin_name,
        },
      };
      binlocationUpdate(input);
    }
  };

  const handleSubmitDelete = async () => {
    if (selectedLocation?.length === 1) {
      const input = {
        uniqueInput: {
          unique_id: selectedLocation[0].unique_id,
        },
      };
      warehouseDelete(input);
    } else if (selectedLocation?.length === 2) {
      const input = {
        uniqueInput: {
          id: selectedLocation[1].id,
        },
      };
      warehouseLv1Delete(input);
    } else if (selectedLocation?.length === 3) {
      const input = {
        uniqueInput: {
          id: selectedLocation[2].id,
        },
      };
      warehouseLv2Delete(input);
    } else if (selectedLocation?.length === 4) {
      const input = {
        uniqueInput: {
          id: selectedLocation[3].id,
        },
      };
      warehouseLv3Delete(input);
    } else if (selectedLocation?.length === 5) {
      const input = {
        uniqueInput: {
          bin_name: selectedLocation[4].bin_name,
        },
      };
      binlocationDelete(input);
    }
  };

  const handleExpandClick = () => {
    const allId: string[] = [];
    allLocation.forEach((warehouse) => {
      if (
        warehouse.warehouse_level_1_list &&
        warehouse.warehouse_level_1_list.length > 0
      ) {
        allId.push(warehouse.unique_id);
        warehouse.warehouse_level_1_list.forEach((locationLv1) => {
          if (
            locationLv1.sub_level_2_list &&
            locationLv1.sub_level_2_list.length > 0
          ) {
            allId.push(warehouse.unique_id + locationLv1.id);
            locationLv1.sub_level_2_list.forEach((locationLv2) => {
              if (
                locationLv2.sub_level_3_list &&
                locationLv2.sub_level_3_list.length > 0
              ) {
                allId.push(
                  warehouse.unique_id + locationLv1.id + locationLv2.id
                );
                locationLv2.sub_level_3_list.forEach((locationLv3) => {
                  if (locationLv3.bin_location_list.length > 0)
                    allId.push(
                      warehouse.unique_id +
                        locationLv1.id +
                        locationLv2.id +
                        locationLv3.id
                    );
                });
              }
            });
          }
        });
      }
    });
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allId : []));
  };

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const deleteTitle = ["deleteLocation"];
  const buttonSx = { padding: "2px 10px", height: "fit-content" };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container mt={2}>
        <Grid item xs={12} sm={10} md={10} lg={11} xl={11}>
          <Typography variant="h5" my={2}>
            {t("setting.inventory.warehouse.index")}
          </Typography>
        </Grid>
        {/* {createPermission && ( */}
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          xl={1}
          alignSelf="center"
          textAlign={"end"}
        >
          <CustomizedButton
            fullWidth
            title={t("setting.inventory.warehouse.addLocation")}
            variant="contained"
            onClick={() => handleOpen({ title: "addLocation" })}
          />
        </Grid>
        {/* )} */}
      </Grid>
      <CustomizedBox>
        <Box
          onClick={handleExpandClick}
          sx={{ cursor: "pointer", width: "fit-content", marginBottom: "40px" }}
          display={"flex"}
        >
          {expanded.length === 0 ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
          <Typography>
            {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
          </Typography>
        </Box>
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={{ flexGrow: 1 }}
        >
          {allLocation &&
            allLocation.map((warehouse) => {
              return (
                <TreeItem
                  key={warehouse.unique_id}
                  nodeId={`${warehouse.unique_id}`}
                  label={
                    <>
                      {warehouse.name}{" "}
                      <label style={{ fontSize: "0.7rem" }}>
                        (รหัส: {warehouse.unique_id})
                      </label>
                    </>
                  }
                >
                  <ToggleButtonGroup aria-label="text alignment">
                    <ToggleButton
                      sx={buttonSx}
                      value="left"
                      aria-label="left aligned"
                      onClick={() =>
                        handleOpen({
                          title: "addLocation",
                          location: [warehouse],
                        })
                      }
                    >
                      เพิ่ม
                    </ToggleButton>
                    <ToggleButton
                      sx={buttonSx}
                      value="center"
                      aria-label="centered"
                      onClick={() =>
                        handleOpen({
                          title: "editLocation",
                          location: [warehouse],
                        })
                      }
                    >
                      เปลี่ยนชื่อ
                    </ToggleButton>
                    <ToggleButton
                      sx={buttonSx}
                      value="right"
                      aria-label="right aligned"
                      onClick={() =>
                        handleOpen({
                          title: "deleteLocation",
                          location: [warehouse],
                        })
                      }
                    >
                      ลบ
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {warehouse.warehouse_level_1_list &&
                    warehouse.warehouse_level_1_list.map((locationLv2) => (
                      <TreeItem
                        key={`${warehouse.unique_id + locationLv2.id}`}
                        nodeId={`${warehouse.unique_id + locationLv2.id}`}
                        label={<>{locationLv2.sub_level_1_name} </>}
                      >
                        <ToggleButtonGroup aria-label="text alignment">
                          <ToggleButton
                            sx={buttonSx}
                            value="left"
                            aria-label="left aligned"
                            onClick={() =>
                              handleOpen({
                                title: "addLocation",
                                location: [warehouse, locationLv2],
                              })
                            }
                          >
                            เพิ่ม
                          </ToggleButton>
                          <ToggleButton
                            sx={buttonSx}
                            value="center"
                            aria-label="centered"
                            onClick={() =>
                              handleOpen({
                                title: "editLocation",
                                location: [warehouse, locationLv2],
                              })
                            }
                          >
                            เปลี่ยนชื่อ
                          </ToggleButton>
                          <ToggleButton
                            sx={buttonSx}
                            value="right"
                            aria-label="right aligned"
                            onClick={() =>
                              handleOpen({
                                title: "deleteLocation",
                                location: [warehouse, locationLv2],
                              })
                            }
                          >
                            ลบ
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {locationLv2.sub_level_2_list &&
                          locationLv2.sub_level_2_list.map((locationLv3) => (
                            <TreeItem
                              key={`${
                                warehouse.unique_id +
                                locationLv2.id +
                                locationLv3.id
                              }`}
                              nodeId={`${
                                warehouse.unique_id +
                                locationLv2.id +
                                locationLv3.id
                              }`}
                              label={<>{locationLv3.sub_level_2_name} </>}
                            >
                              <ToggleButtonGroup aria-label="text alignment">
                                <ToggleButton
                                  sx={buttonSx}
                                  value="left"
                                  aria-label="left aligned"
                                  onClick={() =>
                                    handleOpen({
                                      title: "addLocation",
                                      location: [
                                        warehouse,
                                        locationLv2,
                                        locationLv3,
                                      ],
                                    })
                                  }
                                >
                                  เพิ่ม
                                </ToggleButton>
                                <ToggleButton
                                  sx={buttonSx}
                                  value="center"
                                  aria-label="centered"
                                  onClick={() =>
                                    handleOpen({
                                      title: "editLocation",
                                      location: [
                                        warehouse,
                                        locationLv2,
                                        locationLv3,
                                      ],
                                    })
                                  }
                                >
                                  เปลี่ยนชื่อ
                                </ToggleButton>
                                <ToggleButton
                                  sx={buttonSx}
                                  value="right"
                                  aria-label="right aligned"
                                  onClick={() =>
                                    handleOpen({
                                      title: "deleteLocation",
                                      location: [
                                        warehouse,
                                        locationLv2,
                                        locationLv3,
                                      ],
                                    })
                                  }
                                >
                                  ลบ
                                </ToggleButton>
                              </ToggleButtonGroup>
                              {locationLv3.sub_level_3_list &&
                                locationLv3.sub_level_3_list.map(
                                  (locationLv4) => (
                                    <TreeItem
                                      key={`${
                                        warehouse.unique_id +
                                        locationLv2.id +
                                        locationLv3.id +
                                        locationLv4.id
                                      }`}
                                      nodeId={`${
                                        warehouse.unique_id +
                                        locationLv2.id +
                                        locationLv3.id +
                                        locationLv4.id
                                      }`}
                                      label={
                                        <>{locationLv4.sub_level_3_name} </>
                                      }
                                    >
                                      <ToggleButtonGroup aria-label="text alignment">
                                        <ToggleButton
                                          sx={buttonSx}
                                          value="left"
                                          aria-label="left aligned"
                                          onClick={() =>
                                            handleOpen({
                                              title: "addLocation",
                                              location: [
                                                warehouse,
                                                locationLv2,
                                                locationLv3,
                                                locationLv4,
                                              ],
                                            })
                                          }
                                        >
                                          เพิ่ม
                                        </ToggleButton>
                                        <ToggleButton
                                          sx={buttonSx}
                                          value="center"
                                          aria-label="centered"
                                          onClick={() =>
                                            handleOpen({
                                              title: "editLocation",
                                              location: [
                                                warehouse,
                                                locationLv2,
                                                locationLv3,
                                                locationLv4,
                                              ],
                                            })
                                          }
                                        >
                                          เปลี่ยนชื่อ
                                        </ToggleButton>
                                        <ToggleButton
                                          sx={buttonSx}
                                          value="right"
                                          aria-label="right aligned"
                                          onClick={() =>
                                            handleOpen({
                                              title: "deleteLocation",
                                              location: [
                                                warehouse,
                                                locationLv2,
                                                locationLv3,
                                                locationLv4,
                                              ],
                                            })
                                          }
                                        >
                                          ลบ
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                      {locationLv4.bin_location_list &&
                                        locationLv4.bin_location_list.map(
                                          (binLocation) => (
                                            <TreeItem
                                              key={binLocation.bin_name}
                                              nodeId={`${binLocation.bin_name}`}
                                              label={
                                                <>
                                                  {binLocation.bin_name}{" "}
                                                  <label
                                                    style={{
                                                      fontSize: "0.7rem",
                                                    }}
                                                  >
                                                    (รหัส:{" "}
                                                    {binLocation.bin_name})
                                                  </label>
                                                </>
                                              }
                                            >
                                              <ToggleButtonGroup aria-label="text alignment">
                                                <ToggleButton
                                                  sx={buttonSx}
                                                  value="center"
                                                  aria-label="centered"
                                                  onClick={() =>
                                                    handleOpen({
                                                      title: "editLocation",
                                                      location: [
                                                        warehouse,
                                                        locationLv2,
                                                        locationLv3,
                                                        locationLv4,
                                                        binLocation,
                                                      ],
                                                    })
                                                  }
                                                >
                                                  เปลี่ยนชื่อ
                                                </ToggleButton>
                                                <ToggleButton
                                                  sx={buttonSx}
                                                  value="right"
                                                  aria-label="right aligned"
                                                  onClick={() =>
                                                    handleOpen({
                                                      title: "deleteLocation",
                                                      location: [
                                                        warehouse,
                                                        locationLv2,
                                                        locationLv3,
                                                        locationLv4,
                                                        binLocation,
                                                      ],
                                                    })
                                                  }
                                                >
                                                  ลบ
                                                </ToggleButton>
                                              </ToggleButtonGroup>
                                            </TreeItem>
                                          )
                                        )}
                                    </TreeItem>
                                  )
                                )}
                            </TreeItem>
                          ))}
                      </TreeItem>
                    ))}
                </TreeItem>
              );
            })}
        </TreeView>
      </CustomizedBox>
      <ModalUI
        open={modal}
        handleClose={handleClose}
        title={t(`setting.inventory.warehouse.${title}`) || ""}
        width={
          !deleteTitle.includes(title ?? "")
            ? title !== "editLocation"
              ? 620
              : 320
            : 420
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {!deleteTitle.includes(title ?? "") ? (
            <Fragment>
              <Grid container spacing={2} mt={1}>
                {title !== "editLocation" && (
                  <Grid item xs={6}>
                    <ControlledTextField
                      name={"unique_id"}
                      control={control}
                      label={t("setting.inventory.warehouse.documentId")}
                      error={Boolean(errors.unique_id)}
                      helperText={errors.unique_id?.message}
                      disabled={
                        title !== "addLocation" ||
                        (selectedLocation && selectedLocation.length !== 0)
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={title !== "editLocation" ? 6 : 12}>
                  <ControlledTextField
                    name={"value"}
                    control={control}
                    label={t("setting.inventory.warehouse.name")}
                    error={Boolean(errors.value)}
                    helperText={errors.value?.message}
                    onChange={async (e, field) => {
                      field.onChange(e.target.value);
                      title === "addLocation" &&
                        selectedLocation &&
                        selectedLocation.length > 0 &&
                        setValue(
                          "unique_id",
                          (await getMergePrefixDocumentId(
                            selectedLocation,
                            e.target.value
                          )) ?? ""
                        );
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <CustomizedButton
                  type="reset"
                  onClick={resetForm}
                  title={t("button.cancel")}
                  variant="outlined"
                  sx={{ mt: 2, mr: 2 }}
                />
                <CustomizedButton
                  type="submit"
                  title={t("button.save")}
                  variant="contained"
                  sx={{ mt: 2 }}
                />
              </Box>
            </Fragment>
          ) : (
            <Box display="flex" justifyContent="flex-end">
              <CustomizedButton
                type="reset"
                onClick={resetForm}
                title={t("button.cancel")}
                variant="outlined"
                sx={{ mr: 2 }}
              />
              <CustomizedButton
                type="submit"
                title={t("button.save")}
                variant="contained"
              />
            </Box>
          )}
        </form>
      </ModalUI>
    </Fragment>
  );
};

export default WarehouseSetting;
