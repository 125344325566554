export const formatImportExportName = (
  documentType: string,
  operationType: string,
  status: string
) => {
  let formattedDocumentType = "";
  let formattedOperationType = "";
  let formattedStatus = "";
  switch (documentType) {
    case "stock":
      formattedDocumentType = "สินค้าคงเหลือ";
      break;
    case "stock_by_lot":
      formattedDocumentType = "สินค้าคงเหลือตาม Lot";
      break;
    case "trace_entry":
      formattedDocumentType = "เคลื่อนไหวสินค้า";
      break;
    case "movement_summary":
      formattedDocumentType = "สรุปการเคลื่อนไหวสินค้า";
      break;
    case "item_normal":
      formattedDocumentType = "สินค้าธรรมดา";
      break;
    case "item_variant":
      formattedDocumentType = "สินค้ามีตัวเลือก";
      break;
    case "item_bundle":
      formattedDocumentType = "สินค้าจัดเซ็ต";
      break;
    case "service":
      formattedDocumentType = "บริการ";
      break;
  }

  switch (operationType) {
    case "import":
      formattedOperationType = "นำเข้า";
      break;
    case "report":
      formattedOperationType = "รายงาน";
      break;
  }

  switch (status) {
    case "completed":
      formattedStatus = "สำเร็จ";
      break;
    case "failed":
      formattedStatus = "ไม่สำเร็จ";
      break;
  }

  return `${formattedOperationType}${formattedDocumentType} ${formattedStatus}`;
};

export const formatImportExportNameGridOptions = (documentType: string) => {
  switch (documentType) {
    case "stock":
      return "สินค้าคงเหลือ";
    case "stock_by_lot":
      return "สินค้าคงเหลือตาม Lot";
    case "trace_entry":
      return "เคลื่อนไหวสินค้า";
    case "movement_summary":
      return "สรุปการเคลื่อนไหวสินค้า";
    case "item_normal":
      return "สินค้าธรรมดา";
    case "item_variant":
      return "สินค้ามีตัวเลือก";
    case "item_bundle":
      return "สินค้าจัดเซ็ต";
    case "service":
      return "บริการ";
    default:
      return "";
  }
};
