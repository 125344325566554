import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import AddIcon from "@mui/icons-material/Add";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";

import RoutingWorkOrderTable from "../../../Table/Manufacture/Routing/WorkOrderTable";
import { IRouting } from "../../../../types/Manufacture/routing";
import { useDisable } from "../../../../hooks/use-disable";

const dafaultWOValue = {
  started_date: undefined,
  finished_date: undefined,
  duration: 0,
  duration_unit: "minute",
  name: "",
  remark: "",
  production_center: "",
  responsible_user_list: [],
  cost_price: 0,
  plant: "",
};

const RoutingTableForm = () => {
  const { control } = useFormContext<IRouting>();
  const [disabled] = useDisable();

  const {
    fields: workOrderFields,
    append: workOrderAppend,
    move: workOrderMove,
    remove: workOrderRemove,
    update: workOrderUpdate,
    replace: workOrderReplace,
  } = useFieldArray({
    control,
    name: "work_order_list",
  });

  return (
    <CustomizedBox>
      <RoutingWorkOrderTable
        fields={workOrderFields}
        remove={workOrderRemove}
        move={workOrderMove}
        update={workOrderUpdate}
        replace={workOrderReplace}
      />
      {!disabled && (
        <CustomizedButton
          color="secondary"
          title="เพิ่มการทำงาน"
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => workOrderAppend(dafaultWOValue)}
        />
      )}
    </CustomizedBox>
  );
};

export default RoutingTableForm;
