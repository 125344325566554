import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { useParams } from "react-router-dom";
import { IDefaultForm } from "../../../types/global";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import DropzoneField from "../../UI/DropzoneUI";

type Props = {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  disabled: boolean;
};

const ProjectImg = ({ control, getValues, setValue, disabled }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const imgList = getValues("img_url");

  return (
    <CustomizedBox>
      <Typography mb={2} fontWeight="bold">
        {t("inventory.items.picture")}
      </Typography>
      {id && imgList && imgList.length === 0 && disabled && (
        <Typography textAlign="center">
          {t("inventory.sentence.no_img")}
        </Typography>
      )}
      {((imgList && imgList.length > 0 && disabled) || !disabled) && (
        <DropzoneField
          control={control}
          name="img_url"
          setValue={setValue}
          maxSize={10}
          multiple
          options={[{ value: "เลือกเป็นภาพหลัก" }, { value: "ลบ" }]}
          acceptedFileType="image"
          disabled={disabled}
        />
      )}
    </CustomizedBox>
  );
};

export default ProjectImg;
