import { forwardRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { SxProps } from "@mui/material";

type Props = {
  sx?: SxProps;
  value: boolean;
  isDisabled?: boolean;
  onChange?: CheckboxProps["onChange"];
  label?: string;
  error?: boolean;
  testId?: string;
  onClick?: () => void;
};

const CustomizedCheckboxes = forwardRef<HTMLInputElement, Props>(
  ({ sx, value, isDisabled, onChange, label, error, testId, onClick }, ref) => {
    return (
      <FormControlLabel
        ref={ref}
        control={
          <Checkbox
            data-test-id={testId}
            checked={value}
            value={value}
            disabled={isDisabled}
            onChange={onChange}
            sx={{ ...sx, color: error ? "error.main" : "primary.main" }}
            onClick={(e) => {
              e.stopPropagation();
              onClick && onClick();
            }}
          />
        }
        label={label}
      />
    );
  }
);

export default CustomizedCheckboxes;
