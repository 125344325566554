import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useRef } from "react";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";

import CheckboxModalTable from "./CheckboxModalTable";

import { ROUTING_AGGRID } from "../../services/AgGrid/ManufactureAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

import { GraphQLClient } from "graphql-request";
import { useRoutingColumnDef } from "./Manufacture/Routing/columnDefs";

type CheckedRoutingModalProps = {
  showSelect: boolean;
  closeTable: () => void;
  finishSelect: (data: any) => void;
  ids: string[];
  setIds: Dispatch<SetStateAction<string[]>>;
  idsSnapshot: string[];
  setIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  watchItemId: string;
};

const CheckedRoutingModal = ({
  showSelect,
  closeTable,
  finishSelect,
  watchItemId,
  ids,
  setIds,
  idsSnapshot,
  setIdsSnapshot,
  isLoading,
}: CheckedRoutingModalProps) => {
  const { t } = useTranslation();

  const selectBomGridRef = useRef();

  const columnDefs = useRoutingColumnDef(false);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { is_active, ...otherFilterModel } = filterModel;

      const formatFilter = {
        ...otherFilterModel,

        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
      };

      try {
        const { routingsFindManyAggrid } = await graphQLClient.request(
          ROUTING_AGGRID,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: routingsFindManyAggrid.data as any[],
          rowCount: routingsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instance = params.api.getFilterInstance("is_active");
    const itemUniqueId = params.api.getFilterInstance("item_unique_id");
    instance?.setModel({
      filterType: "set",
      values: ["1"],
    });
    itemUniqueId?.setModel({
      filterType: "text",
      type: "contains",
      filter: watchItemId,
    });
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <CheckboxModalTable
      modalTitle={t("manufacture.bom.index")}
      btnTitle={t("button.add")}
      gridRef={selectBomGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection="single"
      onFinishEditing={finishSelect}
      modalIsOpen={showSelect}
      closeModal={closeTable}
      selectedIds={ids}
      setSelectedIds={setIds}
      idsSnapshot={idsSnapshot}
      setIdsSnapshot={setIdsSnapshot}
      onGridReady={onGridReady}
      isLoading={isLoading}
      // onFirstDataRendered={onFirstDataRendered}
    />
  );
};

export default CheckedRoutingModal;
