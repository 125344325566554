import { gql } from "graphql-request";

export const CONTACTS_AGGRID = gql`
  query ContactsFindManyAggrid($aggridInput: AggridTemplateInput) {
    contactsFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        id
        unique_id
        is_vendor
        is_customer
        img_url
        is_active
        status_remark
        created_date
        contact_type
        contact_type_detail
        title_name
        main_contact_name
        secondary_contact_name
        identity_no
        branch_type
        branch_id
        branch_name
        registered_capital
        contact_source
        contact_group
        remark
        estimate_sales_item_group
        estimate_sales_volume
        contact_channel_list {
          contact_channel_type
          contact_channel_name
        }
        address_list {
          address_type
          is_default
          is_same_as_default_address
          address_contact_name
          address_contact_phone
          address
          sub_district
          district
          province
          postal_code
          country
        }
        contact_person_list {
          position
          title_name
          first_name
          last_name
          nick_name
          img_url
          remark
          contact_channel_list {
            contact_channel_type
            contact_channel_name
          }
        }
        finance {
          is_cheque
          is_transfer
          is_cash
          payment_day
          payment_contact_name
          payment_contact_phone
          credit_count
          billing_day
          billing_contact_name
          billing_contact_phone
          account_payable_id
          account_receivable_id
          request_credit {
            credit_limit_value
            credit_limit_day
          }
          bank_list {
            bank_name
            bank_account_type
            bank_account_name
            bank_account_no
            bank_account_branch
            description
          }
        }
        attachment_list {
          attachment_name
          url
          uploaded_date
        }
        sales_list {
          id
          unique_id
          title_name
          nick_name
          first_name
          last_name
          img_url
        }
        tag_list {
          id
          name
          entity
        }
      }
    }
  }
`;
