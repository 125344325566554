import { Box, Skeleton, Typography } from "@mui/material";
import { ReactNode, useCallback } from "react";
import {
  formatNumber,
  mapStatusToThai,
} from "../../../../utils/dataTransformer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  icon?: ReactNode;
  documentCount: number;
  status?: string;
  subStatus?: string;
  totalValue?: number;
  linkTo?: string;
  expandHeight?: boolean;
  state?: string[];
  isLoading?: boolean;
}

const DashboardCard = ({
  title,
  icon,
  documentCount,
  status,
  subStatus,
  totalValue,
  linkTo,
  expandHeight,
  state,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const renderStatusColor = useCallback(() => {
    switch (status) {
      case t("status.wait_approve"):
      case t("status.wait_accept"):
        return "#C3762E";
      case "รอดำเนินการ":
        return "#1F5BB2";
      default:
        break;
    }
  }, [status, t]);

  return (
    <Link
      to={linkTo ?? ""}
      style={{ textDecoration: "none", color: "black" }}
      state={state}
    >
      <Box
        sx={{
          borderRadius: "10px",
          padding: "20px",
          backgroundColor: "white",
          height: expandHeight ? "100%" : "auto",
          boxShadow: "0px 2px 7px #E5E5E5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            gap: 1,
          }}
        >
          <Typography
            fontWeight={"bold"}
            fontSize={"16px"}
            sx={{ textWrap: "nowrap" }}
            title={title}
          >
            {title}
          </Typography>
          {(totalValue || totalValue === 0) && (
            <Typography
              sx={{
                color: "#737373",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "right",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textWrap: "nowrap",
              }}
              title={`${formatNumber(totalValue ?? 0, 2)} บาท`}
            >
              {isLoading ? (
                <Skeleton width={"65px"} />
              ) : (
                `${formatNumber(totalValue ?? 0, 2)} บาท`
              )}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <Box display="flex" flexDirection="column">
            {icon}
            {status && (
              <Typography
                variant="body2"
                sx={{ textWrap: "nowrap", color: renderStatusColor() }}
              >
                {mapStatusToThai(status)}
              </Typography>
            )}
            {subStatus && (
              <Typography
                fontSize={"12px"}
                color={renderStatusColor()}
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {subStatus}
              </Typography>
            )}
          </Box>

          <Typography
            sx={{
              color: "#2E65CE",
              fontSize: "24px",
              fontWeight: 700,
              textAlign: "right",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={formatNumber(documentCount, 0)}
          >
            {isLoading ? (
              <Skeleton width={"65px"} />
            ) : (
              formatNumber(documentCount, 0)
            )}
          </Typography>
        </Box>
        <Typography
          sx={{ textAlign: "right", color: "#737373", fontSize: "12px" }}
        >
          รายการ
        </Typography>
      </Box>
    </Link>
  );
};

export default DashboardCard;
