import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useFieldArray } from "react-hook-form";
import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import ClearIcon from "@mui/icons-material/Clear";
import SerialNumberCell from "./SerialNumberCell";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { formatDate } from "../../../../utils/Formatter/Date";

type Props = {
  control: IDefaultForm<IGoodsReceive>["control"];
  errors: IDefaultForm<IGoodsReceive>["errors"];
  disabled: IDefaultForm["disabled"];
  nestedIndex: number;
};

const SerialList = ({ control, errors, nestedIndex, disabled }: Props) => {
  const { fields, remove } = useFieldArray({
    control,
    name: `trace_entry_list[${nestedIndex}].serial_list` as `trace_entry_list`,
  });

  return (
    <>
      {fields.map((serial, index) => (
        <TableRow key={serial.id}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center">
            <ControlledTextField
              control={control}
              type="number"
              name={`trace_entry_list[${nestedIndex}].serial_list[${index}].qty`}
              InputProps={{
                inputProps: { min: 0 },
              }}
              error={Boolean(
                errors &&
                  errors.trace_entry_list &&
                  errors.trace_entry_list[nestedIndex] &&
                  errors.trace_entry_list[nestedIndex].serial_list &&
                  errors.trace_entry_list[nestedIndex].serial_list[index] &&
                  errors.trace_entry_list[nestedIndex].serial_list[index].qty
              )}
              disabled={disabled}
            />
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{serial.uom?.name}</Typography>
          </TableCell>
          <TableCell></TableCell>
          <SerialNumberCell control={control} serial={serial} />
          <TableCell align="center">
            <Typography fontSize={14}>{serial.remark}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography fontSize={14}>{formatDate(serial.lot_date)}</Typography>
          </TableCell>
          {!disabled && (
            <TableCell align="center">
              <IconButton onClick={() => remove(index)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default SerialList;
