import { IGoodsReturn } from "../../../../types/Inventory/goodsReturn";
import dayjs from "dayjs";
import * as Yup from "yup";
import { Tracability } from "../../../../generated/inventory";

export const goodsReturnSchema: IGoodsReturn = {
  unique_id: "",
  type: "",
  reference_unique_id: "",
  source_warehouse_unique_id: "",
  main_status: "",
  created_date: dayjs().toDate(),
  posted_date: dayjs(),
  remark: "",
  trace_entry_list: [],
  flag_status: [],
  draft_step_no: 1,
};

const goodsReturnTraceValidation = Yup.object().shape({
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .min(1, "กรุณาระบุจำนวนที่จะรับคืนทุกรายการ")
    .required("กรุณาระบุจำนวนที่จะรับคืนทุกรายการ"),
});

const goodsReturnSerialValidation = Yup.object().shape({
  qty: Yup.number().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Normal
      ? Yup.number()
          .transform((value) =>
            isNaN(value) || value === null || value === undefined ? 0 : value
          )
          .min(1, "กรุณาระบุจำนวนที่จะรับคืนทุกรายการ")
          .required("กรุณาระบุจำนวนที่จะรับคืนทุกรายการ")
      : Yup.number().nullable()
  ),
  serial_list: Yup.array().when("tracability", (tracability: any) =>
    tracability && tracability[0] === Tracability.Serial
      ? Yup.array()
          .of(goodsReturnTraceValidation)
          .min(1, "กรุณาสแกนรายการสินค้า")
      : Yup.array().nullable()
  ),
});

export const goodsReturnStepOneValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  type: Yup.string().required("กรุณาระบุประเภท"),
  source_warehouse_unique_id: Yup.string().required("กรุณาระบุคลัง"),
  reference_unique_id: Yup.string().required("กรุณาระบุเอกสารอ้างอิง"),
});

export const goodsReturnStepTwoValidation = Yup.object().shape({
  trace_entry_list: Yup.array()
    .of(goodsReturnSerialValidation)
    .min(1, "กรุณาเพิ่มสินค้า"),
});
