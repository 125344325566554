import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { IDefaultForm } from "../../../types/global";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../Custom/CustomizedButton";
import { Controller, useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";
import { IContactChannel } from "../../../types/Setting/company";

const contactChannels: string[] = [
  "เบอร์โทรศัพท์",
  "แฟกซ์",
  "Email",
  "Line",
  "Facebook",
  "Website",
  "Instagram",
  "อื่นๆ",
];

const defaultContactChannelValues: IContactChannel = {
  contact_channel_type: "เบอร์โทรศัพท์",
  contact_channel_name: "",
};

const ChannelForm = ({ control, errors, disabled }: IDefaultForm) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_channel_list",
  });

  return (
    <Box sx={{ mb: 3 }}>
      <Accordion
        sx={{ borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography ml={1} fontWeight="bold">
            {t("setting.company.contact_channel.index")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!disabled && (
            <CustomizedButton
              title={"เพิ่มช่องทางติดต่อ"}
              variant="outlined"
              size="medium"
              onClick={() => append({ ...defaultContactChannelValues })}
            />
          )}
          {fields.map((field, index) => (
            <Grid container spacing={2} key={field.id} mt={0.5}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                // order={{ xs: 2, md: 1 }}
              >
                <Controller
                  control={control}
                  name={`contact_channel_list.${index}.contact_channel_type`}
                  render={({ field }) => (
                    <CustomizedComboBox
                      {...field}
                      label={t("setting.company.contact_channel.type")}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }
                        return option === value;
                      }}
                      onChange={(e, value) => field.onChange(value)}
                      options={contactChannels}
                      disabled={disabled}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={2}
                // order={{ xs: 2, md: 3 }}
              >
                <ControlledTextField
                  control={control}
                  name={`contact_channel_list.${index}.contact_channel_name`}
                  label={t("setting.company.contact_channel.info")}
                  error={Boolean(
                    errors.contact_channel_list?.[index]?.contact_channel_name
                  )}
                  helperText={
                    errors.contact_channel_list &&
                    errors.contact_channel_list.length > 0 &&
                    errors.contact_channel_list?.[index]
                      ?.contact_channel_name &&
                    errors.contact_channel_list?.[index]?.contact_channel_name
                      .message
                  }
                  disabled={disabled}
                />
              </Grid>
              {!disabled && (
                <Grid item xs={1} order={{ xs: 1, md: 4 }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => remove(index)}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ChannelForm;
