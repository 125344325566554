import { useMemo } from "react";
import { IStatusOption } from "../../types/Project";

export const useProjectStatusOption = (allStatus: any) => {
  const renderGroupName = (statusName: string) => {
    switch (statusName) {
      case "pre_sales":
        return "ก่อนขาย";
      case "post_sales":
        return "ระหว่างการขาย";
      case "support":
        return "หลังการขาย";
      case "finished":
        return "เสร็จสิ้น";
      default:
        return;
    }
  };

  // Define the sorting order of options
  const customStatusOrder: { [status: string]: number } = useMemo(
    () => ({ pre_sales: 1, post_sales: 2, support: 3, finished: 4 }),
    []
  );

  const defaultOptions: IStatusOption[] = useMemo(() => {
    const options = allStatus?.map((status: any) => {
      const formattedOption = {
        id: status.unique_id,
        label: status.sub_status,
        value: status.sub_status,
        group: renderGroupName(status.status_name),
        main_status: status.status_name,
      };
      return formattedOption;
    });

    // Sort the array based on the custom order
    return options.sort((a: IStatusOption, b: IStatusOption) => {
      const statusA = customStatusOrder[a.main_status] || 0; // Default to 0 if status not found
      const statusB = customStatusOrder[b.main_status] || 0;

      return statusA - statusB;
    });
  }, [allStatus, customStatusOrder]);

  return {
    selectStatusOptions: defaultOptions,
  };
};
