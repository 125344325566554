import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import {
  ContactActivityLog,
  ContactActivityLogCreateInput,
  useContactActivityLogCreateMutation,
  ContactActivityLogDocumentType,
} from "../generated/contact";
import {
  InventoryActivityLog,
  InventoryActivityLogCreateInput,
  useInventoryActivityLogCreateMutation,
  InventoryActivityLogDocumentType,
} from "../generated/inventory";

type ActivityLogCreateInput =
  | ContactActivityLogCreateInput
  | InventoryActivityLogCreateInput;

interface IActivityLogContext {
  createActivityLog: (
    input: ActivityLogCreateInput
  ) => Promise<ContactActivityLog | InventoryActivityLog>;
  activityLogIsInit: boolean;
  setActivityLogIsInit: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  children?: ReactNode;
}

export const ActivityLogContext = createContext<IActivityLogContext>({
  createActivityLog: async () => {
    throw new Error("Not implemented");
  },
  activityLogIsInit: false,
  setActivityLogIsInit: () => {},
});

export const ActivityLogContextProvider = ({ children }: Props) => {
  const [activityLogIsInit, setActivityLogIsInit] = useState<boolean>(false);

  const graphqlClientContact = createGraphQLClientWithMiddleware("contact");
  const graphqlClientInventory = createGraphQLClientWithMiddleware("item");

  const { mutateAsync: contactLogCreate } =
    useContactActivityLogCreateMutation<Error>(graphqlClientContact);

  const { mutateAsync: inventoryLogCreate } =
    useInventoryActivityLogCreateMutation<Error>(graphqlClientInventory);

  const createActivityLog = useCallback(
    async (input: ActivityLogCreateInput) => {
      try {
        let result;
        switch (input.document_type) {
          case ContactActivityLogDocumentType.Contact: {
            const { ContactActivityLogCreate } = await contactLogCreate({
              createInput: {
                ...input,
                document_type: input.document_type as any,
              },
            });
            result = ContactActivityLogCreate;
            break;
          }
          case InventoryActivityLogDocumentType.GoodsReceive:
            const { InventoryActivityLogCreate } = await inventoryLogCreate({
              createInput: {
                ...input,
                document_type: input.document_type as any,
              },
            });
            result = InventoryActivityLogCreate;
            break;
          default:
            throw new Error("Invalid document type");
        }
        setActivityLogIsInit(false);

        return result;
      } catch (error) {
        console.log(error);
      }
    },
    [contactLogCreate, inventoryLogCreate]
  );

  const activityLog = useMemo(
    () => ({
      createActivityLog,
      activityLogIsInit,
      setActivityLogIsInit,
    }),
    [createActivityLog, activityLogIsInit, setActivityLogIsInit]
  );

  return (
    <ActivityLogContext.Provider value={activityLog as IActivityLogContext}>
      {children}
    </ActivityLogContext.Provider>
  );
};

export default ActivityLogContext;
