import { gql } from "graphql-request";

export const DELIVERY_ORDERS_AGGRID = gql`
  query DeliveryOrdersFindManyAggrid($aggridInput: AggridTemplateInput) {
    deliveryOrdersFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        id
        unique_id
        total_amount
        issue_date
        delivery_date
        customer_contact {
          name
          phone
          identity_no
          fax
          email
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        customer_contact_unique_id
        aggrid_status
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
      }
    }
  }
`;
