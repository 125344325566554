import React, { useState } from "react";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDefaultForm } from "../../../types/global";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import ControlledTextField from "../../Controller/ControlledTextField";

const GeneralInfoForm = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
}: IDefaultForm) => {
  const [expectedStartDateIsOpen, setExpectedStartDateIsOpen] =
    useState<boolean>(false);
  const [expectedEndDateIsOpen, setExpectedEndDateIsOpen] =
    useState<boolean>(false);
  const [startDateIsOpen, setStartDateIsOpen] = useState<boolean>(false);
  const [endDateIsOpen, setEndDateIsOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">{t("project.general_info")}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="project_value"
              error={Boolean(errors.project_value)}
              helperText={errors.project_value?.message}
              label={t("project.project.project_value")}
              disabled={disabled}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="opportunity_close_sale"
              error={Boolean(errors.opportunity_close_sale)}
              helperText={errors.opportunity_close_sale?.message}
              label={t("project.project.opportunity_close_sale")}
              disabled={disabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <ControlledDatePicker
              name="expected_start_date"
              control={control}
              error={errors.created_date}
              open={expectedStartDateIsOpen}
              onClose={() => setExpectedStartDateIsOpen(false)}
              onOpen={() => setExpectedStartDateIsOpen(true)}
              label={t("project.project.expected_start_date")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledDatePicker
              name="expected_end_date"
              control={control}
              error={errors.created_date}
              open={expectedEndDateIsOpen}
              onClose={() => setExpectedEndDateIsOpen(false)}
              onOpen={() => setExpectedEndDateIsOpen(true)}
              label={t("project.project.expected_end_date")}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <ControlledDatePicker
              name="start_date"
              control={control}
              error={errors.created_date}
              open={startDateIsOpen}
              onClose={() => setStartDateIsOpen(false)}
              onOpen={() => setStartDateIsOpen(true)}
              label={t("project.project.start_date")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledDatePicker
              name="end_date"
              control={control}
              error={errors.created_date}
              open={endDateIsOpen}
              onClose={() => setEndDateIsOpen(false)}
              onOpen={() => setEndDateIsOpen(true)}
              label={t("project.project.end_date")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledTextField
              control={control}
              name="remark"
              error={Boolean(
                errors.customer_contact && errors.customer_contact.name
              )}
              helperText={
                errors.customer_contact && errors.customer_contact.name?.message
              }
              label={t("project.project.remark")}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default GeneralInfoForm;
