import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useRef } from "react";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";

import CheckboxModalTable from "./CheckboxModalTable";
import { useBomColumnDefs } from "../Table/Manufacture/Bom/columnDefs";
import { dateFilterModel } from "../../utils/Formatter/AgGridFilter";

import { BOMS_AGGRID } from "../../services/AgGrid/ManufactureAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  CreatablesQuery,
  EntityTypeEnum,
  useCreatablesQuery,
} from "../../generated/creatable";
import { GraphQLClient } from "graphql-request";

type CheckedBomModalProps = {
  showSelectBom: boolean;
  closeBomTable: () => void;
  finishBomSelect: (data: any) => void;
  bomIds: string[];
  setBomIds: Dispatch<SetStateAction<string[]>>;
  bomIdsSnapshot: string[];
  setBomIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  rowData?: any[];
  isLoading?: boolean;
  watchItemId: string;
};

const CheckedBomModal = ({
  showSelectBom,
  closeBomTable,
  finishBomSelect,
  watchItemId,
  bomIds,
  setBomIds,
  bomIdsSnapshot,
  setBomIdsSnapshot,
  isLoading,
}: CheckedBomModalProps) => {
  const { t } = useTranslation();

  const selectBomGridRef = useRef();

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data } = useCreatablesQuery<CreatablesQuery>(
    graphQLClientWithHeaderSetting,
    {
      filterInput: {
        usage_field_name: "mfg_type",
        usage_field_type: EntityTypeEnum.Bom,
      },
    }
  );

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const {
        created_date,
        start_date,
        end_date,
        expect_date,
        created_by,
        is_active,
        ...otherFilterModel
      } = filterModel;

      const formatFilter = {
        ...otherFilterModel,
        created_date: dateFilterModel(created_date),
        start_date: dateFilterModel(start_date),
        end_date: dateFilterModel(end_date),
        created_by: created_by && {
          filterType: "json",
          type: created_by.type,
          filter: created_by.filter,
          path: ["first_name"],
        },
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
      };
      try {
        const { bomsFindManyAggrid } =
          await graphQLClientWithHeaderManufacture.request(BOMS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: bomsFindManyAggrid.data as any[],
          rowCount: bomsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    const instance = params.api.getFilterInstance("is_active");
    const itemUniqueId = params.api.getFilterInstance("item_unique_id");
    instance?.setModel({
      filterType: "set",
      values: ["1"],
    });
    itemUniqueId?.setModel({
      filterType: "text",
      type: "contains",
      filter: watchItemId,
    });
    params.api.setServerSideDatasource(datasource);
  };

  return (
    <CheckboxModalTable
      modalTitle={t("manufacture.bom.index")}
      btnTitle={t("button.add")}
      gridRef={selectBomGridRef}
      height={450}
      columnDefs={useBomColumnDefs(t, data?.creatables ?? [], false)}
      rowSelection="single"
      onFinishEditing={finishBomSelect}
      modalIsOpen={showSelectBom}
      closeModal={closeBomTable}
      selectedIds={bomIds}
      setSelectedIds={setBomIds}
      idsSnapshot={bomIdsSnapshot}
      setIdsSnapshot={setBomIdsSnapshot}
      onGridReady={onGridReady}
      isLoading={isLoading}
      // onFirstDataRendered={onFirstDataRendered}
    />
  );
};

export default CheckedBomModal;
