import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Typography } from "@mui/material";

import DeliveryOrderTable from "../../../components/Table/Logistic/DeliveryOrder";

const DeliveryOrderTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Typography variant="h5" mb={3}>
        {t("logistic.delivery_order.index")}
      </Typography>
      <DeliveryOrderTable referenceUniqueId={id} />
    </Fragment>
  );
};

export default DeliveryOrderTab;
