import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../../types/global";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import { useModal } from "../../../../hooks/use-modal";
import LevelOneModal from "../../../../components/Form/Category/LevelOneModal";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  ItemGroupLevel1sQuery,
  useItemGroupLevel1sQuery,
} from "../../../../generated/inventory";
import LevelOneTable from "../../../../components/Table/Setting/Inventory/LevelOneTable";
import { GraphQLClient } from "graphql-request";

const LevelOne = () => {
  const { t } = useTranslation();
  const { modal, openModalHandler, closeModalHandler } = useModal();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.category.level_one"),
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, refetch } = useItemGroupLevel1sQuery<ItemGroupLevel1sQuery>(
    graphQLClientWithHeaderItem
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent="space-between"
        my={2}
        alignItems="center"
      >
        <Typography variant="h5">
          {t("setting.inventory.category.level_one")}
        </Typography>
        <CustomizedButton
          variant="contained"
          title={t("button.add") + t("setting.inventory.category.level_one")}
          onClick={openModalHandler}
        />
      </Box>
      <LevelOneTable data={data} refetch={refetch} />
      <LevelOneModal
        open={modal}
        closeModalHandler={closeModalHandler}
        refetch={refetch}
      />
    </>
  );
};

export default LevelOne;
