export const validateItemFieldOptions = (data: any) => {
  const errorArray: any[] = [];
  data.forEach((item: any) => {
    if (
      item.is_purchasable &&
      item.is_purchasable.trim() !== "ได้" &&
      item.is_purchasable.trim() !== "ไม่ได้"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "ซื้อได้",
        value: item.is_purchasable,
      });
    }

    if (
      item.is_saleable &&
      item.is_saleable.trim() !== "ได้" &&
      item.is_saleable.trim() !== "ไม่ได้"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "ขายได้",
        value: item.is_saleable,
      });
    }

    if (
      item.is_manufacturable &&
      item.is_manufacturable.trim() !== "ได้" &&
      item.is_manufacturable.trim() !== "ไม่ได้"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "ผลิตได้",
        value: item.is_manufacturable,
      });
    }

    if (
      item.is_stockable &&
      item.is_stockable.trim() !== "ได้" &&
      item.is_stockable.trim() !== "ไม่ได้"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "สต๊อกได้",
        value: item.is_stockable,
      });
    }

    if (
      item.is_active &&
      item.is_active.trim() !== "ใช้งาน" &&
      item.is_active.trim() !== "หยุดใช้งาน"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "สถานะ",
        value: item.is_active,
      });
    }

    if (
      item.purchase_vat_type &&
      item.purchase_vat_type !== "7%" &&
      item.purchase_vat_type !== 0.07 &&
      item.purchase_vat_type !== "0%" &&
      item.purchase_vat_type !== 0 &&
      item.purchase_vat_type !== "ไม่มี"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "ประเภทภาษีซื้อ",
        value: item.purchase_vat_type,
      });
    }

    if (
      item.sales_vat_type &&
      item.sales_vat_type !== "7%" &&
      item.sales_vat_type !== 0.07 &&
      item.sales_vat_type !== "0%" &&
      item.sales_vat_type !== 0 &&
      item.sales_vat_type !== "ไม่มี"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "ประเภทภาษีขาย",
        value: item.sales_vat_type,
      });
    }

    if (
      item.mrp_default_method &&
      item.mrp_default_method !== "ผลิต" &&
      item.mrp_default_method !== "สั่งซื้อ" &&
      item.mrp_default_method !== "ไม่ได้ตั้งค่า"
    ) {
      errorArray.push({
        unique_id: item.unique_id,
        type: "invalid",
        field: "การจัดหา",
        value: item.mrp_default_method,
      });
    }

    if (item.tracibility_mode) {
      const tracability = item.tracibility_mode.toUpperCase();
      if (tracability !== "BATCH/SERIAL NUMBER" &&  
      tracability !== "BATCH/SERAIL NUMBER" 
      &&tracability !== "ทั่วไป") {
        errorArray.push({
          unique_id: item.unique_id,
          type: "invalid",
          field: "การติดตามสินค้า",
          value: item.tracibility_mode,
        });
      }
    }
  });
  return errorArray;
};
