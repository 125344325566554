import { Box, CircularProgress, Grid } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import RelatedDocumentList from "../../../components/Table/Projects/RelatedDocumentList";
import { useProjectDocumentOption } from "../../../hooks/Projects/use-project-document-option";
import { useProjectDocumenStatusOption } from "../../../hooks/Projects/use-project-document-status-option";
import {
  QuotationsQuery,
  SalesOrdersQuery,
  SalesReturnsQuery,
  useQuotationsQuery,
  useSalesOrdersQuery,
  useSalesReturnsQuery,
} from "../../../generated/sales";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  PurchaseOrdersQuery,
  PurchaseRequestsQuery,
  PurchaseReturnsQuery,
  usePurchaseOrdersQuery,
  usePurchaseRequestsQuery,
  usePurchaseReturnsQuery,
} from "../../../generated/purchase";
import { useParams } from "react-router-dom";
import { IProjectRelatedDocument } from "../../../types/Project";
import { EntityTypeEnum } from "../../../generated/creatable";
import {
  ManufactureOrdersQuery,
  useManufactureOrdersQuery,
} from "../../../generated/manufacture";
import { GraphQLClient } from "graphql-request";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import {
  DeliveryOrdersQuery,
  useDeliveryOrdersQuery,
} from "../../../generated/logistic";

interface Props {
  tab: string;
}

const RelatedDocumentTab = ({ tab }: Props) => {
  const { control, setValue } = useForm({});
  const options = useProjectDocumentOption(tab);
  const documentWatch = useWatch({ control, name: "document" });
  const statusWatch = useWatch({ control, name: "status" });
  const documentStatusOptions = useProjectDocumenStatusOption(
    tab,
    documentWatch?.value ?? ""
  );
  const { id } = useParams();

  const [documentList, setDocumentList] = useState<IProjectRelatedDocument[]>(
    []
  );

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const { data: quotationsQuery, isLoading: isLoadingQuotations } =
    useQuotationsQuery<QuotationsQuery>(
      graphQLClientWithHeaderSales,
      {},
      {
        enabled:
          !!id &&
          tab === "sales" &&
          ["ALL", EntityTypeEnum.Quotation].includes(documentWatch?.value),
      }
    );

  const { data: salesOrdersQuery, isLoading: isLoadingSalesOrders } =
    useSalesOrdersQuery<SalesOrdersQuery>(
      graphQLClientWithHeaderSales,
      {},
      {
        enabled:
          !!id &&
          tab === "sales" &&
          ["ALL", EntityTypeEnum.SalesOrder].includes(documentWatch?.value),
      }
    );

  const { data: salesReturnsQuery, isLoading: isLoadingSalesReturns } =
    useSalesReturnsQuery<SalesReturnsQuery>(
      graphQLClientWithHeaderSales,
      {},
      {
        enabled:
          !!id &&
          tab === "sales" &&
          ["ALL", EntityTypeEnum.SalesReturn].includes(documentWatch?.value),
      }
    );

  const { data: purchaseOrdersQuery, isLoading: isLoadingPurchaseOrders } =
    usePurchaseOrdersQuery<PurchaseOrdersQuery>(
      graphQLClientWithHeaderPurchase,
      {},
      {
        enabled:
          !!id &&
          tab === "purchase" &&
          ["ALL", EntityTypeEnum.PurchaseOrder].includes(documentWatch?.value),
      }
    );

  const { data: purchaseRequestsQuery, isLoading: isLoadingPurchaseRequests } =
    usePurchaseRequestsQuery<PurchaseRequestsQuery>(
      graphQLClientWithHeaderPurchase,
      {},
      {
        enabled:
          !!id &&
          tab === "purchase" &&
          ["ALL", EntityTypeEnum.PurchaseRequest].includes(
            documentWatch?.value
          ),
      }
    );

  const { data: purchaseReturnsQuery, isLoading: isLoadingPurchaseReturns } =
    usePurchaseReturnsQuery<PurchaseReturnsQuery>(
      graphQLClientWithHeaderPurchase,
      {},
      {
        enabled:
          !!id &&
          tab === "purchase" &&
          ["ALL", EntityTypeEnum.PurchaseReturn].includes(documentWatch?.value),
      }
    );

  const {
    data: manufactureOrdersQuery,
    isLoading: isLoadingManufactureOrders,
  } = useManufactureOrdersQuery<ManufactureOrdersQuery>(
    graphQLClientWithHeaderManufacture,
    {},
    {
      enabled:
        !!id &&
        tab === "manufacture" &&
        ["ALL", EntityTypeEnum.ManufactureOrder].includes(documentWatch?.value),
    }
  );

  const { data: deliveryOrdersQuery, isLoading: isLoadingDeliveryOrders } =
    useDeliveryOrdersQuery<DeliveryOrdersQuery>(
      graphQLClientWithHeaderLogistic,
      {},
      {
        enabled:
          !!id &&
          tab === "logistic" &&
          ["ALL", EntityTypeEnum.DeliveryOrder].includes(documentWatch?.value),
      }
    );

  const quotations: IProjectRelatedDocument[] = useMemo(
    () =>
      quotationsQuery?.quotations.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, quotationsQuery?.quotations]
  );

  const salesOrders: IProjectRelatedDocument[] = useMemo(
    () =>
      salesOrdersQuery?.salesOrders.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, salesOrdersQuery?.salesOrders]
  );

  const salesReturns: IProjectRelatedDocument[] = useMemo(
    () =>
      salesReturnsQuery?.salesReturns.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, salesReturnsQuery?.salesReturns]
  );

  const purchaseRequests: IProjectRelatedDocument[] = useMemo(
    () =>
      purchaseRequestsQuery?.purchaseRequests.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, purchaseRequestsQuery?.purchaseRequests]
  );

  const purchaseOrders: IProjectRelatedDocument[] = useMemo(
    () =>
      purchaseOrdersQuery?.purchaseOrders.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, purchaseOrdersQuery?.purchaseOrders]
  );

  const purchaseReturns: IProjectRelatedDocument[] = useMemo(
    () =>
      purchaseReturnsQuery?.purchaseReturns.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, purchaseReturnsQuery?.purchaseReturns]
  );

  const deliveryOrders: IProjectRelatedDocument[] = useMemo(
    () =>
      deliveryOrdersQuery?.deliveryOrders.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, deliveryOrdersQuery?.deliveryOrders]
  );

  const manufactureOrders: IProjectRelatedDocument[] = useMemo(
    () =>
      manufactureOrdersQuery?.manufactureOrders?.filter(
        (doc) =>
          doc !== null &&
          doc?.project?.map((project) => project.unique_id).includes(id)
      ) ?? [],
    [id, manufactureOrdersQuery?.manufactureOrders]
  );

  const handleDocumentFilterChange = useCallback(
    (documentType: string, status: string) => {
      switch (tab) {
        case "sales":
          switch (documentType) {
            case EntityTypeEnum.Quotation:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? quotations
                  : quotations.filter((doc) => doc?.aggrid_status === status)
              );
            case EntityTypeEnum.SalesOrder:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? salesOrders
                  : salesOrders.filter((doc) => doc?.aggrid_status === status)
              );
            case EntityTypeEnum.SalesReturn:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? salesReturns
                  : salesReturns.filter((doc) => doc?.aggrid_status === status)
              );
            default:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? [...quotations, ...salesOrders, ...salesReturns]
                  : [...quotations, ...salesOrders, ...salesReturns].filter(
                      (doc) => doc?.aggrid_status === status
                    )
              );
          }
        case "purchase":
          switch (documentType) {
            case EntityTypeEnum.PurchaseRequest:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? purchaseRequests
                  : purchaseRequests.filter(
                      (doc) => doc?.aggrid_status === status
                    )
              );
            case EntityTypeEnum.PurchaseOrder:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? purchaseOrders
                  : purchaseOrders.filter(
                      (doc) => doc?.aggrid_status === status
                    )
              );
            case EntityTypeEnum.PurchaseReturn:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? purchaseReturns
                  : purchaseReturns.filter(
                      (doc) => doc?.aggrid_status === status
                    )
              );
            default:
              return setDocumentList(
                status === "" || status === "ALL"
                  ? [...purchaseRequests, ...purchaseOrders, ...purchaseReturns]
                  : [
                      ...purchaseRequests,
                      ...purchaseOrders,
                      ...purchaseReturns,
                    ].filter((doc) => doc?.aggrid_status === status)
              );
          }
        case "logistic":
          return setDocumentList(
            status === "" || status === "ALL"
              ? deliveryOrders
              : deliveryOrders.filter((doc) => doc?.aggrid_status === status)
          );
        case "manufacture":
          return setDocumentList(
            status === "" || status === "ALL"
              ? manufactureOrders
              : manufactureOrders.filter((doc) => doc?.aggrid_status === status)
          );

        default:
          setDocumentList([]);
          break;
      }
    },
    [
      deliveryOrders,
      manufactureOrders,
      purchaseOrders,
      purchaseRequests,
      purchaseReturns,
      quotations,
      salesOrders,
      salesReturns,
      tab,
    ]
  );

  const onFilterSubmit = useCallback(() => {
    if (documentWatch) {
      if (statusWatch) {
        console.log(documentWatch, statusWatch);
        handleDocumentFilterChange(documentWatch.value, statusWatch.value);
      } else handleDocumentFilterChange(documentWatch.value, "ALL");
    }
  }, [documentWatch, handleDocumentFilterChange, statusWatch]);

  useEffect(() => {
    switch (tab) {
      case "sales":
        setValue("document", options[1]);
        handleDocumentFilterChange(EntityTypeEnum.Quotation, "ALL");
        break;
      case "purchase":
        setValue("document", options[2]);
        handleDocumentFilterChange(EntityTypeEnum.PurchaseOrder, "ALL");
        break;
      case "logistic":
        setValue("document", options[1]);
        handleDocumentFilterChange(EntityTypeEnum.DeliveryOrder, "ALL");
        break;
      case "manufacture":
        setValue("document", options[1]);
        handleDocumentFilterChange(EntityTypeEnum.ManufactureOrder, "ALL");
        break;
      default:
        setValue("document", "");
        setDocumentList([]);
        break;
    }

    setValue("status", documentStatusOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, setValue, tab, quotations, purchaseOrders, manufactureOrders]);

  const loadingDocumentHandler = useCallback(() => {
    if (id) {
      switch (tab) {
        case "sales":
          switch (documentWatch?.value) {
            case "ALL":
              return (
                isLoadingQuotations ||
                isLoadingSalesOrders ||
                isLoadingSalesReturns
              );
            case EntityTypeEnum.Quotation:
              return isLoadingQuotations;
            case EntityTypeEnum.SalesOrder:
              return isLoadingSalesOrders;
            case EntityTypeEnum.SalesReturn:
              return isLoadingSalesReturns;
            default:
              return false;
          }
        case "purchase":
          switch (documentWatch?.value) {
            case "ALL":
              return (
                isLoadingPurchaseRequests ||
                isLoadingPurchaseOrders ||
                isLoadingPurchaseReturns
              );
            case EntityTypeEnum.PurchaseRequest:
              return isLoadingPurchaseRequests;
            case EntityTypeEnum.PurchaseOrder:
              return isLoadingPurchaseOrders;
            case EntityTypeEnum.PurchaseReturn:
              return isLoadingPurchaseReturns;
            default:
              return false;
          }
        case "logistic":
          switch (documentWatch?.value) {
            case "ALL":
            case EntityTypeEnum.DeliveryOrder:
              return isLoadingDeliveryOrders;
            default:
              return false;
          }
        case "manufacture":
          switch (documentWatch?.value) {
            case "ALL":
            case EntityTypeEnum.ManufactureOrder:
              return isLoadingManufactureOrders;
            default:
              return false;
          }
        default:
          return false;
      }
    }
    return false;
  }, [
    documentWatch?.value,
    id,
    isLoadingDeliveryOrders,
    isLoadingManufactureOrders,
    isLoadingPurchaseOrders,
    isLoadingPurchaseRequests,
    isLoadingPurchaseReturns,
    isLoadingQuotations,
    isLoadingSalesOrders,
    isLoadingSalesReturns,
    tab,
  ]);

  if (loadingDocumentHandler()) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name={"document"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label={t("project.document")}
                options={options}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name={"status"}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                label={t("project.project.main_status")}
                options={documentStatusOptions}
                onChange={(_, value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <CustomizedButton
            type="submit"
            title={t("button.filter")}
            variant="contained"
            onClick={onFilterSubmit}
            fullWidth
            sx={{ height: "40px" }}
          />
        </Grid>
      </Grid>
      <RelatedDocumentList
        data={documentList}
        tab={tab}
        documentType={documentWatch?.value ?? ""}
      />
    </>
  );
};

export default RelatedDocumentTab;
