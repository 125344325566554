import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

import styled from "@emotion/styled";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import CheckedItemModal from "../../../../Table/CheckedItemModal";
import { CustomizedTooltip } from "../../../../Custom/CustomizedTooltip";
import ControlledTextField from "../../../../Controller/ControlledTextField";

import { ISkuDetail } from "../../../../../types/Inventory/item";

import { useDisable } from "../../../../../hooks/use-disable";
import ControlledServerSideCreatable from "../../../../Controller/ControlledSSCreatable";
import { EntityTypeEnum } from "../../../../../generated/creatable";

const ItemDetail = () => {
  const { t } = useTranslation();
  const [showSelectItem, setShowSelectItem] = useState<boolean>(false);

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [disabled] = useDisable();

  const StyledLink = styled(Link)<any>(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const watchItemId = useWatch({ control, name: "item_unique_id" });

  const watchReferenceList = useWatch({
    control,
    name: "reference_document_list",
  });

  const openItemTable = () => {
    setShowSelectItem(true);
  };

  const closeItemTable = () => {
    setShowSelectItem(false);
  };

  useEffect(() => {
    if (watchItemId) {
      setItemIds([watchItemId]);
      setItemIdsSnapshot([watchItemId]);
    }
  }, [watchItemId]);

  const finishItemSelectHandler = async (data: ISkuDetail) => {
    if (data) {
      setValue("item_unique_id", data.sku_name);
      setValue("item_name", data.item_sku?.item?.name);
      setValue("stock_uom", data.item_sku?.item?.stock_uom?.name);
    }
    closeItemTable();
  };

  return (
    <Box mt={3}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography ml={1} fontWeight="bold">
          {t("manufacture.order.item_detail")}
        </Typography>
        {!disabled && (
          <CustomizedTooltip
            title={
              watchReferenceList && watchReferenceList.length > 0
                ? "เลือกสินค้าใหม่ ที่อ้างอิงกับใบสั่งขายใบนี้"
                : "เลือกสินค้า"
            }
          >
            <IconButton
              onClick={openItemTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_unique_id"
            label={t("manufacture.order.item_unique_id")}
            control={control}
            error={Boolean(errors?.item_unique_id)}
            // helperText={
            //   errors?.item_unique_id && errors?.item_unique_id.message
            // }
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink
                  // onClick={handleClickOpenItem}
                  >
                    {watchItemId || t("manufacture.order.item_unique_id")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchItemId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
                      <IconButton
                        // onClick={() =>
                        //   handleClickOpenItem
                        //     ? handleClickOpenItem()
                        //     : undefined
                        // }
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_name"
            label={t("manufacture.order.item_name")}
            control={control}
            error={Boolean(errors?.item_name)}
            // helperText={errors?.item_name && errors?.item_name.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}></Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="stock_uom"
            label={t("manufacture.order.uom")}
            control={control}
            error={Boolean(errors?.stock_uom)}
            // helperText={errors?.stock_uom && errors?.stock_uom.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="mfg_type"
            dataName="mfg_type"
            documentType={EntityTypeEnum.ManufactureOrder}
            defaultOptions={[]}
            error={Boolean(errors.manufacture_type)}
            // helperText={errors.manufacture_type?.message}
            setValue={setValue}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <CheckedItemModal
        showSelectItem={showSelectItem}
        closeItemTable={closeItemTable}
        finishItemsSelect={finishItemSelectHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="single"
      />
    </Box>
  );
};

export default ItemDetail;
