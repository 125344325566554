import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { TFunction } from "i18next";
import { dateFilterParams } from "../../../../utils/Formatter/AgGridFilter";
import { IGoodsIssue } from "../../../../types/Inventory/goodsIssue";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { goodsIssueTypeValueFormatter } from "../../../../utils/Formatter/GoodsIssue";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GET_ALL_WAREHOUSES } from "../../../../services/AgGrid/InventoryAgGrid";
import { Warehouse } from "../../../../generated/inventory";
import { formatDate } from "../../../../utils/Formatter/Date";
import { statusValueFormatter } from "../../../../utils/Formatter/Global";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import { IAvatar } from "../../../../types/global";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import { GraphQLClient } from "graphql-request";

export const goodsIssueColumnDefs = (
  t: TFunction,
  referenceUniqueId?: string
) => {
  const columnDef: ColDef[] = [
    {
      field: "created_date",
      headerName: t("date.issue_date"),
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("date.issued_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      flex: 1,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "unique_id",
      headerName: t("sales.unique_id"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "reference_unique_id",
      headerName: t("sentence.reference_unique_id"),
      filter: "agTextColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value;
        } else {
          return "-";
        }
      },
      filterParams: {
        readOnly: !!referenceUniqueId,
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "source_warehouse",
      headerName: t("inventory.warehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value.name;
        } else {
          return "";
        }
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("sentence.type"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "sales_order",
          "manufacture_order",
          "purchase_return",
          "other",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          goodsIssueTypeValueFormatter(params.value),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        goodsIssueTypeValueFormatter(params.value),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "created_by",
      headerName: t("sales.created_by"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: ICellRendererParams<IGoodsIssue, IAvatar>) => {
        if (params.value) {
          if (params.value.img_url && params.value.img_url.length > 0) {
            return <CustomizedAvatar avatars={[params.value]} />;
          } else {
            return (
              <CustomizedLetterAvatar
                name={params.value.first_name + " " + params.value.last_name}
              />
            );
          }
        }
      },
      minWidth: 200,
      flex: 1,
    },
    {
      field: "remark",
      headerName: t("sentence.remark"),
      filter: "agTextColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    // {
    //   field: "aggrid_status",
    //   headerName: t("status.index"),
    //   filter: "agSetColumnFilter",
    //   cellRenderer: (params: ICellRendererParams<IGoodsIssue, string>) => {
    //     if (params.data?.flag_status && params.data.flag_status.length > 0) {
    //       const checkCurrentFlag = (flag: string) => {
    //         if (params.data) {
    //           return params.data.flag_status.includes(flag);
    //         }
    //         return false;
    //       };

    //       if (checkCurrentFlag("cancelled")) {
    //         return <CustomizedStatus status="cancelled" />;
    //       } else if (checkCurrentFlag("not_approved")) {
    //         return <CustomizedStatus status="not_approved" />;
    //       } else if (checkCurrentFlag("late")) {
    //         return <CustomizedStatus status="late" />;
    //       }
    //     } else {
    //       return <CustomizedStatus status={params.value} />;
    //     }
    //   },
    //   filterParams: {
    //     values: ["draft", "finished", "cancelled"],
    //     valueFormatter: (params: ValueFormatterParams) =>
    //       statusValueFormatter(params.value),
    //   },
    //   cellStyle: {
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //   },
    // },
    {
      field: "aggrid_status",
      headerName: t("status.index"),
      filter: "agSetColumnFilter",
      cellRenderer: (params: ICellRendererParams<IGoodsIssue, string>) => (
        <CustomizedStatus status={params.value} />
      ),
      filterParams: {
        values: ["draft", "finished", "cancelled"],
        valueFormatter: (params: ValueFormatterParams) =>
          statusValueFormatter(params.value),
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  return columnDef;
};
