import { gql } from "graphql-request";

export const QUOTATIONS_AGGRID = gql`
  query QuotationFindManyAggrid($aggridInput: AggridTemplateInput) {
    quotationFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        accepted_date
        accepted_remark
        aggrid_status
        additional_discount
        additional_discount_type
        created_date
        credit_day
        sales_contact_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        customer_contact {
          name
          phone
          identity_no
          branch
          fax
          email
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        customer_contact_unique_id
        due_date
        external_reference_id
        flag_status
        issue_date
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          item_img_url
          tracability
          qty
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
        main_status
        net_amount
        pre_vat_amount
        price_vat_type
        remark
        shipping_cost
        sub_status
        sub_total
        template_remark_id
        total_amount
        unique_id
        updated_date
        vat_0_amount
        vat_7_amount
        vat_amount
        vat_exempted_amount
        withholding_tax_amount
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
      }
    }
  }
`;

export const SALES_ORDER_AGGRID = gql`
  query SalesOrdersFindManyAggrid($aggridInput: AggridTemplateInput) {
    salesOrdersFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        aggrid_status
        additional_discount
        additional_discount_type
        created_date
        credit_day
        sales_contact_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        customer_contact {
          name
          phone
          identity_no
          branch
          fax
          email
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        customer_contact_unique_id
        delivery_date
        due_date
        external_reference_id
        flag_status
        id
        issue_date
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          item_img_url
          tracability
          qty
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
          # qty_shipped
          # qty_to_ship
          # qty_invoiced
          # qty_return
        }
        main_status
        net_amount
        pre_vat_amount
        price_vat_type
        remark
        shipping_cost
        sub_status
        sub_total
        tax_invoice_number
        template_remark_id
        total_amount
        unique_id
        vat_0_amount
        vat_7_amount
        vat_amount
        vat_exempted_amount
        withholding_tax_amount
        updated_date
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
      }
    }
  }
`;

export const SALES_RETURN_AGGRID = gql`
  query SalesReturnFindManyAggrids($aggridInput: AggridTemplateInput) {
    salesReturnFindManyAggrids(aggridInput: $aggridInput) {
      count
      data {
        aggrid_status
        additional_discount
        additional_discount_type
        created_date
        credit_day
        sales_contact_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        related_user_list {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
        customer_contact {
          name
          phone
          identity_no
          branch
          fax
          email
          billing_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
          delivery_address {
            address_type
            is_default
            is_same_as_default_address
            address_contact_name
            address_contact_phone
            address
            sub_district
            district
            province
            postal_code
            country
          }
        }
        customer_contact_unique_id
        external_reference_id
        flag_status
        is_effect_stock
        issue_date
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          item_img_url
          tracability
          qty
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
        main_status
        net_amount
        pre_vat_amount
        reason_to_return
        remark
        shipping_cost
        sub_status
        template_remark_id
        total_amount
        unique_id
        updated_date
        vat_0_amount
        vat_7_amount
        vat_exempted_amount
        withholding_tax_amount
        vat_amount
        created_by {
          user_unique_id
          first_name
          last_name
          email
          img_url
        }
      }
    }
  }
`;

export const SALES_ITEM_PRICE_HISTORY_AGGRID = gql`
  query SalesItemPriceHistoryFindManyAggrid($aggridInput: AggridTemplateInput) {
    salesItemPriceHistoryFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        id
        document_type
        reference_unique_id
        customer_contact_unique_id
        customer_full_name
        document_created_date
        document_issue_date
        item_unique_id
        item_name
        qty
        uom
        price_per_unit
        discount
        vat_percentage
        remark
      }
    }
  }
`;

export const QUOTATIONS_VIEW_AGGRID = gql`
  query QuotationViewFindManyAggrid($aggridInput: AggridTemplateInput) {
    quotationViewFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        accepted_date
        accepted_remark
        aggrid_status
        additional_discount
        additional_discount_type
        created_date
        credit_day
        sales_contact_list
        related_user_list
        customer_contact
        customer_contact_unique_id
        due_date
        external_reference_id
        flag_status
        issue_date
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          item_img_url
          tracability
          qty
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
        main_status
        net_amount
        pre_vat_amount
        price_vat_type
        remark
        shipping_cost
        sub_status
        sub_total
        template_remark_id
        total_amount
        unique_id
        updated_date
        vat_0_amount
        vat_7_amount
        vat_amount
        vat_exempted_amount
        withholding_tax_amount
        created_by
      }
    }
  }
`;

export const SALES_ORDER_VIEW_AGGRID = gql`
  query SalesOrdersViewFindManyAggrid($aggridInput: AggridTemplateInput) {
    salesOrdersViewFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        created_date
        updated_date
        issue_date
        due_date
        delivery_date
        credit_day
        external_reference_id
        customer_contact_unique_id
        flag_status
        main_status
        sub_status
        remark
        additional_discount
        additional_discount_type
        pre_vat_amount
        sub_total
        shipping_cost
        total_amount
        net_amount
        vat_0_amount
        vat_7_amount
        vat_amount
        vat_exempted_amount
        withholding_tax_amount
        aggrid_status
        unique_id
        tax_invoice_number
        sales_contact_list
        related_user_list
        item_list {
          reference_unique_id
          reference_document_type
          item_unique_id
          item_name
          item_sku_desc
          item_img_url
          tracability
          qty
          price_per_unit
          discount
          vat_percentage
          pre_vat_amount
          withholding_tax_type
          withholding_tax_value
          remark
          stock_qty
          uom
        }
        created_by
        related_user_unique_id_list
        customer_contact
        template_remark_id
      }
    }
  }
`;

export const SALES_RETURN_VIEW_AGGRID = gql`
  query SalesReturnsViewFindManyAggrid($aggridInput: AggridTemplateInput) {
    salesReturnsViewFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        aggrid_status
        additional_discount
        additional_discount_type
        created_date
        credit_day
        sales_contact_list
        related_user_list
        customer_contact
        customer_contact_unique_id
        external_reference_id
        flag_status
        is_effect_stock
        issue_date
        item_list
        main_status
        net_amount
        pre_vat_amount
        reason_to_return
        remark
        shipping_cost
        sub_status
        template_remark_id
        total_amount
        unique_id
        updated_date
        vat_0_amount
        vat_7_amount
        vat_exempted_amount
        withholding_tax_amount
        vat_amount
        created_by
      }
    }
  }
`;
