import { useDraggable } from "@dnd-kit/core";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";

interface Props {
  editMode: boolean;
  id: string;
  contentType: string;
  content: any;
  scale: number;
  styles?: any;
  startX: number;
  startY: number;
  width: number;
  height: number;
  documentWidth: number;
  documentHeight: number;
  documentFont: string;
  itemPerPage: number;
  itemListMargin: number;
  customItemListMargin: number;
  isLineItem?: boolean;
  textAlign: "left" | "center" | "right";
}

const DraggableItem = ({
  editMode = false,
  id,
  contentType = "text",
  content,
  scale = 1,
  styles,
  startX = 0,
  startY = 0,
  width = 150,
  height = 20,
  documentWidth,
  documentHeight,
  documentFont = "Tahoma",
  itemPerPage,
  itemListMargin = 2,
  customItemListMargin,
  isLineItem = false,
  textAlign = "left",
}: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  const [, setCurrentX] = useState<number>(startX || 0);
  const [, setCurrentY] = useState<number>(startY || 0);

  const maxX = documentWidth - width;
  const maxY = documentHeight - height;

  useEffect(() => {
    if (transform) {
      // Calculate current position based on initial position and transform
      let newX = startX + transform.x;
      let newY = startY + transform.y;
      if (newX <= 0) newX = 0;
      if (newX >= maxX) newX = maxX;
      if (newY <= 0) newY = 0;
      if (newY >= maxY) newY = maxY;
      setCurrentX(newX);
      setCurrentY(newY);
    }
  }, [maxX, maxY, startX, startY, transform]);

  const initialTransform = {
    display: "block",
    position: "absolute",
    wordWrap: "break-word",
    cursor: editMode ? "move" : "",
  };

  const CustomStyle = {
    backgroundColor: editMode ? "rgba(255, 65, 143, 0.3)" : "",
    left: `${startX}px`,
    top: `${startY}px`,
    width: `${width}px`,
    height: `${height}px`,
    fontSize: `${scale}em`,
    fontFamily: documentFont === "system" ? "unset" : documentFont,
    overflow: "hidden",
    textAlign: textAlign,
    // actual render user maxHeight
    // maxHeight: `${height}px`,
  };

  const ImageStyle = {
    width: width,
    height: height,
    backgroundSize: "contain",
  };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : { display: `none` };

  const renderLineItems = () => {
    const allLineItems = [];
    for (let i = 1; i < itemPerPage; i++) {
      const marginY = customItemListMargin
        ? customItemListMargin
        : itemListMargin;
      // console.log("customItemListMargin", customItemListMargin);
      // console.log("itemListMargin", itemListMargin);
      // console.log("marginY", marginY);
      let newY = Number(startY) + Number(height) * i + Number(marginY) * i;
      // console.log("content", content);
      // console.log("startY", startY);
      // console.log("height", height);
      // console.log("i", i);
      // console.log("marginY", marginY);
      // console.log("newY", newY);
      const CustomStyleLineItem = {
        top: `${newY}px`,
      };
      allLineItems.push(
        <div
          ref={setNodeRef}
          style={{
            ...style,
            ...CustomStyle,
            ...CustomStyleLineItem,
            ...styles,
            ...initialTransform,
          }}
          {...listeners}
          {...attributes}
        ></div>
      );
    }
    return allLineItems;
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={{ ...style, ...CustomStyle, ...styles, ...initialTransform }}
        {...listeners}
        {...attributes}
      >
        {contentType === "text" && <div>{content}</div>}
        {contentType === "qr" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <QRCode value={content} size={scale} />
          </div>
        )}
        {contentType === "image" && (
          <div style={{ ...ImageStyle }}>
            {content && (
              <img
                alt="item-img"
                width={width}
                height={height}
                src={content}
                style={{ ...ImageStyle }}
              />
            )}
          </div>
        )}

        {/* <div>
              Current Position: ({currentX}, {currentY})
            </div> */}
      </div>

      {isLineItem && editMode && renderLineItems()}
    </>
  );
};

export default DraggableItem;
