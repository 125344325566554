import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import GoodsIssueDetail from "../../../components/Form/Inventory/GoodsIssue/Detail";
import GoodsIssueHeader from "../../../components/Form/Inventory/GoodsIssue/Header";
import { IDefaultForm } from "../../../types/global";
import { GoodsIssueQuery, WarehousesQuery } from "../../../generated/inventory";
import { UseFieldArrayAppend } from "react-hook-form";
import { IGoodsIssue } from "../../../types/Inventory/goodsIssue";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsIssueQuery, unknown>>;
  data?: GoodsIssueQuery;
  warehouseData?: WarehousesQuery;
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
};

const DocumentInfoTab = ({
  control,
  append,
  disabled,
  errors,
  getValues,
  refetch,
  setValue,
  data,
  warehouseData,
  reset,
}: Props) => {
  return (
    <>
      <GoodsIssueHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsIssue?.aggrid_status}
        refetch={refetch}
      />
      <GoodsIssueDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        status={data?.goodsIssue?.aggrid_status}
        append={append}
      />
    </>
  );
};

export default DocumentInfoTab;
