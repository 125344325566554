import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import PurchaseOrderTab from "./PurchaseOrderTab";

const PurchaseRequestContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
      to: "/purchase/request",
    },
    {
      name: id ?? `${t("button.create")}${t("purchase.request.index")}`,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("purchase.document_info"),
      path: `${pathname}`,
    },
    {
      label: t("purchase.order.index"),
      path: `${pathname}?tab=order`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "order":
        setCurrentTab(pathname + "?tab=order");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "order":
        return <PurchaseOrderTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </>
  );
};

export default PurchaseRequestContainer;
