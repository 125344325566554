import React from "react";
import { IDefaultForm } from "../../../types/global";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DocDropzoneUI from "../../UI/DocDropzoneUI";

const ProjectAttachment = ({
  control,
  setValue,
  getValues,
  disabled,
  errors,
}: IDefaultForm) => {
  const { t } = useTranslation();

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            {t("project.project.attachment_list")}
          </Typography>
        </Grid>
        <Grid item xs>
          <DocDropzoneUI
            control={control}
            name="attachment_list"
            setValue={setValue}
            disabled={disabled}
            multiple
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default ProjectAttachment;
