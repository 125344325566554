import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useState, useCallback, useEffect } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { IManufactureOrder } from "../../../types/Manufacture/order";

import ButtonLayout from "../../../components/UI/ButtonLayout";
import Confirmation from "../../../components/UI/Confirmation";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ManufactureOrderHeaderForm from "../../../components/Form/Manufacture/Order/Header";
import {
  manufactureOrderSchema,
  manufactureOrderValidation,
} from "../../../components/Form/Manufacture/Order/schema";
import ManufactureOrderRemarkForm from "../../../components/Form/Manufacture/Order/Remark";
import ManufactureOrderDetailForm from "../../../components/Form/Manufacture/Order/Detail";
import ManufactureOrderTableForm from "../../../components/Form/Manufacture/Order/Table";
import ManufactureOrderAttachment from "../../../components/Form/Manufacture/Order/Detail/Attachment";

import {
  BomQuery,
  ManufactureEntityType,
  ManufactureOrderQuery,
  useBomQuery,
  useManufactureDocumentNextStatusMutation,
  useManufactureOrderCreateMutation,
  useManufactureOrderQuery,
  useManufactureOrderUpdateMutation,
} from "../../../generated/manufacture";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { useConfirmation } from "../../../hooks/use-confrimation";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  copyManufactureOrderformatter,
  formatterManufactureOrder,
  formatQueryManufactureOrder,
} from "../../../utils/Formatter/ManufactureOrder";

import { IBom } from "../../../types/Manufacture/bom";
import { IIngredient } from "../../../types/Manufacture";
import { Box, CircularProgress, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useManufactureError } from "../../../hooks/Manufacture/use-manufacture-error";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [changeStatus, setChangeStatus] = useState<boolean>(false);
  const [changeWorkOrderStatus, setChangeWorkOrderStatus] = useState(false);
  const [roundedProductionQty, setRoundedProductionQty] = useState(0);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [ingredientItemIds, setIngredientItemIds] = useState<string[]>([]);
  const [ingredientItemIdsSnapshot, setIngredientItemIdsSnapshot] = useState<
    string[]
  >([]);
  const [wasteProductionItemIds, setWasteProductionItemIds] = useState<
    string[]
  >([]);
  const [wasteProductionItemIdsSnapshot, setWasteProductionItemIdsSnapshot] =
    useState<string[]>([]);

  const {
    control,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm<IManufactureOrder>({
    defaultValues: manufactureOrderSchema,
    resolver: yupResolver(manufactureOrderValidation),
  });

  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const {
    fields: ingredientFields,
    append: ingredientAppend,
    move: ingredientMove,
    replace: ingredientReplace,
    remove: ingredientRemove,
    update: ingredientUpdate,
  } = useFieldArray({
    control,
    name: "ingredient_list",
  });

  const {
    fields: workOrderFields,
    append: workOrderAppend,
    move: workOrderMove,
    remove: workOrderRemove,
    update: workOrderUpdate,
    replace: workOrderReplace,
  } = useFieldArray({
    control,
    name: "work_order_list",
  });

  const watchBomName = useWatch({ control, name: "bom_name" });
  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const {
    data: moData,
    isSuccess: isSuccessMo,
    isLoading,
  } = useManufactureOrderQuery<ManufactureOrderQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { unique_id: id },
    },
    { enabled: !!id }
  );

  const {
    data: bomData,
    refetch: bomRefetch,
    isLoading: isBomLoading,
  } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { name: watchBomName },
    },
    {
      enabled: Boolean(watchBomName),
    }
  );

  const { mutate: createMo, isLoading: isCreating } =
    useManufactureOrderCreateMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onMutate() {
          if (status && status !== "draft") setDisabled(true);
        },
        onSuccess({ manufactureOrderCreate }) {
          navigate(`/manufacture/order/${manufactureOrderCreate?.unique_id}`);
          if (changeStatus) {
            nextStatusMo({
              documentInput: {
                unique_id: manufactureOrderCreate?.unique_id || "",
                reference_document_type: ManufactureEntityType.ManufactureOrder,
              },
            });
          } else {
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
          }
        },
        onError(error) {
          const duplicatedUniqueId = errorMessageFormatter(error);
          if (duplicatedUniqueId) {
            enqueueSnackbar(duplicatedUniqueId, {
              variant: "error",
            });
          } else {
            if (changeStatus) {
              enqueueSnackbar("ยืนยันไม่สำเร็จ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
                variant: "error",
              });
            }
          }
          setDisabled(false);
        },
      }
    );

  const { mutateAsync: updateMo, isLoading: isUpdating } =
    useManufactureOrderUpdateMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onMutate() {
          if (status && status !== "draft") setDisabled(true);
        },
        onSuccess({ manufactureOrderUpdate }) {
          if (changeWorkOrderStatus) {
            enqueueSnackbar("เปลี่ยนสถานะขั้นตอนงานสำเร็จ", {
              variant: "success",
            });
            setChangeWorkOrderStatus(false);
          } else if (changeStatus) {
            nextStatusMo({
              documentInput: {
                unique_id: manufactureOrderUpdate?.unique_id || "",
                reference_document_type: ManufactureEntityType.ManufactureOrder,
              },
            });
          } else {
            enqueueSnackbar("แก้ไขใบสั่งผลิตสำเร็จ", {
              variant: "success",
            });
          }
          reset(
            formatQueryManufactureOrder(
              manufactureOrderUpdate
            ) as IManufactureOrder
          );
        },
        onError() {
          if (changeWorkOrderStatus) {
            enqueueSnackbar("เปลี่ยนสถานะขั้นตอนงานไม่สำเร็จ", {
              variant: "error",
            });
            setChangeWorkOrderStatus(false);
          } else {
            enqueueSnackbar("แก้ไขใบสั่งผลิตไม่สำเร็จ", {
              variant: "error",
            });
          }
          setDisabled(false);
        },
      }
    );

  const { mutateAsync: nextStatusMo, isLoading: isChangingStatus } =
    useManufactureDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onSuccess({ manufactureDocumentNextStatus }) {
          setValue("aggrid_status", manufactureDocumentNextStatus?.status_name);
          setValue("main_status", manufactureDocumentNextStatus?.status_name);
          enqueueSnackbar("ยืนยันใบสั่งผลิตสำเร็จ", {
            variant: "success",
          });
        },
        onError() {},
      }
    );

  useEffect(() => {
    switch (status) {
      case "pending_manu":
      case "in_progress":
      case "finished":
      case "cancelled":
        setDisabled(true);
        setShowButton(false);
        break;
      default:
        setDisabled(false);
        setShowButton(true);
    }
  }, [status]);

  const fetchMo = useCallback(() => {
    if (isSuccessMo) {
      const moType = formatQueryManufactureOrder(moData.manufactureOrder);
      const ingredientUniqueIdList: string[] =
        moData?.manufactureOrder?.ingredient_list?.map(
          (ingredient: any) => ingredient.item_unique_id
        ) as string[];
      const wasteUniqueIdList: string[] =
        moData?.manufactureOrder?.waste_production_list?.map(
          (waste: any) => waste.item_unique_id
        ) as string[];
      setIngredientItemIds(ingredientUniqueIdList);
      setIngredientItemIdsSnapshot(ingredientUniqueIdList);
      setWasteProductionItemIds(wasteUniqueIdList);
      setWasteProductionItemIdsSnapshot(wasteUniqueIdList);
      reset({ ...moType });
    }
  }, [isSuccessMo, moData?.manufactureOrder, reset]);

  const ingredientQtyCalculation = useCallback(
    async (bomDetail?: IBom) => {
      let bom_detail = null;
      if (bomDetail) bom_detail = bomDetail;
      else bom_detail = getValues("bom_detail") as IBom;
      const { ingredient_list, mfg_qty } = bom_detail;
      if (mfg_qty && parseInt(mfg_qty) > 0) {
        const currentProductionQty = getValues("production_qty") || 0;
        const ingredient_fields = getValues("ingredient_list") || [];
        const new_mfg_qty = parseInt(mfg_qty);
        const ratio = currentProductionQty / new_mfg_qty;
        const roundedRatio = Math.ceil(ratio);
        const recommendProductionQty = roundedRatio * new_mfg_qty;
        setRoundedProductionQty(recommendProductionQty);
        ingredient_list?.forEach((ingredient: IIngredient) => {
          let foundIndex = undefined;
          if (ingredient_fields.length === 0)
            foundIndex = ingredient_list.findIndex(
              (bomIngredient) => bomIngredient.cuid === ingredient?.cuid
            );
          else
            foundIndex = ingredient_fields.findIndex(
              (bomIngredient) => bomIngredient.cuid === ingredient?.cuid
            );
          if (foundIndex !== -1) {
            const qty = ratio * parseInt(ingredient?.qty || "0");
            const roundedQty = halfRoundDecimal(qty);
            ingredientUpdate(foundIndex, {
              ...ingredient_list[foundIndex],
              qty: roundedQty.toString(),
              snapshot_qty: roundedQty,
              unrounded_qty: qty,
              good_issue_qty:
                ingredient_fields[foundIndex]?.good_issue_qty || undefined,
            });
          }
        });
      }
    },
    [getValues, ingredientUpdate]
  );

  useEffect(() => {
    const localState = localStorage.getItem("mo state");

    if (id) {
      fetchMo();
    } else if (state) {
      reset((prev) => ({ ...prev, ...state }));
    } else if (localState) {
      reset((prev) => ({ ...prev, ...JSON.parse(localState) }));
    }
    localStorage.removeItem("mo state");
  }, [fetchMo, id, reset, state]);

  useEffect(() => {
    if (watchBomName && watchBomName !== "") {
      if (bomData) {
        setValue("bom_id", bomData?.bom?.id);
        setValue("bom_detail", bomData?.bom);

        ingredientQtyCalculation();
      }
    }
  }, [bomData, getValues, ingredientQtyCalculation, setValue, watchBomName]);

  const halfRoundDecimal = (qty: number) => {
    // const newQty = qty.toFixed(4);
    // const absNumber = Math.floor(qty);
    // const decimal = parseFloat(newQty) - absNumber;
    // if (decimal === 0) return absNumber;
    // else if (decimal < 0.5) return absNumber + 0.5;
    // else
    return Math.ceil(qty);
  };

  const checkLowerQtyModal = (data: IManufactureOrder): boolean => {
    if (data?.ingredient_list && data?.ingredient_list.length > 0) {
      const isLower = data?.ingredient_list?.some((ingredient) => {
        if (ingredient.qty && ingredient.snapshot_qty)
          return parseInt(ingredient.qty) < ingredient.snapshot_qty;
        else return false;
      });
      return isLower || false;
    } else return false;
  };

  const copyDocumentHandler = () => {
    const typeMo = moData?.manufactureOrder as IManufactureOrder;
    navigate("/manufacture/order/add", {
      state: copyManufactureOrderformatter(typeMo),
    });
  };

  const handleClickOpenItem = async (unique_id?: string | null) => {
    if (unique_id && typeof unique_id === "string") {
      window.open(
        `/inventory/items/${unique_id}?tab=item&subtab=general`,
        "_blank"
      );
    } else {
      const unique_id = getValues("item_unique_id");
      if (unique_id && unique_id !== "")
        window.open(
          `/inventory/items/${unique_id}?tab=item&subtab=general`,
          "_blank"
        );
    }
  };

  const acceptConfirmationAction = async () => {
    const data = getValues();
    const { unique_id, ...otherData } = formatterManufactureOrder(data);
    setChangeStatus(true);
    if (!id)
      createMo({
        createInput: {
          ...otherData,
          unique_id,
          sub_status: "pending_manu",
          ingredient_list: otherData.ingredient_list?.map(
            ({ cuid, ...other }) => other
          ),
          waste_production_list: otherData.waste_production_list?.map(
            ({ cuid, ...other }) => other
          ),
        },
      });
    else
      updateMo({
        uniqueInput: { unique_id: unique_id },
        updateInput: { ...otherData, sub_status: "pending_manu" },
      });
  };

  const lowerQtyConfirmationAction = async (data: IManufactureOrder) => {
    if (!data.sub_status || data.sub_status === "draft") {
      if (changeStatus) {
        submitHandler(data, true);
      } else {
        saveHandler(data, true);
      }
    } else {
      saveHandler(data, true);
    }
  };

  const saveHandler = async (
    data: IManufactureOrder,
    isAcceptQty?: boolean
  ) => {
    setChangeStatus(false);
    let bomData: BomQuery | undefined = undefined;
    const { unique_id, ...otherData } = formatterManufactureOrder(data);
    const { ingredient_list, waste_production_list } = otherData;
    if (watchBomName && watchBomName !== "") {
      const { data: bomFetch } = await bomRefetch();
      bomData = bomFetch;
    }
    if (
      bomData &&
      bomData.bom?.item_unique_id !== data.item_unique_id &&
      data.sub_status === "draft"
    ) {
      openItemChangeConfirmationHandler();
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        if (!id)
          createMo({
            createInput: {
              ...otherData,
              unique_id,
              sub_status: "draft",
              ingredient_list: ingredient_list?.map(
                ({ cuid, ...other }) => other
              ),
              waste_production_list: waste_production_list?.map(
                ({ cuid, ...other }) => other
              ),
            },
          });
        else
          updateMo({
            uniqueInput: { unique_id: unique_id },
            updateInput: otherData,
          });
      } else {
        openLowerQtyConfirmationHandler();
      }
    }
  };

  const updateWorkOrderStatusHandler = async (data: IManufactureOrder) => {
    const { unique_id, work_order_list } = formatterManufactureOrder(data);
    setChangeWorkOrderStatus(true);
    updateMo({
      uniqueInput: { unique_id: unique_id },
      updateInput: { work_order_list },
    });
  };

  const submitHandler = async (
    data: IManufactureOrder,
    isAcceptQty?: boolean
  ) => {
    setChangeStatus(true);
    let bomData: BomQuery | undefined = undefined;
    if (watchBomName && watchBomName !== "") {
      const { data: bomFetch } = await bomRefetch();
      bomData = bomFetch;
    }
    if (
      bomData &&
      bomData.bom?.item_unique_id !== data.item_unique_id &&
      data.sub_status === "draft"
    ) {
      openItemChangeConfirmationHandler();
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        openAcceptConfirmationHandler();
      } else {
        openLowerQtyConfirmationHandler();
      }
    }
  };

  const {
    confirmation: lowerQtyConfirmation,
    openConfirmationHandler: openLowerQtyConfirmationHandler,
    closeConfirmationHandler: closeLowerQtyConfirmationHandler,
    submitConfirmationHandler: submitLowerQtyConfirmationHandler,
  } = useConfirmation(handleSubmit((data) => lowerQtyConfirmationAction(data)));

  const {
    confirmation: itemChangeConfirmation,
    openConfirmationHandler: openItemChangeConfirmationHandler,
    closeConfirmationHandler: closeItemChangeConfirmationHandler,
    submitConfirmationHandler: submitItemChangeConfirmationHandler,
  } = useConfirmation();

  const {
    confirmation: acceptConfirmation,
    openConfirmationHandler: openAcceptConfirmationHandler,
    closeConfirmationHandler: closeAcceptConfirmationHandler,
    submitConfirmationHandler: submitAcceptConfirmationHandler,
  } = useConfirmation(acceptConfirmationAction);

  useManufactureError(errors);

  if (
    id &&
    (isLoading ||
      isCreating ||
      isUpdating ||
      isChangingStatus ||
      (isBomLoading && Boolean(watchBomName)))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <ManufactureOrderHeaderForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        setDisabled={setDisabled}
        copyDocumentHandler={copyDocumentHandler}
        // currentState={state?.id ?? manufactureOrder}
      />
      <ManufactureOrderDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        roundedProductionQty={roundedProductionQty}
        ingredientReplace={ingredientReplace}
        workOrderReplace={workOrderReplace}
        ingredientQtyCalculation={ingredientQtyCalculation}
        handleClickOpenItem={handleClickOpenItem}
      />
      <ManufactureOrderTableForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        ingredientFields={ingredientFields}
        ingredientAppend={ingredientAppend}
        ingredientMove={ingredientMove}
        ingredientRemove={ingredientRemove}
        workOrderFields={workOrderFields}
        workOrderMove={workOrderMove}
        workOrderAppend={workOrderAppend}
        workOrderRemove={workOrderRemove}
        workOrderUpdate={workOrderUpdate}
        workOrderReplace={workOrderReplace}
        handleClickOpenItem={handleClickOpenItem}
        disabled={disabled}
        ingredientItemIds={ingredientItemIds}
        setIngredientItemIds={setIngredientItemIds}
        ingredientItemIdsSnapshot={ingredientItemIdsSnapshot}
        setIngredientItemIdsSnapshot={setIngredientItemIdsSnapshot}
        wasteProductionItemIds={wasteProductionItemIds}
        setWasteProductionItemIds={setWasteProductionItemIds}
        wasteProductionItemIdsSnapshot={wasteProductionItemIdsSnapshot}
        setWasteProductionItemIdsSnapshot={setWasteProductionItemIdsSnapshot}
        workOrderUpdateSubmit={handleSubmit((data) =>
          updateWorkOrderStatusHandler(data)
        )}
      />
      <ManufactureOrderRemarkForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
      />
      <ManufactureOrderAttachment
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
      />
      <Confirmation
        title={t("manufacture.order.sentence.accept_header")}
        message={t("manufacture.order.sentence.lower_qty_message")}
        open={lowerQtyConfirmation}
        handleClose={closeLowerQtyConfirmationHandler}
        action={submitLowerQtyConfirmationHandler}
        maxWidth={"sm"}
      />
      <Confirmation
        title={t("manufacture.order.sentence.change_bom_item_header")}
        message={t("manufacture.order.sentence.change_bom_item_message")}
        open={itemChangeConfirmation}
        handleClose={closeItemChangeConfirmationHandler}
        action={submitItemChangeConfirmationHandler}
        maxWidth={"sm"}
      />
      <Confirmation
        title={t("manufacture.order.sentence.accept_header")}
        message={t("manufacture.order.sentence.accept_message")}
        open={acceptConfirmation}
        handleClose={closeAcceptConfirmationHandler}
        action={submitAcceptConfirmationHandler}
      />
      {(showButton || (id && !disabled && !showButton)) && (
        <BottomNavbar>
          {showButton && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit((data) => saveHandler(data, false))}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.confirm")}
                onClick={handleSubmit((data) => submitHandler(data, false))}
              />
            </Stack>
          )}
          {id && !disabled && !showButton && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => navigate("/manufacture/order")}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit((data) => saveHandler(data, false))}
              />
            </Stack>
          )}
        </BottomNavbar>
      )}
    </form>
  );
};

export default DocumentInfoTab;
