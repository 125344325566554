import { useMemo } from "react";
import { IEvent } from "../../../types/Project";
import { IComboBoxOption } from "../../../types/global";

export const useEventListOption = (allEvents: IEvent[]) => {
  const defaultOptions: IComboBoxOption[] = useMemo(() => {
    const allOption = { id: "ALL", label: "ทั้งหมด", value: "ทั้งหมด" };
    const otherOptions = allEvents?.map((event: IEvent) => {
      const formattedOption = {
        id: event.id,
        label: event.name,
        value: event.name,
      };
      return formattedOption;
    });

    return [allOption, ...otherOptions];
  }, [allEvents]);

  return {
    eventListOptions: defaultOptions,
  };
};
