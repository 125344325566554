import { Box, Skeleton, Typography } from "@mui/material";
import {
  CompanyQuery,
  useCompanyQuery,
} from "../../../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { QRCodeSVG } from "qrcode.react";
import { formatContactName } from "../../../../utils/Formatter/Global";

interface Props {
  documentName: string;
  noCompany?: boolean;
  imgSize?: number;
}

const PDFHeader = ({ documentName, noCompany, imgSize }: Props) => {
  const tenantId = sessionStorage.getItem("tenant-id");

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isLoading } = useCompanyQuery<CompanyQuery>(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: {
        unique_id: tenantId,
      },
    }
  );

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  const defaultAddress =
    data?.company &&
    data.company.address_list &&
    data.company.address_list.find((address) => address.is_default);

  const phoneNumber =
    data?.company &&
    data.company.contact_channel_list &&
    data.company.contact_channel_list
      .filter((channel) => channel.contact_channel_type === "เบอร์โทรศัพท์")
      .map((c) => c.contact_channel_name);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box sx={{ width: 120, height: 120 }}>
            <img
              alt="npr-erp-logo"
              src={
                data?.company?.img_url
                  ? data?.company.img_url[0]
                    ? data?.company.img_url[0]
                    : "/static/logo.png"
                  : "/static/logo.png"
              }
              className="image-no-hover"
            />
          </Box>
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {formatContactName(
                  "นิติบุคคล",
                  data?.company?.type || "",
                  "",
                  data?.company?.name || "",
                  ""
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                {defaultAddress?.address || ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                {defaultAddress?.sub_district || ""}{" "}
                {defaultAddress?.district || ""}{" "}
                {defaultAddress?.province || ""}{" "}
                {defaultAddress?.postal_code || ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                โทรศัพท์{" "}
                {phoneNumber && phoneNumber.length > 1
                  ? phoneNumber?.join(", ")
                  : "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: "24px",
                }}
              >
                เลขประจำตัวผู้เสียภาษีอากร {data?.company?.identity_no || "-"}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {documentName !== "โอนย้าย" && documentName !== "สูตรการผลิต/BOM" && (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                textAlign: "right",
                color:
                  documentName === "ใบขอซื้อ/Purchase Request"
                    ? "#4A22AA"
                    : undefined,
              }}
            >
              (ต้นฉบับ/Original)
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              color:
                documentName === "ใบขอซื้อ/Purchase Request"
                  ? "#4A22AA"
                  : undefined,
            }}
          >
            {documentName}
          </Typography>
          {documentName !== "ใบส่งของ" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: ".25rem",
                marginTop: "0.5rem",
              }}
            >
              <QRCodeSVG value={window.location.href} size={72} />
              <Typography sx={{ fontSize: 12 }}>สแกนดูเอกสาร</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
