import { useCallback, useState } from "react";
import { useWatch } from "react-hook-form";
import { useMemo } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import ControlledDatePicker from "../Controller/ControlledDatePicker";
import ControlledSelect from "../Controller/ControlledSelect";
import { IDefaultForm, ISelectOption } from "../../types/global";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import CustomizedButton from "../Custom/CustomizedButton";
import { GET_ALL_WAREHOUSES } from "../../services/AgGrid/InventoryAgGrid";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

interface Props {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  onFilterReset: () => void;
  getFilteredData: () => void;
  isLoading?: boolean;
}

export default function ReportDateWithWarehouseFilter({
  control,
  setValue,
  getValues,
  onFilterReset,
  isLoading,
  getFilteredData,
}: Props) {
  const { t } = useTranslation();
  const watchDateType = useWatch({ control, name: "dateType" });
  const [dateFromOpen, setDateFromOpen] = useState<boolean>(false);
  const [dateToOpen, setDateToOpen] = useState<boolean>(false);
  const [warehouseOptions, setWarehouseOptions] = useState<ISelectOption[]>([]);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  useEffect(() => {
    const getWarehouseOptions = async () => {
      const { warehouses } = await graphQLClient.request(GET_ALL_WAREHOUSES);

      const warehouseOptions = [
        { label: "ทั้งหมด", value: "" },
        ...warehouses.map((warehouse: any) => {
          return {
            label: warehouse.name,
            value: warehouse.unique_id,
          };
        }),
      ];

      setWarehouseOptions(warehouseOptions);
    };

    getWarehouseOptions();
  }, [graphQLClient]);

  const filterChangedDate = useCallback(
    (startDate: Date, endDate: Date) => {
      setValue("dateFrom", startDate);
      setValue("dateTo", endDate);
    },
    [setValue]
  );

  const dateOption = useMemo(
    () => [
      {
        label: "วันนี้",
        value: "today",
      },
      { label: "เมื่อวาน", value: "yesterday" },
      { label: "7 วันที่แล้ว", value: "lastWeek" },
      { label: "14 วันที่แล้ว", value: "lastTwoWeek" },
      { label: "30 วันที่แล้ว", value: "last30Days" },
      // { label: "เดือนนี้", value: "currentMonth" },
      // { label: "เดือนที่แล้ว", value: "lastMonth" },
      // { label: "3 เดือนที่แล้ว", value: "lastThreeMonth" },
      // { label: "ปีนี้", value: "currentYear" },
      // { label: "ปีที่แล้ว", value: "lastYear" },
    ],
    []
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("dateType");
    let startDate = dayjs().startOf("day").subtract(1, "day").toDate();
    let endDate = dayjs().endOf("day").toDate();
    if (dateType === "today") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "yesterday") {
      startDate = dayjs().startOf("day").subtract(1, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "lastWeek") {
      startDate = dayjs().startOf("day").subtract(6, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "lastTwoWeek") {
      startDate = dayjs().startOf("day").subtract(13, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "last30Days") {
      startDate = dayjs().startOf("day").subtract(29, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "currentMonth") {
      startDate = dayjs().startOf("month").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "lastMonth") {
      startDate = dayjs().startOf("month").subtract(1, "month").toDate();
      endDate = dayjs().endOf("month").subtract(1, "month").toDate();
    } else if (dateType === "lastThreeMonth") {
      startDate = dayjs().startOf("month").subtract(2, "month").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "currentYear") {
      startDate = dayjs().startOf("year").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "lastYear") {
      startDate = dayjs().startOf("year").subtract(1, "year").toDate();
      endDate = dayjs().endOf("year").subtract(1, "year").toDate();
    }
    return filterChangedDate(startDate, endDate);
  }, [filterChangedDate, getValues]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  return (
    <CustomizedBox>
      <Typography fontWeight="bold" mb={2}>
        ตัวกรองแสดงผล
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledSelect
            name="dateType"
            control={control}
            options={dateOption}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledDatePicker
            control={control}
            name="dateFrom"
            label={t("date.from")}
            open={dateFromOpen}
            onClose={() => setDateFromOpen(false)}
            onOpen={() => setDateFromOpen(true)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledDatePicker
            control={control}
            name="dateTo"
            label={t("date.to")}
            open={dateToOpen}
            onClose={() => setDateToOpen(false)}
            onOpen={() => setDateToOpen(true)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <ControlledSelect
            name="source_warehouse_unique_id"
            control={control}
            options={warehouseOptions}
            displayEmpty
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <CustomizedButton
            title={t("button.filter")}
            variant="contained"
            onClick={getFilteredData}
            disabled={isLoading}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
          <CustomizedButton
            title={t("button.reset_filter")}
            variant="outlined"
            onClick={onFilterReset}
            disabled={isLoading}
            fullWidth
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
}
