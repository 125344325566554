import { TableCell, TableRow, Typography } from "@mui/material";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import { formatDate } from "../../utils/Formatter/Date";
import CustomizedAvatar from "../../components/Custom/CustomizedAvatar";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IProjectRelatedDocument } from "../../types/Project";
import { formatNumber } from "../../utils/dataTransformer";
import { EntityTypeEnum } from "../../generated/creatable";

export const useProjectRelatedDocumentCells = (
  documentType: EntityTypeEnum | string,
  tab: string
) => {
  dayjs.extend(relativeTime);

  const renderDueInCell = (inputDate: Date | string | undefined) => {
    const today = dayjs().startOf("day");
    const inputDateDay = dayjs(inputDate).startOf("day");
    const relativeRange = inputDateDay.diff(today, "day");

    if (relativeRange < 0) {
      return (
        <TableCell align="center">
          <Typography
            color={(theme) => theme.palette.error.main}
          >{`เลยมา ${Math.abs(relativeRange)} วัน`}</Typography>
        </TableCell>
      );
    } else if (relativeRange > 0) {
      return (
        <TableCell align="center">
          <Typography
            color={(theme) => theme.palette.success.main}
          >{`อีก ${relativeRange} วัน`}</Typography>
        </TableCell>
      );
    } else {
      return (
        <TableCell align="center">
          <Typography color={(theme) => theme.palette.warning.main}>
            {"วันนี้"}
          </Typography>
        </TableCell>
      );
    }
  };

  const renderDefaultRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderQuotationRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.due_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.due_date)}
      </TableRow>
    );
  };

  const renderSalesOrderRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.delivery_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.delivery_date)}
      </TableRow>
    );
  };

  const renderSalesReturnRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderPurchaseRequestRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.due_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.due_date)}
      </TableRow>
    );
  };

  const renderPurchaseOrderRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.expect_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.due_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.due_date)}
      </TableRow>
    );
  };

  const renderPurchaseReturnRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="right">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.delivery_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.delivery_date)}
      </TableRow>
    );
  };

  const renderDeliveryOrderRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        {/* <TableCell align="right">
          <Typography> {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}</Typography>
        </TableCell> */}
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.delivery_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.delivery_date)}
      </TableRow>
    );
  };

  const renderDeliveryTripRow = (data: IProjectRelatedDocument) => {
    return (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        {/* <TableCell align="right">
          <Typography> {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}</Typography>
        </TableCell> */}
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.delivery_date)}</Typography>
        </TableCell>
        {renderDueInCell(data?.delivery_date)}
        <TableCell align="center">
          <Typography>{data?.delivery_type}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedAvatar avatars={[]}></CustomizedAvatar>;
        </TableCell>
      </TableRow>
    );
  };

  const renderManufactureOrderRow = (
    data: IProjectRelatedDocument,
    documentType?: string | number
  ) => {
    return documentType && documentType !== "ALL" ? (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="center">
          <Typography>
            {data?.total_amount
              ? formatNumber(data?.total_amount!)
              : data?.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.production_date)}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography>
            {formatDate(data?.production_completion_date)}
          </Typography>
        </TableCell>
        {renderDueInCell(data?.delivery_date)}
      </TableRow>
    ) : (
      <TableRow key={data?.unique_id}>
        <TableCell align="center">
          <Typography>{data?.unique_id}</Typography>
        </TableCell>
        <TableCell align="center">
          <CustomizedStatus status={data?.aggrid_status} />
        </TableCell>
        <TableCell align="center">
          <Typography>{formatDate(data?.issue_date)}</Typography>
        </TableCell>
      </TableRow>
    );
  };

  const renderRow = (data: IProjectRelatedDocument) => {
    switch (documentType) {
      case EntityTypeEnum.Quotation:
        return renderQuotationRow(data);
      case EntityTypeEnum.SalesOrder:
        return renderSalesOrderRow(data);
      case EntityTypeEnum.SalesReturn:
        return renderSalesReturnRow(data);
      case EntityTypeEnum.PurchaseRequest:
        return renderPurchaseRequestRow(data);
      case EntityTypeEnum.PurchaseOrder:
        return renderPurchaseOrderRow(data);
      case EntityTypeEnum.PurchaseReturn:
        return renderPurchaseReturnRow(data);
      case EntityTypeEnum.DeliveryOrder:
        return renderDeliveryOrderRow(data);
      case EntityTypeEnum.DeliveryTrip:
        return renderDeliveryTripRow(data);
      case EntityTypeEnum.ManufactureOrder:
        return renderManufactureOrderRow(data, documentType);
      case "ALL":
      default:
        switch (tab) {
          case "sales":
          case "purchase":
            return renderDefaultRow(data);
          case "delivery":
            return renderDeliveryOrderRow(data);
          case "manufacture":
            return renderManufactureOrderRow(data, documentType);
          default:
            break;
        }
        break;
    }
  };

  return renderRow;
};
