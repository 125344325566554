import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ICompany } from "../types/Setting/company";

function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
  let timeout: NodeJS.Timeout;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

export const useCompanyFilter = (companyList: ICompany[]) => {
  const [filterText, setFilterText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<ICompany[]>([]);

  useEffect(() => {
    if (companyList) {
      setFilteredData(companyList);
    }
  }, [companyList]);

  const handleFilterChange = useCallback(
    (searchText: string) => {
      const filteredResults = companyList.filter(
        (company) =>
          company.unique_id.toLowerCase().includes(searchText.toLowerCase()) ||
          company.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredData(filteredResults);
    },
    [companyList]
  );

  const debouncedFilter = useMemo(
    () => debounce(handleFilterChange, 500),
    [handleFilterChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
    debouncedFilter(e.target.value);
  };

  const filteredDataMemoized = useMemo(() => {
    return filteredData;
  }, [filteredData]);

  return {
    filterText,
    handleInputChange,
    filteredDataMemoized,
  };
};
