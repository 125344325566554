import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import UserTable from "../../../components/Table/User";

const User = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("user.index"),
      to: "/user",
    },
    {
      name: t("user.account.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("user.account.tab.index"),
      path: `${pathname}`,
    },
    {
      label: t("user.account.tab.sales"),
      path: `${pathname}?filter=sales`,
    },
    {
      label: t("user.account.tab.inventory"),
      path: `${pathname}?filter=inventory`,
    },
    {
      label: t("user.account.tab.accounting"),
      path: `${pathname}?filter=accounting`,
    },
    {
      label: t("user.account.tab.purchase"),
      path: `${pathname}?filter=purchase`,
    },
    {
      label: t("user.account.tab.management"),
      path: `${pathname}?filter=management`,
    },
    {
      label: t("user.account.tab.logistic"),
      path: `${pathname}?filter=logistic`,
    },
    {
      label: t("user.account.tab.manufacture"),
      path: `${pathname}?filter=manufacture`,
    },
    {
      label: t("user.account.tab.engineer"),
      path: `${pathname}?filter=engineer`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("user.account.index")}</Typography>
        <CustomizedButton
          title={t("user.account.add_new_account")}
          variant="contained"
          onClick={() => navigate(`${pathname}/add?subtab=inventory`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <UserTable />
    </>
  );
};

export default User;
