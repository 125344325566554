import { GraphQLClient } from "graphql-request";
import { EntityTypeEnum } from "../../generated/creatable";
import { InventoryDocumentType } from "../../generated/inventory";
import {
  PurchaseDocumentType,
  PurchaseOrderQuery,
} from "../../generated/purchase";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { IPurchaseItemList } from "../../types/Purchase";
import { IPurchaseOrder } from "../../types/Purchase/purchaseOrder";
import { ICreatedBy } from "../../types/global";
import { itemListToTraceEntryListFormatter } from "./Global";

export const purchaseOrderCreatePayloadFormatter = (
  data: IPurchaseOrder,
  status: string
) => {
  const { item_list, vendor_contact, tag_list, ...otherData } = data;
  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];
  const formatItemList = item_list.map(
    ({ uom_group, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseOrder,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const { unique_id_name, ...vendor } = vendor_contact;
  const formatPayload = {
    ...otherData,
    vendor_contact: vendor,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseOrderUpdatePayloadFormatter = (
  data: IPurchaseOrder,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    vendor_contact,
    item_list,
    tag_list,
    created_by,
    created_date,
    external_reference_id,
    external_reference_id_confirmation,
    ...otherData
  } = data;
  const { unique_id_name, ...vendor } = vendor_contact;

  const formatItemList = item_list.map(
    ({ uom_group, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseOrder,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const formatTagList = tag_list ? tag_list.map((tag: any) => tag.name) : [];

  let formatExternalReferenceId: string | undefined = external_reference_id;

  if (
    external_reference_id_confirmation &&
    external_reference_id_confirmation.trim().length > 0
  ) {
    if (external_reference_id && external_reference_id.trim().length > 0) {
      const externalToArray = external_reference_id.trim().split(",");
      const newExternalConfirmation = [
        ...externalToArray,
        external_reference_id_confirmation,
      ];
      const formatNewExternal = newExternalConfirmation.join(", ");
      formatExternalReferenceId = formatNewExternal;
    } else {
      formatExternalReferenceId = external_reference_id_confirmation;
    }
  }

  const formatPayload = {
    ...otherData,
    vendor_contact: vendor,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    tag_list: formatTagList,
    sub_status: status,
    external_reference_id:
      status === "approved" ||
      status === "partially_imported" ||
      status === "fully_imported"
        ? formatExternalReferenceId
        : external_reference_id,
  };
  return formatPayload;
};

export const purchaseOrderQueryFormatter = async (data: IPurchaseOrder) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: 999,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
    });
  });

  const formatVendor = {
    ...data.vendor_contact,
    unique_id_name: `${data.vendor_contact_unique_id} - ${data.vendor_contact.name}`,
  };

  const formatPayload = {
    ...data,
    vendor_contact: formatVendor,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copyPurchaseOrderFormatter = (
  data?: PurchaseOrderQuery["purchaseOrder"]
) => {
  if (data) {
    const {
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      expect_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({ qty_received, reference_line_item, ...otherItemList }) => ({
          ...otherItemList,
          qty_received: 0,
        })
      );

    return {
      ...otherData,
      item_list: formatItemList,
    };
  }
};

export const createPurchaseReturnFromOrder = (data: IPurchaseOrder) => {
  if (data) {
    const {
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      expect_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      vendor_contact,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          reference_document_type,
          reference_unique_id,
          qty,
          ...otherItemList
        }) => {
          const { uom_group, uom } = otherItemList;
          let stock_qty: number = 1;
          if (uom_group) {
            if (uom !== uom_group.base_uom?.unique_id) {
              if (
                uom_group.uom_conversion_list &&
                uom_group.uom_conversion_list.length > 0
              ) {
                const conversionUom = uom_group.uom_conversion_list.find(
                  (conversion) => conversion.target_uom_unique_id === uom
                );
                if (conversionUom) {
                  const targetUomQty =
                    conversionUom.base_uom_rate / conversionUom.target_uom_rate;
                  stock_qty = targetUomQty;
                }
              }
            }
          }

          return {
            ...otherItemList,
            qty: 1,
            po_qty: qty,
            stock_qty: stock_qty,
          };
        }
      );

    const { delivery_address, ...otherVendor } = vendor_contact;

    const formatVendor = {
      ...otherVendor,
      delivery_address: {
        address_type: "",
        is_same_as_default_address: false,
        address_contact_name: "",
        address_contact_phone: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      },
    };

    return {
      ...otherData,
      reference_unique_id_list: [data.unique_id],
      item_list: formatItemList,
      vendor_contact: formatVendor,
    };
  }
};

export const createGoodsReceiveFromOrder = (
  data: IPurchaseOrder,
  currentUser: ICreatedBy
) => {
  if (data) {
    const { unique_id, item_list, destination_warehouse_unique_id } = data;

    const formatTraceEntry = itemListToTraceEntryListFormatter(
      item_list,
      InventoryDocumentType.GoodsReceive,
      EntityTypeEnum.PurchaseOrder
    );

    return {
      type: "purchase_order",
      reference_unique_id: unique_id,
      trace_entry_list: formatTraceEntry,
      created_by: currentUser,
      source_warehouse_unique_id: destination_warehouse_unique_id,
    };
  }
};
