import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";

const Remark = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.remark.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.remark.sales"),
      to: "/setting/remark/sales?tab=quotation",
      icon: <MonetizationOnOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.remark.purchase"),
      to: "/setting/remark/purchase?tab=purchase_request",
      icon: <ShoppingCartOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.remark.logistic"),
      to: "/setting/remark/logistic?tab=delivery_order",
      icon: <LocalShippingOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.remark.manufacture"),
      to: "/setting/remark/manufacture?tab=manufacture_order",
      icon: <PrecisionManufacturingOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Remark;
