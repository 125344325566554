import { ColDef, RowDoubleClickedEvent } from "ag-grid-community";
import { useNavigate } from "react-router";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { useRoutingColumnDef } from "../Routing/columnDefs";

import { IRouting } from "../../../../types/Manufacture/routing";

interface Props {
  gridRef: RefObject<AgGridReact<IRouting>>;
  rowData: any[];
}

const ManufactureRoutingReportTable = ({ gridRef, rowData }: Props) => {
  const navigate = useNavigate();

  const columnDef: ColDef[] | undefined = useRoutingColumnDef(true);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/manufacture/order/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      rowData={rowData}
      onRowDoubleClicked={onRowDoubleClicked}
    />
  );
};

export default ManufactureRoutingReportTable;
