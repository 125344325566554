import styled from "@emotion/styled";
import { Box, BoxProps } from "@mui/material";

type ExtendedBoxProps = BoxProps & {
  radius?: number | string;
  padding?: number | string;
  margin?: number | string;
  boxShadow?: any;
  borderColor?: string;
  clipPath?: string;
  total?: boolean;
  url?: string;
};

export const CustomizedBox = styled(Box)<ExtendedBoxProps>(
  ({
    padding,
    margin,
    radius,
    total,
    boxShadow,
    clipPath,
    borderColor,
    url,
  }) => ({
    boxShadow: boxShadow ?? "0px 2px 7px #E5E5E5",
    borderRadius: radius || "15px",
    padding: padding ?? "2rem",
    margin: margin ?? "2rem 0rem",
    ...(total && {
      borderLeft: `5px ${
        borderColor ? borderColor : "rgba(72, 48, 222, 1) "
      } solid`,
    }),
    clipPath: clipPath,
    cursor: url ? "pointer" : "auto",
  })
);
