import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { IGoodsReceive } from "../../../types/Inventory/goodsReceive";
import { Typography } from "@mui/material";
import GoodsReceiveTable from "../../../components/Table/Inventory/GoodsReceive";
import { useParams } from "react-router-dom";

const GoodsReceiveTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IGoodsReceive>>(null);
  return (
    <>
      <Typography variant="h5" mb={3}>
        ใบ{t("inventory.goods_receive.index")}
      </Typography>
      <GoodsReceiveTable gridRef={gridRef} referenceUniqueId={id} />
    </>
  );
};

export default GoodsReceiveTab;
