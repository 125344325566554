import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { ITab } from "../../types/global";

interface Props {
  tabs: ITab[];
  currentTab: string;
  centered?: boolean;
  table?: boolean;
  divider?: boolean;
  subtab?: boolean;
  scrollable?: boolean;
}

interface ILinkTab {
  key: number;
  testid?: string;
  label: string;
  value: string;
  to: string;
  errors?: boolean;
  notransform?: boolean;
  disabled?: boolean;
}

function LinkTab(props: ILinkTab) {
  return (
    <Tab
      sx={{
        color: props.errors ? "#F44336 !important" : "",
        textTransform: props.notransform ? "none" : "uppercase",
      }}
      component={Link}
      {...props}
    />
  );
}

export default function CustomizedTab({
  tabs,
  currentTab,
  centered,
  divider,
  table,
  subtab,
}: Props) {
  //table is remove margin when tab on top table
  //subtab is tab inside tab for layout remove margin as table
  return (
    <Box sx={{ textAlign: centered ? "-webkit-center" : 0 }}>
      <Tabs
        variant={"scrollable"}
        // centered={centered}
        value={currentTab}
        aria-label="nav tabs example"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: divider ? "100%" : "fit-content",
          margin: table || subtab ? 0 : "1rem 0",
        }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
        }}
      >
        {tabs.map((tab: ITab, index: number) => (
          <LinkTab
            key={index}
            testid={tab.testid}
            label={tab.label}
            value={tab.path}
            to={tab.path}
            errors={tab.errors}
            notransform={tab.notransform}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
    </Box>
  );
}
