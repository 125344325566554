import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmation } from "../../../../hooks/use-confrimation";

import CustomizedMenuOption from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import Confirmation from "../../../UI/Confirmation";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import { IDefaultForm, IMenuOption } from "../../../../types/global";

const printOptions: IMenuOption[] = [{ value: "พิมพ์สูตรการผลิต" }];

type Props = IDefaultForm & {
  editButtonClickHandler: () => void;
  mainBomSubmitHandler: () => void;
  deleteBomHandler: () => void;
  copyBomHandler: () => void;
};

const BomHeaderForm = ({
  control,
  errors,
  disabled,
  editButtonClickHandler,
  mainBomSubmitHandler,
  deleteBomHandler,
  copyBomHandler,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const [openDrawer, setOpenDrawer] = useState(false);
  const [createdDateisOpen, setCreatedDateisOpen] = useState(false);
  const [startDateisOpen, setStartDateisOpen] = useState(false);
  const [endDateisOpen, setEndDateisOpen] = useState(false);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  const watchName = useWatch({ control, name: "name" });
  const watchIsMainBom = useWatch({ control, name: "is_main_bom" });

  const selectModifyOptions = [
    {
      value: "ใช้เป็นสูตรหลัก",
      disabled: watchIsMainBom,
    },
    {
      value: "แก้ไข",
    },
    {
      value: "คัดลอก",
    },
    {
      value: "ลบ",
    },
  ];

  const {
    confirmation: deleteConfirmation,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(deleteBomHandler);

  const {
    confirmation: copyConfirmation,
    openConfirmationHandler: openCopyConfirmation,
    closeConfirmationHandler: closeCopyConfirmation,
    submitConfirmationHandler: submitCopyConfirmation,
  } = useConfirmation(copyBomHandler);

  const DocumentNameSection = () => {
    const watchIsMainBom = useWatch({ control, name: "is_main_bom" });
    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography variant="h5">
          {id && watchName ? watchName : `สร้าง${t("manufacture.bom.index")}`}
        </Typography>
        <CustomizedStatus status={watchIsMainBom && "is_main_bom"} />
      </Box>
    );
  };

  return (
    <>
      <Grid container my={3} alignItems="center">
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} mb={3}>
          <DocumentNameSection />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={5.5} sm={5.5} md={4} lg={3.5} xl={2}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"พิมพ์เอกสาร"}
                options={printOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  if (value.innerText === "พิมพ์สูตรการผลิต") {
                    navigate(
                      `/manufacture/bom/${encodeURIComponent(id ?? "")}/pdf`
                    );
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3.5} lg={3.5} xl={2}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case "ใช้เป็นสูตรหลัก":
                      mainBomSubmitHandler();
                      break;
                    case "แก้ไข":
                      editButtonClickHandler();
                      break;
                    case "คัดลอก":
                      openCopyConfirmation();
                      break;
                    case "ลบ":
                      openDeleteConfirmation();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    // setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* <RightDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          title={t("inventory.activity")}
          documentId={id}
          documentType="bom"
        /> */}
      </Grid>
      <Grid container justifyContent={isMobile ? "flex-start" : "flex-end"}>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                open={createdDateisOpen}
                onClose={() => setCreatedDateisOpen(false)}
                onOpen={() => setCreatedDateisOpen(true)}
                label={t("manufacture.bom.created_date")}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="start_date"
                control={control}
                error={errors.start_date}
                open={startDateisOpen}
                onClose={() => setStartDateisOpen(false)}
                onOpen={() => setStartDateisOpen(true)}
                label={t("manufacture.bom.start_date")}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <ControlledDatePicker
                name="end_date"
                control={control}
                error={errors.end_date}
                open={endDateisOpen}
                onClose={() => setEndDateisOpen(false)}
                onOpen={() => setEndDateisOpen(true)}
                label={t("manufacture.bom.end_date")}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Confirmation
        title={"ยืนยันการคัดลอก"}
        open={copyConfirmation}
        action={submitCopyConfirmation}
        handleClose={closeCopyConfirmation}
      />
      <Confirmation
        title={`ต้องการลบสูตรการผลิต ${watchName} ใช่หรือไม่`}
        open={deleteConfirmation}
        action={submitDeleteConfirmation}
        handleClose={closeDeleteConfirmation}
      />
    </>
  );
};

export default BomHeaderForm;
