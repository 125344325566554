import ControlledTextField from "../../../Controller/ControlledTextField";
import { IDefaultForm } from "../../../../types/global";
import { IPurchaseItemList } from "../../../../types/Purchase";
import { Typography } from "@mui/material";

interface Props {
  control: IDefaultForm["control"];
  disabled: IDefaultForm["disabled"];
  field: IPurchaseItemList;
  index: number;
}

const StockQtyCell = ({ control, index, field, disabled }: Props) => {
  return (
    <>
      {disabled ? (
        <>
          <Typography fontSize={14}>{field.stock_qty}</Typography>
          <Typography variant="caption">
            {field.uom_group?.base_uom?.name}
          </Typography>
        </>
      ) : (
        <ControlledTextField
          sx={{ mt: 3 }}
          type="number"
          control={control}
          name={`item_list[${index}].stock_qty`}
          helperText={field.uom_group?.base_uom?.name}
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
          disabled={true}
        />
      )}
    </>
  );
};

export default StockQtyCell;
