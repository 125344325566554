import { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { getAllUsers } from "../../../../features/User/Account/account-actions";

import ContactCard from "./ContactCard";
// import CheckboxModalTable from "../../../Table/CheckboxModalTable";
// import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
// import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedButton from "../../Custom/CustomizedButton";

// import { departmentEngToThai } from "../../../../utils/dataTransformer";
import { IDefaultForm } from "../../../types/global";
import CheckedUserModal from "../../Table/CheckedUserModal";
import { IUserArray } from "../../../types/Auth/user";

const SalesListTab = ({
  control,
  setValue,
  getValues,
  disabled,
}: IDefaultForm) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const watchSalesList: IUserArray = useWatch({ control, name: "sales_list" });

  useEffect(() => {
    if (watchSalesList && watchSalesList.length > 0) {
      const newUserIdList = watchSalesList.map(
        (employees) => employees.unique_id
      );
      setSelectedIdsSnapshot(newUserIdList);
      setSelectedIds(newUserIdList);
    }
  }, [watchSalesList]);

  const [selectedIdsSnapshot, setSelectedIdsSnapshot] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  // const datasource = {
  //   getRows(params) {
  //     const request = params.request;
  //     dispatch(
  //       getAllUsers(
  //         {
  //           startRow: request.startRow,
  //           endRow: request.endRow,
  //           filterModel: request.filterModel,
  //           sortModel: request.sortModel,
  //         },
  //         params
  //       )
  //     );
  //   },
  // };

  // const closeModalHandler = () => {
  //   setOpenModal(false);
  //   setSelectedIds(selectedIdsSnapshot);
  // };

  const finishSelectEmployeesHandler = (data: any[]) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = watchSalesList.filter((employee) =>
      selectedIds.includes(employee.unique_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.unique_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.unique_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        selectedIds.indexOf(a.unique_id) - selectedIds.indexOf(b.unique_id)
      );
    });
    setValue("sales_list", filteredEmployees);
    setSelectedIdsSnapshot(selectedIds);
    setOpenModal(false);
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const removeEmployeeHandler = (index: number) => {
    const filterIndex = watchSalesList.filter(
      (list, indexList) => indexList !== index
    );
    setValue("sales_list", filterIndex);
    setSelectedIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setSelectedIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
  };

  const renderEmployeesList = () => {
    return watchSalesList.map((item, index) => {
      return (
        <Grid key={item.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
          <ContactCard
            title={
              index === 0
                ? t("contact.sale_list.responsible_person.main")
                : t("contact.sale_list.responsible_person.sub")
            }
            first_name={item.first_name || "-"}
            last_name={item.last_name || "-"}
            subcontent={item.position || "-"}
            img={item.img_url}
            imgAlt={`img-${index}`}
            index={index}
            onRemove={removeEmployeeHandler}
            isSalesList
            disabled={disabled}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      {!disabled && (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <CustomizedButton
              title={"เพิ่มพนักงานขาย"}
              variant="outlined"
              size="medium"
              onClick={openModalHandler}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        {renderEmployeesList()}
      </Grid>
      <CheckedUserModal
        showSelectUser={openModal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishSelectEmployeesHandler}
        userIds={selectedIds}
        setUserIds={setSelectedIds}
        userIdsSnapshot={selectedIdsSnapshot}
        setUserIdsSnapshot={setSelectedIdsSnapshot}
        rowSelection="multiple"
      />
      {/* <CheckboxModalTable
        modalTitle="เพิ่มพนักงาน"
        btnTitle="เลือกพนักงาน"
        gridRef={selectEmployeeGridRef}
        height={450}
        columnDefs={columnDefs}
        rowSelection="multiple"
        onFinishEditing={finishSelectEmployeesHandler}
        modalIsOpen={openModal}
        closeModal={closeModalHandler}
        onGridReady={onGridReady}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        idsSnapshot={selectedIdsSnapshot}
        setIdsSnapshot={setSelectedIdsSnapshot}
      /> */}
    </>
  );
};

export default SalesListTab;
