import { useMemo } from "react";
import { IMenuOption } from "../../types/global";
import { useTranslation } from "react-i18next";

export const useProjectCreate = (status?: string | null) => {
  const { t } = useTranslation();

  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: t("sales.quotation.index"),
        disabled: false,
      },
      {
        value: t("sales.order.index"),
        disabled: false,
      },
      {
        value: t("sales.return.index"),
        disabled: false,
      },
      {
        value: t("purchase.request.index"),
        disabled: false,
      },
      {
        value: t("purchase.order.index"),
        disabled: false,
      },
      {
        value: t("purchase.return.index"),
        disabled: false,
      },
      {
        value: t("project.deliveryOrder"),
        disabled: false,
      },
      {
        value: t("project.deliveryTrip"),
        disabled: false,
      },
      {
        value: t("manufacture.order.index"),
        disabled: false,
      },
      {
        value: t("project.event.index"),
        disabled: false,
      },
    ],
    [t]
  );

  return {
    selectCreateOptions: defaultOptions,
  };
};
