import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import { IDefaultForm } from "../../types/global";
import { IIngredient } from "../../types/Manufacture";
import { IWorkOrder } from "../../types/Manufacture/workOrder";

export const useManufactureSummary = (
  control: IDefaultForm["control"],
  setValue: IDefaultForm["setValue"]
) => {
  const ingredientList: IIngredient[] = useWatch({
    control,
    name: "ingredient_list",
  });

  const workOrderList: IWorkOrder[] = useWatch({
    control,
    name: "work_order_list",
  });

  const other_manufacture_cost =
    useWatch({
      control,
      name: "other_manufacture_cost",
    }) || 0;

  const [summary, setSummary] = useState({
    work_order_cost: 0,
    other_manufacture_cost: 0,
    total_cost: 0,
    bom_cost: 0,
  });

  useEffect(() => {
    setSummary(({ work_order_cost, total_cost, bom_cost }) => {
      bom_cost =
        ingredientList?.reduce(
          (acc, item) =>
            acc + (item.cost_per_unit || 0) * parseFloat(item.qty || "0"),
          0
        ) || 0;

      work_order_cost =
        workOrderList?.reduce((acc, item) => acc + (item.cost_price || 0), 0) ||
        0;

      total_cost = bom_cost + work_order_cost + other_manufacture_cost;

      return {
        work_order_cost,
        total_cost,
        bom_cost,
        other_manufacture_cost,
      };
    });
  }, [ingredientList, other_manufacture_cost, workOrderList]);

  useEffect(() => {
    setValue("work_order_cost", summary.work_order_cost);
    setValue("bom_cost", summary.bom_cost);
    setValue("total_cost", summary.total_cost);
  }, [setValue, summary.bom_cost, summary.total_cost, summary.work_order_cost]);
  return summary;
};
