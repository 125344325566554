import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import { useTranslation } from "react-i18next";
import ControlledNumberTextField from "../../Controller/ControlledNumberTextField";
import { Theme } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { formatNumber, roundingNumber } from "../../../utils/dataTransformer";
import { usePurchaseSummary } from "../../../hooks/Purchase/use-summary";
import ControlledSelect from "../../Controller/ControlledSelect";
import { AdditionalDiscountType } from "../../../generated/purchase";
import { UseFormResetField, useWatch } from "react-hook-form";

interface Props {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  disabled: IDefaultForm["disabled"];
  documentType: string;
  resetField?: UseFormResetField<any>;
}

interface ISummary {
  name?: string;
  label: string;
  amount: number;
}

const additionalDiscountTypeOptions: ISelectOption[] = [
  {
    label: "บาท",
    value: AdditionalDiscountType.Baht,
  },
  {
    label: "%",
    value: AdditionalDiscountType.Percent,
  },
];

const PurchaseSummary = ({
  control,
  setValue,
  disabled,
  resetField,
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const pre_vat_amount = useWatch({
    control,
    name: "pre_vat_amount",
  });

  const additionDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  const { t } = useTranslation();
  const summary = usePurchaseSummary(control, setValue);

  const netAmount =
    roundingNumber(summary.vat_exempted_amount) +
    roundingNumber(summary.vat_0_amount) +
    roundingNumber(summary.vat_7_amount) +
    roundingNumber(summary.vat_amount);

  const totalAmount =
    netAmount +
    summary.shipping_cost -
    roundingNumber(summary.withholding_tax_amount);

  const defaultsSummaryList: ISummary[] = useMemo(
    () => [
      {
        name: "additional_discount",
        label: `${t("sales.additional_discount")} / Additional Discount`,
        amount: summary.additional_discount,
      },
      {
        label: `${t("sales.sub_total")} / Sub Total`,
        amount: summary.sub_total,
      },
      {
        label: `${t("sales.vat_exempted_amount")} / VAT Exempted Amount`,
        amount: summary.vat_exempted_amount,
      },
      {
        label: `${t("sales.vat_0_amount")} / VAT 0% Amount`,
        amount: summary.vat_0_amount,
      },
      {
        label: `${t("sales.vat_7_amount")} / VAT 7% Amount`,
        amount: summary.vat_7_amount,
      },
      {
        label: `${t("sales.vat_amount")} / VAT Amount`,
        amount: summary.vat_amount,
      },
      {
        label: `${t("sales.net_amount")} / Net Amount`,
        amount: netAmount,
      },
      {
        label: `${t("sales.withholding_tax_amount")} / Withholding Tax`,
        amount: summary.withholding_tax_amount,
      },
      {
        name: "shipping_cost",
        label: `${t("sales.shipping_cost")} / Shipping Cost`,
        amount: summary.shipping_cost,
      },
      {
        label: `${t("sales.total_amount")} / Total Amount`,
        amount: totalAmount,
      },
    ],
    [
      netAmount,
      summary.additional_discount,
      summary.shipping_cost,
      summary.sub_total,
      totalAmount,
      summary.vat_0_amount,
      summary.vat_7_amount,
      summary.vat_amount,
      summary.vat_exempted_amount,
      summary.withholding_tax_amount,
      t,
    ]
  );

  const [summaryList, setSummaryList] =
    useState<ISummary[]>(defaultsSummaryList);

  useEffect(() => {
    if (additionDiscountType === AdditionalDiscountType.Percent) {
      setSummaryList([
        {
          name: "additional_discount",
          label: `${t("sales.additional_discount")} / Additional Discount`,
          amount: (summary.additional_discount / pre_vat_amount) * 100,
        },
        {
          label: "",
          amount: summary.additional_discount,
        },
        {
          label: `${t("sales.sub_total")} / Sub Total`,
          amount: summary.sub_total,
        },
        {
          label: `${t("sales.vat_exempted_amount")} / VAT Exempted Amount`,
          amount: summary.vat_exempted_amount,
        },
        {
          label: `${t("sales.vat_0_amount")} / VAT 0% Amount`,
          amount: summary.vat_0_amount,
        },
        {
          label: `${t("sales.vat_7_amount")} / VAT 7% Amount`,
          amount: summary.vat_7_amount,
        },
        {
          label: `${t("sales.vat_amount")} / VAT Amount`,
          amount: summary.vat_amount,
        },
        {
          label: `${t("sales.net_amount")} / Net Amount`,
          amount: netAmount,
        },
        {
          label: `${t("sales.withholding_tax_amount")} / Withholding Tax`,
          amount: summary.withholding_tax_amount,
        },
        {
          name: "shipping_cost",
          label: `${t("sales.shipping_cost")} / Shipping Cost`,
          amount: summary.shipping_cost,
        },
        {
          label: `${t("sales.total_amount")} / Total Amount`,
          amount: totalAmount,
        },
      ]);
    } else {
      setSummaryList(defaultsSummaryList);
    }
  }, [
    additionDiscountType,
    defaultsSummaryList,
    pre_vat_amount,
    summary.additional_discount,
    netAmount,
    summary.shipping_cost,
    summary.sub_total,
    totalAmount,
    summary.vat_0_amount,
    summary.vat_7_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    summary.withholding_tax_amount,
    t,
  ]);

  const additionalDiscountType: AdditionalDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });

  return (
    <>
      {summaryList.map((item, index) => (
        <Box key={item.label} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 3,
              gap: 2,
            }}
          >
            {!["shipping_cost", "additional_discount"].includes(
              item.name || ""
            ) || disabled ? (
              <>
                <Typography
                  variant="subtitle1"
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ minWidth: 150, textAlign: "right", mr: 1.5, flex: 1 }}
                >
                  {formatNumber(item.amount)}
                </Typography>
                {additionalDiscountType === AdditionalDiscountType.Baht ? (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
                    บาท
                  </Typography>
                ) : item.name === "additional_discount" ? (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 6 }}>
                    %
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
                    บาท
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle1"
                  mr={1.5}
                  textAlign={isMobile ? "left" : "right"}
                  flex={2}
                >
                  {item.label}
                </Typography>
                <Box
                  minWidth={150}
                  flex={1}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ControlledNumberTextField
                    name={item?.name || ""}
                    control={control}
                  />
                </Box>
                {item.name === "additional_discount" ? (
                  <Box
                    sx={{
                      width: 75,
                    }}
                  >
                    <ControlledSelect
                      control={control}
                      name="additional_discount_type"
                      options={additionalDiscountTypeOptions}
                      onChange={() => {
                        if (resetField)
                          resetField("additional_discount", {
                            defaultValue: 0,
                          });
                      }}
                    />
                  </Box>
                ) : (
                  <Typography variant="subtitle1" sx={{ ml: 2.5, mr: 4 }}>
                    บาท
                  </Typography>
                )}
              </>
            )}
          </Box>
          {index === summaryList.length - 2 && (
            <Divider sx={{ mb: 3, mr: 4 }} />
          )}
          {index === summaryList.length - 1 && (
            <>
              <Divider sx={{ mb: 1, mr: 4 }} />
              <Divider sx={{ mb: 2, mr: 4 }} />
            </>
          )}
        </Box>
      ))}
    </>
  );
};

export default PurchaseSummary;
