import { IDefaultForm } from "../../types/global";
import { useWatch } from "react-hook-form";
import { ISalesItemList } from "../../types/Sales";
import { AdditionalDiscountType, PriceVatType } from "../../generated/sales";
import { useEffect, useState } from "react";

export const useSalesSummary = (
  control: IDefaultForm["control"],
  setValue: IDefaultForm["setValue"]
) => {
  const itemList: ISalesItemList[] = useWatch({
    control,
    name: "item_list",
  });
  const shippingCost: number = useWatch({ control, name: "shipping_cost" });
  const additionalDiscount: number = useWatch({
    control,
    name: "additional_discount",
  });
  const additionalDiscountType: AdditionalDiscountType = useWatch({
    control,
    name: "additional_discount_type",
  });
  const priceVatType: PriceVatType = useWatch({
    control,
    name: "price_vat_type",
  });

  const [summary, setSummary] = useState({
    additional_discount: 0,
    shipping_cost: 0,
    sub_total: 0,
    pre_vat_amount: 0,
    vat_exempted_amount: 0,
    vat_0_amount: 0,
    vat_7_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
  });

  useEffect(() => {
    setSummary(
      ({
        additional_discount,
        net_amount,
        pre_vat_amount,
        shipping_cost,
        sub_total,
        total_amount,
        vat_0_amount,
        vat_7_amount,
        vat_amount,
        vat_exempted_amount,
        withholding_tax_amount,
      }) => {
        const total_pre_vat_amount = itemList.reduce(
          (acc, item) => acc + item.pre_vat_amount,
          0
        );

        pre_vat_amount = total_pre_vat_amount;

        if (additionalDiscountType === AdditionalDiscountType.Baht) {
          additional_discount = additionalDiscount || 0;
        } else {
          additional_discount =
            total_pre_vat_amount * ((additionalDiscount || 0) / 100);
        }

        shipping_cost = shippingCost || 0;

        sub_total = itemList.reduce((acc, item) => {
          const item_additional_discount =
            additional_discount *
              (item.pre_vat_amount / total_pre_vat_amount) || 0;

          return acc + (item.pre_vat_amount - item_additional_discount);
        }, 0);

        vat_exempted_amount = itemList
          .filter((item) => item?.vat_percentage === "ไม่มี")
          .reduce((acc, item) => {
            const item_additional_discount =
              additional_discount *
                (item.pre_vat_amount / total_pre_vat_amount) || 0;
            return acc + (item.pre_vat_amount - item_additional_discount);
          }, 0);

        vat_0_amount = itemList
          .filter((item) => item?.vat_percentage === "0")
          .reduce((acc, item) => {
            const item_additional_discount =
              additional_discount *
                (item.pre_vat_amount / total_pre_vat_amount) || 0;
            return acc + (item.pre_vat_amount - item_additional_discount);
          }, 0);

        if (priceVatType === PriceVatType.ExcludedVat) {
          vat_7_amount = itemList
            .filter((item) => item?.vat_percentage === "7")
            .reduce((acc, item) => {
              const item_additional_discount =
                additional_discount *
                  (item.pre_vat_amount / total_pre_vat_amount) || 0;
              return acc + (item.pre_vat_amount - item_additional_discount);
            }, 0);
          vat_amount = vat_7_amount * 0.07;

          withholding_tax_amount =
            itemList
              .filter(
                (item) =>
                  !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                    item?.withholding_tax_type
                  )
              )
              .reduce((acc, item) => {
                const item_additional_discount =
                  additional_discount *
                    (item.pre_vat_amount / total_pre_vat_amount) || 0;
                return (
                  acc +
                  ((item?.pre_vat_amount - item_additional_discount) *
                    parseFloat(item?.withholding_tax_type)) /
                    100
                );
              }, 0) || 0;
        } else {
          vat_7_amount = itemList
            .filter((item) => item?.vat_percentage === "7")
            .reduce((acc, item) => {
              const item_additional_discount =
                additional_discount *
                  (item.pre_vat_amount / total_pre_vat_amount) || 0;
              return (
                acc + (item.pre_vat_amount - item_additional_discount) / 1.07
              );
            }, 0);
          vat_amount = vat_7_amount * 0.07;

          withholding_tax_amount =
            itemList
              .filter(
                (item) =>
                  !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                    item?.withholding_tax_type
                  )
              )
              .reduce((acc, item) => {
                const item_additional_discount =
                  additional_discount *
                    (item.pre_vat_amount / total_pre_vat_amount) || 0;
                if (item.vat_percentage === "7") {
                  return (
                    acc +
                    (((item?.pre_vat_amount - item_additional_discount) /
                      1.07) *
                      parseFloat(item?.withholding_tax_type)) /
                      100
                  );
                }
                return (
                  acc +
                  ((item?.pre_vat_amount - item_additional_discount) *
                    parseFloat(item?.withholding_tax_type)) /
                    100
                );
              }, 0) || 0;
        }

        net_amount =
          vat_exempted_amount + vat_0_amount + vat_7_amount + vat_amount;

        total_amount = shipping_cost + net_amount - withholding_tax_amount;

        return {
          additional_discount,
          net_amount,
          pre_vat_amount,
          shipping_cost,
          sub_total,
          total_amount,
          vat_0_amount,
          vat_7_amount,
          vat_amount,
          vat_exempted_amount,
          withholding_tax_amount,
        };
      }
    );
  }, [
    additionalDiscount,
    additionalDiscountType,
    itemList,
    priceVatType,
    shippingCost,
  ]);

  useEffect(() => {
    setValue("sub_total", summary.sub_total);
    setValue("pre_vat_amount", summary.pre_vat_amount);
    setValue("vat_exempted_amount", summary.vat_exempted_amount);
    setValue("vat_0_amount", summary.vat_0_amount);
    setValue("vat_7_amount", summary.vat_7_amount);
    setValue("vat_amount", summary.vat_amount);
    setValue("net_amount", summary.net_amount);
    setValue("withholding_tax_amount", summary.withholding_tax_amount);
    setValue("total_amount", summary.total_amount);
  }, [
    setValue,
    summary.net_amount,
    summary.pre_vat_amount,
    summary.sub_total,
    summary.total_amount,
    summary.vat_0_amount,
    summary.vat_7_amount,
    summary.vat_amount,
    summary.vat_exempted_amount,
    summary.withholding_tax_amount,
  ]);
  return summary;
};
