import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useModal } from "../../../hooks/use-modal";
import {
  AttributesQuery,
  useAttributesQuery,
} from "../../../generated/inventory";
import AttributeTable from "../../../components/Table/Setting/Inventory/AttributeTable";
import AttributeModal from "../../../components/Form/Attribute/AttributeModal";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const AttributeSetting = () => {
  const { t } = useTranslation();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.attribute"),
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, refetch } = useAttributesQuery<AttributesQuery>(
    graphQLClientWithHeaderItem
  );

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        display="flex"
        justifyContent="space-between"
        my={2}
        alignItems="center"
      >
        <Typography variant="h5">{t("setting.inventory.attribute")}</Typography>
        <CustomizedButton
          variant="contained"
          title={t("button.add") + t("setting.inventory.attribute")}
          onClick={openModalHandler}
        />
      </Box>
      <AttributeTable data={data} refetch={refetch} />
      <AttributeModal
        open={modal}
        closeModalHandler={closeModalHandler}
        refetch={refetch}
      />
    </>
  );
};

export default AttributeSetting;
