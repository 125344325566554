import * as Yup from "yup";
import { ILogin } from "../../../types/Auth/login";

export const schema: ILogin = {
  email: "",
  password: "",
};

export const validation = Yup.object().shape({
  email: Yup.string().required("กรุณากรอก"),
  password: Yup.string().required("กรุณากรอก"),
});
