import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { useStateContext } from "../../contexts/auth-context";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { ICompany } from "../../types/Setting/company";
import { useNavigate } from "react-router-dom";
import {
  ValidateAccesssQuery,
  useValidateAccesssQuery,
} from "../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import CustomizedScrollbar from "../Custom/CustomizedScrollbar";
import CustomizedTextField from "../Custom/CustomizedTextField";
import { useCompanyFilter } from "../../hooks/use-company-filter";
import { CustomizedTooltip } from "../Custom/CustomizedTooltip";

const CompanyBadge = () => {
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const {
    state: { authUser },
  } = useStateContext();

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { refetch } = useValidateAccesssQuery<ValidateAccesssQuery, Error>(
    graphQLClientWithHeaderCompany,
    {},
    {
      enabled: false,
    }
  );

  const currentTenantId = sessionStorage.getItem("tenant-id");

  const defaultCompany = authUser?.company_list.find(
    (company) => company.unique_id === currentTenantId
  );

  const [currentCompany, setCurrentCompany] = useState<ICompany | undefined>(
    defaultCompany
  );
  const [companyList, setCompanyList] = useState<ICompany[] | undefined>([]);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  useEffect(() => {
    const filteredCompanyList = authUser?.company_list.filter(
      (company) => company.unique_id !== currentTenantId
    );
    setCompanyList(filteredCompanyList);
  }, [authUser?.company_list, currentTenantId]);

  const onCompanyChangeHandler = (select_company: ICompany) => {
    sessionStorage.setItem("tenant-id", select_company.unique_id);
    const is_expire = dayjs(select_company.expired_date).isBefore(dayjs());
    sessionStorage.setItem(
      "company-info",
      JSON.stringify({
        is_expired: is_expire,
        expired_date: select_company.expired_date,
        subscription_level_id: select_company.subscription_level_id,
        resubscription_date: select_company.resubscription_date,
        register_date: select_company.register_date,
      })
    );
    setCurrentCompany(select_company);
    closeActionsHandler();

    //need to change register_date to expired_date after fix
    if (is_expire) {
      navigate("/setting/package");
    } else
      navigate("/landing", {
        replace: true,
      });

    refetch();
  };

  const sortByName = (a: ICompany, b: ICompany) => {
    // Use localeCompare for case-insensitive sorting
    return a.name.localeCompare(b.name);
  };

  const sortedCompanyList = companyList?.sort(sortByName) || [];

  const { filterText, filteredDataMemoized, handleInputChange } =
    useCompanyFilter(sortedCompanyList);
  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        disableAutoFocusItem
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                width: 10,
                left: "50%",
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MenuItem disabled sx={{ opacity: "1 !important" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              component="span"
              sx={{
                borderRadius: "50%",
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bgcolor={(theme) => theme.palette.primary.light}
            >
              <DomainOutlinedIcon color="primary" />
            </Box>
            <Box>
              <Typography fontWeight={600} fontSize={14}>
                {currentCompany?.name}
              </Typography>
              <Typography fontSize={14}>
                รหัส: {currentCompany?.unique_id}
              </Typography>
              <Typography fontSize={14}>
                สถานะ:{" "}
                {currentCompany?.is_active ? "ใช้งาน" : "หมดอายุการใช้งาน"}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        {companyList && companyList.length > 0 && <Divider variant="middle" />}
        <Box display="flex" width={250} px={2} pb={1}>
          <CustomizedTextField
            label={"ค้นหา"}
            value={filterText}
            onChange={handleInputChange}
          />
          <CustomizedTooltip title={"สร้างกิจการใหม่"}>
            <IconButton
              onClick={() => {
                window.open(
                  "https://forms.clickup.com/3654689/f/3fh11-20922/J08LMINOHLJX89ST77",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              <AddCircleOutlineOutlinedIcon
                fontSize="medium"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            </IconButton>
          </CustomizedTooltip>
        </Box>
        <CustomizedScrollbar sx={{ maxHeight: 275, width: 250 }}>
          {filteredDataMemoized?.map((company) => (
            <MenuItem
              key={company.unique_id}
              onClick={() => onCompanyChangeHandler(company)}
            >
              <Box display="flex" gap={1} alignItems="center">
                <Box
                  component="span"
                  sx={{
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DomainOutlinedIcon color="disabled" />
                </Box>
                <Box>
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    whiteSpace={"pre-wrap"}
                  >
                    {company.name}
                  </Typography>
                  <Typography fontSize={14}>
                    รหัส: {company.unique_id}
                  </Typography>
                  <Typography fontSize={14}>
                    สถานะ: {company.is_active ? "ใช้งาน" : "หยุดใช้งาน"}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </CustomizedScrollbar>
      </Menu>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={openActionsHandler}
        >
          <Box
            component="span"
            sx={{
              borderRadius: "50%",
              width: 32,
              height: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            bgcolor={(theme) => theme.palette.primary.light}
          >
            <DomainOutlinedIcon color="primary" />
          </Box>
          <Typography fontWeight={600} fontSize={14}>
            {currentCompany?.name}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", alignItems: "center" }}
          onClick={openActionsHandler}
        >
          <Typography fontWeight={600} fontSize={14} ml={3.5}>
            {currentCompany?.name}
          </Typography>
          <IconButton>
            <ExpandMoreIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default CompanyBadge;
