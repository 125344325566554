import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

const LogisticSetting = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.logistic.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.logistic.vehicle"),
      to: "/setting/logistic/vehicle",
      icon: <LocalShippingOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default LogisticSetting;
