import { gql } from "graphql-request";

export const USERS_AGGRID = gql`
  query UsersFindManyAggrid($aggridInput: AggridTemplateInput!) {
    usersFindManyAggrid(aggridInput: $aggridInput) {
      count
      data {
        id
        unique_id
        title_name
        nick_name
        first_name
        last_name
        img_url
        phone
        email
        organization
        address
        dob
        national_id
        department
        position
        created_date
        last_updated_date
        last_login_date
        is_active
      }
    }
  }
`;

export const ROLES_AGGRID = gql`
  query RoleViewAggrid($aggridInput: AnyAggridInput) {
    RoleViewAggrid(aggridInput: $aggridInput) {
      data {
        id
        name
        description
        total_users
      }
      count
    }
  }
`;
