import DashboardCard from "../DashboardCard";
import DashboardSection from "../DashboardSection";
import { useForm, useWatch } from "react-hook-form";
import { IPeriodFilter } from "../../../../../types/Dashboard";
import { periodFilterSchema } from "../../../../Form/Dashboard/schema";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DashboardChartCard from "../DashboardChartCard";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCallback, useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import {
  PURCHASE_ORDERS_AGGRID,
  PURCHASE_REQUESTS_AGGRID,
  PURCHASE_RETURNS_AGGRID,
} from "../../../../../services/AgGrid/PurchaseAgGrid";
import { formatPOChartData } from "../../../../../utils/Formatter/dashboard";
import {
  MostPurchaseItemByStatusQuery,
  PurchaseDashboard,
  useMostPurchaseItemByStatusQuery,
} from "../../../../../generated/purchase";

dayjs.extend(relativeTime);
dayjs.locale(th);

const PurchaseSection = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useForm<IPeriodFilter>({
    defaultValues: periodFilterSchema,
  });
  // const [latestFetchedTime, setLatestFetchedTime] = useState<Dayjs>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [poNotSuccess, setPoNotSuccess] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [poWaitAapprove, setPoWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [prWaitApprove, setPrWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const [rsWaitApprove, setRsWaitApprove] = useState({
    totalValue: 0,
    quantity: 0,
  });

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });
  const [purchaseOrderChartData, setPurchaseOrderChartData] = useState<
    (string | number)[][]
  >([]);

  const cardList = [
    {
      title: t("purchase.order.index"),
      status: t("status.wait_approve"),
      quantity: poWaitAapprove.quantity,
      totalValue: poWaitAapprove.totalValue,
      linkTo: "/purchase/order?filter=wait_approve",
    },
    {
      title: t("purchase.request.index"),
      status: t("status.wait_approve"),
      quantity: prWaitApprove.quantity,
      linkTo: "/purchase/request?filter=wait_approve",
    },
    {
      title: t("purchase.order.index"),
      status: "รอดำเนินการ",
      subStatus: `(${t("status.approved")},${t("status.partially_imported")})`,
      quantity: poNotSuccess.quantity,
      totalValue: poNotSuccess.totalValue,
      linkTo: "/purchase/order",
      state: ["approved", "partially_imported"],
    },
    {
      title: t("purchase.return.index"),
      status: t("status.wait_approve"),
      quantity: rsWaitApprove.quantity,
      totalValue: rsWaitApprove.totalValue,
      linkTo: "/purchase/return?filter=wait_approve",
    },
  ];

  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const {
    isLoading: isLoadingMostPurchaseItems,
    refetch: refetchMostPurchaseItems,
  } = useMostPurchaseItemByStatusQuery<MostPurchaseItemByStatusQuery>(
    graphQLClientWithHeaderPurchase,
    {
      purchaseDashboardInput: {
        started_date: dateFrom,
        ended_date: dateTo,
        status: "fully_imported",
        number_most_sell_item: 5,
      },
    },
    {
      enabled: false,
      onSuccess: ({ MostPurchaseItemByStatus }) => {
        formatChartData(MostPurchaseItemByStatus);
      },
    }
  );

  const getPurchaseOrderData = useCallback(
    async (
      status: string[],
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: status,
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { purchaseOrdersFindManyAggrid } =
          await graphQLClientWithHeaderPurchase.request(
            PURCHASE_ORDERS_AGGRID,
            {
              aggridInput: {
                startRow: 0,
                endRow: 999999,
                filterModel: filter,
                sortModel: [],
              },
            }
          );

        const count = purchaseOrdersFindManyAggrid.count as number;
        const data = purchaseOrdersFindManyAggrid.data as any[];

        const totalValue = getTotalValue(data);

        if (status.includes("wait_approve")) {
          setPoWaitApprove(() => {
            return { totalValue: totalValue, quantity: count };
          });
        } else if (status.includes("partially_imported")) {
          setPoNotSuccess(() => {
            return { totalValue: totalValue, quantity: count };
          });
        }
        // else if (status.includes("fully_imported")) {
        //   storeChartData(data);
        // }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPurchaseRequestData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { purchaseRequestsFindManyAggrid } =
          await graphQLClientWithHeaderPurchase.request(
            PURCHASE_REQUESTS_AGGRID,
            {
              aggridInput: {
                startRow: 0,
                endRow: 999999,
                filterModel: filter,
                sortModel: [],
              },
            }
          );

        const count = purchaseRequestsFindManyAggrid.count as number;

        if (status === "wait_approve") {
          setPrWaitApprove((prev) => {
            return { ...prev, quantity: count };
          });
        }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getPurchaseReturnData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { purchaseReturnsFindManyAggrid } =
          await graphQLClientWithHeaderPurchase.request(
            PURCHASE_RETURNS_AGGRID,
            {
              aggridInput: {
                startRow: 0,
                endRow: 999999,
                filterModel: filter,
                sortModel: [],
              },
            }
          );

        const count = purchaseReturnsFindManyAggrid.count as number;
        const data = purchaseReturnsFindManyAggrid.data as any[];

        const totalValue = getTotalValue(data);
        console.log(totalValue);

        if (status === "wait_approve") {
          setRsWaitApprove(() => {
            return { totalValue: totalValue, quantity: count };
          });
        }
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getTotalValue = (data: any[]) => {
    return data.reduce((acc, curr) => acc + parseFloat(curr.total_amount), 0);
  };

  const getAllData = useCallback(
    async (dateFrom?: Date | null, dateTo?: Date | null) => {
      setIsLoading(true);
      refetchMostPurchaseItems();
      await getPurchaseOrderData(["wait_approve"], dateFrom, dateTo);
      await getPurchaseOrderData(
        ["approved", "partially_imported"],
        dateFrom,
        dateTo
      );
      await getPurchaseReturnData("wait_approve", dateFrom, dateTo);
      await getPurchaseRequestData("wait_approve", dateFrom, dateTo);

      setIsLoading(false);
      // setLatestFetchedTime(dayjs());
    },
    [
      getPurchaseOrderData,
      getPurchaseRequestData,
      getPurchaseReturnData,
      refetchMostPurchaseItems,
    ]
  );

  const formatChartData = (data?: PurchaseDashboard[] | null) => {
    const formatData = formatPOChartData(data ?? []);

    setPurchaseOrderChartData(formatData);
  };

  // const onFilterReset = async () => {
  //   await reset(periodFilterSchema);
  //   await getAllData(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
  // };

  const onFilterChanged = () => {
    getAllData(dateFrom, dateTo);
  };

  // const onRefresh = () => {
  //   getAllData(dateFrom, dateTo);
  // };

  useEffect(() => {
    getAllData(dateFrom, dateTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardSection
      title={t("purchase.index")}
      control={control}
      // onRefresh={onRefresh}
      // onFilterReset={onFilterReset}
      getFilteredData={onFilterChanged}
      getValues={getValues}
      setValue={setValue}
      // latestFetchTime={latestFetchedTime}
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <DashboardChartCard
            data={purchaseOrderChartData}
            height="100%"
            isLoading={isLoading && isLoadingMostPurchaseItems}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} md={8}>
          {cardList.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={6}>
              <DashboardCard
                title={card.title}
                documentCount={card.quantity}
                status={card.status}
                totalValue={card.totalValue}
                linkTo={card.linkTo}
                state={card.state}
                subStatus={card.subStatus}
                isLoading={isLoading}
                expandHeight
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DashboardSection>
  );
};

export default PurchaseSection;
