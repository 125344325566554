import {
  FieldArrayWithId,
  useForm,
  useWatch,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import {
  IDefaultForm,
  IMenuOption,
  ISelectOption,
} from "../../../../types/global";
import { useGoodsTransferItemList } from "../../../../hooks/Inventory/use-transter-item-list";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ControlledTextField from "../../../Controller/ControlledTextField";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import { Fragment, useState } from "react";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import {
  InventoryDocumentStatus,
  Tracability,
  WarehouseQuery,
} from "../../../../generated/inventory";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ControlledSelect from "../../../Controller/ControlledSelect";
// import SerialList from "./SerialList";
import SerialListScan from "./SerialListScan";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { useStateContext } from "../../../../contexts/auth-context";
import dayjs from "dayjs";
import { IGoodsTransfer } from "../../../../types/Inventory/goodsTransfer";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import { useSnackbar } from "notistack";
import useBarcodeScanner from "../../../../hooks/Inventory/use-barcode-scanner";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";

const filterScanOptions: IMenuOption[] = [
  {
    value: "แสดงทั้งหมด",
    disabled: false,
  },
  {
    value: "สแกนแล้ว",
    disabled: false,
  },
  {
    value: "รอสแกน",
    disabled: false,
  },
];

type FieldArrayType = {
  trace_entry_list: ITraceEntry[];
};

type Props = IDefaultForm & {
  fields: FieldArrayWithId<FieldArrayType, "trace_entry_list", "id">[];
  allWarehouses?: WarehouseQuery["warehouse"][];
  status?: string | null;
  update: UseFieldArrayUpdate<IGoodsTransfer, "trace_entry_list">;
};

const GoodsTransferScanItemList = ({
  control,
  errors,
  getValues,
  fields,
  disabled,
  allWarehouses,
  status,
  update,
}: Props) => {
  const {
    state: { authUser },
  } = useStateContext();
  const [filterName, setFilterName] = useState<string>("แสดงทั้งหมด");
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const headers = useGoodsTransferItemList(control, disabled);
  const watchDestinationWarehouseUniqueId = useWatch({
    control,
    name: "destination_warehouse_unique_id",
  });

  const {
    control: barcodeControl,
    reset: resetBarcode,
    getValues: getBarcodeValues,
    watch: watchBarcode,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      destination_bin_location_id: "",
    },
  });

  const foundWarehouse = allWarehouses?.find(
    (warehouse) => warehouse?.unique_id === watchDestinationWarehouseUniqueId
  );

  let allBinLocation: any[] | undefined | null = [];

  foundWarehouse?.warehouse_level_1_list?.forEach((level1) => {
    level1.sub_level_2_list?.forEach((level2) => {
      level2?.sub_level_3_list?.forEach((level3) => {
        if (level3?.bin_location_list) {
          allBinLocation?.push(...level3.bin_location_list);
        }
      });
    });
  });

  const binLocationOptions: ISelectOption[] = allBinLocation.map((bin) => ({
    label: bin.bin_name,
    value: bin.id,
  }));

  const onBarcodeSubmitHandler = (barcode?: string) => {
    const destination_bin_location_id = getBarcodeValues(
      "destination_bin_location_id"
    );
    const foundBinLocation = allBinLocation?.find(
      (bin) => bin.id === destination_bin_location_id
    );

    const fields = getValues("trace_entry_list") as ITraceEntry[];

    if (barcode) {
      fields.forEach((trace, index) => {
        if (trace.tracability === Tracability.Normal) {
          if (trace.barcode === barcode.trim()) {
            if (trace.status === InventoryDocumentStatus.IsActive) {
              const currentTrace = getValues(`trace_entry_list[${index}]`);
              if (
                currentTrace.source_bin_location_id ===
                destination_bin_location_id
              ) {
                enqueueSnackbar(`สถานที่ต้นทางและปลายทางเหมือนกัน`, {
                  variant: "error",
                  style: { whiteSpace: "pre-line" },
                });
                return;
              }
              update(index, {
                ...currentTrace,
                destination_scanned_by: {
                  first_name: authUser?.first_name || "",
                  last_name: authUser?.last_name || "",
                  email: authUser?.email || "",
                  img_url: authUser?.img_url,
                  user_unique_id: authUser?.unique_id || "",
                },
                destination_bin_location: foundBinLocation,
                destination_bin_location_id,
                status: InventoryDocumentStatus.IsScan,
                destination_scanned_date: dayjs(),
              });
              enqueueSnackbar(`สแกน SN :\n${currentTrace.barcode}\nสำเร็จ`, {
                variant: "success",
                style: { whiteSpace: "pre-line" },
              });
            } else {
              enqueueSnackbar(
                `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                {
                  variant: "error",
                  style: { whiteSpace: "pre-line" },
                }
              );
            }
          }
        } else {
          const totalSerial = trace.serial_list?.length;
          const totalScanned = trace.serial_list
            ? trace.serial_list.filter(
                (serial) => serial.status === InventoryDocumentStatus.IsScan
              ).length
            : 0;
          trace.serial_list?.forEach((serial, subIndex) => {
            if (serial.barcode === barcode.trim()) {
              if (serial.status === InventoryDocumentStatus.IsActive) {
                const currentTrace = getValues(`trace_entry_list[${index}]`);
                const currentSerial = getValues(
                  `trace_entry_list[${index}].serial_list[${subIndex}]`
                );

                const formatSerial = {
                  ...currentSerial,
                  destination_scanned_by: {
                    first_name: authUser?.first_name || "",
                    last_name: authUser?.last_name || "",
                    email: authUser?.email || "",
                    img_url: authUser?.img_url,
                    user_unique_id: authUser?.unique_id || "",
                  },
                  destination_bin_location: foundBinLocation,
                  destination_bin_location_id,
                  status: InventoryDocumentStatus.IsScan,
                  destination_scanned_date: dayjs(),
                };
                currentTrace.serial_list[subIndex] = formatSerial;
                update(index, currentTrace);
                enqueueSnackbar(
                  `สแกน SN :\n${currentSerial.barcode}\nสำเร็จ จำนวน ${
                    totalScanned + 1
                  }/${totalSerial}`,
                  {
                    variant: "success",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              } else {
                enqueueSnackbar(
                  `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                  {
                    variant: "error",
                    style: { whiteSpace: "pre-line" },
                  }
                );
              }
            }
          });
        }
      });

      if (
        !fields.some((trace) => {
          if (trace.tracability === Tracability.Normal) {
            return trace.barcode === barcode.trim();
          } else {
            if (
              !trace.serial_list?.some(
                (serial) => serial.barcode === barcode.trim()
              )
            ) {
              return false;
            } else {
              return true;
            }
          }
        })
      ) {
        enqueueSnackbar(`QR/Barcode\nสินค้านี้ไม่อยู่ในรายการ\nกรุณาสแกนใหม่`, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
      }
    }

    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  };

  const rescanBarcodeHandler = (index: number) => {
    const { id, ...otherFields } = fields[index];
    update(index, {
      ...otherFields,
      status: InventoryDocumentStatus.IsActive,
      destination_bin_location: undefined,
      destination_bin_location_id: undefined,
      destination_scanned_by: undefined,
      destination_scanned_date: undefined,
    });
  };

  const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  const checkConditionRender = (trace: ITraceEntry) => {
    if (trace.tracability === Tracability.Normal) {
      if (filterName === "แสดงทั้งหมด") return true;
      else if (
        filterName === "รอสแกน" &&
        trace.status === InventoryDocumentStatus.IsActive
      )
        return true;
      else if (
        filterName === "สแกนแล้ว" &&
        trace.status === InventoryDocumentStatus.IsScan
      )
        return true;
      else return false;
    } else {
      if (filterName === "แสดงทั้งหมด") return true;
      else if (
        filterName === "รอสแกน" &&
        trace.serial_list &&
        trace.serial_list.filter(
          (serial) => serial.status === InventoryDocumentStatus.IsActive
        ).length > 0
      ) {
        return true;
      } else if (
        filterName === "สแกนแล้ว" &&
        trace.serial_list &&
        trace.serial_list.filter(
          (serial) => serial.status === InventoryDocumentStatus.IsScan
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <>
        {!isMobile && <Typography fontWeight="bold">สแกน Barcode</Typography>}
        <Grid container spacing={2} mt={1} mb={3}>
          {isMobile && (
            <Grid item xs={12}>
              <CustomizedMenuOptions
                fullWidth
                size="large"
                label={filterName}
                options={filterScanOptions}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  setFilterName(value.innerText);
                }}
                disabled={disabled && status !== "wait_transfer"}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <ControlledSelect
              label="เลือกสถานที่"
              control={barcodeControl}
              name="destination_bin_location_id"
              options={binLocationOptions || []}
              disabled={disabled && status !== "wait_transfer"}
            />
          </Grid>
          {!isMobile ? (
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    const target = e.target as HTMLInputElement;
                    const data = target.value;
                    onBarcodeSubmitHandler(data);
                  }
                }}
                disabled={
                  (disabled && status !== "wait_transfer") ||
                  !watchBarcode("destination_bin_location_id")
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={10}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      const target = e.target as HTMLInputElement;
                      const data = target.value;
                      onBarcodeSubmitHandler(data);
                    }
                  }}
                  disabled={
                    (disabled && status !== "wait_transfer") ||
                    !watchBarcode("destination_bin_location_id")
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={
                    (disabled && status !== "wait_transfer") ||
                    !watchBarcode("destination_bin_location_id")
                  }
                  onClick={() => setShowCamera(!showCamera)}
                >
                  <QrCodeScannerIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
        {isMobile ? (
          <Box display={showCamera ? "block" : "none"} my={2}>
            {showCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (!!result) {
                    setScanData(result.getText());
                    setIsInterval(true);
                  }
                }}
                scanDelay={2000}
                containerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoContainerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoStyle={{
                  height: "100%",
                  contentVisibility: "auto",
                }}
              />
            )}
          </Box>
        ) : null}
      </>
      <TableContainer ref={scrollRef}>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align="center"
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <>
                {checkConditionRender(trace) && (
                  <Fragment key={trace.id}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[0]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>{index + 1}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[1]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {formatDateTimeNoAMPM(trace.posted_date)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[2]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            minWidth: "90px",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar
                            alt={trace.item_name}
                            src={
                              trace?.item_img_url ? trace.item_img_url[0] : ""
                            }
                            sx={{
                              width: 60,
                              height: 60,
                              border: "1px solid #BEBEBE",
                              borderRadius: "2px",
                            }}
                            variant="square"
                          >
                            <ImageOutlinedIcon
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                              fontSize="medium"
                            />
                          </Avatar>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[3]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.item_unique_id}
                        </Typography>
                        <Typography fontSize={14}>{trace.item_name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[4]?.width || 0
                            : 0) + 16
                        }
                      >
                        {trace.tracability === Tracability.Normal ? (
                          <Typography fontSize={14}>{trace.qty}</Typography>
                        ) : (
                          <TotalPostedQuantityCell
                            control={control}
                            nestedIndex={index}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[5]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>{trace.uom?.name}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[6]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>{trace.serial_no}</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[7]?.width || 0
                            : 0) + 16
                        }
                      >
                        <Typography fontSize={14}>
                          {trace.source_bin_location?.bin_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[8]?.width || 0
                            : 0) + 16
                        }
                      >
                        {trace.scanned_by && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomizedAvatar
                              avatars={[
                                {
                                  unique_id:
                                    trace.scanned_by.user_unique_id || "",
                                  first_name: trace.scanned_by.first_name || "",
                                  last_name: trace.scanned_by.last_name || "",
                                  img_url: trace.scanned_by.img_url
                                    ? trace.scanned_by.img_url[0]
                                    : "",
                                },
                              ]}
                            />
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        width={
                          (headers && headers.length > 0
                            ? headers[9]?.width || 0
                            : 0) + 16
                        }
                        align="center"
                      >
                        {trace.tracability === Tracability.Normal &&
                          trace.destination_bin_location && (
                            <Typography fontSize={14}>
                              {trace.destination_bin_location.bin_name}
                            </Typography>
                          )}
                      </TableCell>
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                        width={
                          (headers && headers.length > 0
                            ? headers[10]?.width || 0
                            : 0) + 16
                        }
                      >
                        <CustomizedStatus status={trace.status} />
                      </TableCell>
                      {status !== "finished" && status !== "cancelled" && (
                        <TableCell
                          align="center"
                          width={
                            (headers && headers.length > 0
                              ? headers[11]?.width || 0
                              : 0) + 16
                          }
                        >
                          {trace.tracability === Tracability.Normal &&
                            trace.status === InventoryDocumentStatus.IsScan && (
                              <CustomizedTooltip title="สแกนใหม่">
                                <IconButton
                                  onClick={() => rescanBarcodeHandler(index)}
                                >
                                  <ReplayOutlinedIcon fontSize="small" />
                                </IconButton>
                              </CustomizedTooltip>
                            )}
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                        align="center"
                      >
                        {trace.tracability === Tracability.Normal &&
                          trace.destination_scanned_by && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CustomizedAvatar
                                avatars={[
                                  {
                                    unique_id:
                                      trace.destination_scanned_by
                                        .user_unique_id || "",
                                    first_name:
                                      trace.destination_scanned_by.first_name ||
                                      "",
                                    last_name:
                                      trace.destination_scanned_by.last_name ||
                                      "",
                                    img_url: trace.destination_scanned_by
                                      .img_url
                                      ? trace.destination_scanned_by.img_url[0]
                                      : "",
                                  },
                                ]}
                              />
                            </Box>
                          )}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          px: 1,
                          py: 1,
                        }}
                      >
                        {trace.tracability === Tracability.Normal && (
                          <Typography fontSize={14}>
                            {formatDateTimeNoAMPM(
                              trace.destination_scanned_date
                            )}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={14}>{trace.barcode}</Typography>
                      </TableCell>
                      <TableCell>
                        {trace.tracability === Tracability.Normal && (
                          <Typography fontSize={14}>
                            {formatDate(trace.lot_date)}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <SerialListScan
                      control={control}
                      nestedIndex={index}
                      status={status}
                    />
                  </Fragment>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GoodsTransferScanItemList;
