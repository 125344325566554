import { v4 as uuidv4 } from "uuid";
import { IPermission } from "../types/Auth/user";
import {
  PermissionBinaryType,
  PermissionBinaryViewType,
  PermissionViewType,
} from "../generated/rbac";

export const departmentType = [
  {
    id: uuidv4(),
    label: "บริหาร",
    value: "บริหาร",
  },
  {
    id: uuidv4(),
    label: "คลังสินค้า",
    value: "คลังสินค้า",
  },
  {
    id: uuidv4(),
    label: "รายรับ",
    value: "รายรับ",
  },
  {
    id: uuidv4(),
    label: "จัดซื้อ",
    value: "จัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "บัญชี",
    value: "บัญชี",
  },
  {
    id: uuidv4(),
    label: "ขนส่ง",
    value: "ขนส่ง",
  },
  {
    id: uuidv4(),
    label: "ผลิต",
    value: "ผลิต",
  },
  {
    id: uuidv4(),
    label: "ดูแลระบบ",
    value: "ดูแลระบบ",
  },
];

export const positionType = [
  {
    id: uuidv4(),
    label: "กรรมการผู้จัดการ",
    value: "กรรมการผู้จัดการ",
  },
  {
    id: uuidv4(),
    label: "ผู้บริหาร",
    value: "ผู้บริหาร",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการผ่ายผลิต",
    value: "ผู้จัดการผ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
    value: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
  },
  {
    id: uuidv4(),
    label: "พนักงานฝ่ายผลิต",
    value: "พนักงานฝ่ายผลิต",
  },
  {
    id: uuidv4(),
    label: "ผู้จัดการฝ่ายขาย",
    value: "ผู้จัดการฝ่ายขาย",
  },
  {
    id: uuidv4(),
    label: "เซลล์",
    value: "เซลล์",
  },
  {
    id: uuidv4(),
    label: "เซลล์แอดมิน",
    value: "เซลล์แอดมิน",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าจัดซื้อ",
    value: "หัวหน้าจัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "จัดซื้อ",
    value: "จัดซื้อ",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าฝ่ายสินเชื่อ",
    value: "หัวหน้าฝ่ายสินเชื่อ",
  },
  {
    id: uuidv4(),
    label: "บัญชีลูกหนี้",
    value: "บัญชีลูกหนี้",
  },

  {
    id: uuidv4(),
    label: "บัญชีคลัง",
    value: "บัญชีคลัง",
  },
  {
    id: uuidv4(),
    label: "บัญชี",
    value: "บัญชี",
  },
  {
    id: uuidv4(),
    label: "พนักงานคลังสโตร์",
    value: "พนักงานคลังสโตร์",
  },
  {
    id: uuidv4(),
    label: "ธุรการคลัง",
    value: "ธุรการคลัง",
  },
  {
    id: uuidv4(),
    label: "หัวหน้าจัดส่ง",
    value: "หัวหน้าจัดส่ง",
  },
  {
    id: uuidv4(),
    label: "ผู้ดูแลระบบ",
    value: "ผู้ดูแลระบบ",
  },
];

export const defaultPermission: IPermission = {
  contact: {
    view: PermissionBinaryType.Allow,
  },
  inventory: {
    view: PermissionBinaryType.Allow,
  },
  project: {
    view: PermissionBinaryType.Allow,
  },
  purchase: {
    view: PermissionBinaryType.Allow,
  },
  sales: {
    view: PermissionBinaryType.Allow,
  },
  delivery: {
    view: PermissionBinaryType.Allow,
  },
  manufacture: {
    view: PermissionBinaryType.Allow,
  },
  setting: {
    view: PermissionBinaryType.Allow,
  },
  quotation: {
    view: PermissionViewType.All,
  },
  sales_order: {
    view: PermissionViewType.All,
  },
  sales_return: {
    view: PermissionViewType.All,
  },
  sales_report: {
    view: PermissionBinaryViewType.All,
  },
  purchase_request: {
    view: PermissionViewType.All,
  },
  purchase_order: {
    view: PermissionViewType.All,
  },
  purchase_return: {
    view: PermissionViewType.All,
  },
  purchase_report: {
    view: PermissionBinaryViewType.All,
  },
  goods_receive: {
    view: PermissionViewType.All,
  },
  goods_issue: {
    view: PermissionViewType.All,
  },
  goods_adjustment: {
    view: PermissionViewType.All,
  },
  goods_transfer: {
    view: PermissionViewType.All,
  },
  goods_return: {
    view: PermissionViewType.All,
  },
  inventory_movement_summary_report: {
    view: PermissionBinaryViewType.All,
  },
  item: {
    view: PermissionViewType.All,
  },
  inventory_report: {
    view: PermissionBinaryViewType.All,
  },
  delivery_order: {
    view: PermissionViewType.All,
  },
  delivery_trip: {
    view: PermissionViewType.All,
  },
  logistic_report: {
    view: PermissionBinaryViewType.All,
  },
  user_information: {
    view: PermissionViewType.All,
  },
  role: {
    view: PermissionViewType.All,
  },
  customer: {
    view: PermissionViewType.All,
  },
  vendor: {
    view: PermissionViewType.All,
  },
  contact_report: {
    view: PermissionBinaryViewType.All,
  },
  manufacture_order: {
    view: PermissionViewType.All,
  },
  bom: {
    view: PermissionViewType.All,
  },
  routing: {
    view: PermissionViewType.All,
  },
  work_order: {
    view: PermissionViewType.All,
  },
  manufacture_report: {
    view: PermissionBinaryViewType.All,
  },
  projects: {
    view: PermissionViewType.All,
  },
  event: {
    view: PermissionViewType.All,
  },
  setting_company: {
    view: PermissionBinaryViewType.All,
  },
  setting_approval: {
    view: PermissionBinaryViewType.All,
  },
  setting_remark: {
    view: PermissionBinaryViewType.All,
  },
  setting_inventory: {
    view: PermissionBinaryViewType.All,
  },
  setting_manufacture: {
    view: PermissionBinaryViewType.All,
  },
  setting_logistic: {
    view: PermissionBinaryViewType.All,
  },
  setting_project: {
    view: PermissionBinaryViewType.All,
  },
};
