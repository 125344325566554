import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";

const Sales = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.quotation.index"),
      to: "/sales/quotation",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("sales.order.index"),
      to: "/sales/order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("sales.return.index"),
      to: "/sales/return",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("report"),
      to: "/sales/report",
      icon: <ContentPasteOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Sales;
