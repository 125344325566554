import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useSalesError = (errors: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const {
        unique_id,
        issue_date,
        item_list,
        customer_contact_unique_id,
        reason_to_return,
      } = errors;
      if (unique_id) {
        enqueueSnackbar(unique_id.message, {
          variant: "error",
        });
      }
      if (issue_date) {
        enqueueSnackbar(issue_date.message, {
          variant: "error",
        });
      }
      if (customer_contact_unique_id) {
        enqueueSnackbar(customer_contact_unique_id.message, {
          variant: "error",
        });
      }
      if (item_list) {
        if (Array.isArray(item_list)) {
          item_list.forEach((item) => {
            if (item) {
              const { item_name, qty, uom, price_per_unit } = item;
              if (qty) {
                enqueueSnackbar(qty.message, {
                  variant: "error",
                });
              }
              if (item_name) {
                enqueueSnackbar(item_name.message, {
                  variant: "error",
                });
              }
              if (uom) {
                enqueueSnackbar(uom.message, {
                  variant: "error",
                });
              }
              if (price_per_unit) {
                enqueueSnackbar(price_per_unit.message, {
                  variant: "error",
                });
              }
            }
          });
        } else {
          enqueueSnackbar(item_list.message, {
            variant: "error",
          });
        }
      }
      if (reason_to_return) {
        enqueueSnackbar(reason_to_return.message, {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);
  return;
};
