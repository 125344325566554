import AgGrid from "../../UI/AgGrid";
import { useCallback, useEffect, useRef } from "react";
import { contactColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { IContact } from "../../../types/Contact";
import { CONTACTS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";
import {
  ContactEntityType,
  ContactTagsFindByEntityQuery,
  useContactTagsFindByEntityQuery,
} from "../../../generated/contact";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { Box } from "@mui/material";

interface Props {
  isFilter?: string | null;
  isContactPage?: boolean;
}

const ContactsTable = ({ isFilter, isContactPage }: Props) => {
  const gridRef = useRef<AgGridReact<IContact>>(null);
  // const filterRef = useRef<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const { data: tagList } =
    useContactTagsFindByEntityQuery<ContactTagsFindByEntityQuery>(
      graphQLClientWithHeaderContact,
      {
        entityName: ContactEntityType.Contact,
      }
    );

  const columnDefs: ColDef[] = contactColumnDefs(
    t,
    undefined,
    tagList?.contactTagsFindByEntity ?? [],
    isContactPage
  );

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { is_customer, is_vendor, tag_list, is_active, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        is_customer: is_customer && {
          filterType: "boolean",
          type: "equals",
          filter: true,
        },
        is_vendor: is_vendor && {
          filterType: "boolean",
          type: "equals",
          filter: true,
        },
        is_active: is_active && {
          ...is_active,
          values: is_active.values.map((v: string) => parseInt(v)),
        },
        tag_list: tag_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            name: {
              filterType: "set",
              values: tag_list.values,
            },
          },
        },
      };
      try {
        const { contactsFindManyAggrid } =
          await graphQLClientWithHeaderContact.request(CONTACTS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        params.success({
          rowData: contactsFindManyAggrid.data as any[],
          rowCount: contactsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceTypeCustomer = params.api.getFilterInstance("is_customer");
      const instanceTypeVendor = params.api.getFilterInstance("is_vendor");
      switch (isFilter) {
        case "vendor":
          instanceTypeVendor?.setModel({
            filterType: "text",
            type: "contains",
            filter: isFilter,
          });
          instanceTypeCustomer?.setModel({});
          break;
        case "customer":
          instanceTypeCustomer?.setModel({
            filterType: "text",
            type: "contains",
            filter: isFilter,
          });
          instanceTypeVendor?.setModel({});
          break;
        default:
          instanceTypeCustomer?.setModel({});
          instanceTypeVendor?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { unique_id } = params.data;
    navigate(`/contact/${unique_id}`);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  return (
    <Box
      sx={{
        "& .ag-floating-filter-body": {
          position: "relative",
          margin: 0,
          padding: "4px",
        },
        "& .ag-header-cell .ag-floating-filter-button": {
          position: "absolute",
          top: "50%",
          right: "8px",
          transform: "translateY(-50%)",
          transition: "visibility 0s, opacity 0.3s ease",
          opacity: 0,
        },
        "& .ag-header-cell:hover .ag-floating-filter-button": {
          // visibility: "visible",
          opacity: 1,
        },
      }}
    >
      <AgGrid
        ref={gridRef}
        columnDefs={columnDefs}
        height={665}
        onGridReady={onGridReady}
        onRowDoubleClicked={rowSelectHandler}
        path={"/contact"}
      />
    </Box>
  );
};

export default ContactsTable;
