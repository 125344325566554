import { useEffect, useMemo, useState } from "react";
import { IMenuOption } from "../../types/global";

export const useSalesOrderOption = (
  status?: string | null,
  taxInvoiceNumber?: string | null
) => {
  const defaultOptions: IMenuOption[] = useMemo(
    () => [
      {
        value: "แก้ไข",
        disabled: false,
      },
      {
        value: "คัดลอก",
        disabled: false,
      },
      {
        value: "ยกเลิก",
        disabled: false,
      },
    ],
    []
  );

  const [selectModifyOptions, setSelectedModifyOptions] =
    useState<IMenuOption[]>(defaultOptions);

  useEffect(() => {
    let options: IMenuOption[] = [];
    switch (status) {
      case "draft":
        options = [defaultOptions[1], defaultOptions[2]]; //ยกเลิก, คัดลอก
        break;
      case "cancelled":
        options = [defaultOptions[1]]; //คัดลอก
        break;
      case "approved":
        options = [
          // {
          //   value: "บันทึกใบแจ้งหนี้",
          //   disabled: false,
          // },
          ...defaultOptions,
        ];
        break;
      case "finished":
        options = [defaultOptions[1], defaultOptions[2]];
        break;
      default:
        options = defaultOptions;
        break;
    }
    if (
      status != null &&
      ["approved", "finished"].includes(status) &&
      taxInvoiceNumber == null
    ) {
      options.splice(0, 0, {
        value: "บันทึกใบกำกับภาษี",
        disabled: false,
      });
    }

    setSelectedModifyOptions(options);
  }, [defaultOptions, status, taxInvoiceNumber]);

  return selectModifyOptions;
};
