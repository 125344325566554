import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ItemType } from "../../../../generated/inventory";
import { ISelectOption } from "../../../../types/global";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import ControlledSelect from "../../../Controller/ControlledSelect";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RadioBox from "../../../UI/RadioBox";
import NewImporterDropzoneUI from "../../../NewImporterDropzoneUI";

const ItemImportUploader = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const itemTypeOptions: ISelectOption[] = [
    { label: t("inventory.type.normal"), value: ItemType.Normal },
    // { label: t("inventory.type.variant"), value: ItemType.Variant },
    // { label: t("inventory.type.bundle"), value: ItemType.Bundle },
    { label: t("inventory.type.service"), value: ItemType.Service },
  ];

  const type = watch("type");

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ControlledSelect
            control={control}
            name="import_type"
            options={itemTypeOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedButton
            title={t("inventory.items.import.download_template")}
            variant="outlined"
            fullWidth
            size="medium"
            onClick={() =>
              window.open("/static/item_normal_import_template.xlsx", "_blank")
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomizedTooltip title="">
            <InfoOutlinedIcon />
          </CustomizedTooltip>
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "create");
            }}
            label={t("button.create")}
            checked={type === "create"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RadioBox
            onClick={() => {
              setValue("type", "update");
            }}
            label={t("sentence.edit")}
            checked={type === "update"}
            small
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12}>
          <NewImporterDropzoneUI hasLabelHeader />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ItemImportUploader;
