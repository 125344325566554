import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { useConfirmation } from "../../../hooks/use-confrimation";

import { Typography, Grid, Box, List, ListItem } from "@mui/material";

import { IDefaultForm } from "../../../types/global";

import ModalUI from "../../../components/UI/ModalUI";
import UserAccountForm from "../../../components/Form/UserAccount";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedMenuOptions from "../../../components/Custom/CustomizedMenuOptions";

import {
  UserDeleteMutation,
  useUserDeleteMutation,
} from "../../../generated/company-user";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

type ExtendProps = IDefaultForm & {
  disabled?: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
};

const Account = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
  setDisabled,
}: ExtendProps) => {
  const { t } = useTranslation();
  // const { createPermission, editPermission } = usePermission();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { mutate: deleteUser } = useUserDeleteMutation<Error>(
    graphQLClientWithHeaderCompany,
    {
      onSuccess: ({ userDelete }: UserDeleteMutation) => {
        enqueueSnackbar("ลบผู้ใช้งานสำเร็จ", {
          variant: "success",
        });
        navigate("/user/account/");
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("ลบผู้ใช้งานไม่สำเร็จ", {
          variant: "error",
        });
        setDisabled(false);
      },
    }
  );

  const submitDeleteHandler = () => {
    const unique_id = getValues("unique_id");
    deleteUser({ uniqueInput: { unique_id } });
    // const unique_id = getValues("unique_id");
    // deleteItem({ uniqueInput: { unique_id } });
  };

  const {
    confirmation: confirmationDelete,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(submitDeleteHandler);

  const bulletRule = [
    "หน้าผู้ใช้งาน และหน้าอื่นๆที่มีรายการผู้ใช้งาน",
    "ตั้งค่าลำดับขั้นอนุมัติ",
    "ผู้เกี่ยวข้องเอกสารที่อยู่ในสถานะที่ไม่เสร็จสิ้น",
    "พนักงานขายที่อยู่ในเอกสารขายสถานะที่ไม่เสร็จสิ้น",
    "พนักงานขนส่งที่อยู่ในเอกสารขนส่งสถานะที่ไม่เสร็จสิ้น",
  ];

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mt={3}>
        <Grid item>
          <Typography variant="h5">{t("user.account.index")}</Typography>
        </Grid>
        <Grid item>
          {disabled && (
            <CustomizedMenuOptions
              label={"ตัวเลือก"}
              options={[{ value: "แก้ไข" }]}
              onSelect={(e) => {
                const value = e.target as HTMLElement;
                switch (value.innerText) {
                  case "แก้ไข":
                    setDisabled(false);
                    break;
                  case "ลบ":
                    openDeleteConfirmation();
                    break;
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <UserAccountForm
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
      />
      <ModalUI
        title={t("user.title.delete_user")}
        open={confirmationDelete}
        handleClose={closeDeleteConfirmation}
        maxWidth="xs"
        fullWidth
      >
        <Typography>{`ผู้ใช้งาน ${getValues(
          "first_name"
        )} จะหายไปจากรายการต่อไปนี้`}</Typography>
        <List sx={{ listStyleType: "disc", ml: 3 }}>
          {bulletRule.map((data) => (
            <ListItem key={data} sx={{ display: "list-item" }}>
              {data}
            </ListItem>
          ))}
        </List>
        <Typography>กดยืนยันหากต้องการลบผู้ใช้งาน</Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={closeDeleteConfirmation}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={submitDeleteConfirmation}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default Account;
