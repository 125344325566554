import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useNavigate } from "react-router-dom";

const Vehicle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.logistic.index"),
      to: "/setting/logistic",
    },
    {
      name: t("setting.logistic.vehicle"),
      // to: "/setting/logistic/vehicle",
    },
  ];
  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">
          {`ตั้งค่า${t("setting.logistic.vehicle")}`}
        </Typography>
        <CustomizedButton
          title={t("button.add")}
          variant="contained"
          onClick={() => navigate("/setting/logistic/vehicle/add")}
        />
      </HeaderLayout>
    </>
  );
};

export default Vehicle;
