import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm } from "../../../types/global";
import {
  GoodsTransferQuery,
  WarehousesQuery,
} from "../../../generated/inventory";
import GoodsTransferHeader from "../../../components/Form/Inventory/GoodsTransfer/Header";
import GoodsTransferDetail from "../../../components/Form/Inventory/GoodsTransfer/Detail";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsTransferQuery, unknown>>;
  data?: GoodsTransferQuery;
  warehouseData?: WarehousesQuery;
};

const DocumentInfoTab = ({
  control,
  disabled,
  errors,
  getValues,
  refetch,
  setValue,
  data,
  warehouseData,
  reset,
}: Props) => {
  return (
    <>
      <GoodsTransferHeader
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        status={data?.goodsTransfer?.aggrid_status}
        refetch={refetch}
      />
      <GoodsTransferDetail
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        reset={reset}
        allWarehouses={warehouseData?.warehouses || []}
        status={data?.goodsTransfer?.aggrid_status}
      />
    </>
  );
};

export default DocumentInfoTab;
