import { useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { useWatch } from "react-hook-form";
import {
  IBillingAddress,
  IDefaultForm,
  IDeliveryAddress,
} from "../types/global";
import { IContact } from "../types/Contact";
import { ICustomer } from "../types/Sales";
import { IAddress } from "../types/Setting/company";
import { IQuotation } from "../types/Sales/quotation";
import { ISalesOrder } from "../types/Sales/salesOrder";
import { ISalesReturn } from "../types/Sales/salesReturn";
import { IPurchaseOrder } from "../types/Purchase/purchaseOrder";
import { IPurchaseReturn } from "../types/Purchase/purchaseReturn";
import { IVendor } from "../types/Purchase";
import { useSearchParams } from "react-router-dom";
import { CompanyQuery, useCompanyQuery } from "../generated/company-user";
import { EntityTypeEnum } from "../generated/creatable";
import { ContactQuery, useContactQuery } from "../generated/contact";
import { GraphQLClient } from "graphql-request";
import { formatContactName } from "../utils/Formatter/Global";

export const useContactDescription = (
  control: IDefaultForm["control"],
  reset: IDefaultForm["reset"],
  module: "sales" | "purchase",
  documentType: EntityTypeEnum
) => {
  const contactUniqueId = useWatch({
    control,
    name:
      module === "sales"
        ? "customer_contact_unique_id"
        : "vendor_contact_unique_id",
  });

  const contact = useWatch({
    control,
    name: module === "sales" ? "customer_contact" : "vendor_contact",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [openContactTable, setOpenContactTable] = useState<boolean>(false);
  const [openContactDetail, setOpenContactDetail] = useState<boolean>(false);

  const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactIdsSnapshot, setContactIdsSnapshot] = useState<string[]>([]);

  const [openBillingAddress, setOpenBillingAddress] = useState<boolean>(false);
  const [billingAddressSnapshot, setBillingAddressSnapshot] =
    useState<IBillingAddress | null>({
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    });

  const [openDeliveryAddress, setOpenDeliveryAddress] =
    useState<boolean>(false);
  const [deliveryAddressSnapshot, setDeliveryAddressSnapshot] =
    useState<IDeliveryAddress | null>({
      address: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_type: "",
      country: "",
      district: "",
      is_same_as_default_address: false,
      postal_code: "",
      province: "",
      sub_district: "",
    });

  const [allAddressList, setAllAddressList] = useState<
    IAddress[] | null | undefined
  >([]);

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderContact: GraphQLClient =
    createGraphQLClientWithMiddleware("contact");

  const currentTenantId = sessionStorage.getItem("tenant-id");

  const { refetch: companyRefetch, isLoading: companyIsLoading } =
    useCompanyQuery<CompanyQuery>(
      graphQLClientWithHeaderCompany,
      {
        uniqueInput: {
          unique_id: currentTenantId,
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: contactRefetch } = useContactQuery<ContactQuery>(
    graphQLClientWithHeaderContact,
    {
      uniqueInput: {
        unique_id: contactUniqueId,
      },
    },
    {
      enabled: false,
    }
  );

  const openContactTableHandler = async () => {
    setOpenContactTable(true);
  };

  const closeContactTableHandler = () => {
    setOpenContactTable(false);
  };

  const openContactDetailHandler = () => {
    setOpenContactDetail(true);
  };

  const closeContactDetailHandler = () => {
    setOpenContactDetail(false);
    searchParams.delete("contactTab");
    setSearchParams(searchParams);
  };

  const openBillingAddressHandler = () => {
    setOpenBillingAddress(true);
  };

  const closeBillingAddressHandler = () => {
    setOpenBillingAddress(false);
  };

  const openDeliveryAddressHandler = async () => {
    if (module === "purchase") {
      if (documentType === EntityTypeEnum.PurchaseOrder) {
        const { data: companyData } = await companyRefetch();

        const address_list = companyData?.company?.address_list;
        setAllAddressList(address_list);
      } else {
        const { data } = await contactRefetch();

        const contactData = data?.contact as IContact;
        const { address_list } = contactData;
        setAllAddressList(address_list);
      }
    } else {
      const { data } = await contactRefetch();
      const contactData = data?.contact as IContact;
      const { address_list } = contactData;
      setAllAddressList(address_list);
    }

    setOpenDeliveryAddress(true);
  };

  const closeDeliveryAddressHandler = () => {
    setOpenDeliveryAddress(false);
  };

  const finishContactsSelect = (data: IContact) => {
    if (data) {
      const {
        contact_channel_list,
        contact_type,
        contact_type_detail,
        title_name,
        main_contact_name,
        secondary_contact_name,
        unique_id,
        identity_no,
        address_list,
        sales_list,
        finance,
        branch_type,
        branch_id,
        branch_name,
      } = data;

      const foundEmail =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "Email"
        )?.contact_channel_name || "";

      const foundPhone =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "เบอร์โทรศัพท์"
        )?.contact_channel_name || "";

      const foundFax =
        contact_channel_list?.find(
          (channel) => channel.contact_channel_type === "แฟกซ์"
        )?.contact_channel_name || "";

      const foundBillingAddress = address_list?.find(
        (address) => address.address_type === "ที่อยู่จดทะเบียน"
      );

      const formatBillingAddress = foundBillingAddress
        ? {
            address: foundBillingAddress.address,
            sub_district: foundBillingAddress.sub_district,
            district: foundBillingAddress.district,
            province: foundBillingAddress.province,
            postal_code: foundBillingAddress.postal_code,
            country: foundBillingAddress.country,
          }
        : {
            address: "",
            sub_district: "",
            district: "",
            province: "",
            postal_code: "",
            country: "",
          };

      let formatBranchName: string = "";

      if (branch_type === "branch") {
        if (branch_id) {
          formatBranchName = `${branch_id}`;
          if (branch_name) {
            formatBranchName = `${branch_id} - สาขา ${branch_name}`;
          }
        }
      } else if (branch_type === "hq") {
        formatBranchName = "สำนักงานใหญ่";
      } else {
        formatBranchName = "ไม่ระบุ";
      }

      if (module === "sales") {
        const foundDeliveryAddress = address_list?.find(
          (address) => address.address_type === "ที่อยู่จัดส่ง"
        );

        const formatDeliveryAddress = foundDeliveryAddress
          ? {
              address_type: foundDeliveryAddress.address_type,
              is_same_as_default_address:
                foundDeliveryAddress.is_same_as_default_address,
              address_contact_name: foundDeliveryAddress.address_contact_name,
              address_contact_phone: foundDeliveryAddress.address_contact_phone,
              address: foundDeliveryAddress.address,
              sub_district: foundDeliveryAddress.sub_district,
              district: foundDeliveryAddress.district,
              province: foundDeliveryAddress.province,
              postal_code: foundDeliveryAddress.postal_code,
              country: foundDeliveryAddress.country,
            }
          : {
              address_type: "",
              is_same_as_default_address: false,
              address_contact_name: "",
              address_contact_phone: "",
              address: "",
              sub_district: "",
              district: "",
              province: "",
              postal_code: "",
              country: "",
            };

        const customer_contact: ICustomer = {
          unique_id_name: `${unique_id} - ${formatContactName(
            contact_type,
            contact_type_detail,
            title_name || "",
            main_contact_name,
            secondary_contact_name || ""
          )}`,
          name: formatContactName(
            contact_type,
            contact_type_detail,
            title_name || "",
            main_contact_name,
            secondary_contact_name || ""
          ),
          identity_no,
          branch: formatBranchName,
          email: foundEmail,
          phone: foundPhone,
          fax: foundFax,
          billing_address: formatBillingAddress,
          delivery_address: formatDeliveryAddress,
        };

        setAllAddressList(address_list);

        const customer_contact_unique_id = unique_id;

        const formatSalesList = sales_list?.map(
          ({ id, unique_id, nick_name, title_name, ...otherSales }) => ({
            ...otherSales,
            user_unique_id: unique_id,
          })
        );

        const credit_day = finance?.payment_day || 0;

        if (reset) {
          reset((prev: IQuotation | ISalesOrder | ISalesReturn) => ({
            ...prev,
            customer_contact,
            customer_contact_unique_id,
            sales_contact_list:
              documentType === EntityTypeEnum.DeliveryOrder
                ? undefined
                : formatSalesList,
            credit_day,
          }));
        }
      } else {
        if (documentType === EntityTypeEnum.PurchaseOrder) {
          const vendor_contact: IVendor = {
            unique_id_name: `${unique_id} - ${formatContactName(
              contact_type,
              contact_type_detail,
              title_name || "",
              main_contact_name,
              secondary_contact_name || ""
            )}`,
            name: formatContactName(
              contact_type,
              contact_type_detail,
              title_name || "",
              main_contact_name,
              secondary_contact_name || ""
            ),
            identity_no,
            branch: formatBranchName,
            email: foundEmail,
            phone: foundPhone,
            fax: foundFax,
            billing_address: formatBillingAddress,
            delivery_address: {
              address_type: "",
              address_contact_name: "",
              address_contact_phone: "",
              address: "",
              sub_district: "",
              district: "",
              province: "",
              postal_code: "",
              country: "",
            },
          };

          const vendor_contact_unique_id = unique_id;

          if (reset) {
            reset((prev: IPurchaseOrder | IPurchaseReturn) => ({
              ...prev,
              vendor_contact,
              vendor_contact_unique_id,
            }));
          }
        } else {
          const foundDeliveryAddress = address_list?.find(
            (address) => address.address_type === "ที่อยู่จัดส่ง"
          );

          const formatDeliveryAddress = foundDeliveryAddress
            ? {
                address_type: foundDeliveryAddress.address_type,
                is_same_as_default_address:
                  foundDeliveryAddress.is_same_as_default_address,
                address_contact_name: foundDeliveryAddress.address_contact_name,
                address_contact_phone:
                  foundDeliveryAddress.address_contact_phone,
                address: foundDeliveryAddress.address,
                sub_district: foundDeliveryAddress.sub_district,
                district: foundDeliveryAddress.district,
                province: foundDeliveryAddress.province,
                postal_code: foundDeliveryAddress.postal_code,
                country: foundDeliveryAddress.country,
              }
            : {
                address_type: "",
                is_same_as_default_address: false,
                address_contact_name: "",
                address_contact_phone: "",
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              };

          const vendor_contact: IVendor = {
            unique_id_name: `${unique_id} - ${main_contact_name}${
              secondary_contact_name ? ` ${secondary_contact_name}` : ""
            }`,
            name: `${main_contact_name}${
              secondary_contact_name ? ` ${secondary_contact_name}` : ""
            }`,
            branch: formatBranchName,
            identity_no,
            email: foundEmail,
            phone: foundPhone,
            fax: foundFax,
            billing_address: formatBillingAddress,
            delivery_address: formatDeliveryAddress,
          };

          setAllAddressList(address_list);
          const vendor_contact_unique_id = unique_id;

          if (reset) {
            reset((prev: IPurchaseOrder | IPurchaseReturn) => ({
              ...prev,
              vendor_contact,
              vendor_contact_unique_id,
            }));
          }
        }
      }
      setBillingAddressSnapshot(formatBillingAddress);
    } else {
      if (reset) {
        if (module === "sales") {
          reset((prev: IQuotation | ISalesOrder | ISalesReturn) => ({
            ...prev,
            customer_contact: {
              name: "",
              phone: "",
              email: "",
              fax: "",
              identity_no: "",
              billing_address: {
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              },
              delivery_address: {
                address_type: "",
                is_same_as_default_address: false,
                address_contact_name: "",
                address_contact_phone: "",
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              },
            },
            customer_contact_unique_id: null,
          }));
        } else {
          reset((prev: IPurchaseOrder | IPurchaseReturn) => ({
            ...prev,
            vendor_contact: {
              name: "",
              phone: "",
              email: "",
              fax: "",
              identity_no: "",
              billing_address: {
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              },
              delivery_address: {
                address_type: "",
                address_contact_name: "",
                address_contact_phone: "",
                address: "",
                sub_district: "",
                district: "",
                province: "",
                postal_code: "",
                country: "",
              },
            },
            vendor_contact_unique_id: null,
          }));
        }
      }
      setAllAddressList([]);
      setBillingAddressSnapshot({
        address: "",
        sub_district: "",
        district: "",
        province: "",
        postal_code: "",
        country: "",
      });
    }
    closeContactTableHandler();
  };

  useEffect(() => {
    if (contactUniqueId && contact) {
      setContactIds([contactUniqueId]);
      setContactIdsSnapshot([contactUniqueId]);
      // setBillingAddressSnapshot(contact.billing_address);
      // setDeliveryAddressSnapshot(contact.delivery_address);
    }
  }, [contact, contactUniqueId]);

  return {
    contact,
    contactUniqueId,
    openContactTable,
    openContactDetail,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openBillingAddress,
    billingAddressSnapshot,
    setBillingAddressSnapshot,
    openDeliveryAddress,
    deliveryAddressSnapshot,
    setDeliveryAddressSnapshot,
    allAddressList,
    companyIsLoading,
    openContactTableHandler,
    openBillingAddressHandler,
    closeBillingAddressHandler,
    openDeliveryAddressHandler,
    closeDeliveryAddressHandler,
    finishContactsSelect,
    openContactDetailHandler,
    closeContactDetailHandler,
  };
};
