import { Box, Divider, Typography } from "@mui/material";
import { useItemSkuQtysQuery } from "../../generated/inventory";
import CircleIcon from "@mui/icons-material/Circle";
import { IDefaultForm } from "../../types/global";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

interface Props {
  control: IDefaultForm["control"];
  documentType: string;
  itemListName?: string;
  index: number;
  isSufficient: boolean;
  setIsSufficient: Dispatch<SetStateAction<boolean>>;
}

const ItemQty = ({
  control,
  index,
  documentType,
  itemListName,
  isSufficient,
  setIsSufficient,
}: Props) => {
  const status = useWatch({
    control,
    name: `aggrid_status`,
  });

  const watchItemUniqueId = useWatch({
    control,
    name: itemListName
      ? `${itemListName}[${index}].item_unique_id`
      : `item_list[${index}].item_unique_id`,
  });

  const watchQty = useWatch({
    control,
    name: `item_list[${index}].qty`,
  });

  const watchUom = useWatch({
    control,
    name: `item_list[${index}].uom`,
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data } = useItemSkuQtysQuery(graphQLClientWithHeaderItem, {
    findManyInput: {
      where: {
        item_unique_id: {
          equals: watchItemUniqueId,
        },
      },
    },
  });

  const filteredQty =
    data &&
    data.itemSkuQtys &&
    data.itemSkuQtys.map((itemSku) => ({
      purchase_ordered_qty: itemSku?.purchase_ordered_qty,
      manufacture_ordered_qty: itemSku?.manufacture_ordered_qty,
      sale_committed_qty: itemSku?.sale_committed_qty,
      manufacture_committed_qty: itemSku?.manufacture_committed_qty,
      stock_qty: itemSku?.stock_qty,
      available_qty: itemSku?.available_qty,
    }));

  const calculateSum = (array: any[], property: string) => {
    let sum = 0;

    array.forEach((element) => {
      sum += element[property];
    });

    return sum;
  };

  useEffect(() => {
    const current_available_qty = calculateSum(
      filteredQty || [],
      "available_qty"
    );
    switch (documentType) {
      case "quotation": {
        const curr_qty = watchQty ? parseFloat(watchQty) : 0;
        const totalAvailableQty = current_available_qty - curr_qty;
        if (totalAvailableQty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      }
      case "purchase_request": {
        const curr_qty = watchQty ? parseFloat(watchQty) : 0;
        const totalAvailableQty = current_available_qty + curr_qty;
        if (totalAvailableQty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
      }
      case "sales_order": {
        switch (status) {
          case null:
          case "draft":
          case "wait_approve":
            const curr_qty = watchQty ? parseFloat(watchQty) : 0;
            const totalAvailableQty = current_available_qty - curr_qty;
            if (totalAvailableQty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
          default:
            if (current_available_qty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
        }
        break;
      }
      case "purchase_order": {
        switch (status) {
          case null:
          case "draft":
          case "wait_approve":
            const curr_qty = watchQty ? parseFloat(watchQty) : 0;
            const totalAvailableQty = current_available_qty + curr_qty;
            if (totalAvailableQty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
          default:
            if (current_available_qty >= 0) {
              setIsSufficient(true);
            } else {
              setIsSufficient(false);
            }
            break;
        }
        break;
      }
      default:
        if (current_available_qty >= 0) {
          setIsSufficient(true);
        } else {
          setIsSufficient(false);
        }
        break;
    }
  }, [documentType, filteredQty, setIsSufficient, status, watchQty]);

  const formatData: any[] = [
    {
      status: "กำลังจัดซื้อ",
      amount: calculateSum(filteredQty || [], "purchase_ordered_qty"),
    },
    {
      status: "กำลังผลิต",
      amount: calculateSum(filteredQty || [], "manufacture_ordered_qty"),
    },
    {
      status: "ถูกจอง (ขาย)",
      amount: calculateSum(filteredQty || [], "sale_committed_qty"),
    },
    {
      status: "ถูกจอง (ผลิต)",
      amount: calculateSum(filteredQty || [], "manufacture_committed_qty"),
    },
    {
      status: "คงคลัง",
      amount: calculateSum(filteredQty || [], "stock_qty"),
    },
    {
      status: "คงเหลือสุทธิ",
      amount: calculateSum(filteredQty || [], "available_qty"),
    },
  ];

  return (
    <Box
      sx={{ p: 1, display: "flex", flexDirection: "column", width: "250px" }}
    >
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: isSufficient ? "#8FCBB1" : "#F44336",
          fontSize: "13px",
        }}
      >
        <CircleIcon fontSize="inherit" />
        <Typography ml={1}>
          {isSufficient ? "สินค้าเพียงพอ" : "สินค้าไม่เพียงพอ"}
        </Typography>
      </Box>
      <Divider />
      {formatData.map((item) => (
        <Box
          key={item.status}
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography>{item.status}</Typography>
          <Typography sx={{ flex: "1", mr: 1 }} align="right">
            {item.amount && item.amount.toLocaleString()}
          </Typography>
          <Typography>{watchUom}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ItemQty;
