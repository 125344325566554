import React, { Fragment } from "react";
import { useItemCurrentStockItemList } from "../../../../hooks/Inventory/use-item-current-stock-list";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ISkuQty } from "../../../../types/Inventory/item";
import { formatNumber } from "../../../../utils/dataTransformer";

type ExtenedType = {
  type: string;
  data: ISkuQty[];
};

const CurrentStock = ({ type, data }: ExtenedType) => {
  const headers = useItemCurrentStockItemList(type);

  const renderCell = (object: any): JSX.Element[] => {
    return Object.entries(object).map(([key, value]) => {
      const align = key === "barcode" ? "left" : "center";
      const formattedValue =
        typeof value === "number" ? formatNumber(value) : (value as string);

      return (
        <TableCell align={align} key={key}>
          {formattedValue}
        </TableCell>
      );
    });
  };

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  width={header.width}
                  key={header.thaiLabel}
                >
                  <Typography
                    textAlign={header.align ? header.align : "center"}
                    fontSize={14}
                    fontWeight={700}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((list, index) => (
                <TableRow key={index}>{renderCell(list)}</TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default CurrentStock;
