import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { IIngredient } from "../../../types/Manufacture/index";
import { useEffect, useState } from "react";

interface Props {
  data: IIngredient[];
  isBom?: boolean;
}

const defaultHeader = [
  {
    thaiLabel: "รายการ",
    width: 70,
  },
  {
    thaiLabel: "วัตถุดิบ",
    width: 275,
  },
  {
    thaiLabel: "จำนวน",
    width: 200,
  },
  {
    thaiLabel: "หน่วย",
    width: 150,
  },
];

const IngredientPdfTable = ({ data, isBom }: Props) => {
  const [header, setHeader] = useState(defaultHeader);

  useEffect(() => {
    if (!isBom) {
      setHeader([
        {
          thaiLabel: "รายการ",
          width: 70,
        },
        {
          thaiLabel: "วัตถุดิบ",
          width: 275,
        },
        {
          thaiLabel: "จำนวน",
          width: 170,
        },
        {
          thaiLabel: "จำนวนที่นำไปใช้",
          width: 170,
        },
        {
          thaiLabel: "หน่วย",
          width: 100,
        },
      ]);
    }
  }, [isBom]);

  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                width={header.width}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item, index) => (
              <TableRow
                key={item?.item_unique_id}
                sx={{ breakInside: "avoid" }}
              >
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12} fontWeight={600}>
                    {item?.item_name}
                  </Typography>
                  <Typography fontSize={12} whiteSpace="pre-line">
                    {item?.item_description}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatNumber(parseInt(item?.qty || "0"))}
                  </Typography>
                </TableCell>
                {!isBom && (
                  <TableCell
                    align="center"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={12}>
                      {formatNumber(item?.good_issue_qty ?? 0)}
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item?.uom}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IngredientPdfTable;
