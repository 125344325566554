import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
// import ItemInfo from "../../../../pages/Inventory/Items/ItemInfo";
import { formatNumber } from "../../../../utils/dataTransformer";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

import styled from "@emotion/styled";
import CheckedItemModal from "../../../Table/CheckedItemModal";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import ControlledTextField from "../../../Controller/ControlledTextField";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";

import { IDefaultForm } from "../../../../types/global";
import { EntityTypeEnum } from "../../../../generated/creatable";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { IBom } from "../../../../types/Manufacture/bom";

// const exceptThisSymbols = ["e", "E", "+", "-", "."];

type Props = IDefaultForm & {
  minimumStockQty: number;
  isMainPage?: boolean;
  handleClickOpenItem?: (unique_id?: string) => void;
};

const BomDetailForm = ({
  control,
  errors,
  setValue,
  reset,
  disabled,
  minimumStockQty,
  isMainPage,
  handleClickOpenItem,
}: Props) => {
  const { t } = useTranslation();
  const [showSelectItem, setShowSelectItem] = useState(false);

  const [itemIds, setItemIds] = useState<string[]>([]);
  const [itemIdsSnapshot, setItemIdsSnapshot] = useState<string[]>([]);

  const StyledLink = styled(Link)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    cursor: "pointer",
  }));

  const openItemTable = () => {
    setShowSelectItem(true);
  };

  const closeItemTable = () => {
    setShowSelectItem(false);
  };

  const radioLists = [
    {
      value: 1,
      label: "ใช้งาน",
    },
    { value: 0, label: "หยุดใช้งาน" },
  ];

  const watchItemId = useWatch({ control, name: "item_unique_id" });
  const watchItemName = useWatch({ control, name: "item_name" });

  const finishItemSelectHandler = (data: ISkuDetail) => {
    if (data) {
      setValue("item_unique_id", data.item_sku?.item?.unique_id);
      setValue("item_name", data.item_sku?.item?.name);
      setValue("mfg_type", data.item_sku?.item?.manufacturing_type);
      setValue("uom_unique_id", data.item_sku?.item?.stock_uom?.unique_id);
      setValue("uom", data.item_sku?.item?.stock_uom?.name);
      setValue("minimum_stock_qty", data.item_sku?.item?.minimum_stock ?? 0);
    } else {
      reset &&
        reset((prev: IBom) => ({
          ...prev,
          item_unique_id: "",
          item_name: "",
          mfg_type: "",
          uom_unique_id: "",
          uom: "",
          minimun_stock_qty: 0,
        }));
    }
    closeItemTable();
  };

  return (
    <CustomizedBox
      boxShadow={!isMainPage ? "none" : undefined}
      margin={!isMainPage ? 0 : "2rem 0 2rem 0"}
      padding={!isMainPage ? "0 2rem 2rem 2rem" : "2rem"}
    >
      <Typography sx={{ ml: 1, fontWeight: "bold" }}>
        {t("manufacture.bom.detail")}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="name"
            label={t("manufacture.bom.bom_name")}
            control={control}
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors?.name.message}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="description"
            label={t("manufacture.bom.bom_description")}
            control={control}
            error={Boolean(errors?.description)}
            helperText={errors?.description && errors?.description.message}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", gap: 1, mt: 3, alignItems: "center" }}>
        <Typography sx={{ ml: 1 }}>{t("manufacture.bom.item")}</Typography>
        {!disabled && (
          <CustomizedTooltip title="เลือกลินค้า">
            <IconButton
              onClick={openItemTable}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomizedTooltip>
        )}
      </Box>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_unique_id"
            label={t("manufacture.order.item_unique_id")}
            control={control}
            error={Boolean(errors?.item_unique_id)}
            helperText={
              errors?.item_unique_id && errors?.item_unique_id.message
            }
            disabled
            required
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={handleClickOpenItem}>
                    {watchItemId || t("manufacture.order.item_unique_id")}
                  </StyledLink>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!disabled && watchItemId && (
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดสินค้า">
                      <IconButton
                        onClick={() =>
                          handleClickOpenItem
                            ? handleClickOpenItem()
                            : undefined
                        }
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="item_name"
            label={t("manufacture.bom.item_name")}
            control={control}
            error={Boolean(errors?.item_name)}
            helperText={errors?.item_name && errors?.item_name.message}
            sx={{
              input: { visibility: disabled ? "hidden" : "visible" },
            }}
            InputProps={{
              startAdornment: disabled && (
                <InputAdornment position="start">
                  <StyledLink onClick={handleClickOpenItem}>
                    {watchItemName || t("manufacture.bom.item_name")}
                  </StyledLink>
                </InputAdornment>
              ),
            }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledNumberTextField
            name="mfg_qty"
            control={control}
            label={t("manufacture.bom.mfg_qty")}
            helperText={`สั่งผลิตขั้นต่ำ ${formatNumber(minimumStockQty)}`}
            error={Boolean(errors?.mfg_qty)}
            disabled={disabled}
            fullWidth
            // decimalScale={3}
            // onKeyDown={(e) =>
            //   exceptThisSymbols.includes(e.key) && e.preventDefault()
            // }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledTextField
            name="uom"
            label={t("manufacture.bom.uom")}
            control={control}
            error={Boolean(errors?.uom)}
            helperText={errors?.uom && errors?.uom.message}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="mfg_type"
            dataName="mfg_type"
            documentType={EntityTypeEnum.Bom}
            defaultOptions={[]}
            error={Boolean(errors.mfg_type)}
            helperText={errors.mfg_type?.message}
            setValue={setValue}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {isMainPage && (
        <Box sx={{ ml: 1, mt: 3 }}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {t("manufacture.bom.status")}
          </Typography>
          <ControlledRadioGroup
            control={control}
            name="is_active"
            radioLists={radioLists}
            disabled={disabled}
            row
          />
        </Box>
      )}
      <CheckedItemModal
        showSelectItem={showSelectItem}
        closeItemTable={closeItemTable}
        finishItemsSelect={finishItemSelectHandler}
        itemIds={itemIds}
        setItemIds={setItemIds}
        itemIdsSnapshot={itemIdsSnapshot}
        setItemIdsSnapshot={setItemIdsSnapshot}
        rowSelection="single"
      />
    </CustomizedBox>
  );
};

export default BomDetailForm;
