import { useTranslation } from "react-i18next";
import { IDefaultForm, ISelectOption } from "../../../types/global";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Box } from "@mui/material";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
}

const PriceVatType = ({ control, errors, disabled }: Props) => {
  const { t } = useTranslation();
  const priceVatTypeOption: ISelectOption[] = [
    {
      label: t("sales.included_vat"),
      value: "included_vat",
    },
    {
      label: t("sales.excluded_vat"),
      value: "excluded_vat",
    },
  ];

  return (
    <Box mb={3} width={155}>
      <ControlledSelect
        label={t("sales.price_vat_type")}
        control={control}
        name="price_vat_type"
        options={priceVatTypeOption}
        disabled={disabled}
        error={Boolean(errors?.price_vat_type)}
        helperText={errors?.price_vat_type && errors?.price_vat_type?.message}
      />
    </Box>
  );
};

export default PriceVatType;
