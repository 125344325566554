import { useParams } from "react-router-dom";
import {
  PurchaseRequestQuery,
  usePurchaseRequestQuery,
} from "../../../generated/purchase";
import { Box, CircularProgress, Divider } from "@mui/material";
import { formatDate } from "../../../utils/Formatter/Date";
import PurchaseRequestPdfTable from "../../../components/Table/Pdf/PurchaseRequestPdfTable";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import PDFInfo from "../../../components/UI/Pdf/OldPDF/PDFInfo";
import PDFLayout from "../../../components/UI/Pdf/OldPDF/PDFLayout";

const PurchaseRequestPDF = () => {
  const { id } = useParams();
  const graphQLClientWithHeaderPurchase: GraphQLClient =
    createGraphQLClientWithMiddleware("purchase");

  const { data, isLoading } = usePurchaseRequestQuery<PurchaseRequestQuery>(
    graphQLClientWithHeaderPurchase,
    {
      uniqueInput: {
        unique_id: id,
      },
    },
    {
      enabled: !!id,
    }
  );

  const leftHeader = [
    "ผู้ขาย / Requestor",
    "แผนก / Department",
    "ตำแหน่ง / Position",
    "วันที่ออกเอกสาร / Issue Date",
    "ต้องการภายในวันที่ / Due Date",
  ];

  const leftInfo = [
    data?.purchaseRequest?.requestor_contact?.name || "-",
    data?.purchaseRequest?.requestor_contact?.department || "-",
    data?.purchaseRequest?.requestor_contact?.position || "-",
    data?.purchaseRequest?.issue_date
      ? formatDate(data?.purchaseRequest?.issue_date)
      : "-",
    data?.purchaseRequest?.due_date
      ? formatDate(data?.purchaseRequest?.due_date)
      : "-",
  ];

  const rightHeader = [
    "เลขที่เอกสาร / Document No.",
    "อ้างอิงถึง / Ref. Document",
    "คลังปลายทาง / Destination WH",
  ];

  const rightInfo = [
    data?.purchaseRequest?.unique_id || "-",
    data?.purchaseRequest?.reference_unique_id_list &&
    data?.purchaseRequest?.reference_unique_id_list.length > 0
      ? data?.purchaseRequest?.reference_unique_id_list.join(", ")
      : "-",
    data?.purchaseRequest?.destination_warehouse_unique_id || "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบขอซื้อ"
      data={data?.purchaseRequest}
      documentType="purchase_request"
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <PurchaseRequestPdfTable data={data?.purchaseRequest} />
    </PDFLayout>
  );
};

export default PurchaseRequestPDF;
