import { ColDef } from "ag-grid-community";
import { TFunction } from "i18next";

interface Props {
  t: TFunction;
}

export const rbacColumnDefs = (t: Props["t"]): ColDef[] => {
  return [
    {
      field: "id",
      headerName: t("user.rbac.role_id"),
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => String(params.value).padStart(3, "0"),
    },
    {
      field: "name",
      headerName: t("user.rbac.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "description",
      headerName: t("user.rbac.description"),
      filter: "agTextColumnFilter",
    },
    {
      field: "total_users",
      headerName: t("user.rbac.total_user"),
      filter: "agNumberColumnFilter",
    },
  ];
};
