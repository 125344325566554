import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { IPurchaseItemList } from "../../types/Purchase";
import { useItemListColumns } from "../../hooks/use-item-list-columns";
import AgGrid from "../UI/AgGrid";
import ModalUI from "../UI/ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISalesItemList } from "../../types/Sales";
import { IDeliveryOrderItemList } from "../../types/Logistic/deliveryOrder";
import useStylesRadioCheckBoxTable from "../../theme/radio-check-box-table";
import { theme } from "../../theme";

interface Props {
  gridRef: RefObject<
    AgGridReact<
      IDeliveryOrderItemList | IPurchaseItemList | ISalesItemList | any
    >
  >;
  itemList?: (IDeliveryOrderItemList | IPurchaseItemList | ISalesItemList)[];
  documentType: string;
  disabled: boolean;
  itemModal: boolean;
  closeItemModalHandler: () => void;
  addItemsHandler: () => void;
}

const ItemListModal = ({
  gridRef,
  itemList,
  documentType,
  disabled,
  itemModal,
  closeItemModalHandler,
  addItemsHandler,
}: Props) => {
  const columnDefs = useItemListColumns(documentType, disabled);
  const style = useStylesRadioCheckBoxTable(theme);

  const { t } = useTranslation();

  return (
    <ModalUI
      open={itemModal}
      handleClose={closeItemModalHandler}
      title="สินค้า"
      maxWidth="lg"
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            size="medium"
            onClick={closeItemModalHandler}
          />
          <CustomizedButton
            title={t("button.confirm")}
            onClick={addItemsHandler}
            variant="contained"
            size="medium"
          />
        </Box>
      }
    >
      <Box sx={style.checkboxTable}>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={itemList ?? []}
          suppressMenu
          disabledSidebar
          disableFloatingFilter
          suppressContextMenu
          height={500}
          isClientSide
          rowSelection={
            documentType === "item" ||
            documentType === "quotation" ||
            documentType === "sales_order" ||
            documentType === "manufacture_order"
              ? "single"
              : "multiple"
          }
          rowMultiSelectWithClick={
            documentType !== "quotation" &&
            documentType !== "sales_order" &&
            documentType !== "manufacture_order"
          }
          paginationSize={
            documentType === "quotation" || documentType === "sales_order"
              ? 5
              : undefined
          }
        />
      </Box>
    </ModalUI>
  );
};

export default ItemListModal;
