import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../../types/global";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../../components/Custom/CustomizedMenuList";

const CategorySetting = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.category.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.inventory.category.level_one"),
      to: "/setting/inventory/category/level_one",
    },
    {
      name: t("setting.inventory.category.level_two"),
      to: "/setting/inventory/category/level_two",
    },
    {
      name: t("setting.inventory.category.level_three"),
      to: "/setting/inventory/category/level_three",
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default CategorySetting;
