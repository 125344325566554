import { useCallback, useEffect, useMemo, useState } from "react";
import DashboardCard from "../DashboardCard";
import DashboardSection from "../DashboardSection";
import { useForm, useWatch } from "react-hook-form";
import { IPeriodFilter } from "../../../../../types/Dashboard";
import { periodFilterSchema } from "../../../../Form/Dashboard/schema";
import { Grid } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MultipleStopOutlinedIcon from "@mui/icons-material/MultipleStopOutlined";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import {
  GOODS_ADJUSTMENTS_AGGRID,
  GOODS_ISSUES_AGGRID,
  GOODS_RECEIVES_AGGRID,
  GOODS_TRANSFERS_AGGRID,
} from "../../../../../services/AgGrid/InventoryAgGrid";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import { DELIVERY_ORDERS_AGGRID } from "../../../../../services/AgGrid/LogisticAgGrid";
import { GET_STOCK_REPORT_VIEW } from "../../../../../services/AgGrid/InventoryReportAgGrid";
import dayjs from "dayjs";
import th from "dayjs/locale/th";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale(th);

const InventorySection = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useForm<IPeriodFilter>({
    defaultValues: periodFilterSchema,
  });
  const [documentCount, setDocumentCount] = useState<Record<string, number>>({
    รอนำเข้า: 0,
    รอนำออก: 0,
    รอโอนย้าย: 0,
    รออนุมัติการปรับปรุง: 0,
    รอจัดส่ง: 0,
    สินค้าที่ต้องสั่งซื้อ: 0,
  });
  // const [latestFetchedTime, setLatestFetchedTime] = useState<Dayjs>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });

  const cardList = useMemo(
    () => [
      {
        title: "รอนำเข้า",
        icon: (
          <FileDownloadOutlinedIcon
            sx={{ fontSize: "24px", color: "#BDBDBD" }}
          />
        ),
        linkTo: "/inventory/goods_receive?filter=draft",
      },
      {
        title: "รอนำออก",
        icon: (
          <FileUploadOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        linkTo: "/inventory/goods_issue?filter=draft",
      },
      {
        title: "รอโอนย้าย",
        icon: (
          <MultipleStopOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        linkTo: "/inventory/goods_transfer",
        state: ["draft", "wait_transfer"],
      },
      {
        title: "รออนุมัติการปรับปรุง",
        icon: (
          <BuildCircleOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        linkTo: "/inventory/goods_adjustment",
        state: ["draft", "wait_approve"],
      },
      {
        title: "รอจัดส่ง",
        icon: (
          <LocalShippingOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        linkTo: "/logistic/delivery_order",
        state: ["draft", "wait_deliver"],
      },
      {
        title: "สินค้าที่ต้องสั่งซื้อ",
        icon: (
          <ShoppingCartOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        linkTo: "/inventory/report/stock",
        state: ["ต้องสั่งซื้อ"],
      },
    ],
    []
  );

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderLogistic: GraphQLClient =
    createGraphQLClientWithMiddleware("logistic");

  const goodReceiveCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: ["draft"],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { goodsReceivesFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(GOODS_RECEIVES_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });
        const count = goodsReceivesFindManyAggrid.count as number;

        setDocumentCount((prev) => {
          return { ...prev, รอนำเข้า: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goodIssueCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: ["draft"],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { goodsIssuesFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(GOODS_ISSUES_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });
        const count = goodsIssuesFindManyAggrid.count as number;

        setDocumentCount((prev) => {
          return { ...prev, รอนำออก: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goodTransferCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: ["draft", "wait_transfer"],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { goodsTransfersFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(GOODS_TRANSFERS_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });
        const count = goodsTransfersFindManyAggrid.count as number;

        setDocumentCount((prev) => {
          return { ...prev, รอโอนย้าย: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const goodAdjustmentCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: ["draft", "wait_approve"],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { goodsAdjustsFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(GOODS_ADJUSTMENTS_AGGRID, {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          });
        const count = goodsAdjustsFindManyAggrid.count as number;

        setDocumentCount((prev) => {
          return { ...prev, รออนุมัติการปรับปรุง: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deliveryOrderCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: ["draft", "wait_deliver"],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { deliveryOrdersFindManyAggrid } =
          await graphQLClientWithHeaderLogistic.request(
            DELIVERY_ORDERS_AGGRID,
            {
              aggridInput: {
                startRow: 0,
                endRow: 999999,
                filterModel: filter,
                sortModel: [],
              },
            }
          );
        const count = deliveryOrdersFindManyAggrid.count as number;

        setDocumentCount((prev) => {
          return { ...prev, รอจัดส่ง: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const reportStockCount = useCallback(
    async (): Promise<void> => {
      const filter = {
        stock_status: {
          filterType: "set",
          values: ["ต้องสั่งซื้อ"],
        },
      };
      try {
        const { StockReportViews } = await graphQLClientWithHeaderItem.request(
          GET_STOCK_REPORT_VIEW,
          {
            aggridInput: {
              startRow: 0,
              endRow: 999999,
              filterModel: filter,
              sortModel: [],
            },
          }
        );
        const count = StockReportViews.count as number;

        setDocumentCount((prev) => {
          return { ...prev, สินค้าที่ต้องสั่งซื้อ: count };
        });
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getAllCount = useCallback(
    async (dateTo: Date | null, dateFrom: Date | null) => {
      setIsLoading(true);

      await goodReceiveCount(dateFrom, dateTo);
      await goodIssueCount(dateFrom, dateTo);
      await goodTransferCount(dateFrom, dateTo);
      await goodAdjustmentCount(dateFrom, dateTo);
      await deliveryOrderCount(dateFrom, dateTo);
      await reportStockCount();

      setIsLoading(false);
      // setLatestFetchedTime(dayjs());
    },
    [
      deliveryOrderCount,
      goodAdjustmentCount,
      goodIssueCount,
      goodReceiveCount,
      goodTransferCount,
      reportStockCount,
    ]
  );

  // const onRefresh = () => {
  //   getAllCount(dateFrom, dateTo);
  // };

  const onFilterChanged = async () => {
    await getAllCount(dateFrom, dateTo);
  };

  // const onFilterReset = async () => {
  //   await reset(periodFilterSchema);
  //   getAllCount(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
  // };

  useEffect(() => {
    getAllCount(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
  }, [getAllCount]);

  return (
    <DashboardSection
      title={t("inventory.index")}
      control={control}
      // onRefresh={onRefresh}
      // onFilterReset={onFilterReset}
      getFilteredData={onFilterChanged}
      getValues={getValues}
      setValue={setValue}
      // latestFetchTime={latestFetchedTime}
    >
      <Grid container spacing={2} mt={2}>
        {cardList.map((card, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <DashboardCard
              title={card.title}
              documentCount={documentCount[card.title]}
              icon={card.icon}
              linkTo={card.linkTo}
              state={card.state}
              isLoading={isLoading}
            />
          </Grid>
        ))}
      </Grid>
    </DashboardSection>
  );
};

export default InventorySection;
