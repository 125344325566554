import { Box, Typography } from "@mui/material";
import React from "react";
import ModalUI from "./ModalUI";
import CustomizedButton from "../Custom/CustomizedButton";
import { IDefaultForm } from "../../types/global";
import CustomizedStatus from "../Custom/CustomizedStatus";
import ControlledTextField from "../Controller/ControlledTextField";
import ControlledDateTimePicker from "../Controller/ControlledDateTimePicker";

type Props = {
  title?: string;
  message?: string | null;
  currStatus: string;
  nextStatus: string;
  open: boolean;
  handleClose: () => void;
  confirmationAction: () => void;
  control: IDefaultForm["control"];
};

const ChangeStatusWoConfirmation = ({
  title,
  message,
  currStatus,
  nextStatus,
  open,
  handleClose,
  confirmationAction,
  control,
}: Props) => {
  return (
    <ModalUI
      title={title}
      status={<CustomizedStatus status={nextStatus} />}
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
    >
      {message && <Typography>{message}</Typography>}
      <Box display="flex" flexDirection="column" mt={2}>
        <Box width="12rem">
          {nextStatus === "in_progress" && (
            <ControlledDateTimePicker
              control={control}
              name="started_date"
              label="วันที่เริ่ม"
            />
          )}
          {nextStatus === "finished" && (
            <ControlledDateTimePicker
              control={control}
              name="finished_date"
              label="วันที่สิ้นสุด"
            />
          )}
        </Box>
        <Box mt={2}>
          <Typography mb={2}>หมายเหตุ</Typography>
          <ControlledTextField
            control={control}
            name="remark"
            multiline
            rows={4}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          gap: ".5rem",
        }}
      >
        <CustomizedButton
          title="ยกเลิก"
          variant="outlined"
          onClick={handleClose}
        />
        <CustomizedButton
          title="ยืนยัน"
          variant="contained"
          onClick={confirmationAction}
        />
      </Box>
    </ModalUI>
  );
};

export default ChangeStatusWoConfirmation;
