import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import thLocale from "@fullcalendar/core/locales/th";
import {
  EventClickArg,
  EventMountArg,
  EventSourceInput,
} from "@fullcalendar/core";
import CustomizedEventContent from "./CustomizedEventContent";
import { Box, Theme, useMediaQuery } from "@mui/material";

type Props = {
  events: EventSourceInput;
  eventClickHandler: (info: EventClickArg) => void;
};

const Calendar = ({ events, eventClickHandler }: Props) => {
  const eventDidMountHandler = (info: EventMountArg) => {
    // info.el.style.borderRadius = "100px";
    info.el.style.padding = "2px 6px";
    info.el.style.marginBottom = "4px";
  };

  // const datesSetHandler = (info: DatesSetArg) => {
  //   const { start, end } = info;
  //   // setStartDate(dateToUnix(start));
  //   // setEndDate(dateToUnix(end));
  // };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ minWidth: "600px" }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={
          isMobile
            ? {
                left: "prev,today,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }
            : {
                left: "today",
                center: "prev title next",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }
        }
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        buttonText={{
          today: "วันนี้",
          month: "เดือน",
          week: "สัปดาห์",
          day: "วัน",
          list: "ลิสต์",
        }}
        events={events}
        eventDidMount={eventDidMountHandler}
        eventContent={(props) => <CustomizedEventContent {...props} />}
        eventClick={eventClickHandler}
        // eventBorderColor="#1E276C"
        // eventBackgroundColor="#FFFFFF"
        locale={thLocale}
        dayMaxEventRows={true} // for all non-TimeGrid views
        views={{
          week: {
            eventMaxStack: 3,
          },
          day: {
            eventMaxStack: 4,
          },
          listMonth: {
            listDayFormat: {
              day: "numeric",
              month: "short",
              year: "numeric",
            },
          },
          dayGridMonth: { dayMaxEvents: 5, dayMaxEventRows: 5 },
        }}
        // datesSet={datesSetHandler}
        //   listDayFormat={false}
        listDaySideFormat={false}
      />
    </Box>
  );
};

export default Calendar;
