import { IBom } from "../../types/Manufacture/bom";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";

export const formatterBom = async (data: IBom) => {
  const {
    creator_unique_id,
    created_by,
    ingredient_list,
    minimum_stock_qty,
    attachment_list,
    ...otherData
  } = data;
  const new_ingredient_list = ingredient_list?.map(
    ({
      cuid = undefined,
      item_unique_id,
      item_name,
      item_description,
      qty,
      uom_unique_id,
      uom,
      item_barcode,
      item_img_url,
      item_tracability,
    }) => ({
      cuid,
      item_unique_id,
      item_name,
      item_description,
      qty,
      uom_unique_id,
      uom,
      item_barcode,
      item_img_url,
      item_tracability,
    })
  );

  let new_attachment_list: IAttachment[] = [];

  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "bom",
          data?.id?.toString() || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        new_attachment_list.push(formatAttachment);
      } else {
        new_attachment_list.push(file);
      }
    }
  }

  return {
    ...otherData,
    ingredient_list: new_ingredient_list,
    attachment_list: new_attachment_list,
    is_active: data.is_active === 1 || data.is_active === "1" ? 1 : 0,
  };
};
