import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const ProjectPermission = ({ columnDefs }: Props) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "project",
        isFeature: true,
        name: t("project.index"),
        view: "view",
      },
      {
        functionName: "projects",
        name: t("project.index"),
        view: "view",
        create: "create",
        edit: "edit",
        cancel: "cancel",
        feature: "project",
      },
      {
        functionName: "event",
        name: t("project.event.index"),
        view: "view",
        create: "create",
        edit: "edit",
        delete: "delete",
        feature: "project",
      },
    ];
    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default ProjectPermission;
