import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Box, Typography } from "@mui/material";
import { formatNumber } from "../../../../utils/dataTransformer";

interface Props {
  control: IDefaultForm["control"];
}

const SumPreVatAmount = ({ control }: Props) => {
  const sumPreVatAmount = useWatch({
    control,
    name: "pre_vat_amount",
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 3,
          mr: 0.5,
        }}
      >
        <>
          <Typography variant="subtitle1" textAlign={"right"} flex={2}>
            รวม
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ minWidth: 150, textAlign: "right", mr: 1.5, flex: 1 }}
          >
            {formatNumber(sumPreVatAmount)}
          </Typography>
        </>
        <Typography variant="subtitle1" sx={{ ml: 1.5, mr: 2 }}>
          บาท
        </Typography>
      </Box>
    </Box>
  );
};

export default SumPreVatAmount;
