import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
} from "@mui/material";
import { useProjectRelatedDocumentList } from "../../../hooks/Projects/use-project-related-document-list";

import { useProjectRelatedDocumentCells } from "../../../hooks/Projects/use-project-related-document-cells";
import { IProjectRelatedDocument } from "../../../types/Project";
import { formatNumber } from "../../../utils/dataTransformer";
import { EntityTypeEnum } from "../../../generated/creatable";

type Props = {
  data: IProjectRelatedDocument[];
  documentType: EntityTypeEnum;
  tab: string;
};

const RelatedDocumentList = ({ data, documentType, tab }: Props) => {
  const headers = useProjectRelatedDocumentList(documentType, tab);
  const renderRow = useProjectRelatedDocumentCells(documentType, tab);

  const totalValue = data.reduce(
    (acc: number, obj: IProjectRelatedDocument) => {
      if (
        obj &&
        typeof obj.total_amount === "number" &&
        !Number.isNaN(obj.total_amount)
      ) {
        return acc + obj.total_amount;
      }
      return acc;
    },
    0
  );

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <TableContainer>
        <Table>
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  width={header.width}
                  key={index}
                >
                  <Typography
                    align={"center"}
                    fontSize={14}
                    fontWeight={700}
                    width={header.width}
                    marginX={"auto"}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => renderRow(row))}
            <TableRow>
              <TableCell>
                <Typography fontWeight={"bold"}>
                  ผลรวม {data.length} รายการ
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell align={"right"}>
                <Typography>{formatNumber(totalValue)}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RelatedDocumentList;
