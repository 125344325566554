import { useTranslation } from "react-i18next";
import { IDefaultForm } from "../../../types/global";
import { useContactDescription } from "../../../hooks/use-contact-description";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  LinkProps,
  Typography,
  styled,
} from "@mui/material";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import ControlledTextField from "../../Controller/ControlledTextField";
import CheckedContactModal from "../../Table/CheckedContactModal";
import BillingAddressForm from "./BillingAddressForm";
import DeliveryAddressForm from "./DeliveryAddressForm";
import ModalUI from "../../UI/ModalUI";
import ContactContainer from "../../../pages/Contact/Container";
import PurchaseTagList from "../../UI/TagList/PurchaseTagList";
import { PurchaseEntityType } from "../../../generated/purchase";
import CustomizedChips from "../../Custom/CustomizedChips";
import { IItem } from "../../../types/Inventory/item";
import { EntityTypeEnum } from "../../../generated/creatable";

interface ThemeComponentProps extends LinkProps {
  color?: "primary" | "secondary";
}

type Props = IDefaultForm & {
  documentType: EntityTypeEnum;
};

const StyledLink = styled(Link)<ThemeComponentProps>(({ theme }) => ({
  // color: theme.pallete.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const VendorInfoForm = ({
  control,
  setValue,
  getValues,
  errors,
  disabled,
  reset,
  documentType,
}: Props) => {
  const { t } = useTranslation();

  const {
    contact,
    contactUniqueId,
    openContactTable,
    closeContactTableHandler,
    contactIds,
    setContactIds,
    contactIdsSnapshot,
    setContactIdsSnapshot,
    openBillingAddress,
    billingAddressSnapshot,
    setBillingAddressSnapshot,
    openDeliveryAddress,
    deliveryAddressSnapshot,
    setDeliveryAddressSnapshot,
    allAddressList,
    openContactTableHandler,
    openBillingAddressHandler,
    closeBillingAddressHandler,
    openDeliveryAddressHandler,
    closeDeliveryAddressHandler,
    finishContactsSelect,
    openContactDetail,
    openContactDetailHandler,
    closeContactDetailHandler,
  } = useContactDescription(control, reset, "purchase", documentType);
  const renderAddressString = (values: string) => {
    const addressValues = getValues(`vendor_contact[${values}]`);
    if (addressValues) {
      const {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      } = addressValues;

      const orderedValues = {
        address,
        sub_district,
        district,
        province,
        postal_code,
        country,
      };
      let result = "";

      if (addressValues && orderedValues) {
        if (Array.isArray(addressValues)) {
          addressValues.forEach((item) => {
            if (item?.length > 0) {
              result = result + item + ", ";
            }
          });
        } else {
          const keys = Object.keys(orderedValues);
          keys.forEach((key) => {
            const newValue = orderedValues[key as keyof typeof orderedValues];
            if (newValue && newValue.length > 0) {
              result = result + newValue + ", ";
            }
          });
        }
        if (result?.trim().length === 0) {
          return "-";
        }
      }
      // remove whitespace and last comma
      return result?.trim().slice(0, -1);
      // remove whitespace and last comma
    } else return "-";
  };

  const renderAddressContact = () => {
    const addressValues = getValues("vendor_contact.delivery_address");

    if (addressValues) {
      const { address_contact_name, address_contact_phone } = addressValues;

      if (address_contact_name && address_contact_phone) {
        const result = `${address_contact_name}, ${address_contact_phone}`;
        return result;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const tagList: IItem["tag_list"] = getValues("tag_list");

  return (
    <>
      <CustomizedBox margin="0 0 2rem 0">
        <Grid container spacing={2} alignItems="center" mb={2}>
          <Grid item xs={6} sm={4} md={2} pl={1}>
            <Typography fontWeight="bold">
              {t("purchase.vendor_description")}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item xs={2}>
              <Box>
                <CustomizedTooltip title={t("sentence.choose_vendor")}>
                  <IconButton
                    onClick={openContactTableHandler}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="vendor_contact.unique_id_name"
              error={Boolean(
                errors.vendor_contact && errors.vendor_contact.unique_id_name
              )}
              helperText={
                errors.vendor_contact &&
                errors.vendor_contact.unique_id_name?.message
              }
              label="รหัสผู้ขาย - ชื่อผู้ขาย"
              disabled
              required
              sx={{
                input: { visibility: disabled ? "hidden" : "visible" },
              }}
              InputProps={{
                startAdornment: disabled && (
                  <InputAdornment position="start">
                    <StyledLink onClick={openContactDetailHandler}>
                      {contact?.unique_id_name}
                    </StyledLink>
                  </InputAdornment>
                ),
                endAdornment: !disabled && contactUniqueId && (
                  <InputAdornment position="end">
                    <CustomizedTooltip title="กดเพื่อดูรายละเอียดผู้ขาย">
                      <IconButton
                        onClick={openContactDetailHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <LaunchOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="vendor_contact.identity_no"
              error={Boolean(
                errors.vendor_contact && errors.vendor_contact.identity_no
              )}
              helperText={
                errors.vendor_contact &&
                errors.vendor_contact.identity_no?.message
              }
              label="เลขประจำตัวผู้เสียภาษี"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="vendor_contact.branch"
            error={Boolean(
              errors.vendor_contact && errors.vendor_contact.branch
            )}
            helperText={
              errors.vendor_contact &&
              errors.vendor_contact.branch?.message
            }
            label="ประเภทสำนักงาน"
            disabled
          />
        </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="vendor_contact.phone"
              error={Boolean(
                errors.vendor_contact && errors.vendor_contact.phone
              )}
              helperText={
                errors.vendor_contact && errors.vendor_contact.phone?.message
              }
              label="เบอร์โทรศัพท์"
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="vendor_contact.email"
              error={Boolean(
                errors.vendor_contact && errors.vendor_contact.email
              )}
              helperText={
                errors.vendor_contact && errors.vendor_contact.email?.message
              }
              label={t("user.account.email")}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ControlledTextField
              control={control}
              name="vendor_contact.fax"
              error={Boolean(
                errors.vendor_contact && errors.vendor_contact.fax
              )}
              helperText={
                errors.vendor_contact && errors.vendor_contact.fax?.message
              }
              label="โทรสาร"
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={4} md={2} pl={1}>
            <Typography fontWeight="bold">
              {t("global.billing_address")}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item xs={2}>
              {contactUniqueId ? (
                <CustomizedTooltip title="แก้ไขที่อยู่จดทะเบียน">
                  <IconButton
                    onClick={openBillingAddressHandler}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </CustomizedTooltip>
              ) : null}
            </Grid>
          )}
        </Grid>
        <Typography variant="body1" sx={{ mr: 2, mt: 2, mb: 2 }}>
          {renderAddressString("billing_address")}
        </Typography>
        {documentType !== EntityTypeEnum.PurchaseOrder && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sm={4} md={2} pl={1}>
                <Typography fontWeight="bold">
                  {t("sentence.deliver_location")}
                </Typography>
              </Grid>
              {!disabled && (
                <Grid item xs={2}>
                  {contactUniqueId ? (
                    <CustomizedTooltip title="แก้ไขที่อยู่จัดส่ง">
                      <IconButton
                        onClick={openDeliveryAddressHandler}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </CustomizedTooltip>
                  ) : null}
                </Grid>
              )}
            </Grid>
            <Typography variant="body1" mt={2}>
              {renderAddressContact()}
            </Typography>
            <Typography variant="body1">
              {renderAddressString("delivery_address")}
            </Typography>
          </>
        )}
        <Typography fontWeight="bold" my={2}>
          Tag
        </Typography>
        {disabled ? (
          <>
            {tagList && tagList.length > 0
              ? tagList.map((tag) => (
                  <CustomizedChips
                    key={tag.name}
                    value={tag.name}
                    color="primary"
                  />
                ))
              : "-"}
          </>
        ) : (
          <Grid container spacing={2} alignItems="center">
            <PurchaseTagList
              control={control}
              disabled={disabled}
              entity={PurchaseEntityType.PurchaseOrder}
              setValue={setValue}
              getValues={getValues}
              name="tag_list"
            />
          </Grid>
        )}
        <CheckedContactModal
          showSelectContact={openContactTable}
          closeContactTable={closeContactTableHandler}
          contactIds={contactIds}
          setContactIds={setContactIds}
          contactIdsSnapshot={contactIdsSnapshot}
          setContactIdsSnapshot={setContactIdsSnapshot}
          finishContactsSelect={finishContactsSelect}
          type="vendor"
        />
        <BillingAddressForm
          control={control}
          errors={errors}
          open={openBillingAddress}
          handleClose={closeBillingAddressHandler}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          reset={reset}
          billingAddressSnapshot={billingAddressSnapshot}
          setBillingAddressSnapshot={setBillingAddressSnapshot}
        />
        <DeliveryAddressForm
          control={control}
          errors={errors}
          open={openDeliveryAddress}
          handleClose={closeDeliveryAddressHandler}
          getValues={getValues}
          setValue={setValue}
          disabled={disabled}
          reset={reset}
          deliveryAddressSnapshot={deliveryAddressSnapshot}
          setDeliveryAddressSnapshot={setDeliveryAddressSnapshot}
          allAddressList={allAddressList}
        />
        <ModalUI
          open={openContactDetail}
          handleClose={closeContactDetailHandler}
          maxWidth="lg"
        >
          <ContactContainer
            isContactPage={false}
            contactUniqueId={contactUniqueId}
          />
        </ModalUI>
      </CustomizedBox>
      {documentType === EntityTypeEnum.PurchaseOrder && (
        <CustomizedBox>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={4} md={2} pl={1}>
              <Typography fontWeight="bold">
                {t("sentence.deliver_location") + "ผู้รับ"}
              </Typography>
            </Grid>
            {!disabled && (
              <Grid item xs={2}>
                {contactUniqueId ? (
                  <CustomizedTooltip title="แก้ไขที่อยู่จัดส่ง">
                    <IconButton
                      onClick={openDeliveryAddressHandler}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </CustomizedTooltip>
                ) : null}
              </Grid>
            )}
          </Grid>
          <Typography variant="body1" mt={2}>
            {renderAddressContact()}
          </Typography>
          <Typography variant="body1">
            {renderAddressString("delivery_address")}
          </Typography>
        </CustomizedBox>
      )}
    </>
  );
};

export default VendorInfoForm;
