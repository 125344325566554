import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { Typography } from "@mui/material";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";

interface Props {
  control: IDefaultForm<IGoodsAdjustment>["control"];
  nestedIndex: number;
  index: number;
}

const NewSerialStockQuantityCell = ({ control, nestedIndex, index }: Props) => {
  const watchSerial =
    useWatch({
      control,
      name: `trace_entry_list[${nestedIndex}].serial_list[${index}]` as `trace_entry_list.0`,
    }) || [];

  return (
    <Typography fontSize={14}>
      {(watchSerial.stock_qty ? watchSerial.stock_qty : 0) +
        (typeof watchSerial.qty === "string"
          ? watchSerial.qty
            ? parseInt(watchSerial.qty)
            : 0
          : watchSerial.qty)}
    </Typography>
  );
};

export default NewSerialStockQuantityCell;
