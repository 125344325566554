import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import DocumentInfoTab from "./DocumentInfoTab";
import RelatedDocumentTab from "./RelatedDocumentTab";

const ProjectContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("project.index"),
      to: "/project",
    },
    {
      name: t("project.index"),
      to: "/project/projects",
    },
    {
      name: id ?? `${t("project.add")}`,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("project.document_info"),
      path: `${pathname}`,
    },
    {
      label: t("project.sales"),
      path: `${pathname}?tab=sales`,
      disabled: !id,
    },
    {
      label: t("project.purchase"),
      path: `${pathname}?tab=purchase`,
      disabled: !id,
    },
    {
      label: t("project.delivery"),
      path: `${pathname}?tab=logistic`,
      disabled: !id,
    },
    {
      label: t("project.manufacture"),
      path: `${pathname}?tab=manufacture`,
      disabled: !id,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "sales":
        setCurrentTab(pathname + "?tab=sales");
        break;
      case "purchase":
        setCurrentTab(pathname + "?tab=purchase");
        break;
      case "logistic":
        setCurrentTab(pathname + "?tab=logistic");
        break;
      case "manufacture":
        setCurrentTab(pathname + "?tab=manufacture");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "sales":
        return <RelatedDocumentTab tab={tab} />;
      case "purchase":
        return <RelatedDocumentTab tab={tab} />;
      case "logistic":
        return <RelatedDocumentTab tab={tab} />;
      case "manufacture":
        return <RelatedDocumentTab tab={tab} />;
      default:
        return <DocumentInfoTab />;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider />
      {renderTab(tab)}
    </>
  );
};

export default ProjectContainer;
