import { TableCell, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { IGoodsReceive } from "../../../../types/Inventory/goodsReceive";
import { ITraceEntry } from "../../../../types/Inventory";

interface Props {
  control: IDefaultForm<IGoodsReceive>["control"];
  serial: ITraceEntry;
}
const SerialNumberCell = ({ control, serial }: Props) => {
  const watchUniqueId = useWatch({
    control,
    name: "unique_id",
  });
  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "source_warehouse_unique_id",
  });
  return (
    <TableCell align="center">
      <Typography
        fontSize={14}
      >{`${watchSourceWarehouseUniqueId}#${watchUniqueId}#${serial.serial_no}`}</Typography>
    </TableCell>
  );
};

export default SerialNumberCell;
