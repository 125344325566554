import { Box, Typography } from "@mui/material";

interface Props {
  rightHeader: string[];
  rightInfo: string[];
}

const PDFRightInfo = ({ rightHeader, rightInfo }: Props) => {
  return (
    <Box px={1}>
      {rightHeader.map((name, index) => (
        <Box sx={{ display: "flex", gap: 2 }} key={index}>
          <Typography
            sx={{
              fontSize: 11,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontWeight: 600,
              color: "#333333",
              flex: 0.55,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: 11,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              flex: 1,
              width: "100%",
              wordBreak: "break-word",
            }}
          >
            {rightInfo[index]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PDFRightInfo;
