import { useEffect, useState } from "react";
import { IDefaultForm, IHeaderTable } from "../../types/global";
import { useWatch } from "react-hook-form";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา (ต้นทาง)",
    width: 130,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่ต้นทาง",
    width: 200,
  },
  {
    thaiLabel: "ผู้สแกนต้นทาง",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

const defaultScanHeader: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา (ต้นทาง)",
    width: 130,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 100,
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Barcode/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่ต้นทาง",
    width: 200,
  },
  {
    thaiLabel: "ผู้สแกนต้นทาง",
    width: 100,
  },
  {
    thaiLabel: "สถานที่ปลายทาง",
    width: 200,
  },
  {
    thaiLabel: "",
    width: 75,
  },
  {
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "ผู้สแกนปลายทาง",
    width: 100,
  },
  {
    thaiLabel: "วันที่สแกน (ปลายทาง)",
    width: 130,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

export const useGoodsTransferItemList = (
  control: IDefaultForm["control"],
  disabled: boolean
) => {
  const status = useWatch({
    control,
    name: "sub_status",
  });

  const flag = useWatch({
    control,
    name: "flag_status",
  });

  const [headers, setHeaders] = useState<IHeaderTable[]>([]);
  useEffect(() => {
    if (!disabled) {
      if (!status || status === "draft") {
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        setHeaders([
          ...defaultScanHeader,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      }
    } else {
      if (!status || status === "draft") {
        setHeaders(defaultHeaders);
      } else {
        if (status === "finished" || flag.includes("cancelled")) {
          const newHeader = defaultScanHeader.filter(
            (_, index) => index !== 11
          );
          setHeaders(newHeader);
        } else {
          setHeaders(defaultScanHeader);
        }
      }
    }
  }, [disabled, flag, status]);

  return headers;
};
