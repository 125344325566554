import { useEffect, useState } from "react";
import { IHeaderTable } from "../../types/global";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "รหัสคลัง",
  },
  {
    thaiLabel: "คลัง",
  },
  {
    thaiLabel: "จำนวนคงคลัง",
  },
];

const binLocationHeaders: IHeaderTable[] = [
  {
    thaiLabel: "รหัสคลัง",
  },
  {
    thaiLabel: "คลัง",
  },
  {
    thaiLabel: "สถานที่",
  },
  {
    thaiLabel: "จำนวนคงคลัง",
  },
];

const serialNumberHeaders: IHeaderTable[] = [
  {
    thaiLabel: "รหัสคลัง",
    width: 100,
  },
  {
    thaiLabel: "คลัง",
  },
  {
    thaiLabel: "Batch/SN",
  },
  {
    thaiLabel: "สถานที่",
    width: 170,
  },
  {
    thaiLabel: "จำนวนคงคลัง",
    width: 100,
  },
  // {
  //   thaiLabel: "Lot",
  //   width: 100,
  // },
  {
    thaiLabel: "Lot",
  },
  {
    thaiLabel: "Barcode",
  },
];

const quantityHeaders: IHeaderTable[] = [
  {
    thaiLabel: "เลขที่เอกสาร",
    width: 120,
  },
  {
    thaiLabel: "คลังสินค้า",
    width: 120,
  },
  {
    thaiLabel: "รหัสสินค้า",
    width: 200,
  },
  {
    thaiLabel: "ชื่อสินค้า",
    width: 150,
  },
  {
    thaiLabel: "จำนวน",
    width: 120,
  },
  {
    thaiLabel: "หน่วย",
    width: 120,
  },
];

export const useItemCurrentStockItemList = (type: string) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    switch (type) {
      case "default":
      case "stock_qry":
        setHeaders(defaultHeaders);
        break;
      case "bin_location":
        setHeaders(binLocationHeaders);
        break;
      case "serial_number":
        setHeaders(serialNumberHeaders);
        break;
      case "purchase_ordered_qty":
        setHeaders([
          ...quantityHeaders,
          {
            thaiLabel: "วันประมาณการณ์สินค้าเข้า",
            width: 200,
          },
        ]);
        break;
      case "manufacture_ordered_qty":
        setHeaders([
          ...quantityHeaders,
          {
            thaiLabel: "วันกำหนดผลิตเสร็จ",
            width: 200,
          },
        ]);
        break;
      case "sale_committed_qty":
        setHeaders([
          ...quantityHeaders,
          {
            thaiLabel: "วันกำหนดส่งของ",
            width: 200,
          },
        ]);
        break;
      case "manufacture_committed_qty":
        setHeaders([
          ...quantityHeaders,
          {
            thaiLabel: "วันกำหนดผลิต",
            width: 200,
          },
        ]);
        break;
      default:
        break;
    }
  }, [type]);

  return headers;
};
