import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CurrentStockBySNTable from "../../../components/Table/Inventory/Reports/CurrentStockBySNTable";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import {
  ItemSkuQtysQuery,
  useItemSkuQtysQuery,
} from "../../../generated/inventory";
import TotalBox from "../../../components/UI/TotalBox";
import { useCallback, useRef, useState } from "react";
import { exportCSVParams } from "../../../utils/Formatter/AgGridFilter";
import { useSnackbar } from "notistack";
import { ISkuQty } from "../../../types/Inventory/item";
import { AgGridReact } from "ag-grid-react";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const CurrentStockBySN = () => {
  const [rowCount, setRowCount] = useState<number>(0);
  const gridRef = useRef<AgGridReact<ISkuQty>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index"),
    },
  ];

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isLoading } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem
  );

  const onBtnExport = useCallback(async () => {
    const params = exportCSVParams(
      t("inventory.itemCurrentStockSerialNumber.index")
    );
    gridRef?.current?.api?.exportDataAsCsv(params);
    enqueueSnackbar(
      `นำออก${t("inventory.itemCurrentStockSerialNumber.index")}สำเร็จ`,
      {
        variant: "success",
      }
    );
  }, [enqueueSnackbar, t]);

  const clearAllFilter = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <TotalBox title="ยอดรวม" value={rowCount} borderColor="#4830DE" />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" my={3}>
          {t("inventory.itemCurrentStockSerialNumber.index")}
        </Typography>
        <Box display="flex" gap={1}>
          <CustomizedButton
            title={`นำออกรายงาน`}
            onClick={onBtnExport}
            variant="contained"
          />
          <CustomizedButton
            title={`ล้างตัวกรอง`}
            variant="outlined"
            onClick={clearAllFilter}
            sx={{ height: "100%" }}
          />
        </Box>
      </Box>
      <CurrentStockBySNTable
        gridRef={gridRef}
        data={data}
        setRowCount={setRowCount}
      />
    </>
  );
};

export default CurrentStockBySN;
