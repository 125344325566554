import { GraphQLClient } from "graphql-request";
import { Cookies } from "react-cookie";
import * as Sentry from "@sentry/react";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;

// Define endpoint URLs for different services
const endpoints: Record<string, string> = {
  "company-user": "company-user",
  contact: "contact",
  item: "item",
  sales: "sales",
  purchase: "purchase",
  logistic: "logistic",
  general: "general",
  crm: "crm",
  manufacture: "manufacture",
};

// Define the types for query and variables
type GraphQLQuery = string;
type GraphQLVariables = Record<string, any>;

// Create a custom GraphQLClient with middleware
class GraphQLClientWithMiddleware extends GraphQLClient {
  constructor(endpoint: string) {
    super(endpoint, {
      credentials: endpoint.includes("https://dev-project-erp-api.npr.digital")
        ? "omit"
        : "include",
      mode: "cors",
    });
  }

  async requestWithMiddleware(
    query: GraphQLQuery,
    variables: GraphQLVariables
  ): Promise<any> {
    try {
      const data = await super.request(query, variables);
      Sentry.addBreadcrumb({
        category: "graphql-query",
        message: "GraphQL Query",
        data: {
          query,
          variables,
        },
      });
      Sentry.addBreadcrumb({
        category: "graphql-response",
        message: "GraphQL Response",
        data: {
          response: data,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

// Modified createGraphQLClient function with middleware
export const createGraphQLClientWithMiddleware = (
  service: string,
  includeAuthorizationHeader: boolean = true
): GraphQLClientWithMiddleware => {
  let baseEndpoint =
    service === "crm"
      ? "https://dev-project-erp-api.npr.digital"
      : ENVIRONMENT === "development"
      ? "https://erp-dev-api.npr.digital"
      : "https://erp-api.npr.digital";

  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const tenant_id = sessionStorage.getItem("tenant-id");

  const endpoint =
    service === "crm"
      ? `${baseEndpoint}/graphql`
      : `${baseEndpoint}/${endpoints[service]}/graphql`;

  const client = new GraphQLClientWithMiddleware(endpoint);

  if (includeAuthorizationHeader) {
    client.setHeader("authorization", `Bearer ${access_token}`);
  }

  client.setHeader("tenant-id", `${tenant_id}`);

  return client;
};

// Export GraphQL clients for each service

// export const graphQLClientWithHeaderCompany: GraphQLClient =
//   createGraphQLClientWithMiddleware("company-user");
//  const graphQLClientWithHeaderContact: GraphQLClient =
//   createGraphQLClientWithMiddleware("contact");

// export const graphQLClientWithHeaderSales: GraphQLClient =
//   createGraphQLClientWithMiddleware("sales");
// export const graphQLClientWithHeaderPurchase: GraphQLClient =
//   createGraphQLClientWithMiddleware("purchase");
// export const graphQLClientWithHeaderLogistic: GraphQLClient =
//   createGraphQLClientWithMiddleware("logistic");
// const graphQLClientWithHeaderSetting: GraphQLClient =
//   createGraphQLClientWithMiddleware("general");
// export const graphQLClientWithHeaderProject: GraphQLClient =
//   createGraphQLClientWithMiddleware("crm");
// export const graphQLClientWithHeaderManufacture: GraphQLClient =
//   createGraphQLClientWithMiddleware("manufacture");
