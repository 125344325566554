import GoodsAdjustmentItemList from "../../../components/Table/Inventory/GoodsAdjustment/GoodsAdjustmentItemList";
import { IDefaultForm } from "../../../types/global";

type Props = IDefaultForm & {
  renderButton: () => JSX.Element | null;
};

const BarcodeTab = ({
  control,
  getValues,
  setValue,
  disabled,
  errors,
  renderButton,
}: Props) => {
  return (
    <>
      <GoodsAdjustmentItemList
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
      />
      {renderButton()}
    </>
  );
};

export default BarcodeTab;
