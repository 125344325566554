import dayjs from "dayjs";
import * as Yup from "yup";

export const eventSchema = {
  name: "",
  desc: "",
  remark: "",
  related_user_list: [],
  main_status: "",
  sub_status: "",
  flag_status: [],
  project_name: "",
  task_status: "",
  contact_name: "",
  contact_person_name: "",
  planning_started_date: dayjs().toDate(),
  planning_ended_date: dayjs().add(1, "h").toDate(),
  appointment_started_date: null,
  appointment_ended_date: null,
  check_in_start_date: null,
  check_in_end_date: null,
  created_date: dayjs().toDate(),
  updated_date: dayjs().toDate(),
  created_by: null,
  attachment_list: [],
  location_name: "",
  location_link: "",
  start_check_in_point: "",
  destination_check_point: "",
  distance: null,
  period: null,
};

export const eventValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุชื่อเหตุการณ์"),
  main_status: Yup.string().required("กรุณาระบุสถานะ"),
  // planning_started_date: Yup.date()
  //   .required("กรุณาระบุวันที่เริ่มวางแผน")
  //   .typeError("กรุณาระบุวันที่เริ่มวางแผน")
  //   .nullable(),
  // planning_ended_date: Yup.date()
  //   .required("กรุณาระบุวันที่สิ้นสุดวางแผน")
  //   .typeError("กรุณาระบุวันที่เริ่มวางแผน")
  //   .nullable(),
  // appointment_started_date: Yup.date()
  //   .required("กรุณาระบุวันที่เริ่มนัดหมาย")
  //   .typeError("กรุณาระบุวันที่เริ่มวางแผน")
  //   .nullable(),
  // appointment_ended_date: Yup.date()
  //   .required("กรุณาระบุวันที่สิ้นสุดนัดหมาย")
  //   .typeError("กรุณาระบุวันที่เริ่มวางแผน")
  //   .nullable(),
});
