import { LineOASettingGroupField } from "../../types/Liff/lineOASetting";

const LINE_OA_SETTING_GROUPS: LineOASettingGroupField[] = [
  {
    name: "sales_setting",
    display_name: "การขาย",
    settings: [
      {
        name: "QT_to_wait_approve",
        display_name: 'ใบเสนอราคา เปลี่ยนสถานะเป็น "รออนุมัติ"',
      },
      {
        name: "QT_to_approved_or_not_approved",
        display_name: 'ใบเสนอราคา เปลี่ยนสถานะเป็น "อนุมัติ" หรือ "ไม่อนุมัติ"',
      },
      {
        name: "SO_to_wait_approve",
        display_name: 'ใบสั่งขาย เปลี่ยนสถานะเป็น "รออนุมัติ"',
      },
      {
        name: "SO_to_approved_or_not_approved",
        display_name: 'ใบสั่งขาย เปลี่ยนสถานะเป็น "อนุมัติ" หรือ "ไม่อนุมัติ"',
      },
      {
        name: "SR_to_wait_approve",
        display_name: 'ใบรับคืน เปลี่ยนสถานะเป็น "รออนุมัติ"',
      },
      {
        name: "SR_to_approved_or_not_approved",
        display_name: 'ใบรับคืน เปลี่ยนสถานะเป็น "อนุมัติ" หรือ "ไม่อนุมัติ"',
      },
    ],
  },
  {
    name: "inventory_setting",
    display_name: "คลัง",
    settings: [
      {
        name: "GA_to_wait_approve",
        display_name: 'ใบปรับปรุง เปลี่ยนสถานะเป็น "รออนุมัติ"',
      },
      {
        name: "GA_to_approve_or_not_approve",
        display_name: 'ใบปรับปรุง เปลี่ยนสถานะเป็น "อนุมัติ" หรือ "ไม่อนุมัติ"',
      },
      {
        name: "ITEM_from_active_to_in_active",
        display_name: 'สินค้า เปลี่ยนสถานะจาก "ใช้งาน" หรือ "หยุดใช้งาน"',
      },
    ],
  },
];

export const useLineOASettingGroups = () => {
  return LINE_OA_SETTING_GROUPS;
};
