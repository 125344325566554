import { useTranslation } from "react-i18next";
import ModalUI from "../../UI/ModalUI";
import { useForm } from "react-hook-form";
import { IItemGroup } from "../../../types/Inventory/item";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import { useEffect } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import {
  ItemGroupLevel2Query,
  useItemGroupLevel2CreateMutation,
  useItemGroupLevel2Query,
  useItemGroupLevel2UpdateMutation,
} from "../../../generated/inventory";
import { categorySchema, categoryValidation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

interface Props {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ItemGroupLevel2Query, unknown>>;
}

const LevelTwoModal = ({
  open,
  closeModalHandler,
  currentId,
  refetch,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isSuccess } = useItemGroupLevel2Query<ItemGroupLevel2Query>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        id: currentId,
      },
    },
    {
      enabled: Boolean(currentId),
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IItemGroup>({
    defaultValues: categorySchema,
    resolver: yupResolver(categoryValidation),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  const { mutate: createItemGroupLevel2 } =
    useItemGroupLevel2CreateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: () => {
        enqueueSnackbar(
          `เพิ่ม${t("setting.inventory.category.level_two")} สำเร็จ`,
          {
            variant: "success",
          }
        );
        refetch();
        onClose();
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(
          errorMessage ||
            `เพิ่ม${t("setting.inventory.category.level_two")} ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    });

  const { mutate: updateItemGroupLevel2 } =
    useItemGroupLevel2UpdateMutation<Error>(graphQLClientWithHeaderItem, {
      onSuccess: ({ itemGroupLevel2Update }) => {
        if (itemGroupLevel2Update) {
          enqueueSnackbar(
            `แก้ไข${t("setting.inventory.category.level_two")} สำเร็จ`,
            {
              variant: "success",
            }
          );
          const formatLevel2 = {
            id: itemGroupLevel2Update.id,
            name: itemGroupLevel2Update.name,
            unique_id: itemGroupLevel2Update.unique_id,
          };
          reset(formatLevel2);
          refetch();
          onClose();
        }
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(
          errorMessage ||
            `แก้ไข${t("setting.inventory.category.level_two")} ไม่สำเร็จ`,
          {
            variant: "error",
          }
        );
      },
    });

  useEffect(() => {
    if (isSuccess) {
      const formatLevel2 = {
        id: data?.itemGroupLevel2?.id,
        name: data?.itemGroupLevel2?.name,
        unique_id: data?.itemGroupLevel2?.unique_id,
      };
      reset(formatLevel2);
    }
  }, [
    data?.itemGroupLevel2?.id,
    data?.itemGroupLevel2?.name,
    data?.itemGroupLevel2?.unique_id,
    isSuccess,
    reset,
  ]);

  const addItemGroupLevel1Handler = (data: IItemGroup) => {
    createItemGroupLevel2({
      createInput: {
        unique_id: data.name,
        name: data.name,
      },
    });
  };

  const updateItemGroupLevel2Handler = (data: IItemGroup) => {
    updateItemGroupLevel2({
      uniqueInput: {
        id: data.id,
      },
      updateInput: {
        unique_id: data.name,
        name: data.name,
      },
    });
  };

  return (
    <ModalUI
      title={
        (currentId ? t("sentence.edit") : t("button.add")) +
        t("setting.inventory.category.level_two")
      }
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(updateItemGroupLevel2Handler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(addItemGroupLevel1Handler)}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ControlledTextField
            label={"ชื่อ" + t("setting.inventory.category.level_two")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message}
            required
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default LevelTwoModal;
