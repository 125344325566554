import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";

const Manufacture = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.bom.index"),
      to: "/manufacture/bom",
      icon: <PrecisionManufacturingOutlinedIcon color="primary" />,
    },
    {
      name: t("manufacture.routing.index"),
      to: "/manufacture/routing",
      icon: <FormatListNumberedRtlOutlinedIcon color="primary" />,
    },
    {
      name: t("manufacture.order.index"),
      to: "/manufacture/order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    // {
    //   name: t("manufacture.workOrder.index"),
    //   to: "/manufacture/work-order",
    //   icon: <DescriptionOutlinedIcon color="primary" />,
    // },
    {
      name: t("report"),
      to: "/manufacture/report",
      icon: <ContentPasteOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Manufacture;
