import { Cookies } from "react-cookie";
import React, { useEffect } from "react";
import { GraphQLClient, gql } from "graphql-request";
import { useStateContext } from "../contexts/auth-context";
import {
  ValidateAccesssQuery,
  useValidateAccesssQuery,
} from "../generated/company-user";
import { IUser } from "../types/Auth/user";
import { useNavigate } from "react-router";
import jwtDecode from "jwt-decode";
import { IToken } from "../types/Auth/token";
import { useLocation } from "react-router-dom";
import { useConfirmation } from "../hooks/use-confrimation";
import ExpiredConfirmation from "../components/UI/ExpiredConfirmation";
import * as Sentry from "@sentry/react";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { Box, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { useUserRole } from "../hooks/use-role-permission";

const cookies = new Cookies();

export const REFRESH_ACCESS_TOKEN = gql`
  mutation {
    refreshToken {
      access_token
    }
  }
`;

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { confirmation, closeConfirmationHandler, openConfirmationHandler } =
    useConfirmation();

  const { dispatch } = useStateContext();

  // const [cookies, setCookie, removeCookie] = useCookies([
  //   "access_token",
  //   "refresh_token",
  //   "logged_in",
  // ]);

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "company-user",
    false
  );

  const { refetch, isLoading, isFetching, isError } = useValidateAccesssQuery<
    ValidateAccesssQuery,
    Error
  >(
    graphQLClientWithHeaderCompany,
    {},
    {
      retry: 1,
      enabled: cookies.get("logged_in"),
      staleTime: 3600 * 1000,
      onSuccess: (data) => {
        const tenant_name =
          data.validateAccesssTokenWithHeader?.company_list?.find(
            (company) =>
              company?.unique_id ===
              data.validateAccesssTokenWithHeader.last_login_tenant
          )?.name;
        //TODO: set tenant id
        dispatch({
          type: "SET_USER",
          payload: data.validateAccesssTokenWithHeader as IUser,
        });

        Sentry.setUser({
          id: data.validateAccesssTokenWithHeader.unique_id,
          email: data.validateAccesssTokenWithHeader.email,
        });
        const tenant_id = sessionStorage.getItem("tenant-id");
        const company_info = sessionStorage.getItem("company-info");
        const logged_in = cookies.get("logged_in");
        const selected_tenant = cookies.get("selected_tenant");

        if (logged_in) {
          if (selected_tenant) {
            if (!tenant_id) {
              sessionStorage.setItem(
                "tenant-id",
                data.validateAccesssTokenWithHeader.last_login_tenant || ""
              );
              cookies.set("selected_tenant", "true", { path: "/" });
            }
          } else {
            cookies.set("selected_tenant", "false", { path: "/" });
            navigate("/select_company", {
              replace: true,
            });
          }
        } else {
          cookies.set("selected_tenant", "false", { path: "/" });
          navigate("/login", {
            replace: true,
          });
        }
        const found_company =
          data.validateAccesssTokenWithHeader?.company_list?.find(
            (company) =>
              company?.unique_id ===
              data.validateAccesssTokenWithHeader.last_login_tenant
          );
        const is_expire = dayjs(found_company?.expired_date).isBefore(dayjs());
        const format_company = {
          is_expired: is_expire,
          expired_date: found_company?.expired_date,
          subscription_level_id: found_company?.subscription_level_id,
          resubscription_date: found_company?.resubscription_date,
          register_date: found_company?.register_date,
        };
        if (!company_info) {
          sessionStorage.setItem(
            "company-info",
            JSON.stringify(format_company) || ""
          );
        }
        Sentry.setTag(
          "tenant_id",
          `${data.validateAccesssTokenWithHeader.last_login_tenant}`
        );
        Sentry.setTag("tenant_name", `${tenant_name}`);
      },
      async onError(error: any) {
        const refresh_token = cookies.get("refresh_token");
        if (refresh_token) {
          for (const err of error.response.errors) {
            if (err.message.includes("Unauthorized")) {
              try {
                const { refreshToken } = await graphQLClient.request(
                  REFRESH_ACCESS_TOKEN
                );
                const { access_token } = refreshToken;
                const { exp: accessMaxAge } = jwtDecode<IToken>(access_token);
                cookies.set("access_token", access_token, {
                  path: "/",
                  maxAge: accessMaxAge,
                });
                await refetch();
              } catch (refreshError) {
                console.log("error here");
                cookies.remove("access_token");
                cookies.remove("refresh_token");
                cookies.set("logged_in", "false", { path: "/" });
                cookies.set("selected_tenant", "false", { path: "/" });
                sessionStorage.removeItem("tenant-id");
                sessionStorage.removeItem("company-info");
                navigate("/login");
              }
            }
          }
        } else {
          if (location.pathname !== "/login") {
            openConfirmationHandler();
          } else {
            logoutHandler();
          }
        }
      },
    }
  );

  useEffect(() => {
    if (!cookies.get("access_token") && cookies.get("logged_in")) {
      refetch();
    }
  }, [refetch, location]);

  const isUserRoleLoading = useUserRole();

  const loading =
    isUserRoleLoading ||
    isLoading ||
    isFetching ||
    (isError && cookies.get("refresh_token"));

  const logoutHandler = () => {
    cookies.remove("access_token", {
      path: "/",
    });
    cookies.remove("refresh_token", {
      path: "/",
    });
    cookies.set("logged_in", "false", {
      path: "/",
    });
    cookies.set("selected_tenant", "false", {
      path: "/",
    });
    sessionStorage.removeItem("tenant-id");
    sessionStorage.removeItem("company-info");
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    closeConfirmationHandler();
    navigate("/login", { replace: true });
  };

  if (!confirmation && loading && cookies.get("logged_in")) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {children}
      <ExpiredConfirmation open={confirmation} logout={logoutHandler} />
    </>
  );
};

export default AuthMiddleware;
