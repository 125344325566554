import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { IBreadcrumbsAndMenu } from "../../types/global";
import CustomizedMenuBox from "./CustomizedMenuBox";

interface Props {
  menuList: IBreadcrumbsAndMenu[];
}

const CustomizedMenuList = ({ menuList }: Props) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ my: 2 }}>
      <Grid container spacing={1}>
        {menuList?.map((menu: IBreadcrumbsAndMenu) => (
          <Grid item xs={6} sm={6} md={3} lg={3} xl={2} key={menu.name}>
            <CustomizedMenuBox
              title={menu.name}
              onClick={() => navigate(`${menu.to}`)}
              isgriditem="true"
              linkto={menu.to ?? "/"}
              icon={menu.icon}
              ismoblie={isMobile}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CustomizedMenuList;
