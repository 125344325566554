import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomizedBox } from "../../Custom/CustomizedBox";

interface IProps {
  onEdit?: any;
  onRemove?: any;
  index: number;
  img: any;
  imgAlt: string;
  title: string;
  title_name?: string;
  first_name: string;
  last_name: string;
  nickname?: string;
  subcontent: string;
  disabled: boolean;
  isSalesList?: boolean;
}

const NewContactCard = ({
  onEdit,
  onRemove,
  index,
  img,
  imgAlt,
  title,
  title_name,
  first_name,
  last_name,
  nickname,
  subcontent,
  disabled,
  isSalesList,
}: IProps) => {
  const { t } = useTranslation();
  const [anchorPos, setAnchorPos] = useState(null);
  const showActions = Boolean(anchorPos);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  const editHandler = () => {
    setAnchorPos(null);
    onEdit(index);
  };

  return (
    <>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
      >
        {!isSalesList && (
          <MenuItem onClick={editHandler}>{disabled ? "ดู" : "แก้ไข"}</MenuItem>
        )}
        {!disabled && (
          <MenuItem onClick={() => onRemove(index)}>
            {t("contact.utility.delete")}
          </MenuItem>
        )}
      </Menu>
      <CustomizedBox sx={{ py: "1.5rem", px: "2rem" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar alt={imgAlt} src={img} />
            <Typography variant="body1" marginLeft={2}>
              {title}
            </Typography>
          </Box>
          {!isSalesList && (
            <IconButton
              onClick={openActionsHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          {isSalesList && !disabled && (
            <IconButton
              onClick={openActionsHandler}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={{ display: "flex", mt: 2, mb: subcontent ? 2 : 6.5 }}>
          {title_name && (
            <Typography
              variant="body2"
              sx={{ mr: 0.5, color: (theme) => theme.palette.grey[700] }}
            >
              {title_name}
            </Typography>
          )}
          {first_name && (
            <Typography
              variant="body2"
              sx={{ mr: 2, color: (theme) => theme.palette.grey[700] }}
            >
              {first_name}
            </Typography>
          )}
          {first_name && last_name && (
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.palette.grey[700] }}
            >
              {last_name}
            </Typography>
          )}
          {!first_name && nickname && (
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.palette.grey[700] }}
            >
              {nickname}
            </Typography>
          )}
        </Box>
        {subcontent && (
          <Typography
            variant="body2"
            sx={{ my: 2, color: (theme) => theme.palette.grey[700] }}
          >
            {subcontent}
          </Typography>
        )}
      </CustomizedBox>
    </>
  );
};

export default NewContactCard;
