import { CustomizedBox } from "../../Custom/CustomizedBox";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controller/ControlledTextField";
import { Control } from "react-hook-form";
import { IProject } from "../../../types/Project";

interface Props {
  control: Control<IProject, any>;
}

const CancelRemark = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">
            {t("project.project.cancel_remark")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ControlledTextField
            multiline
            minRows={4}
            maxRows={4}
            name={"cancel_remark"}
            control={control}
            disabled={true}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default CancelRemark;
