import {
  Button,
  ButtonProps,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  alpha,
  styled,
} from "@mui/material";
import { MouseEventHandler, ReactNode, useState } from "react";
import { IMenuOption } from "../../types/global";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 87,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Props {
  label: ReactNode;
  options: IMenuOption[];
  onSelect: MouseEventHandler;
  size?: ButtonProps["size"];
  fullWidth?: ButtonProps["fullWidth"];
  disabled?: ButtonProps["disabled"];
  sx?: ButtonProps["sx"];
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  isIcon?: boolean;
}

const CustomizedMenuOptions = ({
  label,
  options,
  onSelect,
  size,
  fullWidth,
  disabled,
  sx,
  variant,
  color,
  isIcon,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isIcon ? (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant={variant ?? "outlined"}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          size={size ? size : "small"}
          fullWidth={fullWidth}
          disabled={disabled}
          color={color ?? "primary"}
          sx={sx}
        >
          {label}
        </Button>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option: IMenuOption, index: number) => (
          <MenuItem
            onClick={(e) => {
              onSelect(e);
              handleClose();
            }}
            disabled={option.disabled}
            disableRipple
            key={index}
          >
            {option.value}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default CustomizedMenuOptions;
