import { Grid } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import Remark from "./Remark";
import { IDefaultForm } from "../../../types/global";
import SalesSummary from "./Summary";
import { UseFormResetField } from "react-hook-form";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: IDefaultForm["disabled"];
  setValue: IDefaultForm["setValue"];
  resetField?: UseFormResetField<any>;
  documentType: string;
}

const SalesFooter = ({
  control,
  errors,
  disabled,
  setValue,
  resetField,
  documentType,
}: Props) => {
  return (
    <CustomizedBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Remark
            control={control}
            errors={errors}
            disabled={disabled}
            setValue={setValue}
            documentType={documentType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <SalesSummary
            control={control}
            disabled={disabled}
            setValue={setValue}
            resetField={resetField}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default SalesFooter;
