import { Fragment, useState } from "react";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import {
  Box,
  CircularProgress,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../components/Controller/ControlledTextField";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ModalUI from "../../../components/UI/ModalUI";
import {
  WorkflowTemplatesQuery,
  useWorkflowTemplateCreateMutation,
  useWorkflowTemplateDeleteMutation,
  useWorkflowTemplatesQuery,
  useUpdateWorkflowTemplateMutation,
  ReferenceDocumentType,
} from "../../../generated/project";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { enqueueSnackbar } from "notistack";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IProjectWorkflowTemplate } from "../../../types/Project";

const defaultMainStatus = [
  { name: "ก่อนการขาย", var_name: "pre_sales" },
  { name: "ระหว่างการขาย", var_name: "post_sales" },
  { name: "หลังการขาย", var_name: "support" },
  { name: "เสร็จสิ้น", var_name: "finished" },
];

const validation = Yup.object().shape({
  value: Yup.string().required("กรุณากรอก"),
});

interface IDefault {
  value: string;
  main_status: string;
  unique_id: string;
}

const ProjectStatusSetting = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [editStatus, setEditStatus] = useState<IProjectWorkflowTemplate>();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.project.status"),
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      main_status: "",
      value: "",
      unique_id: "",
    },
    resolver: yupResolver(validation),
  });

  const graphQLClientWithHeaderProject: GraphQLClient =
    createGraphQLClientWithMiddleware("crm");

  const { data, refetch, isLoading } =
    useWorkflowTemplatesQuery<WorkflowTemplatesQuery>(
      graphQLClientWithHeaderProject
    );

  const {
    isLoading: isCreatingWorkflowTemplate,
    // mutate: createWorkflowTemplate,
  } = useWorkflowTemplateCreateMutation<Error>(graphQLClientWithHeaderProject, {
    onSuccess: ({ createWorkflowTemplate }) => {
      if (createWorkflowTemplate) {
        enqueueSnackbar("สร้างสถานะโครงการสำเร็จ", {
          variant: "success",
        });
        handleClose();
      }
    },
    onError: (err) => {
      enqueueSnackbar("สร้างโครงการไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const {
    isLoading: isUpdatingWorkflowTemplate,
    // mutate: updateWorkflowTemplate,
  } = useUpdateWorkflowTemplateMutation<Error>(graphQLClientWithHeaderProject, {
    onSuccess: ({ updateWorkflowTemplate }) => {
      enqueueSnackbar("แก้ไขสถานะโครงการสำเร็จ", {
        variant: "success",
      });

      handleClose();
      refetch();
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("แก้ไขโครงการไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const {
    // mutate: deleteWorkflowTemplate,
    isLoading: isDeletingWorkflowTemplate,
  } = useWorkflowTemplateDeleteMutation<Error>(graphQLClientWithHeaderProject, {
    onSuccess: ({ removeWorkflowTemplate }) => {
      enqueueSnackbar("ลบสถานะโครงการสำเร็จ", {
        variant: "success",
      });

      handleClose();
      refetch();
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar("ลบสถานะโครงการไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const onSubmit = (data: IDefault) => {
    console.log(title);
    console.log(data);
    switch (title) {
      case "addSubStatus":
        handleSubmitCreate(data);
        break;
      case "editSubStatus":
        handleSubmitUpdate(data);
        break;
      case "deleteSubStatus":
        handleSubmitDelete(data);
        break;
      default:
        break;
    }
  };

  const handleSubmitCreate = async (data: IDefault) => {
    const createPayload = {
      reference_document_type: ReferenceDocumentType.Project,
      unique_id: `${data.value}_${data.main_status}_project`, //wait for changes
      sub_status: data.value,
      status_name: data.main_status,
    };
    console.log(createPayload);
    // createWorkflowTemplate({ data: createPayload });
  };

  const handleSubmitUpdate = async (data: IDefault) => {
    // const uniqueId = data.unique_id;
    const { approval_template, id, ...otherData } = editStatus!;

    let formattedOtherData: any = {};
    for (const [key, value] of Object.entries(otherData)) {
      formattedOtherData[key] = { set: value };
    }

    const updatePayload = {
      ...formattedOtherData,
      approval_template: approval_template ?? undefined,
      sub_status: { set: data.value },
    };
    console.log(updatePayload);
    // updateWorkflowTemplate({
    //   where: { unique_id: uniqueId },
    //   data: updatePayload,
    // });
  };

  const handleSubmitDelete = async (data: IDefault) => {
    // deleteWorkflowTemplate({ where: { unique_id: data.unique_id } });
  };

  const handleClose = async () => {
    setModal(false);
    reset();
    // refetch();
    setTimeout(() => {
      setTitle("");
    }, 150);
  };

  const resetForm = () => {
    handleClose();
    reset();
  };

  const handleOpen = async ({
    title,
    mainStatus,
    uniqueId,
    value,
  }: {
    title: string;
    mainStatus: string;
    uniqueId?: string;
    value?: string;
  }) => {
    setValue("main_status", mainStatus);

    if (title !== "addSubStatus") {
      setValue("unique_id", uniqueId ?? "");
      setValue("value", value!);
    }

    setTitle(title);
    setModal(true);
  };

  const handleToggle = (event: any, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    const allId: string[] = [];
    defaultMainStatus.forEach((mainStatus, index) => {
      allId.push(index.toString());
    });
    data?.workflowTemplates.forEach((status) => {
      allId.push(status.unique_id);
    });

    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allId : []));
  };

  const buttonSx = { padding: "2px 10px", height: "fit-content" };

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" my={2}>
        {t("setting.project.status")}
      </Typography>
      {!isLoading ? (
        <CustomizedBox>
          <Box
            onClick={handleExpandClick}
            sx={{
              cursor: "pointer",
              width: "fit-content",
              marginBottom: "40px",
            }}
            display={"flex"}
          >
            {expanded.length === 0 ? (
              <KeyboardArrowRightIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
            <Typography>
              {expanded.length === 0 ? "เปิดทั้งหมด" : "ปิดทั้งหมด"}
            </Typography>
          </Box>
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleToggle}
            sx={{ flexGrow: 1 }}
          >
            {defaultMainStatus.map((mainStatus, index) => {
              return (
                <TreeItem
                  key={index}
                  nodeId={index.toString()}
                  label={mainStatus.name}
                >
                  <ToggleButtonGroup aria-label="text alignment">
                    <ToggleButton
                      sx={buttonSx}
                      value="left"
                      aria-label="left aligned"
                      onClick={() =>
                        handleOpen({
                          title: "addSubStatus",
                          mainStatus: mainStatus.var_name,
                        })
                      }
                    >
                      เพิ่ม
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {data?.workflowTemplates
                    .filter(
                      (status) => status.status_name === mainStatus.var_name
                    )
                    .map((status) => (
                      <TreeItem
                        key={`${status.unique_id}`}
                        nodeId={`${status.unique_id}`}
                        label={
                          status.step_number ? (
                            <>
                              <>{status.sub_status}</>
                              <label
                                style={{
                                  fontSize: "0.9rem",
                                  marginLeft: "0.5rem",
                                  color: "rgba(159, 159, 159, 1)",
                                }}
                              >
                                (ค่าเริ่มต้น)
                              </label>
                            </>
                          ) : (
                            status.sub_status
                          )
                        }
                      >
                        <ToggleButtonGroup aria-label="text alignment">
                          <ToggleButton
                            sx={buttonSx}
                            value="center"
                            aria-label="centered"
                            onClick={() => {
                              handleOpen({
                                title: "editSubStatus",
                                mainStatus: mainStatus.var_name,
                                uniqueId: status.unique_id,
                                value: status.sub_status ?? "",
                              });
                              setEditStatus(status);
                            }}
                          >
                            เปลี่ยนชื่อ
                          </ToggleButton>
                          {!status.step_number && (
                            <ToggleButton
                              sx={buttonSx}
                              value="right"
                              aria-label="right aligned"
                              onClick={() =>
                                handleOpen({
                                  title: "deleteSubStatus",
                                  mainStatus: mainStatus.var_name,
                                  uniqueId: status.unique_id,
                                  value: status.sub_status ?? "",
                                })
                              }
                            >
                              ลบ
                            </ToggleButton>
                          )}
                        </ToggleButtonGroup>
                      </TreeItem>
                    ))}
                </TreeItem>
              );
            })}
          </TreeView>
        </CustomizedBox>
      ) : (
        <Box
          sx={{
            height: "calc(100vh - 300px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ModalUI
        open={modal}
        handleClose={handleClose}
        title={t(`setting.project.${title}`) || ""}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {title !== "deleteSubStatus" ? (
            <Fragment>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <ControlledTextField
                    name={"main_status"}
                    control={control}
                    label={t("setting.project.chooseMainStatus")}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlledTextField
                    name={"value"}
                    control={control}
                    label={t("setting.project.subStatusName")}
                    error={Boolean(errors.value)}
                    helperText={errors.value?.message}
                    onChange={(e, field) => {
                      field.onChange(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <CustomizedButton
                  type="reset"
                  onClick={resetForm}
                  title={t("button.cancel")}
                  variant="outlined"
                  sx={{ mt: 2, mr: 2 }}
                />
                <CustomizedButton
                  type="submit"
                  title={t("button.save")}
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={
                    isUpdatingWorkflowTemplate || isCreatingWorkflowTemplate
                  }
                />
              </Box>
            </Fragment>
          ) : (
            <Box display="flex" justifyContent="flex-end">
              <CustomizedButton
                type="reset"
                onClick={resetForm}
                title={t("button.cancel")}
                variant="outlined"
                sx={{ mr: 2 }}
              />
              <CustomizedButton
                type="submit"
                title={t("button.save")}
                variant="contained"
                disabled={isDeletingWorkflowTemplate}
              />
            </Box>
          )}
        </form>
      </ModalUI>
    </Fragment>
  );
};

export default ProjectStatusSetting;
