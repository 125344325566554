import { useTranslation } from "react-i18next";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import DocumentInfoTab from "./DocumentInfoTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import SalesReturnTab from "./SalesReturnTab";
import GoodsIssueTab from "./GoodsIssueTab";
import DeliveryOrderTab from "./DeliveryOrderTab";
import ManufactureOrderTab from "./ManufactureOrderTab";
import PurchaseRequestTab from "./PurchaseRequestTab";

const SalesOrderContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.order.index"),
      to: "/sales/order",
    },
    {
      name: id ?? `สร้าง${t("sales.order.index")}`,
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("sales.document_info"),
      path: `${pathname}`,
    },
    {
      label: t("purchase.request.index"),
      path: `${pathname}?tab=purchase_request`,
      disabled: !id,
    },
    {
      label: t("manufacture.order.index"),
      path: `${pathname}?tab=manufacture_order`,
      disabled: !id,
    },
    {
      label: t("logistic.delivery_order.index"),
      path: `${pathname}?tab=delivery_order`,
      disabled: !id,
    },
    {
      label: "ใบ" + t("inventory.goods_issue.index"),
      path: `${pathname}?tab=goods_issue`,
      disabled: !id,
    },
    {
      label: t("sales.return.index"),
      path: `${pathname}?tab=return`,
      disabled: !id,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "purchase_request":
        return <PurchaseRequestTab />;
      case "manufacture_order":
        return <ManufactureOrderTab />;
      case "delivery_order":
        return <DeliveryOrderTab />;
      case "goods_issue":
        return <GoodsIssueTab />;
      case "return":
        return <SalesReturnTab />;
      default:
        return <DocumentInfoTab />;
    }
  };

  const currentTab = pathname + (tab ? `?tab=${tab}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedTab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
    </>
  );
};

export default SalesOrderContainer;
