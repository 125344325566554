import React from "react";
import { Box, Grid, IconButton, Theme, useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { GraphQLClient } from "graphql-request";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";

import ControlledTextField from "../Controller/ControlledTextField";

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import useBarcodeScanner from "../../hooks/Inventory/use-barcode-scanner";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import {
  ItemSkuDetailQuery,
  ItemSkuQtysQuery,
  useItemSkuDetailQuery,
  useItemSkuQtysQuery,
} from "../../generated/inventory";
import { IBarcodeForm } from "../../types/Inventory";
import { useSnackbar } from "notistack";

type Props = {
  pathname: string;
};

const ScanNavigate = ({ pathname }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
    setValue: setBarcodeValue,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      barcodeMobile: "",
    },
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const { refetch: refetchSkuDatail } =
    useItemSkuDetailQuery<ItemSkuDetailQuery>(
      graphQLClientWithHeaderItem,
      {
        uniqueInput: {
          sku_name:
            watchBarcode("barcodeMobile")?.trim() ||
            watchBarcode("barcode").trim(),
        },
      },
      {
        enabled: false,
      }
    );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data: skuQtyData } = await refetchSkuQty();
    const { data: skuDetailData } = await refetchSkuDatail();
    if (skuQtyData?.itemSkuQtys && skuQtyData.itemSkuQtys.length > 0) {
      navigate(
        `/${pathname}/${encodeURIComponent(
          skuQtyData.itemSkuQtys[0]?.sku_name || ""
        )}?tab=item&subtab=general`
      );
    } else if (skuDetailData?.itemSkuDetail) {
      navigate(
        `/${pathname}/${encodeURIComponent(
          skuDetailData?.itemSkuDetail.sku_name
        )}?tab=item&subtab=general`
      );
    } else {
      enqueueSnackbar("ไม่พบ Barcode หรือ รหัสสินค้า นี้ในระบบ", {
        variant: "error",
      });
    }
    resetBarcode();
  }, [
    enqueueSnackbar,
    navigate,
    pathname,
    refetchSkuDatail,
    refetchSkuQty,
    resetBarcode,
  ]);

  const { showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  return (
    <div>
      <Grid container spacing={2} my={2}>
        {!isMobile ? (
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ControlledTextField
              label="Scan Barcode"
              control={barcodeControl}
              name="barcode"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  onBarcodeSubmitHandler();
                }
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={10}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => setShowCamera(!showCamera)}>
                <QrCodeScannerIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      {isMobile ? (
        <Box display={showCamera ? "block" : "none"} my={2}>
          {showCamera && (
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={(result) => {
                if (!!result) {
                  setScanData(result.getText());
                  setIsInterval(true);
                  setBarcodeValue("barcodeMobile", result.getText());
                }
              }}
              scanDelay={2000}
              containerStyle={{
                width: "calc(100vw-48px)",
                height: "100%",
                contentVisibility: "auto",
              }}
              videoContainerStyle={{
                width: "calc(100vw-48px)",
                height: "100%",
                contentVisibility: "auto",
              }}
              videoStyle={{
                height: "100%",
                contentVisibility: "auto",
              }}
            />
          )}
        </Box>
      ) : null}
    </div>
  );
};

export default ScanNavigate;
