import { IRouting } from "../../types/Manufacture/routing";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";

export const formatterRouting = async (data: IRouting) => {
  const { created_by, attachment_list, ...otherData } = data;

  let new_attachment_list: IAttachment[] = [];

  if (attachment_list && attachment_list.length > 0) {
    for (const file of attachment_list) {
      if (file instanceof File) {
        const { Location } = await uploadFileToS3(
          file,
          "bom",
          data?.id?.toString() || ""
        );
        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };
        new_attachment_list.push(formatAttachment);
      } else {
        new_attachment_list.push(file);
      }
    }
  }

  return {
    ...otherData,
    attachment_list: new_attachment_list,
    is_active: data.is_active === 1 || data.is_active === "1" ? 1 : 0,
  };
};

export const formatQueryRouting = (data: IRouting): IRouting => {
  const { work_order_list, ...otherData } = data;
  const new_work_order_list = work_order_list?.map(
    ({
      cost_price = 0,
      duration = 0,
      duration_unit,
      name,
      plant,
      production_center,
      responsible_user_list = [],
      remark = "",
    }) => ({
      cost_price,
      duration,
      duration_unit,
      name,
      plant,
      production_center,
      responsible_user_list,
      remark,
    })
  );

  return { ...otherData, work_order_list: new_work_order_list };
};
