import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CustomizedBox } from "../../../Custom/CustomizedBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { useFormContext } from "react-hook-form";
import { useDisable } from "../../../../hooks/use-disable";

const RoutingRemark = () => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [disabled] = useDisable();

  return (
    <CustomizedBox>
      <Typography ml={1} mb={3} fontWeight="bold">
        {t("manufacture.bom.internal_remark")}
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="internal_remark"
            control={control}
            error={Boolean(errors?.internal_remark)}
            // helperText={errors?.internal_remark && errors?.internal_remark.message}
            disabled={disabled}
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default RoutingRemark;
