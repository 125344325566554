import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm, ITab } from "../../../types/global";
import {
  GoodsReceiveQuery,
  WarehousesQuery,
} from "../../../generated/inventory";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DocumentInfoTab from "./DocumentInfoTab";
import BarcodeTab from "./BarcodeTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import { IGoodsReturn } from "../../../types/Inventory/goodsReturn";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsReceiveQuery, unknown>>;
  data?: GoodsReceiveQuery;
  warehouseData?: WarehousesQuery;
  step: number;
  renderButton: () => JSX.Element | null;
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReturn, "trace_entry_list">;
};
const GoodsReturnMobile = ({
  control,
  getValues,
  setValue,
  disabled,
  refetch,
  reset,
  step,
  errors,
  data,
  warehouseData,
  fields,
  append,
  remove,
  update,
  renderButton,
}: Props) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs: ITab[] = [
    {
      label: "ข้อมูลรับคืน",
      path: `${pathname}`,
    },
    {
      label: "สแกน Barcode",
      path: `${pathname}?tab=barcode`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "barcode":
        setCurrentTab(pathname + "?tab=barcode");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "barcode":
        return (
          <BarcodeTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            renderButton={renderButton}
            fields={fields}
            append={append}
            remove={remove}
            update={update}
            step={step}
            warehouseData={warehouseData}
          />
        );
      default:
        return (
          <DocumentInfoTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            refetch={refetch}
            setValue={setValue}
            step={step}
            data={data}
            reset={reset}
            warehouseData={warehouseData}
            append={append}
          />
        );
    }
  };

  return (
    <form>
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </form>
  );
};

export default GoodsReturnMobile;
