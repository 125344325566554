import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Divider, Typography } from "@mui/material";

import { GraphQLClient } from "graphql-request";
import { formatDate } from "../../../utils/Formatter/Date";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import {
  ManufactureOrderQuery,
  useManufactureOrderQuery,
} from "../../../generated/manufacture";
import IngredientPdfTable from "../../../components/Table/Pdf/IngredientPdfTable";
import WorkOrderPdfTable from "../../../components/Table/Pdf/WorkOrderPdfTable";

import { IIngredient } from "../../../types/Manufacture";
import { IWorkOrder } from "../../../types/Manufacture/workOrder";
import PDFInfo from "../../../components/UI/Pdf/OldPDF/PDFInfo";
import PDFLayout from "../../../components/UI/Pdf/OldPDF/PDFLayout";

const defaultSx = {
  fontSize: 12,
  lineHeight: "10px",
  letterSpacing: "0.15px",
  fontWeight: 600,
  color: "#333333",
  flex: 1,
  my: 2,
};

const ManufactureOrderPDF = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { data, isLoading } = useManufactureOrderQuery<ManufactureOrderQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { unique_id: id },
    },
    { enabled: !!id }
  );

  const leftHeader = [
    t("manufacture.order.customer"),
    t("manufacture.bom.item_unique_id"),
    t("manufacture.bom.item_name"),
    t("manufacture.bom.mfg_qty"),
    t("manufacture.bom.uom"),
    t("manufacture.bom.bom_description"),
    t("manufacture.order.item_remark"),
    t("manufacture.order.type"),
    t("manufacture.order.bom"),
  ];

  const leftInfo = [
    data?.manufactureOrder?.customer_name || "-",
    data?.manufactureOrder?.item_unique_id || "-",
    data?.manufactureOrder?.item_name || "-",
    data?.manufactureOrder?.production_qty || "-",
    data?.manufactureOrder?.uom || "-",
    data?.manufactureOrder?.item_description || "-",
    data?.manufactureOrder?.item_remark || "-",
    data?.manufactureOrder?.type || "-",
    data?.manufactureOrder?.bom_name || "-",
  ];

  const rightHeader = [
    t("manufacture.order.unique_id"),
    t("manufacture.order.reference_unique_id_list"),
    t("manufacture.order.external_reference_id"),
    t("manufacture.bom.created_date"),
    t("manufacture.order.issue_date"),
    t("manufacture.order.production_completion_date"),
    t("manufacture.order.delivery_date"),
    t("manufacture.order.employee_list"),
  ];

  const rightInfo = [
    data?.manufactureOrder?.unique_id || "-",
    data?.manufactureOrder?.reference_unique_id_list?.join(",") || "-",
    data?.manufactureOrder?.external_reference_id || "-",
    formatDate(data?.manufactureOrder?.created_date) || "-",
    formatDate(data?.manufactureOrder?.issue_date) || "-",
    formatDate(data?.manufactureOrder?.production_completion_date) || "-",
    formatDate(data?.manufactureOrder?.delivery_date) || "-",
    data?.manufactureOrder?.related_user_list?.map(
      (employee) => employee.first_name + " " + employee.last_name
    )[0] || "-",
  ];

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 125px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PDFLayout
      documentName="ใบสั่งผลิต"
      data={data?.manufactureOrder}
      documentType="manufacture_order"
      noCompany
    >
      <PDFInfo
        leftHeader={leftHeader}
        leftInfo={leftInfo}
        rightHeader={rightHeader}
        rightInfo={rightInfo}
      />
      <Divider sx={{ mb: 2 }} />
      <Typography sx={defaultSx}>ส่วนประกอบ</Typography>
      <IngredientPdfTable
        data={(data?.manufactureOrder?.ingredient_list as IIngredient[]) || []}
      />
      <Typography sx={defaultSx}>การทำงาน</Typography>
      <WorkOrderPdfTable
        data={(data?.manufactureOrder?.work_order_list as IWorkOrder[]) || []}
      />
    </PDFLayout>
  );
};

export default ManufactureOrderPDF;
