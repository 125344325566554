import { UseFieldArrayReplace, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import BomDetail from "./Bom";
import ItemDetail from "./Item";
import CustomerDetail from "./Customer";
import RoutingDetail from "./Routing";
import ManufactureTagList from "../../../../UI/TagList/ManufactureTagList";

import { CustomizedBox } from "../../../../Custom/CustomizedBox";
import ControlledServerSideCreatable from "../../../../Controller/ControlledSSCreatable";

import { IDefaultForm } from "../../../../../types/global";
import { EntityTypeEnum } from "../../../../../generated/creatable";
import { IManufactureOrder } from "../../../../../types/Manufacture/order";
import { useParams } from "react-router-dom";

// import RoutingDetail from "./Routing";

type Props = IDefaultForm & {
  roundedProductionQty: number;
  ingredientReplace: UseFieldArrayReplace<IManufactureOrder, "ingredient_list">;
  workOrderReplace: UseFieldArrayReplace<IManufactureOrder, "work_order_list">;
  ingredientQtyCalculation: () => void;
  handleClickOpenItem?: (unique_id?: string) => void;
};

const ManufactureOrderDetailForm = ({
  control,
  errors,
  disabled,
  setValue,
  getValues,
  ingredientReplace,
  workOrderReplace,
  ingredientQtyCalculation,
  roundedProductionQty,
  handleClickOpenItem,
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const status = useWatch({
    control,
    name: "main_status",
  });

  return (
    <CustomizedBox>
      <CustomerDetail
        control={control}
        setValue={setValue}
        getValues={getValues}
        disabled={(id && status !== "draft") || disabled}
        errors={errors}
      />
      <ItemDetail
        control={control}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        errors={errors}
        roundedProductionQty={roundedProductionQty}
        handleClickOpenItem={handleClickOpenItem}
      />
      <BomDetail
        control={control}
        setValue={setValue}
        getValues={getValues}
        disabled={(id && status !== "draft") || disabled}
        errors={errors}
        ingredientReplace={ingredientReplace}
        ingredientQtyCalculation={ingredientQtyCalculation}
      />
      <RoutingDetail
        control={control}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        errors={errors}
        workOrderReplace={workOrderReplace}
      />
      <Typography ml={1} my={2} fontWeight="bold">
        {t("manufacture.order.group")}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <ControlledServerSideCreatable
            title={t("inventory.items.manufactureType")}
            control={control}
            name="type"
            dataName="manufacture_type"
            documentType={EntityTypeEnum.ManufactureOrder}
            defaultOptions={[]}
            error={Boolean(errors.manufacture_type)}
            helperText={errors.manufacture_type?.message}
            setValue={setValue}
            disabled={status !== "finished" ? disabled : true}
          />
        </Grid>
        <ManufactureTagList
          name="tag_list"
          control={control}
          setValue={setValue}
          getValues={getValues}
          disabled={status !== "finished" ? disabled : true}
          entity={EntityTypeEnum.ManufactureOrder}
        />
      </Grid>
    </CustomizedBox>
  );
};

export default ManufactureOrderDetailForm;
