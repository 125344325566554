import { Control, UseFormRegister, UseFormWatch } from "react-hook-form";
import { LineOaSettingUpsertInput } from "../../../generated/company-user";
import LineOACheckBoxSetting from "./LineOACheckBoxSetting";
import { FormGroup } from "@mui/material";
import { LineOASettingGroupField } from "../../../types/Liff/lineOASetting";

const LineOACheckBoxSettingGroup = ({
  settingGroup,
  control,
  watch,
}: {
  settingGroup: LineOASettingGroupField;
  control: Control<LineOaSettingUpsertInput, any>;
  watch: UseFormWatch<LineOaSettingUpsertInput>;
}) => {
  return (
    <FormGroup>
      <p>{settingGroup.display_name}</p>
      <LineOACheckBoxSetting
        label="เปิด/ปิด"
        name={`${settingGroup.name}.is_active`}
        control={control}
        watch={watch}
      />
      {settingGroup.settings.map((subSetting) => (
        <LineOACheckBoxSetting
          key={subSetting.name}
          label={subSetting.display_name}
          name={`${settingGroup.name}.${subSetting.name}`}
          control={control}
          watch={watch}
          disabled={!watch(`${settingGroup.name}.is_active`)}
        />
      ))}
    </FormGroup>
  );
};

export default LineOACheckBoxSettingGroup;
