export const dateOption = [
  {
    label: "วันนี้",
    value: "today",
  },
  { label: "เมื่อวาน", value: "yesterday" },
  { label: "7 วันที่แล้ว", value: "lastWeek" },
  { label: "14 วันที่แล้ว", value: "lastTwoWeek" },
  { label: "30 วันที่แล้ว", value: "last30Days" },
  { label: "เดือนนี้", value: "currentMonth" },
  { label: "เดือนที่แล้ว", value: "lastMonth" },
  { label: "3 เดือนที่แล้ว", value: "lastThreeMonth" },
  { label: "ปีนี้", value: "currentYear" },
  { label: "ปีที่แล้ว", value: "lastYear" },
];
