import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AgGrid from "../../UI/AgGrid";

type Props = {
  columnDefs: any;
};

const ConfigPermission = ({ columnDefs }: Props) => {
  const gridRef = useRef();
  const { t } = useTranslation();

  const useFilteredRows = useCallback(() => {
    const rows = [
      {
        functionName: "setting_company",
        name: t("setting.company.index"),
        view: "view",
        edit: "edit",
        isNotViewSelf: true,
      },
      {
        functionName: "setting_approval",
        name: t("setting.approval.index"),
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
      {
        functionName: "setting_remark",
        name: "หมายเหตุ",
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
      // {
      //   functionName: "PURCHASE_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.purchase"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "LOGISTIC_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.logistic"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "MANUFACTURE_REMARK",
      //   name: "หมายเหตุ" + t("setting.remark.manufacture"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      {
        functionName: "setting_inventory",
        name: t("setting.inventory.index"),
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
      // {
      //   functionName: "LOCATION",
      //   name: t("setting.inventory.location.index"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "CATEGORY",
      //   name: t("setting.inventory.category.index"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "UOM",
      //   name: t("setting.inventory.uom.index"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      // {
      //   functionName: "ATTRIBUTE",
      //   name: t("setting.inventory.attribute.index"),
      //   view: "view",
      //   create: "create",
      //   edit: "edit",
      // },
      {
        functionName: "setting_manufacture",
        name: t("setting.manufacture.index"),
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
      {
        functionName: "setting_logistic",
        name: t("setting.logistic.setting"),
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
      {
        functionName: "setting_project",
        name: t("setting.project.index"),
        view: "view",
        create: "create",
        edit: "edit",
        isNotViewSelf: true,
      },
    ];
    return rows;
  }, [t]);

  return (
    <AgGrid
      rowData={useFilteredRows()}
      ref={gridRef}
      columnDefs={columnDefs}
      autoHeight
      disabledSidebar
      isClientSide
    />
  );
};

export default ConfigPermission;
