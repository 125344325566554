import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDefaultForm, IRadio, ISelectOption } from "../../../types/global";
import { Box } from "@mui/system";
import ControlledTextField from "../../Controller/ControlledTextField";
import ControlledDatePicker from "../../Controller/ControlledDatePicker";
import { useState } from "react";
import ControlledSelect from "../../Controller/ControlledSelect";
import { Controller, useWatch } from "react-hook-form";
import CustomizedCheckboxes from "../../Custom/CustomizedCheckbox";
import DropzoneField from "../../UI/DropzoneUI";
import ControlledRadioGroup from "../../Controller/ControlledRadioGroup";

const radioList: IRadio[] = [
  {
    label: "ใช้งาน",
    value: 1,
  },
  {
    label: "ไม่ใช้งาน",
    value: 0,
  },
];

const GeneralForm = ({ control, errors, disabled, setValue }: IDefaultForm) => {
  const { t } = useTranslation();
  const [registerDateIsOpen, setRegisterDateIsOpen] = useState<boolean>(false);
  const [vatRegisterDateIsOpen, setVatRegisterDateIsOpen] =
    useState<boolean>(false);
  const [identityNoError, setIdentityNoError] = useState<boolean>(false);

  const watchRegisterVat = useWatch({ control, name: "is_registered_vat" });

  const corporateTypes: ISelectOption[] = [
    {
      label: t("setting.company.corporate_type.company"),
      value: "บริษัท",
    },
    {
      label: t("setting.company.corporate_type.public_limited"),
      value: "บริษัทมหาชนจำกัด",
    },
    {
      label: t("setting.company.corporate_type.limited_partnership"),
      value: "ห้างหุ้นส่วนจำกัด",
    },
    {
      label: t("setting.company.corporate_type.juristic_partnership"),
      value: "ห้างหุ้นส่วนสามัญนิติบุคคล",
    },
    {
      label: t("setting.company.corporate_type.association"),
      value: "สมาคม",
    },
    { label: t("setting.company.corporate_type.other"), value: "อื่นๆ" },
  ];

  const openRegisterDateHandler = () => {
    setRegisterDateIsOpen(true);
  };

  const closeRegisterDateHandler = () => {
    setRegisterDateIsOpen(false);
  };

  const openVatRegisterDateHandler = () => {
    setVatRegisterDateIsOpen(true);
  };

  const closeVatRegisterDateHandler = () => {
    setVatRegisterDateIsOpen(false);
  };

  return (
    <Box sx={{ my: 3 }}>
      <Accordion
        sx={{ borderRadius: "15px", boxShadow: "0px 2px 7px #E5E5E5" }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography ml={1} fontWeight="bold">
            {t("setting.company.index")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} mb={6} lg={8} xl={8}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledTextField
                    label={t("setting.company.unique_id")}
                    control={control}
                    name="unique_id"
                    error={Boolean(errors.unique_id)}
                    onChange={(e, field) => {
                      const trimmedValue = e.target.value
                        .replaceAll(" ", "")
                        .replaceAll(/\u00a0/g, "");
                      field.onChange(trimmedValue);
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledTextField
                    name="name"
                    control={control}
                    label={t("setting.company.name")}
                    error={Boolean(errors.company_name)}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledSelect
                    name="type"
                    control={control}
                    options={corporateTypes}
                    label={t("setting.company.corporate_type.index")}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledTextField
                    name="identity_no"
                    control={control}
                    label={t("setting.company.identity_no")}
                    error={Boolean(errors.identity_no) || identityNoError}
                    helperText={
                      identityNoError
                        ? "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก"
                        : errors.identity_no?.message
                    }
                    onChange={(e, field) => {
                      if (e.target.value.length > 13) {
                        setIdentityNoError(true);
                      } else {
                        setIdentityNoError(false);
                      }
                      field.onChange(e.target.value);
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ControlledDatePicker
                    name="register_date"
                    control={control}
                    label={t("setting.company.register_date")}
                    error={Boolean(errors.register_date)}
                    open={registerDateIsOpen}
                    onOpen={openRegisterDateHandler}
                    onClose={closeRegisterDateHandler}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Box sx={{ my: 2, ml: 1 }}>
                <Controller
                  name="is_registered_vat"
                  control={control}
                  render={({ field }) => (
                    <CustomizedCheckboxes
                      label="จดทะเบียนภาษีมูลค่าเพิ่ม"
                      isDisabled={disabled}
                      {...field}
                    />
                  )}
                />
              </Box>
              {watchRegisterVat && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ControlledDatePicker
                      name="vat_registration_date"
                      control={control}
                      label={t("setting.company.vat_registration_date")}
                      error={Boolean(errors.vat_registration_date)}
                      open={vatRegisterDateIsOpen}
                      onOpen={openVatRegisterDateHandler}
                      onClose={closeVatRegisterDateHandler}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}
              <Typography ml={1} mt={3} fontWeight="bold">
                {t("setting.company.status")}
              </Typography>
              <Box ml={1} mt={1}>
                <ControlledRadioGroup
                  control={control}
                  name="is_active"
                  row
                  radioLists={radioList}
                  disabled={disabled}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              mb={6}
              lg={4}
              xl={4}
              display="flex"
              justifyContent="center"
            >
              <Box height={180}>
                <DropzoneField
                  control={control}
                  setValue={setValue}
                  name="img_url"
                  acceptedFileType="image"
                  disabled={disabled}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default GeneralForm;
