import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumReferenceDocumentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ReferenceDocumentType>;
};

export type EnumReferenceDocumentTypeFilter = {
  equals?: InputMaybe<ReferenceDocumentType>;
  in?: InputMaybe<Array<ReferenceDocumentType>>;
  not?: InputMaybe<NestedEnumReferenceDocumentTypeFilter>;
  notIn?: InputMaybe<Array<ReferenceDocumentType>>;
};

export type EnumReferenceDocumentTypeNullableFilter = {
  equals?: InputMaybe<ReferenceDocumentType>;
  in?: InputMaybe<Array<ReferenceDocumentType>>;
  not?: InputMaybe<NestedEnumReferenceDocumentTypeNullableFilter>;
  notIn?: InputMaybe<Array<ReferenceDocumentType>>;
};

export type Event = {
  __typename?: 'Event';
  aggrid_status?: Maybe<Scalars['String']>;
  appointment_ended_date?: Maybe<Scalars['DateTime']>;
  appointment_started_date?: Maybe<Scalars['DateTime']>;
  attachment_list?: Maybe<Array<Scalars['JSON']>>;
  cancellation_date?: Maybe<Scalars['DateTime']>;
  check_in_end_date?: Maybe<Scalars['DateTime']>;
  check_in_start_date?: Maybe<Scalars['DateTime']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_person_name?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['JSON']>;
  created_date: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  destination_check_point?: Maybe<Scalars['JSON']>;
  distance?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  location_link?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  main_status: Scalars['String'];
  name: Scalars['String'];
  period?: Maybe<Scalars['String']>;
  planning_ended_date?: Maybe<Scalars['DateTime']>;
  planning_started_date?: Maybe<Scalars['DateTime']>;
  project_name?: Maybe<Scalars['String']>;
  related_user_list?: Maybe<Array<Scalars['JSON']>>;
  remark?: Maybe<Scalars['String']>;
  start_check_in_point?: Maybe<Scalars['JSON']>;
  sub_status?: Maybe<Scalars['String']>;
  task_status?: Maybe<Scalars['String']>;
  updated_date: Scalars['DateTime'];
};

export type EventCreateInput = {
  aggrid_status?: InputMaybe<Scalars['String']>;
  appointment_ended_date?: InputMaybe<Scalars['DateTime']>;
  appointment_started_date?: InputMaybe<Scalars['DateTime']>;
  attachment_list?: InputMaybe<EventCreateattachment_ListInput>;
  cancellation_date?: InputMaybe<Scalars['DateTime']>;
  check_in_end_date?: InputMaybe<Scalars['DateTime']>;
  check_in_start_date?: InputMaybe<Scalars['DateTime']>;
  contact_name?: InputMaybe<Scalars['String']>;
  contact_person_name?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  desc?: InputMaybe<Scalars['String']>;
  destination_check_point?: InputMaybe<Scalars['JSON']>;
  distance?: InputMaybe<Scalars['String']>;
  flag_status?: InputMaybe<EventCreateflag_StatusInput>;
  location_link?: InputMaybe<Scalars['String']>;
  location_name?: InputMaybe<Scalars['String']>;
  main_status: Scalars['String'];
  name: Scalars['String'];
  period?: InputMaybe<Scalars['String']>;
  planning_ended_date?: InputMaybe<Scalars['DateTime']>;
  planning_started_date?: InputMaybe<Scalars['DateTime']>;
  project_name?: InputMaybe<Scalars['String']>;
  related_user_list?: InputMaybe<EventCreaterelated_User_ListInput>;
  remark?: InputMaybe<Scalars['String']>;
  start_check_in_point?: InputMaybe<Scalars['JSON']>;
  sub_status?: InputMaybe<Scalars['String']>;
  task_status?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['DateTime']>;
};

export type EventCreateattachment_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type EventCreateflag_StatusInput = {
  set: Array<Scalars['String']>;
};

export type EventCreaterelated_User_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type EventOrderByWithRelationInput = {
  aggrid_status?: InputMaybe<SortOrderInput>;
  appointment_ended_date?: InputMaybe<SortOrderInput>;
  appointment_started_date?: InputMaybe<SortOrderInput>;
  attachment_list?: InputMaybe<SortOrder>;
  cancellation_date?: InputMaybe<SortOrderInput>;
  check_in_end_date?: InputMaybe<SortOrderInput>;
  check_in_start_date?: InputMaybe<SortOrderInput>;
  contact_name?: InputMaybe<SortOrderInput>;
  contact_person_name?: InputMaybe<SortOrderInput>;
  created_by?: InputMaybe<SortOrderInput>;
  created_date?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrderInput>;
  destination_check_point?: InputMaybe<SortOrderInput>;
  distance?: InputMaybe<SortOrderInput>;
  flag_status?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location_link?: InputMaybe<SortOrderInput>;
  location_name?: InputMaybe<SortOrderInput>;
  main_status?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  period?: InputMaybe<SortOrderInput>;
  planning_ended_date?: InputMaybe<SortOrderInput>;
  planning_started_date?: InputMaybe<SortOrderInput>;
  project_name?: InputMaybe<SortOrderInput>;
  related_user_list?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrderInput>;
  start_check_in_point?: InputMaybe<SortOrderInput>;
  sub_status?: InputMaybe<SortOrderInput>;
  task_status?: InputMaybe<SortOrderInput>;
  updated_date?: InputMaybe<SortOrder>;
};

export enum EventScalarFieldEnum {
  AggridStatus = 'aggrid_status',
  AppointmentEndedDate = 'appointment_ended_date',
  AppointmentStartedDate = 'appointment_started_date',
  AttachmentList = 'attachment_list',
  CancellationDate = 'cancellation_date',
  CheckInEndDate = 'check_in_end_date',
  CheckInStartDate = 'check_in_start_date',
  ContactName = 'contact_name',
  ContactPersonName = 'contact_person_name',
  CreatedBy = 'created_by',
  CreatedDate = 'created_date',
  Desc = 'desc',
  DestinationCheckPoint = 'destination_check_point',
  Distance = 'distance',
  FlagStatus = 'flag_status',
  Id = 'id',
  LocationLink = 'location_link',
  LocationName = 'location_name',
  MainStatus = 'main_status',
  Name = 'name',
  Period = 'period',
  PlanningEndedDate = 'planning_ended_date',
  PlanningStartedDate = 'planning_started_date',
  ProjectName = 'project_name',
  RelatedUserList = 'related_user_list',
  Remark = 'remark',
  StartCheckInPoint = 'start_check_in_point',
  SubStatus = 'sub_status',
  TaskStatus = 'task_status',
  UpdatedDate = 'updated_date'
}

export type EventUpdateInput = {
  aggrid_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  appointment_ended_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  appointment_started_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  attachment_list?: InputMaybe<EventUpdateattachment_ListInput>;
  cancellation_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  check_in_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  check_in_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  contact_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact_person_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  desc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  destination_check_point?: InputMaybe<Scalars['JSON']>;
  distance?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  flag_status?: InputMaybe<EventUpdateflag_StatusInput>;
  location_link?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  main_status?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  period?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  planning_ended_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  planning_started_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  related_user_list?: InputMaybe<EventUpdaterelated_User_ListInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  start_check_in_point?: InputMaybe<Scalars['JSON']>;
  sub_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  task_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateattachment_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type EventUpdateflag_StatusInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type EventUpdaterelated_User_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  aggrid_status?: InputMaybe<StringNullableFilter>;
  appointment_ended_date?: InputMaybe<DateTimeNullableFilter>;
  appointment_started_date?: InputMaybe<DateTimeNullableFilter>;
  attachment_list?: InputMaybe<JsonNullableListFilter>;
  cancellation_date?: InputMaybe<DateTimeNullableFilter>;
  check_in_end_date?: InputMaybe<DateTimeNullableFilter>;
  check_in_start_date?: InputMaybe<DateTimeNullableFilter>;
  contact_name?: InputMaybe<StringNullableFilter>;
  contact_person_name?: InputMaybe<StringNullableFilter>;
  created_by?: InputMaybe<JsonNullableFilter>;
  created_date?: InputMaybe<DateTimeFilter>;
  desc?: InputMaybe<StringNullableFilter>;
  destination_check_point?: InputMaybe<JsonNullableFilter>;
  distance?: InputMaybe<StringNullableFilter>;
  flag_status?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<IntFilter>;
  location_link?: InputMaybe<StringNullableFilter>;
  location_name?: InputMaybe<StringNullableFilter>;
  main_status?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  period?: InputMaybe<StringNullableFilter>;
  planning_ended_date?: InputMaybe<DateTimeNullableFilter>;
  planning_started_date?: InputMaybe<DateTimeNullableFilter>;
  project_name?: InputMaybe<StringNullableFilter>;
  related_user_list?: InputMaybe<JsonNullableListFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  start_check_in_point?: InputMaybe<JsonNullableFilter>;
  sub_status?: InputMaybe<StringNullableFilter>;
  task_status?: InputMaybe<StringNullableFilter>;
  updated_date?: InputMaybe<DateTimeFilter>;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['JSON']>>;
  has?: InputMaybe<Scalars['JSON']>;
  hasEvery?: InputMaybe<Array<Scalars['JSON']>>;
  hasSome?: InputMaybe<Array<Scalars['JSON']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkInEndEvent: Event;
  checkInStartEvent: Event;
  createEvent: Event;
  createProject: Project;
  createProjectTag: ProjectTag;
  createTask: Task;
  createWorkflowProgress: WorkflowProgress;
  createWorkflowTemplate: WorkflowTemplate;
  removeEvent: Event;
  removeProject: Project;
  removeProjectTag: ProjectTag;
  removeProjectTagVerify: ProjectTagVerify;
  removeTask: Task;
  removeWorkflowProgress: WorkflowProgress;
  removeWorkflowTemplate: WorkflowTemplate;
  updateEvent: Event;
  updateProject: Project;
  updateTask: Task;
  updateWorkflowProgress: WorkflowProgress;
  updateWorkflowTemplate: WorkflowTemplate;
};


export type MutationCheckInEndEventArgs = {
  expected_status: Scalars['String'];
  lat: Scalars['String'];
  lng: Scalars['String'];
  where: EventWhereUniqueInput;
};


export type MutationCheckInStartEventArgs = {
  expected_status: Scalars['String'];
  lat: Scalars['String'];
  lng: Scalars['String'];
  where: EventWhereUniqueInput;
};


export type MutationCreateEventArgs = {
  data: EventCreateInput;
};


export type MutationCreateProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateProjectTagArgs = {
  data: ProjectTagCreateInput;
};


export type MutationCreateTaskArgs = {
  data: TaskCreateInput;
};


export type MutationCreateWorkflowProgressArgs = {
  data: WorkflowProgressCreateInput;
};


export type MutationCreateWorkflowTemplateArgs = {
  data: WorkflowTemplateCreateInput;
};


export type MutationRemoveEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationRemoveProjectArgs = {
  shouldDeliveryError: Scalars['Boolean'];
  shouldManufactureError: Scalars['Boolean'];
  shouldPurchaseError: Scalars['Boolean'];
  shouldSaleError: Scalars['Boolean'];
  where: ProjectWhereUniqueInput;
};


export type MutationRemoveProjectTagArgs = {
  where: ProjectTagWhereUniqueInput;
};


export type MutationRemoveProjectTagVerifyArgs = {
  where: ProjectTagWhereUniqueInput;
};


export type MutationRemoveTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type MutationRemoveWorkflowProgressArgs = {
  where: WorkflowProgressWhereUniqueInput;
};


export type MutationRemoveWorkflowTemplateArgs = {
  where: WorkflowTemplateWhereUniqueInput;
};


export type MutationUpdateEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateTaskArgs = {
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};


export type MutationUpdateWorkflowProgressArgs = {
  data: WorkflowProgressUpdateInput;
  where: WorkflowProgressWhereUniqueInput;
};


export type MutationUpdateWorkflowTemplateArgs = {
  data: WorkflowTemplateUpdateInput;
  where: WorkflowTemplateWhereUniqueInput;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumReferenceDocumentTypeFilter = {
  equals?: InputMaybe<ReferenceDocumentType>;
  in?: InputMaybe<Array<ReferenceDocumentType>>;
  not?: InputMaybe<NestedEnumReferenceDocumentTypeFilter>;
  notIn?: InputMaybe<Array<ReferenceDocumentType>>;
};

export type NestedEnumReferenceDocumentTypeNullableFilter = {
  equals?: InputMaybe<ReferenceDocumentType>;
  in?: InputMaybe<Array<ReferenceDocumentType>>;
  not?: InputMaybe<NestedEnumReferenceDocumentTypeNullableFilter>;
  notIn?: InputMaybe<Array<ReferenceDocumentType>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableEnumReferenceDocumentTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ReferenceDocumentType>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Project = {
  __typename?: 'Project';
  _count: ProjectCount;
  aggrid_status?: Maybe<Scalars['String']>;
  attachment_list?: Maybe<Array<Scalars['JSON']>>;
  cancel_remark?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['JSON']>;
  created_date?: Maybe<Scalars['DateTime']>;
  customer_contact_unique_id: Scalars['String'];
  customer_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['DateTime']>;
  expected_end_date?: Maybe<Scalars['DateTime']>;
  expected_start_date?: Maybe<Scalars['DateTime']>;
  flag_status?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  img_url?: Maybe<Array<Scalars['String']>>;
  main_status: Scalars['String'];
  name: Scalars['String'];
  opportunity_close_sale?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  project_value?: Maybe<Scalars['String']>;
  related_user_list?: Maybe<Array<Scalars['JSON']>>;
  remark?: Maybe<Scalars['String']>;
  responsible_user_list?: Maybe<Array<Scalars['JSON']>>;
  start_date?: Maybe<Scalars['DateTime']>;
  sub_status?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<ProjectTag>>;
  tasks_id_list?: Maybe<Array<Scalars['Int']>>;
  unique_id: Scalars['String'];
  updated_date: Scalars['DateTime'];
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  tag_list: Scalars['Int'];
};

export type ProjectCreateInput = {
  aggrid_status?: InputMaybe<Scalars['String']>;
  attachment_list?: InputMaybe<ProjectCreateattachment_ListInput>;
  cancel_remark?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  customer_contact_unique_id: Scalars['String'];
  customer_name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  expected_end_date?: InputMaybe<Scalars['DateTime']>;
  expected_start_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<ProjectCreateflag_StatusInput>;
  img_url?: InputMaybe<ProjectCreateimg_UrlInput>;
  main_status: Scalars['String'];
  name: Scalars['String'];
  opportunity_close_sale?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  project_value?: InputMaybe<Scalars['String']>;
  related_user_list?: InputMaybe<ProjectCreaterelated_User_ListInput>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<ProjectCreateresponsible_User_ListInput>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tag_list?: InputMaybe<ProjectTagCreateNestedManyWithoutProjectsInput>;
  tasks_id_list?: InputMaybe<ProjectCreatetasks_Id_ListInput>;
  unique_id: Scalars['String'];
  updated_date?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateNestedManyWithoutTag_ListInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutTag_ListInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutTag_ListInput>>;
};

export type ProjectCreateOrConnectWithoutTag_ListInput = {
  create: ProjectCreateWithoutTag_ListInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateWithoutTag_ListInput = {
  aggrid_status?: InputMaybe<Scalars['String']>;
  attachment_list?: InputMaybe<ProjectCreateattachment_ListInput>;
  cancel_remark?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  customer_contact_unique_id: Scalars['String'];
  customer_name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  expected_end_date?: InputMaybe<Scalars['DateTime']>;
  expected_start_date?: InputMaybe<Scalars['DateTime']>;
  flag_status?: InputMaybe<ProjectCreateflag_StatusInput>;
  img_url?: InputMaybe<ProjectCreateimg_UrlInput>;
  main_status: Scalars['String'];
  name: Scalars['String'];
  opportunity_close_sale?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  project_value?: InputMaybe<Scalars['String']>;
  related_user_list?: InputMaybe<ProjectCreaterelated_User_ListInput>;
  remark?: InputMaybe<Scalars['String']>;
  responsible_user_list?: InputMaybe<ProjectCreateresponsible_User_ListInput>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  sub_status?: InputMaybe<Scalars['String']>;
  tasks_id_list?: InputMaybe<ProjectCreatetasks_Id_ListInput>;
  unique_id: Scalars['String'];
  updated_date?: InputMaybe<Scalars['DateTime']>;
};

export type ProjectCreateattachment_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type ProjectCreateflag_StatusInput = {
  set: Array<Scalars['String']>;
};

export type ProjectCreateimg_UrlInput = {
  set: Array<Scalars['String']>;
};

export type ProjectCreaterelated_User_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type ProjectCreateresponsible_User_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type ProjectCreatetasks_Id_ListInput = {
  set: Array<Scalars['Int']>;
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithRelationInput = {
  aggrid_status?: InputMaybe<SortOrderInput>;
  attachment_list?: InputMaybe<SortOrder>;
  cancel_remark?: InputMaybe<SortOrderInput>;
  created_by?: InputMaybe<SortOrderInput>;
  created_date?: InputMaybe<SortOrderInput>;
  customer_contact_unique_id?: InputMaybe<SortOrder>;
  customer_name?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  end_date?: InputMaybe<SortOrderInput>;
  expected_end_date?: InputMaybe<SortOrderInput>;
  expected_start_date?: InputMaybe<SortOrderInput>;
  flag_status?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  img_url?: InputMaybe<SortOrder>;
  main_status?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  opportunity_close_sale?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  project_value?: InputMaybe<SortOrderInput>;
  related_user_list?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrderInput>;
  responsible_user_list?: InputMaybe<SortOrder>;
  start_date?: InputMaybe<SortOrderInput>;
  sub_status?: InputMaybe<SortOrderInput>;
  tag_list?: InputMaybe<ProjectTagOrderByRelationAggregateInput>;
  tasks_id_list?: InputMaybe<SortOrder>;
  unique_id?: InputMaybe<SortOrder>;
  updated_date?: InputMaybe<SortOrder>;
};

export enum ProjectScalarFieldEnum {
  AggridStatus = 'aggrid_status',
  AttachmentList = 'attachment_list',
  CancelRemark = 'cancel_remark',
  CreatedBy = 'created_by',
  CreatedDate = 'created_date',
  CustomerContactUniqueId = 'customer_contact_unique_id',
  CustomerName = 'customer_name',
  Description = 'description',
  EndDate = 'end_date',
  ExpectedEndDate = 'expected_end_date',
  ExpectedStartDate = 'expected_start_date',
  FlagStatus = 'flag_status',
  Id = 'id',
  ImgUrl = 'img_url',
  MainStatus = 'main_status',
  Name = 'name',
  OpportunityCloseSale = 'opportunity_close_sale',
  Phone = 'phone',
  ProjectValue = 'project_value',
  RelatedUserList = 'related_user_list',
  Remark = 'remark',
  ResponsibleUserList = 'responsible_user_list',
  StartDate = 'start_date',
  SubStatus = 'sub_status',
  TasksIdList = 'tasks_id_list',
  UniqueId = 'unique_id',
  UpdatedDate = 'updated_date'
}

export type ProjectTag = {
  __typename?: 'ProjectTag';
  _count: ProjectTagCount;
  id: Scalars['ID'];
  name: Scalars['String'];
  projects?: Maybe<Array<Project>>;
};

export type ProjectTagCount = {
  __typename?: 'ProjectTagCount';
  projects: Scalars['Int'];
};

export type ProjectTagCreateInput = {
  name: Scalars['String'];
  projects?: InputMaybe<ProjectCreateNestedManyWithoutTag_ListInput>;
};

export type ProjectTagCreateNestedManyWithoutProjectsInput = {
  connect?: InputMaybe<Array<ProjectTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectTagCreateOrConnectWithoutProjectsInput>>;
  create?: InputMaybe<Array<ProjectTagCreateWithoutProjectsInput>>;
};

export type ProjectTagCreateOrConnectWithoutProjectsInput = {
  create: ProjectTagCreateWithoutProjectsInput;
  where: ProjectTagWhereUniqueInput;
};

export type ProjectTagCreateWithoutProjectsInput = {
  name: Scalars['String'];
};

export type ProjectTagListRelationFilter = {
  every?: InputMaybe<ProjectTagWhereInput>;
  none?: InputMaybe<ProjectTagWhereInput>;
  some?: InputMaybe<ProjectTagWhereInput>;
};

export type ProjectTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectTagOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  projects?: InputMaybe<ProjectOrderByRelationAggregateInput>;
};

export enum ProjectTagScalarFieldEnum {
  Id = 'id',
  Name = 'name'
}

export type ProjectTagScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectTagScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectTagScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ProjectTagUpdateManyMutationInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProjectTagUpdateManyWithWhereWithoutProjectsInput = {
  data: ProjectTagUpdateManyMutationInput;
  where: ProjectTagScalarWhereInput;
};

export type ProjectTagUpdateManyWithoutProjectsNestedInput = {
  connect?: InputMaybe<Array<ProjectTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectTagCreateOrConnectWithoutProjectsInput>>;
  create?: InputMaybe<Array<ProjectTagCreateWithoutProjectsInput>>;
  delete?: InputMaybe<Array<ProjectTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectTagWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectTagUpdateWithWhereUniqueWithoutProjectsInput>>;
  updateMany?: InputMaybe<Array<ProjectTagUpdateManyWithWhereWithoutProjectsInput>>;
  upsert?: InputMaybe<Array<ProjectTagUpsertWithWhereUniqueWithoutProjectsInput>>;
};

export type ProjectTagUpdateWithWhereUniqueWithoutProjectsInput = {
  data: ProjectTagUpdateWithoutProjectsInput;
  where: ProjectTagWhereUniqueInput;
};

export type ProjectTagUpdateWithoutProjectsInput = {
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ProjectTagUpsertWithWhereUniqueWithoutProjectsInput = {
  create: ProjectTagCreateWithoutProjectsInput;
  update: ProjectTagUpdateWithoutProjectsInput;
  where: ProjectTagWhereUniqueInput;
};

export type ProjectTagVerify = {
  __typename?: 'ProjectTagVerify';
  canRemove: Scalars['Boolean'];
};

export type ProjectTagWhereInput = {
  AND?: InputMaybe<Array<ProjectTagWhereInput>>;
  NOT?: InputMaybe<Array<ProjectTagWhereInput>>;
  OR?: InputMaybe<Array<ProjectTagWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  projects?: InputMaybe<ProjectListRelationFilter>;
};

export type ProjectTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectUpdateInput = {
  aggrid_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment_list?: InputMaybe<ProjectUpdateattachment_ListInput>;
  cancel_remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  customer_contact_unique_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  customer_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  expected_end_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  expected_start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  flag_status?: InputMaybe<ProjectUpdateflag_StatusInput>;
  img_url?: InputMaybe<ProjectUpdateimg_UrlInput>;
  main_status?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  opportunity_close_sale?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project_value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  related_user_list?: InputMaybe<ProjectUpdaterelated_User_ListInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  responsible_user_list?: InputMaybe<ProjectUpdateresponsible_User_ListInput>;
  start_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sub_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tag_list?: InputMaybe<ProjectTagUpdateManyWithoutProjectsNestedInput>;
  tasks_id_list?: InputMaybe<ProjectUpdatetasks_Id_ListInput>;
  unique_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProjectUpdateattachment_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type ProjectUpdateflag_StatusInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProjectUpdateimg_UrlInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ProjectUpdaterelated_User_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type ProjectUpdateresponsible_User_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type ProjectUpdatetasks_Id_ListInput = {
  push?: InputMaybe<Array<Scalars['Int']>>;
  set?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  aggrid_status?: InputMaybe<StringNullableFilter>;
  attachment_list?: InputMaybe<JsonNullableListFilter>;
  cancel_remark?: InputMaybe<StringNullableFilter>;
  created_by?: InputMaybe<JsonNullableFilter>;
  created_date?: InputMaybe<DateTimeNullableFilter>;
  customer_contact_unique_id?: InputMaybe<StringFilter>;
  customer_name?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  end_date?: InputMaybe<DateTimeNullableFilter>;
  expected_end_date?: InputMaybe<DateTimeNullableFilter>;
  expected_start_date?: InputMaybe<DateTimeNullableFilter>;
  flag_status?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<IntFilter>;
  img_url?: InputMaybe<StringNullableListFilter>;
  main_status?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  opportunity_close_sale?: InputMaybe<IntNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  project_value?: InputMaybe<StringNullableFilter>;
  related_user_list?: InputMaybe<JsonNullableListFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  responsible_user_list?: InputMaybe<JsonNullableListFilter>;
  start_date?: InputMaybe<DateTimeNullableFilter>;
  sub_status?: InputMaybe<StringNullableFilter>;
  tag_list?: InputMaybe<ProjectTagListRelationFilter>;
  tasks_id_list?: InputMaybe<IntNullableListFilter>;
  unique_id?: InputMaybe<StringFilter>;
  updated_date?: InputMaybe<DateTimeFilter>;
};

export type ProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  event: Event;
  events: Array<Event>;
  getUniqueId: Scalars['String'];
  project: Project;
  projectTag: ProjectTag;
  projectTags: Array<ProjectTag>;
  projects: Array<Project>;
  task: Task;
  tasks: Array<Task>;
  uniqueIdIsExist: Scalars['Boolean'];
  workflowProgress: WorkflowProgress;
  workflowProgresses: Array<WorkflowProgress>;
  workflowTemplate: WorkflowTemplate;
  workflowTemplates: Array<WorkflowTemplate>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  distinct?: InputMaybe<Array<EventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryGetUniqueIdArgs = {
  document_type: ReferenceDocumentType;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectTagArgs = {
  where: ProjectTagWhereUniqueInput;
};


export type QueryProjectTagsArgs = {
  cursor?: InputMaybe<ProjectTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectTagWhereInput>;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type QueryTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryUniqueIdIsExistArgs = {
  document_type: ReferenceDocumentType;
  unique_id: Scalars['String'];
};


export type QueryWorkflowProgressArgs = {
  where: WorkflowProgressWhereUniqueInput;
};


export type QueryWorkflowProgressesArgs = {
  cursor?: InputMaybe<WorkflowProgressWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkflowProgressScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkflowProgressOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WorkflowProgressWhereInput>;
};


export type QueryWorkflowTemplateArgs = {
  where: WorkflowTemplateWhereUniqueInput;
};


export type QueryWorkflowTemplatesArgs = {
  cursor?: InputMaybe<WorkflowTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkflowTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkflowTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WorkflowTemplateWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum ReferenceDocumentType {
  Event = 'EVENT',
  Project = 'PROJECT',
  Task = 'TASK'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Task = {
  __typename?: 'Task';
  attachment_list?: Maybe<Array<Scalars['JSON']>>;
  created_by?: Maybe<Scalars['JSON']>;
  created_date?: Maybe<Scalars['DateTime']>;
  desc?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  reference_unique_id?: Maybe<Scalars['String']>;
  related_user_list?: Maybe<Array<Scalars['JSON']>>;
  remark?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  updated_date: Scalars['DateTime'];
};

export type TaskCreateInput = {
  attachment_list?: InputMaybe<TaskCreateattachment_ListInput>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<Scalars['DateTime']>;
  desc?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  reference_unique_id?: InputMaybe<Scalars['String']>;
  related_user_list?: InputMaybe<TaskCreaterelated_User_ListInput>;
  remark?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['DateTime']>;
};

export type TaskCreateattachment_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type TaskCreaterelated_User_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type TaskOrderByWithRelationInput = {
  attachment_list?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<SortOrderInput>;
  created_date?: InputMaybe<SortOrderInput>;
  desc?: InputMaybe<SortOrderInput>;
  due_date?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  reference_unique_id?: InputMaybe<SortOrderInput>;
  related_user_list?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updated_date?: InputMaybe<SortOrder>;
};

export enum TaskScalarFieldEnum {
  AttachmentList = 'attachment_list',
  CreatedBy = 'created_by',
  CreatedDate = 'created_date',
  Desc = 'desc',
  DueDate = 'due_date',
  Id = 'id',
  Name = 'name',
  ReferenceUniqueId = 'reference_unique_id',
  RelatedUserList = 'related_user_list',
  Remark = 'remark',
  Status = 'status',
  Type = 'type',
  UpdatedDate = 'updated_date'
}

export type TaskUpdateInput = {
  attachment_list?: InputMaybe<TaskUpdateattachment_ListInput>;
  created_by?: InputMaybe<Scalars['JSON']>;
  created_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  desc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  due_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reference_unique_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  related_user_list?: InputMaybe<TaskUpdaterelated_User_ListInput>;
  remark?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated_date?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaskUpdateattachment_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type TaskUpdaterelated_User_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type TaskWhereInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  attachment_list?: InputMaybe<JsonNullableListFilter>;
  created_by?: InputMaybe<JsonNullableFilter>;
  created_date?: InputMaybe<DateTimeNullableFilter>;
  desc?: InputMaybe<StringNullableFilter>;
  due_date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  reference_unique_id?: InputMaybe<StringNullableFilter>;
  related_user_list?: InputMaybe<JsonNullableListFilter>;
  remark?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updated_date?: InputMaybe<DateTimeFilter>;
};

export type TaskWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type WorkflowProgress = {
  __typename?: 'WorkflowProgress';
  approved_action?: Maybe<Scalars['String']>;
  approved_step_number?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  need_approve?: Maybe<Scalars['Boolean']>;
  reference_document_type: ReferenceDocumentType;
  reference_unique_id: Scalars['String'];
  reviewer_unique_id?: Maybe<Scalars['String']>;
  status_name: Scalars['String'];
  sub_status?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type WorkflowProgressCreateInput = {
  approved_action?: InputMaybe<Scalars['String']>;
  approved_step_number?: InputMaybe<Scalars['Int']>;
  need_approve?: InputMaybe<Scalars['Boolean']>;
  reference_document_type: ReferenceDocumentType;
  reference_unique_id: Scalars['String'];
  reviewer_unique_id?: InputMaybe<Scalars['String']>;
  status_name: Scalars['String'];
  sub_status?: InputMaybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type WorkflowProgressOrderByWithRelationInput = {
  approved_action?: InputMaybe<SortOrderInput>;
  approved_step_number?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  need_approve?: InputMaybe<SortOrderInput>;
  reference_document_type?: InputMaybe<SortOrder>;
  reference_unique_id?: InputMaybe<SortOrder>;
  reviewer_unique_id?: InputMaybe<SortOrderInput>;
  status_name?: InputMaybe<SortOrder>;
  sub_status?: InputMaybe<SortOrderInput>;
  unique_id?: InputMaybe<SortOrder>;
};

export enum WorkflowProgressScalarFieldEnum {
  ApprovedAction = 'approved_action',
  ApprovedStepNumber = 'approved_step_number',
  Id = 'id',
  NeedApprove = 'need_approve',
  ReferenceDocumentType = 'reference_document_type',
  ReferenceUniqueId = 'reference_unique_id',
  ReviewerUniqueId = 'reviewer_unique_id',
  StatusName = 'status_name',
  SubStatus = 'sub_status',
  UniqueId = 'unique_id'
}

export type WorkflowProgressUpdateInput = {
  approved_action?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  approved_step_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  need_approve?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  reference_document_type?: InputMaybe<EnumReferenceDocumentTypeFieldUpdateOperationsInput>;
  reference_unique_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reviewer_unique_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status_name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sub_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unique_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WorkflowProgressWhereInput = {
  AND?: InputMaybe<Array<WorkflowProgressWhereInput>>;
  NOT?: InputMaybe<Array<WorkflowProgressWhereInput>>;
  OR?: InputMaybe<Array<WorkflowProgressWhereInput>>;
  approved_action?: InputMaybe<StringNullableFilter>;
  approved_step_number?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  need_approve?: InputMaybe<BoolNullableFilter>;
  reference_document_type?: InputMaybe<EnumReferenceDocumentTypeFilter>;
  reference_unique_id?: InputMaybe<StringFilter>;
  reviewer_unique_id?: InputMaybe<StringNullableFilter>;
  status_name?: InputMaybe<StringFilter>;
  sub_status?: InputMaybe<StringNullableFilter>;
  unique_id?: InputMaybe<StringFilter>;
};

export type WorkflowProgressWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate';
  approval_template?: Maybe<Scalars['JSON']>;
  approval_template_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  need_approve?: Maybe<Scalars['Boolean']>;
  reference_document_type?: Maybe<ReferenceDocumentType>;
  reviewer_list?: Maybe<Array<Scalars['JSON']>>;
  reviewer_unique_id_list?: Maybe<Array<Scalars['String']>>;
  status_name?: Maybe<Scalars['String']>;
  step_number?: Maybe<Scalars['Int']>;
  sub_status?: Maybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type WorkflowTemplateCreateInput = {
  approval_template?: InputMaybe<Scalars['JSON']>;
  approval_template_id?: InputMaybe<Scalars['Int']>;
  need_approve?: InputMaybe<Scalars['Boolean']>;
  reference_document_type?: InputMaybe<ReferenceDocumentType>;
  reviewer_list?: InputMaybe<WorkflowTemplateCreatereviewer_ListInput>;
  reviewer_unique_id_list?: InputMaybe<WorkflowTemplateCreatereviewer_Unique_Id_ListInput>;
  status_name?: InputMaybe<Scalars['String']>;
  step_number?: InputMaybe<Scalars['Int']>;
  sub_status?: InputMaybe<Scalars['String']>;
  unique_id: Scalars['String'];
};

export type WorkflowTemplateCreatereviewer_ListInput = {
  set: Array<Scalars['JSON']>;
};

export type WorkflowTemplateCreatereviewer_Unique_Id_ListInput = {
  set: Array<Scalars['String']>;
};

export type WorkflowTemplateOrderByWithRelationInput = {
  approval_template?: InputMaybe<SortOrderInput>;
  approval_template_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  need_approve?: InputMaybe<SortOrderInput>;
  reference_document_type?: InputMaybe<SortOrderInput>;
  reviewer_list?: InputMaybe<SortOrder>;
  reviewer_unique_id_list?: InputMaybe<SortOrder>;
  status_name?: InputMaybe<SortOrderInput>;
  step_number?: InputMaybe<SortOrderInput>;
  sub_status?: InputMaybe<SortOrderInput>;
  unique_id?: InputMaybe<SortOrder>;
};

export enum WorkflowTemplateScalarFieldEnum {
  ApprovalTemplate = 'approval_template',
  ApprovalTemplateId = 'approval_template_id',
  Id = 'id',
  NeedApprove = 'need_approve',
  ReferenceDocumentType = 'reference_document_type',
  ReviewerList = 'reviewer_list',
  ReviewerUniqueIdList = 'reviewer_unique_id_list',
  StatusName = 'status_name',
  StepNumber = 'step_number',
  SubStatus = 'sub_status',
  UniqueId = 'unique_id'
}

export type WorkflowTemplateUpdateInput = {
  approval_template?: InputMaybe<Scalars['JSON']>;
  approval_template_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  need_approve?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  reference_document_type?: InputMaybe<NullableEnumReferenceDocumentTypeFieldUpdateOperationsInput>;
  reviewer_list?: InputMaybe<WorkflowTemplateUpdatereviewer_ListInput>;
  reviewer_unique_id_list?: InputMaybe<WorkflowTemplateUpdatereviewer_Unique_Id_ListInput>;
  status_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  step_number?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_status?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  unique_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type WorkflowTemplateUpdatereviewer_ListInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WorkflowTemplateUpdatereviewer_Unique_Id_ListInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type WorkflowTemplateWhereInput = {
  AND?: InputMaybe<Array<WorkflowTemplateWhereInput>>;
  NOT?: InputMaybe<Array<WorkflowTemplateWhereInput>>;
  OR?: InputMaybe<Array<WorkflowTemplateWhereInput>>;
  approval_template?: InputMaybe<JsonNullableFilter>;
  approval_template_id?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  need_approve?: InputMaybe<BoolNullableFilter>;
  reference_document_type?: InputMaybe<EnumReferenceDocumentTypeNullableFilter>;
  reviewer_list?: InputMaybe<JsonNullableListFilter>;
  reviewer_unique_id_list?: InputMaybe<StringNullableListFilter>;
  status_name?: InputMaybe<StringNullableFilter>;
  step_number?: InputMaybe<IntNullableFilter>;
  sub_status?: InputMaybe<StringNullableFilter>;
  unique_id?: InputMaybe<StringFilter>;
};

export type WorkflowTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  unique_id?: InputMaybe<Scalars['String']>;
};

export type EventCheckInEndMutationVariables = Exact<{
  where: EventWhereUniqueInput;
  lat: Scalars['String'];
  lng: Scalars['String'];
  expectedStatus: Scalars['String'];
}>;


export type EventCheckInEndMutation = { __typename?: 'Mutation', checkInEndEvent: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventCheckInStartMutationVariables = Exact<{
  where: EventWhereUniqueInput;
  lat: Scalars['String'];
  lng: Scalars['String'];
  expectedStatus: Scalars['String'];
}>;


export type EventCheckInStartMutation = { __typename?: 'Mutation', checkInStartEvent: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventCreateMutationVariables = Exact<{
  data: EventCreateInput;
}>;


export type EventCreateMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventDeleteMutationVariables = Exact<{
  where: EventWhereUniqueInput;
}>;


export type EventDeleteMutation = { __typename?: 'Mutation', removeEvent: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventQueryVariables = Exact<{
  where: EventWhereUniqueInput;
}>;


export type EventQuery = { __typename?: 'Query', event: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventUpdateMutationVariables = Exact<{
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
}>;


export type EventUpdateMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null } };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, name: string, desc?: string | null, remark?: string | null, related_user_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, project_name?: string | null, task_status?: string | null, contact_name?: string | null, contact_person_name?: string | null, planning_started_date?: any | null, planning_ended_date?: any | null, appointment_started_date?: any | null, appointment_ended_date?: any | null, created_date: any, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, location_name?: string | null, location_link?: string | null, start_check_in_point?: any | null, destination_check_point?: any | null, check_in_start_date?: any | null, check_in_end_date?: any | null, cancellation_date?: any | null, distance?: string | null, period?: string | null }> };

export type ProjectCreateMutationVariables = Exact<{
  data: ProjectCreateInput;
}>;


export type ProjectCreateMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, unique_id: string, name: string, description?: string | null, img_url?: Array<string> | null, related_user_list?: Array<any> | null, responsible_user_list?: Array<any> | null, customer_contact_unique_id: string, customer_name?: string | null, phone?: string | null, project_value?: string | null, opportunity_close_sale?: number | null, expected_start_date?: any | null, expected_end_date?: any | null, start_date?: any | null, end_date?: any | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, cancel_remark?: string | null, tasks_id_list?: Array<number> | null, tag_list?: Array<{ __typename?: 'ProjectTag', name: string }> | null } };

export type ProjectDeleteMutationVariables = Exact<{
  where: ProjectWhereUniqueInput;
  shouldSaleError: Scalars['Boolean'];
  shouldPurchaseError: Scalars['Boolean'];
  shouldDeliveryError: Scalars['Boolean'];
  shouldManufactureError: Scalars['Boolean'];
}>;


export type ProjectDeleteMutation = { __typename?: 'Mutation', removeProject: { __typename?: 'Project', id: string, unique_id: string, name: string, description?: string | null, img_url?: Array<string> | null, related_user_list?: Array<any> | null, responsible_user_list?: Array<any> | null, customer_contact_unique_id: string, customer_name?: string | null, phone?: string | null, project_value?: string | null, opportunity_close_sale?: number | null, expected_start_date?: any | null, expected_end_date?: any | null, start_date?: any | null, end_date?: any | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, cancel_remark?: string | null, tasks_id_list?: Array<number> | null, tag_list?: Array<{ __typename?: 'ProjectTag', name: string }> | null } };

export type ProjectQueryVariables = Exact<{
  where: ProjectWhereUniqueInput;
}>;


export type ProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, unique_id: string, name: string, description?: string | null, img_url?: Array<string> | null, related_user_list?: Array<any> | null, responsible_user_list?: Array<any> | null, customer_contact_unique_id: string, customer_name?: string | null, phone?: string | null, project_value?: string | null, opportunity_close_sale?: number | null, expected_start_date?: any | null, expected_end_date?: any | null, start_date?: any | null, end_date?: any | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, cancel_remark?: string | null, tasks_id_list?: Array<number> | null, tag_list?: Array<{ __typename?: 'ProjectTag', name: string }> | null } };

export type ProjectTagCreateMutationVariables = Exact<{
  data: ProjectTagCreateInput;
}>;


export type ProjectTagCreateMutation = { __typename?: 'Mutation', createProjectTag: { __typename?: 'ProjectTag', id: string, name: string } };

export type ProjectTagDeleteMutationVariables = Exact<{
  where: ProjectTagWhereUniqueInput;
}>;


export type ProjectTagDeleteMutation = { __typename?: 'Mutation', removeProjectTag: { __typename?: 'ProjectTag', id: string, name: string } };

export type ProjectTagDeleteVerifyMutationVariables = Exact<{
  where: ProjectTagWhereUniqueInput;
}>;


export type ProjectTagDeleteVerifyMutation = { __typename?: 'Mutation', removeProjectTagVerify: { __typename?: 'ProjectTagVerify', canRemove: boolean } };

export type ProjectTagQueryVariables = Exact<{
  where: ProjectTagWhereUniqueInput;
}>;


export type ProjectTagQuery = { __typename?: 'Query', projectTag: { __typename?: 'ProjectTag', id: string, name: string, projects?: Array<{ __typename?: 'Project', unique_id: string, id: string }> | null } };

export type ProjectTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectTagsQuery = { __typename?: 'Query', projectTags: Array<{ __typename?: 'ProjectTag', id: string, name: string, projects?: Array<{ __typename?: 'Project', unique_id: string, id: string }> | null }> };

export type ProjectUniqueIdQueryVariables = Exact<{
  documentType: ReferenceDocumentType;
}>;


export type ProjectUniqueIdQuery = { __typename?: 'Query', getUniqueId: string };

export type ProjectUpdateMutationVariables = Exact<{
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
}>;


export type ProjectUpdateMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', id: string, unique_id: string, name: string, description?: string | null, img_url?: Array<string> | null, related_user_list?: Array<any> | null, responsible_user_list?: Array<any> | null, customer_contact_unique_id: string, customer_name?: string | null, phone?: string | null, project_value?: string | null, opportunity_close_sale?: number | null, expected_start_date?: any | null, expected_end_date?: any | null, start_date?: any | null, end_date?: any | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, cancel_remark?: string | null, tasks_id_list?: Array<number> | null, tag_list?: Array<{ __typename?: 'ProjectTag', name: string }> | null } };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, unique_id: string, name: string, description?: string | null, img_url?: Array<string> | null, related_user_list?: Array<any> | null, responsible_user_list?: Array<any> | null, customer_contact_unique_id: string, customer_name?: string | null, phone?: string | null, project_value?: string | null, opportunity_close_sale?: number | null, expected_start_date?: any | null, expected_end_date?: any | null, start_date?: any | null, end_date?: any | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null, main_status: string, sub_status?: string | null, flag_status?: Array<string> | null, aggrid_status?: string | null, cancel_remark?: string | null, tasks_id_list?: Array<number> | null, tag_list?: Array<{ __typename?: 'ProjectTag', name: string }> | null }> };

export type TaskCreateMutationVariables = Exact<{
  data: TaskCreateInput;
}>;


export type TaskCreateMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Task', id: string, reference_unique_id?: string | null, name: string, desc?: string | null, status: number, due_date?: any | null, type?: string | null, related_user_list?: Array<any> | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null } };

export type TaskDeleteMutationVariables = Exact<{
  where: TaskWhereUniqueInput;
}>;


export type TaskDeleteMutation = { __typename?: 'Mutation', removeTask: { __typename?: 'Task', id: string, reference_unique_id?: string | null, name: string, desc?: string | null, status: number, due_date?: any | null, type?: string | null, related_user_list?: Array<any> | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null } };

export type TaskQueryVariables = Exact<{
  where: TaskWhereUniqueInput;
}>;


export type TaskQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, reference_unique_id?: string | null, name: string, desc?: string | null, status: number, due_date?: any | null, type?: string | null, related_user_list?: Array<any> | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null } };

export type TaskUpdateMutationVariables = Exact<{
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
}>;


export type TaskUpdateMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Task', id: string, reference_unique_id?: string | null, name: string, desc?: string | null, status: number, due_date?: any | null, type?: string | null, related_user_list?: Array<any> | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null } };

export type TasksQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksQuery = { __typename?: 'Query', tasks: Array<{ __typename?: 'Task', id: string, reference_unique_id?: string | null, name: string, desc?: string | null, status: number, due_date?: any | null, type?: string | null, related_user_list?: Array<any> | null, remark?: string | null, created_date?: any | null, updated_date: any, created_by?: any | null, attachment_list?: Array<any> | null }> };

export type WorkflowTemplateCreateMutationVariables = Exact<{
  data: WorkflowTemplateCreateInput;
}>;


export type WorkflowTemplateCreateMutation = { __typename?: 'Mutation', createWorkflowTemplate: { __typename?: 'WorkflowTemplate', id: string, unique_id: string, reference_document_type?: ReferenceDocumentType | null, step_number?: number | null, status_name?: string | null, sub_status?: string | null, need_approve?: boolean | null, approval_template?: any | null, approval_template_id?: number | null, reviewer_unique_id_list?: Array<string> | null, reviewer_list?: Array<any> | null } };

export type WorkflowTemplateDeleteMutationVariables = Exact<{
  where: WorkflowTemplateWhereUniqueInput;
}>;


export type WorkflowTemplateDeleteMutation = { __typename?: 'Mutation', removeWorkflowTemplate: { __typename?: 'WorkflowTemplate', id: string, unique_id: string, reference_document_type?: ReferenceDocumentType | null, step_number?: number | null, status_name?: string | null, sub_status?: string | null, need_approve?: boolean | null, approval_template?: any | null, approval_template_id?: number | null, reviewer_unique_id_list?: Array<string> | null, reviewer_list?: Array<any> | null } };

export type WorkflowTemplateQueryVariables = Exact<{
  where: WorkflowTemplateWhereUniqueInput;
}>;


export type WorkflowTemplateQuery = { __typename?: 'Query', workflowTemplate: { __typename?: 'WorkflowTemplate', id: string, unique_id: string, reference_document_type?: ReferenceDocumentType | null, step_number?: number | null, status_name?: string | null, sub_status?: string | null, need_approve?: boolean | null, approval_template?: any | null, approval_template_id?: number | null, reviewer_unique_id_list?: Array<string> | null, reviewer_list?: Array<any> | null } };

export type UpdateWorkflowTemplateMutationVariables = Exact<{
  data: WorkflowTemplateUpdateInput;
  where: WorkflowTemplateWhereUniqueInput;
}>;


export type UpdateWorkflowTemplateMutation = { __typename?: 'Mutation', updateWorkflowTemplate: { __typename?: 'WorkflowTemplate', id: string, unique_id: string, reference_document_type?: ReferenceDocumentType | null, step_number?: number | null, status_name?: string | null, sub_status?: string | null, need_approve?: boolean | null, approval_template_id?: number | null, approval_template?: any | null, reviewer_unique_id_list?: Array<string> | null, reviewer_list?: Array<any> | null } };

export type WorkflowTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkflowTemplatesQuery = { __typename?: 'Query', workflowTemplates: Array<{ __typename?: 'WorkflowTemplate', id: string, unique_id: string, reference_document_type?: ReferenceDocumentType | null, step_number?: number | null, status_name?: string | null, sub_status?: string | null, need_approve?: boolean | null, approval_template?: any | null, approval_template_id?: number | null, reviewer_unique_id_list?: Array<string> | null, reviewer_list?: Array<any> | null }> };


export const EventCheckInEndDocument = `
    mutation EventCheckInEnd($where: EventWhereUniqueInput!, $lat: String!, $lng: String!, $expectedStatus: String!) {
  checkInEndEvent(
    where: $where
    lat: $lat
    lng: $lng
    expected_status: $expectedStatus
  ) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventCheckInEndMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EventCheckInEndMutation, TError, EventCheckInEndMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EventCheckInEndMutation, TError, EventCheckInEndMutationVariables, TContext>(
      ['EventCheckInEnd'],
      (variables?: EventCheckInEndMutationVariables) => fetcher<EventCheckInEndMutation, EventCheckInEndMutationVariables>(client, EventCheckInEndDocument, variables, headers)(),
      options
    );
export const EventCheckInStartDocument = `
    mutation EventCheckInStart($where: EventWhereUniqueInput!, $lat: String!, $lng: String!, $expectedStatus: String!) {
  checkInStartEvent(
    where: $where
    lat: $lat
    lng: $lng
    expected_status: $expectedStatus
  ) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventCheckInStartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EventCheckInStartMutation, TError, EventCheckInStartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EventCheckInStartMutation, TError, EventCheckInStartMutationVariables, TContext>(
      ['EventCheckInStart'],
      (variables?: EventCheckInStartMutationVariables) => fetcher<EventCheckInStartMutation, EventCheckInStartMutationVariables>(client, EventCheckInStartDocument, variables, headers)(),
      options
    );
export const EventCreateDocument = `
    mutation EventCreate($data: EventCreateInput!) {
  createEvent(data: $data) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EventCreateMutation, TError, EventCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EventCreateMutation, TError, EventCreateMutationVariables, TContext>(
      ['EventCreate'],
      (variables?: EventCreateMutationVariables) => fetcher<EventCreateMutation, EventCreateMutationVariables>(client, EventCreateDocument, variables, headers)(),
      options
    );
export const EventDeleteDocument = `
    mutation EventDelete($where: EventWhereUniqueInput!) {
  removeEvent(where: $where) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EventDeleteMutation, TError, EventDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EventDeleteMutation, TError, EventDeleteMutationVariables, TContext>(
      ['EventDelete'],
      (variables?: EventDeleteMutationVariables) => fetcher<EventDeleteMutation, EventDeleteMutationVariables>(client, EventDeleteDocument, variables, headers)(),
      options
    );
export const EventDocument = `
    query Event($where: EventWhereUniqueInput!) {
  event(where: $where) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventQuery = <
      TData = EventQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: EventQueryVariables,
      options?: UseQueryOptions<EventQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<EventQuery, TError, TData>(
      ['Event', variables],
      fetcher<EventQuery, EventQueryVariables>(client, EventDocument, variables, headers),
      options
    );
export const EventUpdateDocument = `
    mutation EventUpdate($data: EventUpdateInput!, $where: EventWhereUniqueInput!) {
  updateEvent(data: $data, where: $where) {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EventUpdateMutation, TError, EventUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EventUpdateMutation, TError, EventUpdateMutationVariables, TContext>(
      ['EventUpdate'],
      (variables?: EventUpdateMutationVariables) => fetcher<EventUpdateMutation, EventUpdateMutationVariables>(client, EventUpdateDocument, variables, headers)(),
      options
    );
export const EventsDocument = `
    query Events {
  events {
    id
    name
    desc
    remark
    related_user_list
    main_status
    sub_status
    flag_status
    aggrid_status
    project_name
    task_status
    contact_name
    contact_person_name
    planning_started_date
    planning_ended_date
    appointment_started_date
    appointment_ended_date
    created_date
    updated_date
    created_by
    attachment_list
    location_name
    location_link
    start_check_in_point
    destination_check_point
    check_in_start_date
    check_in_end_date
    cancellation_date
    distance
    period
  }
}
    `;
export const useEventsQuery = <
      TData = EventsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: EventsQueryVariables,
      options?: UseQueryOptions<EventsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<EventsQuery, TError, TData>(
      variables === undefined ? ['Events'] : ['Events', variables],
      fetcher<EventsQuery, EventsQueryVariables>(client, EventsDocument, variables, headers),
      options
    );
export const ProjectCreateDocument = `
    mutation ProjectCreate($data: ProjectCreateInput!) {
  createProject(data: $data) {
    id
    unique_id
    name
    description
    img_url
    related_user_list
    responsible_user_list
    customer_contact_unique_id
    customer_name
    phone
    project_value
    opportunity_close_sale
    expected_start_date
    expected_end_date
    start_date
    end_date
    remark
    created_date
    updated_date
    created_by
    attachment_list
    main_status
    sub_status
    flag_status
    aggrid_status
    cancel_remark
    tag_list {
      name
    }
    tasks_id_list
  }
}
    `;
export const useProjectCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectCreateMutation, TError, ProjectCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectCreateMutation, TError, ProjectCreateMutationVariables, TContext>(
      ['ProjectCreate'],
      (variables?: ProjectCreateMutationVariables) => fetcher<ProjectCreateMutation, ProjectCreateMutationVariables>(client, ProjectCreateDocument, variables, headers)(),
      options
    );
export const ProjectDeleteDocument = `
    mutation ProjectDelete($where: ProjectWhereUniqueInput!, $shouldSaleError: Boolean!, $shouldPurchaseError: Boolean!, $shouldDeliveryError: Boolean!, $shouldManufactureError: Boolean!) {
  removeProject(
    where: $where
    shouldSaleError: $shouldSaleError
    shouldPurchaseError: $shouldPurchaseError
    shouldDeliveryError: $shouldDeliveryError
    shouldManufactureError: $shouldManufactureError
  ) {
    id
    unique_id
    name
    description
    img_url
    related_user_list
    responsible_user_list
    customer_contact_unique_id
    customer_name
    phone
    project_value
    opportunity_close_sale
    expected_start_date
    expected_end_date
    start_date
    end_date
    remark
    created_date
    updated_date
    created_by
    attachment_list
    main_status
    sub_status
    flag_status
    aggrid_status
    cancel_remark
    tag_list {
      name
    }
    tasks_id_list
  }
}
    `;
export const useProjectDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectDeleteMutation, TError, ProjectDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectDeleteMutation, TError, ProjectDeleteMutationVariables, TContext>(
      ['ProjectDelete'],
      (variables?: ProjectDeleteMutationVariables) => fetcher<ProjectDeleteMutation, ProjectDeleteMutationVariables>(client, ProjectDeleteDocument, variables, headers)(),
      options
    );
export const ProjectDocument = `
    query Project($where: ProjectWhereUniqueInput!) {
  project(where: $where) {
    id
    unique_id
    name
    description
    img_url
    related_user_list
    responsible_user_list
    customer_contact_unique_id
    customer_name
    phone
    project_value
    opportunity_close_sale
    expected_start_date
    expected_end_date
    start_date
    end_date
    remark
    created_date
    updated_date
    created_by
    attachment_list
    main_status
    sub_status
    flag_status
    aggrid_status
    cancel_remark
    tag_list {
      name
    }
    tasks_id_list
  }
}
    `;
export const useProjectQuery = <
      TData = ProjectQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ProjectQueryVariables,
      options?: UseQueryOptions<ProjectQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProjectQuery, TError, TData>(
      ['Project', variables],
      fetcher<ProjectQuery, ProjectQueryVariables>(client, ProjectDocument, variables, headers),
      options
    );
export const ProjectTagCreateDocument = `
    mutation ProjectTagCreate($data: ProjectTagCreateInput!) {
  createProjectTag(data: $data) {
    id
    name
  }
}
    `;
export const useProjectTagCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectTagCreateMutation, TError, ProjectTagCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectTagCreateMutation, TError, ProjectTagCreateMutationVariables, TContext>(
      ['ProjectTagCreate'],
      (variables?: ProjectTagCreateMutationVariables) => fetcher<ProjectTagCreateMutation, ProjectTagCreateMutationVariables>(client, ProjectTagCreateDocument, variables, headers)(),
      options
    );
export const ProjectTagDeleteDocument = `
    mutation ProjectTagDelete($where: ProjectTagWhereUniqueInput!) {
  removeProjectTag(where: $where) {
    id
    name
  }
}
    `;
export const useProjectTagDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectTagDeleteMutation, TError, ProjectTagDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectTagDeleteMutation, TError, ProjectTagDeleteMutationVariables, TContext>(
      ['ProjectTagDelete'],
      (variables?: ProjectTagDeleteMutationVariables) => fetcher<ProjectTagDeleteMutation, ProjectTagDeleteMutationVariables>(client, ProjectTagDeleteDocument, variables, headers)(),
      options
    );
export const ProjectTagDeleteVerifyDocument = `
    mutation ProjectTagDeleteVerify($where: ProjectTagWhereUniqueInput!) {
  removeProjectTagVerify(where: $where) {
    canRemove
  }
}
    `;
export const useProjectTagDeleteVerifyMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectTagDeleteVerifyMutation, TError, ProjectTagDeleteVerifyMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectTagDeleteVerifyMutation, TError, ProjectTagDeleteVerifyMutationVariables, TContext>(
      ['ProjectTagDeleteVerify'],
      (variables?: ProjectTagDeleteVerifyMutationVariables) => fetcher<ProjectTagDeleteVerifyMutation, ProjectTagDeleteVerifyMutationVariables>(client, ProjectTagDeleteVerifyDocument, variables, headers)(),
      options
    );
export const ProjectTagDocument = `
    query ProjectTag($where: ProjectTagWhereUniqueInput!) {
  projectTag(where: $where) {
    id
    name
    projects {
      unique_id
      id
    }
  }
}
    `;
export const useProjectTagQuery = <
      TData = ProjectTagQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ProjectTagQueryVariables,
      options?: UseQueryOptions<ProjectTagQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProjectTagQuery, TError, TData>(
      ['ProjectTag', variables],
      fetcher<ProjectTagQuery, ProjectTagQueryVariables>(client, ProjectTagDocument, variables, headers),
      options
    );
export const ProjectTagsDocument = `
    query ProjectTags {
  projectTags {
    id
    name
    projects {
      unique_id
      id
    }
  }
}
    `;
export const useProjectTagsQuery = <
      TData = ProjectTagsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ProjectTagsQueryVariables,
      options?: UseQueryOptions<ProjectTagsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProjectTagsQuery, TError, TData>(
      variables === undefined ? ['ProjectTags'] : ['ProjectTags', variables],
      fetcher<ProjectTagsQuery, ProjectTagsQueryVariables>(client, ProjectTagsDocument, variables, headers),
      options
    );
export const ProjectUniqueIdDocument = `
    query ProjectUniqueId($documentType: ReferenceDocumentType!) {
  getUniqueId(document_type: $documentType)
}
    `;
export const useProjectUniqueIdQuery = <
      TData = ProjectUniqueIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ProjectUniqueIdQueryVariables,
      options?: UseQueryOptions<ProjectUniqueIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProjectUniqueIdQuery, TError, TData>(
      ['ProjectUniqueId', variables],
      fetcher<ProjectUniqueIdQuery, ProjectUniqueIdQueryVariables>(client, ProjectUniqueIdDocument, variables, headers),
      options
    );
export const ProjectUpdateDocument = `
    mutation ProjectUpdate($data: ProjectUpdateInput!, $where: ProjectWhereUniqueInput!) {
  updateProject(data: $data, where: $where) {
    id
    unique_id
    name
    description
    img_url
    related_user_list
    responsible_user_list
    customer_contact_unique_id
    customer_name
    phone
    project_value
    opportunity_close_sale
    expected_start_date
    expected_end_date
    start_date
    end_date
    remark
    created_date
    updated_date
    created_by
    attachment_list
    main_status
    sub_status
    flag_status
    aggrid_status
    cancel_remark
    tag_list {
      name
    }
    tasks_id_list
  }
}
    `;
export const useProjectUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProjectUpdateMutation, TError, ProjectUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProjectUpdateMutation, TError, ProjectUpdateMutationVariables, TContext>(
      ['ProjectUpdate'],
      (variables?: ProjectUpdateMutationVariables) => fetcher<ProjectUpdateMutation, ProjectUpdateMutationVariables>(client, ProjectUpdateDocument, variables, headers)(),
      options
    );
export const ProjectsDocument = `
    query Projects {
  projects {
    id
    unique_id
    name
    description
    img_url
    related_user_list
    responsible_user_list
    customer_contact_unique_id
    customer_name
    phone
    project_value
    opportunity_close_sale
    expected_start_date
    expected_end_date
    start_date
    end_date
    remark
    created_date
    updated_date
    created_by
    attachment_list
    main_status
    sub_status
    flag_status
    aggrid_status
    cancel_remark
    tag_list {
      name
    }
    tasks_id_list
  }
}
    `;
export const useProjectsQuery = <
      TData = ProjectsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ProjectsQueryVariables,
      options?: UseQueryOptions<ProjectsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProjectsQuery, TError, TData>(
      variables === undefined ? ['Projects'] : ['Projects', variables],
      fetcher<ProjectsQuery, ProjectsQueryVariables>(client, ProjectsDocument, variables, headers),
      options
    );
export const TaskCreateDocument = `
    mutation TaskCreate($data: TaskCreateInput!) {
  createTask(data: $data) {
    id
    reference_unique_id
    name
    desc
    status
    due_date
    type
    related_user_list
    remark
    created_date
    updated_date
    created_by
    attachment_list
  }
}
    `;
export const useTaskCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TaskCreateMutation, TError, TaskCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TaskCreateMutation, TError, TaskCreateMutationVariables, TContext>(
      ['TaskCreate'],
      (variables?: TaskCreateMutationVariables) => fetcher<TaskCreateMutation, TaskCreateMutationVariables>(client, TaskCreateDocument, variables, headers)(),
      options
    );
export const TaskDeleteDocument = `
    mutation TaskDelete($where: TaskWhereUniqueInput!) {
  removeTask(where: $where) {
    id
    reference_unique_id
    name
    desc
    status
    due_date
    type
    related_user_list
    remark
    created_date
    updated_date
    created_by
    attachment_list
  }
}
    `;
export const useTaskDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TaskDeleteMutation, TError, TaskDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TaskDeleteMutation, TError, TaskDeleteMutationVariables, TContext>(
      ['TaskDelete'],
      (variables?: TaskDeleteMutationVariables) => fetcher<TaskDeleteMutation, TaskDeleteMutationVariables>(client, TaskDeleteDocument, variables, headers)(),
      options
    );
export const TaskDocument = `
    query Task($where: TaskWhereUniqueInput!) {
  task(where: $where) {
    id
    reference_unique_id
    name
    desc
    status
    due_date
    type
    related_user_list
    remark
    created_date
    updated_date
    created_by
    attachment_list
  }
}
    `;
export const useTaskQuery = <
      TData = TaskQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: TaskQueryVariables,
      options?: UseQueryOptions<TaskQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TaskQuery, TError, TData>(
      ['Task', variables],
      fetcher<TaskQuery, TaskQueryVariables>(client, TaskDocument, variables, headers),
      options
    );
export const TaskUpdateDocument = `
    mutation TaskUpdate($data: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
  updateTask(data: $data, where: $where) {
    id
    reference_unique_id
    name
    desc
    status
    due_date
    type
    related_user_list
    remark
    created_date
    updated_date
    created_by
    attachment_list
  }
}
    `;
export const useTaskUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TaskUpdateMutation, TError, TaskUpdateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TaskUpdateMutation, TError, TaskUpdateMutationVariables, TContext>(
      ['TaskUpdate'],
      (variables?: TaskUpdateMutationVariables) => fetcher<TaskUpdateMutation, TaskUpdateMutationVariables>(client, TaskUpdateDocument, variables, headers)(),
      options
    );
export const TasksDocument = `
    query Tasks {
  tasks {
    id
    reference_unique_id
    name
    desc
    status
    due_date
    type
    related_user_list
    remark
    created_date
    updated_date
    created_by
    attachment_list
  }
}
    `;
export const useTasksQuery = <
      TData = TasksQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: TasksQueryVariables,
      options?: UseQueryOptions<TasksQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TasksQuery, TError, TData>(
      variables === undefined ? ['Tasks'] : ['Tasks', variables],
      fetcher<TasksQuery, TasksQueryVariables>(client, TasksDocument, variables, headers),
      options
    );
export const WorkflowTemplateCreateDocument = `
    mutation WorkflowTemplateCreate($data: WorkflowTemplateCreateInput!) {
  createWorkflowTemplate(data: $data) {
    id
    unique_id
    reference_document_type
    step_number
    status_name
    sub_status
    need_approve
    approval_template
    approval_template_id
    reviewer_unique_id_list
    reviewer_list
  }
}
    `;
export const useWorkflowTemplateCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WorkflowTemplateCreateMutation, TError, WorkflowTemplateCreateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WorkflowTemplateCreateMutation, TError, WorkflowTemplateCreateMutationVariables, TContext>(
      ['WorkflowTemplateCreate'],
      (variables?: WorkflowTemplateCreateMutationVariables) => fetcher<WorkflowTemplateCreateMutation, WorkflowTemplateCreateMutationVariables>(client, WorkflowTemplateCreateDocument, variables, headers)(),
      options
    );
export const WorkflowTemplateDeleteDocument = `
    mutation WorkflowTemplateDelete($where: WorkflowTemplateWhereUniqueInput!) {
  removeWorkflowTemplate(where: $where) {
    id
    unique_id
    reference_document_type
    step_number
    status_name
    sub_status
    need_approve
    approval_template
    approval_template_id
    reviewer_unique_id_list
    reviewer_list
  }
}
    `;
export const useWorkflowTemplateDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WorkflowTemplateDeleteMutation, TError, WorkflowTemplateDeleteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WorkflowTemplateDeleteMutation, TError, WorkflowTemplateDeleteMutationVariables, TContext>(
      ['WorkflowTemplateDelete'],
      (variables?: WorkflowTemplateDeleteMutationVariables) => fetcher<WorkflowTemplateDeleteMutation, WorkflowTemplateDeleteMutationVariables>(client, WorkflowTemplateDeleteDocument, variables, headers)(),
      options
    );
export const WorkflowTemplateDocument = `
    query WorkflowTemplate($where: WorkflowTemplateWhereUniqueInput!) {
  workflowTemplate(where: $where) {
    id
    unique_id
    reference_document_type
    step_number
    status_name
    sub_status
    need_approve
    approval_template
    approval_template_id
    reviewer_unique_id_list
    reviewer_list
  }
}
    `;
export const useWorkflowTemplateQuery = <
      TData = WorkflowTemplateQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: WorkflowTemplateQueryVariables,
      options?: UseQueryOptions<WorkflowTemplateQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WorkflowTemplateQuery, TError, TData>(
      ['WorkflowTemplate', variables],
      fetcher<WorkflowTemplateQuery, WorkflowTemplateQueryVariables>(client, WorkflowTemplateDocument, variables, headers),
      options
    );
export const UpdateWorkflowTemplateDocument = `
    mutation UpdateWorkflowTemplate($data: WorkflowTemplateUpdateInput!, $where: WorkflowTemplateWhereUniqueInput!) {
  updateWorkflowTemplate(data: $data, where: $where) {
    id
    unique_id
    reference_document_type
    step_number
    status_name
    sub_status
    need_approve
    approval_template_id
    approval_template
    reviewer_unique_id_list
    reviewer_list
  }
}
    `;
export const useUpdateWorkflowTemplateMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateWorkflowTemplateMutation, TError, UpdateWorkflowTemplateMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateWorkflowTemplateMutation, TError, UpdateWorkflowTemplateMutationVariables, TContext>(
      ['UpdateWorkflowTemplate'],
      (variables?: UpdateWorkflowTemplateMutationVariables) => fetcher<UpdateWorkflowTemplateMutation, UpdateWorkflowTemplateMutationVariables>(client, UpdateWorkflowTemplateDocument, variables, headers)(),
      options
    );
export const WorkflowTemplatesDocument = `
    query WorkflowTemplates {
  workflowTemplates {
    id
    unique_id
    reference_document_type
    step_number
    status_name
    sub_status
    need_approve
    approval_template
    approval_template_id
    reviewer_unique_id_list
    reviewer_list
  }
}
    `;
export const useWorkflowTemplatesQuery = <
      TData = WorkflowTemplatesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: WorkflowTemplatesQueryVariables,
      options?: UseQueryOptions<WorkflowTemplatesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WorkflowTemplatesQuery, TError, TData>(
      variables === undefined ? ['WorkflowTemplates'] : ['WorkflowTemplates', variables],
      fetcher<WorkflowTemplatesQuery, WorkflowTemplatesQueryVariables>(client, WorkflowTemplatesDocument, variables, headers),
      options
    );