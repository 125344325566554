import { useTranslation } from "react-i18next";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const PurchaseReport = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.request.index"),
      to: "/purchase/report/request",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("purchase.order.index"),
      to: "/purchase/report/order",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
    {
      name: t("purchase.return.index"),
      to: "/purchase/report/return",
      icon: <DescriptionOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default PurchaseReport;
