import { Box, Skeleton, Typography } from "@mui/material";
import { CompanyQuery, useCompanyQuery } from "../../../generated/company-user";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { formatContactName } from "../../../utils/Formatter/Global";

interface Props {
  noCompany?: boolean;
  imgSize?: number;
}

const PDFCompanyInfo = ({ noCompany, imgSize }: Props) => {
  const tenantId = sessionStorage.getItem("tenant-id");

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const { data, isLoading } = useCompanyQuery<CompanyQuery>(
    graphQLClientWithHeaderCompany,
    {
      uniqueInput: {
        unique_id: tenantId,
      },
    }
  );

  if (isLoading) {
    return <Skeleton animation="wave" />;
  }

  const defaultAddress =
    data?.company &&
    data.company.address_list &&
    data.company.address_list.find((address) => address.is_default);

  // const phoneNumber =
  //   data?.company &&
  //   data.company.contact_channel_list &&
  //   data.company.contact_channel_list
  //     .filter((channel) => channel.contact_channel_type === "เบอร์โทรศัพท์")
  //     .map((c) => c.contact_channel_name);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "top" }}>
          <Box
            sx={{
              width: 65,
              height: 65,
              minWidth: 65,
              minHeight: 65,
              mt: 0.5,
            }}
          >
            <img
              alt="npr-erp-logo"
              src={
                data?.company?.img_url
                  ? data?.company.img_url[0]
                    ? data?.company.img_url[0]
                    : "/static/logo.png"
                  : "/static/logo.png"
              }
              className="image-no-hover"
              style={{ borderRadius: 3 }}
            />
          </Box>
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {formatContactName(
                  "นิติบุคคล",
                  data?.company?.type || "",
                  "",
                  data?.company?.name || "",
                  ""
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  lineHeight: "24px",
                }}
              >
                {defaultAddress?.address || ""}{" "}
                {defaultAddress?.sub_district || ""}{" "}
                {defaultAddress?.district || ""}{" "}
                {defaultAddress?.province || ""}{" "}
                {defaultAddress?.postal_code || ""}
              </Typography>
              <Box height="24px">
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 600,
                    lineHeight: "24px",
                    mr: 1,
                  }}
                  component="span"
                >
                  เลขประจำตัวผู้เสียภาษี
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    lineHeight: "24px",
                  }}
                  component="span"
                >
                  {data?.company?.identity_no || "-"}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PDFCompanyInfo;
