import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useGoodsReturnItemList } from "../../../../hooks/Inventory/use-return-item-list";
import { FieldArrayWithId, useWatch } from "react-hook-form";
import { IGoodsReturn } from "../../../../types/Inventory/goodsReturn";
import { Tracability } from "../../../../generated/inventory";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Fragment } from "react";
import TotalPostedQuantityCell from "./TotalPostedQuantityCell";

type Props = IDefaultForm & {
  fields: FieldArrayWithId<IGoodsReturn, "trace_entry_list", "id">[];
  step: number;
  initialTraceItems?: string[];
  existingInitialTraceItems?: string[];
};

const GoodsReturnItemList = ({
  control,
  disabled,
  fields,
  step,
  initialTraceItems,
  existingInitialTraceItems,
}: Props) => {
  const watchMainStatus = useWatch({
    control,
    name: "main_status",
  });

  const headers = useGoodsReturnItemList(step, disabled);
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                align={header.align ? header.align : "center"}
                key={index}
                sx={{
                  px: 1,
                  py: 1,
                }}
                width={header.width}
              >
                <Typography fontSize={14} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((trace, index) => (
            <Fragment key={trace.id}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[0]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[1]?.width || 0
                      : 0) + 16
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      minWidth: "90px",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt={trace.item_name}
                      src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                      sx={{
                        width: 60,
                        height: 60,
                        border: "1px solid #BEBEBE",
                        borderRadius: "2px",
                      }}
                      variant="square"
                    >
                      <ImageOutlinedIcon
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        fontSize="medium"
                      />
                    </Avatar>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[2]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.item_unique_id}</Typography>
                  <Typography fontSize={14}>{trace.item_name}</Typography>
                  {watchMainStatus === "draft" &&
                    initialTraceItems?.includes(trace.item_unique_id) &&
                    !existingInitialTraceItems?.includes(
                      trace.item_unique_id
                    ) && (
                      <Typography
                        fontSize={12}
                        sx={{
                          mt: 0.5,
                          color: (theme) => theme.palette.error.main,
                        }}
                      >
                        สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                      </Typography>
                    )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[3]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>
                    {trace.document_item_qty || 0}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[4]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.posted_qty || 0}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[5]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>
                    {(trace.document_item_qty || 0) - (trace.posted_qty || 0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={
                    (headers && headers.length > 0
                      ? headers[6]?.width || 0
                      : 0) + 16
                  }
                >
                  {trace.tracability === Tracability.Normal ? (
                    <Typography fontSize={14}>{trace.qty}</Typography>
                  ) : (
                    <TotalPostedQuantityCell
                      control={control}
                      nestedIndex={index}
                    />
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  width={
                    (headers && headers.length > 0
                      ? headers[7]?.width || 0
                      : 0) + 16
                  }
                >
                  <Typography fontSize={14}>{trace.uom?.name}</Typography>
                </TableCell>
                {/* <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.serial_no}</Typography>
                  </TableCell> */}
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsReturnItemList;
