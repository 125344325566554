import { Box, Grid, Typography } from "@mui/material";
import { ReactNode, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import { useTranslation } from "react-i18next";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { dateOption } from "../../../../utils/options";
import CustomizedButton from "../../../Custom/CustomizedButton";
import dayjs, { Dayjs } from "dayjs";
import { IDefaultForm } from "../../../../types/global";

interface Props {
  title: string;
  children: ReactNode;
  onRefresh?: () => void;
  latestFetchTime?: Dayjs;
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  onFilterReset?: () => void;
  getFilteredData: () => void;
}

const DashboardSection = ({
  title,
  control,
  children,
  onRefresh,
  latestFetchTime,
  setValue,
  getValues,
  onFilterReset,
  getFilteredData,
}: Props) => {
  const { t } = useTranslation();
  const watchDateType = useWatch({ control, name: "dateType" });

  const filterChangedDate = useCallback(
    (startDate: Date, endDate: Date) => {
      setValue("dateFrom", startDate);
      setValue("dateTo", endDate);
    },
    [setValue]
  );

  const onChangeDateHandle = useCallback(() => {
    const dateType = getValues("dateType");
    let startDate = dayjs().startOf("day").subtract(1, "day").toDate();
    let endDate = dayjs().endOf("day").toDate();
    if (dateType === "today") {
      startDate = dayjs().startOf("day").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "yesterday") {
      startDate = dayjs().startOf("day").subtract(1, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "lastWeek") {
      startDate = dayjs().startOf("day").subtract(6, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "lastTwoWeek") {
      startDate = dayjs().startOf("day").subtract(13, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "last30Days") {
      startDate = dayjs().startOf("day").subtract(29, "day").toDate();
      endDate = dayjs().endOf("day").subtract(1, "day").toDate();
    } else if (dateType === "currentMonth") {
      startDate = dayjs().startOf("month").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "lastMonth") {
      startDate = dayjs().startOf("month").subtract(1, "month").toDate();
      endDate = dayjs().endOf("month").subtract(1, "month").toDate();
    } else if (dateType === "lastThreeMonth") {
      startDate = dayjs().startOf("month").subtract(2, "month").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "currentYear") {
      startDate = dayjs().startOf("year").toDate();
      endDate = dayjs().endOf("day").toDate();
    } else if (dateType === "lastYear") {
      startDate = dayjs().startOf("year").subtract(1, "year").toDate();
      endDate = dayjs().endOf("year").subtract(1, "year").toDate();
    }
    return filterChangedDate(startDate, endDate);
  }, [filterChangedDate, getValues]);

  useEffect(() => {
    if (watchDateType) onChangeDateHandle();
  }, [onChangeDateHandle, watchDateType]);

  return (
    <Box
      sx={{ backgroundColor: "#F5F5F5", borderRadius: "20px", padding: "30px" }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
        flexDirection={"row"}
        mb={2}
      >
        <Grid item xs={12} sm={"auto"}>
          <Typography fontWeight={"bold"} fontSize={"24px"}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={10}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {/* Refresh button and latest fetch time indicator */}

          {/* <Grid item container xs={12} sm={"auto"} alignItems={"center"}>
            <Grid item>
              <TimeFromNowDisplay latestFetchedTime={latestFetchTime} />
            </Grid>
            <Grid item>
              <CustomizedTooltip title="อัปเดตข้อมูล">
                <IconButton onClick={onRefresh}>
                  <RefreshIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={"auto"}>
            <ControlledSelect
              control={control}
              name={"dateType"}
              options={dateOption}
              label={t("date.range")}
              sx={{ width: { xs: "246px", sm: "145px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={"auto"}>
            <ControlledDatePicker
              name="dateFrom"
              control={control}
              label={t("date.from")}
              sx={{ width: { xs: "auto", sm: "145px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={"auto"}>
            <ControlledDatePicker
              name="dateTo"
              control={control}
              label={t("date.to")}
              sx={{ width: { xs: "auto", sm: "145px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={"auto"}>
            <CustomizedButton
              title={t("button.filter")}
              variant="contained"
              size="medium"
              onClick={getFilteredData}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Filter reset button */}

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}> */}
      {/* <CustomizedButton
          title={t("button.reset_filter")}
          variant="outlined"
          size="medium"
          onClick={onFilterReset}
        /> */}
      {/* </Box> */}
      {children}
    </Box>
  );
};

export default DashboardSection;
