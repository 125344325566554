import AgGrid from "../../UI/AgGrid";
import { RefObject, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ColDef,
  // GridReadyEvent,
  // IServerSideGetRowsParams,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
// import { CONTACTS_AGGRID } from "../../../services/AgGrid/ContactAgGrid";
import { projectColumnDefs } from "./columnDefs";
import { IProject } from "../../../types/Project";
// import { GraphQLClient } from "graphql-request";
// import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
// import { mockProjectsData } from "../../../pages/Project/Projects/mockData";
// import { ProjectsQuery, useProjectsQuery } from "../../../generated/project";
interface Props {
  gridRef: RefObject<AgGridReact<IProject>>;
  isFilter?: string | null;
  allProjects?: IProject[];
}

const ProjectsTable = ({ gridRef, isFilter, allProjects }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const graphQLClientWithHeaderProject: GraphQLClient =
  //   createGraphQLClientWithMiddleware("crm");

  // const {
  //   data: projectsQuery,
  //   isLoading: isLoadingProjects,
  //   isSuccess: isSuccessProjects,
  // } = useProjectsQuery<ProjectsQuery>(
  //   graphQLClientWithHeaderProject,
  //   {}
  //   // { enabled: view === ProjectView.Kanban }
  // );

  const columnDefs: ColDef[] = projectColumnDefs(t);

  // const datasource = {
  //   async getRows(params: IServerSideGetRowsParams) {
  //     const { request } = params;
  //     const { startRow, endRow, filterModel, sortModel } = request;
  //     const { tag_list, ...otherFilter } = filterModel;
  //       ...otherFilter,
  //       tag_list: tag_list && {
  //         filterType: "objectArray",
  //         type: "some",
  //         filter: {
  //           name: {
  //             filterType: "set",
  //             values: tag_list.values,
  //           },
  //         },
  //       },
  //     };
  //     try {
  //       const { contactsFindManyAggrid } =
  //         await graphQLClientWithHeaderContact.request(CONTACTS_AGGRID, {
  //           aggridInput: {
  //             startRow,
  //             endRow,
  //             filterModel: formatFilter,
  //             sortModel,
  //           },
  //         });
  //       params.success({
  //         rowData: contactsFindManyAggrid.data as any[],
  //         rowCount: contactsFindManyAggrid.count as number,
  //       });
  //     } catch (err) {
  //       params.fail();
  //     }
  //   },
  // };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "pre-sales":
          instance?.setModel({ values: ["pre_sales"] });
          break;
        case "post-sales":
          instance?.setModel({ values: ["post_sales"] });
          break;
        case "support":
          instance?.setModel({ values: ["support"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({});
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  // const onGridReady = (params: GridReadyEvent) => {
  //   onFilterChanged(params);
  //   params.api.setServerSideDatasource(datasource);
  // };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`/project/projects/${encodeURIComponent(params.data.unique_id)}`);
  };

  return (
    <AgGrid
      rowData={allProjects}
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      // onGridReady={onGridReady}
      onRowDoubleClicked={onRowDoubleClicked}
      // onFirstDataRendered={onFirstDataRendered}
      path={"/project/projects"}
    />
  );
};

export default ProjectsTable;
