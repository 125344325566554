import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { IDefaultForm, ITab } from "../../../types/global";
import {
  GoodsReceiveQuery,
  WarehousesQuery,
} from "../../../generated/inventory";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DocumentInfoTab from "./DocumentInfoTab";
import BarcodeTab from "./BarcodeTab";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { IGoodsReceive } from "../../../types/Inventory/goodsReceive";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
} from "react-hook-form";

type Props = IDefaultForm & {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GoodsReceiveQuery, unknown>>;
  data?: GoodsReceiveQuery;
  warehouseData?: WarehousesQuery;
  step: number;
  itemIds: string[];
  setItemIds: Dispatch<SetStateAction<string[]>>;
  itemIdsSnapshot: string[];
  setItemIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  confirmation: boolean;
  closeConfirmationHandler: () => void;
  submitConfirmationHandler: () => void;
  renderButton: () => JSX.Element | null;
  append: UseFieldArrayAppend<IGoodsReceive, "trace_entry_list">;
  fields: FieldArrayWithId<IGoodsReceive, "trace_entry_list", "id">[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsReceive, "trace_entry_list">;
  replace: UseFieldArrayReplace<IGoodsReceive, "trace_entry_list">;
};
const GoodsReceiveMobile = ({
  control,
  getValues,
  setValue,
  disabled,
  refetch,
  reset,
  step,
  errors,
  data,
  warehouseData,
  itemIds,
  setItemIds,
  itemIdsSnapshot,
  setItemIdsSnapshot,
  confirmation,
  closeConfirmationHandler,
  submitConfirmationHandler,
  renderButton,
  append,
  fields,
  remove,
  update,
  replace,
}: Props) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const { pathname } = useLocation();

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs: ITab[] = [
    {
      label: "ข้อมูลนำเข้า",
      path: `${pathname}`,
    },
    {
      label: "สแกน Barcode",
      path: `${pathname}?tab=barcode`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "barcode":
        setCurrentTab(pathname + "?tab=barcode");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "barcode":
        return (
          <BarcodeTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            step={step}
            data={data}
            reset={reset}
            warehouseData={warehouseData}
            itemIds={itemIds}
            itemIdsSnapshot={itemIdsSnapshot}
            setItemIdsSnapshot={setItemIdsSnapshot}
            setItemIds={setItemIds}
            confirmation={confirmation}
            closeConfirmationHandler={closeConfirmationHandler}
            submitConfirmationHandler={submitConfirmationHandler}
            renderButton={renderButton}
            fields={fields}
            remove={remove}
            replace={replace}
            update={update}
          />
        );
      default:
        return (
          <DocumentInfoTab
            control={control}
            disabled={disabled}
            errors={errors}
            getValues={getValues}
            refetch={refetch}
            setValue={setValue}
            step={step}
            data={data}
            reset={reset}
            warehouseData={warehouseData}
            append={append}
          />
        );
    }
  };

  return (
    <form>
      <CustomizedTab tabs={tabs} currentTab={currentTab} centered />
      {renderTab(tab)}
    </form>
  );
};

export default GoodsReceiveMobile;
