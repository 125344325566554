import { useCallback, useEffect, useState } from "react";
import addressData from "../data/address.json";
import { Control, useWatch } from "react-hook-form";

export const useAddressOptions = (control: Control<any>) => {
  const [subDistrictOptions, setSubDistrictOptions] = useState<string[]>([]);
  const [districtOptions, setDistrictOptions] = useState<string[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<string[]>([]);
  const [searchSubDistrict, setSearchSubDistrict] = useState<
    string | null | undefined
  >("");
  const [searchDistrict, setSearchDistrict] = useState<
    string | null | undefined
  >("");
  const [searchProvince, setSearchProvince] = useState<
    string | null | undefined
  >("");

  const updateFilter = useCallback(() => {
    let filteredAddressData = addressData;
    if (searchSubDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.sub_district.includes(searchSubDistrict)
      );
    }
    if (searchDistrict) {
      filteredAddressData = addressData.filter((item) =>
        item.district.includes(searchDistrict)
      );
    }
    if (searchProvince) {
      filteredAddressData = addressData.filter((item) =>
        item.province.includes(searchProvince)
      );
    }
    let filteredProvinces: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredProvinces.includes(item.province)) {
        filteredProvinces = [...filteredProvinces, item.province];
      }
    });
    setProvinceOptions(filteredProvinces);

    let filteredDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredDistricts.includes(item.district)) {
        filteredDistricts = [...filteredDistricts, item.district];
      }
    });
    setDistrictOptions(filteredDistricts);

    let filteredSubDistricts: string[] = [];
    filteredAddressData.forEach((item) => {
      if (!filteredSubDistricts.includes(item.sub_district)) {
        filteredSubDistricts = [...filteredSubDistricts, item.sub_district];
      }
    });
    setSubDistrictOptions(filteredSubDistricts);
  }, [searchDistrict, searchProvince, searchSubDistrict]);

  useEffect(() => {
    updateFilter();
  }, [updateFilter]);

  const watchFieldArray = useWatch({
    control,
    name: "customer_contact.delivery_address",
  });

  const isSameAddress = watchFieldArray?.is_same_as_default_address;

  return {
    subDistrictOptions,
    setSubDistrictOptions,
    districtOptions,
    setDistrictOptions,
    provinceOptions,
    setProvinceOptions,
    searchSubDistrict,
    setSearchSubDistrict,
    searchDistrict,
    setSearchDistrict,
    searchProvince,
    setSearchProvince,
    updateFilter,
    isSameAddress,
  };
};
