import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import ControlledTextField from "../../../../Controller/ControlledTextField";

import { useDisable } from "../../../../../hooks/use-disable";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

const RoutingInformation = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Fragment>
      <Typography fontWeight="bold">
        {t("manufacture.workOrder.detail")}
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            control={control}
            name="name"
            label={t("manufacture.routing.name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message?.toString()}
            disabled={Boolean(id) || disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("manufacture.workOrder.description")}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default RoutingInformation;
