import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import VillaOutlinedIcon from "@mui/icons-material/VillaOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";

const InventorySetting = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.inventory.warehouse.index"),
      to: "/setting/inventory/warehouse",
      icon: <VillaOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.category.level_one"),
      to: "/setting/inventory/category/level_one",
      icon: <GridViewOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.category.level_two"),
      to: "/setting/inventory/category/level_two",
      icon: <GridViewOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.category.level_three"),
      to: "/setting/inventory/category/level_three",
      icon: <GridViewOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.uom"),
      to: "/setting/inventory/uom",
      icon: <WorkspacesOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.attribute"),
      to: "/setting/inventory/attribute",
      icon: <FeaturedPlayListOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default InventorySetting;
