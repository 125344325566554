import { useEffect, useState } from "react";
import liff from "@line/liff";
import VConsole from "vconsole";
import jwtDecode from "jwt-decode";
import { useStateContext } from "../contexts/auth-context";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";
import { ILoginByLine } from "../.../../types/Auth/loginByLine";
import { QrReader } from "react-qr-reader";
import { Cookies, useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LineConnectMutation,
  LineDisconnectMutation,
  LoginByLineMutation,
  LoginMutation,
  useLineConnectMutation,
  useLineDisconnectMutation,
  useLoginByLineMutation,
  useLoginMutation,
} from "../generated/company-user";
import Login from "./Login";
import { IToken } from "../types/Auth/token";
import jwt from "jwt-decode";
import dayjs from "dayjs";
import { IUser } from "../types/Auth/user";
import { defaultPermission } from "../utils/userInfo";
import { enqueueSnackbar } from "notistack";
import LoadingUI from "../components/UI/LoadingUI";
import LiffSettingForm from "../components/Form/Liff/LiffSettingForm";

interface Profile {
  userId: string;
  pictureUrl?: string;
  displayName: string;
  statusMessage?: string;
  email?: string;
  idToken: string | null;
}

const Liff = () => {
  // Initialize VConsole for debugging
  new VConsole();

  const [step, setStep] = useState(1);
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [isFriend, setIsFriend] = useState<boolean | undefined>(undefined);
  const [userCheckMsg, setUserCheckMsg] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [isGetFriendshipStatusLoading, setIsGetFriendshipStatusLoading] =
    useState(false);
  const [isQRCodeLoading, setIsQRCodeLoading] = useState(false);

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const stateContext = useStateContext();
  const location = useLocation();
  const from = ((location.state as any)?.from.pathname as string) || "/landing";

  const [, setCookie, removeCookie] = useCookies([
    "access_token",
    "refresh_token",
    "logged_in",
  ]);

  const graphQLClient: GraphQLClient = createGraphQLClientWithMiddleware(
    "company-user",
    true
  );

  const navigate = useNavigate();
  const { dispatch } = useStateContext();

  const {
    isLoading: isLineConnectLoading,
    mutate: lineConnect,
    mutateAsync: lineConnectAsync,
  } = useLineConnectMutation<Error>(graphQLClient, {
    onSuccess(data: LineConnectMutation) {
      console.log("Connect succeeded:", data);
      enqueueSnackbar("เชื่อมต่อสำเร็จ", {
        variant: "success",
      });
    },
    onError(err) {
      console.log("Connect failed:", err);
      enqueueSnackbar("เชื่อมต่อไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const {
    isLoading: isLineDisconnectLoading,
    mutate: lineDisconnect,
    mutateAsync: lineDisconnectAsync,
  } = useLineDisconnectMutation<Error>(graphQLClient, {
    onSuccess(data: LineDisconnectMutation) {
      console.log("Disconnect succeeded:", data);
      enqueueSnackbar("ยกเลิกการเชื่อมต่อสำเร็จ", {
        variant: "success",
      });
    },
    onError(err) {
      console.log("Disconnect failed:", err);
      enqueueSnackbar("ยกเลิกการเชื่อมต่อไม่สำเร็จ", {
        variant: "error",
      });
    },
  });

  const {
    isLoading: isLineLoginLoading,
    mutate: loginByLine,
    mutateAsync: loginByLineAsync,
  } = useLoginByLineMutation<Error>(graphQLClient, {
    onSuccess(data: LoginByLineMutation) {
      console.log("Login by LINE succeeded:", data);
      if (data.loginByLine) {
        console.log("Setting access token");
        const accessToken = data.loginByLine.access_token;
        const refreshToken = data.loginByLine.refresh_token;

        const { exp: accessMaxAge } = jwt<IToken>(accessToken);
        const { exp: refreshMaxAge } = jwt<IToken>(refreshToken);

        setCookie("access_token", accessToken, {
          path: "/",
          maxAge: accessMaxAge,
        });
        setCookie("refresh_token", refreshToken, {
          path: "/",
          maxAge: refreshMaxAge,
        });
        setCookie("logged_in", "true", {
          path: "/",
        });

        if (data.loginByLine.user.company_list) {
          if (data.loginByLine.user.company_list.length === 1) {
            sessionStorage.setItem(
              "tenant-id",
              data.loginByLine.user.company_list[0]?.unique_id || ""
            );
            const is_expire = dayjs(
              data.loginByLine.user.company_list[0]?.expired_date
            ).isBefore(dayjs());

            const companyInfo = {
              is_expired: is_expire,
              expired_date: data.loginByLine.user.company_list[0]?.expired_date,
              subscription_level_id:
                data.loginByLine.user.company_list[0]?.subscription_level_id,
              resubscription_date:
                data.loginByLine.user.company_list[0]?.resubscription_date,
              register_date:
                data.loginByLine.user.company_list[0]?.register_date,
            };

            sessionStorage.setItem("company-info", JSON.stringify(companyInfo));

            //need to change register_date to expired_date after fix
            if (is_expire) {
              navigate("/setting/package");
            } else navigate(from);

            navigate(from);
          } else {
            navigate("/select_company");
          }
        }
        stateContext.dispatch({
          type: "SET_USER",
          payload: data.loginByLine.user as IUser,
        });
        stateContext.dispatch({
          type: "SET_PERMISSION",
          payload: defaultPermission,
        });

        graphQLClient.setHeader("authorization", `Bearer ${accessToken}`);
      }
      enqueueSnackbar("ล็อกอินโดยใช้ LINE สำเร็จ", {
        variant: "success",
      });
    },
    onError(error: any) {
      error.response.errors.forEach((err: any) => {
        console.log("Login by LINE failed:", err);
        enqueueSnackbar("ล็อกอินโดยใช้ LINE ไม่สำเร็จ", {
          variant: "error",
        });
      });
    },
  });

  const handleGetFriendshipStatus = async () => {
    setIsGetFriendshipStatusLoading(true);
    try {
      const friend = await liff.getFriendship();
      setIsFriend(friend.friendFlag);
      if (friend.friendFlag) {
        setStep(3);
      }
    } catch (error) {
      console.error("Failed to get friendship status", error);
    } finally {
      setIsGetFriendshipStatusLoading(false);
    }
  };

  const handleScanQR = () => {
    liff
      .scanCodeV2()
      .then(async (result) => {
        setUserCheckMsg("");
        try {
          // Get User data
          setIsQRCodeLoading(true);

          const accessToken = result.value!;
          const decoded: any = jwtDecode(accessToken);
          console.log("JWT Decoded Successfully:", decoded);

          // Check Expiration
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decoded.exp < currentTime) {
            setUserCheckMsg("QR code expired");
            console.log("QR code expired");
            return;
          }

          if (typeof profile === "undefined") {
            setUserCheckMsg("Profile is undefined");
            console.log("Profile is undefined");
            return;
          }

          if (profile.idToken === null) {
            setUserCheckMsg("ID Token is null");
            console.log("ID Token is null");
            return;
          }

          const { exp: accessMaxAge } = jwt<IToken>(accessToken);

          // Set access token (for Line connect)
          setCookie("access_token", accessToken, {
            path: "/",
            maxAge: accessMaxAge,
          });
          console.log(accessToken);
          graphQLClient.setHeader("authorization", `Bearer ${accessToken}`);

          // Connect to line (Verify token)
          await lineConnectAsync(
            { lineIdToken: profile.idToken },
            {
              async onSuccess(data, variables, context) {
                await loginByLineAsync({ lineIdToken: profile.idToken ?? "" });
              },
            }
          );
          console.log("Navigating to LIFF");
          // Success navigate to /liff
          navigate("/", { replace: true });
        } catch (error) {
          console.error("Error reading QR Code:", (error as Error).message);
          setUserCheckMsg("Error reading QR Code please try again");
        } finally {
          setIsQRCodeLoading(false);
          setUserCheckMsg("QR code scanned successfully");
        }
      })
      .catch((error) => {
        console.error("Error scanning QR code:", error);
        setUserCheckMsg("Failed to scan QR code please try again");
        setIsQRCodeLoading(false);
      });
  };

  const handleDisconnect = async () => {
    try {
      await lineDisconnectAsync(
        {},
        {
          onSuccess(data, variables, context) {
            removeCookie("access_token", {
              path: "/",
            });
            removeCookie("refresh_token", {
              path: "/",
            });
            setCookie("logged_in", "false", {
              path: "/",
            });
            sessionStorage.removeItem("tenant-id");
            sessionStorage.removeItem("company-info");
            dispatch({
              type: "SET_USER",
              payload: null,
            });
            dispatch({
              type: "SET_PERMISSION",
              payload: null,
            });
            navigate("/", { replace: true });
          },
        }
      );
    } catch (error) {}
    return;
  };

  const logoutLiff = async () => {
    try {
      await liff.logout();
      console.log("User logged out from LIFF");
      // Optionally, perform any additional actions upon successful logout
    } catch (error) {
      console.error(
        "Error logging out user from LIFF:",
        (error as Error).message
      );
      // Optionally, handle any errors that occur during the logout process
    }
  };

  const logoutHandler = () => {
    removeCookie("access_token", {
      path: "/",
    });
    removeCookie("refresh_token", {
      path: "/",
    });
    setCookie("logged_in", "false", {
      path: "/",
    });
    sessionStorage.removeItem("tenant-id");
    sessionStorage.removeItem("company-info");
    dispatch({
      type: "SET_USER",
      payload: null,
    });
    dispatch({
      type: "SET_PERMISSION",
      payload: null,
    });
    navigate("/", { replace: true });
  };

  // Initialize LIFF
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        console.log("Starting LIFF initialization...");

        const originalFetch = window.fetch;
        function customFetch(
          url: RequestInfo | URL,
          options?: RequestInit
        ): Promise<Response> {
          if (
            url.toString().startsWith("https://liffsdk.line-scdn.net/xlt/") &&
            url.toString().endsWith(".json")
          ) {
            url = url + "?ts=" + Math.random();
          }
          return originalFetch(url, options);
        }
        window.fetch = customFetch;

        await liff.init({ liffId: "2005495782-nODRbPEa" });
        console.log("LIFF initialized successfully");

        if (liff.isLoggedIn()) {
          const userProfile = await liff.getProfile();
          const idToken = liff.getIDToken();
          if (idToken === null) {
            throw new Error("idToken is null");
          }
          const email = liff.getDecodedIDToken()?.email || undefined;

          const myProfile: Profile = {
            userId: userProfile.userId,
            pictureUrl: userProfile.pictureUrl,
            displayName: userProfile.displayName,
            statusMessage: userProfile.statusMessage,
            email: email,
            idToken: idToken,
          };

          const urlParams = new URLSearchParams(window.location.search);
          console.log(urlParams);
          setRedirect(urlParams.get("red"));

          if (!authUser) {
            console.log("Trying to log in by LINE");
            try {
              await loginByLineAsync(
                { lineIdToken: idToken },
                {
                  onSuccess(data, variables, context) {
                    graphQLClient.setHeader(
                      "authorization",
                      `Bearer ${data.loginByLine.access_token}`
                    );

                    // Success navigate to /liff
                    console.log("Navigating to LIFF");
                    navigate("/", { replace: true });
                  },
                }
              );
            } catch (error) {
              enqueueSnackbar("กรุณาเชื่อมต่อบัญชีกับ LINE", {
                variant: "error",
              });
            }
          }
          setProfile(myProfile);
          setIsLoading(false);
          setStep(2);
        } else {
          console.log("User is not logged in, redirecting to login...");
          // liff.login({
          //   redirectUri: window.location.href,
          // });
          liff.login();
        }
      } catch (error) {
        console.error("LIFF initialization failed", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (liff.isInClient()) {
      console.log("Detected LIFF client");
      initializeLiff();
    } else {
      console.log("Detected web browser");
      // await initializeLiff();
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (step === 2 && isFriend === undefined) {
      handleGetFriendshipStatus();
    }
  }, [step]);

  return (
    <div>
      <h1>LINE LIFF</h1>
      <div>
        <a href="/login">Login</a>
      </div>
      <div>
        <a href="/">Home</a>
      </div>
      <div>Redirect: {redirect}</div>
      <button onClick={logoutLiff}>Logout LINE</button>
      {/* <div>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result) => {
            if (!!result) {
              console.log("result QR", result.getText());
              // setScanData(result.getText());
              // setIsInterval(true);
              // setBarcodeValue("barcodeMobile", result.getText());
            }
          }}
          scanDelay={2000}
          containerStyle={{
            width: "calc(100vw-48px)",
            height: "100%",
            contentVisibility: "auto",
          }}
          videoContainerStyle={{
            width: "calc(100vw-48px)",
            height: "100%",
            contentVisibility: "auto",
          }}
          videoStyle={{
            height: "100%",
            contentVisibility: "auto",
          }}
        />
      </div> */}
      {step >= 1 && (
        <>
          <h2>STEP 1: Line login</h2>
          {isLoading ? (
            <LoadingUI />
          ) : (
            <>
              {profile ? (
                <div>
                  <p>User ID: {profile.userId}</p>
                  <p>Display Name: {profile.displayName}</p>
                  {profile.pictureUrl && (
                    <img src={profile.pictureUrl} alt="Profile" width="150" />
                  )}
                  {profile.statusMessage && (
                    <p>Status: {profile.statusMessage}</p>
                  )}
                  {profile.email && <p>Email: {profile.email}</p>}
                  {profile.idToken && <p>ID Token: {profile.idToken}</p>}
                </div>
              ) : (
                <div>ไม่สามารถดึงข้อมูลได้</div>
              )}
            </>
          )}
        </>
      )}
      {step >= 2 && (
        <>
          <h2>STEP 2: Add friend</h2>
          {isGetFriendshipStatusLoading ? (
            <LoadingUI />
          ) : (
            <>
              {isFriend ? (
                <div>เพิ่มเพื่อนแล้ว</div>
              ) : (
                <div>
                  <button onClick={handleGetFriendshipStatus}>
                    ตรวจสอบอีกครั้ง
                  </button>
                  กรุณาเพิ่มเพื่อน
                  <a href="https://page.line.me/?accountId=517mdpec">
                    <img
                      src={"https://qr-official.line.me/sid/L/517mdpec.png"}
                      alt="Add Friend"
                      width={"150"}
                    />
                  </a>
                </div>
              )}
            </>
          )}
        </>
      )}

      {step >= 3 && (
        <>
          <h2>STEP 3: User</h2>
          {isLoading ? (
            <LoadingUI />
          ) : (
            <>
              {userCheckMsg && <p>{userCheckMsg}</p>}

              {authUser ? (
                <div>
                  <button onClick={logoutHandler}>Logout NPR</button>
                  <div>USER ID: {authUser.unique_id}</div>
                  <div>EMAIL: {authUser.email}</div>
                  <div>
                    NAME: {authUser.first_name} {authUser.last_name}
                  </div>
                  <pre>{JSON.stringify(authUser, null, 2)}</pre>
                  <button
                    onClick={handleDisconnect}
                    disabled={isLineDisconnectLoading}
                  >
                    {isLineDisconnectLoading
                      ? "กำลังดำเนินการ..."
                      : "ยกเลิกการเชื่อมต่อ"}
                  </button>
                  <div>
                    (คุณจะไม่ได้สามารถเข้าสู่ระบบด้วยไลน์ได้และจำเป็นจะต้องตั้งรหัสผ่านก่อนยกเลิก
                    และคุณจะไม่สามารถรับการแจ้งเตือนผ่านไลน์ได้)
                  </div>
                  {/*
                    SelectCompany
                  */}
                  {/* <div>ตั้งค่าการแจ้งเตือน</div>
                  <div>เปิด/ปิด</div> */}

                  <LiffSettingForm />
                </div>
              ) : isQRCodeLoading ? (
                <LoadingUI />
              ) : (
                <div>
                  <button onClick={handleScanQR}>Scan QR CODE</button>
                  <a
                    href="https://doc.clickup.com/3654689/p/h/3fh11-53362/db286604b2ae948/3fh11-83876"
                    target="_blank"
                  >
                    วิธีการเชื่อมต่อ
                  </a>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Liff;
