import { Fragment } from "react";
import { IDefaultForm } from "../../../types/global";
import AddressForm from "../Address";

const AddressTab = ({
  control,
  errors,
  setValue,
  getValues,
  disabled,
}: IDefaultForm) => {
  return (
    <Fragment>
      <AddressForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        errors={errors}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default AddressTab;
