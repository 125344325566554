import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ReportDateFilter from "../../../components/UI/ReportDateFilter";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { dateFilterModel } from "../../../utils/Formatter/AgGridFilter";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

import {
  ManufactureBomExportViewsAggridQuery,
  SortOrder,
  useManufactureBomExportViewsAggridQuery,
} from "../../../generated/manufacture-export";
import { IBom } from "../../../types/Manufacture/bom";
import ManufactureBomReportTable from "../../../components/Table/Manufacture/Report/Bom";
import { GENERATE_EXPORT } from "../../../services/AgGrid/ManufactureReportAgGrid";

const ManufactureBomReport = () => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<IBom>>(null);

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("manufacture.index"),
      to: "/manufacture",
    },
    {
      name: t("report"),
      to: "/manufacture/report",
    },
    {
      name: t("manufacture.bom.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "today",
      dateFrom: dayjs().startOf("day").subtract(6, "day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    },
  });

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBtnExport = async () => {
    try {
      setIsLoading(true);
      const filterModel = gridRef?.current?.api.getFilterModel() as any;
      const sortModel = gridRef?.current?.columnApi
        .getColumnState()
        .filter((s) => s.sort !== null)
        .map(({ sort, colId }) => ({
          sort,
          colId,
        }));

      const { end_date, created_date, start_date, ...otherFilter } =
        filterModel;
      const formatFilter = {
        ...otherFilter,
        created_date: dateFilterModel(created_date),
        end_date: dateFilterModel(end_date),
        start_date: start_date
          ? dateFilterModel(start_date)
          : dateFilterModel({
              filterType: "date",
              type: "inRange",
              dateFrom: dateFrom,
              dateTo: dateTo,
            }),
      };

      const { GenerateExport } =
        await graphQLClientWithHeaderManufacture.request(GENERATE_EXPORT, {
          input: {
            export_type: "manufac_bom_report",
            params: {
              aggridInput: {
                startRow: 0,
                endRow: Math.pow(10, 5),
                filterModel: formatFilter,
                sortModel:
                  sortModel?.length !== 0
                    ? sortModel
                    : [
                        {
                          sort: SortOrder.Desc,
                          colId: "start_date",
                        },
                      ],
              },
            },
          },
        });
      window.open(GenerateExport, "_blank");
      enqueueSnackbar("นำออกข้อมูลสำเร็จ", {
        variant: "success",
      });

      //   await graphQLClientWithHeaderManufacture.request(GENERATE_REPORT, {
      //     priority: 1,
      //     reportType: "trace_entry",
      //     params: {
      //       aggridInput: {
      //         startRow: 0,
      //         endRow: Math.pow(10, 5),
      //         filterModel: formatFilter,
      //         sortModel,
      //       },
      //     },
      //   });

      setIsLoading(false);
      //   enqueueSnackbar("คำขอสำเร็จ ระบบกำลังดำเนินการ", {
      //     variant: "success",
      //   });
    } catch (err) {
      enqueueSnackbar("คำขอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
      });
      // enqueueSnackbar("นำออกข้อมูลไม่สำเร็จ", {
      //   variant: "error",
      // });
    }
  };

  const { data, refetch } =
    useManufactureBomExportViewsAggridQuery<ManufactureBomExportViewsAggridQuery>(
      graphQLClientWithHeaderManufacture,
      {
        aggridInput: {
          startRow: 0,
          endRow: Math.pow(10, 5),
          filterModel: {
            start_date: {
              filterType: "date",
              type: "inRange",
              dateFrom: dateFrom,
              dateTo: dateTo,
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "start_date",
            },
          ],
        },
      },
      {
        cacheTime: 0,
        enabled: false,
      }
    );

  const getFilteredData = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const onFilterReset = async () => {
    if (gridRef && gridRef.current) {
      gridRef.current.api.setFilterModel({});
    }
    await reset({
      dateType: "today",
      dateFrom: dayjs().startOf("day").subtract(6, "day").toDate(),
      dateTo: dayjs().endOf("day").toDate(),
    });
    await refetch();
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("manufacture.bom.index")}</Typography>
        <CustomizedButton
          title={`${t("button.export")}`}
          variant="contained"
          onClick={onBtnExport}
          disabled={isLoading}
        />
      </HeaderLayout>
      <ReportDateFilter
        control={control}
        setValue={setValue}
        getValues={getValues}
        onFilterReset={onFilterReset}
        getFilteredData={getFilteredData}
      />
      <ManufactureBomReportTable
        gridRef={gridRef}
        rowData={data?.ManufactureBomExportViewsAggrid.results || []}
      />
    </>
  );
};

export default ManufactureBomReport;
