import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Box, Grid, Typography } from "@mui/material";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import GoodsReturnTable from "../../../components/Table/Inventory/GoodsReturn";
import TotalBox from "../../../components/UI/TotalBox";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { IGoodsReturn } from "../../../types/Inventory/goodsReturn";

const GoodsReturn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const gridRef = useRef<AgGridReact<IGoodsReturn>>(null);

  const [rowCount, setRowCount] = useState<number>(0);

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.goods_return.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("status.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.finished"),
      path: `${pathname}?filter=finished`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const clearAllFilter = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
    if (searchParams.has("filter")) {
      searchParams.delete("filter");
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2.4}>
          <TotalBox title="ทั้งหมด" value={rowCount} borderColor="#4830DE" />
        </Grid>
      </Grid>
      <HeaderLayout>
        <Typography variant="h5">
          {t("inventory.goods_return.index")}
        </Typography>
        <Box display="flex" gap={1}>
          <CustomizedButton
            title={`${t("button.create")}${t("inventory.goods_return.index")}`}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
          <CustomizedButton
            title={`ล้างตัวกรอง`}
            variant="outlined"
            onClick={clearAllFilter}
            sx={{ height: "100%" }}
          />
        </Box>
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
        <CustomizedButton
          title={`ล้างตัวกรอง`}
          variant="outlined"
          onClick={clearAllFilter}
          sx={{ height: "100%" }}
        />
      </Box> */}
      <GoodsReturnTable
        gridRef={gridRef}
        isFilter={isFilter}
        setRowCount={setRowCount}
      />
    </>
  );
};

export default GoodsReturn;
