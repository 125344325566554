import { Box, Typography } from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledServerSideCreatable from "../../../Controller/ControlledSSCreatable";
import { IDefaultForm, IRadio } from "../../../../types/global";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import { EntityTypeEnum } from "../../../../generated/creatable";

const radioLists: IRadio[] = [
  {
    label: "มีผล",
    value: true,
  },
  {
    label: "ไม่มีผล",
    value: false,
  },
];

const ReasonForm = ({ control, errors, disabled, setValue }: IDefaultForm) => {
  const { t } = useTranslation();
  return (
    <CustomizedBox margin="0 0 2rem 0">
      <Typography ml={1} fontWeight="bold">
        {t("sales.return.reason_to_return")}
      </Typography>
      <Box width={300} my={2}>
        <ControlledServerSideCreatable
          control={control}
          error={Boolean(errors && errors.reason_to_return)}
          defaultOptions={[]}
          documentType={EntityTypeEnum.SalesReturn}
          name="reason_to_return"
          setValue={setValue}
          disabled={disabled}
          placeholder={t("sales.return.reason_to_return")}
        />
      </Box>
      <Typography ml={1} mb={1} fontWeight="bold">
        {t("sales.return.is_effect_stock")}
      </Typography>
      <ControlledRadioGroup
        control={control}
        name="is_effect_stock"
        disabled={disabled}
        radioLists={radioLists}
        row
      />
    </CustomizedBox>
  );
};

export default ReasonForm;
