import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgGrid from "../../../components/UI/AgGrid";

import {
  GridReadyEvent,
  IServerSideDatasource,
  RowDoubleClickedEvent,
} from "ag-grid-community";

import { rbacColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { ROLES_AGGRID } from "../../../services/AgGrid/UserAgGrid";

const RbacTable = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const columnDefs = rbacColumnDefs(t);

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      try {
        const { RoleViewAggrid } = await graphQLClient.request(ROLES_AGGRID, {
          aggridInput: {
            startRow,
            endRow,
            filterModel,
            sortModel,
          },
        });
        params.success({
          rowData: RoleViewAggrid.data as any[],
          rowCount: RoleViewAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
  };

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(`${pathname}/${params.data.id}?subtab=inventory`);
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      path={"/user/rbac"}
      onGridReady={onGridReady}
    />
  );
};

export default RbacTable;
