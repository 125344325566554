import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../components/Custom/CustomizedMenuList";
import { IBreadcrumbsAndMenu } from "../../types/global";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

const Setting = () => {
  const { t } = useTranslation();
  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.company.index"),
      to: "/setting/company",
      icon: <StorefrontOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.approval.index"),
      to: "/setting/approval",
      icon: <FactCheckOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.remark.index"),
      to: "/setting/remark",
      icon: <PushPinOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
      icon: <HolidayVillageOutlinedIcon color="primary" />,
    },
    {
      name: t("setting.logistic.index"),
      to: "/setting/logistic",
      icon: <LocalShippingOutlinedIcon color="primary" />,
    },
    // {
    //   name: t("setting.manufacture.index"),
    //   to: "/setting/manufacture",
    // },
    {
      name: t("setting.project.status"),
      to: "/setting/project-status",
      icon: <CorporateFareOutlinedIcon color="primary" />,
    },
  ];

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </>
  );
};

export default Setting;
