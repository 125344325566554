import * as Yup from "yup";
import { characterRegExp } from "../../../utils/Formatter/Global";

export const categorySchema = {
  unique_id: "",
  name: "",
};

export const categoryValidation = Yup.object().shape({
  name: Yup.string()
    .required("กรุณาระบุชื่อหมวดหมู่")
    .matches(characterRegExp, "ห้ามมีอักขระพิเศษ"),
});
