import CustomizedAvatar from "../../Custom/CustomizedAvatar";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { Box } from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { TFunction } from "i18next";
import { IContact } from "../../../types/Contact";
import { IContactChannel } from "../../../types/global";
import CustomizedChips from "../../Custom/CustomizedChips";
import { ContactTagsFindByEntityQuery } from "../../../generated/contact";
interface Props {
  t: TFunction;
  isReport?: boolean;
  allTag: ContactTagsFindByEntityQuery["contactTagsFindByEntity"];
  isContactPage?: boolean;
}

export const contactColumnDefs = (
  t: Props["t"],
  isReport: Props["isReport"],
  allTag: Props["allTag"],
  isContactPage?: Props["isContactPage"]
): ColDef[] => {
  const contactChannelAction = (type: string, info: string) => {
    switch (type) {
      case "เบอร์โทรศัพท์":
      case "แฟกซ์":
        window.open(`tel:${info}`, "_self");
        return;
      case "Email":
        window.open("mailto:" + info, "_self");
        return;
      case "Line":
        window.open(
          "https://line.me/R/ti/p/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Facebook":
        window.open(
          "https://facebook.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      case "Website":
        window.open(`https://${info}`, "_blank", "noopener,noreferrer");
        return;
      case "Instagram":
        window.open(
          "https://instagram.com/" + info,
          "_blank",
          "noopener,noreferrer"
        );
        return;
      default:
        return;
    }
  };

  return [
    {
      field: "unique_id",
      headerName: t("contact.document_id"),
      filter: "agTextColumnFilter",
      width: isContactPage ? 140 : undefined,
    },
    {
      field: "contact_type_detail",
      headerName: t("contact.info.business_type"),
      filter: "agTextColumnFilter",
      width: isContactPage ? 150 : undefined,
    },
    {
      headerName: t("contact.contact_type.index"),
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      cellRenderer: ({ data }: { data: IContact }) => {
        if (data.is_vendor && data.is_customer) {
          return "ลูกค้า, ผู้ขาย";
        }
        if (data.is_vendor) {
          return "ผู้ขาย";
        }
        if (data.is_customer) {
          return "ลูกค้า";
        }
        return "";
      },
      width: isContactPage ? 140 : undefined,
    },
    {
      field: "is_vendor",
      headerName: t("contact.contact_type.index"),
      filter: "agTextColumnFilter",
      suppressColumnsToolPanel: true,
      hide: true,
      cellRenderer: ({ data }: { data: IContact }) => {
        if (data.is_vendor && data.is_customer) {
          return "ลูกค้า, ผู้ขาย";
        }
        if (data.is_vendor) {
          return "ผู้ขาย";
        }
        if (data.is_customer) {
          return "ลูกค้า";
        }
        return "";
      },
    },
    {
      field: "is_customer",
      headerName: t("contact.contact_type.index"),
      filter: "agTextColumnFilter",
      filterParams: {
        textMatcher: ({
          data,
          filterText,
        }: {
          data: IContact;
          filterText: string;
        }) => {
          return data.is_customer.toString() === filterText;
        },
      },
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: "img_url",
      headerName: t("contact.img_url"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams<IContact, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              avatars={params.value.map((v) => ({
                img_url: v,
                unique_id: params?.data?.unique_id ?? "",
                first_name: params?.data?.main_contact_name ?? "",
                last_name: params?.data?.secondary_contact_name ?? "",
              }))}
            />
          );
        } else
          return (
            <CustomizedAvatar
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.unique_id ?? "",
                  first_name: params?.data?.main_contact_name ?? "",
                  last_name: params?.data?.secondary_contact_name ?? "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
      headerClass: "ag-center-header",
    },
    {
      field: "main_contact_name",
      headerName: "ชื่อกิจการ/ชื่อ นามสกุล",
      cellRenderer: ({ data }: { data: IContact }) => {
        if (data.main_contact_name === "นิติบุคคล") {
          return data.main_contact_name;
        }
        return `${data.main_contact_name} ${data.secondary_contact_name ?? ""}`;
      },
      filter: "agTextColumnFilter",
      width: isContactPage ? 200 : undefined,
    },
    {
      field: "created_date",
      headerName: t("sales.createdDate"),
      sort: "desc",
      hide: true,
      filter: "agDateColumnFilter",
      // filterParams: {
      //   filterOptions: filterParamsOptions("date"),
      //   comparator: isReport ? dateComparator : undefined,
      // },
      // valueFormatter: (params) =>
      //   unixToDateWithFormat(params.data.created_date),
      width: 200,
    },
    {
      field: "contact_channel_list",
      headerName: t("contact.contact_channel.index"),
      filter: false,
      filterParams: {
        textMatcher: ({
          data,
          filterText,
        }: {
          data: IContact;
          filterText: string;
        }) => {
          const includesText = data.contact_channel_list?.some((channel) => {
            return (
              channel.contact_channel_type.includes(filterText) ||
              channel.contact_channel_name.includes(filterText)
            );
          });
          return includesText;
        },
      },
      cellRenderer: ({ value }: { value: IContactChannel[] }) => {
        return value.map((channel, index) => (
          <Box
            sx={{ display: "inline-block", mr: 1 }}
            key={`${channel.contact_channel_name}-${index}`}
          >
            <CustomizedChips
              onClick={() =>
                contactChannelAction(
                  channel.contact_channel_type,
                  channel.contact_channel_name
                )
              }
              value={`${channel.contact_channel_type}: ${channel.contact_channel_name}`}
            />
          </Box>
        ));
      },
      width: isContactPage ? 150 : undefined,
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.group_tag_list"),
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params: any) => {
          // fetch values from server
          const values = allTag;
          const formatValues = values.map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value)
          return params.value?.map((tag: { name: string }) => (
            <Box sx={{ display: "inline-block", mr: 1, gap: 1 }}>
              <CustomizedChips key={tag.name} value={`${tag.name}`} />
            </Box>
          ));
      },
      width: isContactPage ? 135 : undefined,
    },
    {
      field: "is_active",
      headerName: t("contact.contact_status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["1", "0"],
        valueFormatter: ({ value }: { value: string }) => {
          if (value === "1") {
            return "ใช้งาน";
          }
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => (
        <CustomizedStatus status={value ? "active" : "inactive"} />
      ),
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      headerClass: "ag-center-header",
    },
  ];
};
