import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import { CustomizedBox } from "../../Custom/CustomizedBox";
import ControlledTextField from "../../Controller/ControlledTextField";
import { IRole } from "../../../types/Auth/user";
import { useDisable } from "../../../hooks/use-disable";

const RbacInformation = () => {
  const { t } = useTranslation();
  const [disabled] = useDisable();

  const {
    control,
    formState: { errors },
  } = useFormContext<IRole>();

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Typography mb={2} variant="h6">
        {t("sentence.detail")}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            control={control}
            name="name"
            label={t("user.rbac.name")}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ControlledTextField
            control={control}
            name="description"
            label={t("user.rbac.description")}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </CustomizedBox>
  );
};

export default RbacInformation;
