import { Fragment } from "react";
import { Controller } from "react-hook-form";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import { IDefaultForm } from "../../../types/global";
import { Grid, Typography } from "@mui/material";

type ExtendedProps = IDefaultForm & {
  isContactPage: boolean;
};

const ContactRemarkForm = ({
  control,
  errors,
  isContactPage,
  disabled,
}: ExtendedProps) => {
  return (
    <Fragment>
      <CustomizedBox
        boxShadow={!isContactPage ? "none" : undefined}
        margin={!isContactPage ? 0 : "2rem 0 4rem 0"}
      >
        <Typography fontWeight="bold" marginBottom={1} marginLeft={1}>
          หมายเหตุ
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  fullWidth
                  multiline
                  rows={4}
                  error={Boolean(errors.remark)}
                  helperText={errors.remark?.message}
                  {...field}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
        </Grid>
      </CustomizedBox>
    </Fragment>
  );
};

export default ContactRemarkForm;
