import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import CustomizedTab from "../../Custom/CustomizedTab";
import ConfigPermission from "./Config";
import ContactPermission from "./Contact";
import InventoryPermission from "./Inventory";
import LogisticPermission from "./Logistic";
import ManufacturePermission from "./Manufacture";
import PurchasePermission from "./Purchase";
import SalesPermission from "./Sales";
import UserPermission from "./User";
import ProjectPermission from "./Project";
import { PermissionViewType } from "../../../generated/rbac";
import ControlledSelect from "../../Controller/ControlledSelect";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";

type ExtendProps = {
  disabled: boolean;
};

const inventoryFeature = [
  "item",
  "goods_receive",
  "goods_return",
  "goods_issue",
  "goods_transfer",
  "goods_adjustment",
  "inventory_report",
];

const salesFeature = [
  "quotation",
  "sales_order",
  "sales_return",
  "sales_report",
];

const purchaseFeature = [
  "purchase_request",
  "purchase_order",
  "purchase_return",
  "purchase_report",
];

const manufactureFeature = [
  "bom",
  "routing",
  "manufacture_order",
  "manufacture_report",
];

const logisticFeature = ["delivery_trip", "delivery_order", "logistic_report"];

const contactFeature = ["customer", "vendor", "contact_report"];

const projectFeature = ["projects", "event"];

const PermissionForm = ({ disabled }: ExtendProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const subtab = searchParams.get("subtab");

  const { control, getValues, setValue, watch } = useFormContext();

  // const { createPermission, editPermission } = usePermission();

  const [columnDefs, setColumnDefs] = useState<any[]>([]);

  const tabs = [
    {
      label: t("inventory.index"),
      path: `${pathname}?subtab=inventory`,
    },
    {
      label: t("contact.index"),
      path: `${pathname}?subtab=contact`,
    },
    {
      label: t("sales.index"),
      path: `${pathname}?subtab=sales`,
    },
    {
      label: t("purchase.index"),
      path: `${pathname}?subtab=purchase`,
    },
    {
      label: t("logistic.index"),
      path: `${pathname}?subtab=logistic`,
    },
    {
      label: t("project.index"),
      path: `${pathname}?subtab=project`,
    },
    {
      label: t("manufacture.index"),
      path: `${pathname}?subtab=manufacture`,
    },
    {
      label: t("user.index"),
      path: `${pathname}?subtab=user`,
    },
    {
      label: t("setting.index"),
      path: `${pathname}?subtab=config`,
    },
  ];

  const [showSelect, setShowSelect] = useState(false);

  const [defaultOptions] = useState([
    {
      id: 1,
      label: "ทั้งหมด",
      value: "ALL",
    },
    {
      id: 2,
      label: "เฉพาะข้อมูลตนเอง",
      value: "SELF",
    },
    {
      id: 3,
      label: "ปิด",
      value: "NONE",
    },
  ]);

  const [featureOptions] = useState([
    {
      id: 1,
      label: "เปิด",
      value: "ALLOW",
    },
    {
      id: 2,
      label: "ปิด",
      value: "DENY",
    },
  ]);

  const denyFeatureHandler = useCallback(
    (featureName: string, changeTo: boolean) => {
      let modules: string[] = [];
      switch (featureName) {
        case "inventory":
          modules = inventoryFeature;
          break;
        case "purchase":
          modules = purchaseFeature;
          break;
        case "sales":
          modules = salesFeature;
          break;
        case "contact":
          modules = contactFeature;
          break;
        case "manufacture":
          modules = manufactureFeature;
          break;
        case "delivery":
          modules = logisticFeature;
          break;
        case "project":
          modules = projectFeature;
          break;
        default:
          modules = inventoryFeature;
          break;
      }
      for (const module of modules) {
        const moduleValue = getValues(`permission_list.${module}`);
        for (const key in moduleValue) {
          if (typeof moduleValue[key] === "string") {
            setValue(
              `permission_list.${module}.${key}`,
              changeTo ? PermissionViewType.All : PermissionViewType.None
            );
          } else {
            setValue(`permission_list.${module}[${key}]`, changeTo);
          }
        }
      }
    },
    [getValues, setValue]
  );

  const RenderCustomizedSelect = useCallback(
    ({
      params,
      name,
      disabled,
    }: {
      params: any;
      name: string;
      disabled: boolean;
    }) => {
      const formatName = `permission_list.${params.data.functionName}.${name}`;

      let disabledFeature = false;

      if (params.data.feature) {
        const isFeatureDeny =
          watch(`permission_list.${params.data.feature}.${name}`) === "DENY";

        disabledFeature = isFeatureDeny;
      }

      setShowSelect(true);

      return showSelect ? (
        <ControlledSelect
          control={control}
          name={formatName}
          options={
            params.data.isNotViewSelf
              ? [defaultOptions[0], defaultOptions[2]]
              : params.data.isFeature
              ? featureOptions
              : defaultOptions
          }
          onChange={(e: any) => {
            if (params.data.isFeature)
              denyFeatureHandler(
                params.data.functionName,
                e.target.value === "ALLOW"
              );
          }}
          // errors={`${errors}.${formatName}`}
          disabled={disabled || disabledFeature}
        />
      ) : (
        <></>
      );
    },
    [
      control,
      defaultOptions,
      denyFeatureHandler,
      featureOptions,
      showSelect,
      watch,
    ]
  );

  useEffect(() => {
    switch (subtab) {
      case "inventory":
      case "manufacture":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => (
              <RenderCustomizedSelect
                params={params}
                disabled={disabled}
                name="view"
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
        ]);
        break;
      case "contact":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                disabled={disabled}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
        ]);
        break;
      case "sales":
      case "purchase":
      case "logistic":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                disabled={disabled}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
        ]);
        break;
      case "user":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                disabled={disabled}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
        ]);
        break;
      case "config":
        setColumnDefs([
          {
            field: "name",
            headerName: "รายการ",
            filter: "agTextColumnFilter",
          },
          {
            field: "view",
            headerName: "ดู",
            filter: "agTextColumnFilter",
            cellRenderer: (params: any) => (
              <RenderCustomizedSelect
                params={params}
                name="view"
                disabled={disabled}
              />
            ),
            cellStyle: {
              display: "block",
              paddingTop: "3px",
            },
          },
        ]);
        break;
      default:
        return;
    }
  }, [RenderCustomizedSelect, disabled, subtab]);

  const renderTab = (subtab: string) => {
    switch (subtab) {
      case "inventory":
        return <InventoryPermission columnDefs={columnDefs} />;
      case "contact":
        return <ContactPermission columnDefs={columnDefs} />;
      case "sales":
        return <SalesPermission columnDefs={columnDefs} />;
      case "purchase":
        return <PurchasePermission columnDefs={columnDefs} />;
      case "logistic":
        return <LogisticPermission columnDefs={columnDefs} />;
      case "project":
        return <ProjectPermission columnDefs={columnDefs} />;
      case "manufacture":
        return <ManufacturePermission columnDefs={columnDefs} />;
      case "user":
        return <UserPermission columnDefs={columnDefs} />;
      case "config":
        return <ConfigPermission columnDefs={columnDefs} />;
      default:
        return;
    }
  };

  const currentTab = pathname + (subtab ? `?subtab=${subtab}` : "");

  return (
    <>
      <CustomizedScrollbar>
        <CustomizedTab tabs={tabs} currentTab={currentTab} subtab />
      </CustomizedScrollbar>
      {renderTab(subtab || "")}
    </>
  );
};

export default PermissionForm;
