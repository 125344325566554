import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";

import { Box, Grid, IconButton, Typography } from "@mui/material";

import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";

import Confirmation from "../../../UI/Confirmation";
import ControlledDatePicker from "../../../Controller/ControlledDatePicker";
import CustomizedMenuOptions from "../../../Custom/CustomizedMenuOptions";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

import { IMenuOption } from "../../../../types/global";
import { useRoutingDeleteMutation } from "../../../../generated/manufacture";

import { useDisable } from "../../../../hooks/use-disable";
import { useConfirmation } from "../../../../hooks/use-confrimation";

type Props = {
  mainRoutingHandler: () => void;
};

const RoutingHeader = ({ mainRoutingHandler }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setDisabled] = useDisable();
  const { enqueueSnackbar } = useSnackbar();

  const { control, getValues } = useFormContext();

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const watchIsMain = useWatch({ control, name: "is_main_routing" });

  const options: IMenuOption[] = [
    {
      value: "ใช้เป็นขั้นตอนงานหลัก",
      disabled: watchIsMain,
    },
    { value: t("sentence.edit") },
    { value: t("button.delete") },
  ];

  const { mutateAsync: routingDelete } = useRoutingDeleteMutation<Error>(
    graphQLClient,
    {
      onMutate(variables) {
        setDisabled(true);
      },
      onSuccess() {
        navigate("/manufacture/routing");
        enqueueSnackbar("ลบขั้นตอนงานผลิตสำเร็จ", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("ลบขั้นตอนงานผลิตไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const deleteDocumentHandler = async () => {
    const id = getValues("id");
    await routingDelete({
      uniqueInput: { id: id },
    });
  };

  const {
    confirmation: deleteConfirmation,
    openConfirmationHandler: openDeleteConfirmation,
    closeConfirmationHandler: closeDeleteConfirmation,
    submitConfirmationHandler: submitDeleteConfirmation,
  } = useConfirmation(deleteDocumentHandler);

  return (
    <Box my={2}>
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography variant="h5">
              {id
                ? getValues("name")
                : `${t("button.create")}${t("manufacture.routing.index")}`}
            </Typography>
            <CustomizedStatus status={watchIsMain && "is_main_routing"} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1.5} justifyContent="end">
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <CustomizedMenuOptions
                fullWidth
                size="medium"
                label={t("button.option")}
                options={options}
                onSelect={(e) => {
                  const value = e.target as HTMLElement;
                  switch (value.innerText) {
                    case `${t("sentence.edit")}`:
                      setDisabled(false);
                      break;
                    case "ใช้เป็นขั้นตอนงานหลัก":
                      mainRoutingHandler();
                      break;
                    case `${t("button.delete")}`:
                      openDeleteConfirmation();
                      break;
                    default:
                  }
                }}
                disabled={!id}
              />
            </Grid>
            <Grid item xs={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  //   onClick={() => {
                  //     setOpenDrawer(true);
                  //   }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1.5} justifyContent="end" mt={1}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <ControlledDatePicker
            name="created_date"
            control={control}
            label={t("date.created_date")}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Confirmation
        title={t("manufacture.routing.sentence.delete_title")}
        open={deleteConfirmation}
        handleClose={closeDeleteConfirmation}
        action={submitDeleteConfirmation}
      />
    </Box>
  );
};

export default RoutingHeader;
