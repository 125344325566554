import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomizedTooltip } from "../../Custom/CustomizedTooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { EventContentArg } from "@fullcalendar/core";

const CustomizedEventContent = (props: EventContentArg) => {
  const { event, view } = props;
  const { title, extendedProps, start, end } = event;
  const { aggrid_status, project_name } = extendedProps;
  const [disabled, setDisabled] = useState(false);

  const isSameDay = (d1: Date | null, d2: Date | null) => {
    if (d2 && d1) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    }
    return false;
  };

  useEffect(() => {
    if (aggrid_status === "cancelled" || aggrid_status === "canceled") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [aggrid_status]);

  return (
    <CustomizedTooltip
      title={`[${project_name}] - ${title}`}
      isCalendar
      isDisabled={disabled}
    >
      <Box
        display="flex"
        gap={0.5}
        overflow="hidden"
        alignItems="center"
        borderRadius={"16"}
        sx={{ width: "100%" }}
      >
        {isSameDay(start, end) && view.type !== "listMonth" && (
          <CircleIcon fontSize="inherit" color="primary" />
        )}
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: view.type === "dayGridMonth" ? 12 : 16,
            fontWeight: 500,
            color: (theme) =>
              disabled ? "#fff" : theme.palette.secondary.main,
          }}
        >
          {title}
        </Typography>
      </Box>
    </CustomizedTooltip>
  );
};

export default CustomizedEventContent;
