import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CustomizedButton from "../Custom/CustomizedButton";
import { useNavigate } from "react-router";
import { useSubscription, gql } from "@apollo/client";
import { formatImportExportName } from "../../utils/Formatter/ImportExport";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import th from "dayjs/locale/th";
import { GraphQLClient } from "graphql-request";
import {
  useRecentHistoryReportRequestQuery,
  useRecentHistoryImportRequestQuery,
} from "../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

dayjs.extend(relativeTime);
dayjs.locale(th);

const IMPORT_EXPORT_SUBSCRIPTION = gql`
  subscription SubscriptionImportReport {
    SubscriptionImportReport {
      status
      timestamp
      type
      report_result {
        report_type
        report
      }
      import_result {
        import_type
        import
      }
    }
  }
`;

const ExportBadge = () => {
  const navigate = useNavigate();
  const [anchorPos, setAnchorPos] = useState(null);
  const [isInit, setisInit] = useState(false);
  const [exportData, setExportData] = useState<any[]>([]);
  const showActions = Boolean(anchorPos);
  const {
    data: subscribeData,
    loading,
    error,
  } = useSubscription(IMPORT_EXPORT_SUBSCRIPTION, {});
  const [hasNotification, setHasNotification] = useState(false);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch: recentHistoryReportRefetch } =
    useRecentHistoryReportRequestQuery(
      graphQLClientWithHeaderItem,
      {},
      { enabled: false }
    );

  const { refetch: recentHistoryImportRefetch } =
    useRecentHistoryImportRequestQuery(
      graphQLClientWithHeaderItem,
      {},
      { enabled: false }
    );

  useEffect(() => {
    if (!isInit) {
      const getIntialData = async () => {
        try {
          const recentHistoryReport = await recentHistoryReportRefetch();
          const recentHistoryImport = await recentHistoryImportRefetch();
          const recentHistoryReportData =
            recentHistoryReport?.data?.RecentHistoryReportRequest?.data;
          const recentHistoryImportData =
            recentHistoryImport?.data?.RecentHistoryImportRequest?.data;
          const preformattedData = [
            ...recentHistoryReportData,
            ...recentHistoryImportData,
          ];

          // sort from the most recent ones
          const sortedData = preformattedData
            .sort(
              (a, b) =>
                dayjs(b?.data?.data?.timestamp).unix() -
                dayjs(a?.data?.data?.timestamp).unix()
            )
            .slice(0, 5);

          const formattedData = sortedData.map((record: any) => {
            return {
              job_id: record?.jobId,
              [`${record?.data?.data?.request_type}_name`]:
                record?.data?.data?.[`${record?.data?.data?.request_type}Name`],
              download_url: record?.downloadUrl,
              timestamp: record?.data?.data?.timestamp,
              type: record?.data?.data?.request_type,
              [`${record?.data?.data?.request_type}_type`]:
                record?.data?.data?.[
                  `${record?.data?.data?.request_type}_type`
                ],
              status: record.data.status,
            };
          });

          setExportData(formattedData);

          setisInit(true);
        } catch (err) {
          console.log(err);
        }
      };
      getIntialData();
    }
  }, [isInit, recentHistoryReportRefetch, recentHistoryImportRefetch]);

  useEffect(() => {
    if (
      subscribeData?.SubscriptionImportReport?.status === "completed" ||
      subscribeData?.SubscriptionImportReport?.status === "failed"
    ) {
      const preformattedData = subscribeData.SubscriptionImportReport;
      const subscribeResult =
        preformattedData?.[`${preformattedData.type}_result`]?.[
          preformattedData.type
        ];
      setExportData((prevState: any) => {
        const foundPrevJob = prevState.find(
          (record: any) => record.job_id === subscribeResult?.job_id
        );
        if (foundPrevJob) {
          return prevState;
        }
        const formattedData = {
          job_id: subscribeResult?.job_id,
          report_name: subscribeResult?.[`${preformattedData.type}_name`],
          download_url: subscribeResult?.download_url,
          timestamp: preformattedData.timestamp,
          type: preformattedData.type,
          [`${preformattedData.type}_type`]:
            preformattedData?.[`${preformattedData.type}_result`]?.[
              `${preformattedData.type}_type`
            ],
          status: preformattedData.status,
        };

        return [formattedData, ...prevState].slice(0, 5);
      });
      setHasNotification(true);
    }
  }, [subscribeData, loading, error]);

  const openActionsHandler = (event: any) => {
    setAnchorPos(event.currentTarget);
    setHasNotification(false);
  };

  const closeActionsHandler = () => {
    setAnchorPos(null);
  };

  return (
    <>
      <IconButton
        onClick={openActionsHandler}
        size="small"
        aria-controls={anchorPos ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorPos ? "true" : undefined}
      >
        <Badge
          badgeContent={" "}
          color={hasNotification ? "success" : undefined}
          variant="dot"
        >
          <ImportExportIcon color="primary" />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorPos}
        open={showActions}
        onClose={closeActionsHandler}
        disableAutoFocusItem
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                width: 10,
                left: "50%",
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Typography color="primary.main" fontWeight={600} pt={1} px={2}>
          นำเข้า/นำออกข้อมูล
        </Typography>
        <Box width={375}>
          {exportData && exportData.length > 0 ? (
            <List sx={{ maxHeight: 320, overflow: "auto" }} dense>
              {exportData.map((record: any) => {
                return (
                  <ListItem
                    key={`${record.report_name}_${record.job_id}`}
                    secondaryAction={
                      record.type === "report" &&
                      record.status === "completed" && (
                        <IconButton
                          edge="end"
                          aria-label="download"
                          onClick={() => {
                            window.open(
                              record.download_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          <DownloadIcon color="primary" />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: "primary.light" }}>
                        <InsertDriveFileIcon color="primary" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={formatImportExportName(
                        record.report_type || record.import_type,
                        record.type,
                        record.status
                      )}
                      primaryTypographyProps={{
                        fontSize: 14,
                      }}
                      secondary={dayjs(record.timestamp).fromNow()}
                      secondaryTypographyProps={{
                        fontSize: 12,
                      }}
                    />
                  </ListItem>
                );
              })}
              <Divider variant="middle" component="li" />
            </List>
          ) : (
            <>
              <Typography variant="body2" my={1.5} align="center">
                ไม่มีข้อมูล
              </Typography>
              <Divider variant="middle" component="li" />
            </>
          )}
        </Box>
        <CustomizedButton
          title="ดูเพิ่มเติม"
          fullWidth
          sx={{ mt: 1 }}
          onClick={() => navigate("/import-export-log")}
        />
      </Menu>
    </>
  );
};

export default ExportBadge;
