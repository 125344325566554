import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AgGrid from "../../../components/UI/AgGrid";
import { RowDoubleClickedEvent } from "ag-grid-community";
import { userColumnDefs } from "./columnDefs";
import { useTranslation } from "react-i18next";
// import { IServerSideGetRowsParams } from "ag-grid-community";
// import { USERS_AGGRID } from "../../../services/AgGrid/UserAgGrid";
import { IUser, IUserArray, IUserInfo } from "../../../types/Auth/user";
import { mergeUsersFormatter } from "../../../utils/Formatter/User";
import { UsersQuery, useUsersQuery } from "../../../generated/company-user";
import {
  UserInformationsQuery,
  useUserInformationsQuery,
} from "../../../generated/user-infomation";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

const UserTable = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<IUserArray>([]);

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { refetch: refetchUsers } = useUsersQuery<UsersQuery>(
    graphQLClientWithHeaderCompany
  );

  const { refetch: refetchUserInfos } =
    useUserInformationsQuery<UserInformationsQuery>(
      graphQLClientWithHeaderSetting
    );

  const fetchData = useCallback(async () => {
    const { data: userData } = await refetchUsers();
    const { data: userInfoData } = await refetchUserInfos();
    if (userData && userInfoData) {
      const mergeData = mergeUsersFormatter(
        userData.users as IUser[],
        userInfoData.userInformations as IUserInfo[]
      );
      setRowData(mergeData);
    }
  }, [refetchUsers, refetchUserInfos]);

  useEffect(() => {
    fetchData();
    return () => setRowData([]);
  }, [fetchData]);

  // const datasource = {
  //   async getRows(params: IServerSideGetRowsParams) {
  //     const { request } = params;
  //     const { startRow, endRow, filterModel, sortModel } = request;
  //     const formatFilter = {
  //       ...filterModel,
  //     };
  //     try {
  //       const { usersFindManyAggrid } = await graphQLClient.request(
  //         USERS_AGGRID,
  //         {
  //           aggridInput: {
  //             startRow,
  //             endRow,
  //             filterModel: formatFilter,
  //             sortModel,
  //           },
  //         }
  //       );
  //       params.success({
  //         rowData: usersFindManyAggrid.data as any[],
  //         rowCount: usersFindManyAggrid.count as number,
  //       });
  //     } catch (err) {
  //       params.fail();
  //     }
  //   },
  // };

  // const onGridReady = (params: GridReadyEvent) => {
  //   params.api.setServerSideDatasource(datasource);
  // };

  const rowSelectHandler = (params: RowDoubleClickedEvent) => {
    const { unique_id } = params.data;
    navigate(`/user/account/${unique_id}?subtab=inventory`);
  };

  const columnDefs = userColumnDefs(t);

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDefs}
      height={665}
      onRowDoubleClicked={rowSelectHandler}
      rowData={rowData}
      path={"/user/account"}
      // onGridReady={onGridReady}
    />
  );
};

export default UserTable;
