import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../types/global";
import { Fragment } from "react";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuList from "../../../components/Custom/CustomizedMenuList";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import DevicesOtherOutlinedIcon from "@mui/icons-material/DevicesOtherOutlined";
import DirectionsOutlinedIcon from "@mui/icons-material/DirectionsOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";

const InventoryReport = () => {
  const { t } = useTranslation();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("report"),
    },
  ];

  const menuList: IBreadcrumbsAndMenu[] = [
    {
      name: t("inventory.itemStock.index"),
      to: "stock",
      icon: <ShoppingBasketOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.itemStockByLot.index"),
      to: "stock-by-lot",
      icon: <DevicesOtherOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.traceEntry.index"),
      to: "trace-entry",
      icon: <DirectionsOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.itemCurrentStockSerialNumber.index") + "(เก่า)",
      to: "current-stock-by-sn",
      icon: <HolidayVillageOutlinedIcon color="primary" />,
    },
    {
      name: t("inventory.movementSummaryReport.index"),
      to: "movement-summary-report",
      icon: <HolidayVillageOutlinedIcon color="primary" />, // TODO
    },
  ];

  return (
    <Fragment>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedMenuList menuList={menuList} />
    </Fragment>
  );
};

export default InventoryReport;
