import { GraphQLClient } from "graphql-request";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import LineOACheckBoxSettingGroup from "./LineOACheckBoxSettingGroup";
import {
  LineOaSettingQuery,
  LineOaSettingUpsertInput,
  LineOaSettingUpsertMutation,
  useLineOaSettingCreateMutation,
  useLineOaSettingQuery,
  useLineOaSettingUpsertMutation,
} from "../../../generated/company-user";
import { enqueueSnackbar } from "notistack";
import { useForm, UseFormRegister } from "react-hook-form";
import { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useLineOASettingGroups } from "../../../hooks/Liff/use-line-oa-setting-groups";
import { en } from "@fullcalendar/core/internal-common";

const LiffSettingForm = () => {
  const lineOASettingGroups = useLineOASettingGroups();

  const {
    state: { authUser, permissions },
  } = useStateContext();

  const graphQLClientWithHeaderCompanyUser: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user", true);

  const {
    data: lineOaSetting,
    refetch: refetchLineOaSetting,
    isFetching: isLineOASettingsFetching,
    isFetched: isLineOASettingsFetched,
  } = useLineOaSettingQuery<LineOaSettingQuery>(
    graphQLClientWithHeaderCompanyUser,
    {
      uniqueInput: {
        user_unique_id: authUser?.unique_id,
      },
    }
  );

  const { mutate: upsertLineOaSetting } =
    useLineOaSettingUpsertMutation<LineOaSettingUpsertMutation>(
      graphQLClientWithHeaderCompanyUser
    );

  const { mutate: createLineOaSetting } =
    useLineOaSettingCreateMutation<LineOaSettingUpsertMutation>(
      graphQLClientWithHeaderCompanyUser
    );

  const onSubmit = (data: LineOaSettingUpsertInput) => {
    upsertLineOaSetting(
      {
        upsertInput: { ...data },
      },
      {
        onSuccess(data, variables, context) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        },
        onError(error, variables, context) {
          // TODO: Add reason for error
          // enqueueSnackbar("ไม่สามารถบันทึกข้อมูลได้เนื่องจาก [Error message]", {
          //   variant: "error",
          // });
          enqueueSnackbar("ไม่สามารถบันทึกข้อมูลได้", {
            variant: "error",
          });
        },
      }
    );
  };

  const { control, register, handleSubmit, reset, watch } =
    useForm<LineOaSettingUpsertInput>({
      defaultValues: lineOaSetting?.lineOASetting ?? undefined,
    });

  const [selectedLineOACompany, setSelectedLineOACompany] = useState(
    localStorage.getItem("selected_line_oa_company")
  );

  const [selectedLineOACompanyName, setSelectedLineOACompanyName] = useState(
    localStorage.getItem("selected_line_oa_company_name")
  );

  const onTenantSelectHandler = () => {
    if (lineOaSetting?.lineOASetting?.user.company_list == null) {
      enqueueSnackbar("ไม่สามารถเปลี่ยนบริษัทได้", {
        variant: "error",
      });
      return;
    }

    for (const company of lineOaSetting?.lineOASetting?.user.company_list) {
      if (company == null) {
        continue;
      }

      if (company.unique_id === selectedLineOACompany) {
        localStorage.setItem("selected_line_oa_company", selectedLineOACompany);
        localStorage.setItem("selected_line_oa_company_name", company.name);
        setSelectedLineOACompanyName(company.name);
        refetchLineOaSetting();
        enqueueSnackbar("เปลี่ยนบริษัทสำเร็จ", { variant: "success" });
        return;
      }
    }
    enqueueSnackbar("ไม่สามารถเปลี่ยนบริษัทได้", {
      variant: "error",
    });
  };

  useEffect(() => {
    if (lineOaSetting?.lineOASetting == null && authUser?.unique_id != null) {
      createLineOaSetting(
        {
          createInput: {
            user_unique_id: authUser?.unique_id,
          },
        },
        {
          onSuccess(data, variables, context) {
            refetchLineOaSetting();
          },
          onError(error, variables, context) {
            enqueueSnackbar("ไม่สามารถสร้างการตั้งค่าได้", {
              variant: "error",
            });
          },
        }
      );
    }
  }, [lineOaSetting]);

  useEffect(() => {
    if (isLineOASettingsFetched && lineOaSetting?.lineOASetting != null) {
      const { id, user, ...rest } = lineOaSetting?.lineOASetting;
      reset(rest);
    }
  }, [lineOaSetting, isLineOASettingsFetched, isLineOASettingsFetching]);

  return (
    <div>
      <p>เปลี่ยนช่องใช้งาน</p>
      <Select
        onChange={(event) => {
          if (event.target.value) {
            setSelectedLineOACompany(event.target.value);
          }
        }}
        defaultValue={selectedLineOACompany}
      >
        {lineOaSetting?.lineOASetting?.user.company_list?.map((company) => (
          <MenuItem key={company?.unique_id} value={company?.unique_id}>
            {company?.name}
          </MenuItem>
        ))}
      </Select>
      <br />
      <button
        onClick={() => {
          onTenantSelectHandler();
        }}
      >
        ใช้งาน
      </button>
      <p>บริษัทที่ใช้งานปัจจุบัน: {selectedLineOACompanyName ?? "ไม่มี"}</p>

      <p>ตั้งค่าการใช้งาน</p>

      {lineOASettingGroups.map((settingGroup) => (
        <LineOACheckBoxSettingGroup
          key={settingGroup.name}
          settingGroup={settingGroup}
          control={control}
          watch={watch}
        />
      ))}

      <button onClick={handleSubmit(onSubmit)}>บันทึก</button>
    </div>
  );
};

export default LiffSettingForm;
