import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useHistoryImportRequestForTableQuery,
  useHistoryReportRequestForTableQuery,
  ImportExportHistoryObject,
} from "../generated/inventory";
import { useUsersFullNameQuery } from "../generated/company-user";
import AgGrid from "../components/UI/AgGrid";
import { GraphQLClient } from "graphql-request";
import { useState, useEffect, useRef } from "react";
import {
  ColDef,
  ValueGetterParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { formatImportExportNameGridOptions } from "../utils/Formatter/ImportExport";
import { dateFilterParams } from "../utils/Formatter/AgGridFilter";
import { formatDate, formatDateTimeWithSeconds } from "../utils/Formatter/Date";

import CustomizedStatus from "../components/Custom/CustomizedStatus";
import CustomizedButton from "../components/Custom/CustomizedButton";
import CustomizedAvatar from "../components/Custom/CustomizedAvatar";

import { createGraphQLClientWithMiddleware } from "../services/graphqlClient";

const ImportExportLog = () => {
  const { t } = useTranslation();
  const importExportLogGridRef: any = useRef();
  const [isInit, setIsInit] = useState(false);
  const [isFormatted, setIsFormatted] = useState(false);
  const [relatedUseUniqueIds, setRelatedUseUniqueIds] = useState<string[]>([]);
  const [historyData, setHistoryData] = useState<ImportExportHistoryObject[]>(
    []
  );

  const graphQLClientWithHeaderCompany: GraphQLClient =
    createGraphQLClientWithMiddleware("company-user");

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch: historyImportRefetch } =
    useHistoryImportRequestForTableQuery(
      graphQLClientWithHeaderItem,
      {},
      { enabled: false }
    );

  const { refetch: historyReportRefetch } =
    useHistoryReportRequestForTableQuery(
      graphQLClientWithHeaderItem,
      {},
      { enabled: false }
    );

  const { refetch: usersFullNameRefetch } = useUsersFullNameQuery(
    graphQLClientWithHeaderCompany,
    {
      findManyInput: {
        unique_id_list: relatedUseUniqueIds,
      },
    },
    { enabled: false }
  );

  useEffect(() => {
    if (!isInit) {
      const getIntialData = async () => {
        const historyImportData = await historyImportRefetch();
        const historyReportData = await historyReportRefetch();
        const preformattedData = [
          ...(historyImportData as any).data?.HistoryImportRequestForTable,
          ...(historyReportData as any).data?.HistoryReportRequestForTable,
        ];
        const relatedUsersUniqueId = [
          ...new Set(preformattedData.map((data) => data.requester_unique_id)),
        ];
        setRelatedUseUniqueIds(relatedUsersUniqueId);
        setHistoryData(preformattedData);
        setIsInit(true);
      };
      getIntialData();
    }
  }, [isInit, historyImportRefetch, historyReportRefetch]);

  useEffect(() => {
    if (isInit && !isFormatted) {
      const formatData = async () => {
        const usersFullNameData = await usersFullNameRefetch();
        setHistoryData((prevState) => {
          const updated = prevState.map((data: any) => {
            const relatedUser = (
              usersFullNameData as any
            ).data?.usersFullName?.find(
              (user: any) => user.unique_id === data.requester_unique_id
            );
            return {
              ...data,
              timestamp: data.timestamp,
              expired_date: data.expired_date,
              img_url: data.img_url,
              requestor_first_name: relatedUser?.first_name,
              requestor_last_name: relatedUser?.last_name,
              requestor_full_name:
                `${relatedUser?.first_name} ${relatedUser?.last_name}`.trim(),
            };
          });
          return updated;
        });

        setIsFormatted(true);
      };
      formatData();
    }
  }, [isInit, isFormatted, usersFullNameRefetch]);

  const columnDefs: ColDef[] = [
    {
      field: "timestamp",
      headerName: t("inventory.date/time"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      sort: "desc",
      valueGetter: (params: ValueGetterParams) =>
        formatDateTimeWithSeconds(params.data.timestamp),
    },
    {
      field: "operation_type",
      headerName: t("inventory.type.index"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["import", "report"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === "import") {
            return "นำเข้าข้อมูล";
          } else if (params.value === "report") {
            return "นำออกข้อมูล";
          }
          return "";
        },
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === "import") {
          return "นำเข้าข้อมูล";
        } else if (params.value === "report") {
          return "นำออกข้อมูล";
        }
        return "";
      },
    },
    {
      field: "document_type",
      headerName: "ประเภทข้อมูล",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "trace_entry",
          "stock",
          "stock_by_lot",
          "item_normal",
          "item_variant",
          "item_bundle",
          "service",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          formatImportExportNameGridOptions(params.value),
      },
      valueFormatter: (params: ValueFormatterParams) =>
        formatImportExportNameGridOptions(params.value),
    },
    {
      field: "file_name",
      headerName: "ไฟล์",
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("status.index"),
      cellRenderer: ({ value }: { value: string }) => {
        return <CustomizedStatus status={value} />;
      },
      filterParams: {
        values: ["completed", "failed"],
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === "completed") {
            return "สำเร็จ";
          } else if (params.value === "failed") {
            return "ไม่สำเร็จ";
          }
          return "";
        },
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "download_url",
      headerName: "",
      filter: false,
      sortable: false,
      cellRenderer: ({ value }: { value: string }) => {
        if (!value) return "";
        return (
          <CustomizedButton
            title={"ดาวน์โหลด"}
            variant="outlined"
            onClick={() => {
              window.open(value, "_blank", "noopener,noreferrer");
            }}
          />
        );
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "expired_date",
      headerName: "วันหมดอายุ",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueGetter: (params: ValueGetterParams) =>
        formatDate(params.data.expired_date),
    },
    {
      field: "requestor_full_name",
      headerName: "โดย",
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }: { data: any; value: string }) => {
        if (data.img_url) {
          return (
            <CustomizedAvatar
              avatars={data.img_url.map((v: string) => ({
                img_url: v,
                unique_id: data?.requester_unique_id ?? "",
                first_name: data?.requestor_first_name ?? "",
                last_name: data?.requestor_last_name ?? "",
              }))}
            />
          );
        }
        return (
          <CustomizedAvatar
            avatars={[
              {
                img_url: undefined,
                unique_id: data?.requester_unique_id ?? "",
                first_name: data?.requestor_first_name ?? "",
                last_name: data?.requestor_last_name ?? "",
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {"นำเข้าข้อมูล/นำออกข้อมูล"}
      </Typography>
      <AgGrid
        ref={importExportLogGridRef}
        columnDefs={columnDefs}
        height={1400}
        disabledSidebar
        rowData={historyData}
        paginationSize={25}
      />
    </>
  );
};

export default ImportExportLog;
