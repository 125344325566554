import { Box, Typography } from "@mui/material";

interface IProps {
  title: string;
  value?: string | number;
  unit?: string;
  borderColor?: string;
  onClick?: () => void;
}

const TotalBox = ({ title, value, unit, borderColor, onClick }: IProps) => {
  return (
    <Box
      sx={{
        cursor: onClick ? "pointer" : "auto",
        borderLeft: `5px ${
          borderColor ? borderColor : "rgba(72, 48, 222, 1) "
        } solid`,
        padding: ".5rem .5rem 1rem 1rem",
        boxShadow: "0px 2px 7px #E5E5E5",
        margin: "1rem 0 0 0",
        borderRadius: "5px",
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "16px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "30px",
          lineHeight: "35px",
          my: ".5rem",
        }}
      >
        {value}
      </Typography>
      {unit && (
        <Box sx={{ position: "relative" }}>
          <Typography
            sx={{
              position: "absolute",
              right: 1,
              bottom: -15,
            }}
            variant="caption"
          >
            {`${unit}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TotalBox;
