import { useEffect } from "react";

const useLocalStorageChangeListener = (callback: () => void) => {
  useEffect(() => {
    const handleCustomEvent = () => {
      callback();
    };

    window.addEventListener("localStorageChange", handleCustomEvent);

    return () => {
      window.removeEventListener("localStorageChange", handleCustomEvent);
    };
  }, [callback]);
};

export default useLocalStorageChangeListener;
