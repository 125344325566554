import CustomizedButton from "../Custom/CustomizedButton";
import ModalUI from "../UI/ModalUI";
import AgGrid from "../UI/AgGrid";
import {
  CheckboxSelectionCallbackParams,
  ColDef,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReactProps } from "ag-grid-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type ExtendedProps = {
  gridRef: any;
  modalTitle: string;
  btnTitle: string;
  modalIsOpen: boolean;
  height: number | string;
  onFinishEditing: (data: any) => void;
  closeModal: () => void;
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  idsSnapshot: string[];
  setIdsSnapshot: Dispatch<SetStateAction<string[]>>;
  lockRows?: (string | undefined)[];
  disabledSidebar?: boolean;
  rowsToDisable?: any;
  columnDefs: ColDef[];
  isLoading?: boolean;
};
type CheckboxModalTableProps = AgGridReactProps & ExtendedProps;

const CheckboxModalTable = ({
  gridRef,
  modalTitle,
  btnTitle,
  modalIsOpen,
  columnDefs,
  onGridReady,
  rowSelection,
  height,
  rowData,
  onRowClicked,
  onRowDoubleClicked,
  onFinishEditing,
  enableRangeSelection,
  closeModal,
  selectedIds,
  setSelectedIds,
  idsSnapshot,
  setIdsSnapshot,
  lockRows,
  getRowId,
  rowsToDisable,
  onFirstDataRendered,
  masterDetail,
  detailCellRendererParams,
  disabledSidebar,
  isLoading,
  children,
}: CheckboxModalTableProps) => {
  const [updatedColumnDefs, setUpdatedColumnDefs] = useState<ColDef<any>[]>([]);
  useEffect(() => {
    if (columnDefs) {
      const isRowSelected = (params: ValueGetterParams) => {
        if (params && params.node) {
          const idKeyName = columnDefs[0]?.field;
          if (idKeyName) {
            return selectedIds.includes(params.node.data[idKeyName]);
          } else {
            return false;
          }
        } else {
          return false;
        }
      };

      const checkboxColumn: ColDef = {
        ...columnDefs[0],
        checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
          if (!rowsToDisable || rowsToDisable.length === 0) {
            return true;
          } else {
            const idKeyName = columnDefs[0]?.field;
            if (idKeyName) {
              return !rowsToDisable.includes(params.data[idKeyName]);
            } else {
              return false;
            }
          }
        },
        valueGetter: (params: ValueGetterParams) => {
          if (params && params.node) {
            if (isRowSelected(params)) {
              params.node.setSelected(true);
            }
            const idKeyName = columnDefs[0]?.field;
            if (idKeyName) {
              return params.data[idKeyName];
            }
          }
        },
      };

      const newColumnDefs: ColDef[] = [
        checkboxColumn,
        ...columnDefs.slice(1, columnDefs.length),
      ];

      setUpdatedColumnDefs(newColumnDefs);
    }
  }, [columnDefs, rowsToDisable, selectedIds]);

  // this triggers on opening the modal too
  const rowSelectHandler = (params: RowSelectedEvent) => {
    if (columnDefs) {
      let selectedId: string = "";
      const idKeyName = columnDefs[0]?.field;
      if (idKeyName) {
        selectedId = params.node.data[idKeyName];
        if (selectedId) {
          if (rowsToDisable?.includes(selectedId)) {
            params.node.setSelected(false);
            return;
          }
          if (lockRows?.includes(selectedId)) {
            params.node.setSelected(true);
            return;
          }
          if (params.node.isSelected()) {
            if (!selectedIds.includes(selectedId)) {
              setSelectedIds((prevIds: string[]) => [...prevIds, selectedId]);
            }
          } else {
            setSelectedIds((prevIds: string[]) =>
              prevIds.filter((id: string) => id !== selectedId)
            );
          }
        } else {
          setSelectedIds([]);
        }
      }
    }
  };

  const finishSelectingHandler = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setIdsSnapshot(selectedIds);
    if (rowSelection === "single") {
      onFinishEditing(selectedRows[0] || null);
    } else {
      onFinishEditing(selectedRows);
    }
    closeModal();
  };

  const closeModalHandler = () => {
    setSelectedIds(idsSnapshot);
    closeModal();
  };

  return (
    <ModalUI
      open={modalIsOpen}
      handleClose={closeModalHandler}
      title={modalTitle}
      fullWidth
      maxWidth="lg"
      isLoading={isLoading}
      action={
        <CustomizedButton
          title={btnTitle}
          variant="contained"
          onClick={finishSelectingHandler}
          sx={{ mt: 2 }}
        />
      }
    >
      {children}
      <AgGrid
        ref={gridRef}
        columnDefs={updatedColumnDefs}
        height={height}
        onGridReady={onGridReady}
        rowSelection={rowSelection}
        rowData={rowData}
        onRowSelected={rowSelectHandler}
        onRowClicked={onRowClicked}
        onRowDoubleClicked={onRowDoubleClicked}
        enableRangeSelection={enableRangeSelection}
        getRowId={getRowId}
        rowMultiSelectWithClick={rowSelection !== "single"}
        onFirstDataRendered={onFirstDataRendered}
        masterDetail={masterDetail}
        detailCellRendererParams={detailCellRendererParams}
        disabledSidebar={disabledSidebar}
      />
    </ModalUI>
  );
};

export default CheckboxModalTable;
