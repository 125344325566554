import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CompanyExpired = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const companyInfo = sessionStorage.getItem("company-info")
    ? JSON.parse(sessionStorage.getItem("company-info") ?? "")
    : null;

  const expired = companyInfo?.is_expired ?? false;

  useEffect(() => {
    if (location.pathname !== "/setting/package")
      if (expired) {
        navigate("/setting/package");
      }
  }, [expired, location, navigate]);

  return <></>;
};

export default CompanyExpired;
