import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  ContactImportMode,
  ValidateContactImportQuery,
  useImportContactAddressMutation,
  useValidateContactImportQuery,
} from "../../../generated/contact";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useStateContext } from "../../../contexts/auth-context";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { mapNestedData } from "../../../utils/Importer";
import { formatString } from "../../../utils/dataTransformer";

const columns = [
  "unique_id",
  "address_type",
  "address",
  "address_contact_name",
  "sub_district",
  "district",
  "province",
  "postal_code",
  "country",
  "address_contact_phone",
];

const count_limit = 5000;

export const useContactAddressImporter = (
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>
) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { authUser },
  } = useStateContext();

  const [contactUniqueIdList, setContactUniqueIdList] = useState<string[]>([]);

  const graphqlClient = createGraphQLClientWithMiddleware("contact");

  const { refetch: validateContact, isFetching: isValidating } =
    useValidateContactImportQuery<ValidateContactImportQuery>(
      graphqlClient,
      {
        validateInput: {
          import_mode: ContactImportMode.Update,
          contact_unique_id_list: contactUniqueIdList,
        },
      },
      {
        enabled: false,
      }
    );

  const { mutateAsync: create, isLoading: isCreating } =
    useImportContactAddressMutation<Error>(graphqlClient);

  const formatItemData = async (data: any) => {
    let missingCols: any[] = [];
    try {
      const dataCols = Object.keys(data?.[0]);
      missingCols = columns.filter((col) => !dataCols.includes(col));

      if (missingCols.length > 0) {
        throw new Error("template");
      }

      const formattedData: any[] = Array.from(
        data
          .reduce((map: any, item: any) => {
            const {
              unique_id,
              address_type,
              address,
              address_contact_name,
              sub_district,
              district,
              province,
              postal_code,
              country,
              address_contact_phone,
            } = item;

            if (!map.has(unique_id)) {
              // Create a new entry if the unique_id doesn't exist
              map.set(unique_id, {
                unique_id,
                address_list: [],
              });
            }

            // Add the contact channel to the existing entry
            map.get(unique_id).address_list.push({
              address_type,
              address: formatString(address),
              address_contact_name,
              sub_district,
              district,
              province,
              postal_code: formatString(postal_code),
              country,
              address_contact_phone: formatString(address_contact_phone),
            });

            return map;
          }, new Map())
          .values()
      );

      const uniqueIdList: string[] = [];

      formattedData.forEach((contact) => {
        const { unique_id } = contact;

        if (unique_id) {
          uniqueIdList.push(unique_id);
        }
      });

      setContactUniqueIdList([...new Set(uniqueIdList)]);

      return formattedData;
    } catch (e) {
      console.error(e);
      let message = "Template ไม่ตรง";
      if (missingCols.length > 0) {
        message = `Template ไม่ตรง ไม่พบคอลัม ${missingCols.join(", ")}`;
      }
      enqueueSnackbar(message, {
        variant: "error",
      });
      return [];
    }
  };

  const validateHandler = async () => {
    try {
      const tempDoc = mapNestedData([], getValues, enqueueSnackbar);
      const formattedData = await formatItemData(tempDoc);
      if (formattedData.length > count_limit) {
        enqueueSnackbar(
          `ไม่สามารถนำเข้าไฟล์ที่มีจำนวนแถวมากกว่า ${count_limit} แถวได้`,
          {
            variant: "error",
          }
        );
      } else {
        setRowData(formattedData);
        enqueueSnackbar("ตรวจสอบไฟล์สำเร็จ", {
          variant: "success",
        });
        setValue("step", 1);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ตรวจสอบไฟล์ไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const importHandler = async () => {
    try {
      //TODO: Validate
      const { data } = await validateContact();
      const missingUniqueId =
        data?.ValidateContactImport.missing_contact_unique_id_list || [];

      const arrays = [missingUniqueId];

      const sumErrorLength = arrays.reduce(
        (total, currentArray) => total + currentArray.length,
        0
      );

      if (sumErrorLength) {
        enqueueSnackbar("นำเข้าไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        try {
          await create({
            importInput: {
              data: rowData,
              priority: 1,
              user_unique_id: authUser?.unique_id || "",
            },
          });
          enqueueSnackbar("นำเข้าสำเร็จ", {
            variant: "success",
          });
          setValue("step", 3);
        } catch (err) {
          enqueueSnackbar("นำเข้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      }
    } catch (err) {
      enqueueSnackbar("นำเข้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  return {
    rowData,
    isLoading: isValidating || isCreating,
    validateHandler,
    importHandler,
  };
};
