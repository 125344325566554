import { useMemo } from "react";
import { IComboBoxOption } from "../../../types/global";
import { IContact } from "../../../types/Contact";

export const useEventContactPersonListOption = (
  allContacts: IContact[],
  contactUniqeid: string | undefined
) => {
  const defaultOptions: IComboBoxOption[] = useMemo(() => {
    const contact = allContacts?.find(
      (contact) => contact.unique_id === contactUniqeid
    );

    const contactPerson =
      contact?.contact_person_list?.map((contactPerson, index) => {
        const formattedOption = {
          id: `${index}_${contactPerson.first_name}_${contactPerson.last_name}`,
          label: `${contactPerson.first_name} ${contactPerson.last_name}`,
          value: `${contactPerson.first_name} ${contactPerson.last_name}`,
        };
        return formattedOption;
      }) ?? [];

    return contactPerson;
  }, [allContacts, contactUniqeid]);

  return {
    contactPersonListOptions: defaultOptions,
  };
};
