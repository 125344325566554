import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import {
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  UseFieldArrayUpdate,
  useFieldArray,
  UseFieldArrayMove,
  useWatch,
} from "react-hook-form";

import CustomizedTab from "../../../Custom/CustomizedTab";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import CustomizedButton from "../../../Custom/CustomizedButton";

import AddIcon from "@mui/icons-material/Add";

import { IDefaultForm } from "../../../../types/global";
import { ISkuDetail } from "../../../../types/Inventory/item";
import { IManufactureOrder } from "../../../../types/Manufacture/order";

import IngredientTable from "../../../Table/Manufacture/Order/IngredientTable";
import CheckedItemModal from "../../../Table/CheckedItemModal";
import { useModal } from "../../../../hooks/use-modal";
import WorkInfoTable from "../../../Table/Manufacture/Order/WorkInfoTable";
import WasteProductionTable from "../../../Table/Manufacture/Order/WasteProductionTable";
import { Tracability } from "../../../../generated/inventory";
import ManuItemListTable from "../../../Table/Manufacture/Order/ManuItemList";

const dafaultWOValue = {
  started_date: undefined,
  duration: 0,
  duration_unit: "minute",
  name: "",
  remark: "",
  production_center: "",
  responsible_user_list: [],
  cost_price: 0,
  plant: "",
};

type Props = IDefaultForm & {
  ingredientFields: IManufactureOrder["ingredient_list"];
  ingredientAppend: UseFieldArrayAppend<IManufactureOrder, "ingredient_list">;
  ingredientMove: UseFieldArrayMove;
  ingredientRemove: UseFieldArrayRemove;
  workOrderFields: IManufactureOrder["work_order_list"];
  workOrderAppend: UseFieldArrayAppend<IManufactureOrder, "work_order_list">;
  workOrderMove: UseFieldArrayMove;
  workOrderRemove: UseFieldArrayRemove;
  workOrderUpdate: UseFieldArrayUpdate<IManufactureOrder, "work_order_list">;
  workOrderReplace: UseFieldArrayReplace<IManufactureOrder, "work_order_list">;
  handleClickOpenItem?: (unique_id?: string) => void;
  ingredientItemIds: string[];
  setIngredientItemIds: React.Dispatch<React.SetStateAction<string[]>>;
  ingredientItemIdsSnapshot: string[];
  setIngredientItemIdsSnapshot: React.Dispatch<React.SetStateAction<string[]>>;
  wasteProductionItemIds: string[];
  setWasteProductionItemIds: React.Dispatch<React.SetStateAction<string[]>>;
  wasteProductionItemIdsSnapshot: string[];
  setWasteProductionItemIdsSnapshot: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  workOrderUpdateSubmit: () => any;
};

type FieldsArrayList = {
  waste_production_list: IManufactureOrder["waste_production_list"];
};

const ManufactureOrderTableForm = ({
  control,
  errors,
  setValue,
  getValues,
  watch,
  ingredientFields,
  ingredientAppend,
  ingredientMove,
  ingredientRemove,
  workOrderFields,
  workOrderAppend,
  workOrderMove,
  workOrderRemove,
  workOrderUpdate,
  workOrderReplace,
  handleClickOpenItem,
  disabled,
  ingredientItemIds,
  setIngredientItemIds,
  ingredientItemIdsSnapshot,
  setIngredientItemIdsSnapshot,
  wasteProductionItemIds,
  setWasteProductionItemIds,
  wasteProductionItemIdsSnapshot,
  setWasteProductionItemIdsSnapshot,
  workOrderUpdateSubmit,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("subtab");
  const { pathname } = useLocation();

  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const [currentTab, setCurrentTab] = useState(pathname);

  const tabs = [
    {
      label: t("manufacture.bom.ingredient"),
      path: `${pathname}`,
    },
    {
      label: t("manufacture.workOrder.name"),
      path: `${pathname}?subtab=work-info`,
    },
    {
      label: t("manufacture.bom.manufacture_item"),
      path: `${pathname}?subtab=manufacture-item`,
      disabled: id && status !== "draft" ? false : true,
    },
    {
      label: t("manufacture.bom.under_quantity_item_list"),
      path: `${pathname}?subtab=waste-production-item-list`,
    },
  ];

  useEffect(() => {
    switch (tab) {
      case "work-info":
        setCurrentTab(pathname + "?subtab=work-info");
        break;
      case "manufacture-item":
        setCurrentTab(pathname + "?subtab=manufacture-item");
        break;
      case "waste-production-item-list":
        setCurrentTab(pathname + "?subtab=waste-production-item-list");
        break;
      default:
        setCurrentTab(pathname);
        break;
    }
  }, [pathname, tab]);

  const {
    modal: ingredientModal,
    closeModalHandler: closeIngredientModal,
    openModalHandler: openIngredientModal,
  } = useModal();

  const {
    modal: wasteProductionModal,
    closeModalHandler: closeWasteProductionModal,
    openModalHandler: openWasteProductionModal,
  } = useModal();

  const {
    fields: wasteProductionFields,
    append: wasteProductionAppend,
    move: wasteProductionMove,
    remove: wasteProductionRemove,
  } = useFieldArray<FieldsArrayList, "waste_production_list", "id">({
    control,
    name: "waste_production_list",
  });

  const handleSelectIngredientItems = (data: ISkuDetail[]) => {
    data.forEach((item: ISkuDetail) => {
      const formatItem = {
        item_unique_id: item?.sku_name ?? "",
        item_name: item?.item_sku?.item?.name ?? "",
        item_description: item?.item_sku?.item?.desc ?? "",
        item_tracability:
          item?.item_sku?.item?.tracability ?? Tracability.Serial,
        item_barcode: item?.item_sku?.item?.barcode ?? "",
        item_img_url: item?.item_sku?.item?.img_url ?? [],
        qty: "1",
        uom: item?.item_sku?.item?.stock_uom?.name,
        uom_unique_id: item?.item_sku?.item?.stock_uom?.unique_id,
        current_ordered_purchase_qty:
          item?.item_sku_qty?.purchase_ordered_qty ?? 0,
        current_ordered_manufacture_qty:
          item?.item_sku_qty?.manufacture_ordered_qty ?? 0,
        current_committed_sales_qty:
          item?.item_sku_qty?.sale_committed_qty ?? 0,
        current_committed_manufacture_qty:
          item?.item_sku_qty?.manufacture_committed_qty ?? 0,
        current_stock_qty: item?.item_sku_qty?.stock_qty ?? 0,
        current_available_qty: item?.item_sku_qty?.available_qty ?? 0,
      };
      if (
        !ingredientFields?.some(
          ({ item_unique_id }) => item_unique_id === formatItem.item_unique_id
        )
      ) {
        ingredientAppend(formatItem);
      }
    });
  };

  const handleSelectWasteProductionItems = (data: ISkuDetail[]) => {
    data.forEach((item: ISkuDetail) => {
      const formatItem = {
        item_unique_id: item?.sku_name ?? "",
        item_name: item?.item_sku?.item?.name ?? "",
        item_description: item?.item_sku?.item?.desc ?? "",
        qty: 1,
        uom_unique_id: item?.item_sku?.item?.stock_uom?.unique_id,
        uom: item?.item_sku?.item?.stock_uom?.name,
        remark: "",
        current_ordered_purchase_qty:
          item?.item_sku_qty?.purchase_ordered_qty ?? 0,
        current_ordered_manufacture_qty:
          item?.item_sku_qty?.manufacture_ordered_qty ?? 0,
        current_committed_sales_qty:
          item?.item_sku_qty?.sale_committed_qty ?? 0,
        current_committed_manufacture_qty:
          item?.item_sku_qty?.manufacture_committed_qty ?? 0,
        current_stock_qty: item?.item_sku_qty?.stock_qty ?? 0,
        current_available_qty: item?.item_sku_qty?.available_qty ?? 0,
      };
      if (
        !wasteProductionFields?.some(
          ({ item_unique_id }) => item_unique_id === formatItem.item_unique_id
        )
      ) {
        wasteProductionAppend(formatItem);
      }
    });
  };

  const renderTab = (tab?: string | null) => {
    switch (tab) {
      case "work-info":
        return (
          <WorkInfoTable
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            fields={workOrderFields}
            move={workOrderMove}
            remove={workOrderRemove}
            update={workOrderUpdate}
            replace={workOrderReplace}
            disabled={Boolean(id && status !== "draft")}
            workOrderUpdateSubmit={workOrderUpdateSubmit}
          />
        );
      case "manufacture-item":
        return (
          <ManuItemListTable
            control={control}
            errors={errors}
            disabled={disabled}
          />
        );
      case "waste-production-item-list":
        return (
          <WasteProductionTable
            control={control}
            errors={errors}
            fields={wasteProductionFields}
            move={wasteProductionMove}
            remove={wasteProductionRemove}
            disabled={disabled}
            setWasteProductionItemIds={setWasteProductionItemIds}
            setWasteProductionItemIdsSnapshot={
              setWasteProductionItemIdsSnapshot
            }
          />
        );
      default:
        return (
          <IngredientTable
            control={control}
            errors={errors}
            move={ingredientMove}
            watch={watch}
            fields={ingredientFields}
            remove={ingredientRemove}
            disabled={Boolean(id && status !== "draft")}
            setIngredientItemIds={setIngredientItemIds}
            setIngredientItemIdsSnapshot={setIngredientItemIdsSnapshot}
            isTooltip
          />
        );
    }
  };

  const renderButton = (tab?: string | null) => {
    switch (tab) {
      case "work-info":
        if ((id && status !== "draft") || disabled) {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มการทำงาน"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => workOrderAppend(dafaultWOValue)}
            />
          );
        }
      case "waste-production-item-list":
        if (disabled && status !== "draft") {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มสินค้า"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => openWasteProductionModal()}
            />
          );
        }
      case "manufacture-item":
        return;
      default:
        if ((id && status !== "draft") || disabled) {
          return;
        } else {
          return (
            <CustomizedButton
              color="secondary"
              title="เพิ่มส่วนประกอบ"
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={() => openIngredientModal()}
            />
          );
        }
    }
  };

  return (
    <CustomizedBox>
      <CustomizedTab subtab tabs={tabs} currentTab={currentTab} />
      {renderTab(tab)}
      {renderButton(tab)}
      <CheckedItemModal
        showSelectItem={ingredientModal}
        closeItemTable={closeIngredientModal}
        finishItemsSelect={handleSelectIngredientItems}
        itemIds={ingredientItemIds}
        setItemIds={setIngredientItemIds}
        itemIdsSnapshot={ingredientItemIdsSnapshot}
        setItemIdsSnapshot={setIngredientItemIdsSnapshot}
        rowSelection="multiple"
      />
      <CheckedItemModal
        showSelectItem={wasteProductionModal}
        closeItemTable={closeWasteProductionModal}
        finishItemsSelect={handleSelectWasteProductionItems}
        itemIds={wasteProductionItemIds}
        setItemIds={setWasteProductionItemIds}
        itemIdsSnapshot={wasteProductionItemIdsSnapshot}
        setItemIdsSnapshot={setWasteProductionItemIdsSnapshot}
        rowSelection="multiple"
      />
    </CustomizedBox>
  );
};

export default ManufactureOrderTableForm;
