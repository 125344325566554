import { RefObject, useCallback, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Box, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { ITab, IRadio, IDefaultForm } from "../../../../types/global";
import ModalUI from "../../../UI/ModalUI";
import AgGrid from "../../../UI/AgGrid";
import ControlledRadioGroup from "../../../Controller/ControlledRadioGroup";
import CustomizedButton from "../../../Custom/CustomizedButton";
import CustomizedTab from "../../../Custom/CustomizedTab";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { ISalesItemPriceHistoryList } from "../../../../types/Sales";
import { SALES_ITEM_PRICE_HISTORY_AGGRID } from "../../../../services/AgGrid/SalesAgGrid";
import { useSalesItemPriceHistoryColumnDefs } from "./columnDefs";
import { theme } from "../../../../theme";
import useStylesRadioCheckBoxTable from "../../../../theme/radio-check-box-table";

const radioList: IRadio[] = [
  {
    label: "ลูกค้าทั้งหมด",
    value: 1,
  },
  {
    label: "ลูกค้าที่เลือกจากเอกสาร",
    value: 0,
  },
];

interface Props {
  gridRef: RefObject<AgGridReact<ISalesItemPriceHistoryList | any>>;
  control: IDefaultForm["control"];
  open: boolean;
  disabled: boolean;
  itemUniqueId?: string | undefined;
  closeModalHandler: () => void;
  addPriceHandler: () => void;
}

const SalesItemPriceHistoryModal = ({
  gridRef,
  control,
  open,
  disabled,
  itemUniqueId,
  closeModalHandler,
  addPriceHandler,
}: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const documentFilter = searchParams.get("type");
  const columnDefs = useSalesItemPriceHistoryColumnDefs(t, disabled);

  const style = useStylesRadioCheckBoxTable(theme);

  const { control: modalControl, setValue: modalSetValue } = useForm({
    defaultValues: {
      use_all_customers: 1,
    },
  });

  const watchCustomerUniqueId = useWatch({
    control,
    name: "customer_contact_unique_id",
  });

  const watchUseAllCustomers = useWatch({
    control: modalControl,
    name: "use_all_customers",
  });

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const tabs: ITab[] = [
    {
      label: t("sales.quotation.index"),
      path: `${pathname}?type=quotation`,
    },
    {
      label: t("sales.order.index"),
      path: `${pathname}?type=order`,
    },
  ];

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const { document_issue_date, ...otherFilterModel } = filterModel;
      const formatFilter = {
        ...otherFilterModel,
        document_issue_date: dateFilterModel(document_issue_date),
      };

      try {
        const { salesItemPriceHistoryFindManyAggrid } =
          await graphQLClientWithHeaderSales.request(
            SALES_ITEM_PRICE_HISTORY_AGGRID,
            {
              aggridInput: {
                startRow,
                endRow,
                filterModel: formatFilter,
                sortModel,
              },
            }
          );
        params.success({
          rowData: salesItemPriceHistoryFindManyAggrid.data as any[],
          rowCount: salesItemPriceHistoryFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instanceType = params.api.getFilterInstance("document_type");
      const instanceContactUniqueId = params.api.getFilterInstance(
        "customer_contact_unique_id"
      );
      const instanceItemUniqueId =
        params.api.getFilterInstance("item_unique_id");
      switch (documentFilter) {
        case "quotation":
          instanceType?.setModel({
            filterType: "set",
            values: ["quotation"],
          });
          break;
        case "order":
          instanceType?.setModel({
            filterType: "set",
            values: ["sales_order"],
          });
          break;
        default:
          instanceType?.setModel({});
      }
      if (
        (!watchUseAllCustomers || (watchUseAllCustomers as any) === "0") &&
        watchCustomerUniqueId
      ) {
        instanceContactUniqueId?.setModel({
          filterType: "text",
          type: "equals",
          filter: watchCustomerUniqueId,
        });
      } else {
        instanceContactUniqueId?.setModel({});
      }
      if (itemUniqueId) {
        instanceItemUniqueId?.setModel({
          filterType: "text",
          type: "equals",
          filter: itemUniqueId,
        });
      } else {
        instanceItemUniqueId?.setModel({});
      }
      params.api.onFilterChanged();
    },
    [documentFilter, watchUseAllCustomers, watchCustomerUniqueId, itemUniqueId]
  );

  useEffect(() => {
    if (!watchCustomerUniqueId) {
      modalSetValue("use_all_customers", 1);
    }
  }, [watchCustomerUniqueId, modalSetValue]);

  useEffect(() => {
    // check if the modal is open first to reduce unnecessary refreshes
    if (open && gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [open, gridRef, onFilterChanged]);

  const currentTab =
    pathname + (documentFilter ? `?type=${documentFilter}` : "");

  return (
    <ModalUI
      title={t("sales.sales_item_price_history")}
      titleSize="1.5rem"
      open={open}
      handleClose={closeModalHandler}
      maxWidth="lg"
      action={
        !disabled && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <CustomizedButton
              title={t("button.cancel")}
              variant="outlined"
              size="medium"
              onClick={closeModalHandler}
            />
            <CustomizedButton
              title={"เลือกรายการ"}
              onClick={addPriceHandler}
              variant="contained"
              size="medium"
            />
          </Box>
        )
      }
    >
      {!disabled && <Typography fontWeight="600">{"ประเภทลูกค้า"}</Typography>}
      {!disabled && (
        <Box ml={1}>
          <ControlledRadioGroup
            control={modalControl}
            name="use_all_customers"
            row
            radioLists={radioList}
            disabled={!watchCustomerUniqueId}
          />
        </Box>
      )}
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <Box sx={style.checkboxTable}>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={500}
          onGridReady={onGridReady}
          suppressMenu
          disabledSidebar
          suppressContextMenu
          rowSelection={disabled ? undefined : "single"}
        />
      </Box>
    </ModalUI>
  );
};

export default SalesItemPriceHistoryModal;
