// import React, { useEffect, useMemo } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import { IDefaultForm } from "../../../../types/global";
import { IUom, IUomConversion } from "../../../../types/Inventory/item";
import Conversion from "./Conversion";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import ControlledTextField from "../../../Controller/ControlledTextField";
import CustomizedRadioGroup from "../../../Custom/CustomizedRadioGroup";
import {
  ItemType,
  UomsQuery,
  useUomsQuery,
} from "../../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { renderUomOptions } from "../../../../utils/dataTransformer";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { GraphQLClient } from "graphql-request";

type ExtendedProps = IDefaultForm & {
  watchBaseUom: string;
  watchConversion: IUomConversion[];
  isInventoryPage: boolean;
  hasBarcodeError?: boolean;
};

const Warehouse = ({
  control,
  errors,
  reset,
  getValues,
  setValue,
  disabled,
  watchBaseUom,
  watchConversion,
  isInventoryPage,
  hasBarcodeError,
}: ExtendedProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const watchItemType = useWatch({ control, name: "type" });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: uomData } = useUomsQuery<UomsQuery>(
    graphQLClientWithHeaderItem
  );

  const uomConversion = watchConversion?.map(
    (conversion) => conversion.target_uom_unique_id
  );

  const newUomList =
    (uomData &&
      uomData.uoms?.filter(
        (uom) =>
          uom?.unique_id === watchBaseUom ||
          uomConversion?.includes(uom?.unique_id as string)
      )) ||
    [];

  // console.log("newUomList", newUomList);

  // const checkUomList = newUomList.map((uom) => uom.label);

  const isStock = useWatch({ control, name: "is_stockable" });
  const isSNTrack = useWatch({ control, name: "tracability" });

  const trackSerialList = [
    {
      label: "ติดตาม Batch/Serial Number",
      value: "serial",
    },
    {
      label: "ติดตามแบบทั่วไป",
      value: "normal",
    },
  ];

  const warehouseUomList = [
    {
      testId: "item-width-text-field",
      label: t("inventory.items.sales_uom"),
      name: "sales_uom_unique_id",
      errors: errors.sales_uom_unique_id,
    },
    {
      testId: "item-length-text-field",
      label: t("inventory.items.purchase_uom"),
      name: "purchase_uom_unique_id",
      errors: errors.purchase_uom_unique_id,
    },
    {
      testId: "item-height-text-field",
      label: t("inventory.items.deliver_uom"),
      name: "deliver_uom_unique_id",
      errors: errors.deliver_uom_unique_id,
    },
  ];

  const renderFieldValue = (value: string) =>
    uomData?.uoms?.find((uom) => uom?.unique_id === value)?.name;

  return (
    <>
      <Box>
        {isStock &&
          ![ItemType.Bundle, ItemType.Service].includes(watchItemType) && (
            <Fragment>
              <Box my={2} ml={1} display={"flex"} alignItems="center">
                <Typography fontWeight="bold">การติดตามสินค้า</Typography>
                <CustomizedTooltip
                  padding="10px"
                  title={
                    <Box>
                      <Box display="flex">
                        <Typography fontWeight="bold" fontSize={14}>
                          {t("inventory.sentence.tracability.normal")}
                        </Typography>
                        <Typography fontSize={14}>
                          : {t("inventory.sentence.tracability.detail_normal")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14} color="#00000099">
                          {t(
                            "inventory.sentence.tracability.detail_normal_remark"
                          )}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography fontWeight="bold" fontSize={14}>
                          {t("inventory.sentence.tracability.serial")}
                        </Typography>
                        <Typography fontSize={14}>
                          : {t("inventory.sentence.tracability.detail_serial")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14} color="#00000099">
                          {t(
                            "inventory.sentence.tracability.detail_serial_remark"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  isNoMaxWidth
                  placement="right"
                >
                  <ErrorOutlineIcon sx={{ fontSize: "14px", ml: 1 }} />
                </CustomizedTooltip>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                ml={2}
                alignItems="center"
              >
                <Controller
                  name="tracability"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <CustomizedRadioGroup
                        radioList={trackSerialList}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          if (e.target.value === "normal") {
                            setValue("barcode", getValues("unique_id"));
                          }
                        }}
                        row
                        disabled={disabled || Boolean(id)}
                      />
                    </Grid>
                  )}
                />
                {isSNTrack === "normal" && watchItemType !== "variant" && (
                  <Box>
                    <ControlledTextField
                      control={control}
                      name="barcode"
                      label={t("inventory.barcode")}
                      error={errors?.barcode || hasBarcodeError}
                      helperText={errors?.barcode?.message}
                      disabled={disabled}
                      sx={{ minWidth: 370 }}
                      required
                    />
                  </Box>
                )}
              </Box>
            </Fragment>
          )}
        {/* <Typography fontWeight="bold" my={2} ml={1}>
          {t("inventory.items.accounting")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={"inventory_account"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  label={t("inventory.items.inventoryAccount")}
                  options={[]}
                  disabled={true}
                />
              )}
            />
          </Grid>
        </Grid> */}
        <Box display={"flex"}>
          <Typography fontWeight="bold" my={2} ml={1}>
            {t("inventory.unit")}
          </Typography>
        </Box>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={"stock_uom_unique_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-base-uom-field"
                  label={t("inventory.items.stock_uom")}
                  options={renderUomOptions(uomData?.uoms as IUom[])}
                  onChange={(_, newValue) => {
                    field.onChange(newValue ? newValue.value : "");
                    if (watchItemType === "bundle") {
                      for (const list of warehouseUomList) {
                        setValue(list.name, newValue ? newValue.value : "");
                      }
                    }
                  }}
                  value={renderFieldValue(field.value)}
                  error={Boolean(errors.stock_uom_unique_id)}
                  helperText={errors.stock_uom_unique_id?.message}
                  disabled={!isInventoryPage || disabled}
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          {warehouseUomList.map((list) => (
            <Grid item xs={12} sm={4} key={list.name}>
              <Controller
                control={control}
                name={list.name}
                render={({ field }) => (
                  <CustomizedComboBox
                    {...field}
                    label={list.label}
                    options={renderUomOptions(newUomList as IUom[])}
                    onChange={(_, newValue) =>
                      field.onChange(newValue ? newValue.value : "")
                    }
                    value={renderFieldValue(field.value)}
                    error={Boolean(list.errors)}
                    helperText={list.errors?.message}
                    disabled={
                      !isInventoryPage || disabled || watchItemType === "bundle"
                    }
                  />
                )}
              />
            </Grid>
          ))}
          {/* <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={"sales_uom"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-sales-uom-field"
                  label={t("inventory.items.sales_uom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  value={renderFieldValue(field.value)}
                  error={Boolean(errors.sales_uom)}
                  helperText={errors.sales_uom?.message}
                  disabled={!isInventoryPage || disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={"purchase_uom"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-purchase-uom-field"
                  label={t("inventory.items.purchaseUom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  value={renderFieldValue(field.value)}
                  error={Boolean(errors.purchase_uom)}
                  helperText={errors.purchase_uom?.message}
                  disabled={!isInventoryPage || disabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              name={"deliver_uom"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  testId="item-deliver-uom-field"
                  label={t("inventory.items.deliveryUom")}
                  options={newUomList}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.label : "")
                  }
                  value={renderFieldValue(field.value)}
                  error={Boolean(errors.deliver_uom)}
                  helperText={errors.deliver_uom?.message}
                  disabled={!isInventoryPage || disabled}
                />
              )}
            />
          </Grid> */}
        </Grid>

        {watchItemType !== "bundle" && (
          <Conversion
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            uomData={uomData?.uoms as IUom[]}
            watchBaseUom={watchBaseUom}
            watchConversion={watchConversion}
            disabled={!isInventoryPage || disabled}
          />
        )}
      </Box>
    </>
  );
};

export default Warehouse;
