import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IWorkOrder } from "../../../types/Manufacture/workOrder";
import { formatDateTimeWithSeconds } from "../../../utils/Formatter/Date";
import {
  formatExportedWorkOrderDurationUnit,
  formatNumber,
  mapStatusToThai,
} from "../../../utils/dataTransformer";

type Props = {
  data: IWorkOrder[];
};

const WorkOrderPdfTable = ({ data }: Props) => {
  const header = [
    {
      thaiLabel: "รายการ",
      width: 40,
    },
    {
      thaiLabel: "การทำงาน",
      width: 80,
    },
    {
      thaiLabel: "โรงงาน",
      width: 65,
    },
    {
      thaiLabel: "ศูนย์ผลิต",
      width: 85,
    },
    {
      thaiLabel: "วันที่เริ่ม",
      width: 115,
    },
    // {
    //   thaiLabel: "วันที่สิ้นสุด",
    //   width: 115,
    // },
    {
      thaiLabel: "ระยะเวลาการผลิต",
      width: 90,
    },
    {
      thaiLabel: "หน่วยเวลาการผลิต",
      width: 95,
    },
    {
      thaiLabel: "คำอธิบาย",
      width: 80,
    },
    {
      thaiLabel: "สถานะ",
      width: 55,
    },
  ];
  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography width={header.width} fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item, index) => (
              <TableRow key={item.id} sx={{ breakInside: "avoid" }}>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{index + 1}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12} fontWeight={600}>
                    {item.name}
                  </Typography>
                  {/* <Divider sx={{ my: 1 }} /> */}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.plant}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {item.production_center}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatDateTimeWithSeconds(item.started_date)}
                  </Typography>
                </TableCell>
                {/* <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatDateTimeWithSeconds(item.finished_date)}
                  </Typography>
                </TableCell> */}
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatNumber(item.duration || 0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {formatExportedWorkOrderDurationUnit(
                      item.duration_unit || ""
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>{item.remark || "-"}</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={12}>
                    {mapStatusToThai(item.aggrid_status || "")}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkOrderPdfTable;
