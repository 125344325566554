import * as Yup from "yup";
import { IPurchaseReturn } from "../../../../types/Purchase/purchaseReturn";
import dayjs from "dayjs";
import {
  AdditionalDiscountType,
  PriceVatType,
} from "../../../../generated/purchase";

export const purchaseReturnSchema: IPurchaseReturn = {
  unique_id: "",
  created_by: null,
  created_date: dayjs().toDate(),
  credit_day: 0,
  issue_date: dayjs(),
  delivery_date: dayjs(),
  reason_to_return: "",
  additional_discount: 0,
  additional_discount_type: AdditionalDiscountType.Baht,
  shipping_cost: 0,
  price_vat_type: PriceVatType.ExcludedVat,
  destination_warehouse_unique_id: "",
  vendor_contact: {
    unique_id_name: "",
    name: "",
    phone: "",
    email: "",
    fax: "",
    identity_no: "",
    branch: "",
    billing_address: {
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
    delivery_address: {
      address_type: "",
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  },
  remark: "",
  item_list: [],
  net_amount: 0,
  pre_vat_amount: 0,
  related_user_list: [],
  sub_total: 0,
  total_amount: 0,
  vat_0_amount: 0,
  vat_7_amount: 0,
  vat_amount: 0,
  vat_exempted_amount: 0,
  vendor_contact_unique_id: null,
  withholding_tax_amount: 0,
  external_reference_id: "",
  tag_list: [],
};

const purchaseReturnItemListSchema = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวนที่มากกว่า 0")
    .required("กรุณาระบุจำนวนสินค้า"),
  uom: Yup.string().required("กรุณาเลือกหน่วย"),
  price_per_unit: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุราคาต่อหน่วยที่มากกว่า 0")
    .required("กรุณาระบุราคาต่อหน่วย"),
});

export const purchaseReturnValidation = Yup.object().shape({
  unique_id: Yup.string().required("กรุณาระบุเลขที่เอกสาร"),
  issue_date: Yup.date().required("กรุณาระบุวันที่ออกเอกสาร").nullable(),
  credit_day: Yup.lazy((value) =>
    value === ""
      ? Yup.string()
      : Yup.number().typeError("กรุณาระบุเครดิตเป็นตัวเลขเท่านั้น")
  ),
  vendor_contact_unique_id: Yup.string().required("กรุณาระบุผู้ขาย"),
  item_list: Yup.array()
    .of(purchaseReturnItemListSchema)
    .min(1, "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น"),
  reason_to_return: Yup.string().required("กรุณาระบุเหตุผลการคืนของ"),
});
