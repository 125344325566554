import DashboardCard from "../DashboardCard";
import DashboardSection from "../DashboardSection";
import { useForm, useWatch } from "react-hook-form";
import { IPeriodFilter } from "../../../../../types/Dashboard";
import { periodFilterSchema } from "../../../../Form/Dashboard/schema";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DashboardChartCard from "../DashboardChartCard";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { MANUFACTURE_ORDER_AGGRID } from "../../../../../services/AgGrid/ManufactureAgGrid";
import { createGraphQLClientWithMiddleware } from "../../../../../services/graphqlClient";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatMOChartData } from "../../../../../utils/Formatter/dashboard";
import {
  ManufactureDashboardResponse,
  MostManufactureItemByStatusQuery,
  useMostManufactureItemByStatusQuery,
} from "../../../../../generated/manufacture";

dayjs.extend(relativeTime);
dayjs.locale(th);

const ManufactureSection = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useForm<IPeriodFilter>({
    defaultValues: periodFilterSchema,
  });
  const [documentCount, setDocumentCount] = useState<Record<string, number>>(
    {}
  );
  const [pendingManuChartData, setPendingManuChartData] = useState<
    (string | number)[][]
  >([]);
  const [inProgressChartData, setInProgressChartData] = useState<
    (string | number)[][]
  >([]);
  // const [latestFetchedTime, setLatestFetchedTime] = useState<Dayjs>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dateFrom = useWatch({
    control,
    name: "dateFrom",
  });

  const dateTo = useWatch({
    control,
    name: "dateTo",
  });

  const cardList = useMemo(
    () => [
      {
        title: t("status.pending_manu"),
        icon: (
          <AccessTimeOutlinedIcon
            sx={{ fontSize: "24px", color: " #BDBDBD" }}
          />
        ),
        quantity: 2,
        status: "pending_manu",
        linkTo: "/manufacture/order?filter=pending_manu",
      },
      {
        title: t("status.in_progress"),
        icon: <DonutLargeIcon sx={{ fontSize: "24px", color: " #BDBDBD" }} />,
        quantity: 2,
        status: "in_progress",
        linkTo: "/manufacture/order?filter=in_progress",
      },
    ],
    [t]
  );

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const {
    isLoading: isLoadingMostManufactureItems,
    refetch: refetchMostManufactureItems,
  } = useMostManufactureItemByStatusQuery<MostManufactureItemByStatusQuery>(
    graphQLClientWithHeaderManufacture,
    {
      manufactureDashboardInput: {
        started_date: dateFrom,
        ended_date: dateTo,
        status: ["pending_manu", "in_progress"],
        number_most_sell_item: 5,
      },
    },
    {
      enabled: false,
      onSuccess: ({ MostManufactureItemByStatus }) => {
        formatChartData(MostManufactureItemByStatus);
      },
    }
  );

  const getManufactureOrderData = useCallback(
    async (
      status: string,
      dateFrom?: Date | null,
      dateTo?: Date | null
    ): Promise<void> => {
      const filter = {
        aggrid_status: {
          filterType: "set",
          values: [status],
        },
        created_date: {
          filterType: "date",
          type: "inRange",
          dateFrom: dateFrom,
          dateTo: dateTo,
        },
      };
      try {
        const { manufactureOrdersFindManyAggrid } =
          await graphQLClientWithHeaderManufacture.request(
            MANUFACTURE_ORDER_AGGRID,
            {
              aggridInput: {
                startRow: 0,
                endRow: 999999,
                filterModel: filter,
                sortModel: [],
              },
            }
          );

        const count = manufactureOrdersFindManyAggrid.count as number;

        storeCountData(status, count);
      } catch (err) {
        // Handle the error if needed
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const storeCountData = (status: string, count: number) => {
    setDocumentCount((prev) => {
      return { ...prev, [status]: count };
    });
  };

  const formatChartData = (data?: ManufactureDashboardResponse | null) => {
    const inProgressData = formatMOChartData(data?.in_progress ?? []);
    const pendingManuData = formatMOChartData(data?.pending_manu ?? []);

    setInProgressChartData(inProgressData);
    setPendingManuChartData(pendingManuData);
  };

  const getAllData = useCallback(
    async (dateFrom?: Date | null, dateTo?: Date | null) => {
      setIsLoading(true);

      for (const list of cardList) {
        await getManufactureOrderData(list.status, dateFrom, dateTo);
      }

      setIsLoading(false);
      // setLatestFetchedTime(dayjs());
    },
    [cardList, getManufactureOrderData]
  );

  const onFilterChanged = () => {
    getAllData(dateFrom, dateTo);
    refetchMostManufactureItems();
  };

  // const onRefresh = () => {
  //   getAllData(dateFrom, dateTo);
  // };

  // const onFilterReset = async () => {
  //   await reset(periodFilterSchema);
  //   await getAllData(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
  // };

  useEffect(() => {
    getAllData(periodFilterSchema.dateFrom, periodFilterSchema.dateTo);
    refetchMostManufactureItems();
  }, [getAllData, refetchMostManufactureItems]);

  return (
    <DashboardSection
      title={t("manufacture.index")}
      control={control}
      // onRefresh={onRefresh}
      // onFilterReset={onFilterReset}
      getFilteredData={onFilterChanged}
      getValues={getValues}
      setValue={setValue}
      // latestFetchTime={latestFetchedTime}
    >
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={4}>
          <DashboardChartCard
            data={pendingManuChartData}
            title={"สินค้ารอผลิต"}
            height="100%"
            isLoading={isLoading && isLoadingMostManufactureItems}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardChartCard
            data={inProgressChartData}
            title={"สินค้ากำลังผลิต"}
            height="100%"
            isLoading={isLoading && isLoadingMostManufactureItems}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} md={4}>
          {cardList.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={12}>
              <DashboardCard
                title={card.title}
                documentCount={documentCount[card.status] || 0}
                icon={card.icon}
                linkTo={card.linkTo}
                isLoading={isLoading}
                expandHeight
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DashboardSection>
  );
};

export default ManufactureSection;
