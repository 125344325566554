import { useSnackbar } from "notistack";
import { Grid, IconButton } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import CustomizedChips from "../../Custom/CustomizedChips";
import CustomizedCreatable from "../../Custom/CustomizedCreatable";
import DeleteTagListConfirmation from "../DeleteTagListConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import { IDefaultForm } from "../../../types/global";
import { IItem } from "../../../types/Inventory/item";
import {
  InventoryTagsFindByEntityQuery,
  useInventoryTagCreateMutation,
  useInventoryTagDeleteMutation,
  useInventoryTagDeleteVerifyMutation,
  useInventoryTagsFindByEntityQuery,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";

type ExtenedProps = {
  control: IDefaultForm["control"];
  setValue: IDefaultForm["setValue"];
  getValues: IDefaultForm["getValues"];
  disabled: IDefaultForm["disabled"];
  entity: any;
  name: string;
  title?: string;
  onClickTagListHandler?: (data: string) => void;
};

interface TagList {
  id: number;
  name: string;
  entity: any;
}

interface ModalPayload {
  value: boolean;
  document_id_list: string[];
  optionToDelete: string;
  uniqueInput: { id: number };
}

type FieldArrayType = {
  tag_list: IItem["tag_list"];
};

const InventoryTagList = ({
  control,
  getValues,
  setValue,
  disabled,
  entity,
  name,
  title,
  onClickTagListHandler,
}: ExtenedProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tagList, setTagList] = useState<TagList[]>([]);
  const [modalDelete, setModalDelete] = useState<ModalPayload>({
    value: false,
    document_id_list: [],
    optionToDelete: "",
    uniqueInput: { id: 0 },
  });

  const { fields, append, remove } = useFieldArray<
    FieldArrayType,
    "tag_list",
    "id"
  >({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "tag_list", // unique name for your Field Array
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data: tagListResult, isSuccess } =
    useInventoryTagsFindByEntityQuery<InventoryTagsFindByEntityQuery>(
      graphQLClientWithHeaderItem,
      {
        entityName: entity,
      }
    );

  const { mutate: createTag } = useInventoryTagCreateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data) {
        const { itemTagCreate } = data;
        const dataType = itemTagCreate as TagList;
        setTagList((prev) => [...prev, dataType]);
      },
    }
  );

  const { mutate: verifyTag } = useInventoryTagDeleteVerifyMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data, variables) {
        if (data.itemTagDeleteVerify && data.itemTagDeleteVerify.length === 0) {
          deleteOptionHandler(variables.uniqueInput ?? { id: 0 });
        } else
          setModalDelete((prev) => ({
            ...prev,
            value: true,
            document_id_list: data.itemTagDeleteVerify || [],
            uniqueInput: variables.uniqueInput ?? { id: 0 },
          }));
      },
      onError() {
        enqueueSnackbar("ไม่มีกลุ่มนี้อยู่ในระบบ", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: daleteTag } = useInventoryTagDeleteMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess(data) {
        setTagList((prevOptions) =>
          prevOptions.filter(
            (option) => option.id || null !== data.itemTagDelete?.id
          )
        );
      },
      onError() {
        enqueueSnackbar("ลบการจัดกลุ่มไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const getAllTag = useCallback(() => {
    if (isSuccess) {
      const { itemTagsFindByEntity } = tagListResult;
      const tagListType = itemTagsFindByEntity as TagList[];
      setTagList(tagListType);
    }
  }, [isSuccess, tagListResult]);

  const createOptionHandler = async (createInput: {
    name: string;
    entity: any;
  }) => {
    createTag({ createInput });
  };

  const deleteVerify = async (optionToDelete: string) => {
    const findId = tagList.find((tag) => tag.name === optionToDelete);
    if (findId) {
      const uniqueInput = {
        id: findId.id,
      };
      setModalDelete((prev) => ({
        ...prev,
        optionToDelete: optionToDelete,
      }));
      verifyTag({ uniqueInput });
    }
  };

  const deleteOptionHandler = async (uniqueInput: { id: number }) => {
    daleteTag({ uniqueInput });
    setTagList((prevOptions) =>
      prevOptions.filter((option) => option.id !== uniqueInput.id)
    );
    const value = getValues(name);
    const filterValue = value.filter((v: { name: string } | string) => {
      if (typeof v === "string") return v !== modalDelete.optionToDelete;
      else return v.name !== modalDelete.optionToDelete;
    });
    setValue(name, filterValue);
  };

  const closeModalHandler = () => {
    setModalDelete((prev) => ({
      ...prev,
      value: false,
    }));
    setTimeout(() => {
      setModalDelete((prev) => ({
        ...prev,
        document_id_list: [],
        optionToDelete: "",
        uniqueInput: { id: 0 },
      }));
    }, 700);
  };

  const comfirmationDeleteAction = () => {
    const { uniqueInput } = modalDelete;
    deleteOptionHandler(uniqueInput);
    closeModalHandler();
  };

  useEffect(() => {
    getAllTag();
  }, [getAllTag]);

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <CustomizedCreatable
          testId="item-tag-list-combobox-field"
          options={
            tagList?.map((tag) => ({
              id: tag.id,
              value: tag.name,
              label: tag.name,
            })) || []
          }
          title={title ? title : "การจัดกลุ่ม"}
          onChange={(event: ChangeEvent<{ value: unknown }>, newValue: any) => {
            if (typeof newValue === "string") {
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              const createInput = {
                name: newValue.inputValue,
                entity: entity,
              };
              createOptionHandler(createInput);
              append({ name: newValue.inputValue });
            } else {
              if (newValue) append({ name: newValue.value });
            }
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.code === "Enter") {
              e.preventDefault();
            }
          }}
          renderOption={(props: any, option: any) => {
            return (
              <li
                {...props}
                value={option.value ?? option}
                key={option.label ?? option}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {option.label ?? option}
                {!option?.label?.includes("เพิ่ม ") && (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      deleteVerify(option.value ?? option);
                      e.stopPropagation();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </li>
            );
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={8} container spacing={1}>
        {fields.map((data, index) => (
          <Grid item key={index + Math.random()}>
            <Controller
              name={`${name}[${index}].name`}
              control={control}
              render={({ field }) => (
                <CustomizedChips
                  handleDelete={!disabled ? () => remove(index) : null}
                  onClick={
                    onClickTagListHandler
                      ? () => onClickTagListHandler(data.name)
                      : undefined
                  }
                  {...field}
                  ref={field.ref}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
      <DeleteTagListConfirmation
        payload={modalDelete}
        openDeleteConfirmation={modalDelete.value}
        comfirmationDeleteAction={comfirmationDeleteAction}
        closeModalHandler={closeModalHandler}
        entityType={entity}
      />
    </>
  );
};

export default InventoryTagList;
