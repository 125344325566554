import { Box } from "@mui/system";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  marginBottom?: number;
  marginTop?: number;
};

const HeaderLayout = ({ children, marginBottom, marginTop }: Props) => {
  return (
    <Box
      sx={{
        mb: marginBottom ?? 3,
        mt: marginTop ?? 3,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Box>
  );
};

export default HeaderLayout;
