import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { LineOaSettingUpsertInput } from "../../../generated/company-user";
import { Checkbox, FormControlLabel } from "@mui/material";

const LineOACheckBoxSetting = ({
  label,
  name,
  control,
  watch,
  disabled = false,
}: {
  label: string;
  name: Parameters<UseFormRegister<LineOaSettingUpsertInput>>[0];
  control: Control<LineOaSettingUpsertInput, any>;
  watch: UseFormWatch<LineOaSettingUpsertInput>;
  disabled?: boolean;
}) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={watch(name) || false}
              disabled={disabled}
            />
          )}
        />
      }
    />
  );
};

export default LineOACheckBoxSetting;
