import {
  PermissionBinaryType,
  PermissionBinaryViewType,
  PermissionViewType,
} from "../../generated/rbac";
import { IPermission, IRole, IUserInfo } from "../../types/Auth/user";

export const formatterRole = (data: IRole) => {
  const {
    user_unique_id_list,
    user_list,
    created_date,
    last_updated_date,
    ...otherData
  } = data;
  const new_user_id_list = user_list.map((list) => list.unique_id);
  return { user_unique_id_list: new_user_id_list, ...otherData };
};

export const mergeUserPermissions = (
  role_list: IUserInfo["role_list"]
): IPermission => {
  let mergedPermission: IPermission = {
    contact: {
      view: PermissionBinaryType.Deny,
    },
    inventory: {
      view: PermissionBinaryType.Deny,
    },
    project: {
      view: PermissionBinaryType.Deny,
    },
    purchase: {
      view: PermissionBinaryType.Deny,
    },
    sales: {
      view: PermissionBinaryType.Deny,
    },
    delivery: {
      view: PermissionBinaryType.Deny,
    },
    manufacture: {
      view: PermissionBinaryType.Deny,
    },
    setting: {
      view: PermissionBinaryType.Deny,
    },
    quotation: {
      view: PermissionViewType.None,
    },
    sales_order: {
      view: PermissionViewType.None,
    },
    sales_return: {
      view: PermissionViewType.None,
    },
    sales_report: {
      view: PermissionBinaryViewType.None,
    },
    purchase_request: {
      view: PermissionViewType.None,
    },
    purchase_order: {
      view: PermissionViewType.None,
    },
    purchase_return: {
      view: PermissionViewType.None,
    },
    purchase_report: {
      view: PermissionBinaryViewType.None,
    },
    goods_receive: {
      view: PermissionViewType.None,
    },
    goods_issue: {
      view: PermissionViewType.None,
    },
    goods_adjustment: {
      view: PermissionViewType.None,
    },
    goods_transfer: {
      view: PermissionViewType.None,
    },
    goods_return: {
      view: PermissionViewType.None,
    },
    item: {
      view: PermissionViewType.None,
    },
    inventory_report: {
      view: PermissionBinaryViewType.None,
    },
    delivery_order: {
      view: PermissionViewType.None,
    },
    delivery_trip: {
      view: PermissionViewType.None,
    },
    logistic_report: {
      view: PermissionBinaryViewType.None,
    },
    user_information: {
      view: PermissionViewType.None,
    },
    role: {
      view: PermissionViewType.None,
    },
    customer: {
      view: PermissionViewType.None,
    },
    vendor: {
      view: PermissionViewType.None,
    },
    contact_report: {
      view: PermissionBinaryViewType.None,
    },
    manufacture_order: {
      view: PermissionViewType.None,
    },
    bom: {
      view: PermissionViewType.None,
    },
    routing: {
      view: PermissionViewType.None,
    },
    work_order: {
      view: PermissionViewType.None,
    },
    manufacture_report: {
      view: PermissionBinaryViewType.None,
    },
    projects: {
      view: PermissionViewType.None,
    },
    event: {
      view: PermissionViewType.None,
    },
    setting_company: {
      view: PermissionBinaryViewType.None,
    },
    setting_approval: {
      view: PermissionBinaryViewType.None,
    },
    setting_remark: {
      view: PermissionBinaryViewType.None,
    },
    setting_inventory: {
      view: PermissionBinaryViewType.None,
    },
    setting_manufacture: {
      view: PermissionBinaryViewType.None,
    },
    setting_logistic: {
      view: PermissionBinaryViewType.None,
    },
    setting_project: {
      view: PermissionBinaryViewType.None,
    },
  };

  if (role_list.length <= 0) return mergedPermission;

  for (const role of role_list) {
    for (const key in role.permission_list) {
      if (role.permission_list.hasOwnProperty(key)) {
        if (role.permission_list[key]) {
          const currentView = role.permission_list[key].view;
          const mergedItem = mergedPermission[key] || {
            view: PermissionViewType.None,
          };

          if (currentView === PermissionBinaryType.Allow) {
            mergedItem.view = PermissionBinaryType.Allow;
          } else if (
            currentView === PermissionViewType.All ||
            currentView === PermissionBinaryViewType.All
          ) {
            mergedItem.view = PermissionViewType.All;
          } else if (
            currentView === PermissionViewType.Self &&
            mergedItem.view !==
              (PermissionViewType.All || PermissionBinaryViewType.All)
          ) {
            mergedItem.view = PermissionViewType.Self;
          }

          mergedPermission[key] = mergedItem;
        }
      }
    }
  }

  return mergedPermission;
};
