import {
  ColDef,
  ValueFormatterParams,
  IServerSideGetRowsParams,
  GridReadyEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import AgGrid from "../../../UI/AgGrid";
import { AgGridReact } from "ag-grid-react";
import {
  dateFilterModel,
  dateFilterParams,
} from "../../../../utils/Formatter/AgGridFilter";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  GET_ALL_BIN_LOCATION,
  GET_ALL_UOM,
  GET_ALL_WAREHOUSES,
} from "../../../../services/AgGrid/InventoryAgGrid";
import {
  Warehouse,
  WarehouseBinLocation,
} from "../../../../generated/inventory";
import { RefObject } from "@fullcalendar/core/preact";
import { GET_TRACE_ENTRY_REPORT_VIEW } from "../../../../services/AgGrid/InventoryReportAgGrid";
import { entryTypeEngToThai } from "../../../../utils/dataTransformer";
import { formatDate } from "../../../../utils/Formatter/Date";
import { IUom } from "../../../../types/Inventory/item";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";

interface Props {
  gridRef: RefObject<AgGridReact>;
  onFilterChanged: (params: any) => void;
}

const TraceEntryReportTable = ({ gridRef, onFilterChanged }: Props) => {
  const { t } = useTranslation();

  const columnDefs: ColDef[] = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "posted_date",
      headerName: t("inventory.list.posted_date"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "source_warehouse_unique_id",
      headerName: t("inventory.list.sourceWarehousDocId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesUniqueId = warehouses.map(
            (warehouse: Warehouse) => warehouse.unique_id
          );
          params.success(warehousesUniqueId);
        },
      },
    },
    {
      field: "source_warehouse_name",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
    },
    {
      field: "source_bin_location_id",
      headerName: `รหัส${t("inventory.list.sourceBinLocation")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsId = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.id
          );
          params.success(binLocationsId);
        },
      },
    },
    {
      field: "source_bin_location_name",
      headerName: t("inventory.list.sourceBinLocation"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsName = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.bin_name
          );
          params.success(binLocationsName);
        },
      },
    },
    {
      field: "destination_warehouse_unique_id",
      headerName: t("inventory.list.destinationWarehouseDocId"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesUniqueId = warehouses.map(
            (warehouse: Warehouse) => warehouse.unique_id
          );
          params.success(warehousesUniqueId);
        },
      },
    },
    {
      field: "destination_warehouse_name",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouses } = await graphQLClientWithHeaderItem.request(
            GET_ALL_WAREHOUSES
          );
          const warehousesName = warehouses.map(
            (warehouse: Warehouse) => warehouse.name
          );
          params.success(warehousesName);
        },
      },
    },
    {
      field: "destination_bin_location_id",
      headerName: `รหัส${t("inventory.list.destinationBinLocation")}`,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsId = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.id
          );
          params.success(binLocationsId);
        },
      },
    },
    {
      field: "destination_bin_location_name",
      headerName: t("inventory.list.destinationBinLocation"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { warehouseBinLocations } =
            await graphQLClientWithHeaderItem.request(GET_ALL_BIN_LOCATION);
          const binLocationsName = warehouseBinLocations.map(
            (binLocation: WarehouseBinLocation) => binLocation.bin_name
          );
          params.success(binLocationsName);
        },
      },
    },
    {
      field: "item_unique_id",
      headerName: t("inventory.items.unique_id"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item_sku_name",
      headerName: t("inventory.items.sku"),
      filter: "agTextColumnFilter",
    },
    {
      field: "img_url",
      headerName: t("inventory.img_url"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: ICellRendererParams<any, string[]>) => {
        if (params.value && params.value.length) {
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: params.value?.[0] ?? "",
                  unique_id: params?.data?.item_unique_id ?? "",
                  first_name: params?.data?.item_name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
        } else
          return (
            <CustomizedAvatar
              variant="rounded"
              avatars={[
                {
                  img_url: undefined,
                  unique_id: params?.data?.item_unique_id ?? "",
                  first_name: params?.data?.item_name ?? "",
                  last_name: "",
                },
              ]}
            />
          );
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: "item_name",
      headerName: t("inventory.items.name"),
      filter: "agTextColumnFilter",
    },
    {
      field: "lot_date",
      headerName: t("inventory.list.lot_number_thai"),
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 200,
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value),
    },
    {
      field: "serial_no",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "qty",
      headerName: "จำนวนที่เคลื่อนไหว",
      filter: false,
    },
    {
      field: "uom_name",
      headerName: t("inventory.unit"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params: any) => {
          const graphQLClientWithHeaderItem: GraphQLClient =
            createGraphQLClientWithMiddleware("item");
          const { uoms } = await graphQLClientWithHeaderItem.request(
            GET_ALL_UOM
          );
          const uomsName = uoms.map((uom: IUom) => uom.name);
          params.success(uomsName);
        },
      },
    },
    {
      field: "item_status",
      headerName: "สถานะสินค้า",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [1, 0],
        valueFormatter: ({ value }: { value: number }) => {
          if (value) return "ใช้งาน";
          return "หยุดใช้งาน";
        },
      },
      cellRenderer: ({ value }: { value: boolean }) => {
        if (value) return <CustomizedStatus status="active" />;
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "type",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) return entryTypeEngToThai(params.value);
        else return "";
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "goods_receive",
          "goods_issue",
          "goods_transfer",
          "goods_adjust",
        ],
        valueFormatter: (params: ValueFormatterParams) =>
          entryTypeEngToThai(params.value),
      },
    },
    {
      field: "reference_unique_id",
      headerName: t("inventory.stockEntry.referenceDocument"),
      filter: "agTextColumnFilter",
    },
    {
      field: "scanned_by",
      headerName: t("inventory.items.scanned_by"),
      filter: "agTextColumnFilter",
    },
  ];

  const graphQLClient: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource = {
    async getRows(params: IServerSideGetRowsParams) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;
      const {
        created_date,
        posted_date,
        lot_date,
        item_status,
        ...otherFilter
      } = filterModel;
      const formatFilter = {
        ...otherFilter,
        item_status: item_status && {
          ...item_status,
          values: item_status.values.map((v: string) => parseInt(v)),
        },
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        lot_date: dateFilterModel(lot_date),
      };
      try {
        const { TraceEntryReportViews } = await graphQLClient.request(
          GET_TRACE_ENTRY_REPORT_VIEW,
          {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          }
        );
        params.success({
          rowData: TraceEntryReportViews.data as any[],
          rowCount: TraceEntryReportViews.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.setServerSideDatasource(datasource);
    onFilterChanged(params);
  };

  return (
    <AgGrid
      height={665}
      ref={gridRef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
    />
  );
};

export default TraceEntryReportTable;
