import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { CustomizedBox } from "../../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { IDefaultForm, ISelectOption } from "../../../../types/global";
import ControlledSelect from "../../../Controller/ControlledSelect";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import Confirmation from "../../../UI/Confirmation";
import {
  InventoryDocumentType,
  InventoryUniqueIdQuery,
  ModelType,
  WarehouseQuery,
  useInventoryUniqueIdQuery,
} from "../../../../generated/inventory";
import { IGoodsReturn } from "../../../../types/Inventory/goodsReturn";
import { Controller, UseFieldArrayAppend, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedSelect from "../../../Custom/CustomizedSelect";
import {
  SalesDocumentType,
  SalesItemListQuery,
  SalesReturnsUniqueIdQuery,
  SortOrder,
  useSalesItemListQuery,
  useSalesReturnsUniqueIdQuery,
} from "../../../../generated/sales";
import { EntityTypeEnum } from "../../../../generated/user-infomation";
import { itemListToTraceEntryListFormatter } from "../../../../utils/Formatter/Global";
import { ISalesItemList } from "../../../../types/Sales";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import {
  ManufactureOrderQuery,
  ManufactureOrdersUniqueIdQuery,
  useManufactureOrderQuery,
  useManufactureOrdersUniqueIdQuery,
} from "../../../../generated/manufacture";
import { manufactureOrderToTraceEntryFormatter } from "../../../../utils/Formatter/ManufactureOrder";
import { IManufactureOrder } from "../../../../types/Manufacture/order";

const typeOptions: ISelectOption[] = [
  {
    label: "รับคืนจากลูกค้า",
    value: "sales_return",
  },
  {
    label: "รับคืนจากผลิต",
    value: "manufacture_order",
  },
];

const StyledLink = styled(Link)<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
  marginLeft: 8,
}));

type Props = IDefaultForm & {
  status?: string | null;
  step: number;
  allWarehouses?: WarehouseQuery["warehouse"][];
  append: UseFieldArrayAppend<IGoodsReturn, "trace_entry_list">;
};

const GoodsReturnDetail = ({
  control,
  errors,
  setValue,
  getValues,
  reset,
  append,
  disabled,
  allWarehouses,
  status,
  step,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [currentType, setCurrentType] = useState<string>("");
  const [currentReference, setCurrentReference] = useState<string>("");
  const [referenceUniqueIdOptions, setReferenceUniqueIdOptions] = useState<
    ISelectOption[]
  >([]);

  const watchType = useWatch({
    control,
    name: "type",
  });

  const watchReferenceUniqueId = useWatch({
    control,
    name: "reference_unique_id",
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const graphQLClientWithHeaderSales: GraphQLClient =
    createGraphQLClientWithMiddleware("sales");

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const { refetch } = useInventoryUniqueIdQuery<InventoryUniqueIdQuery>(
    graphQLClientWithHeaderItem,
    {
      modelType: ModelType.GoodsReceive,
    },
    {
      enabled: false,
      onSuccess: ({ utilGetUniqueId }) => {
        setValue("unique_id", utilGetUniqueId);
      },
    }
  );

  const { data: salesReturnsUniqueId, isFetching } =
    useSalesReturnsUniqueIdQuery<SalesReturnsUniqueIdQuery>(
      graphQLClientWithHeaderSales,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            aggrid_status: {
              values: ["approved", "finished"],
              filterType: "set",
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: watchType === "sales_return",
      }
    );

  const { refetch: salesItemListRefetch } =
    useSalesItemListQuery<SalesItemListQuery>(
      graphQLClientWithHeaderSales,
      {
        whereInput: {
          reference_document_type: SalesDocumentType.SalesReturn,
          reference_unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const { data: manufactureOrdersUniqueId, isFetching: isManufactureFetching } =
    useManufactureOrdersUniqueIdQuery<ManufactureOrdersUniqueIdQuery>(
      graphQLClientWithHeaderManufacture,
      {
        aggridInput: {
          startRow: 0,
          endRow: 9999,
          filterModel: {
            aggrid_status: {
              values: ["pending_manu", "in_progress", "finished"],
              filterType: "set",
            },
          },
          sortModel: [
            {
              sort: SortOrder.Desc,
              colId: "created_date",
            },
          ],
        },
      },
      {
        enabled: watchType === "manufacture_order",
      }
    );

  const { refetch: manufactureOrderRefetch } =
    useManufactureOrderQuery<ManufactureOrderQuery>(
      graphQLClientWithHeaderManufacture,
      {
        uniqueInput: {
          unique_id: watchReferenceUniqueId,
        },
      },
      {
        enabled: false,
      }
    );

  const resetTraceEntry = () => {
    if (reset) {
      reset((prev: IGoodsReturn) => ({
        ...prev,
        trace_entry_list: [],
      }));
    }
  };

  const onTypeChangeHandler = () => {
    setValue("type", currentType);
    setValue("reference_unique_id", "");
    resetTraceEntry();
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(onTypeChangeHandler);

  const onReferenceChangeHandler = async () => {
    resetTraceEntry();
    if (currentReference) {
      await setValue("reference_unique_id", currentReference);
      if (currentType === "sales_return") {
        const { data } = await salesItemListRefetch();
        if (data?.salesItems) {
          const formatSalesReturnItemList = itemListToTraceEntryListFormatter(
            data.salesItems as ISalesItemList[],
            InventoryDocumentType.GoodsReceive,
            EntityTypeEnum.SalesReturn
          );
          append(formatSalesReturnItemList);
        }
      } else if (currentType === "manufacture_order") {
        const { data } = await manufactureOrderRefetch();
        if (data?.manufactureOrder) {
          const formatManufactureOrderItemList =
            manufactureOrderToTraceEntryFormatter(
              data.manufactureOrder as IManufactureOrder,
              undefined,
              InventoryDocumentType.GoodsReturn
            );
          append(formatManufactureOrderItemList);
        }
      }
    }
  };

  const {
    confirmation: referenceConfirmation,
    openConfirmationHandler: openReferenceConfirmationHandler,
    closeConfirmationHandler: closeReferenceConfirmationHandler,
    submitConfirmationHandler: submitReferenceConfirmationHandler,
  } = useConfirmation(onReferenceChangeHandler);

  const warehouseOptions: ISelectOption[] =
    allWarehouses?.map((warehouse) => ({
      label: warehouse?.name || "",
      value: warehouse?.unique_id || "",
    })) || [];

  useEffect(() => {
    if (watchType === "sales_return") {
      setReferenceUniqueIdOptions(
        salesReturnsUniqueId?.salesReturnFindManyAggrids.data?.map(
          (salesReturn) => ({
            id: salesReturn?.unique_id || "",
            label: salesReturn?.unique_id || "",
            value: salesReturn?.unique_id || "",
          })
        ) || []
      );
    } else if (watchType === "manufacture_order") {
      setReferenceUniqueIdOptions(
        manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data?.map(
          (manufactureOrder) => ({
            id: manufactureOrder?.unique_id || "",
            label: manufactureOrder?.unique_id || "",
            value: manufactureOrder?.unique_id || "",
          })
        ) || []
      );
    } else {
      setReferenceUniqueIdOptions([]);
    }
  }, [
    watchType,
    salesReturnsUniqueId?.salesReturnFindManyAggrids.data,
    manufactureOrdersUniqueId?.manufactureOrdersFindManyAggrid.data,
  ]);

  const generateUniqueId = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!id) {
      generateUniqueId();
    }
  }, [generateUniqueId, id]);

  const handleClickOpenRef = () => {
    if (watchType === "manufacture_order")
      return window.open(
        `/manufacture/order/` + watchReferenceUniqueId,
        "_blank"
      );
    else if (watchType === "sales_return")
      return window.open(`/sales/return/` + watchReferenceUniqueId, "_blank");
  };

  return (
    <CustomizedBox margin="2rem 0 0 0">
      <Typography fontWeight="bold">{t("sentence.detail")}</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ControlledTextField
              control={control}
              name="unique_id"
              error={Boolean(errors && errors.unique_id)}
              helperText={errors && errors.unique_id?.message}
              label={t("sentence.unique_id")}
              disabled={disabled || status === "draft"}
              required
              onChange={(e, field) => {
                const trimmedValue = e.target.value
                  .replaceAll(" ", "")
                  .replaceAll(/\u00a0/g, "");
                field.onChange(trimmedValue);
              }}
            />
            {!id && (
              <CustomizedTooltip
                title="เรียกเลขที่เอกสารใหม่"
                enterNextDelay={200}
              >
                <IconButton
                  onClick={generateUniqueId}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                label={t("sentence.type")}
                error={Boolean(errors && errors.type)}
                helperText={errors && errors.type?.message}
                disabled={disabled || step === 2}
                options={typeOptions}
                onChange={(e: any) => {
                  setCurrentType(e.target.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openConfirmationHandler();
                  } else {
                    field.onChange(e);
                  }
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Controller
            name="reference_unique_id"
            control={control}
            render={({ field }) => (
              <CustomizedComboBox
                {...field}
                loading={isManufactureFetching || isFetching}
                label={t("sentence.reference_unique_id")}
                error={Boolean(errors && errors.reference_unique_id)}
                helperText={errors && errors.reference_unique_id?.message}
                disabled={disabled || step === 2 || !watchType}
                options={referenceUniqueIdOptions}
                onChange={async (_, newValue) => {
                  setCurrentReference(newValue.value);
                  const currentTrace = getValues("trace_entry_list");
                  if (currentTrace.length > 0) {
                    openReferenceConfirmationHandler();
                  } else {
                    await field.onChange(newValue.value);
                    if (currentType === "sales_return") {
                      const { data } = await salesItemListRefetch();
                      if (data?.salesItems) {
                        const formatSalesReturnItemList =
                          itemListToTraceEntryListFormatter(
                            data.salesItems as ISalesItemList[],
                            InventoryDocumentType.GoodsReceive,
                            EntityTypeEnum.SalesReturn
                          );
                        append(formatSalesReturnItemList);
                      }
                    } else if (currentType === "manufacture_order") {
                      const { data } = await manufactureOrderRefetch();
                      if (data?.manufactureOrder) {
                        const formatManufactureOrderItemList =
                          manufactureOrderToTraceEntryFormatter(
                            data.manufactureOrder as IManufactureOrder,
                            undefined,
                            InventoryDocumentType.GoodsIssue
                          );
                        append(formatManufactureOrderItemList);
                      }
                    }
                  }
                }}
                required
                sx={{
                  input: {
                    visibility: disabled ? "hidden" : "visible",
                  },
                }}
                InputProps={{
                  startAdornment: disabled && (
                    <InputAdornment position="start">
                      <StyledLink onClick={handleClickOpenRef}>
                        {watchReferenceUniqueId}
                      </StyledLink>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledSelect
            label={t("inventory.warehouse")}
            control={control}
            name="source_warehouse_unique_id"
            options={warehouseOptions}
            error={Boolean(errors && errors.source_warehouse_unique_id)}
            helperText={errors && errors.source_warehouse_unique_id?.message}
            disabled={disabled || step === 2}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <ControlledTextField
            control={control}
            name="remark"
            error={Boolean(errors && errors.remark)}
            helperText={errors && errors.remark?.message}
            label={t("sentence.remark")}
            disabled={status === "finished" || status === "cancelled"}
          />
        </Grid>
      </Grid>
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนประเภท"
        message="หากเปลี่ยนประเภทแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
      <Confirmation
        title="ยืนยันหากต้องการเปลี่ยนเอกสารอ้างอิง"
        message="หากเปลี่ยนเอกสารอ้างอิงแล้ว ตารางสินค้าด้านล่างจะถูกเปลี่ยนทั้งหมด"
        open={referenceConfirmation}
        handleClose={closeReferenceConfirmationHandler}
        action={submitReferenceConfirmationHandler}
      />
    </CustomizedBox>
  );
};

export default GoodsReturnDetail;
