import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../../types/global";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { Typography } from "@mui/material";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import HeaderLayout from "../../../components/UI/HeaderLayout";
import PurchaseRequestTable from "../../../components/Table/Purchase/Request";

const PurchaseRequest = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("purchase.index"),
      to: "/purchase",
    },
    {
      name: t("purchase.request.index"),
    },
  ];

  const tabs: ITab[] = [
    {
      label: t("global.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.wait_approve"),
      path: `${pathname}?filter=wait_approve`,
    },
    {
      label: t("status.not_approved"),
      path: `${pathname}?filter=not_approved`,
    },
    {
      label: t("status.approved"),
      path: `${pathname}?filter=approved`,
    },
    {
      label: t("status.partially_ordered"),
      path: `${pathname}?filter=partially_ordered`,
    },
    {
      label: t("status.fully_ordered"),
      path: `${pathname}?filter=fully_ordered`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <HeaderLayout>
        <Typography variant="h5">{t("purchase.request.index")}</Typography>
        <CustomizedButton
          title={`${t("button.create")}${t("purchase.request.index")}`}
          variant="contained"
          onClick={() => navigate(`${pathname}/add`)}
        />
      </HeaderLayout>
      <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
      <PurchaseRequestTable isFilter={isFilter} />
    </>
  );
};

export default PurchaseRequest;
