import * as Yup from "yup";

export const remarkTemplateSchema = {
  remark_templates: [],
};

export const remarkTemplateValidation = Yup.object().shape({
  remark_templates: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("กรุณากรอก"),
      remark_text: Yup.string().required("กรุณากรอก"),
    })
  ),
});
