import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import GoodsIssueItemList from "../../../components/Table/Inventory/GoodsIssue/GoodsIssueItemLIst";
import { IDefaultForm } from "../../../types/global";
import { IGoodsIssue } from "../../../types/Inventory/goodsIssue";

type Props = IDefaultForm & {
  fields: FieldArrayWithId<IGoodsIssue, "trace_entry_list", "id">[];
  append: UseFieldArrayAppend<IGoodsIssue, "trace_entry_list">;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IGoodsIssue, "trace_entry_list">;
  renderButton: () => JSX.Element | null;
};

const BarcodeTab = ({
  control,
  getValues,
  setValue,
  disabled,
  errors,
  fields,
  append,
  remove,
  update,
  renderButton,
}: Props) => {
  return (
    <>
      <GoodsIssueItemList
        control={control}
        getValues={getValues}
        setValue={setValue}
        disabled={disabled}
        errors={errors}
        fields={fields}
        append={append}
        remove={remove}
        update={update}
      />
      {renderButton()}
    </>
  );
};

export default BarcodeTab;
