import dayjs from "dayjs";
import { IEvent, IProject, ITask } from "../../types/Project";
import { IAttachment } from "../../types/global";
import { uploadFileToS3 } from "../s3";

const formatProjectImgUrl = async (data: IProject) => {
  let current_img: string[] = [];
  if (data.img_url && data.img_url.length > 0) {
    const filteredFile = data.img_url.filter(
      (img: string | File) => img instanceof File
    );

    if (filteredFile && filteredFile.length > 0) {
      const { Location } = await uploadFileToS3(
        filteredFile[0],
        "project",
        data?.unique_id || ""
      );
      current_img.push(Location);
    } else {
      current_img = data.img_url;
    }
  }
  return current_img;
};

const formatProjectAttachmentList = async (data: IProject) => {
  let formatted_attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        // console.log(data.unique_id);
        const { Location } = await uploadFileToS3(
          file,
          "project",
          data.unique_id || ""
        );

        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };

        formatted_attachment_list.push(formatAttachment);
      } else {
        formatted_attachment_list.push(file);
      }
    }
  }
  return formatted_attachment_list;
};

const formatTaskAttachmentList = async (data: ITask) => {
  let formatted_attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        // console.log(data.unique_id);
        const { Location } = await uploadFileToS3(file, "task", data.id || "");

        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };

        formatted_attachment_list.push(formatAttachment);
      } else {
        formatted_attachment_list.push(file);
      }
    }
  }
  return formatted_attachment_list;
};

const formatEventAttachmentList = async (data: IEvent) => {
  let formatted_attachment_list: IAttachment[] = [];
  if (data.attachment_list && data.attachment_list.length > 0) {
    for (const file of data.attachment_list) {
      if (file instanceof File) {
        // console.log(data.unique_id);
        const { Location } = await uploadFileToS3(file, "event", data.id || "");

        const formatAttachment: IAttachment = {
          attachment_name: file.attachment_name,
          uploaded_by: file.uploaded_by,
          uploaded_date: file.uploaded_date,
          url: Location,
        };

        formatted_attachment_list.push(formatAttachment);
      } else {
        formatted_attachment_list.push(file);
      }
    }
  }
  return formatted_attachment_list;
};

export const projectCreatePayloadFormatter = async (data: IProject) => {
  const {
    customer_contact,
    tag_list,
    created_date,
    img_url,
    attachment_list,
    flag_status,
    related_user_list,
    responsible_user_list,
    created_by,
    project_value,
    id,
    credit_day,
    sales_contact_list,
    tasks_id_list,
    ...otherData
  } = data;

  const formattedImgUrl = await formatProjectImgUrl(data);
  const formattedAttachmentlList = await formatProjectAttachmentList(data);
  const formattedProjectValue = project_value.toString();

  const formattedPayload = {
    ...otherData,
    customer_name: customer_contact?.name,
    phone: customer_contact?.phone,
    tag_list: { connect: tag_list },
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list },
    responsible_user_list: { set: responsible_user_list },
    created_by: created_by,
    project_value: formattedProjectValue,
    img_url: { set: formattedImgUrl },
    flag_status: { set: flag_status },
    tasks_id_list: { set: tasks_id_list },
  };
  return formattedPayload;
};

export const projectUpdatePayloadFormatter = async (
  data: IProject,
  flagStatus?: string
) => {
  const {
    customer_contact,
    tag_list,
    created_date,
    img_url,
    attachment_list,
    flag_status,
    related_user_list,
    responsible_user_list,
    created_by,
    project_value,
    id,
    credit_day,
    sales_contact_list,
    phone,
    customer_name,
    tasks_id_list,
    ...otherData
  } = data;

  const formattedImgUrl = await formatProjectImgUrl(data);
  const formattedAttachmentlList = await formatProjectAttachmentList(data);
  const formattedProjectValue = project_value.toString();

  const formattedFlagStatus =
    flagStatus === "cancelled" ? [...flag_status, "cancelled"] : flag_status;

  let formattedOtherData: any = {};
  for (const [key, value] of Object.entries(otherData)) {
    formattedOtherData[key] = { set: value };
  }

  const formattedPayload = {
    ...formattedOtherData,
    customer_name: {
      set: Boolean(customer_contact?.name)
        ? customer_contact?.name
        : customer_name,
    },
    phone: {
      set: Boolean(customer_contact?.phone) ? customer_contact?.phone : phone,
    },
    tag_list: { set: tag_list ?? [] },
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list },
    responsible_user_list: { set: responsible_user_list },
    created_by: created_by,
    project_value: { set: formattedProjectValue },
    img_url: { set: formattedImgUrl },
    flag_status: { set: formattedFlagStatus },
    tasks_id_list: { set: tasks_id_list },
  };
  return formattedPayload;
};

export const taskCreatePayloadFormatter = async (
  data: ITask,
  referenceUniqueId: string
) => {
  const { attachment_list, related_user_list, id, status, ...otherData } = data;

  const formattedAttachmentlList = await formatTaskAttachmentList(data);

  const formattedPayload = {
    ...otherData,
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list ?? [] },
    reference_unique_id: referenceUniqueId,
    status: 0,
  };
  return formattedPayload;
};

export const taskUpdatePayloadFormatter = async (data: ITask) => {
  const {
    attachment_list,
    related_user_list,
    id,
    status,
    created_by,
    updated_date,
    ...otherData
  } = data;

  const formattedAttachmentlList = await formatTaskAttachmentList(data);

  let formattedOtherData: any = {};
  for (const [key, value] of Object.entries(otherData)) {
    formattedOtherData[key] = { set: value };
  }

  const formattedPayload = {
    ...formattedOtherData,
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list ?? [] },
    status: { set: status },
    created_by: created_by,
    updated_date: { set: dayjs() },
  };
  return formattedPayload;
};

export const eventCreatePayloadFormatter = async (data: IEvent) => {
  const {
    attachment_list,
    related_user_list,
    flag_status,
    main_status,
    start_check_in_point,
    destination_check_point,
    ...otherData
  } = data;

  const formattedAttachmentlList = await formatEventAttachmentList(data);

  const formattedPayload = {
    ...otherData,
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list ?? [] },
    flag_status: { set: flag_status },
    main_status: main_status,
    sub_status: main_status,
  };
  return formattedPayload;
};

export const eventUpdatePayloadFormatter = async (data: IEvent) => {
  const {
    id,
    attachment_list,
    related_user_list,
    created_by,
    updated_date,
    start_check_in_point,
    destination_check_point,
    main_status,
    ...otherData
  } = data;

  const formattedAttachmentlList = await formatEventAttachmentList(data);

  let formattedOtherData: any = {};
  for (const [key, value] of Object.entries(otherData)) {
    formattedOtherData[key] = { set: value };
  }

  const formattedPayload = {
    ...formattedOtherData,
    attachment_list: { set: formattedAttachmentlList },
    related_user_list: { set: related_user_list ?? [] },
    created_by: created_by,
    main_status: { set: main_status },
    sub_status: { set: main_status },
    updated_date: { set: dayjs() },
    start_check_in_point: start_check_in_point ?? { set: start_check_in_point },
    destination_check_point: destination_check_point ?? {
      set: destination_check_point,
    },
    cancellation_date: { set: main_status === "cancelled" ? dayjs() : null },
    // flag_status: { set: flag_status },
  };
  return formattedPayload;
};
