import { Dayjs } from "dayjs";
import { ICustomer } from "../Sales";
import { IAttachment, ICreatedBy } from "../global";
import { ReferenceDocumentType } from "../../generated/project";
import { FieldValues } from "react-hook-form";

export enum ProjectView {
  Table = "table",
  Kanban = "kanban",
  Calendar = "calendar",
}

export enum ProjectDocumentType {
  Project = "PROJECT",
  Event = "EVENT",
  Task = "TASK",
}

export enum ProjectMainStatus {
  PreSales = "pre_sales",
  PostSales = "post_sales",
  Support = "support",
  Finished = "finished",
}

export enum EventStatus {
  Planning = "planning",
  Appointment = "appointment",
  CheckIn = "check_in",
  Finished = "finished",
  Cancelled = "cancelled",
}

export interface IProject {
  id: string;
  unique_id: string;
  name: string;
  description?: string | null;
  img_url?: string[];
  related_user_list: ICreatedBy[];
  responsible_user_list: ICreatedBy[];
  customer_contact?: ICustomer;
  credit_day?: number;
  sales_contact_list?: ICreatedBy[];
  customer_contact_unique_id: string;
  customer_name?: string;
  phone?: string;
  tag_list: { name: string }[];
  project_value: string;
  opportunity_close_sale: number;
  expected_start_date: Dayjs;
  expected_end_date: Dayjs;
  start_date: Dayjs;
  end_date: Dayjs;
  remark?: string;
  created_date: Date | null;
  created_by: ICreatedBy | null;
  attachment_list: IAttachment[];
  main_status: string;
  sub_status?: string;
  aggrid_status?: string | null;
  flag_status: string[];
  cancel_remark?: string;
  tasks_id_list: number[];
}
export interface ITask {
  id: string;
  reference_unique_id?: string;
  status?: number;
  name: string;
  desc?: string | null;
  due_date?: Dayjs;
  type?: string | null;
  related_user_list?: ICreatedBy[] | null;
  remark?: string | null;
  created_date?: Date | null;
  created_by?: ICreatedBy | null;
  updated_date?: Dayjs;
  attachment_list?: IAttachment[] | null;
}

export interface IEvent extends FieldValues {
  id: string;
  name: string;
  desc: string | null;
  remark?: string | null;
  related_user_list: ICreatedBy[];
  aggrid_status?: string;
  main_status: string;
  sub_status?: string | null;
  flag_status: string[];
  project_name: string;
  task_status: string;
  contact_name: string;
  contact_person_name: string;
  planning_started_date: Date | null;
  planning_ended_date: Date | null;
  appointment_started_date: Date | null;
  appointment_ended_date: Date | null;
  check_in_start_date?: Date | null;
  check_in_end_date?: Date | null;
  cancellation_date?: Date | null;
  created_date: Date;
  updated_date: Date;
  created_by: ICreatedBy | null;
  attachment_list?: IAttachment[] | null;
  location_name: string;
  location_link: string;
  address_list?: ICheckInAddress[];
  start_check_in_point?: string;
  destination_check_point?: string;
  distance: string | null;
  period: string | null;
}

export interface IFilterBox {
  project_name: string | null;
  project_unique_id: string | null;
  related_user_list: ICreatedBy[] | null;
  status: string | null;
  filter_tags: string[] | null;
  event_name: string | null;
}

export interface IStatusOption {
  id: string;
  label: string;
  value: string;
  group: string;
  main_status: string;
}

export type IProjectRelatedDocument = {
  unique_id: string;
  total_amount?: number | null;
  // main_status?: string;
  // flag_status?: string;
  aggrid_status?: string | null;
  issue_date?: Date;
  created_date?: Date;
  due_date?: Date;
  delivery_date?: Date;
  expect_date?: Date;
  project?:
    | {
        __typename?: "ProjectObject" | undefined;
        name?: string | null;
        unique_id?: string | null;
      }[]
    | null;
  production_date?: Date;
  production_completion_date?: Date;
  delivery_type?: string;
} | null;

export interface IProjectWorkflowTemplate {
  id?: string;
  unique_id: string;
  reference_document_type?: ReferenceDocumentType | null;
  step_number?: number | null;
  status_name?: string | null;
  sub_status?: string | null;
  need_approve?: boolean | null;
  approval_template?: any | null;
  approval_template_id?: number | null;
  reviewer_unique_id_list?: Array<string> | null;
  reviewer_list?: Array<any> | null;
}

export interface ICheckInAddress {
  address_number?: string | null;
  building?: string;
  sub_district?: string | null;
  district?: string | null;
  province?: string | null;
  postal_code?: string | null;
  country?: string | null;
}
