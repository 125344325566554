import * as Yup from "yup";

export const contactSchema = {
  unique_id: "",
  is_customer: false,
  is_vendor: false,
  img_url: [],
  is_active: 1,
  status_remark: "",
  created_date: new Date(),
  contact_type: "",
  contact_type_detail: "",
  title_name: "",
  main_contact_name: "",
  secondary_contact_name: "",
  identity_no: "",
  branch_type: "",
  branch_id: "",
  branch_name: "",
  registered_capital: 0,
  contact_source: "",
  contact_channel_list: [],
  contact_group: [],
  remark: "",
  estimate_sales_item_group: [],
  estimate_sales_volume: null,
  address_list: [
    {
      address_type: "ที่อยู่จดทะเบียน",
      is_default: true,
      is_same_as_default_address: false,
      address_contact_name: "",
      address_contact_phone: "",
      address: "",
      sub_district: "",
      district: "",
      province: "",
      postal_code: "",
      country: "",
    },
  ],
  contact_person_list: [],
  finance: {
    is_cheque: false,
    is_transfer: false,
    is_cash: false,
    payment_day: "",
    payment_contact_name: "",
    payment_contact_phone: "",
    credit_count: "",
    billing_day: "",
    billing_contact_name: "",
    billing_contact_phone: "",
    account_payable_id: "",
    account_receivable_id: "",
    request_credit: {
      credit_limit_value: null,
      credit_limit_day: "",
    },
    bank_list: [],
  },
  attachment_list: [],
  sales_list: [],
};

const nullNumber = Yup.lazy((value) =>
  value === "" ? Yup.string() : Yup.number().nullable()
);

export const validation = Yup.object().shape(
  {
    unique_id: Yup.string().required("กรุณาระบุรหัสผู้ติดต่อ"),
    is_customer: Yup.boolean().when("is_vendor", (is_vendor) => {
      return is_vendor.includes(true)
        ? Yup.boolean()
        : Yup.boolean()
            .required("กรุณาเลือกประเภทผู้ติดต่อ")
            .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ");
    }),
    is_vendor: Yup.boolean().when("is_customer", (is_customer) => {
      return is_customer.includes(true)
        ? Yup.boolean()
        : Yup.boolean()
            .required("กรุณาเลือกประเภทผู้ติดต่อ")
            .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ");
    }),
    // is_customer: Yup.boolean().when("is_vendor", {
    //   is: (is_vendor) => is_vendor === false,
    //   then: Yup.boolean()
    //     .required("กรุณาเลือกประเภทผู้ติดต่อ")
    //     .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
    //   otherwise: Yup.boolean(),
    // }),
    // is_vendor: Yup.boolean().when("is_customer", {
    //   is: (is_customer) => is_customer === false,
    //   then: Yup.boolean()
    //     .required("กรุณาเลือกประเภทผู้ติดต่อ")
    //     .oneOf([true], "กรุณาเลือกประเภทผู้ติดต่อ"),
    //   otherwise: Yup.boolean(),
    // }),
    contact_type: Yup.string().required("กรุณาเลือกประเภทกิจการ"),
    main_contact_name: Yup.string().when("contact_type", (contact_type) =>
      typeof contact_type === "string" && contact_type === ""
        ? Yup.string()
        : Yup.string().when("contact_type", (contact_type) =>
            typeof contact_type === "string" && contact_type === "นิติบุคคล"
              ? Yup.string().required("กรุณาระบุชื่อกิจการ")
              : Yup.string().required("กรุณาระบุชื่อ")
          )
    ),
    secondary_contact_name: Yup.string().when("contact_type", (contact_type) =>
      typeof contact_type === "string" && contact_type === ""
        ? Yup.string()
        : Yup.string().when("contact_type", (contact_type) =>
            typeof contact_type === "string" && contact_type === "บุคคลธรรมดา"
              ? Yup.string().required("กรุณาระบุนามสกุล")
              : Yup.string().nullable()
          )
    ),
    // main_contact_name: Yup.string().when("contact_type", {
    //   is: (contact_type) => contact_type !== "",
    //   then: Yup.string().when("contact_type", {
    //     is: (contact_type) => contact_type === "นิติบุคคล",
    //     then: Yup.string().required("กรุณาระบุชื่อกิจการ"),
    //     otherwise: Yup.string().required("กรุณาระบุชื่อ"),
    //   }),
    //   otherwise: Yup.string(),
    // }),
    // secondary_contact_name: Yup.string().when("contact_type", {
    //   is: (contact_type) => contact_type === "บุคคลธรรมดา",
    //   then: Yup.string().required("กรุณาระบุนามสกุล"),
    //   otherwise: Yup.string(),
    // }),
    identity_no: Yup.string()
      .nullable()
      .test("len", "เลขประจำตัวผู้เสียภาษีห้ามมีความยาวเกิน 13 หลัก", (val) => {
        if (!val || (val && val.length <= 13)) return true;
      }),
    branch_type: Yup.string().required("กรุณาเลือกประเภทสำนักงาน"),
    branch_id: Yup.string().when("branch_type", {
      is: "branch",
      then: (schema) => schema.required("กรุณาระบุรหัสสาขา"),
      otherwise: (schema) => schema.nullable(),
    }),
    registered_capital: nullNumber,
    estimate_sales_volume: nullNumber,
    finance: Yup.object()
      .shape({
        payment_day: nullNumber,
        billing_day: nullNumber,
        request_credit: Yup.object().shape({
          credit_limit_value: nullNumber,
          credit_limit_day: nullNumber,
        }),
      })
      .nullable(),
    address_list: Yup.array().of(
      Yup.object().shape({
        default_address: Yup.boolean(),
        same_as_default_address: Yup.boolean().nullable(),
        address: Yup.string().nullable(),
        address_contact_name: Yup.string().nullable(),
        address_contact_phone: Yup.string().nullable(),
        address_type: Yup.string().nullable(),
        country: Yup.string().nullable(),
        sub_district: Yup.string().nullable(),
        district: Yup.string().nullable(),
        province: Yup.string().nullable(),
        postal_code: Yup.string().nullable(),
      })
    ),
  },
  [["is_customer", "is_vendor"]]
);
