import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu, ITab } from "../../types/global";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../components/Custom/CustomizedBreadcrumbs";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { CustomizedBox } from "../../components/Custom/CustomizedBox";
import CustomizedSteppers from "../../components/Custom/CustomizedStepper";
import CustomizedButton from "../../components/Custom/CustomizedButton";
import ContactImportUploader from "../../components/Form/Contact/ImportUploader";
import CustomizedTab from "../../components/Custom/CustomizedTab";
import ContactImporterTable from "../../components/Table/Contacts/Import";
import useNormalContactImporter from "../../hooks/Importer/Contact/use-normal-contact-importer";
import { useContactAddressImporter } from "../../hooks/Importer/Contact/use-contact-address-importer";
import { MouseEventHandler, useState } from "react";
import { useModal } from "../../hooks/use-modal";
import ImporterErrorModal from "../../components/UI/ImporterErrorModal";

const NewContactImporter = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");

  const steps = [
    t("inventory.items.import.upload_file"),
    t("inventory.items.import.validate_data"),
    t("inventory.items.import.import_data"),
  ];
  const navigate = useNavigate();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("contact.index") + "ทั้งหมด",
      to: "/contact/contacts",
    },
    {
      name: `นำเข้า${t("contact.index")}`,
    },
  ];

  const methods = useForm<any>({
    defaultValues: {
      import_type: "contact",
      type: "create",
      filePreview: null,
      file: null,
      step: 0,
      errors: [],
    },
  });

  const tabs: ITab[] = [
    {
      label: t("status.success"),
      path: `${pathname}`,
    },
    {
      label: t("status.failed"),
      path: `${pathname}?filter=failed`,
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const { watch, getValues, setValue } = methods;
  const activeStep = watch("step");
  const importType = watch("import_type");
  const [errorData, setErrorData] = useState<any[]>([]);
  const { modal, openModalHandler, closeModalHandler } = useModal();

  const { importHandler, isLoading, rowData, validateHandler } =
    useNormalContactImporter(getValues, setValue, setErrorData, openModalHandler);

  const {
    importHandler: importContactAddressHandler,
    isLoading: isContactAddressLoading,
    rowData: contactAddressData,
    validateHandler: validateAddress,
  } = useContactAddressImporter(getValues, setValue);

  const backToUploadHandler = () => {
    setValue("step", 0);
  };

  const backToAllContactHandler = () => {
    navigate("/contact/contacts");
  };

  const renderTable = (importType: string) => {
    switch (importType) {
      case "contact":
        return <ContactImporterTable data={rowData} />;
      case "contact_address":
        return <ContactImporterTable data={contactAddressData} />;
    }
  };

  const renderContent = (step: number) => {
    if (step === 0) {
      return <ContactImportUploader />;
    } else {
      return (
        <>
          {step === 3 && (
            <CustomizedTab tabs={tabs} currentTab={currentTab} divider table />
          )}
          {renderTable(importType)}
        </>
      );
    }
  };

  const renderButton = (step: number, importType: string) => {
    const getHandler = (
      handlerType: "validate" | "import"
    ): MouseEventHandler<HTMLButtonElement> | undefined => {
      switch (importType) {
        case "contact":
          return handlerType === "validate" ? validateHandler : importHandler;
        case "contact_address":
          return handlerType === "validate"
            ? validateAddress
            : importContactAddressHandler;
        default:
          return undefined;
      }
    };

    const validateContactHandler = getHandler("validate");
    const importContactHandler = getHandler("import");

    switch (step) {
      case 0:
        return (
          <CustomizedButton
            title="ตรวจสอบไฟล์"
            variant="contained"
            onClick={validateContactHandler}
          />
        );
      case 1:
        return (
          <>
            <CustomizedButton
              title="ย้อนกลับ"
              variant="outlined"
              onClick={backToUploadHandler}
            />
            <CustomizedButton
              title="นำเข้าข้อมูล"
              variant="contained"
              onClick={importContactHandler}
            />
          </>
        );
      case 3:
        return (
          <CustomizedButton
            title="ดูผู้ติดต่อทั้งหมด"
            variant="contained"
            onClick={backToAllContactHandler}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading || isContactAddressLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant="h5" mt={3}>
        {`นำเข้า${t("contact.index")}`}
      </Typography>
      <FormProvider {...methods}>
        <CustomizedBox>
          <Box my={5}>
            <CustomizedSteppers steps={steps} activeStep={activeStep} />
          </Box>
          {renderContent(activeStep)}
        </CustomizedBox>
      </FormProvider>
      <Stack direction={"row"} gap={1}>
        {renderButton(activeStep, importType)}
      </Stack>
      <ImporterErrorModal
        open={modal}
        closeModalHandler={closeModalHandler}
        data={errorData}
      />
    </>
  );
};

export default NewContactImporter;
