import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SalesOrderTable from "../../../components/Table/Sales/Order";
import { useParams } from "react-router-dom";

const SalesOrderTab = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" sx={{ ml: 1, mb: 4 }}>
        {t("sales.order.index")}
      </Typography>
      <SalesOrderTable referenceUniqueId={id} />
    </>
  );
};

export default SalesOrderTab;
