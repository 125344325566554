import { useEffect, useMemo, useState } from "react";
import { ISelectOption } from "../../../types/global";
import { useTranslation } from "react-i18next";

export const useEventStatusOption = (
  status: string,
  eventId: string | undefined
) => {
  const { t } = useTranslation();
  const defaultOptions: ISelectOption[] = useMemo(() => {
    return [
      {
        label: t("project.event.status.planning"),
        value: "planning",
      },
      {
        label: t("project.event.status.appointment"),
        value: "appointment",
      },
      {
        label: t("project.event.status.check_in"),
        value: "check_in",
      },
      {
        label: t("project.event.status.finished"),
        value: "finished",
      },
      {
        label: t("project.event.status.cancelled"),
        value: "cancelled",
      },
    ];
  }, [t]);

  const [selectModifyOptions, setSelectedModifyOptions] = useState<
    ISelectOption[]
  >([defaultOptions[0], defaultOptions[1]]);

  useEffect(() => {
    if (eventId) {
      switch (status) {
        case "planning":
          setSelectedModifyOptions([
            defaultOptions[0],
            defaultOptions[1],
            defaultOptions[3],
            defaultOptions[4],
          ]);
          break;
        case "appointment":
          setSelectedModifyOptions([
            defaultOptions[1],
            defaultOptions[3],
            defaultOptions[4],
          ]);
          break;
        case "check_in":
          setSelectedModifyOptions([
            defaultOptions[2],
            defaultOptions[3],
            defaultOptions[4],
          ]);
          break;
        case "finished":
          setSelectedModifyOptions([defaultOptions[3]]);
          break;
        case "cancelled":
          setSelectedModifyOptions([defaultOptions[4]]);
          break;
        default:
          setSelectedModifyOptions([defaultOptions[0], defaultOptions[1]]);
          break;
      }
    } else {
      setSelectedModifyOptions([defaultOptions[0], defaultOptions[1]]);
    }
  }, [defaultOptions, eventId, status]);

  return {
    selectStatusOptions: selectModifyOptions,
  };
};
