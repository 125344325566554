import { Fragment } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import CustomizedTab from "../../Custom/CustomizedTab";
import AddressTab from "./AddressTab";
import NewContactTab from "./NewContactTab";
import FinancialTab from "./FinancialTab";
import AttachmentTab from "./AttachmentTab";
import SpecificTab from "./SpecificTab";
import SalesListTab from "./SalesListTab";
import { Box, Grid } from "@mui/material";
import { IDefaultForm } from "../../../types/global";

type ExtendedProps = IDefaultForm & {
  isContactPage: boolean;
};

const ContactGeneralForm = ({
  control,
  errors,
  setValue,
  getValues,
  isContactPage,
  disabled,
}: ExtendedProps) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(isContactPage ? "tab" : "contactTab");
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (isInit) {
  //     setValue("contact_type_detail", "");
  //     setValue("secondary_contact_name", "");
  //     setValue("title_name", "");
  //   } else {
  //     dispatch(contactActions.initalizeGeneral());
  //   }
  //   switch (watchContactType) {
  //     case "นิติบุคคล":
  //       setContactTypeOptions(memorizedCoperateTypes);
  //       break;
  //     case "บุคคลธรรมดา":
  //       setContactTypeOptions(memoriedNaturalPersonTypes);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [
  //   watchContactType,
  //   memorizedCoperateTypes,
  //   memoriedNaturalPersonTypes,
  //   setValue,
  //   isInit,
  //   contactSnapshot,
  //   dispatch,
  // ]);

  // useEffect(() => {
  //   if (isInit && contactSnapshot) {
  //     if (contactSnapshot.contact_type === "บุคคลธรรมดา") {
  //       setContactTypeOptions(memoriedNaturalPersonTypes);
  //       setValue("title_name", contactSnapshot?.title_name);
  //       setValue("secondary_contact_name", contactSnapshot?.secondary_contact_name);
  //     } else {
  //       setContactTypeOptions(memorizedCoperateTypes);
  //     }
  //     setValue("contact_type_detail", contactSnapshot?.contact_type_detail);
  //   }
  // }, [
  //   isInit,
  //   dispatch,
  //   setValue,
  //   contactSnapshot,
  //   generalIsInit,
  //   memoriedNaturalPersonTypes,
  //   memorizedCoperateTypes,
  // ]);

  const tabs = [
    {
      label: t("contact.tab.address"),
      path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=address`,
    },
    {
      label: t("contact.tab.contact_person"),
      path: `${pathname}?${
        isContactPage ? "tab" : "contactTab"
      }=contact_person`,
    },
    {
      label: t("contact.tab.financial"),
      path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=financial`,
    },
    {
      label: t("contact.tab.specific_info"),
      path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=specific_info`,
    },
    {
      label: t("contact.tab.related_external_document"),
      path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=attachment`,
    },
    {
      label: t("contact.tab.sale_list"),
      path: `${pathname}?${isContactPage ? "tab" : "contactTab"}=sale_list`,
    },
  ];

  const renderTab = (tab: string | null) => {
    switch (tab) {
      case "address":
      case null:
        return (
          <AddressTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            disabled={disabled}
          />
        );
      case "contact_person":
        return (
          <NewContactTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            disabled={disabled}
          />
        );
      case "financial":
        return (
          <FinancialTab
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            disabled={disabled}
          />
        );
      case "specific_info":
        return (
          <SpecificTab
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            disabled={disabled}
          />
        );
      case "attachment":
        return (
          <AttachmentTab
            control={control}
            setValue={setValue}
            getValues={getValues}
            disabled={disabled}
            errors={errors}
          />
        );
      case "sale_list":
        return (
          <SalesListTab
            control={control}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            disabled={disabled}
          />
        );
      default:
        return;
    }
  };

  const currentTab = tab
    ? pathname + `?${isContactPage ? "tab" : "contactTab"}=${tab}`
    : pathname + `?${isContactPage ? "tab" : "contactTab"}=address`;

  return (
    <Fragment>
      <CustomizedBox
        boxShadow={!isContactPage ? "none" : undefined}
        margin={!isContactPage ? 0 : "2rem 0 2rem 0"}
      >
        <Box sx={{ width: "100%", mt: -4 }}>
          <CustomizedTab
            tabs={tabs}
            currentTab={currentTab}
            scrollable
            divider
          />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            {renderTab(tab)}
          </Grid>
        </Grid>
      </CustomizedBox>
    </Fragment>
  );
};

export default ContactGeneralForm;
