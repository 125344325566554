import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  RowDoubleClickedEvent,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AgGrid from "../../../UI/AgGrid";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { goodsAdjustmentColumnDefs } from "./columnDefs";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { GOODS_ADJUSTMENTS_AGGRID } from "../../../../services/AgGrid/InventoryAgGrid";
import { dateFilterModel } from "../../../../utils/Formatter/AgGridFilter";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import { AgGridReact } from "ag-grid-react";
import { GraphQLClient } from "graphql-request";
import { useStateContext } from "../../../../contexts/auth-context";

interface Props {
  gridRef: RefObject<AgGridReact<IGoodsAdjustment>>;
  isFilter: string | null;
  setRowCount: Dispatch<SetStateAction<number>>;
}

const GoodsAdjustmentTable = ({ gridRef, isFilter, setRowCount }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    state: { permissions, authUser },
  } = useStateContext();

  const columnDef: ColDef[] = goodsAdjustmentColumnDefs(t);

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const datasource: IServerSideDatasource = {
    async getRows(params) {
      const { request } = params;
      const { startRow, endRow, filterModel, sortModel } = request;

      const { created_date, posted_date, source_warehouse, created_by } =
        filterModel;

      const formatFilter = {
        ...filterModel,
        created_date: dateFilterModel(created_date),
        posted_date: dateFilterModel(posted_date),
        source_warehouse: source_warehouse && {
          filterType: "object",
          filter: {
            name: source_warehouse,
          },
        },
        created_by:
          permissions?.goods_adjustment.view === "SELF"
            ? {
                filterType: "json",
                type: "equals",
                filter: authUser?.unique_id,
                path: ["user_unique_id"],
              }
            : created_by && {
                filterType: "json",
                type: created_by.type,
                filter: created_by.filter,
                path: ["first_name"],
              },
      };

      try {
        const { goodsAdjustsFindManyAggrid } =
          await graphQLClientWithHeaderItem.request(GOODS_ADJUSTMENTS_AGGRID, {
            aggridInput: {
              startRow,
              endRow,
              filterModel: formatFilter,
              sortModel,
            },
          });
        setRowCount(goodsAdjustsFindManyAggrid.count as number);
        params.success({
          rowData: goodsAdjustsFindManyAggrid.data as any[],
          rowCount: goodsAdjustsFindManyAggrid.count as number,
        });
      } catch (err) {
        params.fail();
      }
    },
  };

  const onFilterChanged = useCallback(
    (params: any) => {
      const instance = params.api.getFilterInstance("aggrid_status");
      switch (isFilter) {
        case "draft":
          instance?.setModel({ values: ["draft"] });
          break;
        case "wait_approve":
          instance?.setModel({ values: ["wait_approve"] });
          break;
        case "not_approved":
          instance?.setModel({ values: ["not_approved"] });
          break;
        case "finished":
          instance?.setModel({ values: ["finished"] });
          break;
        case "cancelled":
          instance?.setModel({ values: ["cancelled"] });
          break;
        default:
          instance?.setModel({});
          break;
      }

      if (state) {
        instance?.setModel({ values: state });
      }

      params.api.onFilterChanged();
    },
    [isFilter, state]
  );

  const onGridReady = (params: GridReadyEvent) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
  }, [gridRef, onFilterChanged]);

  const onRowDoubleClicked = (params: RowDoubleClickedEvent) => {
    navigate(
      `/inventory/goods_adjustment/${encodeURIComponent(params.data.unique_id)}`
    );
  };

  return (
    <AgGrid
      ref={gridRef}
      columnDefs={columnDef}
      height={665}
      onRowDoubleClicked={onRowDoubleClicked}
      onGridReady={onGridReady}
      path={"/inventory/goods_adjustment"}
    />
  );
};

export default GoodsAdjustmentTable;
