import * as Yup from "yup";
import { IBom } from "../../../../types/Manufacture/bom";
import dayjs from "dayjs";

export const bomSchema: IBom = {
  id: undefined,
  name: "",
  description: "",
  created_date: dayjs(),
  start_date: dayjs(),
  end_date: dayjs(),
  is_active: 1,
  internal_remark: "",
  is_main_bom: 0,
  item_unique_id: "",
  item_name: "",
  mfg_qty: "0",
  uom: "",
  mfg_type: "",
  creator_unique_id: undefined,
  created_by: undefined,
  ingredient_list: [],
  attachment_list: [],
};

const ingredientValidation = Yup.object().shape({
  item_name: Yup.string().required("กรุณาระบุชื่อสินค้า"),
  qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
});

export const bomValidation = Yup.object().shape({
  name: Yup.string().required("กรุณาระบุชื่อสูตร"),
  item_unique_id: Yup.string().required("กรุณาระบุสินค้า"),
  mfg_qty: Yup.number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    )
    .positive("กรุณาระบุจำนวน")
    .required("กรุณาระบุจำนวน"),
  ingredient_list: Yup.array()
    .of(ingredientValidation)
    .min(1, "กรุณาระบุส่วนประกอบ")
    .nullable(),
});
