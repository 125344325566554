import { useTranslation } from "react-i18next";
import ModalUI from "../../UI/ModalUI";
import { useForm } from "react-hook-form";
import { IUom } from "../../../types/Inventory/item";
import { Box, Grid } from "@mui/material";
import CustomizedButton from "../../Custom/CustomizedButton";
import ControlledTextField from "../../Controller/ControlledTextField";
import {
  UomQuery,
  UomsQuery,
  useUomCreateMutation,
  useUomQuery,
  useUomUpdateMutation,
} from "../../../generated/inventory";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { useEffect } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { uomSchema, uomValidation } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import { GraphQLClient } from "graphql-request";

interface Props {
  open: boolean;
  closeModalHandler: () => void;
  currentId?: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UomsQuery, unknown>>;
}

const UomModal = ({ open, closeModalHandler, currentId, refetch }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { data, isSuccess } = useUomQuery<UomQuery>(
    graphQLClientWithHeaderItem,
    {
      uniqueInput: {
        id: currentId,
      },
    },
    {
      enabled: Boolean(currentId),
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUom>({
    defaultValues: uomSchema,
    resolver: yupResolver(uomValidation),
  });

  const onClose = () => {
    reset();
    closeModalHandler();
  };

  const { mutate: createUom } = useUomCreateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: () => {
        enqueueSnackbar("เพิ่มหน่วยสำเร็จ", {
          variant: "success",
        });
        refetch();
        onClose();
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(errorMessage || "เพิ่มหน่วยไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: updateUom } = useUomUpdateMutation<Error>(
    graphQLClientWithHeaderItem,
    {
      onSuccess: ({ uomUpdate }) => {
        if (uomUpdate) {
          enqueueSnackbar("แก้ไขหน่วยสำเร็จ", {
            variant: "success",
          });
          const formatUom = {
            id: uomUpdate.id,
            name: uomUpdate.name,
            unique_id: uomUpdate.unique_id,
          };
          reset(formatUom);
          refetch();
          onClose();
        }
      },
      onError: (error) => {
        const errorMessage = errorMessageFormatter(error, "setting");
        enqueueSnackbar(errorMessage || "แก้ไขหน่วยไม่สำเร็จ", {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const formatUom = {
        id: data?.uom?.id,
        name: data?.uom?.name,
        unique_id: data?.uom?.unique_id,
      };
      reset(formatUom);
    }
  }, [data?.uom?.id, data?.uom?.name, data?.uom?.unique_id, isSuccess, reset]);

  const addUomHandler = (data: IUom) => {
    createUom({
      createInput: {
        unique_id: data.name,
        name: data.name,
      },
    });
  };

  const updateUomHandler = (data: IUom) => {
    updateUom({
      uniqueInput: {
        id: data.id,
      },
      updateInput: {
        unique_id: data.name,
        name: data.name,
      },
    });
  };

  return (
    <ModalUI
      title={
        (currentId ? t("sentence.edit") : t("button.add")) +
        t("setting.inventory.uom")
      }
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {currentId ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={handleSubmit(updateUomHandler)}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={handleSubmit(addUomHandler)}
            />
          )}
        </Box>
      }
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ControlledTextField
            label={"ชื่อ" + t("setting.inventory.uom")}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message}
            required
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default UomModal;
